import React, { useEffect } from 'react';
import { Messages } from '@public/locales/messages';
import Loader from '@src/components/Loader';
import Select from '@src/components/Select';
import { SessionStatus } from '@src/global-interfaces';
import { SessionStatuses } from '@src/modules/Pages/Reports/AgentReport/components/SessionStatuses';
import Statistics from '@src/modules/Pages/Reports/AgentReport/components/Statistics';
import { InnerStyled, StyledAgentReportWrapper, StyledGetStatsBtn, } from '@src/modules/Pages/Reports/AgentReport/styled';
import { useAgentReport } from '@src/modules/Pages/Reports/AgentReport/useAgentReport';
import { Button } from '../../../../components/Button/Button';
export var AgentReport = function (_a) {
    var agentId = _a.agentId, getAgentReports = _a.getAgentReports, agentName = _a.agentName, reports = _a.reports, clearAgentReports = _a.clearAgentReports, clearCheckedReportBlockDay = _a.clearCheckedReportBlockDay;
    var _b = useAgentReport({
        agentId: agentId,
        getAgentReports: getAgentReports,
        clearAgentReports: clearAgentReports,
        clearCheckedReportBlockDay: clearCheckedReportBlockDay,
    }), years = _b.years, months = _b.months, currentYear = _b.currentYear, currentMonth = _b.currentMonth, setCheckedStatuses = _b.setCheckedStatuses, checkedStatuses = _b.checkedStatuses, setCheckedYear = _b.setCheckedYear, setCheckedMonth = _b.setCheckedMonth, getAgentReport = _b.getAgentReport, isReportsLading = _b.isReportsLading;
    useEffect(function () { return function () {
        clearAgentReports();
        clearCheckedReportBlockDay();
    }; }, []);
    return (React.createElement(StyledAgentReportWrapper, null,
        React.createElement(InnerStyled, null,
            React.createElement("div", { className: "report__agent-name" }, "".concat(Messages.Labels.Report, ":"),
                " ",
                agentName),
            React.createElement("div", { className: "report__select-date" },
                React.createElement("div", { className: "report__select-date_month" },
                    React.createElement(Select, { items: months, initialState: currentMonth, isMultiply: false, disableSearch: true, stateCB: function (el) { return setCheckedMonth(el.value); } })),
                React.createElement("div", { className: "report__select-date_year" },
                    React.createElement(Select, { items: years, initialState: currentYear, isMultiply: false, disableSearch: true, stateCB: function (el) { return setCheckedYear(el.value); } })),
                React.createElement("div", null,
                    React.createElement(StyledGetStatsBtn, null,
                        React.createElement(Button, { title: Messages.Buttons.getStatistics, onClick: getAgentReport })))),
            React.createElement("div", { className: "report__statuses-filter" },
                React.createElement(SessionStatuses, { status: SessionStatus.online, checkedStatuses: checkedStatuses.online, setCheckedStatuses: setCheckedStatuses, title: Messages.Controls.Online }),
                React.createElement(SessionStatuses, { status: SessionStatus.break, checkedStatuses: checkedStatuses.break, setCheckedStatuses: setCheckedStatuses, title: Messages.Controls.Break }),
                React.createElement(SessionStatuses, { status: SessionStatus.offline, checkedStatuses: checkedStatuses.offline, setCheckedStatuses: setCheckedStatuses, title: Messages.Controls.Offline })),
            isReportsLading && !reports && React.createElement(Loader, null),
            reports && reports.length > 0 && React.createElement(Statistics, { checkedStatuses: checkedStatuses }))));
};
