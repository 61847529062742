export var getNamesByIds = function (ids, dictionary) {
    return ids
        .map(function (el) {
        if (!el) {
            return '';
        }
        return dictionary[el];
    })
        .filter(function (el) { return !!el; });
};
