var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledAgentName = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-top: 15px;\n    img {\n      width: 25px;\n      height: 25px;\n      object-fit: cover;\n      border-radius: 50%;\n      filter: ", ";\n    }\n\n    .agent-name-text {\n      padding-left: 8px;\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-top: 15px;\n    img {\n      width: 25px;\n      height: 25px;\n      object-fit: cover;\n      border-radius: 50%;\n      filter: ", ";\n    }\n\n    .agent-name-text {\n      padding-left: 8px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, isAvatar = _a.isAvatar;
    return !isAvatar && theme.invert;
});
export var StyledAgentGroupsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    color: ", ";\n    font-size: 15px;\n    align-items: center;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    display: flex;\n    color: ", ";\n    font-size: 15px;\n    align-items: center;\n    justify-content: space-between;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledRemoveAgentGroups = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    user-select: none;\n    font-weight: bold;\n    cursor: pointer;\n    color: ", ";\n  }\n"], ["\n  && {\n    user-select: none;\n    font-weight: bold;\n    cursor: pointer;\n    color: ", ";\n  }\n"])), COLORS.RED);
export var StyledAddAgentGroupsWrapper = styled(StyledModalWrapperMedia)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 40vw;\n  height: 30vh;\n  padding: 10px 20px;\n  overflow: auto;\n  ", "\n"], ["\n  width: 40vw;\n  height: 30vh;\n  padding: 10px 20px;\n  overflow: auto;\n  ", "\n"])), scrollStyles);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
