var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { StyledModalContentFieldsWrapper, StyledModalContentWrapper, StyledModalFooterOneBtnWrapper, } from '@src/app/styled';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledAddNewBtn, StyledSaveBtn } from '@src/components/Button/styled';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import Loader from '@src/components/Loader';
import ModalWindow from '@src/components/ModalWindow';
import Select from '@src/components/Select';
import { notificationTypes } from '@src/global-interfaces';
import { StyledCreateTagsModalWrapper, StyledTagInput, StyleUsefulLinksWrapper, } from '@src/modules/Pages/Tags/TagsCreateModal/styled';
import UsefulLinksItem from '@src/modules/Pages/Tags/UsefulLinksItem';
import UsefulLinksModal from '@src/modules/Pages/Tags/UsefulLinksModal';
import { useCallbackUseful } from '@src/modules/Pages/Tags/UsefulLinksModal/useCallbackUseful';
import { transformDictionary } from '@src/utils/transformDictionary';
import { composeValidators, isRequired, lengthCreator } from '@src/utils/validations';
var TagsCreateModal = function (props) {
    var createTag = props.createTag, groupDictionary = props.groupDictionary, showNotification = props.showNotification, isLoadingCreatingTag = props.isLoadingCreatingTag, toggleModalCreateTags = props.toggleModalCreateTags, isModalOpenUsefulLinks = props.isModalOpenUsefulLinks, toggleModalUsefulLinks = props.toggleModalUsefulLinks, resetNotificationState = props.resetNotificationState;
    var _a = useCallbackUseful(), clickEdit = _a.clickEdit, setUsefulLinksElem = _a.setUsefulLinksElem, editUsefulLinks = _a.editUsefulLinks, deleteUsefulLinks = _a.deleteUsefulLinks;
    var minNameTag = lengthCreator.minNameTag, maxNameTag = lengthCreator.maxNameTag;
    var _b = __read(useState([]), 2), usefulLinks = _b[0], setUsefulLinks = _b[1];
    var _c = __read(useState(false), 2), isOpenTwoModal = _c[0], setIsOpenTwoModal = _c[1];
    var _d = __read(useState([]), 2), selectedChannel = _d[0], setSelectedChannel = _d[1];
    var _e = __read(useState(true), 2), isSelectedChannel = _e[0], setIsSelectedChannel = _e[1];
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChangeSelectedChannel = useCallback(function (el) {
        checkIsSelectedChannel(el);
        setSelectedChannel(el);
    }, [selectedChannel]);
    var checkIsSelectedChannel = useCallback(function (channels) {
        if (channels.length <= 0) {
            setIsSelectedChannel(false);
            return;
        }
        setIsSelectedChannel(true);
    }, [isSelectedChannel]);
    var onChangeSetUsefulLinks = useCallback(function (el) {
        setUsefulLinksElem(el, usefulLinks, setUsefulLinks, toggleModalUsefulLinks, setIsOpenTwoModal);
    }, [usefulLinks]);
    var onEditUsefulLinks = useCallback(function (el, index) {
        editUsefulLinks(el, usefulLinks, setUsefulLinks, toggleModalUsefulLinks, setIsOpenTwoModal, index);
    }, [usefulLinks]);
    var onClickDelete = useCallback(function (index) {
        deleteUsefulLinks(index, usefulLinks, setUsefulLinks);
    }, [usefulLinks]);
    var onClickEdit = useCallback(function (index) {
        clickEdit(index, setIsOpenTwoModal, usefulLinks, toggleModalUsefulLinks);
    }, [usefulLinks]);
    var onSubmit = function (data) {
        if (selectedChannel.length <= 0)
            return;
        var groupsId = selectedChannel.map(function (el) { return el.value; });
        createTag({ name: data.tagName, groups: groupsId, usefulLinks: usefulLinks });
    };
    var tagsInput = InputWithStyles(Input, StyledTagInput);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalWindow, { closeModalWindow: toggleModalCreateTags, title: Messages.Labels.CreateTag, outsideClick: !isOpenTwoModal },
            React.createElement(StyledCreateTagsModalWrapper, null, isLoadingCreatingTag ? (React.createElement(Loader, null)) : (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(StyledModalContentWrapper, null,
                            React.createElement(StyledModalContentFieldsWrapper, null,
                                React.createElement(Field, { name: "tagName", label: "".concat(Messages.Labels.TagName, "*"), showWarning: showWarning, validate: composeValidators(isRequired, minNameTag, maxNameTag), placeholder: "".concat(Messages.Controls.TagName, "*"), component: tagsInput }),
                                React.createElement(Select, { items: transformDictionary(groupDictionary, 'text', 'value'), label: "".concat(Messages.Labels.SelectGroups, "*"), error: !isSelectedChannel, placeholder: "".concat(Messages.Selects.SelectGroups, "*"), stateCB: function (el) { return onChangeSelectedChannel(el); } }),
                                !!(usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.length) && (React.createElement(StyleUsefulLinksWrapper, null,
                                    React.createElement("div", { className: "useful-links-name" }, Messages.Labels.UsefulLinks),
                                    React.createElement("div", { className: "useful-links-content" }, usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.map(function (el, index) { return (React.createElement(UsefulLinksItem, { name: el.alias, key: index, onClickDelete: function () { return onClickDelete(index); }, onClickEdit: function () { return onClickEdit(index); } })); })))),
                                React.createElement(StyledAddNewBtn, null,
                                    React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addNewUsefulLinks), onClick: function () {
                                            toggleModalUsefulLinks(null);
                                            setIsOpenTwoModal(true);
                                        } })))),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { return checkIsSelectedChannel(selectedChannel); }, type: TButtonTypes.submit })))));
                } })))),
        isModalOpenUsefulLinks && (React.createElement(UsefulLinksModal, { setIsOpenTwoModal: setIsOpenTwoModal, setUsefulLinks: onChangeSetUsefulLinks, onEditUsefulLinks: onEditUsefulLinks }))));
};
export default TagsCreateModal;
