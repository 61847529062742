import React from 'react';
import { StyledLoader } from './styled';
var Loader = function () { return (React.createElement(React.Fragment, null,
    React.createElement(StyledLoader, { id: "globalLoader" },
        React.createElement("div", { className: "loading" },
            React.createElement("div", { className: "preloader" },
                React.createElement("div", { className: "preloader_content" },
                    React.createElement("div", { className: "preloader_first" }),
                    React.createElement("div", { className: "preloader_second" }),
                    React.createElement("div", { className: "preloader_third" }))))))); };
export default Loader;
