var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledTeamLeadWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    padding: 0 10px;\n  }\n"], ["\n  && {\n    color: ", ";\n    padding: 0 10px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledAddOrRemoveTeamLeadWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    margin: 15px 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n"], ["\n  && {\n    margin: 15px 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n"])));
var templateObject_1, templateObject_2;
