import { createSelector } from '@reduxjs/toolkit';
var brandsState = function (state) { return state.pages.brands; };
var brandsStore = {
    getCurrentBrand: createSelector(brandsState, function (state) { return state.currentBrand; }),
    getEmailMessengers: createSelector(brandsState, function (state) { return state.currentBrandMessengers.filter(function (el) { return el.messenger === 'email'; }); }),
    getTelegramPrivateMessengers: createSelector(brandsState, function (state) { return state.currentBrandMessengers.filter(function (el) { return el.messenger === 'telegramPrivate'; }); }),
    getSmsMessengers: createSelector(brandsState, function (state) { return state.currentBrandMessengers.filter(function (el) { return el.messenger === 'sms'; }); }),
    getSmsAccountsSettings: createSelector(brandsState, function (state) { return state.smsAccountsSettings; }),
};
export default brandsStore;
