var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { TButtonTypes } from '@src/components/Button/Button';
import { statusInbox } from '@src/global-interfaces';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import ModalWindow from '@src/components/ModalWindow';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledCheckboxWrapper, StyledUpdateMailNameWrapper, } from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox/styled';
import { Messages } from '@public/locales/messages';
var UpdateTelegramInbox = function (props) {
    var toggleModal = props.toggleModal, telegramInbox = props.telegramInbox, setIsOpenTwoModal = props.setIsOpenTwoModal, updateTelegramInbox = props.updateTelegramInbox, isLoadingCreateInbox = props.isLoadingCreateInbox;
    var _a = __read(useState(telegramInbox.status), 2), inboxStatus = _a[0], setInboxStatus = _a[1];
    var handleInboxStatusChange = function (changeEvent) {
        setInboxStatus(+changeEvent.target.value);
    };
    var closeModal = function () {
        toggleModal();
        setIsOpenTwoModal(false);
    };
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: Messages.Labels.TelegramInbox }, isLoadingCreateInbox ? (React.createElement(Loader, null)) : (React.createElement(React.Fragment, null,
        React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.ChangeStatus),
        React.createElement(StyledCheckboxWrapper, null, statusInbox.map(function (el, index) { return (React.createElement(Checkbox, { key: index + el.value + el.text, checked: inboxStatus === +statusInbox[index].value, label: el.text, onChange: handleInboxStatusChange, value: el.value })); })),
        React.createElement(StyledSaveBtn, null,
            React.createElement(Button, { title: Messages.Buttons.changeStatus, onClick: function () { return updateTelegramInbox(__assign(__assign({}, telegramInbox), { status: inboxStatus })); }, type: TButtonTypes.submit }))))));
};
export default UpdateTelegramInbox;
