var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import Audio from '@src/components/Audio';
import Loader from '@src/components/Loader';
import MessagesContainer from '@src/components/MessagesContainer';
import { StylerAudioContentTitle, StylerAudioContentWrapper, StylerLoaderModalContentWrapper, } from '@src/modules/Pages/Dialogs/ShowDialogModal/styled';
import { Messages } from '@public/locales/messages';
var ShowDialogModal = function (props) {
    var messages = props.messages, agentDictionary = props.agentDictionary, modalInfo = props.modalInfo, entity = props.entity, isLoading = props.isLoading, audioCalls = props.audioCalls;
    var clientInfo = entity.filter(function (el) { return el.id === modalInfo; })[0].clientInfo;
    var clientName = (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.username) || (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.lastName) || (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.firstName) || Messages.Controls.NoData;
    var _a = __read(useState(''), 2), selectedRecords = _a[0], setSelectedRecords = _a[1];
    return (isLoading
        ? (React.createElement(StylerLoaderModalContentWrapper, null,
            React.createElement(Loader, null)))
        : (React.createElement(React.Fragment, null,
            React.createElement(MessagesContainer, { messages: messages, agentDictionary: agentDictionary, clientName: clientName }),
            (audioCalls === null || audioCalls === void 0 ? void 0 : audioCalls.length) >= 1 && (React.createElement(StylerAudioContentWrapper, null,
                React.createElement(StylerAudioContentTitle, null, "Call recordings"), audioCalls === null || audioCalls === void 0 ? void 0 :
                audioCalls.map(function (el) { return (React.createElement(Audio, { createdAt: el.createdAt, filePath: el.filePath, key: el.filePath, recorderState: selectedRecords, setSelectedRecords: setSelectedRecords })); }))))));
};
export default ShowDialogModal;
