var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledIconWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    background: ", ";\n    color: ", ";\n    max-width: 150px;\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 18px;\n    font-weight: bold;\n    text-align: center;\n    user-select: none;\n    height: 100px;\n    line-height: 100px;\n  }\n"], ["\n  && {\n    background: ", ";\n    color: ", ";\n    max-width: 150px;\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 18px;\n    font-weight: bold;\n    text-align: center;\n    user-select: none;\n    height: 100px;\n    line-height: 100px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledPipeDriveWrapper = styled(StyledModalWrapperMedia)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  width: 30vw;\n  color: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  width: 30vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledPipeDriveLoaderWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    height: 50px;\n  }\n"], ["\n  && {\n    width: 100%;\n    height: 50px;\n  }\n"])));
export var ItemKeyPipeDrive = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n    justify-content: space-between;\n    padding: 8px;\n    margin: 0 0 10px 0;\n    background: ", ";\n    color: ", ";\n\n    :hover {\n      opacity: 0.9;\n    }\n\n    .item-pipeDrive-text {\n      display: flex;\n      align-items: baseline;\n      word-break: break-word;\n      white-space: pre-line;\n    }\n\n    .item-pipeDrive-icon-box {\n      display: flex;\n    }\n\n    img {\n      margin-left: 5px;\n      filter: ", ";\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n    justify-content: space-between;\n    padding: 8px;\n    margin: 0 0 10px 0;\n    background: ", ";\n    color: ", ";\n\n    :hover {\n      opacity: 0.9;\n    }\n\n    .item-pipeDrive-text {\n      display: flex;\n      align-items: baseline;\n      word-break: break-word;\n      white-space: pre-line;\n    }\n\n    .item-pipeDrive-icon-box {\n      display: flex;\n    }\n\n    img {\n      margin-left: 5px;\n      filter: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.isSelectedItemCategoryBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
});
export var StyledBackWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    display: flex;\n    margin-bottom: 10px;\n    cursor: pointer;\n\n    img {\n      width: 20px;\n      height: 20px;\n      transform: rotate(90deg);\n      cursor: pointer;\n      filter: ", ";\n    }\n  }\n"], ["\n  && {\n    color: ", ";\n    display: flex;\n    margin-bottom: 10px;\n    cursor: pointer;\n\n    img {\n      width: 20px;\n      height: 20px;\n      transform: rotate(90deg);\n      cursor: pointer;\n      filter: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
