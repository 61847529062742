import { localeFlags } from '@public/images';
import { Messages } from '@public/locales/messages';
export var SORTING_TYPES;
(function (SORTING_TYPES) {
    SORTING_TYPES["DESC"] = "desc";
    SORTING_TYPES["ASC"] = "asc";
    SORTING_TYPES["DEFAULT"] = "";
})(SORTING_TYPES || (SORTING_TYPES = {}));
export var SessionStatus;
(function (SessionStatus) {
    SessionStatus["online"] = "online";
    SessionStatus["offline"] = "offline";
    SessionStatus["break"] = "break";
})(SessionStatus || (SessionStatus = {}));
export var TAgentStatus;
(function (TAgentStatus) {
    TAgentStatus["active"] = "active";
    TAgentStatus["banned"] = "banned";
})(TAgentStatus || (TAgentStatus = {}));
export var IPages;
(function (IPages) {
    IPages["dialogs"] = "dialogs";
    IPages["groups"] = "groups";
    IPages["agents"] = "agents";
    IPages["tags"] = "tags";
    IPages["clients"] = "clients";
    IPages["channels"] = "channels";
    IPages["brands"] = "brands";
    IPages["reports"] = "reports";
    IPages["messengerReports"] = "messengerReports";
    IPages["agentsReports"] = "agentsReports";
    IPages["groupsReports"] = "groupsReports";
})(IPages || (IPages = {}));
export var ITables;
(function (ITables) {
    ITables["dialogs"] = "dialogs";
    ITables["groups"] = "groups";
    ITables["agents"] = "agents";
    ITables["tags"] = "tags";
    ITables["clients"] = "clients";
    ITables["channels"] = "channels";
    ITables["brands"] = "brands";
    ITables["reports"] = "reports";
    ITables["messengerReports"] = "messengerReports";
    ITables["agentsReports"] = "agentsReports";
    ITables["groupsReports"] = "groupsReports";
})(ITables || (ITables = {}));
export var notificationTypes;
(function (notificationTypes) {
    notificationTypes["success"] = "success";
    notificationTypes["warning"] = "warning";
    notificationTypes["error"] = "error";
    notificationTypes["info"] = "info";
    notificationTypes["default"] = "";
})(notificationTypes || (notificationTypes = {}));
export var reduxModules;
(function (reduxModules) {
    reduxModules["excel"] = "@excel";
    reduxModules["entity"] = "@entity";
    reduxModules["appPage"] = "@appPage";
    reduxModules["authPage"] = "@authPage";
    reduxModules["tagsPage"] = "@tagsPage";
    reduxModules["groupsPage"] = "@groupsPage";
    reduxModules["agentsPage"] = "@agentsPage";
    reduxModules["brandsPage"] = "@brandsPage";
    reduxModules["dialogsPage"] = "@dialogsPage";
    reduxModules["clientsPage"] = "@clientsPage";
    reduxModules["channelsPage"] = "@channelsPage";
    reduxModules["reportsPage"] = "@reportsPage";
    reduxModules["dictionary"] = "@dictionary";
    reduxModules["notification"] = "@notification";
    reduxModules["tableOptions"] = "@tableOptions";
    reduxModules["toolBar"] = "@toolBar";
    reduxModules["modalWindows"] = "@modalWindows";
    reduxModules["isLoading"] = "@isLoading";
    reduxModules["resetPasswordPage"] = "@resetPasswordPage";
})(reduxModules || (reduxModules = {}));
export var AllAgentSettings = {
    roles: Messages.Labels.Roles,
    groups: Messages.Labels.Groups,
    teamLead: Messages.Labels.TeamLead,
    tags: Messages.Labels.Tags,
    info: Messages.Labels.Info,
    ban: Messages.Labels.Ban,
};
export var AllGroupsSettings = {
    agents: Messages.Labels.Agents,
    updateGroup: Messages.Labels.UpdateGroup,
    usefulPhrases: Messages.Labels.UsefulPhrases,
    channels: Messages.Labels.Channels,
    tags: Messages.Labels.Tags,
};
export var AgentRolesTableColumns = [
    Messages.Labels.Brand,
    Messages.Labels.Group,
    Messages.Labels.Roles,
];
export var AccessLevelGroups = [
    {
        value: 'low',
        text: Messages.Labels.Low,
    },
    {
        value: 'medium',
        text: Messages.Labels.Medium,
    },
    {
        value: 'high',
        text: Messages.Labels.High,
    },
];
export var statusTag = [
    {
        value: 'hidden',
        text: Messages.Labels.Hidden,
    },
    {
        value: 'active',
        text: Messages.Labels.Active,
    },
];
export var ClientInfoTableHeadNames = [
    Messages.Pages.Clients.TableColumns.Tag,
    Messages.Pages.Clients.TableColumns.Status,
    Messages.Pages.Clients.TableColumns.Channel,
    Messages.Pages.Clients.TableColumns.Messenger,
    Messages.Pages.Clients.TableColumns.AgentName,
    Messages.Pages.Clients.TableColumns.LastActivity,
];
export var selectMessenger = [
    { value: 'telegram', text: 'Telegram' },
    { value: 'sms', text: Messages.Labels.SMS },
    { value: 'viber', text: 'Viber' },
    { value: 'smartbot', text: 'Web Library' },
    { value: 'whatsapp', text: 'Whatsapp' },
    { value: 'email', text: Messages.Labels.Email },
    { value: 'messenger', text: 'Messenger' },
    { value: 'metadialog', text: 'MetaDialog' },
];
export var selectBrandStatus = [
    { value: 'trial', text: 'Trial' },
    { value: 'active', text: 'Active' },
    { value: 'suspended', text: 'Suspended' },
];
export var uniqueClient = [
    { value: 'phone', text: Messages.Labels.Phone },
    { value: 'externalID', text: Messages.Labels.ID },
    { value: 'email', text: Messages.Labels.Email },
];
export var statusInbox = [
    { value: '1', text: Messages.Labels.New },
    { value: '2', text: Messages.Labels.Active },
    { value: '3', text: Messages.Labels.Inactive },
    { value: '4', text: Messages.Labels.WaitForActivation },
];
export var clientBanTypes = [
    { value: 'ip', text: Messages.Labels.IpAddress },
    { value: 'id', text: Messages.Labels.ExternalID },
];
export var messengerProvider;
(function (messengerProvider) {
    messengerProvider["bulkGate"] = "Bulk Gate";
    messengerProvider["messageBird"] = "Message Bird";
    messengerProvider["bulkGateAndMessageBird"] = "Bulk Gate And Message Bird";
})(messengerProvider || (messengerProvider = {}));
export var availableCountriesBulkGate = [
    { value: 'US', text: Messages.Countries.UnitedStates },
    { value: 'CA', text: Messages.Countries.Canada },
    { value: 'AU', text: Messages.Countries.Australia },
    { value: 'CZ', text: Messages.Countries.CzechRepublic },
    { value: 'DK', text: Messages.Countries.Denmark },
    { value: 'HU', text: Messages.Countries.Hungary },
    { value: 'NL', text: Messages.Countries.Netherlands },
    { value: 'SE', text: Messages.Countries.Sweden },
    { value: 'GB', text: Messages.Countries.UnitedKingdom },
    { value: 'FR', text: Messages.Countries.France },
    { value: 'DE', text: Messages.Countries.Germany },
    { value: 'PL', text: Messages.Countries.Poland },
    { value: 'IT', text: Messages.Countries.Italy },
    { value: 'ES', text: Messages.Countries.Spain },
];
export var availableCountriesMessageBird = [
    { value: 'US', text: Messages.Countries.UnitedStates },
    { value: 'CA', text: Messages.Countries.Canada },
    { value: 'AU', text: Messages.Countries.Australia },
    { value: 'CZ', text: Messages.Countries.CzechRepublic },
    { value: 'DK', text: Messages.Countries.Denmark },
    { value: 'HU', text: Messages.Countries.Hungary },
    { value: 'NL', text: Messages.Countries.Netherlands },
    { value: 'SE', text: Messages.Countries.Sweden },
    { value: 'GB', text: Messages.Countries.UnitedKingdom },
];
export var smsInbox = {
    bulkgate: {
        applicationId: '',
        listCountries: [''],
        senderId: 0,
        applicationToken: '',
    },
    messagebird: {
        accessKey: '',
        listCountries: [],
    },
    brandId: '',
    messenger: 'sms',
    providerType: 1,
    status: 1,
};
export var clientHistoryValues = [
    { value: 'allDialogs', text: Messages.Labels.GetAllDialogs },
    { value: 'currentDialog', text: Messages.Labels.GetCurrentDialog },
    { value: 'localStorage', text: Messages.Labels.SaveInLocalStorage },
    { value: 'sessionStorage', text: Messages.Labels.SaveInSessionStorage },
];
export var requiredFieldsValues = [
    { value: 'email', text: Messages.Labels.Email },
    { value: 'firstName', text: Messages.Labels.Name },
    { value: 'lastName', text: Messages.Labels.LastName },
    { value: 'phone', text: Messages.Labels.Phone },
    { value: 'userName', text: Messages.Labels.UserName },
    { value: 'invoiceNumber', text: Messages.Labels.InvoiceNumber },
    { value: 'accountNumber', text: Messages.Labels.AccountNumber },
];
export var enabledLanguagesChannel = [
    { icon: localeFlags.en, value: 'en', text: Messages.Languages.English },
    { value: 'ru', text: Messages.Languages.Russian },
    { icon: localeFlags.de, value: 'de', text: Messages.Languages.Deutsch },
    { icon: localeFlags.es, value: 'es', text: Messages.Languages.Spanish },
    { icon: localeFlags.en, value: 'fr', text: Messages.Languages.French },
    { icon: localeFlags.pl, value: 'pl', text: Messages.Languages.Polish },
    { icon: localeFlags.pt, value: 'pt', text: Messages.Languages.Portuguese },
    { icon: localeFlags.vi, value: 'vi', text: Messages.Languages.Vietnamese },
    { icon: localeFlags.it, value: 'it', text: Messages.Languages.Italian },
    { icon: localeFlags.ko, value: 'ko', text: Messages.Languages.Korean },
    { icon: localeFlags.ar, value: 'ar', text: Messages.Languages.Arabic },
    { icon: localeFlags.ua, value: 'ua', text: Messages.Languages.Ukrainian },
    { icon: localeFlags.hu, value: 'hu', text: Messages.Languages.Hungarian },
];
export var greetingScheme = [
    { locale: 'ua', text: '', phrases: [] },
    { locale: 'ru', text: '', phrases: [] },
    { locale: 'en', text: '', phrases: [] },
    { locale: 'pl', text: '', phrases: [] },
    { locale: 'de', text: '', phrases: [] },
    { locale: 'es', text: '', phrases: [] },
    { locale: 'vi', text: '', phrases: [] },
    { locale: 'pt', text: '', phrases: [] },
    { locale: 'ko', text: '', phrases: [] },
    { locale: 'it', text: '', phrases: [] },
    { locale: 'ar', text: '', phrases: [] },
    { locale: 'fr', text: '', phrases: [] },
    { locale: 'hu', text: '', phrases: [] },
];
export var assignationTypeValues = [
    { value: 1, text: Messages.Controls.AllDialogsAreVisibleToAllAgents },
    { value: 2, text: Messages.Controls.DialogueIsAssignedAutomatically },
    { value: 3, text: Messages.Controls.AllDialogsAreVisibleToSeveralAgents },
];
export var CustomizationsModuleName = {
    header: Messages.Labels.HeaderColorScheme,
    greeting: Messages.Labels.GreetingColorScheme,
    quickPhrases: Messages.Labels.QuickPhrases,
    clientMsg: Messages.Labels.ClientMessage,
    agentMessage: Messages.Labels.AgentMessage,
    endDialogModal: Messages.Labels.EndDialogModal,
    icon: Messages.Labels.IconColorScheme,
    scroll: Messages.Labels.ScrollColorScheme,
    chatWindow: Messages.Labels.ChatWindowColor,
    footer: Messages.Labels.FooterColorScheme,
    auth: Messages.Labels.AuthColorScheme,
};
export var CustomizationsName = {
    headerBackground: Messages.Labels.HeaderBackground,
    headerBorderColor: Messages.Labels.HeaderBorderColor,
    scrollBackground: Messages.Labels.ScrollBackground,
    scrollThumbBackground: Messages.Labels.ScrollThumbBackground,
    colorGreetingBotName: Messages.Labels.ColorGreetingBotName,
    messageGreetingIconBackgroundBot: Messages.Labels.MessageGreetingIcon,
    messageGreetingColor: Messages.Labels.MessageGreetingColor,
    messageGreetingBorderColor: Messages.Labels.MessageGreetingBorderColor,
    messageGreetingBackgroundColor: Messages.Labels.MessageGreetingBackgroundColor,
    quickPhrasesColor: Messages.Labels.QuickPhrasesColor,
    quickPhrasesBackground: Messages.Labels.QuickPhrasesBackground,
    quickPhrasesBorderColor: Messages.Labels.QuickPhrasesBorderColor,
    quickPhrasesColorHover: Messages.Labels.QuickPhrasesColorHover,
    quickPhrasesBackgroundHover: Messages.Labels.QuickPhrasesBackgroundHover,
    quickPhrasesBorderColorHover: Messages.Labels.QuickPhrasesBorderColorHover,
    clientMessageColor: Messages.Labels.ClientMessageColor,
    clientTimeColor: Messages.Labels.ClientTimeColor,
    clientMessageBackground: Messages.Labels.ClientMessageBackground,
    clientMessageBorderColor: Messages.Labels.ClientMessageBorderColor,
    clientLink: Messages.Labels.ClientLink,
    clientHoverLink: Messages.Labels.ClientLinkHover,
    clientActiveLink: Messages.Labels.ClientActiveLink,
    clientVisitedLink: Messages.Labels.ClientVisitedLink,
    colorAgentNameAndTime: Messages.Labels.ColorAgentNameAndTime,
    messageAgentColor: Messages.Labels.MessageAgentColor,
    messageAgentBackgroundColor: Messages.Labels.MessageAgentBackgroundColor,
    messageAgentBorderColor: Messages.Labels.MessageAgentBorderColor,
    agentLink: Messages.Labels.AgentLink,
    agentHoverLink: Messages.Labels.AgentHoverLink,
    agentActiveLink: Messages.Labels.AgentActiveLink,
    agentVisitedLink: Messages.Labels.AgentVisitedLink,
    messageIconBackgroundAgent: Messages.Labels.MessageIconBackgroundAgent,
    textColor: Messages.Labels.TextColor,
    backdropColor: Messages.Labels.BackdropColor,
    backdropColorHover: Messages.Labels.BackdropColorHover,
    colorBtn: Messages.Labels.ButtonColor,
    backdropColorBtn: Messages.Labels.BackdropColorBtn,
    iconBackground: Messages.Labels.IconBackground,
    chatWindowsBodyBackground: Messages.Labels.ChatWindowsBodyBackground,
    chatWindowsBorderColor: Messages.Labels.ChatWindowsBorderColor,
    footerBackground: Messages.Labels.FooterBackground,
    footerBorderColor: Messages.Labels.FooterBorderColor,
    footerBackgroundTextArea: Messages.Labels.FooterBackgroundTextArea,
    inputBorderColor: Messages.Labels.InputBorderColor,
    registrationBackgroundColor: Messages.Labels.RegistrationBackgroundColor,
    labelColor: Messages.Labels.LabelColor,
    labelErrorColor: Messages.Labels.LabelErrorColor,
    inputColor: Messages.Labels.InputColor,
    inputBackground: Messages.Labels.InputBackground,
    signUpColor: Messages.Labels.SignUpColor,
    signUpBackground: Messages.Labels.SignUpBackground,
    backdropItemHoverColor: 'Backdrop item hover color',
};
export var AllWebChatWindows = {
    chat: Messages.Labels.Chat,
    authorisation: Messages.Labels.Authorization,
    minimised: Messages.Labels.Minimized,
};
export var clientsDialogsStatus = [
    { value: 'active', text: 'Active' },
    { value: 'new', text: 'New' },
    { value: 'assigned', text: 'Assigned' },
];
