import wretch from 'wretch';
import { getAuthCookie } from '@src/utils/cookie';
import { apiConfig } from '@src/utils/baseUrl';
var getMessagesByDialogId = function (_a) {
    var count = _a.count, offset = _a.offset, dialogId = _a.dialogId;
    return wretch("".concat(apiConfig.baseUrl, "/Message/messages/byDialogId?Count=").concat(count, "&Offset=").concat(offset, "&dialogId=").concat(dialogId))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getAudioCallsByDialogId = function (dialogId) { return wretch("".concat(apiConfig.baseUrl, "/Dialog/callRecords/byDialogId?dialogId=").concat(dialogId))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
export var dialogsApi = {
    getMessagesByDialogId: getMessagesByDialogId,
    getAudioCallsByDialogId: getAudioCallsByDialogId,
};
