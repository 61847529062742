import React, { useEffect, useRef } from 'react';
import { icons } from '@public/images/';
import { Messages } from '@public/locales/messages';
import ErrorMessage from '@src/components/ErrorMessage';
import { SelectedItem } from '@src/components/Select/SelectedItem/SelectedItem';
import { SelectList } from '@src/components/Select/SelectList/SelectList';
import { SelectWrapper, StyledSelectLabel } from '@src/components/Select/styled';
import { useSelect } from '@src/components/Select/useSelect';
import { useOutsideClick } from '@src/utils/useOutsideClick';
export var Select = function (_a) {
    var _b = _a.items, items = _b === void 0 ? [] : _b, _c = _a.error, error = _c === void 0 ? false : _c, stateCB = _a.stateCB, placeholder = _a.placeholder, _d = _a.initialState, initialState = _d === void 0 ? [] : _d, _e = _a.styles, styles = _e === void 0 ? {} : _e, _f = _a.label, label = _f === void 0 ? false : _f, _g = _a.isMultiply, isMultiply = _g === void 0 ? true : _g, _h = _a.searchKeyName, searchKeyName = _h === void 0 ? 'text' : _h, _j = _a.searchPlaceholder, searchPlaceholder = _j === void 0 ? Messages.Controls.Search : _j, _k = _a.disableRemoveItem, disableRemoveItem = _k === void 0 ? false : _k, disableRemoveItemFn = _a.disableRemoveItemFn, _l = _a.disableSearch, disableSearch = _l === void 0 ? false : _l;
    var ref = useRef(null);
    var _m = useSelect({
        stateCB: stateCB,
        initialState: initialState,
        isMultiply: isMultiply,
        items: items,
        searchKeyName: searchKeyName,
    }), height = _m.height, isOpen = _m.isOpen, setIsOpen = _m.setIsOpen, listItems = _m.listItems, setHeight = _m.setHeight, selectItem = _m.selectItem, removeItem = _m.removeItem, selectedItems = _m.selectedItems, filterSearchItems = _m.filterSearchItems, handleOpenOnSelectClick = _m.handleOpenOnSelectClick;
    useOutsideClick(function () {
        filterSearchItems('');
        setIsOpen(false);
    }, ref, ['customSelectListId'], true);
    useEffect(function () {
        var _a;
        if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.clientHeight) {
            setHeight(ref.current.clientHeight);
        }
    });
    return (React.createElement(React.Fragment, null,
        label && React.createElement(StyledSelectLabel, null, label),
        React.createElement(SelectWrapper, { id: "customSelectId", isOpen: isOpen, error: error, styles: styles, isMultiply: isMultiply, ref: ref, onClick: handleOpenOnSelectClick },
            selectedItems.length > 0 ? (React.createElement("div", { className: "select_items", id: "customSelectItemsId" }, selectedItems.map(function (item, index) { return (React.createElement(SelectedItem, { disableRemoveItemFn: disableRemoveItemFn, disableRemoveItem: disableRemoveItem, key: index, item: item, removeItem: removeItem, isMultiply: isMultiply })); }))) : (React.createElement("div", { id: "customSelectPlaceholderId", className: "select_placeholder" }, placeholder)),
            React.createElement("div", { className: "arrow_down_wrapper", onClick: function () { return setIsOpen(!isOpen); } },
                React.createElement("img", { src: icons.downArrowWhite, alt: "show" })),
            isOpen && (React.createElement(SelectList, { disableRemoveItemFn: disableRemoveItemFn, items: listItems, disableRemoveItem: disableRemoveItem, selectedItems: selectedItems, searchPlaceholder: searchPlaceholder, isMultiply: isMultiply, removeItem: removeItem, styles: styles, selectItem: selectItem, currentInputHeight: height, filterSearchItems: filterSearchItems, disableSearch: disableSearch }))),
        error && React.createElement(ErrorMessage, { text: Messages.Errors.FieldIsRequired })));
};
