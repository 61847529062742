import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var ReportsPageTypes = {
    SHOW_AGENT_REPORT: "".concat(reduxModules.reportsPage, "-currentReport/showAgentReport"),
    SET_AGENT_REPORTS: "".concat(reduxModules.reportsPage, "-currentReport/setAgentReports"),
    GET_AGENT_REPORTS: "".concat(reduxModules.reportsPage, "-currentReport/getAgentReports"),
};
var initialState = {
    agentReports: null,
    checkedReportBlockDay: null,
};
var reportsSlice = createSlice({
    name: reduxModules.reportsPage,
    initialState: initialState,
    reducers: {
        setAgentReports: function (state, action) {
            state.agentReports = action.payload;
        },
        setAgentReportBlockDay: function (state, action) {
            state.checkedReportBlockDay = action.payload;
        },
    },
});
export var reportsActions = {
    showReportByAgent: createAction(ReportsPageTypes.SHOW_AGENT_REPORT),
    setAgentReports: reportsSlice.actions.setAgentReports,
    setAgentReportBlockDay: reportsSlice.actions.setAgentReportBlockDay,
    getAgentReports: function (type, payload) { return ({
        type: ReportsPageTypes.GET_AGENT_REPORTS,
        payload: payload,
    }); },
};
export var reportsReducer = reportsSlice.reducer;
