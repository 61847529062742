import { notificationTypes, reduxModules } from '@src/global-interfaces';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    text: '',
    type: notificationTypes.default,
    isShowNotification: false,
    isShowConfirm: false,
    isOpenOverlay: false,
};
var NotificationSlice = createSlice({
    name: reduxModules.notification,
    initialState: initialState,
    reducers: {
        showConfirmModal: function (state, action) {
            var msg = action.payload.msg;
            state.isShowConfirm = true;
            state.text = msg;
            state.isOpenOverlay = true;
        },
        showNotification: function (state, action) {
            var _a = action.payload, msg = _a.msg, type = _a.type;
            state.isShowNotification = true;
            state.text = msg;
            state.type = type;
        },
        resetNotificationState: function (state) {
            state.text = '';
            state.type = notificationTypes.default;
            state.isShowNotification = false;
            state.isShowConfirm = false;
            state.isOpenOverlay = false;
        },
    },
});
export var notificationActions = {
    showConfirm: NotificationSlice.actions.showConfirmModal,
    showNotification: NotificationSlice.actions.showNotification,
    resetNotificationState: NotificationSlice.actions.resetNotificationState,
};
export var notificationReducer = NotificationSlice.reducer;
