export var formatterAudioForSlider = function (seconds) {
    if (!seconds)
        return '00m 00s';
    var duration = seconds;
    var hours = duration / 3600;
    duration %= 3600;
    var min = parseInt((duration / 60).toString(), 10);
    duration %= 60;
    var sec = parseInt(duration, 10);
    if (sec < 10) {
        sec = "0".concat(sec);
    }
    if (min < 10) {
        min = "0".concat(min);
    }
    if (parseInt(hours.toString(), 10) > 0) {
        return "".concat(parseInt(hours.toString(), 10), "h ").concat(min, "m ").concat(sec, "s");
    }
    if (min === 0) {
        return "00m ".concat(sec, "s");
    }
    return "".concat(min, "m ").concat(sec, "s");
};
