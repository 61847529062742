import React from 'react';
import { Messages } from '@public/locales/messages';
import { icons, messengers } from '@public/images';
import Button from '@src/components/Button';
import Header from '@src/modules/Header';
import Toolbar from '@src/modules/ToolBar';
import ClientInfo from '@src/modules/Pages/Clients/ClientInfo';
import CreateClient from '@src/modules/Pages/Clients/CreateClient';
import ClientsImport from '@src/modules/Pages/Clients/ClientsImport';
import TableComponent from '@src/modules/Pages/TableComponent';
import BulkMessaging from '@src/modules/Pages/Clients/BulkMessaging';
import { StyledContent, StyledPageWrapper } from '@src/app/styled';
import { StyledAddBtnWithImg, StyledBulkMessagingBtn, StyledCreateBtnWithImg } from '@src/components/Button/styled';
var downloadIcon = icons.downloadIcon, clientIcon = icons.clientIcon;
var ClientsPage = function (props) {
    var currentPage = props.currentPage, isModalOpen = props.isModalOpen, isCreteClientOpen = props.isCreteClientOpen, isCreteImportClient = props.isCreteImportClient, isModalOpenBulkMessaging = props.isModalOpenBulkMessaging, toggleModalBulkMessaging = props.toggleModalBulkMessaging, toggleModalImportClient = props.toggleModalImportClient, toggleModalCreateClient = props.toggleModalCreateClient;
    return (React.createElement(StyledPageWrapper, { className: "clients-page" },
        React.createElement(Header, null),
        React.createElement(StyledContent, { currentPage: currentPage },
            React.createElement("div", { className: "page-btn-wrapper" },
                React.createElement(StyledCreateBtnWithImg, { onClick: toggleModalCreateClient },
                    React.createElement("img", { src: clientIcon, onClick: function () { }, alt: "clientIcon" }),
                    React.createElement(Button, { title: Messages.Buttons.createClient, onClick: function () { } })),
                React.createElement(StyledAddBtnWithImg, { onClick: toggleModalImportClient },
                    React.createElement("img", { src: downloadIcon, onClick: function () { }, alt: "downloadIcon" }),
                    React.createElement(Button, { title: Messages.Buttons.importClient, onClick: function () { } })),
                React.createElement(StyledBulkMessagingBtn, { onClick: toggleModalBulkMessaging },
                    React.createElement("img", { src: messengers.smsIcon, onClick: function () { }, alt: "bulkMessaging" }),
                    React.createElement(Button, { title: Messages.Buttons.bulkMessaging, onClick: function () { } }))),
            React.createElement(Toolbar, null),
            React.createElement(TableComponent, null)),
        isModalOpen && React.createElement(ClientInfo, null),
        isCreteClientOpen && React.createElement(CreateClient, null),
        isCreteImportClient && React.createElement(ClientsImport, null),
        isModalOpenBulkMessaging && React.createElement(BulkMessaging, null)));
};
export default ClientsPage;
