var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
var VERY_DARK_BLUE = COLORS.VERY_DARK_BLUE;
export var StyledUpdateBrandWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  max-height: 55vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  max-height: 55vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledClientBanTimeWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    padding: 5px 0;\n\n    input {\n      margin-left: 5px;\n      border-radius: 4px;\n      padding: 5px 5px 5px 8px;\n      width: 45px;\n      height: 30px;\n      color: ", ";\n      ::placeholder {\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  && {\n    padding: 5px 0;\n\n    input {\n      margin-left: 5px;\n      border-radius: 4px;\n      padding: 5px 5px 5px 8px;\n      width: 45px;\n      height: 30px;\n      color: ", ";\n      ::placeholder {\n        color: ", ";\n      }\n    }\n  }\n"])), VERY_DARK_BLUE, VERY_DARK_BLUE);
export var StyledBrandStatusWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n  }\n"], ["\n  && {\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
