import { connect } from 'react-redux';
import { loadingStore } from '@src/redux/loading/selector';
import { brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import BulkGateAndMessageBird from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/BulkGateAndMessageBird/BulkGateAndMessageBird';
var mapStateToProps = function (state) { return ({
    isLoadingCreateInbox: loadingStore.getIsLoadingCreateInbox(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    createSmsInbox: function (data) { return dispatch(brandsActions.createSmsInbox(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(BulkGateAndMessageBird);
