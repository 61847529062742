var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Messages } from '@public/locales/messages';
export var mapAgentsData = function (agents, dictionaries) {
    var groupDictionary = dictionaries.groupDictionary;
    var formatAgentGroups = function (agentGroupIds) {
        var groupNames = [];
        if (agentGroupIds && !!(agentGroupIds === null || agentGroupIds === void 0 ? void 0 : agentGroupIds.length)) {
            agentGroupIds.map(function (agentGroupId) {
                if (groupDictionary[agentGroupId]) {
                    groupNames.push(groupDictionary[agentGroupId]);
                }
            });
        }
        return groupNames;
    };
    return agents.map(function (agent, index) { return (__assign(__assign({}, agent), { forActions: {
            id: agent.id,
        }, index: ++index || 'cart', name: {
            name: agent.name,
            avatar: agent.avatar,
        }, sortTagsCount: agent.tags.length || Messages.Controls.NoData, created_at: agent.createdAt || Messages.Controls.NoData, lastActivity: agent.lastActivity || Messages.Controls.NoData, groups: formatAgentGroups(agent.groupIds) || Messages.Controls.NoData })); });
};
