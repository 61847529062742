import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import GroupsCreateModal from '@src/modules/Pages/Groups/GroupsCreateModal/GroupsCreateModal';
var mapStateToProps = function (state) { return ({
    tagDictionary: dictionariesStore.dictionaries(state).tagDictionary,
    channelDictionary: dictionariesStore.dictionaries(state).channelDictionary,
    isLoadingCreateGroups: loadingStore.getIsLoadingCreateGroups(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_GROUPS_MODAL, null)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    createAgentGroups: function (data) { return dispatch(groupsActions.createAgentGroups(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GroupsCreateModal);
