import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import GroupsUsefulPhrases from './GroupsUsefulPhrases';
var mapStateToProps = function (state) { return ({
    currentGroup: groupsStore.getCurrentGroup(state),
    isNewPhraseGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.NEW_PHRASE_GROUPS),
    isEditPhraseGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.EDIT_PHRASE_GROUPS),
    isNewCategoryGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.NEW_CATEGORY_GROUPS),
    isEditCategoryGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.EDIT_CATEGORY_GROUPS),
    isClearCategoriesGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.CLEAR_CATEGORIES_GROUPS),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    deletePhrase: function (data) { return dispatch(groupsActions.deletePhrase(data)); },
    deleteCategory: function (id) { return dispatch(groupsActions.deleteCategory(id)); },
    deleteAllCategory: function () { return dispatch(groupsActions.deleteAllCategory()); },
    toggleModalNewPhrase: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.NEW_PHRASE_GROUPS, null)); },
    toggleModalEditPhrase: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.EDIT_PHRASE_GROUPS, null)); },
    toggleModalNewCategory: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.NEW_CATEGORY_GROUPS, null)); },
    toggleModalEditCategory: function (categoryId) { return dispatch(modalWindowsActions.toggleModal(modalTypes.EDIT_CATEGORY_GROUPS, categoryId)); },
    toggleModalClearCategories: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CLEAR_CATEGORIES_GROUPS, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GroupsUsefulPhrases);
