import React from 'react';
import { icons } from '@public/images';
export var SelectedItem = function (_a) {
    var _b = _a.item, item = _b === void 0 ? {} : _b, removeItem = _a.removeItem, isMultiply = _a.isMultiply, disableRemoveItem = _a.disableRemoveItem, disableRemoveItemFn = _a.disableRemoveItemFn;
    var text = item.text, value = item.value, icon = item.icon;
    return (React.createElement("div", { className: "select_items__item" },
        icon && (React.createElement("div", { className: "select_items__item-flag" },
            React.createElement("img", { src: icon, alt: "flag" }))),
        React.createElement("div", { className: "select_items__item-text" }, text),
        isMultiply && React.createElement("div", { className: "select_items__item-close" },
            React.createElement("img", { src: icons.ex, id: "customSelectIdRemoveIcon", alt: "remove", onClick: function () { return (!disableRemoveItem ? removeItem(value) : disableRemoveItemFn()); } }))));
};
