import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { clientsActions } from '@src/modules/Pages/Clients/redux/reducer';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import ClientsImport from '@src/modules/Pages/Clients/ClientsImport/ClientsImport';
var mapStateToProps = function (state) { return ({
    brandDictionary: dictionariesStore.dictionaries(state).brandDictionary,
    isLoadingImportClients: loadingStore.getIsLoadingImportClients(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.IMPORT_CLIENTS, null)); },
    getBrandSettings: function (id) { return dispatch(clientsActions.getBrandSettings(id)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    importClients: function (clients) { return dispatch(clientsActions.importClients(clients)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ClientsImport);
