import { createSelector } from '@reduxjs/toolkit';
var groupsState = function (state) { return state.pages.groups; };
var groupsStore = {
    getCurrentGroup: createSelector(groupsState, function (state) { return state.currentGroup; }),
    getCurrentGroupsAgents: createSelector(groupsState, function (state) { return state.currentGroupsAgents; }),
    getAssignedAgentsToGroup: createSelector(groupsState, function (state) { return state.assignedAgentsToGroup; }),
    getUnassignedAgentsToGroup: createSelector(groupsState, function (state) { return state.unassignedAgentsToGroup; }),
    getAssignedTagsToGroup: createSelector(groupsState, function (state) { return state.assignedTagsToGroup; }),
    getUnassignedTagsToGroup: createSelector(groupsState, function (state) { return state.unassignedTagsToGroup; }),
    getAssignedChannelsToGroup: createSelector(groupsState, function (state) { return state.assignedChannelsToGroup; }),
    getUnassignedChannelsToGroup: createSelector(groupsState, function (state) { return state.unassignedChannelsToGroup; }),
};
export default groupsStore;
