var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Steps as ProgressSteps } from 'antd';
import AccountNumber from '../AccountNumber/AccountNumber';
import Email from '../Email/Email';
import FirstName from '../FirstName/FirstName';
import InvoiceNumber from '../InvoiceNumber/InvoiceNumber';
import LastName from '../LastName/LastName';
import Phone from '../Phone/Phone';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import UserName from '../UserName/UserName';
var Steps = function (props) {
    var privacyPolicy = props.privacyPolicy, auth = props.auth, requiredFields = props.requiredFields, lang = props.lang;
    var _a = __read(useState(1), 2), current = _a[0], setCurrent = _a[1];
    var _b = __read(useState(false), 2), isPrivacyPolicy = _b[0], setIsPrivacyPolicy = _b[1];
    var stepsLength = Object.entries(requiredFields)
        .filter(function (el) { return el[1]; })
        .map(function (el) { return [el[0], '']; }).length + 1;
    var inputProps = {
        auth: auth,
        lang: lang,
        current: current,
        setCurrent: setCurrent,
        stepsLength: stepsLength,
        privacyPolicy: privacyPolicy,
        isPrivacyPolicy: isPrivacyPolicy,
        PP: privacyPolicy.enabled && current + 1 === stepsLength && (React.createElement(PrivacyPolicy, { privacyPolicy: privacyPolicy, isPrivacyPolicy: isPrivacyPolicy, lang: 'en', setIsPrivacyPolicy: setIsPrivacyPolicy })),
    };
    var steps = [
        React.createElement("div", null),
        requiredFields.email && React.createElement(Email, __assign({}, inputProps)),
        requiredFields.firstName && React.createElement(FirstName, __assign({}, inputProps)),
        requiredFields.lastName && React.createElement(LastName, __assign({}, inputProps)),
        requiredFields.phone && React.createElement(Phone, __assign({}, inputProps)),
        requiredFields.userName && React.createElement(UserName, __assign({}, inputProps)),
        requiredFields.accountNumber && React.createElement(AccountNumber, __assign({}, inputProps)),
        requiredFields.invoiceNumber && React.createElement(InvoiceNumber, __assign({}, inputProps)),
    ].filter(Boolean);
    return (React.createElement(React.Fragment, null,
        React.createElement(ProgressSteps, { current: current }, steps.map(function (item, index) { return (React.createElement(ProgressSteps.Step, { key: index })); })),
        React.createElement("div", { className: "steps-content_wrap" }, steps[current])));
};
export default Steps;
