var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { StyledTabs } from '@src/components/Tabs/styled';
export var Tabs = function (_a) {
    var children = _a.children, callback = _a.callback, currentTab = _a.currentTab, isShowBottomLine = _a.isShowBottomLine, callbackTwo = _a.callbackTwo;
    var _b = __read(useState(currentTab || children[0].props.tabKey), 2), activeTab = _b[0], setActiveTab = _b[1];
    useEffect(function () {
        if (!currentTab) {
            callback(children[0].props.tabKey);
        }
    }, []);
    var onClickTabItem = function (tab) {
        setActiveTab(tab);
        callbackTwo && callbackTwo();
        callback(tab);
    };
    return (React.createElement(StyledTabs, { isShowBottomLine: isShowBottomLine },
        React.createElement("ul", { className: "tab-list" }, children.map(function (_a) {
            var _b = _a.props, tabKey = _b.tabKey, title = _b.title;
            return (React.createElement("li", { className: "tab-list-item ".concat(activeTab === tabKey && 'tab-list-active'), key: tabKey, onClick: function () { return onClickTabItem(tabKey); } }, title));
        })),
        React.createElement("div", { className: "tab-content" }, children.map(function (child) {
            if (activeTab === child.props.tabKey)
                return child;
        }))));
};
export var Tab = function (_a) {
    var children = _a.children, tabKey = _a.tabKey, title = _a.title;
    return React.createElement(React.Fragment, null, children);
};
