export var transformSortingParameter = function (parameter) {
    var sortingParameter;
    switch (parameter) {
        case 'lastActivity':
            sortingParameter = 'last_activity';
            break;
        case 'createdAt':
            sortingParameter = 'created_at';
            break;
        case 'updatedAt':
            sortingParameter = 'updated_at';
            break;
        case 'firstName':
            sortingParameter = 'first_name';
            break;
        case 'lastName':
            sortingParameter = 'last_name';
            break;
        case 'dialogsCount':
            sortingParameter = 'dialogs_count';
            break;
        case 'agentAssigned':
            sortingParameter = 'agent_assigned';
            break;
        case 'entryDate':
            sortingParameter = 'entry_date';
            break;
        case 'lastMsgTime':
            sortingParameter = 'last_msg_time';
            break;
        case 'username':
            sortingParameter = 'username';
            break;
        case 'email':
            sortingParameter = 'email';
            break;
        case 'id':
            sortingParameter = 'id';
            break;
        case 'key':
            sortingParameter = 'key';
            break;
        case 'name':
            sortingParameter = 'name';
            break;
        case 'enabledLang':
            sortingParameter = 'language';
            break;
        case 'url':
            sortingParameter = 'url';
            break;
        case 'clientUniqueness':
            sortingParameter = 'client_uniqueness';
            break;
        case 'receivedCount':
            sortingParameter = 'ReceivedCount';
            break;
        case 'positivePercent':
            sortingParameter = 'PositivePercent';
            break;
        case 'negativePercent':
            sortingParameter = 'NegativePercent';
            break;
        case 'unratedPercent':
            sortingParameter = 'UnratedDialogs';
            break;
        case 'avgResponseTime':
            sortingParameter = 'AvgResponseTime';
            break;
        case 'missedPercent':
            sortingParameter = 'MissedPercent';
            break;
        case 'status':
            sortingParameter = 'status';
            break;
        case 'description':
            sortingParameter = 'description';
            break;
        default:
            sortingParameter = 'created_at';
    }
    return sortingParameter;
};
