var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { isEqual } from 'lodash';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { Accordion } from '@src/components/Accordion/Accordion';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledAddNewBtn, StyledSaveBtn } from '@src/components/Button/styled';
import { getDefaultCustomizations } from '@src/constants/defaultCustomizations';
import GeneralSettings from '@src/modules/Pages/Channels/ChannelUpdate/components/GeneralSettings';
import PipeDrive from '@src/modules/Pages/Channels/ChannelUpdate/components/IntegrationCRM/PipeDrive';
import WebChatConstructor from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatConstructor';
import WebChatSettings from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatSettings';
import LayoutWebChat from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatWidows/LayoutWebChat';
import { StyledBackToChannels, StyledChannelSettingsAccordionWrapper, StyledChannelSettingsBtnWrapper, StyledChannelSettingsWrapper, StyledChannelUpdateContent, StyledChannelUpdateContentWrapper, StyledIntegrationCRMWrapper, } from '@src/modules/Pages/Channels/ChannelUpdate/styled';
var ChannelUpdate = function (props) {
    var toggleModal = props.toggleModal, currentChannel = props.currentChannel, updateChannel = props.updateChannel, isLoadingLayoutWebChat = props.isLoadingLayoutWebChat, updateDefaultCustomizations = props.updateDefaultCustomizations;
    var _a = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isCaptcha) || false), 2), isCaptcha = _a[0], setIsCaptcha = _a[1];
    var _b = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.gettingClientHistory) || ''), 2), clientHistory = _b[0], setClientHistory = _b[1];
    var _c = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.groupIds) || []), 2), selectedGroups = _c[0], setSelectedGroups = _c[1];
    var _d = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.assignationType) || 0), 2), assignationType = _d[0], setAssignationType = _d[1];
    var _e = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.enabledLang) || []), 2), selectedLanguages = _e[0], setSelectedLanguages = _e[1];
    var _f = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.showAllClientDialogs) || false), 2), showAllClientDialogs = _f[0], setShowAllClientDialogs = _f[1];
    var _g = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.customizations) || {}), 2), customizations = _g[0], setCustomizations = _g[1];
    var _h = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.requiredFields) || {}), 2), requiredFields = _h[0], setRequiredFields = _h[1];
    var _j = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.privacyPolicy) || {
        data: null,
        enabled: false,
    }), 2), privacyPolicy = _j[0], setPrivacyPolicy = _j[1];
    var _k = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.greeting) || []), 2), greeting = _k[0], setGreeting = _k[1];
    var _l = __read(useState((currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.botNameLocales) || {}), 2), botNameLocales = _l[0], setBotNameLocales = _l[1];
    var _m = __read(useState(0), 2), positionScroll = _m[0], setPositionScroll = _m[1];
    var getInitHeaderText = function () {
        var tempObj = {};
        for (var prop in customizations.header.headerText) {
            tempObj["header-headerTextTop/".concat(prop)] = customizations.header.headerText[prop].headerTextTop;
            tempObj["header-headerTextBottom/".concat(prop)] = customizations.header.headerText[prop].headerTextBottom;
        }
        return tempObj;
    };
    var getInitGreetingAndPhrase = function () {
        var tempObj = {};
        greeting.forEach(function (elem) {
            tempObj["greetings/".concat(elem.locale)] = elem.text;
            elem.phrases.forEach(function (el, index) {
                tempObj["fastPhrase\u2116".concat(index + 1, "/").concat(elem.locale)] = el;
            });
        });
        return tempObj;
    };
    var getInitPrivacyPolicy = function () {
        if (!(privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.data))
            return;
        var tempObj = {};
        for (var prop in privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.data) {
            tempObj["policy-text/".concat(prop)] = privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.data[prop].text;
            tempObj["policy-link/".concat(prop)] = privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.data[prop].link;
            tempObj["policy-linkText/".concat(prop)] = privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.data[prop].linkText;
        }
        return tempObj;
    };
    var getInitBotName = function () {
        if (!botNameLocales)
            return;
        var tempObj = {};
        for (var prop in botNameLocales) {
            tempObj["botNameLocales/".concat(prop)] = botNameLocales[prop];
        }
        return tempObj;
    };
    var getInitValueForm = function () { return (__assign(__assign(__assign(__assign({ channelName: (currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.name) || '', channelURL: (currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.url) || '', logoIconURL: customizations.header.logoUrl || '', iconUrl: customizations.icon.iconUrl || '', chatWindowsLogo: customizations.chatWindow.chatWindowsLogo || '', messageGreetingIconUrlBot: customizations.greeting.messageGreetingIconUrlBot || '', messageIconUrlAgent: customizations.agentMessage.messageIconUrlAgent || '', inputMessagesPlaceholder: customizations.footer.inputPlaceholder || '', sendImageUrl: customizations.footer.sendImage || '', sendFileUrl: customizations.footer.sendFileImage || '', muteIconUrl: customizations.moreMenu.muteIcon || '', unionIconUrl: customizations.moreMenu.unionIcon || '', exitIconUrl: customizations.moreMenu.exitIcon || '', unMuteIconUrl: customizations.moreMenu.unMuteIcon || '', minimizeIconUrl: customizations.header.minimizeIcon || '', moreIconUrl: customizations.header.moreIcon || '' }, getInitBotName()), getInitHeaderText()), getInitPrivacyPolicy()), getInitGreetingAndPhrase())); };
    var onSubmit = function (data) {
        updateChannel({
            url: data.channelURL,
            name: data.channelName,
            greeting: greeting,
            isCaptcha: isCaptcha,
            clientHistory: clientHistory,
            selectedGroups: selectedGroups,
            assignationType: assignationType,
            selectedLanguages: selectedLanguages,
            showAllClientDialogs: showAllClientDialogs,
            customizations: customizations,
            privacyPolicy: privacyPolicy,
            botNameLocales: botNameLocales,
            requiredFields: requiredFields,
        });
    };
    var setDefaultSettings = function () {
        setCustomizations(getDefaultCustomizations());
        updateDefaultCustomizations(getDefaultCustomizations());
    };
    var ref = useRef(null);
    var onMouseScrollHandler = function () {
        if (ref.current) {
            setPositionScroll(Math.round(ref.current.scrollTop));
        }
    };
    return (React.createElement(StyledChannelUpdateContentWrapper, { ref: ref, onScroll: onMouseScrollHandler },
        React.createElement(StyledChannelUpdateContent, null,
            React.createElement(StyledChannelSettingsWrapper, null,
                React.createElement(StyledBackToChannels, { onClick: toggleModal },
                    React.createElement("img", { src: icons.downArrow, alt: "downArrow" }),
                    React.createElement("div", null, Messages.Buttons.backToChannels)),
                React.createElement(Form, { initialValues: getInitValueForm(), onSubmit: onSubmit, render: function (_a) {
                        var handleSubmit = _a.handleSubmit, getState = _a.form.getState;
                        return (React.createElement("form", { onSubmit: handleSubmit },
                            React.createElement(StyledChannelSettingsBtnWrapper, null,
                                React.createElement("div", { className: "title" }, Messages.Labels.ChannelSettings),
                                React.createElement("div", { className: "btn-wrapper" },
                                    React.createElement(StyledAddNewBtn, null,
                                        React.createElement(Button, { title: Messages.Buttons.defaultSettings, onClick: setDefaultSettings })),
                                    React.createElement(StyledSaveBtn, null,
                                        React.createElement(Button, { type: TButtonTypes.submit, title: Messages.Buttons.upgradeChannel, onClick: function () { } })))),
                            React.createElement(StyledChannelSettingsAccordionWrapper, null,
                                React.createElement(Accordion, { title: Messages.Labels.GeneralSettings, isPadding: true, isBoldTitle: true, isBorderBottom: true, secondaryTitle: "".concat(Messages.Controls.Name, ",").concat(Messages.Labels.URL) },
                                    React.createElement(GeneralSettings, { customizations: customizations, setCustomizations: setCustomizations, channelId: (currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.id) || '', showAllClientDialogs: showAllClientDialogs, isCaptcha: isCaptcha, setShowAllClientDialogs: setShowAllClientDialogs, setIsCaptcha: setIsCaptcha, selectedGroups: selectedGroups, setSelectedGroups: setSelectedGroups, selectedLanguages: selectedLanguages, setSelectedLanguages: function (value) { return setSelectedLanguages(value); }, assignationType: assignationType, setAssignationType: setAssignationType, clientHistory: clientHistory, setClientHistory: setClientHistory })),
                                React.createElement(Accordion, { title: Messages.Labels.WebChatConstructor, isPadding: true, isBoldTitle: true, isBorderBottom: true, secondaryTitle: "".concat(Messages.Labels.BackgroundColor, ", ").concat(Messages.Labels.MessageColor, "...") },
                                    React.createElement(WebChatConstructor, { getState: getState, selectedLanguages: selectedLanguages, customizations: customizations, setCustomizations: setCustomizations })),
                                React.createElement(Accordion, { title: Messages.Labels.WebChatSettings, isPadding: true, isBoldTitle: true, isBorderBottom: true, secondaryTitle: "".concat(Messages.Labels.Greetings, ", ").concat(Messages.Labels.QuickReplies, ", ").concat(Messages.Labels.BotSettings, "...") },
                                    React.createElement(WebChatSettings, { getState: getState, selectedLanguages: selectedLanguages, privacyPolicy: privacyPolicy, greeting: greeting, botNameLocales: botNameLocales, setBotNameLocales: setBotNameLocales, setGreeting: setGreeting, setPrivacyPolicy: setPrivacyPolicy, requiredFields: requiredFields, setRequiredFields: setRequiredFields })),
                                React.createElement(Accordion, { title: Messages.Labels.IntegrationWithOtherCRM, isPadding: true, isBoldTitle: true, isBorderBottom: true, secondaryTitle: "".concat(Messages.Labels.SettingUpInteractionBetweenSystems, "...") },
                                    React.createElement(StyledIntegrationCRMWrapper, null,
                                        React.createElement(PipeDrive, null))))));
                    } })),
            React.createElement(LayoutWebChat, { positionScroll: positionScroll, greeting: greeting, privacyPolicy: privacyPolicy, botNameLocales: botNameLocales, customizations: customizations, requiredFields: requiredFields, selectedLanguages: selectedLanguages, isLoadingLayoutWebChat: isLoadingLayoutWebChat }))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(ChannelUpdate, areEqual);
