var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledCreateGroupsModalWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 40vw;\n  padding: 10px 0 10px 15px;\n  ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n"], ["\n  width: 40vw;\n  padding: 10px 0 10px 15px;\n  ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n"])), function (_a) {
    var isLoadingCreateGroups = _a.isLoadingCreateGroups;
    return isLoadingCreateGroups && 'height 50vh';
});
export var StyledCreateGroupsModalContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    max-height: 50vh;\n    padding-right: 15px;\n    overflow: auto;\n    ", "\n  }\n"], ["\n  && {\n    display: flex;\n    max-height: 50vh;\n    padding-right: 15px;\n    overflow: auto;\n    ", "\n  }\n"])), scrollStyles);
export var StyledCreateGroupInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"], ["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"])));
export var StyledCreateGroupSelect = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    padding-bottom: ", ";\n  }\n"], ["\n  && {\n    padding-bottom: ", ";\n  }\n"])), function (_a) {
    var padding = _a.padding;
    return (padding ? '8px' : '0px');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
