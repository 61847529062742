import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var ToolBarTypes = {
    ADD_FILTERS: "".concat(reduxModules.toolBar, "/addFilters"),
    DELETE_FILTERS: "".concat(reduxModules.toolBar, "/deleteFilters"),
    RESET_FILTERS: "".concat(reduxModules.toolBar, "/resetFilters"),
    CHANGE_SHOW_SELECTED_FILTER: "".concat(reduxModules.toolBar, "/changeShowSelectedFilter"),
    SAVE_VALUE_SELECTED_FILTER: "".concat(reduxModules.toolBar, "/saveValueSelectedFilter"),
    DELETE_VALUE_SELECTED_FILTER: "".concat(reduxModules.toolBar, "/deleteValueSelectedFilter"),
};
var initialState = {
    filters: {},
    showSelectedFilter: null,
    selectedFilter: {},
};
var toolBarSlice = createSlice({
    name: reduxModules.toolBar,
    initialState: initialState,
    reducers: {
        setFilters: function (state, action) {
            state.filters = action.payload;
        },
        setShowSelectedFilter: function (state, action) {
            state.showSelectedFilter = action.payload;
        },
        setSelectedFilter: function (state, action) {
            state.selectedFilter = action.payload;
        },
    },
});
export var toolBarActions = {
    setFilters: toolBarSlice.actions.setFilters,
    setShowSelectedFilter: toolBarSlice.actions.setShowSelectedFilter,
    setSelectedFilter: toolBarSlice.actions.setSelectedFilter,
    addFilters: createAction(ToolBarTypes.ADD_FILTERS),
    saveValueSelectedFilter: createAction(ToolBarTypes.SAVE_VALUE_SELECTED_FILTER),
    deleteValueSelectedFilter: createAction(ToolBarTypes.DELETE_VALUE_SELECTED_FILTER),
    deleteFilters: createAction(ToolBarTypes.DELETE_FILTERS),
    resetFilters: createAction(ToolBarTypes.RESET_FILTERS),
    changeShowSelectedFilter: createAction(ToolBarTypes.CHANGE_SHOW_SELECTED_FILTER),
};
export var toolBarReducer = toolBarSlice.reducer;
