var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useMemo, useCallback, } from 'react';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import Icon from '@src/components/Icon';
import Button from '@src/components/Button';
import Select from '@src/components/Select';
import Loader from '@src/components/Loader';
import ModalWindow from '@src/components/ModalWindow';
import { urlValidation } from '@src/utils/validations';
import { StyledAddNewBtn, StyledSaveBtn } from '@src/components/Button/styled';
import { StyledAgentName, StyledRemoveAgentGroups, StyledAgentGroupsWrapper, StyledAddAgentGroupsWrapper, } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsAgents/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var GroupsAgents = function (props) {
    var modalInfo = props.modalInfo, toggleModal = props.toggleModal, setIsOpenTwoModal = props.setIsOpenTwoModal, currentGroupsAgents = props.currentGroupsAgents, removeAgentFromGroup = props.removeAgentFromGroup, assignedAgentsToGroup = props.assignedAgentsToGroup, unassignedAgentsToGroup = props.unassignedAgentsToGroup, removeOrAddAgentsFromGroup = props.removeOrAddAgentsFromGroup, isLoadingChangeAgentsGroups = props.isLoadingChangeAgentsGroups, isModalOpenAddAgentsToGroups = props.isModalOpenAddAgentsToGroups;
    var mapAgentForSelect = function (agents) { return (agents === null || agents === void 0 ? void 0 : agents.map(function (el) { return ({ text: el.name, value: el.id }); })) || []; };
    var memoizedAssignedAgentsGroups = useMemo(function () { return mapAgentForSelect(assignedAgentsToGroup); }, [assignedAgentsToGroup]);
    var memoizedUnassignedAgentsGroups = useMemo(function () { return mapAgentForSelect(unassignedAgentsToGroup); }, [unassignedAgentsToGroup]);
    var _a = __read(useState(memoizedAssignedAgentsGroups), 2), agentsGroups = _a[0], setAgentsGroups = _a[1];
    var onChangeSelectAgent = useCallback(function (data) { return setAgentsGroups(data); }, [agentsGroups]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledAddNewBtn, null,
            React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addNew), onClick: function () {
                    toggleModal();
                    setIsOpenTwoModal(true);
                } })),
        currentGroupsAgents && currentGroupsAgents.map(function (el, index) {
            var isAvatar = urlValidation(el.avatar);
            return (React.createElement(StyledAgentGroupsWrapper, { key: index },
                React.createElement(StyledAgentName, { isAvatar: isAvatar },
                    React.createElement(Icon, { src: isAvatar ? el.avatar : icons.defaultAvatar, alt: "Avatar" }),
                    React.createElement("div", { className: "agent-name-text" }, el.name)),
                React.createElement(StyledRemoveAgentGroups, { onClick: function () { return removeAgentFromGroup({ agentsId: el.id, groupId: modalInfo }); } }, Messages.Buttons.remove)));
        }),
        isModalOpenAddAgentsToGroups && (React.createElement(ModalWindow, { closeModalWindow: function () {
                toggleModal();
                setIsOpenTwoModal(false);
            }, title: Messages.Labels.AddAgentToGroup },
            React.createElement(StyledAddAgentGroupsWrapper, null, isLoadingChangeAgentsGroups ? (React.createElement(Loader, null)) : (React.createElement(StyledModalContentWrapper, null,
                React.createElement(StyledModalContentFieldsWrapper, null,
                    React.createElement(Select, { label: Messages.Labels.SelectAgents, items: __spreadArray(__spreadArray([], __read(memoizedAssignedAgentsGroups), false), __read(memoizedUnassignedAgentsGroups), false), initialState: agentsGroups, placeholder: Messages.Selects.SelectAgents, stateCB: function (el) { return onChangeSelectAgent(el); } })),
                React.createElement(StyledModalFooterOneBtnWrapper, null,
                    React.createElement(StyledSaveBtn, null,
                        React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { return removeOrAddAgentsFromGroup(agentsGroups); } }))))))))));
};
export default GroupsAgents;
