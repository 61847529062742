var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledCreateChannelWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 45vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 45vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledChannelInput = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    .input-container {\n      background: ", ";\n    }\n    input {\n      background: ", ";\n      height: 32px;\n    }\n  }\n"], ["\n  && {\n    .input-container {\n      background: ", ";\n    }\n    input {\n      background: ", ";\n      height: 32px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.selectBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.selectBackground;
});
export var StyledStepWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    padding-top: 50px;\n    height: 100%;\n  }\n"], ["\n  && {\n    padding-top: 50px;\n    height: 100%;\n  }\n"])));
export var StyledFastPhraseWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    padding-top: 10px;\n    height: 80%;\n  }\n"], ["\n  && {\n    padding-top: 10px;\n    height: 80%;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
