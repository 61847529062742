import { connect } from 'react-redux';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { agentsActions } from '@src/modules/Pages/Agents/redux/reducer';
import AgentToTags from './AgentToTags';
var mapStateToProps = function (state) { return ({
    tagsCurrentAgent: agentsStore.getTagsCurrentAgent(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    addAndDeleteAgentTags: function (data) { return dispatch(agentsActions.addAndDeleteAgentTags(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AgentToTags);
