var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var DARK_GREY = COLORS.DARK_GREY;
export var SearchInputWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  position: relative;\n  & > input {\n    background: ", ";\n    padding-right: 30px;\n  }\n  & > img {\n    cursor: pointer;\n    position: absolute;\n    right: 10px;\n    top: 25%;\n  }\n"], ["\n  color: ", ";\n  position: relative;\n  & > input {\n    background: ", ";\n    padding-right: 30px;\n  }\n  & > img {\n    cursor: pointer;\n    position: absolute;\n    right: 10px;\n    top: 25%;\n  }\n"])), DARK_GREY, function (_a) {
    var theme = _a.theme;
    return theme.colors.selectBackground;
});
var templateObject_1;
