import React from 'react';
import { Field, Form } from 'react-final-form';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import ModalWindow from '@src/components/ModalWindow';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isCorrectEmail, isRequired, isSpaces, lengthCreator, } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledBrandInput } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { StyledCreateMailInboxWrapper } from '@src/modules/Pages/Brands/BrandSettings/CreateMailInbox/styled';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import { Messages } from '@public/locales/messages';
var CreateMailInbox = function (props) {
    var toggleModal = props.toggleModal, createMailInbox = props.createMailInbox, showNotification = props.showNotification, setIsOpenTwoModal = props.setIsOpenTwoModal, isLoadingCreateInbox = props.isLoadingCreateInbox, resetNotificationState = props.resetNotificationState;
    var maxEmailLength = lengthCreator.maxEmailLength, minEmailLength = lengthCreator.minEmailLength;
    var closeModal = function () {
        toggleModal();
        setIsOpenTwoModal(false);
    };
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onSubmit = function (data) {
        createMailInbox(data.mailInbox);
        setIsOpenTwoModal(false);
    };
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: Messages.Labels.CreateMailInbox },
        React.createElement(StyledCreateMailInboxWrapper, null, isLoadingCreateInbox ? (React.createElement(Loader, null)) : (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: "mailInbox", label: Messages.Labels.MailInbox, showWarning: showWarning, validate: composeValidators(isSpaces, isRequired, isCorrectEmail, minEmailLength, maxEmailLength), component: BrandInput, placeholder: Messages.Controls.MailInbox }),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit })))));
            } })))));
};
export default CreateMailInbox;
