var Messages = {
    Success: {
        Success: 'Éxito',
        ForgotPasswordSend: 'El enlace de cambio de contraseña fue enviado a su correo electrónico',
        ResetPasswordSuccess: 'Su contraseña ha sido actualizada satisfactoriamente',
        BrandCreated: '¡Marca creada!',
        MailInboxCreated: 'Bandeja de entrada de correo creada!',
        PrivacyPolicyChanged: '¡La política de privacidad cambió!',
        SmsInboxCreated: '¡Bandeja de entrada de sms creada!',
        InboxDeleted: '¡Bandeja de entrada eliminada!',
        StatusChanged: '¡Estado cambiado!',
        FileLoaded: '¡Archivo cargado!',
        AuthMail: 'Correo de autenticación!',
        DomainSettings: '¡Configuración de dominio!',
        UpdatedSmsAccounts: 'Cuentas de sms actualizadas!',
        ChanelCreated: 'Canal creado!',
        DeleteMessenger: '¡Eliminar Mensajero!',
        AddMessenger: 'Añadir mensajero!',
        ChangedMessenger: '¡Mensajero cambiado!',
        ClientCreated: 'Cliente creado!',
        ClientsImports: 'Clientes importaciones!',
        GroupCreated: '¡Grupo creado!',
        ChangedAssignedAgentsInGroups: '¡Se cambiaron los agentes asignados en los grupos!',
        ChangedAssignedChannelsInGroups: '¡Cambiaron los canales asignados en grupos!',
        ChangedAssignedTagsInGroups: '¡Cambiaron las etiquetas asignadas en los grupos!',
        CreateNewCategory: 'Crear nueva categoría!',
        CreateNewPhrase: '¡Crea una nueva frase!',
        DeleteCategory: '¡Eliminar categoría!',
        DeletePhrase: '¡Borrar frase!',
        UpdatePhrase: '¡Frase actualizada!',
        EditCategory: '¡Editar categoria!',
        DeleteAllCategory: '¡Eliminar todas las categorías!',
        TagCreated: '¡Etiqueta creada!',
        TagUpdated: '¡Etiqueta actualizada!',
        TelegramInboxCreated: 'Bandeja de entrada de Telegram creada!',
        HeaderTextChanged: 'El texto del encabezado cambió',
        LogoIconUrlChanged: 'Se ha cambiado la URL del icono del logotipo',
        IconUrlChanged: 'Url de icono cambiado',
        BackgroundImageUrlChanged: 'La URL de la imagen de fondo cambió',
        GreetingImageUrlChanged: 'La URL de la imagen de saludo ha cambiado',
        AgentImageUrlChanged: 'La URL de la imagen del agente cambió',
        InputPlaceholderChanged: 'El marcador de posición de entrada cambió',
        SendImageUrlChanged: 'Enviar URL de imagen cambiada',
        AgentStatusChanged: 'El estado del agente cambió',
        SelectedAgentTagsChanged: 'Se cambiaron las etiquetas de agente seleccionadas',
        AssignedAgentsGroupsChanged: 'Los grupos de agentes asignados cambiaron',
        TeamLeadChanged: 'Líder de equipo cambiado',
        AgentRoleChanged: 'Rol de agente cambiado',
        AgentInfoChanged: 'La información del agente cambió',
    },
    Errors: {
        PasswordMatching: '¡Las contraseñas no coinciden!',
        IncorrectEmail: 'El correo electrónico es incorrecto',
        IncorrectPhone: 'El teléfono es incorrecto',
        IncorrectUrl: 'La URL es incorrecta',
        FieldContainSpaces: 'El campo contiene espacios',
        FieldIsRequired: 'Se requiere campo',
        NameValidation: 'La longitud del campo debe ser de 2 a 40 caracteres inclusive',
        NumberValidation: 'El campo debe contener solo números',
        PasswordValidation: 'La contraseña debe contener letras y números y no puede contener espacios',
        InvoiceNumberValidation: 'El número de factura debe contener letras latinas, números y caracteres especiales',
        MinimumLength: 'Longitud mínima {0} símbolos',
        MaximumLength: 'Longitud máxima {0} símbolos',
        ServerError: 'Error del servidor, inténtalo más tarde',
        ServerErrorFileDownloadTimeOut: 'Error del servidor, se agotó el tiempo de descarga del archivo, inténtelo más tarde',
        NotValidEmailOrPassword: '¡El correo electrónico o la contraseña no son válidos!',
        UserWasNotFound: '¡No se encontró al usuario!',
        UserIsAlreadyExist: 'El usuario ya existe!',
        AgentWasBanned: 'El agente fue prohibido',
        TokenExpired: 'La sesión ha expirado. Vuelva a iniciar sesión',
        TelegramIdIsIncorrect: 'El ID de Telegram es incorrecto',
        ErrorWrongFileFormat: '¡Error, formato de archivo incorrecto!',
        FileSizeExceededWarnings: '¡El tamaño del archivo excedió las advertencias!',
        BrandWithSuchNameIsAlreadyExisting: '¡La marca con ese nombre ya existe!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Ya has seleccionado un filtro',
        AgentCreate: 'Eres un nuevo agente en el sistema AskMe. ¡Tenga en cuenta que solo puede iniciar sesión después de que se le haya asignado el rol de administrador!',
        NoStatisticsForTheAgent: 'No hay datos de estadísticas para el agente para el período de tiempo seleccionado',
        FieldIsRequired: 'Se requiere campo',
        ChannelMustContainAtLeastOneLanguage: 'El canal debe contener al menos un idioma',
    },
    Info: {
        Copied: 'copiada',
        PhraseBotNameAndGreetingsChanged: 'Se cambiaron las frases, el nombre del bot y el saludo.',
    },
    Confirm: {},
    Message: {
        Welcome: 'Bienvenido a AksMe',
        SignIn: 'Inicie sesión en el Panel de administración o vaya a su ',
        SignUp: 'Regístrese en el Panel de administración o vaya a su ',
        Register: '¿No tienes cuenta con nosotros?',
        Login: '¿Ya tienes una cuenta?',
        Logout: '¿De verdad quieres irte?',
        ForgotPassword: '¿Se te olvidó tu contraseña?',
        ExcelFilesDownloading: 'Descarga de archivos de Excel',
        excelFileIs: 'archivo Excel',
        Created: 'es creado',
        Creating: 'esta creando',
    },
    Accordions: {
        MailInboxes: 'Bandejas de entrada de correo',
        TelegramAccounts: 'Cuentas de telegramas',
        SmsAccounts: 'Cuentas SMS',
    },
    Controls: {
        Newbie: 'Novato',
        Name: 'Nombre',
        Email: 'Correo electrónico',
        Password: 'Contraseña',
        RepeatPassword: 'repite la contraseña',
        Register: 'Registrarse',
        Login: 'Iniciar sesión',
        Or: 'O',
        SignUp: 'Inscribirse',
        Send: 'Enviar',
        SearchFilter: 'Filtros de búsqueda',
        Search: 'Búsqueda',
        SearchMessengers: 'Buscar mensajeros',
        SearchAgent: 'Agente de búsqueda',
        SearchLanguage: 'Idioma de búsqueda',
        SearchChannel: 'Canal de búsqueda',
        SearchTag: 'Etiqueta de búsqueda',
        SearchStatus: 'Estado de búsqueda',
        SearchBrand: 'Buscar marca',
        SearchWhiteListTags: 'Buscar etiquetas de lista blanca',
        SearchSessionStatus: 'Estado de la sesión de búsqueda',
        SearchAccessLevel: 'Nivel de acceso de búsqueda',
        SearchHasActiveDialogs: 'La búsqueda tiene diálogos activos',
        GroupName: 'Nombre del grupo*',
        Description: 'Descripción*',
        LinkAlias: 'alias de enlace*',
        CategoryName: 'Nombre de la categoría*',
        BrandName: 'Nombre de la marca',
        AccessKey: 'Llave de acceso',
        ClientName: 'Nombre de usuario del cliente',
        ClientFirstName: 'Nombre del cliente',
        ClientLastName: 'apellido del cliente ',
        ClientPhone: 'teléfono del cliente',
        ClientEmail: 'correo electrónico del cliente',
        PleaseWrite: 'Por favor escribe',
        MailInbox: 'Bandeja de entrada de correo',
        SmsInbox: 'Bandeja de entrada de SMS',
        TelegramInbox: 'Bandeja de entrada de telegramas',
        ApplicationID: 'ID de aplicación',
        ApplicationToken: 'Ficha de aplicación',
        SenderID: 'ID del remitente',
        ChangeName: 'Cambiar nombre',
        ChangeURL: 'Cambiar URL',
        TagName: 'Nombre de la etiqueta',
        LinkUrl: 'URL del enlace*',
        Greetings: 'Saludos',
        FastPhrase: 'Frase rápida №',
        ClickOnPaperclip: 'Haga clic en el icono del clip para seleccionar el archivo',
        UserName: 'Acceso',
        Domain: 'Dominio*',
        Imap: 'Imap (host:puerto)*',
        SmtpTLS: 'Smtp TLS (host:puerto)*',
        SmtpSSL: 'Smtp SSL (host:puerto)*',
        AttachFile: 'Adjuntar archivo',
        NoData: 'sin datos',
        NoRating: 'sin evaluar',
        IfYouWantFinishDialog: 'Si desea finalizar el cuadro de diálogo, haga clic en el botón Aceptar',
        AllDialogsAreVisibleToAllAgents: 'Todos los diálogos son visibles para todos los agentes.',
        DialogueIsAssignedAutomatically: 'El diálogo se asigna automáticamente al agente que tiene el menor número de diálogos',
        AllDialogsAreVisibleToSeveralAgents: 'Los diálogos son visibles para varios agentes con el menor número de diálogos',
        On: 'Encender',
        Off: 'Apagado',
        FirstName: 'Primer nombre',
        InvoiceNumber: 'Número de factura',
        LastName: 'Apellido',
        SMS: 'SMS',
        New: 'Nuevo',
        Active: 'Activo',
        Finished: 'Finalizado',
        Assigned: 'Asignado',
        Online: 'En línea',
        Offline: 'Desconectado',
        Break: 'Descanso',
        Low: 'Bajo',
        Medium: 'Medio',
        High: 'Alto',
        True: 'Cierto',
        False: 'Falso',
        Date: 'Fecha',
        AgentName: 'Nombre del agente',
        Messenger: 'Mensajero',
        Language: 'Idioma',
        Channel: 'Canal',
        Tag: 'Etiqueta',
        Status: 'Estado',
        Brand: 'Marca',
        AccessLevel: 'Nivel de acceso',
        WhiteListTags: 'Etiquetas de lista blanca',
        SessionStatus: 'Estado de la sesión',
        LastTags: 'Últimas etiquetas',
        HasActiveDialogs: 'Tiene diálogos activos',
        ChannelName: 'Nombre del Canal',
        WriteYourFirstName: '* Por favor, escribe tu nombre',
        WriteYourLastName: '* Por favor, escribe tu apellido',
        WriteYourUserName: '* Por favor, escriba el nombre de usuario',
        WriteYourPhoneNumber: '* Por favor, escriba el número de teléfono',
        WriteYourEmail: '* Por favor, escriba su correo electrónico',
        WriteYourInvoiceNumber: '* Por favor, escriba su número de factura',
        WriteYourAccountNumber: '* Por favor, escribe tu número de cuenta',
        BotName: 'Nombre del bot',
        Character: 'personaje',
        Number: 'número',
        IdExample: 'ejemplo de identificación',
        XCharecter: 'X-Zeichen',
        YNumber: 'Y-Nummer',
        ThisOperationCanTakeLongTime: '¡Esta operación puede llevar mucho tiempo!',
        ClickTiSaveExcel: 'Haga clic para guardar en Excel',
        PrivacyPolicyText: 'Texto de la política de privacidad',
        PrivacyPolicyLink: 'Enlace de política de privacidad',
        PrivacyPolicyLinkText: 'Texto del enlace de la política de privacidad',
        AgentPhone: 'Agente telefonico',
        HeaderTextTop: 'Parte superior del texto del encabezado',
        HeaderTextBottom: 'Parte inferior del texto del encabezado',
        LogoIconUrl: 'URL del icono del logotipo',
        IconUrl: 'URL del icono',
        ChatWindowsLogo: 'Logotipo de ventanas de chat',
        GreetingIconBotUrl: 'Url de bot de icono de saludo',
        AgentMessageIconUrl: 'URL del icono del mensaje del agente',
        WriteMessagesPlaceholder: 'Escribir marcador de posición de mensajes',
        SendImageUrl: 'Enviar URL de imagen',
        ChannelURL: 'URL del canal',
    },
    Selects: {
        SelectAccessLevel: 'Seleccionar nivel de acceso*',
        SelectChannel: 'Seleccionar canal*',
        SelectChannels: 'Seleccionar canales',
        SelectWhiteTagsList: 'Seleccione la lista de etiquetas blancas*',
        SelectAgents: 'Seleccionar agentes',
        SelectGroups: 'Seleccionar grupos',
        SelectBrand: 'Seleccionar marca',
        SelectTags: 'Seleccionar etiquetas',
        ChooseProviderCountry: 'Elija el país del proveedor',
        SelectMessengers: 'Seleccionar mensajeros',
        SelectTagStatus: 'Seleccione el estado de la etiqueta',
        SelectAgent: 'Seleccionar agente',
        SelectEnabledLanguages: 'Seleccionar idiomas habilitados',
        ChannelName: 'Nombre del Canal',
        ChannelURL: 'URL del canal',
        SelectLanguageWebChat: 'Seleccionar idiomas Web Chat',
    },
    Labels: {
        MoreIconUrl: 'Más icono URL',
        MinimizeIconUrl: 'Minimizar icono URL',
        SendFileUrl: 'Enviar URL de archivo',
        NumberOfNewAgents: 'Número de nuevas agentes',
        TextForBulkMessaging: 'Texto para mensajes masivos',
        Name: 'Nombre',
        LastName: 'Apellido',
        Phone: 'Teléfono',
        GroupName: 'Nombre del grupo*',
        SelectBrand: 'Seleccionar marca',
        Channel: 'Canal*',
        Description: 'Descripción*',
        AccessLevel: 'Nivel de acceso*',
        WhiteTagsList: 'Lista de etiquetas blancas*',
        BrandName: 'Nombre de la marca',
        CategoryName: 'Nombre de la categoría*',
        ChooseProviderCountry: 'Elija el país del proveedor',
        AccessKey: 'Llave de acceso',
        SelectChannels: 'Seleccionar canales',
        SelectGroups: 'Seleccionar grupos',
        SelectAgents: 'Seleccionar agentes',
        SelectTags: 'Seleccionar etiquetas',
        SelectAccessLevel: 'Seleccionar nivel de acceso*',
        Password: 'Contraseña*',
        Login: 'Iniciar sesión',
        SelectMessengers: 'Seleccionar mensajeros',
        UserName: 'Nombre de usuario',
        ClientName: 'Nombre de usuario del cliente',
        ClientFirstName: 'Nombre del cliente',
        ClientLastName: 'apellido del cliente',
        ClientPhone: 'teléfono del cliente',
        ClientEmail: 'correo electrónico del cliente',
        ClientInfo: 'información del cliente',
        UpdateGroup: 'Actualizar grupo',
        Agents: 'Agentes',
        UsefulPhrases: 'Frases útiles',
        Channels: 'Canales',
        Tags: 'Etiquetas',
        AddNewPhrases: 'Agregar nuevas frases',
        Phrase: 'Frase',
        SelectCategory: 'Selecciona una categoría',
        EditCategory: 'Editar categoria',
        EditPhrase: 'Editar frase',
        Or: 'o',
        AddNewCategory: 'Añadir nueva categoria',
        Dialogs: 'Diálogos',
        TotalDuration: 'Duración total',
        Email: 'Correo electrónico',
        ClientId: 'Identificación del cliente',
        ClientBanType: 'Tipo de prohibición de cliente',
        ClientBanTime: 'Tiempo de prohibición del cliente',
        Messengers: 'Mensajeros',
        IpAddress: 'dirección IP',
        ExternalID: 'Identificación externa',
        UpdateBrand: 'Actualizar marca',
        CreateMailInbox: 'Crear cuenta de bandeja de entrada de correo',
        CreateTelegramInbox: 'Crear cuenta de telegrama',
        CreateSmsInbox: 'Crear cuenta SMS',
        MailInbox: 'Bandeja de entrada de correo',
        TelegramInbox: 'Bandeja de entrada de telegramas',
        SmsInbox: 'Bandeja de entrada de SMS',
        ApplicationID: 'ID de aplicación',
        ApplicationToken: 'Ficha de aplicación',
        SenderID: 'ID del remitente',
        ChangeName: 'Cambiar nombre',
        ChangeURL: 'Cambiar URL',
        TagName: 'Nombre de la etiqueta',
        SelectTagStatus: 'Seleccione el estado de la etiqueta',
        SelectAgent: 'Seleccionar agente',
        UpdateTag: 'Actualizar etiqueta',
        CreateTag: 'Crear etiqueta',
        UsefulLinks: 'Enlaces útiles',
        LinkAlias: 'alias de enlace',
        LinkUrl: 'URL del enlace',
        Roles: 'roles',
        Groups: 'Grupos',
        CreateGroups: 'Crear grupos',
        TeamLead: 'Jefe de equipo',
        Ban: 'Prohibición',
        SelectEnabledLanguages: 'Seleccionar idiomas habilitados',
        Greetings: 'Saludos',
        FastPhrase: 'Frase rápida №',
        ClientHistory: 'historial del cliente',
        ChooseRequiredFields: 'Por favor, elija los campos obligatorios',
        GetAllDialogs: 'Obtener todos los diálogos',
        GetCurrentDialog: 'Obtener cuadro de diálogo actual',
        SaveInLocalStorage: 'Guardar en almacenamiento local',
        SaveInSessionStorage: 'Guardar en SessionStorage',
        InvoiceNumber: 'número de factura',
        AccountNumber: 'Número de cuenta',
        General: 'General',
        Authorization: 'Autorización',
        Languages: 'Idiomas',
        New: 'Nuevo',
        Active: 'Activo',
        Inactive: 'Inactivo',
        WaitForActivation: 'Esperar a la activación',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Bajo',
        Medium: 'Medio',
        High: 'Alto',
        Hidden: 'Oculto',
        Brand: 'Marca',
        Group: 'Grupo',
        ChangeStatus: 'Cambiar Estado',
        AuthMail: 'Correo de autenticación',
        SetDomainSettings: 'Establecer la configuración del dominio',
        Domain: 'Dominio*',
        Imap: 'Imap (host:puerto)*',
        SmtpTLS: 'Smtp TLS (host:puerto)*',
        SmtpSSL: 'Smtp SSL (host:porto)*',
        AttachFile: 'Adjuntar archivo',
        ChannelSettings: 'Configuración de canales',
        GeneralSettings: 'Configuración general',
        WebChatConstructor: 'Constructor de chat web',
        BackgroundColor: 'Color de fondo',
        MessageColor: 'Color del mensaje',
        URL: 'URL',
        ChannelName: 'Nombre del Canal',
        ChannelURL: 'URL del canal',
        AllowSMS: 'Permitir SMS',
        AllowShowAllClientDialogs: 'Permitir mostrar todos los diálogos del cliente',
        AllowCaptcha: 'Permitir captcha',
        InboxSettings: 'Configuración de la bandeja de entrada',
        DistributionOfNewDialogs: 'Distribución de nuevos diálogos entre agentes online',
        ThereAreNoAvailableMessengers: '¡No hay mensajeros disponibles para el canal! ¡Créelos para la marca propietaria de este canal!',
        HeaderColorScheme: 'esquema de color del encabezado',
        GreetingColorScheme: 'Esquema de color de saludo',
        QuickPhrases: 'Esquema de color de frases rápidas',
        ClientMessage: 'Esquema de color del mensaje del cliente',
        AgentMessage: 'Esquema de color del mensaje del agente',
        EndDialogModal: 'Esquema de color modal de diálogo final',
        IconColorScheme: 'Esquema de color del icono',
        ScrollColorScheme: 'Esquema de color de desplazamiento',
        ChatWindowColor: 'Esquema de colores de la ventana de chat',
        FooterColorScheme: 'Esquema de color del pie de página',
        AuthColorScheme: 'Esquema de color de autenticación',
        HeaderBackground: 'Fondo del encabezado',
        HeaderBorderColor: 'Color del borde del encabezado',
        ColorGreetingBotName: 'Color del nombre del bot de saludo',
        MessageGreetingIcon: 'Fondo de icono de saludo',
        MessageGreetingColor: 'Color del texto de saludo',
        MessageGreetingBorderColor: 'Mensaje de bienvenida Color del borde',
        MessageGreetingBackgroundColor: 'Mensaje de bienvenida Color de fondo',
        QuickPhrasesColor: 'Frase rápida Color del texto',
        QuickPhrasesBackground: 'Fondo de frase rápida',
        QuickPhrasesBorderColor: 'Frase rápida Color del borde',
        QuickPhrasesColorHover: 'Frase rápida Color de desplazamiento',
        QuickPhrasesBackgroundHover: 'Desplazamiento de fondo de frase rápida',
        QuickPhrasesBorderColorHover: 'Desplazamiento de color de borde de frase rápida',
        ClientMessageColor: 'Mensaje del cliente Color del texto',
        ClientTimeColor: 'Color de tiempo del cliente',
        ClientMessageBackground: 'Mensaje del cliente Color de fondo',
        ClientMessageBorderColor: 'Mensaje del cliente Color del borde',
        ClientLink: 'Enlace de cliente',
        ClientLinkHover: 'Desplazamiento del enlace del cliente',
        ClientActiveLink: 'Enlace activo del cliente',
        ClientVisitedLink: 'Enlace visitado por el cliente',
        ColorAgentNameAndTime: 'Nombre del agente y color de la hora',
        MessageAgentColor: 'Color del mensaje del agente',
        MessageAgentBackgroundColor: 'Fondo del mensaje del agente',
        MessageAgentBorderColor: 'Mensaje del agente Color del borde',
        AgentLink: 'Enlace de agente',
        AgentHoverLink: 'Enlace de desplazamiento del agente',
        AgentActiveLink: 'Enlace activo del agente',
        AgentVisitedLink: 'Enlace del agente visitado',
        MessageIconBackgroundAgent: 'Color de fondo del icono del mensaje del agente',
        TextColor: 'Color de texto',
        BackdropColor: 'color de fondo',
        BackdropColorHover: 'Desplazamiento de color de fondo',
        ButtonColor: 'Color del boton',
        BackdropColorBtn: 'Color de fondo del botón de telón de fondo',
        IconBackground: 'Fondo del icono',
        ChatWindowsBodyBackground: 'Color de fondo del cuerpo de las ventanas de chat',
        ChatWindowsBorderColor: 'Color del borde de las ventanas de chat',
        FooterBackground: 'Color de fondo del pie de página',
        FooterBorderColor: 'Color del borde del pie de página',
        FooterBackgroundTextArea: 'Color de fondo del área de texto del pie de página',
        InputBorderColor: 'Color del borde de entrada',
        RegistrationBackgroundColor: 'Color de fondo de registro',
        LabelColor: 'color de la etiqueta',
        LabelErrorColor: 'Color de error de etiqueta',
        InputColor: 'Color de entrada',
        InputBackground: 'Fondo de entrada',
        SignUpColor: 'Regístrate color',
        SignUpBackground: 'Registrarse color de fondo',
        Chat: 'Chat',
        Minimized: 'Minimizado',
        ScrollBackground: 'Fondo de desplazamiento',
        ScrollThumbBackground: 'Desplazar el fondo del pulgar',
        UniqueClientIdentifier: 'Identificador único de cliente',
        AgentsSettings: 'Configuración de agentes',
        ThisAgentSuperAdmin: 'Este agente es superadministrador, haga clic en el botón para eliminar los derechos de superadministrador',
        ThisAgentNotSuperAdmin: 'Este agente no es un superadministrador, haga clic en el botón para convertirlo en superadministrador',
        YouCanAssignBrandTheBrand: 'Puede asignar la marca del administrador para esto necesita seleccionar la marca',
        Role: 'Papel',
        BanningThisAgent: 'Prohibir a este agente significa que no podrá iniciar sesión en la plataforma. Todavía van a aparecer en el sistema.',
        Report: 'Informe',
        SelectLanguageWebChat: 'Seleccionar idiomas Web Chat',
        FirstName: 'Primer nombre',
        PhoneNumber: 'Número de teléfono',
        Info: 'Información',
        WebChatSettings: 'Configuración de chat web',
        IntegrationWithOtherCRM: 'Integración con otros CRM',
        SettingUpInteractionBetweenSystems: 'Configuración de la interacción entre sistemas',
        QuickReplies: 'Respuestas rápidas',
        BotSettings: 'Configuración de bots',
        BotName: 'Nombre del bot',
        EnabledPrivacyPolicy: 'Política de privacidad habilitada',
        PleaseChooseRequiredFields: 'Por favor, elija los campos obligatorios',
        ChangeGreetingsAndPhrases: 'Cambiar Saludo y Frase, Política de Privacidad',
        ChangeSmsSettings: 'Cambiar la configuración de SMS',
        ChangeCountries: 'Cambiar países',
        ContactsAreWorkingWithClientUniqueness: 'Los contactos funcionan con la exclusividad del cliente. El campo es obligatorio si la exclusividad del cliente es igual.',
        ClientUniquenessIs: 'La singularidad del cliente es:',
        PrivacyPolicyText: 'Texto de la política de privacidad',
        PrivacyPolicyLink: 'Enlace de política de privacidad',
        PrivacyPolicyLinkText: 'Texto del enlace de la política de privacidad',
        AgentPhone: 'Agente telefonico',
        HeaderSettings: 'Configuración de encabezado',
        DisableDraggable: 'Deshabilitar arrastrable',
        DisableHeaderFullScreen: 'Deshabilitar encabezado de pantalla completa',
        DisableHeaderHideChatWindow: 'Deshabilitar ocultar ventana de chat de encabezado',
        DisableHeaderLogo: 'Deshabilitar logotipo de encabezado',
        DisableHeaderShowEndDialogModal: 'Deshabilitar encabezado mostrar cuadro de diálogo final modal',
        HeaderText: 'Texto de cabecera',
        HeaderTextTop: 'Parte superior del texto del encabezado',
        HeaderTextBottom: 'Parte inferior del texto del encabezado',
        HeaderHeight: 'Altura del encabezado',
        LogoIconUrl: 'URL del icono del logotipo',
        IconUrl: 'URL del icono',
        IconSettings: 'Configuración de iconos',
        ScrollSettings: 'Configuración de desplazamiento',
        ScrollWidth: 'Ancho de desplazamiento',
        ChatWindowsLogo: 'Logotipo de ventanas de chat',
        BackgroundImageSize: 'Tamaño de la imagen de fondo',
        GreetingSettings: 'Ajustes de saludo',
        GreetingIconBotUrl: 'URL del bot del icono de saludo',
        QuickPhrasesSettings: 'Configuración de frases rápidas',
        ClientMessageSettings: 'Configuración de mensajes del cliente',
        AgentMessageSettings: 'Configuración de mensajes del agente',
        AgentMessageIconUrl: 'URL del icono del mensaje del agente',
        EndDialogModalSettings: 'Configuración modal de diálogo final',
        AuthWindowSettings: 'Configuración de la ventana de autenticación',
        FooterSettings: 'Configuración de pie de página',
        WriteMessagesPlaceholder: 'Escribir marcador de posición de mensajes',
        SendImageUrl: 'Enviar URL de imagen',
        SendButtonIconHeight: 'Altura del icono del botón Enviar',
        SendButtonIconWidth: 'Ancho del icono del botón Enviar',
        ChatWindowSettings: 'Configuración de la ventana de chat',
        ClearAllPhrase: 'Borrar toda la frase',
        AreYouSure: '¿Está seguro?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Esta acción eliminará todas las frases y todas las categorías',
        AddAgentToGroup: 'Agregar agentes a un grupo',
        CreateClient: 'Crear Cliente',
        ClientsImport: 'Importacion de clientes',
        UploadExcelFile: 'Subir archivo de Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Haga clic en el icono del clip para seleccionar el archivo de Excel',
        Contacts: 'Contactos',
        Personal: 'Personal',
        FileUploadHint: 'Cargue un archivo de Excel lleno de números de teléfono. El archivo de Excel debe contener una columna llamada "Teléfonos" ' +
            'y los números de teléfono deben ingresarse en esta columna. Solo se pueden importar archivos .xls o .xlsx.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Cambiar Saludo y Frase, Política de Privacidad',
    },
    AdditionalText: {
        AgentProfile: 'Perfil del agente',
        OutOf: 'fuera de',
    },
    Pages: {
        Dialogs: {
            Title: 'Diálogos',
            TableColumns: {
                Status: 'Estado',
                Dialog: 'Diálogo',
                Messenger: 'Mensajero',
                Channel: 'Canal',
                Tag: 'Etiqueta',
                Agent: 'Agente',
                Rating: 'Clasificación',
                Language: 'Idioma',
                MessagesCount: 'Cuenta de mensajes',
                FirstMessage: 'Primer mensaje',
                LastActivity: 'Última actividad',
            },
            Messages: 'Mensajes',
        },
        AgentGroups: {
            Title: 'Grupos de agentes',
            TableColumns: {
                Groups: 'Grupos',
                Description: 'Descripción',
                AccessLevel: 'Nivel de acceso',
                WhiteListTags: 'Etiquetas de lista blanca',
                CreatedAt: 'Creado en',
                UpdatedAt: 'Actualizado en',
            },
        },
        Agents: {
            Title: 'Agentes',
            TableColumns: {
                AgentStatus: 'Estado del agente',
                Agents: 'Agentes',
                Email: 'Correo electrónico',
                Groups: 'Grupos',
                CreatedAt: 'Creado en',
                LastActivity: 'Última actividad',
            },
        },
        Tags: {
            Title: 'Etiquetas',
            TableColumns: {
                Tags: 'Etiquetas',
                Dialogs: 'Diálogos',
                Agents: 'Agentes',
                UsefulLinks: 'Enlaces útiles',
                Status: 'Estado',
                CreatedAt: 'Creado en',
                UpdatedAt: 'Actualizado en',
            },
        },
        Clients: {
            Title: 'Clientes',
            TableColumns: {
                SearchName: 'nombre',
                AgentName: 'Nombre del agente',
                Messenger: 'Mensajero',
                LastTag: 'última etiqueta',
                ActiveDialog: 'Tiene diálogo activo',
                Brand: 'Marca',
                InternalId: 'Id interna',
                ExternalId: 'Id externa',
                Name: 'Nombre del cliente',
                FirstName: 'Nombre de pila',
                LastName: 'Apellido',
                Email: 'Correo electrónico',
                InvoiceNumber: 'Invoice Number',
                DialogsCount: 'Dialogs Count',
                FirstMessage: 'Primer mensaje',
                LastActivity: 'Última actividad',
                Tag: 'Etiqueta',
                Status: 'Estado',
                Channel: 'Canal',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Canales',
            TableColumns: {
                ChannelId: 'id del canal',
                ChannelKey: 'Clave de canal',
                ChannelName: 'Nombre del Canal',
                Language: 'Idioma',
                Url: 'Url',
                CreatedAt: 'Creado en',
                UpdatedAt: 'Actualizado en',
            },
        },
        Brands: {
            Title: 'Marcas',
            TableColumns: {
                BrandsId: 'Marca id',
                BrandName: 'Nombre de la marca',
                ClientUniqueness: 'Exclusividad del cliente',
                BrandChannels: 'Canales de marca',
                DialogCount: 'Diálogos cuentan',
                CreatedAt: 'Creado en',
                UpdatedAt: 'Actualizado en',
            },
        },
        Reports: {
            Title: 'Informes',
            ByMessengersTab: {
                Title: 'Informe por mensajeros',
                Columns: {
                    Messenger: 'Mensajero',
                    ReceivedDialogs: 'Diálogos recibidos',
                    PositiveDialogs: 'Diálogos positivos, %',
                    NegativeDialogs: 'Diálogos negativos, %',
                    UnratedDialogs: 'Diálogos sin calificar, %',
                    AvgResponseTime: 'Tiempo de respuesta promedio',
                },
            },
            ByAgentsTab: {
                Title: 'Informe de los agentes',
                Columns: {
                    agentFullName: 'Nombre completo del agente',
                    agentSystemId: 'ID del sistema del agente',
                    brandName: 'Nombre de la marca',
                    receivedDialogs: 'Diálogos recibidos',
                    dialogsTelegram: 'Diálogos Telegram',
                    dialogsWebChat: 'Diálogos Web Chat',
                    dialogsViber: 'Diálogos Viber',
                    dialogsWhatsApp: 'Diálogos WhatsApp',
                    dialogsFacebook: 'Diálogos Facebook',
                    dialogsEmail: 'Diálogos Email',
                    dialogsSms: 'Diálogos SMS',
                    positiveDialogs: 'Diálogos positivos, %',
                    negativeDialogs: 'Diálogos negativos, %',
                    unratedDialogs: 'Diálogos sin calificar, %',
                    avgResponseTime: 'Tiempo de respuesta promedio',
                },
                noDataForThisDay: 'No hay datos para este día',
            },
            ByGroupsTab: {
                Title: 'Informe por grupos',
                Columns: {
                    groupSystemId: 'ID del sistema de grupo',
                    groupName: 'Nombre del grupo',
                    brandName: 'Nombre de la marca',
                    receivedDialogs: 'Diálogos recibidos',
                    positiveDialogs: 'Diálogos positivos, %',
                    negativeDialogs: 'Diálogos negativos, %',
                    unratedDialogs: 'Diálogos sin calificar, %',
                    avgResponseTime: 'Tiempo de respuesta promedio',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Añadir filtro',
        reset: 'Reiniciar',
        createClient: 'Crear cliente',
        importClient: 'Importar Clientes',
        createChannel: 'Crear canal',
        previous: 'Previo',
        next: 'Próximo',
        addPhrase: 'Añadir frase',
        addGroup: 'Añadir grupo',
        createBrand: 'crear marca',
        addTag: 'Añadir etiqueta',
        save: 'Ahorrar',
        send: 'Enviar',
        getStatistics: 'Obtener estadísticas',
        setCountries: 'Establecer países',
        changeStatus: 'Cambiar Estado',
        upload: 'Subir',
        setDomainSettings: 'Establecer la configuración del dominio',
        login: 'Acceso',
        update: 'Actualizar',
        resetPassword: 'Restablecer la contraseña',
        addNew: 'Agregar nuevo',
        addNewPhrase: 'Agregar nueva frase',
        addNewCategory: 'Añadir nueva categoria',
        clearAll: 'Limpiar todo',
        loginWithGoogle: 'Iniciar sesión con Google',
        loginWithFacebook: 'Iniciar sesión con Facebook',
        onPage: 'en la página',
        addNewUsefulLinks: 'Agregar nuevos enlaces útiles',
        backToChannels: 'Volver a Canales',
        defaultSettings: 'Configuración por defecto',
        upgradeChannel: 'Actualizar canal',
        apply: 'Aplicar',
        remove: 'Eliminar',
        removeSuperAdmin: 'Eliminar superadministrador',
        makeSuperAdmin: 'Hacer superadministrador',
        makeBrandAdmin: 'Hacer administrador de marca',
        removeBrandAdmin: 'Eliminar administrador de marca',
        ban: 'Ban',
        unban: 'Desbanear',
        makeTeamLead: 'Hacer líder del equipo',
        removeTeamLead: 'Quitar líder de equipo',
        signUp: 'Inscribirse',
        ok: 'Ok',
        cancel: 'Cancelar',
        bulkMessaging: 'Mensajería masiva',
        back: 'Espalda',
    },
    Countries: {
        UnitedStates: 'Estados Unidos',
        Canada: 'Canadá',
        Australia: 'Australia',
        CzechRepublic: 'Republica checa',
        Denmark: 'Dinamarca',
        Hungary: 'Hungría',
        Netherlands: 'Países Bajos',
        Sweden: 'Suecia',
        UnitedKingdom: 'Reino Unido',
        France: 'Francia',
        Germany: 'Alemania',
        Poland: 'Polonia',
        Italy: 'Italia',
        Spain: 'España',
    },
    Languages: {
        Ukrainian: 'Ucranio',
        Russian: 'Ruso',
        English: 'Inglés',
        Polish: 'Polaco',
        Deutsch: 'Alemán',
        Spanish: 'Español',
        Vietnamese: 'vietnamita',
        Portuguese: 'Portugués',
        Korean: 'Coreano',
        Italian: 'Italiano',
        Arabic: 'Arábica',
        French: 'Francés',
        Azerbaijan: 'Azerbaiyán',
        Hungarian: 'Húngara',
    },
    BulkMessaging: {
        exclude: 'Excluir',
        typeText: 'Teclee el texto',
        selectClients: 'Seleccionar clientes',
        uploadClients: 'Subir clientes',
        sendingMessagesToNewClients: 'Envío de mensajes a nuevos clientes',
        sendingMessagesToExistingClients: 'Envío de mensajes a clientes existentes',
        clientsWhoHaveDialogsWithFollowingStatus: 'Clientes, que tienen diálogos con el siguiente estado',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Clientes, que tienen este texto en nombre o apellido o nombre de usuario',
        limitQuantity: 'Cantidad límite',
        quantityLimit: 'Límite de cantidad',
        limitQuantityText: 'Limite la cantidad en la transmisión a no más del número especificado de destinatarios. Los clientes más nuevos del sistema entrarán en la lista.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Clientes que escribieron a AskMe antes de la hora del último mensaje',
    },
};
export default Messages;
