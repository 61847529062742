var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var TableHeadWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  padding-left: 56px;\n  padding-right: 10px;\n  font-size: 14px;\n  height: 33px;\n  border-radius: 4px 4px 0 0;\n  display: grid;\n  align-items: center;\n  grid-template-columns: ", ";\n  & div {\n    text-align: ", ";\n  }\n"], ["\n  background: ", ";\n  padding-left: 56px;\n  padding-right: 10px;\n  font-size: 14px;\n  height: 33px;\n  border-radius: 4px 4px 0 0;\n  display: grid;\n  align-items: center;\n  grid-template-columns: ", ";\n  & div {\n    text-align: ", ";\n  }\n"])), function (_a) {
    var headBackground = _a.headBackground, theme = _a.theme;
    return headBackground || theme.colors.tableHeader;
}, function (_a) {
    var headNamesLength = _a.headNamesLength;
    return "repeat(".concat(headNamesLength, ", 1fr);");
}, function (_a) {
    var headTextAlign = _a.headTextAlign;
    return headTextAlign || 'left';
});
var templateObject_1;
