var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { takeEvery, put, delay, fork, call, select, } from 'redux-saga/effects';
import { difference } from 'lodash';
import tagsStore from '@src/modules/Pages/Tags/redux/selector';
import { tagsApi } from '@src/modules/Pages/Tags/redux/api';
import { Messages } from '@public/locales/messages';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { entitiesActions } from '@src/redux/entities/reducers';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { tagsActions, TagsPageTypes } from '@src/modules/Pages/Tags/redux/reducer';
import { formatGroupIdsToQuery } from '@src/utils/formatGroupIdsToQuery';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
import { errorNotification, successNotification } from '@src/redux/notification/saga';
function getAgentsForTag(id) {
    var _a, assigned, unAssigned, e_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, call(tagsApi.getAgentsForTag, id)];
            case 1:
                _a = _b.sent(), assigned = _a.assigned, unAssigned = _a.unAssigned;
                return [4, put(tagsActions.setAssignedAgent(assigned))];
            case 2:
                _b.sent();
                return [4, put(tagsActions.setUnAssignedAgent(unAssigned))];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                e_1 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_1.text)];
            case 5:
                _b.sent();
                console.error(e_1);
                return [3, 6];
            case 6: return [2];
        }
    });
}
function getTagById(id) {
    var tag, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(tagsApi.getTagById, id)];
            case 1:
                tag = _a.sent();
                return [4, put(tagsActions.setCurrentTag(tag))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_2 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_2.text)];
            case 4:
                _a.sent();
                console.error(e_2);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function createNewTag(_a) {
    var usefulLinks, name, groups, groupIdsToQuery, e_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                usefulLinks = payload.usefulLinks, name = payload.name, groups = payload.groups;
                return [4, call(formatGroupIdsToQuery, groups)];
            case 1:
                groupIdsToQuery = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4, call(tagsApi.createTag, { usefulLinks: usefulLinks, name: name, status: 'active' }, groupIdsToQuery)];
            case 3:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.TagCreated)];
            case 4:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 5:
                _b.sent();
                return [3, 8];
            case 6:
                e_3 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_3.text)];
            case 7:
                _b.sent();
                console.error(e_3);
                return [3, 8];
            case 8: return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_TAGS_MODAL, null))];
            case 9:
                _b.sent();
                return [4, put(setLoading(TagsPageTypes.CREATE_TAG, false))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function showSettingsTags(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, put(modalWindowsActions.toggleModal(modalTypes.UPDATE_TAGS_MODAL, payload))];
            case 1:
                _b.sent();
                return [4, call(getAgentsForTag, payload)];
            case 2:
                _b.sent();
                return [4, call(getTagById, payload)];
            case 3:
                _b.sent();
                return [4, put(setLoading(TagsPageTypes.SHOW_TAGS_SETTINGS, false))];
            case 4:
                _b.sent();
                return [2];
        }
    });
}
function updateTagSettings(_a) {
    var status, assignedAgents, name, usefulLinks, currentTag, updatedTag, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                status = payload.status, assignedAgents = payload.assignedAgents, name = payload.name, usefulLinks = payload.usefulLinks;
                return [4, select(tagsStore.getCurrentTag)];
            case 1:
                currentTag = _b.sent();
                return [4, call(addOrRemoveAgentsToTag, assignedAgents, currentTag.id)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                _b.trys.push([3, 9, , 11]);
                return [4, call(tagsApi.updateTag, __assign(__assign({}, currentTag), { status: status, name: name, usefulLinks: usefulLinks }))];
            case 4:
                updatedTag = _b.sent();
                return [4, put(tagsActions.setCurrentTag(updatedTag))];
            case 5:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _b.sent();
                return [4, call(getAgentsForTag, currentTag.id)];
            case 7:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.TagUpdated)];
            case 8:
                _b.sent();
                return [3, 11];
            case 9:
                e_4 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_4.text)];
            case 10:
                _b.sent();
                console.error(e_4);
                return [3, 11];
            case 11: return [4, put(setLoading(TagsPageTypes.UPDATE_TAG_SETTINGS, false))];
            case 12:
                _b.sent();
                return [2];
        }
    });
}
function addOrRemoveAgentsToTag(assignedAgents, tagIg) {
    var currentAssignedAgentsTag, currentAssignedAgentsTagId, deleteAgentsTag, addAgentsTag, deleteAgentsTag_1, deleteAgentsTag_1_1, agent, e_5_1, e_6;
    var e_5, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(tagsStore.getAssignedAgentTags)];
            case 1:
                currentAssignedAgentsTag = _b.sent();
                currentAssignedAgentsTagId = currentAssignedAgentsTag.map((function (el) { return el.id; }));
                deleteAgentsTag = difference(currentAssignedAgentsTagId, assignedAgents);
                addAgentsTag = difference(assignedAgents, currentAssignedAgentsTagId);
                _b.label = 2;
            case 2:
                _b.trys.push([2, 14, , 16]);
                if (!(deleteAgentsTag.length > 0)) return [3, 11];
                _b.label = 3;
            case 3:
                _b.trys.push([3, 9, 10, 11]);
                deleteAgentsTag_1 = __values(deleteAgentsTag), deleteAgentsTag_1_1 = deleteAgentsTag_1.next();
                _b.label = 4;
            case 4:
                if (!!deleteAgentsTag_1_1.done) return [3, 8];
                agent = deleteAgentsTag_1_1.value;
                return [4, call(tagsApi.unAssignTagFromAgents, tagIg, agent)];
            case 5:
                _b.sent();
                return [4, delay(100)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7:
                deleteAgentsTag_1_1 = deleteAgentsTag_1.next();
                return [3, 4];
            case 8: return [3, 11];
            case 9:
                e_5_1 = _b.sent();
                e_5 = { error: e_5_1 };
                return [3, 11];
            case 10:
                try {
                    if (deleteAgentsTag_1_1 && !deleteAgentsTag_1_1.done && (_a = deleteAgentsTag_1.return)) _a.call(deleteAgentsTag_1);
                }
                finally { if (e_5) throw e_5.error; }
                return [7];
            case 11:
                if (!(addAgentsTag.length > 0)) return [3, 13];
                return [4, call(tagsApi.assignTagToAgents, tagIg, assignedAgents)];
            case 12:
                _b.sent();
                _b.label = 13;
            case 13: return [3, 16];
            case 14:
                e_6 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_6.text)];
            case 15:
                _b.sent();
                console.error(e_6);
                return [3, 16];
            case 16: return [2];
        }
    });
}
export var tagsPageSagas = [
    takeEvery(TagsPageTypes.CREATE_TAG, loaderWrap(createNewTag, false)),
    takeEvery(TagsPageTypes.SHOW_TAGS_SETTINGS, loaderWrap(showSettingsTags, false)),
    takeEvery(TagsPageTypes.UPDATE_TAG_SETTINGS, loaderWrap(updateTagSettings, false)),
];
