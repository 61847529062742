import { combineReducers } from 'redux';
import { appReducer } from '@src/redux/app/reducer';
import { authReducer } from '@src/modules/Auth/redux/reducer';
import { tagsReducer } from '@src/modules/Pages/Tags/redux/reducer';
import { excelReducer } from '@src/modules/Excel/redux/reducer';
import { agentsReducer } from '@src/modules/Pages/Agents/redux/reducer';
import { brandsReducer } from '@src/modules/Pages/Brands/redux/reducer';
import { groupsReducer } from '@src/modules/Pages/Groups/redux/reducer';
import { dialogsReducer } from '@src/modules/Pages/Dialogs/redux/reducer';
import { clientsReducer } from '@src/modules/Pages/Clients/redux/reducer';
import { toolBarReducer } from '@src/modules/ToolBar/redux/reducer';
import { reportsReducer } from '@src/modules/Pages/Reports/redux/reducer';
import { channelsReducer } from '@src/modules/Pages/Channels/redux/reducer';
import { entitiesReducer } from '@src/redux/entities/reducers';
import { isLoadingReducer } from '@src/redux/loading/reducer';
import { notificationReducer } from '@src/redux/notification/reducer';
import { dictionariesReducer } from '@src/redux/dictionaries/reducer';
import { tableOptionsReducer } from '@src/redux/tableOptions/reducer';
import { modalWindowsReducer } from '@src/redux/modalWindows/reducer';
import { resetPasswordPageReducer } from '@src/modules/Pages/ResetPassword/redux/reducer';
var pagesReducer = combineReducers({
    dialogs: dialogsReducer,
    groups: groupsReducer,
    agents: agentsReducer,
    tags: tagsReducer,
    clients: clientsReducer,
    channels: channelsReducer,
    brands: brandsReducer,
    reports: reportsReducer,
    resetPasswordPage: resetPasswordPageReducer,
});
export var rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    isLoading: isLoadingReducer,
    modalWindows: modalWindowsReducer,
    dictionaries: dictionariesReducer,
    entities: entitiesReducer,
    notification: notificationReducer,
    tableOptions: tableOptionsReducer,
    toolBar: toolBarReducer,
    pages: pagesReducer,
    excel: excelReducer,
});
