var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Messages } from '@public/locales/messages';
export var mapTagsData = function (tags) { return tags.map(function (tag) {
    var _a, _b, _c;
    return (__assign(__assign({}, tag), { id: ((_a = tag === null || tag === void 0 ? void 0 : tag.tag) === null || _a === void 0 ? void 0 : _a.id) || Messages.Controls.NoData, name: ((_b = tag === null || tag === void 0 ? void 0 : tag.tag) === null || _b === void 0 ? void 0 : _b.name) || Messages.Controls.NoData, dialoguesCount: tag.dialoguesCount || Messages.Controls.NoData, agentsCount: tag.agentsCount || Messages.Controls.NoData, usefulLinks: (_c = tag.tag.usefulLinks) === null || _c === void 0 ? void 0 : _c.map(function (el) { return el.alias; }), status: tag.tag.status || Messages.Controls.NoData, createdAt: tag.tag.createdAt || Messages.Controls.NoData, updatedAt: tag.tag.updatedAt || Messages.Controls.NoData }));
}); };
