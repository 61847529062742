var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { checkIsPaddingTopTable } from '@src/utils/checkIsPaddingTopTable';
export var COLORS = {
    MAIN_DARK: '#12171e',
    BLACK: '#000000',
    MAIN_BACKGROUND: '#1d2933',
    MAIN_BRIGHT: '#202933',
    GREY: '#C4C4C4',
    DODGER_BLUE: '#4285F4',
    DODGER_BLUE_OPACITY: '#4285F41A',
    DARK_BLUE: '#003273',
    LIGHT_DARK_BLUE: '#005cc9',
    LIGHT_GREY: '#F5F5F5',
    WHITE: '#FFFFFF',
    DARK_GREY: '#434343',
    GREY_BLUE: '#657187',
    LIGHT_GREY_BLUE: '#99A4AC',
    ORANGE_HOT: '#f87007',
    YELLOW: '#d4b106',
    GREEN_DARKER: '#30b580',
    GREEN: '#41d05b',
    DIRTY_GREEN: '#3C9F4D',
    BLUE_VIVID: '#1972f5',
    ALERT: '#D93025',
    RED: '#D80027',
    VERY_DARK_BLUE: '#001529',
    BANNED: '#4C3131',
    BOTTOM_MSG: '#9098B1',
    TOOLBAR: '#404550',
    TABLE_ROW: '#31394C',
    SECONDARY_BACKGROUND: '#EFEFF4',
    SECONDARY_TOOLBAR: '#F6F7F9',
    SECONDARY_TABLE_ROW: '#FDFEFF',
    DATE_PICKER_BACKGROUND: '#F8FAFD',
    SECONDARY_TEXT: '#5D5D5D',
    SECONDARY_BANNED: '#B3A9AC',
    MIDDLE_LIGHT_GREY: '#8F8F8F',
    MIDDLE_WHITE: '#E5E5E5',
};
export var FONT = {
    DEFAULT_FONT: 'Roboto, sans-serif',
    DEFAULT_FONT_SIZE: '12px',
};
export var HEADER_HEIGHT = 60;
export var TABLE_HEADER_HEIGHT = 33;
export var SCROLL_HEIGHT = 8;
export var ROW_HEIGHT = 26;
export var FIRST_LAST_ROW_MARGIN = 18;
export var MARGIN_BETWEEN_ROWS = 4;
export var StyledPageWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    min-height: 100vh;\n    background: ", ";\n    .banned-agent {\n      background: ", " !important;\n    }\n    & .page-btn-wrapper {\n      display: flex;\n      margin-bottom: 15px;\n    }\n  }\n"], ["\n  && {\n    min-height: 100vh;\n    background: ", ";\n    .banned-agent {\n      background: ", " !important;\n    }\n    & .page-btn-wrapper {\n      display: flex;\n      margin-bottom: 15px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bannedAgent;
});
export var StyledContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    padding: ", ";\n    height: calc(100vh - ", ");\n  }\n"], ["\n  && {\n    padding: ", ";\n    height: calc(100vh - ", ");\n  }\n"])), function (_a) {
    var currentPage = _a.currentPage;
    return (checkIsPaddingTopTable(currentPage) ? '20px 30px 0 30px' : '0 30px 0 30px');
}, HEADER_HEIGHT);
export var smooth = function () { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  animation: showDropDownList 200ms forwards;\n\n  @keyframes showDropDownList {\n    0% {\n      opacity: 0;\n      transform: translateY(-50px);\n    }\n    100% {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n"], ["\n  animation: showDropDownList 200ms forwards;\n\n  @keyframes showDropDownList {\n    0% {\n      opacity: 0;\n      transform: translateY(-50px);\n    }\n    100% {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n"]))); };
export var scrollStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ::-webkit-scrollbar {\n    height: 8px;\n    width: 8px;\n  }\n\n  ::-webkit-scrollbar-track {\n    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;\n    border-radius: 10px;\n    margin-right: 5px;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    // @ts-ignore\n    background: linear-gradient(180deg, ", ", ", ");\n  }\n"], ["\n  ::-webkit-scrollbar {\n    height: 8px;\n    width: 8px;\n  }\n\n  ::-webkit-scrollbar-track {\n    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;\n    border-radius: 10px;\n    margin-right: 5px;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    // @ts-ignore\n    background: linear-gradient(180deg, ", ", ", ");\n  }\n"])), theme.colors.scroll, theme.colors.scroll);
};
export var StyledModalFooterTwoBtnWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: space-between;\n  }\n"])));
export var StyledModalFooterOneBtnWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: flex-end;\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: flex-end;\n  }\n"])));
export var StyledModalContentWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n"])));
export var StyledModalContentFieldsWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n  }\n"], ["\n  && {\n  }\n"])));
export var StyledModalWrapperMedia = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    @media screen and (max-width: 1200px) {\n      width: 60vw;\n    }\n    @media screen and (max-width: 1000px) {\n      width: 70vw;\n    }\n    @media screen and (max-width: 700px) {\n      width: 80vw;\n    }\n  }\n"], ["\n  && {\n    @media screen and (max-width: 1200px) {\n      width: 60vw;\n    }\n    @media screen and (max-width: 1000px) {\n      width: 70vw;\n    }\n    @media screen and (max-width: 700px) {\n      width: 80vw;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
