import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { notificationActions } from '@src/redux/notification/reducer';
import { StyledOverlay } from './styled';
var Overlay = function (_a) {
    var resetNotificationState = _a.resetNotificationState;
    var ref = useRef(null);
    var onOverlayHandler = function () { return resetNotificationState(); };
    useEffect(function () {
        var _a;
        (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener('click', onOverlayHandler);
        return function () { var _a; return (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', onOverlayHandler); };
    });
    return (React.createElement(StyledOverlay, { id: "overlay", ref: ref }));
};
var mapDispatchToProps = function (dispatch) { return ({
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(null, mapDispatchToProps)(Overlay);
