var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState, } from 'react';
import ReactDOM from 'react-dom';
import { icons } from '@public/images';
import { ModalWindowHeader, ModalWindowTitle, ModalWindowWrapper, ModalWindowContent, ModalWindowContentWrap, } from '@src/components/ModalWindow/styled';
import { useOutsideClick } from '@src/utils/useOutsideClick';
var ModalWindow = function (props) {
    var children = props.children, _a = props.title, title = _a === void 0 ? 'Title' : _a, _b = props.outsideClick, outsideClick = _b === void 0 ? true : _b, closeModalWindow = props.closeModalWindow;
    var _c = __read(useState(document.createElement('div')), 1), container = _c[0];
    useEffect(function () {
        document.body.appendChild(container);
        return function () {
            document.body.removeChild(container);
        };
    }, []);
    var ref = useRef();
    useOutsideClick(closeModalWindow, ref, ['customPopoverText'], outsideClick);
    return ReactDOM.createPortal(React.createElement(ModalWindowWrapper, null,
        React.createElement(ModalWindowContentWrap, { ref: ref },
            React.createElement(ModalWindowContent, null,
                React.createElement(ModalWindowHeader, null,
                    React.createElement("img", { onClick: closeModalWindow, src: icons.close, alt: "closeModalWindow" })),
                React.createElement(ModalWindowTitle, null, title),
                children))), container);
};
export default ModalWindow;
