import { connect } from 'react-redux';
import { tagsActions } from '@src/modules/Pages/Tags/redux/reducer';
import tagsStore from '@src/modules/Pages/Tags/redux/selector';
import TagsUpdateModal from '@src/modules/Pages/Tags/TagsUpdateModal/TagsUpdateModal';
import { loadingStore } from '@src/redux/loading/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { notificationActions } from '@src/redux/notification/reducer';
var mapStateToProps = function (state) { return ({
    currentTag: tagsStore.getCurrentTag(state),
    assignedAgentTags: tagsStore.getAssignedAgentTags(state),
    unAssignedAgentTags: tagsStore.getUnAssignedAgentTags(state),
    isLoadingCurrentTags: loadingStore.getIsLoadingCurrentTags(state),
    isModalOpenUsefulLinks: modalWindowsStore.getIsModalOpen(state, modalTypes.USEFUL_LINKS_MODAL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    resetCurrentTag: function (data) { return dispatch(tagsActions.setCurrentTag(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    updateTagSettings: function (data) { return dispatch(tagsActions.updateTagSettings(data)); },
    toggleModalUpdateTags: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_TAGS_MODAL, null)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    toggleModalUsefulLinks: function (data) {
        return dispatch(modalWindowsActions.toggleModal(modalTypes.USEFUL_LINKS_MODAL, data));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TagsUpdateModal);
