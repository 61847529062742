var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Messages } from '@public/locales/messages';
import Steps from '@src/components/Steps/Steps';
import StepOne from '@src/modules/Pages/Clients/CreateClient/StepOne';
import StepTwo from '@src/modules/Pages/Clients/CreateClient/StepTwo';
import StepThree from '@src/modules/Pages/Clients/CreateClient/StepThree';
import ModalWindow from '@src/components/ModalWindow';
import { StyledCreateClientWrapper } from '@src/modules/Pages/Clients/CreateClient/styled';
var CreateClient = function (props) {
    var toggleModal = props.toggleModal, brandDictionary = props.brandDictionary;
    var _a = __read(useState(0), 2), currentStep = _a[0], setCurrentStep = _a[1];
    var _b = __read(useState({ value: '', text: '' }), 2), selectedBrand = _b[0], setSelectedBrand = _b[1];
    var _c = __read(useState({ value: '', text: '' }), 2), selectedMessengers = _c[0], setSelectedMessengers = _c[1];
    var _d = __read(useState(''), 2), phone = _d[0], setPhone = _d[1];
    var _e = __read(useState(''), 2), email = _e[0], setEmail = _e[1];
    var _f = __read(useState(''), 2), name = _f[0], setName = _f[1];
    var _g = __read(useState(''), 2), lastName = _g[0], setLastName = _g[1];
    var _h = __read(useState(''), 2), firstName = _h[0], setFirstName = _h[1];
    return (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: Messages.Labels.CreateClient },
        React.createElement(StyledCreateClientWrapper, null,
            React.createElement(Steps, { items: [{ title: Messages.Labels.General }, { title: Messages.Labels.Contacts }, { title: Messages.Labels.Personal }], current: currentStep }),
            currentStep === 0 && (React.createElement(StepOne, { currentStep: currentStep, selectedBrand: selectedBrand, setCurrentStep: setCurrentStep, brandDictionary: brandDictionary, setSelectedBrand: setSelectedBrand, selectedMessengers: selectedMessengers, setSelectedMessengers: setSelectedMessengers })),
            currentStep === 1 && (React.createElement(StepTwo, { currentBrand: selectedBrand, phone: phone, email: email, setPhone: setPhone, setEmail: setEmail, currentStep: currentStep, setCurrentStep: setCurrentStep, selectedMessengers: selectedMessengers })),
            currentStep === 2 && (React.createElement(StepThree, { name: name, phone: phone, email: email, setName: setName, lastName: lastName, firstName: firstName, setLastName: setLastName, currentStep: currentStep, setFirstName: setFirstName, selectedBrand: selectedBrand, setCurrentStep: setCurrentStep, selectedMessengers: selectedMessengers })))));
};
export default CreateClient;
