var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledHistoryComponentLoader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    background-color: ", ";\n    height: 70px;\n    position: relative;\n  }\n"], ["\n  && {\n    background-color: ", ";\n    height: 70px;\n    position: relative;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
var templateObject_1;
