var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles } from '@src/app/styled';
import { SessionStatus } from '@src/global-interfaces';
var DODGER_BLUE = COLORS.DODGER_BLUE, DODGER_BLUE_OPACITY = COLORS.DODGER_BLUE_OPACITY, GREY = COLORS.GREY, GREEN_DARKER = COLORS.GREEN_DARKER, GREY_BLUE = COLORS.GREY_BLUE;
export var SessionStatusesWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  align-items: center;\n\n  & > .session-status__circle {\n    width: ", ";\n    height: ", ";\n    font-size: ", ";\n    border-radius: 50%;\n    background: none;\n    border: 1px solid\n      ", ";\n    text-align: center;\n    color: ", ";\n    margin-right: 10px;\n    cursor: pointer;\n    user-select: none;\n  }\n\n  & > .session-status__title {\n    display: flex;\n    padding-right: 5px;\n    align-items: center;\n    //line-height: 0;\n    font-size: 10px;\n  }\n"], ["\n  color: ", ";\n  display: flex;\n  align-items: center;\n\n  & > .session-status__circle {\n    width: ", ";\n    height: ", ";\n    font-size: ", ";\n    border-radius: 50%;\n    background: none;\n    border: 1px solid\n      ", ";\n    text-align: center;\n    color: ", ";\n    margin-right: 10px;\n    cursor: pointer;\n    user-select: none;\n  }\n\n  & > .session-status__title {\n    display: flex;\n    padding-right: 5px;\n    align-items: center;\n    //line-height: 0;\n    font-size: 10px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var size = _a.size;
    return (size === 'small' ? '14px' : '18px');
}, function (_a) {
    var size = _a.size;
    return (size === 'small' ? '14px' : '18px');
}, function (_a) {
    var size = _a.size;
    return (size === 'small' ? '9px' : 'auto');
}, function (_a) {
    var status = _a.status;
    return status === SessionStatus.online ? GREEN_DARKER : (status === SessionStatus.break ? DODGER_BLUE : GREY);
}, function (_a) {
    var status = _a.status;
    return status === SessionStatus.online ? GREEN_DARKER : (status === SessionStatus.break ? DODGER_BLUE : GREY);
});
export var StyledStatistics = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
export var StatisticsByDaysStyledWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 250px;\n  overflow-y: auto;\n  width: 55%;\n  ", "\n"], ["\n  height: 250px;\n  overflow-y: auto;\n  width: 55%;\n  ", "\n"])), scrollStyles);
export var StatisticsForDayStyledWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-height: 250px;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  //padding-right: 25px;\n  & > div {\n    margin-top: 10px;\n    &:first-of-type {\n      margin-top: 0;\n    }\n  }\n  ", "\n"], ["\n  max-height: 250px;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  //padding-right: 25px;\n  & > div {\n    margin-top: 10px;\n    &:first-of-type {\n      margin-top: 0;\n    }\n  }\n  ", "\n"])), scrollStyles);
export var DayBlockWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  max-width: 160px;\n  border: 1px solid ", ";\n  background: ", ";\n  box-sizing: border-box;\n  border-radius: 8px;\n  padding: 12px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  height: 85px;\n  cursor: pointer;\n  transition: 0.2s;\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n  .day-block__day {\n  }\n  .day-block__time-by-status {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n  }\n  &:last-child {\n    //margin-bottom: 0;\n  }\n"], ["\n  max-width: 160px;\n  border: 1px solid ", ";\n  background: ", ";\n  box-sizing: border-box;\n  border-radius: 8px;\n  padding: 12px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  height: 85px;\n  cursor: pointer;\n  transition: 0.2s;\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n  .day-block__day {\n  }\n  .day-block__time-by-status {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n  }\n  &:last-child {\n    //margin-bottom: 0;\n  }\n"])), function (_a) {
    var isChecked = _a.isChecked;
    return (isChecked ? DODGER_BLUE : GREY_BLUE);
}, function (_a) {
    var isChecked = _a.isChecked;
    return (isChecked ? DODGER_BLUE_OPACITY : 'none');
}, DODGER_BLUE_OPACITY, DODGER_BLUE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
