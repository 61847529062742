import { connect } from 'react-redux';
import brandsStore from '@src/modules/Pages/Brands/redux/selector';
import { authStore } from '@src/modules/Auth/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import BrandSettings from '@src/modules/Pages/Brands/BrandSettings/BrandSettings';
var mapStateToProps = function (state) { return ({
    roles: authStore.roles(state),
    currentBrand: brandsStore.getCurrentBrand(state),
    smsMessengers: brandsStore.getSmsMessengers(state),
    emailMessengers: brandsStore.getEmailMessengers(state),
    isLoadingCreateInbox: loadingStore.getIsLoadingCreateInbox(state),
    isLoadingCurrentBrand: loadingStore.getIsLoadingCurrentBrand(state),
    isModalOpenCreateSmsInbox: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_SMS_INBOX),
    isModalOpenUpdateSmsInbox: modalWindowsStore.getIsModalOpen(state, modalTypes.UPDATE_SMS_INBOX),
    telegramPrivateMessengers: brandsStore.getTelegramPrivateMessengers(state),
    isModalOpenCreateMailInbox: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_MAIL_INBOX),
    isModalOpenUpdateMailInbox: modalWindowsStore.getIsModalOpen(state, modalTypes.UPDATE_MAIL_INBOX),
    isModalOpenUpdateTelegramInbox: modalWindowsStore.getIsModalOpen(state, modalTypes.UPDATE_TELEGRAM_INBOX),
    isModalOpenCreateTelegramInbox: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_TELEGRAM_INBOX),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.SETTINGS_BRAND, null)); },
    deleteInbox: function (data) { return dispatch(brandsActions.deleteInbox(data)); },
    setCurrentBrand: function (data) { return dispatch(brandsActions.setCurrentBrand(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    updateCurrentBrand: function (data) { return dispatch(brandsActions.updateCurrentBrand(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    toggleModalCreateSmsInbox: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_SMS_INBOX, null)); },
    toggleModalUpdateSmsInbox: function (inbox) { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_SMS_INBOX, inbox)); },
    toggleModalCreateMailInbox: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_MAIL_INBOX, null)); },
    toggleModalUpdateMailInbox: function (inbox) { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_MAIL_INBOX, inbox)); },
    toggleModalUpdateTelegramInbox: function (inbox) { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_TELEGRAM_INBOX, inbox)); },
    toggleModalCreateTelegramInbox: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_TELEGRAM_INBOX, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(BrandSettings);
