import { connect } from 'react-redux';
import { notificationActions } from '@src/redux/notification/reducer';
import { toolBarActions } from '@src/modules/ToolBar/redux/reducer';
import CustomDatePicker from './CustomDatePicker';
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    setShowSelectedFilter: function (data) { return dispatch(toolBarActions.setShowSelectedFilter(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(null, mapDispatchToProps)(CustomDatePicker);
