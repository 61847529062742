import React from 'react';
import { IPages } from '@src/global-interfaces';
import { appStore } from '@src/redux/app/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { connect } from 'react-redux';
import { mapDialogsData } from '@src/modules/Pages/Dialogs/tableData/dataFormatter';
import { mapTagsData } from '@src/modules/Pages/Tags/tableData/dataFormatter';
import { mapClientsData } from '@src/modules/Pages/Clients/tableData/dataFormatter';
import { mapChannelData } from '@src/modules/Pages/Channels/tableData/dataFormatter';
import { mapBrandsData } from '@src/modules/Pages/Brands/tableData/dataFormatter';
import { mapAgentsData } from '@src/modules/Pages/Agents/tableData/dataFormatter';
import { mapAgentGroupsData } from '@src/modules/Pages/Groups/tableData/dataFormatter';
import { mapAgentsReportData, mapGroupsReportData, mapMessengersData, } from '@src/modules/Pages/Reports/components/ReportsTables/tableData/dataFormatter';
import { dialogColumns } from '@src/modules/Pages/Dialogs/tableData/columns';
import { tagColumns } from '@src/modules/Pages/Tags/tableData/columns';
import { groupsColumns } from '@src/modules/Pages/Groups/tableData/columns';
import { clientsColumns } from '@src/modules/Pages/Clients/tableData/columns';
import { brandColumns } from '@src/modules/Pages/Brands/tableData/columns';
import { agentColumns } from '@src/modules/Pages/Agents/tableData/columns';
import { channelsColumns } from '@src/modules/Pages/Channels/tableData/columns';
import { entityStore } from '@src/redux/entities/selectors';
import { tableOptionsStore } from '@src/redux/tableOptions/selector';
import { entitiesActions } from '@src/redux/entities/reducers';
import { reportAgentsColumns, reportGroupsColumns, reportMessengerColumns, } from '@src/modules/Pages/Reports/components/ReportsTables/tableData/columns';
import Table from '@src/components/Table';
import { dialogActions } from '@src/modules/Pages/Dialogs/redux/reducer';
import { loadingStore } from '@src/redux/loading/selector';
import { agentsActions } from '@src/modules/Pages/Agents/redux/reducer';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { tagsActions } from '@src/modules/Pages/Tags/redux/reducer';
import { reportsActions } from '@src/modules/Pages/Reports/redux/reducer';
import { clientsActions } from '@src/modules/Pages/Clients/redux/reducer';
import { brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import { channelsActions } from '@src/modules/Pages/Channels/redux/reducer';
var TableContent = function (props) {
    var entity = props.entity, sorting = props.sorting, isLoading = props.isLoading, filtering = props.filtering, pagination = props.pagination, currentPage = props.currentPage, dictionaries = props.dictionaries, getTableData = props.getTableData, showClientInfo = props.showClientInfo, showTagsSettings = props.showTagsSettings, showCurrentBrand = props.showCurrentBrand, getMessagesDialog = props.getMessagesDialog, showReportByAgent = props.showReportByAgent, showAgentSettings = props.showAgentSettings, showGroupsSettings = props.showGroupsSettings, showCurrentCurrent = props.showCurrentCurrent;
    var columns;
    var mappedData;
    var rowSettings;
    var getRowId;
    switch (currentPage) {
        case IPages.dialogs:
            columns = dialogColumns;
            mappedData = mapDialogsData(entity, dictionaries);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return getMessagesDialog(id); };
            break;
        case IPages.groups:
            columns = groupsColumns;
            mappedData = mapAgentGroupsData(entity, dictionaries);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return showGroupsSettings(id); };
            break;
        case IPages.agents:
            columns = agentColumns;
            mappedData = mapAgentsData(entity, dictionaries);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return showAgentSettings(id); };
            break;
        case IPages.tags:
            columns = tagColumns;
            mappedData = mapTagsData(entity);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return showTagsSettings(id); };
            break;
        case IPages.clients:
            columns = clientsColumns;
            mappedData = mapClientsData(entity, dictionaries);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return showClientInfo(id); };
            break;
        case IPages.channels:
            columns = channelsColumns;
            mappedData = mapChannelData(entity);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return showCurrentCurrent(id); };
            break;
        case IPages.brands:
            columns = brandColumns;
            mappedData = mapBrandsData(entity);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return showCurrentBrand(id); };
            break;
        case IPages.messengerReports:
            columns = reportMessengerColumns;
            mappedData = mapMessengersData(entity);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return id; };
            break;
        case IPages.agentsReports:
            columns = reportAgentsColumns;
            mappedData = mapAgentsReportData(entity, dictionaries);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return showReportByAgent(id); };
            break;
        case IPages.groupsReports:
            columns = reportGroupsColumns;
            mappedData = mapGroupsReportData(entity, dictionaries);
            rowSettings = { showCursor: true, rowHover: true };
            getRowId = function (event, id) { return id; };
            break;
        default:
            return null;
    }
    return (React.createElement(Table, { columns: columns, isLoading: isLoading, pagination: pagination, sorting: sorting, dataSource: mappedData, getTableData: getTableData, rowSettings: rowSettings, getRowId: getRowId, filtering: filtering }));
};
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    isLoading: loadingStore.getIsLoading(state),
    dictionaries: dictionariesStore.dictionaries(state),
    pagination: tableOptionsStore.pagination(state),
    sorting: tableOptionsStore.sorting(state),
    filtering: tableOptionsStore.filtering(state),
    entity: entityStore.entity(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    getTableData: function (data) { return dispatch(entitiesActions.getTableData(data)); },
    getMessagesDialog: function (id) { return dispatch(dialogActions.getMessagesDialog(id)); },
    showAgentSettings: function (id) { return dispatch(agentsActions.showAgentSettings(id)); },
    showReportByAgent: function (id) { return dispatch(reportsActions.showReportByAgent(id)); },
    showTagsSettings: function (id) { return dispatch(tagsActions.showTagsSettings(id)); },
    showGroupsSettings: function (id) { return dispatch(groupsActions.showGroupsSettings(id)); },
    showClientInfo: function (id) { return dispatch(clientsActions.showClientInfo(id)); },
    showCurrentBrand: function (id) { return dispatch(brandsActions.showCurrentBrand(id)); },
    showCurrentCurrent: function (id) { return dispatch(channelsActions.showCurrentCurrent(id)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TableContent);
