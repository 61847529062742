var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
var WHITE = COLORS.WHITE;
export var StyledImportClientWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 40vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 40vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledSendFileLabelWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    margin-top: 24px;\n    padding-bottom: 5px;\n  }\n"], ["\n  && {\n    margin-top: 24px;\n    padding-bottom: 5px;\n  }\n"])));
export var StyledImportClientsWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    background: ", ";\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n    padding: 10px 12px;\n  }\n"], ["\n  && {\n    background: ", ";\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n    padding: 10px 12px;\n  }\n"])), WHITE);
export var StyledImportClientsFileName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    margin-left: 8px;\n  }\n"], ["\n  && {\n    margin-left: 8px;\n  }\n"])));
export var SendFileButtonWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    max-width: max-content;\n    max-height: max-content;\n    border-radius: 8px;\n    background: transparent;\n    cursor: pointer;\n    transition: 0.5s;\n  }\n"], ["\n  && {\n    max-width: max-content;\n    max-height: max-content;\n    border-radius: 8px;\n    background: transparent;\n    cursor: pointer;\n    transition: 0.5s;\n  }\n"])));
export var SendFileButtonInputLabel = styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    justify-content: center;\n\n    img {\n      width: 15px;\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    justify-content: center;\n\n    img {\n      width: 15px;\n    }\n  }\n"])));
export var SendFileButtonInput = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    opacity: 0;\n    width: 0;\n    height: 0;\n    position: absolute;\n    z-index: -1;\n  }\n"], ["\n  && {\n    opacity: 0;\n    width: 0;\n    height: 0;\n    position: absolute;\n    z-index: -1;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
