import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import GroupsUpdateModal from './GroupsUpdateModal';
var mapStateToProps = function (state) { return ({
    currentGroup: groupsStore.getCurrentGroup(state),
    isLoadingCurrentGroups: loadingStore.getIsLoadingCurrentGroups(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_GROUPS_MODAL, null)); },
    resetCurrentGroup: function (data) { return dispatch(groupsActions.setCurrentGroup(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GroupsUpdateModal);
