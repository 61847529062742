var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SORTING_TYPES, } from '@src/global-interfaces';
import { tableOptionsActions } from '@src/redux/tableOptions/reducer';
import { StyledSorting } from './StyledSorting';
var Sorting = function (_a) {
    var columnKey = _a.columnKey, sorting = _a.sorting, activeColumn = _a.activeColumn, setActiveColumn = _a.setActiveColumn, updateSorting = _a.updateSorting;
    var _b = __read(useState(sorting), 2), sortingState = _b[0], setSortingState = _b[1];
    var isCurAscSort = columnKey === activeColumn && sortingState.order === SORTING_TYPES.ASC;
    var isCurDescSort = columnKey === activeColumn && sortingState.order === SORTING_TYPES.DESC;
    var setDefaultSorting = function () {
        var sorting = {
            column: '',
            order: SORTING_TYPES.DEFAULT,
        };
        setActiveColumn('');
        setSortingState(sorting);
        updateSorting(sorting);
    };
    var ascSortingHandler = function () {
        var sorting = {
            column: columnKey,
            order: SORTING_TYPES.ASC,
        };
        if (isCurAscSort) {
            setDefaultSorting();
            return;
        }
        setActiveColumn(columnKey);
        setSortingState(sorting);
        updateSorting(sorting);
    };
    var descSortingHandler = function () {
        var sorting = {
            column: columnKey,
            order: SORTING_TYPES.DESC,
        };
        if (isCurDescSort) {
            setDefaultSorting();
            return;
        }
        setActiveColumn(columnKey);
        setSortingState(sorting);
        updateSorting(sorting);
    };
    return (React.createElement(StyledSorting, { isCurAscSort: isCurAscSort, isCurDescSort: isCurDescSort },
        React.createElement("div", { className: "asc-sort", onClick: ascSortingHandler },
            React.createElement("svg", { "aria-hidden": "true", focusable: "false", "data-prefix": "fas", "data-icon": "caret-up", className: "svg-inline--fa fa-caret-up fa-w-10", role: "img", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 320 512" },
                React.createElement("path", { d: "M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" }))),
        React.createElement("div", { className: "desc-sort", onClick: descSortingHandler },
            React.createElement("svg", { "aria-hidden": "true", focusable: "false", "data-prefix": "fas", "data-icon": "caret-up", className: "svg-inline--fa fa-caret-up fa-w-10", role: "img", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 320 512" },
                React.createElement("path", { d: "M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" })))));
};
var mapDispatchToProps = function (dispatch) { return ({
    updateSorting: function (sorting) { return dispatch(tableOptionsActions.updateSorting(sorting)); },
}); };
export default connect(null, mapDispatchToProps)(Sorting);
