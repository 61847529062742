var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledToolBarWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    min-height: 48px;\n    padding: 10px 15px;\n    margin-bottom: 2px;\n    color: ", ";\n    background: ", ";\n    align-items: center;\n    border-top-left-radius: 6px;\n    border-top-right-radius: 6px;\n  }\n"], ["\n  && {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    min-height: 48px;\n    padding: 10px 15px;\n    margin-bottom: 2px;\n    color: ", ";\n    background: ", ";\n    align-items: center;\n    border-top-left-radius: 6px;\n    border-top-right-radius: 6px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.tableHeader;
});
export var StyledCountAgentsNewBees = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n  }\n"], ["\n  && {\n  }\n"])));
export var StyledSaveExcelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    display: flex;\n  }\n"], ["\n  && {\n    display: flex;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
