var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledSaveBtn } from '@src/components/Button/styled';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { authActions } from '@src/modules/Auth/redux/reducer';
import { resetPasswordPageActions } from '@src/modules/Pages/ResetPassword/redux/reducer';
import { ResetPasswordBackDisplay, ResetPasswordInput, ResetPasswordTitle, ResetPasswordWrapper, } from '@src/modules/Pages/ResetPassword/styled';
import getAllUrlParams from '@src/utils/getAllUrlParams';
import { composeValidators, isRequired, lengthCreator, passwordValidation } from '@src/utils/validations';
function ResetPassword() {
    var dispatch = useDispatch();
    var resetPasswordInput = InputWithStyles(Input, ResetPasswordInput);
    var _a = __read(useState(''), 2), token = _a[0], setToken = _a[1];
    useEffect(function () {
        if (getAllUrlParams().token) {
            setToken(getAllUrlParams().token);
        }
        else {
            dispatch(authActions.logout());
        }
        dispatch(resetPasswordPageActions.sendCheckIsValidToken(getAllUrlParams().token));
    }, []);
    var minPasswordLength = lengthCreator.minPasswordLength, maxPasswordLength = lengthCreator.maxPasswordLength;
    var _b = __read(useState({
        password: 'password',
        repeatPassword: 'password',
    }), 2), passwordInput = _b[0], setPasswordInput = _b[1];
    var passwordMatching = function (values) {
        var errors = {};
        if (values.password !== values.repeatPassword) {
            Object.assign(errors, { repeatPassword: Messages.Errors.PasswordMatching });
        }
        return errors;
    };
    var submitResetPassword = function (_a) {
        var password = _a.password, repeatPassword = _a.repeatPassword;
        dispatch(resetPasswordPageActions.sendNewPassword({ token: token, password: password, confirmPassword: repeatPassword }));
    };
    var showPassword = function (inputName) {
        var _a;
        var value = passwordInput[inputName] === 'text' ? 'password' : 'text';
        setPasswordInput(__assign(__assign({}, passwordInput), (_a = {}, _a[inputName] = value, _a)));
    };
    return (React.createElement(ResetPasswordBackDisplay, null,
        React.createElement(ResetPasswordWrapper, null,
            React.createElement(ResetPasswordTitle, null, "Reset password"),
            React.createElement(Form, { onSubmit: submitResetPassword, validate: passwordMatching, render: function (_a) {
                    var handleSubmit = _a.handleSubmit, submitting = _a.submitting, pristine = _a.pristine;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(Field, { name: "password", type: passwordInput.password, label: "Create new password", validate: composeValidators(isRequired, minPasswordLength, maxPasswordLength, passwordValidation), placeholder: "Create new password", component: resetPasswordInput, icon: passwordInput.password === 'text' ? icons.notShowPassword : icons.showPassword, iconOnclick: function () { return showPassword(Object.keys(passwordInput)[0]); } }),
                        React.createElement(Field, { name: "repeatPassword", type: passwordInput.repeatPassword, label: "Re-enter new password", validate: composeValidators(isRequired, minPasswordLength, maxPasswordLength, passwordValidation), placeholder: "Re-enter new password", component: resetPasswordInput, icon: passwordInput.repeatPassword === 'text' ? icons.notShowPassword : icons.showPassword, iconOnclick: function () { return showPassword(Object.keys(passwordInput)[1]); } }),
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.resetPassword, onClick: function () { }, disabled: !submitting || pristine, type: TButtonTypes.submit }))));
                } }))));
}
export default ResetPassword;
