var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState, } from 'react';
import { isEqual } from 'lodash';
import { icons } from '@public/images';
import { allFiltersName } from '@src/constants/allFilters';
import { isEqualObject } from '@src/utils/isEqualObject';
import Icon from '@src/components/Icon';
import Button from '@src/components/Button';
import ComponentsFiltration from '@src/modules/ToolBar/ToolBarUI/Filtration/ComponentsFiltration';
import { StyledSelectedFilterBtn } from '@src/components/Button/styled';
import { SelectedFilterWrapper, SelectFilterDataContainer, } from '@src/modules/ToolBar/ToolBarUI/Filtration/SelectedFilters/styled';
export var SelectedFilter = function (props) {
    var title = props.title, clearFilter = props.clearFilter, onChangeShowSelectedFilter = props.onChangeShowSelectedFilter, currentPage = props.currentPage, showSelectedFilter = props.showSelectedFilter, setShowSelectedFilter = props.setShowSelectedFilter, setIsShowSearchFilterList = props.setIsShowSearchFilterList;
    var _a = __read(useState(false), 2), isShow = _a[0], setShow = _a[1];
    var onClickSelectedFilter = useCallback(function () {
        if (isEqualObject(showSelectedFilter, { currentPage: currentPage, filter: title })) {
            setShowSelectedFilter(null);
            setShow(false);
            return;
        }
        setShow(true);
        onChangeShowSelectedFilter({
            currentPage: currentPage,
            filter: title,
        });
        setIsShowSearchFilterList(false);
    }, [currentPage, showSelectedFilter]);
    return (React.createElement(SelectedFilterWrapper, null,
        React.createElement(StyledSelectedFilterBtn, null,
            React.createElement(Button, { title: allFiltersName[title], onClick: onClickSelectedFilter },
                React.createElement(Icon, { src: icons.close, styles: { width: '10px' }, onClick: function () { return clearFilter(title); } }))),
        (isEqualObject(showSelectedFilter, { currentPage: currentPage, filter: title }) && isShow) && (React.createElement(SelectFilterDataContainer, null,
            React.createElement(ComponentsFiltration, { selectedFilter: title, setShow: setShow })))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(SelectedFilter, areEqual);
