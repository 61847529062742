var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo, useState } from 'react';
import { Messages } from '@public/locales/messages';
import Loader from '@src/components/Loader';
import Select from '@src/components/Select';
import { Tab, Tabs } from '@src/components/Tabs/Tabs';
import { AllWebChatWindows, enabledLanguagesChannel } from '@src/global-interfaces';
import Auth from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatWidows/Auth/Auth';
import Chat from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatWidows/Chat/Chat';
import Icon from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatWidows/Icon/Icon';
import { StyledAskMeWebChat, StyledSelectWrapper, StyledViewAskMeWebChat, StyledWebChatWindowWrapper, } from '@src/modules/Pages/Channels/ChannelUpdate/styled';
var LayoutWebChat = function (props) {
    var greeting = props.greeting, privacyPolicy = props.privacyPolicy, requiredFields = props.requiredFields, botNameLocales = props.botNameLocales, customizations = props.customizations, positionScroll = props.positionScroll, selectedLanguages = props.selectedLanguages, isLoadingLayoutWebChat = props.isLoadingLayoutWebChat;
    var memoizedSelectedLanguages = useMemo(function () {
        if (typeof selectedLanguages[0] === 'string') {
            return selectedLanguages.map(function (elem) { return enabledLanguagesChannel.find(function (el) { return el.value === elem; }); });
        }
        return selectedLanguages;
    }, [selectedLanguages]);
    var _a = __read(useState(AllWebChatWindows.chat), 2), currentTab = _a[0], setCurrentTab = _a[1];
    var _b = __read(useState(null), 2), currentLanguage = _b[0], setCurrentLanguage = _b[1];
    useEffect(function () {
        setCurrentLanguage([memoizedSelectedLanguages[0]]);
    }, [memoizedSelectedLanguages, selectedLanguages]);
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    return (React.createElement(StyledViewAskMeWebChat, { positionScroll: positionScroll },
        currentLanguage && (React.createElement(StyledSelectWrapper, null,
            React.createElement(Select, { label: Messages.Labels.SelectLanguageWebChat, items: memoizedSelectedLanguages, stateCB: function (el) { return setCurrentLanguage([el]); }, isMultiply: false, placeholder: Messages.Selects.SelectLanguageWebChat, initialState: currentLanguage }))),
        isLoadingLayoutWebChat ? (React.createElement(StyledAskMeWebChat, null,
            React.createElement(Loader, null))) : (React.createElement(Tabs, { callback: onTabChangeHandler, currentTab: currentTab, isShowBottomLine: true },
            React.createElement(Tab, { tabKey: AllWebChatWindows.chat, title: AllWebChatWindows.chat },
                React.createElement(StyledWebChatWindowWrapper, null,
                    React.createElement(Chat, { botNameLocales: botNameLocales, greeting: greeting, currentLanguage: currentLanguage, customizations: customizations }))),
            React.createElement(Tab, { tabKey: AllWebChatWindows.authorisation, title: AllWebChatWindows.authorisation },
                React.createElement(StyledWebChatWindowWrapper, null,
                    React.createElement(Auth, { lang: currentLanguage, privacyPolicy: privacyPolicy, requiredFields: requiredFields, customizations: customizations }))),
            React.createElement(Tab, { tabKey: AllWebChatWindows.minimised, title: AllWebChatWindows.minimised },
                React.createElement(StyledWebChatWindowWrapper, null,
                    React.createElement(Icon, { customizations: customizations })))))));
};
export default LayoutWebChat;
