export var convertDate = function (stringDate) {
    var date = new Date(stringDate);
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0".concat(date.getMonth() + 1),
        day: date.getDate() >= 10 ? date.getDate() : "0".concat(date.getDate()),
        hours: date.getHours() >= 10 ? date.getHours() : "0".concat(date.getHours()),
        minutes: date.getMinutes() >= 10 ? date.getMinutes() : "0".concat(date.getMinutes()),
    };
};
