import { Messages } from '@public/locales/messages';
export var MessengersFilters = [
    { name: 'Viber', value: 'viber' },
    { name: 'Wagrey', value: 'wagrey' },
    { name: 'Web Chat', value: 'smartbot' },
    { name: 'Telegram', value: 'telegram' },
    { name: 'Whatsapp', value: 'whatsapp' },
    { name: 'MetaDialog', value: 'metadialog' },
    { name: Messages.Controls.Messenger, value: 'messenger' },
    { name: Messages.Controls.Email, value: 'email' },
    { name: Messages.Controls.SMS, value: 'sms' },
];
export var StatusesFilters = [
    { name: Messages.Controls.New, value: 'new' },
    { name: Messages.Controls.Active, value: 'active' },
    { name: Messages.Controls.Finished, value: 'finished' },
    { name: Messages.Controls.Assigned, value: 'assigned' },
];
export var sessionStatusFilters = [
    { name: Messages.Controls.Online, value: 'online' },
    { name: Messages.Controls.Offline, value: 'offline' },
    { name: Messages.Controls.Break, value: 'break' },
];
export var LanguageFilters = [
    { name: Messages.Languages.Ukrainian, value: 'ua' },
    { name: Messages.Languages.Russian, value: 'ru' },
    { name: Messages.Languages.English, value: 'en' },
    { name: Messages.Languages.Polish, value: 'pl' },
    { name: Messages.Languages.Deutsch, value: 'de' },
    { name: Messages.Languages.Spanish, value: 'es' },
    { name: Messages.Languages.Vietnamese, value: 'vi' },
    { name: Messages.Languages.Portuguese, value: 'pt' },
    { name: Messages.Languages.Korean, value: 'ko' },
    { name: Messages.Languages.Italian, value: 'it' },
    { name: Messages.Languages.Arabic, value: 'ar' },
    { name: Messages.Languages.French, value: 'fr' },
    { name: Messages.Languages.Hungarian, value: 'hu' },
];
export var AccessLevelFilters = [
    { name: Messages.Controls.Low, value: 'low' },
    { name: Messages.Controls.Medium, value: 'medium' },
    { name: Messages.Controls.High, value: 'high' },
];
export var ActiveDialogsFilters = [
    { name: Messages.Controls.True, value: true },
    { name: Messages.Controls.False, value: false },
];
export var isNewbieFilters = [
    { name: Messages.Controls.True, value: true },
    { name: Messages.Controls.False, value: false },
];
var dialogsFilters = [
    { name: Messages.Controls.Date, value: 'date' },
    { name: Messages.Controls.AgentName, value: 'agentNames' },
    { name: Messages.Controls.Messenger, value: 'messengers' },
    { name: Messages.Controls.Language, value: 'languages' },
    { name: Messages.Controls.Channel, value: 'channels' },
    { name: Messages.Controls.Tag, value: 'tags' },
    { name: Messages.Controls.Status, value: 'statuses' },
    { name: Messages.Controls.Brand, value: 'brands' },
];
var groupsFilters = [
    { name: Messages.Controls.AccessLevel, value: 'accessLevel' },
    { name: Messages.Controls.WhiteListTags, value: 'whiteListTags' },
    { name: Messages.Controls.Brand, value: 'brands' },
    { name: Messages.Controls.Date, value: 'date' },
];
var agentsFilters = [
    { name: Messages.Controls.Date, value: 'date' },
    { name: Messages.Controls.SessionStatus, value: 'sessionStatus' },
    { name: Messages.Controls.Brand, value: 'brands' },
    { name: Messages.Controls.Newbie, value: 'isNewbie' },
];
var tagsFilters = [
    { name: Messages.Controls.Brand, value: 'brands' },
];
var clientsFilters = [
    { name: Messages.Controls.AgentName, value: 'agentNames' },
    { name: Messages.Controls.Brand, value: 'brands' },
    { name: Messages.Controls.Messenger, value: 'messengers' },
    { name: Messages.Controls.LastTags, value: 'lastTags' },
    { name: Messages.Controls.HasActiveDialogs, value: 'hasActiveDialogs' },
];
var clientsSearchValues = [
    { name: Messages.Controls.Email, value: 'email' },
    { name: Messages.Controls.FirstName, value: 'first_name' },
    { name: Messages.Controls.LastName, value: 'last_name' },
    { name: Messages.Controls.InvoiceNumber, value: 'invoice_number' },
];
var channelSearchValues = [
    { name: Messages.Controls.ChannelName, value: 'ChannelName' },
];
var brandSearchValues = [
    { name: Messages.Controls.BrandName, value: 'BrandName' },
];
var agentsReportsSearchValues = [
    { name: Messages.Controls.AgentName, value: 'AgentName' },
];
var channelsFilters = [
    { name: Messages.Controls.Brand, value: 'brands' },
    { name: Messages.Controls.Language, value: 'languages' },
];
var messengerReportsFilters = [
    { name: Messages.Controls.Brand, value: 'brands' },
    { name: Messages.Controls.Date, value: 'date' },
];
var agentsReportsFilters = [
    { name: Messages.Controls.Brand, value: 'brands' },
    { name: Messages.Controls.Date, value: 'date' },
];
var groupsReportsFilters = [
    { name: Messages.Controls.Brand, value: 'brands' },
    { name: Messages.Controls.Date, value: 'date' },
];
export var allFilters = {
    dialogs: dialogsFilters,
    groups: groupsFilters,
    agents: agentsFilters,
    tags: tagsFilters,
    clients: clientsFilters,
    channels: channelsFilters,
    brands: [],
    messengerReports: messengerReportsFilters,
    agentsReports: agentsReportsFilters,
    groupsReports: groupsReportsFilters,
};
export var isSearchOfValue = {
    dialogs: false,
    groups: false,
    agents: true,
    tags: false,
    clients: clientsSearchValues,
    channels: channelSearchValues,
    brands: brandSearchValues,
    messengerReports: false,
    agentsReports: agentsReportsSearchValues,
    groupsReports: false,
};
export var allFiltersName = {
    date: Messages.Controls.Date,
    messengers: Messages.Controls.Messenger,
    agentNames: Messages.Controls.AgentName,
    sessionStatus: Messages.Controls.SessionStatus,
    languages: Messages.Controls.Language,
    channels: Messages.Controls.Channel,
    tags: Messages.Controls.Tag,
    lastTags: Messages.Controls.LastTags,
    statuses: Messages.Controls.Status,
    brands: Messages.Controls.Brand,
    accessLevel: Messages.Controls.AccessLevel,
    whiteListTags: Messages.Controls.WhiteListTags,
    hasActiveDialogs: Messages.Controls.HasActiveDialogs,
    isNewbie: Messages.Controls.Newbie,
};
export var allSearchName = {
    name: Messages.Pages.Clients.TableColumns.SearchName,
    email: Messages.Pages.Clients.TableColumns.WriteEmail,
    invoiceNumber: Messages.Pages.Clients.TableColumns.InvoiceNumber,
    first_name: Messages.Controls.FirstName,
    invoice_number: Messages.Controls.InvoiceNumber,
    last_name: Messages.Controls.LastName,
    ChannelName: Messages.Selects.ChannelName,
    BrandName: Messages.Controls.BrandName,
    AgentName: Messages.Controls.AgentName,
};
