var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { takeEvery, put, fork, call, select, } from 'redux-saga/effects';
import { Messages } from '@public/locales/messages';
import { dialogsApi } from '@src/modules/Pages/Dialogs/redux/api';
import { clientsApi } from '@src/modules/Pages/Clients/redux/api';
import { brandsApi } from '@src/modules/Pages/Brands/redux/api';
import { entitiesActions } from '@src/redux/entities/reducers';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { clientsStore } from '@src/modules/Pages/Clients/redux/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { ClientPageTypes, clientsActions } from '@src/modules/Pages/Clients/redux/reducer';
import { errorNotification, successNotification } from '@src/redux/notification/saga';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
function getDialogsByClientId(id) {
    var clientDialogs, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(clientsApi.getDialogsByClientId, id)];
            case 1:
                clientDialogs = (_a.sent()).data;
                return [4, put(clientsActions.setClientDialogs(clientDialogs))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_1 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_1.text)];
            case 4:
                _a.sent();
                console.error(e_1.text);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getCurrentAudioCalls(id) {
    var audioCalls, currentAudioCalls, e_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, call(dialogsApi.getAudioCallsByDialogId, id)];
            case 1:
                audioCalls = (_b.sent()).data;
                return [4, select(clientsStore.getCurrentAudioCalls)];
            case 2:
                currentAudioCalls = _b.sent();
                return [4, put(clientsActions.setCurrentAudioCalls(__assign(__assign({}, currentAudioCalls), (_a = {}, _a[id] = __spreadArray([], __read(audioCalls), false), _a))))];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                e_2 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_2.text)];
            case 5:
                _b.sent();
                console.error(e_2.text);
                return [3, 6];
            case 6: return [2];
        }
    });
}
function getCurrentDialogMessages(id) {
    var overallCount, dialogMessages, currentDialogMessages, e_3;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4, call(dialogsApi.getMessagesByDialogId, { count: 0, offset: 0, dialogId: id })];
            case 1:
                overallCount = (_b.sent()).overallCount;
                return [4, call(dialogsApi.getMessagesByDialogId, { count: overallCount, offset: 0, dialogId: id })];
            case 2:
                dialogMessages = (_b.sent()).data;
                return [4, select(clientsStore.getCurrentDialogMessages)];
            case 3:
                currentDialogMessages = _b.sent();
                return [4, put(clientsActions.setCurrentDialogMessages(__assign(__assign({}, currentDialogMessages), (_a = {}, _a[id] = __spreadArray([], __read(dialogMessages), false), _a))))];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_3 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_3.text)];
            case 6:
                _b.sent();
                console.error(e_3.text);
                return [3, 7];
            case 7: return [2];
        }
    });
}
function showClientInfo(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, put(modalWindowsActions.toggleModal(modalTypes.CLIENT_INFO_MODAL, null))];
            case 1:
                _b.sent();
                return [4, call(getDialogsByClientId, payload)];
            case 2:
                _b.sent();
                return [2];
        }
    });
}
function showClientHistoryDialog(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, call(getCurrentDialogMessages, payload)];
            case 1:
                _b.sent();
                return [4, call(getCurrentAudioCalls, payload)];
            case 2:
                _b.sent();
                return [2];
        }
    });
}
function getBrandSettings(_a) {
    var brandSettings, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4, call(brandsApi.getBrandSettingById, payload)];
            case 1:
                brandSettings = _b.sent();
                return [4, put(clientsActions.setCurrentBrandSettings(brandSettings))];
            case 2:
                _b.sent();
                return [3, 5];
            case 3:
                e_4 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_4.text)];
            case 4:
                _b.sent();
                console.error(e_4);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function createNewClient(_a) {
    var e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, call(clientsApi.createClient, payload)];
            case 1:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 2:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.ClientCreated)];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                e_5 = _b.sent();
                return [4, fork(errorNotification, e_5.text || Messages.Errors.ServerError)];
            case 5:
                _b.sent();
                console.error(e_5);
                return [3, 6];
            case 6: return [4, put(setLoading(ClientPageTypes.CREATE_CLIENT, false))];
            case 7:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_CLIENT, null))];
            case 8:
                _b.sent();
                return [2];
        }
    });
}
function importClients(_a) {
    var brandId, clientBase64, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                brandId = payload.brandId, clientBase64 = payload.clientBase64;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4, call(clientsApi.importClient, { brandId: brandId, payload: clientBase64 })];
            case 2:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 3:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.ClientsImports)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_6 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_6.text)];
            case 6:
                _b.sent();
                console.error(e_6);
                return [3, 7];
            case 7: return [4, put(setLoading(ClientPageTypes.IMPORT_CLIENTS, false))];
            case 8:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.IMPORT_CLIENTS, null))];
            case 9:
                _b.sent();
                return [2];
        }
    });
}
function importPhoneForBulkMessaging(_a) {
    var message, phonesBase64, response, e_7;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                message = payload.message, phonesBase64 = payload.phonesBase64;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4, call(clientsApi.sendBulkMessaging, { message: message, phone: phonesBase64 })];
            case 2:
                response = _b.sent();
                if (!response) return [3, 4];
                return [4, fork(successNotification, Messages.Success.FileLoaded)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [4, put(modalWindowsActions.toggleModal(modalTypes.BULK_MESSAGING, null))];
            case 5:
                _b.sent();
                return [3, 8];
            case 6:
                e_7 = _b.sent();
                return [4, fork(errorNotification, e_7.text || Messages.Errors.ServerError)];
            case 7:
                _b.sent();
                console.error(e_7);
                return [3, 8];
            case 8: return [2];
        }
    });
}
function sendSelectClients(_a) {
    var e_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4, call(clientsApi.sendSelectClientBulkMessaging, payload)];
            case 1:
                _b.sent();
                return [3, 4];
            case 2:
                e_8 = _b.sent();
                return [4, fork(errorNotification, e_8.text || Messages.Errors.ServerError)];
            case 3:
                _b.sent();
                console.error(e_8);
                return [3, 4];
            case 4: return [2];
        }
    });
}
export var clientPageSagas = [
    takeEvery(ClientPageTypes.SHOW_CLIENT_INFO, loaderWrap(showClientInfo)),
    takeEvery(ClientPageTypes.SHOW_CLIENT_HISTORY_DIALOG, loaderWrap(showClientHistoryDialog)),
    takeEvery(ClientPageTypes.GET_BRAND_SETTINGS, loaderWrap(getBrandSettings)),
    takeEvery(ClientPageTypes.CREATE_CLIENT, loaderWrap(createNewClient, false)),
    takeEvery(ClientPageTypes.IMPORT_CLIENTS, loaderWrap(importClients, false)),
    takeEvery(ClientPageTypes.IMPORT_PHONE_FOR_BULK_MESSAGING, loaderWrap(importPhoneForBulkMessaging)),
    takeEvery(ClientPageTypes.SELECT_CLIENTS_FOR_BULK_MESSAGING, loaderWrap(sendSelectClients)),
];
