var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Messages } from '@public/locales/messages';
import { AllGroupsSettings } from '@src/global-interfaces';
import Loader from '@src/components/Loader';
import ModalWindow from '@src/components/ModalWindow';
import { Tabs, Tab } from '@src/components/Tabs/Tabs';
import GroupsTags from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsTags';
import GroupsAgents from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsAgents';
import UpdateGroups from '@src/modules/Pages/Groups/GroupsUpdateModal/UpdateGroups';
import GroupsChannel from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsChannel';
import GroupsUsefulPhrases from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases';
import { StyledUpdateGroupsModalWrapper } from '@src/modules/Pages/Groups/GroupsUpdateModal/styled';
var GroupsUpdateModal = function (props) {
    var toggleModal = props.toggleModal, currentGroup = props.currentGroup, resetCurrentGroup = props.resetCurrentGroup, isLoadingCurrentGroups = props.isLoadingCurrentGroups;
    var _a = __read(useState(AllGroupsSettings.agents), 2), currentTab = _a[0], setCurrentTab = _a[1];
    var _b = __read(useState(false), 2), isOpenTwoModal = _b[0], setIsOpenTwoModal = _b[1];
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    var closeModal = function () {
        toggleModal();
        resetCurrentGroup(null);
    };
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: "".concat(Messages.Labels.UpdateGroup, " ").concat(currentGroup ? "- ".concat(currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.name) : ''), outsideClick: !isOpenTwoModal },
        React.createElement(StyledUpdateGroupsModalWrapper, null,
            React.createElement(Tabs, { callback: onTabChangeHandler, currentTab: currentTab, isShowBottomLine: true },
                React.createElement(Tab, { tabKey: AllGroupsSettings.agents, title: AllGroupsSettings.agents }, isLoadingCurrentGroups ? (React.createElement(Loader, null)) : (React.createElement(GroupsAgents, { setIsOpenTwoModal: setIsOpenTwoModal }))),
                React.createElement(Tab, { tabKey: AllGroupsSettings.updateGroup, title: AllGroupsSettings.updateGroup }, isLoadingCurrentGroups ? (React.createElement(Loader, null)) : (React.createElement(UpdateGroups, null))),
                React.createElement(Tab, { tabKey: AllGroupsSettings.usefulPhrases, title: AllGroupsSettings.usefulPhrases }, isLoadingCurrentGroups ? (React.createElement(Loader, null)) : (React.createElement(GroupsUsefulPhrases, { setIsOpenTwoModal: setIsOpenTwoModal }))),
                React.createElement(Tab, { tabKey: AllGroupsSettings.channels, title: AllGroupsSettings.channels }, isLoadingCurrentGroups ? (React.createElement(Loader, null)) : (React.createElement(GroupsChannel, null))),
                React.createElement(Tab, { tabKey: AllGroupsSettings.tags, title: AllGroupsSettings.tags }, isLoadingCurrentGroups ? (React.createElement(Loader, null)) : (React.createElement(GroupsTags, null)))))));
};
export default GroupsUpdateModal;
