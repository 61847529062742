var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Messages } from '@public/locales/messages';
import { Accordion } from '@src/components/Accordion/Accordion';
import Checkbox from '@src/components/Checkbox';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import Loader from '@src/components/Loader';
import Select from '@src/components/Select';
import { ToggleSwitch } from '@src/components/Switch/Switch';
import { assignationTypeValues, clientHistoryValues, enabledLanguagesChannel, notificationTypes, } from '@src/global-interfaces';
import { StyledCheckboxWrapper } from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox/styled';
import { StyledLoaderWrapper, StyledSelectWrapper, StyledSettingsWrapper, StyledSwitchWrapper, } from '@src/modules/Pages/Channels/ChannelUpdate/styled';
import { mimeTypes, mimeTypesList, responsesBotAnswerType } from '@src/modules/Pages/Channels/constant';
import { StyledChannelInput } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { channelsActions } from '@src/modules/Pages/Channels/redux/reducer';
import channelsStore from '@src/modules/Pages/Channels/redux/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { loadingStore } from '@src/redux/loading/selector';
import { notificationActions } from '@src/redux/notification/reducer';
import { transformDictionary } from '@src/utils/transformDictionary';
import { composeValidators, isRequired, lengthCreator, urlCorrect } from '@src/utils/validations';
var GeneralSettings = function (props) {
    var _a;
    var channelId = props.channelId, isCaptcha = props.isCaptcha, setIsCaptcha = props.setIsCaptcha, clientHistory = props.clientHistory, selectedGroups = props.selectedGroups, assignationType = props.assignationType, groupDictionary = props.groupDictionary, showNotification = props.showNotification, customizations = props.customizations, setCustomizations = props.setCustomizations, setClientHistory = props.setClientHistory, selectedLanguages = props.selectedLanguages, setSelectedGroups = props.setSelectedGroups, setAssignationType = props.setAssignationType, setSelectedLanguages = props.setSelectedLanguages, showAllClientDialogs = props.showAllClientDialogs, resetNotificationState = props.resetNotificationState, addMessengerForChannel = props.addMessengerForChannel, setShowAllClientDialogs = props.setShowAllClientDialogs, currentChannelMessengers = props.currentChannelMessengers, currentBrandSmsMessengers = props.currentBrandSmsMessengers, deleteMessengerFromChannel = props.deleteMessengerFromChannel, currentBrandEmailMessengers = props.currentBrandEmailMessengers, deleteAndAddMessengerForChannel = props.deleteAndAddMessengerForChannel, isLoadingChangeMessengerChannel = props.isLoadingChangeMessengerChannel, currentBrandTelegramPrivateMessengers = props.currentBrandTelegramPrivateMessengers;
    var minNameLength = lengthCreator.minNameLength, maxNameLength = lengthCreator.maxNameLength;
    var memoizedSelectedGroups = useMemo(function () {
        return selectedGroups.map(function (elem) {
            return transformDictionary(groupDictionary, 'text', 'value').find(function (el) { return el.value === elem; });
        });
    }, [selectedGroups]);
    var memoizedSelectedLanguages = useMemo(function () {
        return selectedLanguages.map(function (elem) {
            if (typeof elem === 'string') {
                return enabledLanguagesChannel.find(function (el) { return el.value === elem; });
            }
            return elem;
        });
    }, [selectedLanguages]);
    var _b = __read(useState(false), 2), isLoadingSmsInbox = _b[0], setIsLoadingSmsInbox = _b[1];
    var _c = __read(useState(false), 2), isLoadingEmailInbox = _c[0], setIsLoadingEmailInbox = _c[1];
    var _d = __read(useState(false), 2), isLoadingTelegramPrivateInbox = _d[0], setIsLoadingTelegramPrivateInbox = _d[1];
    var _e = __read(useState(function () {
        return Object.keys(mimeTypes).map(function (el) { return ({
            text: el,
            value: el,
        }); });
    }), 1), mimeTypesArr = _e[0];
    var _f = __read(useState([]), 2), typesArr = _f[0], setTypes = _f[1];
    var showWarning = function (msg) {
        if (msg === void 0) { msg = Messages.Errors.FieldIsRequired; }
        showNotification({ msg: msg, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChangeSelectedGroups = function (el) { return setSelectedGroups(el); };
    var onChangeSelectedLanguages = function (el) { return setSelectedLanguages(el); };
    var handleClientHistoryChange = function (changeEvent) { return setClientHistory(changeEvent.target.value); };
    var handleAssignationType = function (changeEvent) { return setAssignationType(changeEvent.target.value); };
    var onChangeShowAllClientDialogs = function (e) { return setShowAllClientDialogs(e.target.checked); };
    var onChangeIsCaptcha = function (e) { return setIsCaptcha(e.target.checked); };
    var onChangeActiveInbox = function (e, id, inbox) {
        var isActiveInbox = currentChannelMessengers.find(function (el) { return el.messenger === inbox; });
        if (isActiveInbox && e.target.checked) {
            deleteAndAddMessengerForChannel({
                addMessenger: { messenger: inbox, messengerId: id, channelId: channelId },
                deleteMessenger: { messenger: inbox, messengerId: isActiveInbox.id, channelId: channelId },
            });
            return;
        }
        if (e.target.checked && !isActiveInbox) {
            addMessengerForChannel({ messenger: inbox, messengerId: id, channelId: channelId });
            return;
        }
        if (!e.target.checked) {
            deleteMessengerFromChannel({ messenger: inbox, messengerId: id, channelId: channelId });
        }
    };
    var onChangeMailInbox = function (e, id) {
        setIsLoadingSmsInbox(false);
        setIsLoadingEmailInbox(true);
        setIsLoadingTelegramPrivateInbox(false);
        onChangeActiveInbox(e, id, 'email');
    };
    var onChangeTelegramPrivateInbox = function (e, id) {
        setIsLoadingSmsInbox(false);
        setIsLoadingEmailInbox(false);
        setIsLoadingTelegramPrivateInbox(true);
        onChangeActiveInbox(e, id, 'telegramPrivate');
    };
    var onChangeSmsInbox = function (e, id) {
        setIsLoadingSmsInbox(true);
        setIsLoadingEmailInbox(false);
        setIsLoadingTelegramPrivateInbox(false);
        onChangeActiveInbox(e, id, 'sms');
    };
    var onChangeResponsesBotAnswerType = function (el) {
        setCustomizations(__assign(__assign({}, customizations), { botChannel: { responseType: el.value } }));
    };
    var selectedMimeTypes = function (el) {
        var tempArr = [];
        el.forEach(function (el) {
            mimeTypes[el.value].forEach(function (item) {
                tempArr.push({
                    text: item,
                    value: item,
                });
            });
        });
        setTypes(tempArr);
    };
    var selectedTypes = function (el) {
        var tempObj = {};
        el.forEach(function (el) {
            console.log(mimeTypesList[el.value]);
            if (tempObj[mimeTypesList[el.value]]) {
                tempObj[mimeTypesList[el.value]].push(el.value);
            }
            else {
                tempObj[mimeTypesList[el.value]] = [el.value];
            }
        });
        setCustomizations(__assign(__assign({}, customizations), { mimeTypesPermission: tempObj }));
    };
    var ChannelInput = InputWithStyles(Input, StyledChannelInput);
    var getTypes = function (mimeTypesPermission) {
        var tempArr = [];
        Object.values(mimeTypesPermission).forEach(function (el) {
            if (Array.isArray(el)) {
                el.forEach(function (el) {
                    tempArr.push({ text: el, value: el });
                });
            }
            else {
                tempArr.push({ text: el, value: el });
            }
        });
        return tempArr;
    };
    return (React.createElement(StyledSettingsWrapper, null,
        React.createElement(StyledSelectWrapper, null,
            React.createElement(Select, { initialState: ((_a = customizations === null || customizations === void 0 ? void 0 : customizations.botChannel) === null || _a === void 0 ? void 0 : _a.responseType)
                    ? [responsesBotAnswerType.find(function (el) { var _a; return (el === null || el === void 0 ? void 0 : el.value) === ((_a = customizations === null || customizations === void 0 ? void 0 : customizations.botChannel) === null || _a === void 0 ? void 0 : _a.responseType); })]
                    : [], isMultiply: false, label: "Select responses bot answer type", placeholder: "Select responses bot answer type", items: responsesBotAnswerType, stateCB: function (el) { return onChangeResponsesBotAnswerType(el); } })),
        React.createElement(StyledSelectWrapper, null,
            React.createElement(Select, { initialState: Object.keys(customizations.mimeTypesPermission).length > 0
                    ? Object.keys(customizations.mimeTypesPermission).map(function (el) { return ({ text: el, value: el }); })
                    : [], label: "Select mime types", placeholder: "Select mime types", items: mimeTypesArr, stateCB: selectedMimeTypes })),
        React.createElement(StyledSelectWrapper, null,
            React.createElement(Select, { initialState: Object.keys(customizations.mimeTypesPermission).length > 0
                    ? getTypes(customizations.mimeTypesPermission)
                    : [], label: "Select file types", placeholder: "Select file types", items: Object.keys(customizations.mimeTypesPermission).length > 0
                    ? __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(getTypes(customizations.mimeTypesPermission)), false), __read(typesArr), false))), false) : typesArr, stateCB: selectedTypes })),
        React.createElement(StyledSelectWrapper, null,
            React.createElement(Select, { initialState: memoizedSelectedLanguages || [], label: Messages.Labels.SelectEnabledLanguages, disableRemoveItemFn: function () { return showWarning(Messages.Warning.ChannelMustContainAtLeastOneLanguage); }, disableRemoveItem: memoizedSelectedLanguages.length <= 1, placeholder: Messages.Selects.SelectEnabledLanguages, items: enabledLanguagesChannel, stateCB: function (el) { return onChangeSelectedLanguages(el); } })),
        React.createElement(StyledSelectWrapper, null,
            React.createElement(Select, { initialState: memoizedSelectedGroups || [], label: Messages.Labels.SelectGroups, placeholder: Messages.Selects.SelectGroups, items: transformDictionary(groupDictionary, 'text', 'value'), stateCB: function (el) { return onChangeSelectedGroups(el); } })),
        React.createElement(Field, { name: "channelName", label: Messages.Labels.ChannelName, validate: composeValidators(isRequired, minNameLength, maxNameLength), showWarning: showWarning, placeholder: Messages.Selects.ChannelName, component: ChannelInput }),
        React.createElement(Field, { name: "channelURL", label: Messages.Labels.ChannelURL, validate: composeValidators(isRequired, urlCorrect), showWarning: showWarning, placeholder: Messages.Selects.ChannelURL, component: ChannelInput }),
        React.createElement("div", null, Messages.Labels.ClientHistory),
        React.createElement(StyledCheckboxWrapper, null, clientHistoryValues.map(function (el, index) { return (React.createElement(Checkbox, { key: index, checked: clientHistory === clientHistoryValues[index].value, label: el.text, onChange: handleClientHistoryChange, value: el.value })); })),
        React.createElement("div", null, Messages.Labels.DistributionOfNewDialogs),
        React.createElement(StyledCheckboxWrapper, null, assignationTypeValues.map(function (el, index) { return (React.createElement(Checkbox, { key: index, checked: +assignationType === +assignationTypeValues[index].value, label: el.text, onChange: handleAssignationType, value: el.value })); })),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: Messages.Labels.AllowShowAllClientDialogs, onChange: onChangeShowAllClientDialogs, checked: showAllClientDialogs }),
            React.createElement("div", { className: "title" }, Messages.Labels.AllowShowAllClientDialogs)),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: Messages.Labels.AllowCaptcha, onChange: onChangeIsCaptcha, checked: isCaptcha }),
            React.createElement("div", { className: "title" }, Messages.Labels.AllowCaptcha)),
        React.createElement("div", null, Messages.Labels.InboxSettings),
        currentBrandEmailMessengers.length <= 0 &&
            currentBrandTelegramPrivateMessengers.length <= 0 &&
            currentBrandSmsMessengers.length <= 0 && React.createElement("div", null, Messages.Labels.ThereAreNoAvailableMessengers),
        currentBrandEmailMessengers.length > 0 && (React.createElement(Accordion, { title: "Mail inboxes", secondBackground: true }, isLoadingChangeMessengerChannel && isLoadingEmailInbox ? (React.createElement(StyledLoaderWrapper, null,
            React.createElement(Loader, null))) : (currentBrandEmailMessengers.map(function (el, index) {
            var _a;
            return (React.createElement(StyledSwitchWrapper, { key: el.externalId + index },
                React.createElement(ToggleSwitch, { label: el.externalId, onChange: function (e) { return onChangeMailInbox(e, el.id); }, checked: el.externalId === ((_a = currentChannelMessengers.find(function (el) { return el.messenger === 'email'; })) === null || _a === void 0 ? void 0 : _a.externalId) }),
                React.createElement("div", { className: "title" }, el.externalId)));
        })))),
        currentBrandTelegramPrivateMessengers.length > 0 && (React.createElement(Accordion, { title: Messages.Accordions.TelegramAccounts, secondBackground: true }, isLoadingChangeMessengerChannel && isLoadingTelegramPrivateInbox ? (React.createElement(StyledLoaderWrapper, null,
            React.createElement(Loader, null))) : (currentBrandTelegramPrivateMessengers.map(function (el, index) {
            var _a;
            return (React.createElement(StyledSwitchWrapper, { key: el.externalId + index },
                React.createElement(ToggleSwitch, { label: el.externalId, onChange: function (e) { return onChangeTelegramPrivateInbox(e, el.id); }, checked: el.externalId ===
                        ((_a = currentChannelMessengers.find(function (el) { return el.messenger === 'telegramPrivate'; })) === null || _a === void 0 ? void 0 : _a.externalId) }),
                React.createElement("div", { className: "title" }, el.externalId)));
        })))),
        currentBrandSmsMessengers.length > 0 && (React.createElement(Accordion, { title: Messages.Accordions.SmsAccounts, secondBackground: true }, isLoadingChangeMessengerChannel && isLoadingSmsInbox ? (React.createElement(StyledLoaderWrapper, null,
            React.createElement(Loader, null))) : (currentBrandSmsMessengers.map(function (el, index) {
            var _a;
            return (React.createElement(StyledSwitchWrapper, { key: el.externalId + index },
                React.createElement(ToggleSwitch, { label: el.externalId, onChange: function (e) { return onChangeSmsInbox(e, el.id); }, checked: el.externalId === ((_a = currentChannelMessengers.find(function (el) { return el.messenger === 'sms'; })) === null || _a === void 0 ? void 0 : _a.externalId) }),
                React.createElement("div", { className: "title" }, el.externalId)));
        }))))));
};
var mapStateToProps = function (state) { return ({
    groupDictionary: dictionariesStore.dictionaries(state).groupDictionary,
    currentChannelMessengers: channelsStore.getCurrentChannelMessengers(state),
    currentBrandSmsMessengers: channelsStore.getCurrentBrandSmsMessengers(state),
    currentBrandEmailMessengers: channelsStore.getCurrentBrandEmailMessengers(state),
    isLoadingChangeMessengerChannel: loadingStore.getIsLoadingChangeMessengerChannel(state),
    currentBrandTelegramPrivateMessengers: channelsStore.getCurrentBrandTelegramPrivateMessengers(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    addMessengerForChannel: function (data) { return dispatch(channelsActions.addMessengerForChannel(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    deleteMessengerFromChannel: function (data) {
        return dispatch(channelsActions.deleteMessengerFromChannel(data));
    },
    deleteAndAddMessengerForChannel: function (data) {
        return dispatch(channelsActions.deleteAndAddMessengerForChannel(data));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
