import { createBrowserHistory } from 'history';
var history = null;
export var getHistory = function () {
    if (!history) {
        history = createBrowserHistory();
    }
    return history;
};
export var redirect = function (target) {
    try {
        getHistory().push(target);
    }
    catch (error) {
        console.error(error);
    }
};
