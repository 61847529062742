var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { StyledIcon } from './styled';
export var Icon = function (_a) {
    var src = _a.src, styles = _a.styles, onClick = _a.onClick, data = _a.data, _b = _a.isEvent, isEvent = _b === void 0 ? false : _b, alt = _a.alt;
    return (React.createElement(StyledIcon, null,
        React.createElement("img", { onClick: function (e) { return onClick && onClick(isEvent ? e : data); }, style: __assign({}, styles), src: src, alt: alt || src })));
};
