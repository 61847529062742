var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useState, useRef, } from 'react';
import { isEqual } from 'lodash';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { useOutsideClick } from '@src/utils/useOutsideClick';
import { system } from '@src/constants/objects';
import { allSearchName } from '@src/constants/allFilters';
import { Input } from '@src/components/Input/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, invoiceNumberValidation, isCorrectEmail, isRequired, isSpaces, lengthCreator, nameValidation, } from '@src/utils/validations';
import { inputStyles, StyledSelectSearchItem, StyledSearchFilterInput, StyledSelectSearchWrapper, StyledSearchFilterWrapper, } from '@src/modules/ToolBar/ToolBarUI/Filtration/SearchFilter/styled';
var SearchFilter = function (props) {
    var _a;
    var currentPage = props.currentPage, saveValueSelectedFilter = props.saveValueSelectedFilter, selectedAllFilters = props.selectedAllFilters, deleteValueSelectedFilter = props.deleteValueSelectedFilter;
    var minNameLength = lengthCreator.minNameLength, maxNameLength = lengthCreator.maxNameLength, minFirstNameLength = lengthCreator.minFirstNameLength, maxFirstNameLength = lengthCreator.maxFirstNameLength, minLastNameLength = lengthCreator.minLastNameLength, maxLastNameLength = lengthCreator.maxLastNameLength, minChannelNameLength = lengthCreator.minChannelNameLength, maxChannelNameLength = lengthCreator.maxChannelNameLength, minBrandNameLength = lengthCreator.minBrandNameLength, maxBrandNameLength = lengthCreator.maxBrandNameLength, maxEmailLength = lengthCreator.maxEmailLength, minEmailLength = lengthCreator.minEmailLength, minInvoiceNumberLength = lengthCreator.minInvoiceNumberLength, maxInvoiceNumberLength = lengthCreator.maxInvoiceNumberLength;
    var _b = __read(useState(system.DEFAULT_SELECTED_SEARCH_FILTER), 2), selectedSearch = _b[0], setSelectedSearch = _b[1];
    var _c = __read(useState([]), 2), selectSearchItem = _c[0], setSelectSearchItem = _c[1];
    var _d = __read(useState(false), 2), isShowSelectSearchList = _d[0], setIsShowSelectSearchList = _d[1];
    var _e = __read(useState(''), 2), defaultValue = _e[0], setDefaultValue = _e[1];
    var _f = __read(useState(false), 2), isResetValue = _f[0], setIsResetValue = _f[1];
    useEffect(function () {
        if (currentPage === 'clients' || currentPage === 'channels' || currentPage === 'brands' || currentPage === 'agentsReports') {
            setSelectedSearch(system.isSearchOfValue[currentPage][0].value);
        }
    }, [currentPage]);
    var onSubmit = function (data) {
        saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { search: { name: selectedSearch, value: data[selectedSearch] } } });
    };
    var inputDefaultValue = function () {
        var _a, _b;
        var isSearchFilter = (_b = (_a = selectedAllFilters[currentPage]) === null || _a === void 0 ? void 0 : _a.search) === null || _b === void 0 ? void 0 : _b.some(function (el) {
            if (!Array.isArray(el) && typeof el === 'object') {
                if (el.name === selectedSearch) {
                    setDefaultValue(el.value);
                    return el;
                }
            }
        });
        !isSearchFilter && setDefaultValue('');
    };
    var getRulesValidation = function (selectedSearch) {
        switch (selectedSearch) {
            case 'name':
                return composeValidators(isRequired, nameValidation, minNameLength, maxNameLength);
            case 'first_name':
                return composeValidators(isRequired, nameValidation, minFirstNameLength, maxFirstNameLength);
            case 'last_name':
                return composeValidators(isRequired, nameValidation, minLastNameLength, maxLastNameLength);
            case 'ChannelName':
                return composeValidators(isRequired, nameValidation, minChannelNameLength, maxChannelNameLength);
            case 'BrandName':
                return composeValidators(isRequired, nameValidation, minBrandNameLength, maxBrandNameLength);
            case 'email':
                return composeValidators(isSpaces, isRequired, isCorrectEmail, minEmailLength, maxEmailLength);
            default:
                return composeValidators(isSpaces, isRequired, invoiceNumberValidation, minInvoiceNumberLength, maxInvoiceNumberLength);
        }
    };
    var resetErrorValidation = function (value, fn, field) {
        if (value.length >= 1)
            setIsResetValue(false);
        if (value.length <= 0) {
            if (!isResetValue) {
                deleteValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { search: { name: selectedSearch, value: '' } } });
            }
            fn(field);
        }
    };
    var onChangeSelectedSearch = useCallback(function (value) {
        setIsResetValue(true);
        setSelectedSearch(value);
    }, [selectedSearch]);
    var onChangeShowSelectSearch = useCallback(function () { return setIsShowSelectSearchList(!isShowSelectSearchList); }, [isShowSelectSearchList]);
    useEffect(function () {
        inputDefaultValue();
    }, [selectedSearch, selectedAllFilters]);
    useEffect(function () {
        if (Array.isArray(system.isSearchOfValue[currentPage])) {
            setSelectSearchItem(system.isSearchOfValue[currentPage]);
        }
    }, [currentPage]);
    var searchFilterInput = InputWithStyles(Input, StyledSearchFilterInput);
    var ref = useRef();
    useOutsideClick(setIsShowSelectSearchList, ref, [], true);
    return (React.createElement(StyledSearchFilterWrapper, null,
        React.createElement(Form, { initialValues: (_a = {}, _a[selectedSearch] = defaultValue, _a), onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit, form = _a.form;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { styles: inputStyles, name: selectedSearch, component: searchFilterInput, placeholder: "".concat(Messages.Controls.PleaseWrite, " ").concat(allSearchName[selectedSearch]), secondIcon: Array.isArray(system.isSearchOfValue[currentPage]) && icons.changeSearch, icon: icons.search, validate: getRulesValidation(selectedSearch), iconOnclick: handleSubmit, secondIconOnclick: onChangeShowSelectSearch }),
                    React.createElement(OnChange, { name: selectedSearch }, function (value) { return resetErrorValidation(value, form.resetFieldState, selectedSearch); })));
            } }),
        isShowSelectSearchList && (React.createElement(StyledSelectSearchWrapper, { ref: ref }, typeof selectSearchItem !== 'boolean' && (selectSearchItem === null || selectSearchItem === void 0 ? void 0 : selectSearchItem.map(function (el, index) {
            var name = el.name, value = el.value;
            var isSelected = value === selectedSearch;
            return (React.createElement(StyledSelectSearchItem, { key: index, isSelected: isSelected, onClick: function () { return onChangeSelectedSearch(value); } }, name));
        }))))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(SearchFilter, areEqual);
