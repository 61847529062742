var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledAgentNameRenderer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    img {\n      object-fit: cover;\n      filter: ", ";\n    }\n  }\n"], ["\n  && {\n    img {\n      object-fit: cover;\n      filter: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, isAvatar = _a.isAvatar;
    return !isAvatar && theme.invert;
});
var templateObject_1;
