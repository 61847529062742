var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledCreateTagsModalWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 40vw;\n  max-height: 50vh;\n  min-height: 10vh;\n  padding: 10px 20px;\n  overflow: auto;\n  ", "\n"], ["\n  width: 40vw;\n  max-height: 50vh;\n  min-height: 10vh;\n  padding: 10px 20px;\n  overflow: auto;\n  ", "\n"])), scrollStyles);
export var StyledTagInput = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"], ["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"])));
export var StyleUsefulLinksWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    padding-top: 10px;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n\n    & .useful-links-name {\n      color: ", ";\n    }\n\n    & .useful-links-content {\n      display: flex;\n      flex-wrap: wrap;\n      align-items: baseline;\n    }\n  }\n"], ["\n  && {\n    padding-top: 10px;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n\n    & .useful-links-name {\n      color: ", ";\n    }\n\n    & .useful-links-content {\n      display: flex;\n      flex-wrap: wrap;\n      align-items: baseline;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1, templateObject_2, templateObject_3;
