import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loader from '@src/components/Loader';
import Confirm, { Notification } from '@src/components/Notification/Notification';
import Overlay from '@src/components/Overlay';
import { system } from '@src/constants/objects';
import AuthPage from '@src/modules/Auth';
import { authActions } from '@src/modules/Auth/redux/reducer';
import { authStore } from '@src/modules/Auth/redux/selector';
import ExcelModal from '@src/modules/Excel/ExcelModal';
import excelStore from '@src/modules/Excel/redux/selector';
import AgentsPage from '@src/modules/Pages/Agents';
import BrandsPage from '@src/modules/Pages/Brands';
import ChannelsPage from '@src/modules/Pages/Channels';
import ClientsPage from '@src/modules/Pages/Clients';
import DialogsPage from '@src/modules/Pages/Dialogs';
import GroupsPage from '@src/modules/Pages/Groups';
import ReportsPage from '@src/modules/Pages/Reports';
import ResetPassword from '@src/modules/Pages/ResetPassword';
import TagsPage from '@src/modules/Pages/Tags';
import { appStore } from '@src/redux/app/selector';
import { loadingStore } from '@src/redux/loading/selector';
import { notificationStore } from '@src/redux/notification/selector';
import { getAuthCookie } from '@src/utils/cookie';
import { getHistory } from '@src/utils/history';
import { getThemeOptions } from '@src/utils/theme';
import { ThemeProvider } from '../theme';
var _a = system.PAGE_PATHS, LOGIN = _a.LOGIN, ROOT = _a.ROOT, REPORTS = _a.REPORTS, BRANDS = _a.BRANDS, CHANNELS = _a.CHANNELS, CLIENTS = _a.CLIENTS, TAGS = _a.TAGS, AGENTS = _a.AGENTS, GROUPS = _a.GROUPS, RESET_PASSWORD = _a.RESET_PASSWORD;
export var getAppLang = function () { return localStorage.getItem('app_lang') || 'en'; };
function App(_a) {
    var initApp = _a.initApp, notification = _a.notification, contentReady = _a.contentReady, excelModalIsShown = _a.excelModalIsShown, logout = _a.logout, theme = _a.theme;
    useEffect(function () {
        var pathName = getHistory().location.pathname;
        initApp(pathName);
    }, []);
    var isShowNotification = notification.isShowNotification, isShowConfirm = notification.isShowConfirm, isOpenOverlay = notification.isOpenOverlay, type = notification.type, text = notification.text;
    if (!contentReady && getAuthCookie()) {
        return (React.createElement(ThemeProvider, { language: getAppLang(), theme: getThemeOptions(theme) },
            React.createElement(Loader, null),
            isShowNotification && React.createElement(Notification, { type: type, text: text })));
    }
    return (React.createElement(ThemeProvider, { language: getAppLang(), theme: getThemeOptions(theme) },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: ROOT, component: DialogsPage }),
            React.createElement(Route, { exact: true, path: AGENTS, component: AgentsPage }),
            React.createElement(Route, { exact: true, path: REPORTS, component: ReportsPage }),
            React.createElement(Route, { exact: true, path: BRANDS, component: BrandsPage }),
            React.createElement(Route, { exact: true, path: CHANNELS, component: ChannelsPage }),
            React.createElement(Route, { exact: true, path: CLIENTS, component: ClientsPage }),
            React.createElement(Route, { exact: true, path: TAGS, component: TagsPage }),
            React.createElement(Route, { exact: true, path: GROUPS, component: GroupsPage }),
            React.createElement(Route, { exact: true, path: RESET_PASSWORD, component: ResetPassword }),
            React.createElement(Route, { path: LOGIN, component: AuthPage }),
            React.createElement(Route, { path: "*" },
                React.createElement(Redirect, { to: ROOT }))),
        isShowNotification && React.createElement(Notification, { type: type, text: text }),
        isShowConfirm && React.createElement(Confirm, { text: text, action: logout }),
        isOpenOverlay && React.createElement(Overlay, null),
        excelModalIsShown && React.createElement(ExcelModal, null)));
}
var mapStateToProps = function (state) { return ({
    excelModalIsShown: !!excelStore.getExcelQueue(state),
    notification: notificationStore.notification(state),
    isLoading: loadingStore.getIsLoading(state),
    contentReady: authStore.contentReady(state),
    theme: appStore.theme(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    initApp: function (pathName) { return dispatch(authActions.initApp(pathName)); },
    logout: function () {
        dispatch(authActions.logout());
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(App);
