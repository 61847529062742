import { isEqualObject } from '@src/utils/isEqualObject';
export var createUniqueArrayOfObjects = function (array) {
    var _a;
    var isAdded;
    var arr = [];
    var _loop_1 = function (i) {
        isAdded = arr.some(function (v) { return isEqualObject(v, array[i]); });
        if (!isAdded)
            arr.push(array[i]);
    };
    for (var i = 0; i < array.length; i++) {
        _loop_1(i);
    }
    var uniqueArr = Array.from(new Set(arr));
    var temp = {};
    var _loop_2 = function (i) {
        if (typeof uniqueArr[i] === 'object' && !Array.isArray(uniqueArr[i])) {
            var tempElems = uniqueArr.filter(function (el) { var _a; return el.name === ((_a = uniqueArr[i]) === null || _a === void 0 ? void 0 : _a.name); });
            temp[(_a = uniqueArr[i]) === null || _a === void 0 ? void 0 : _a.name] = tempElems[tempElems.length - 1];
        }
        else {
            temp = uniqueArr;
        }
    };
    for (var i = 0; i < uniqueArr.length; i++) {
        _loop_2(i);
    }
    return Object.values(temp);
};
