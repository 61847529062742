var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getHistory } from '@src/utils/history';
import { formatToQueryFilters, transformFiltering as transformFilteringOriginal } from '@src/utils/transformFiltering';
export var formatToQueryFiltersReportsPageExcelBrand = function (filters, query) { return "&".concat(query, "=").concat(filters.map(function (elem) { return elem; }).join(',')); };
var formatToQueryDate = function (filters) {
    if (Array.isArray(filters[1]) && ['/reports'].includes(getHistory().location.pathname))
        return "&DateFrom=".concat(filters[1][0], "&DateTo=").concat(filters[1][1]);
    if (Array.isArray(filters[1]))
        return "&DatePeriod.DateFrom=".concat(filters[1][0], "&DatePeriod.DateTo=").concat(filters[1][1]);
    return '';
};
export var transformFiltering = function (objFilter) { return (__assign(__assign({}, transformFilteringOriginal(objFilter)), { date: formatToQueryDate((objFilter === null || objFilter === void 0 ? void 0 : objFilter.date) || []), messengers: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.messengers) || [], 'Messenger'), brands: formatToQueryFiltersReportsPageExcelBrand((objFilter === null || objFilter === void 0 ? void 0 : objFilter.brands) || [], 'BrandId') })); };
