import React from 'react';
import { StyledPageWrapper } from '@src/app/styled';
import ModalWindow from '@src/components/ModalWindow';
import Header from '@src/modules/Header';
import AgentReport from '@src/modules/Pages/Reports/AgentReport/index';
import { Content } from '@src/modules/Pages/Reports/components/Content/Content';
function ReportsPage(_a) {
    var currentPage = _a.currentPage, isModalOpen = _a.isModalOpen, toggleModal = _a.toggleModal;
    return (React.createElement(StyledPageWrapper, { className: "reports-page" },
        React.createElement(Header, null),
        React.createElement(Content, null),
        isModalOpen && (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: "" },
            React.createElement(AgentReport, null)))));
}
export default ReportsPage;
