import { connect } from 'react-redux';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { agentsActions } from '@src/modules/Pages/Agents/redux/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import BanAgent from './BanAgent';
var mapStateToProps = function (state) { return ({
    currentAgent: agentsStore.getCurrentAgent(state),
    modalInfo: modalWindowsStore.getModalInfo(state, modalTypes.AGENT_MODAL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    banOrUnbanAgent: function (id) { return dispatch(agentsActions.banOrUnbanAgent(id)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(BanAgent);
