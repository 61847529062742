import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var getDialogsByClientId = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Dialog/dialogs/byClientId?clientId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var createClient = function (client) { return wretch("".concat(apiConfig.baseUrl, "/Client"))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(client)
    .text(); };
var importClient = function (data) { return wretch("".concat(apiConfig.baseUrl, "/Client/import"))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(data); };
var sendBulkMessaging = function (bulkMessaging) { return wretch("".concat(apiConfig.baseUrl, "/Channel/bulk-messaging/telegram"))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(bulkMessaging)
    .json(); };
var sendSelectClientBulkMessaging = function (data) { return wretch("".concat(apiConfig.baseUrl, "/Client/bulk-messaging"))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(data)
    .json(); };
export var clientsApi = {
    importClient: importClient,
    createClient: createClient,
    sendBulkMessaging: sendBulkMessaging,
    getDialogsByClientId: getDialogsByClientId,
    sendSelectClientBulkMessaging: sendSelectClientBulkMessaging,
};
