var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo, useState, } from 'react';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes } from '@src/global-interfaces';
import { icons } from '@public/images';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import { Icon } from '@src/components/Icon/Icon';
import { NoData } from '@src/components/NoData/NoData';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired, lengthCreator, } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledPhrases, StyledPhrasesName, StyledCategoryInput, StyledPhrasesWrapper, StyledCategoryWrapper, } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalCategory/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var CategoryModal = function (props) {
    var _a;
    var _b = props.isEdit, isEdit = _b === void 0 ? false : _b, _c = props.categoryId, categoryId = _c === void 0 ? '' : _c, currentGroup = props.currentGroup, deletePhrase = props.deletePhrase, updateCategory = props.updateCategory, showNotification = props.showNotification, createNewCategory = props.createNewCategory, resetNotificationState = props.resetNotificationState, isLoadingUsefulPhrases = props.isLoadingUsefulPhrases;
    var minNameCategory = lengthCreator.minNameCategory, maxNameCategory = lengthCreator.maxNameCategory;
    var currentPhrases = useMemo(function () { var _a; return (_a = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.usefulPhrases.find(function (el) { return el.id === categoryId; })) === null || _a === void 0 ? void 0 : _a.phrases; }, [currentGroup]);
    var _d = __read(useState(((_a = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.usefulPhrases.find(function (el) { return el.id === categoryId; })) === null || _a === void 0 ? void 0 : _a.name) || ''), 2), categoryName = _d[0], setCategoryName = _d[1];
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onSubmit = function (data) {
        if (!isEdit)
            createNewCategory(data.categoryName);
        if (isEdit)
            updateCategory({ categoryId: categoryId, name: data.categoryName });
        setCategoryName('');
    };
    var groupsInput = InputWithStyles(Input, StyledCategoryInput);
    var renderForm = useMemo(function () { return (isLoadingUsefulPhrases ? (React.createElement(Loader, null)) : (React.createElement(Form, { initialValues: { categoryName: categoryName }, onSubmit: onSubmit, render: function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(StyledModalContentWrapper, null,
                    React.createElement(StyledModalContentFieldsWrapper, null,
                        React.createElement(Field, { name: "categoryName", label: Messages.Labels.CategoryName, showWarning: showWarning, validate: composeValidators(isRequired, minNameCategory, maxNameCategory), placeholder: Messages.Controls.CategoryName, component: groupsInput }),
                        isEdit && (React.createElement(React.Fragment, null,
                            React.createElement(StyledPhrasesName, null, Messages.Labels.UsefulPhrases),
                            React.createElement(StyledPhrasesWrapper, null, currentPhrases.length >= 1 ? (React.createElement(React.Fragment, null, currentPhrases.map(function (el, index) { return (React.createElement(StyledPhrases, { key: index },
                                React.createElement("div", null, el.text),
                                React.createElement(Icon, { src: icons.deletePhrases, onClick: function () { return deletePhrase({ phraseId: el.id, categoryId: categoryId }); } }))); }))) : (React.createElement(NoData, null)))))),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit }))))));
        } }))); }, [isLoadingUsefulPhrases]);
    return (React.createElement(StyledCategoryWrapper, null, renderForm));
};
export default CategoryModal;
