var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var WHITE = COLORS.WHITE, DARK_GREY = COLORS.DARK_GREY, BLUE_VIVID = COLORS.BLUE_VIVID, LIGHT_GREY = COLORS.LIGHT_GREY, DODGER_BLUE = COLORS.DODGER_BLUE;
export var StyledSearchFilterWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    position: relative;\n    margin-right: 15px;\n  }\n"], ["\n  && {\n    position: relative;\n    margin-right: 15px;\n  }\n"])));
export var StyledSelectSearchWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    background: ", ";\n    position: absolute;\n    width: 200px;\n    top: 35px;\n    z-index: 999;\n    border-radius: 4px;\n    padding: 15px;\n    max-height: 200px;\n  }\n"], ["\n  && {\n    background: ", ";\n    position: absolute;\n    width: 200px;\n    top: 35px;\n    z-index: 999;\n    border-radius: 4px;\n    padding: 15px;\n    max-height: 200px;\n  }\n"])), WHITE);
export var StyledSelectSearchItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    padding: 7px 10px;\n    width: 100%;\n    word-wrap: break-word;\n    font-size: 11px;\n    margin-top: 7px;\n    color: ", ";\n    background: ", ";\n    border-radius: 16px;\n\n    :hover {\n      cursor: pointer;\n      background: ", ";\n    }\n  }\n"], ["\n  && {\n    padding: 7px 10px;\n    width: 100%;\n    word-wrap: break-word;\n    font-size: 11px;\n    margin-top: 7px;\n    color: ", ";\n    background: ", ";\n    border-radius: 16px;\n\n    :hover {\n      cursor: pointer;\n      background: ", ";\n    }\n  }\n"])), function (props) { return (props.isSelected ? WHITE : DARK_GREY); }, function (props) { return (props.isSelected ? DODGER_BLUE : WHITE); }, function (props) { return (props.isSelected ? BLUE_VIVID : LIGHT_GREY); });
export var StyledSearchFilterInput = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    border-radius: 4px;\n\n    .input-container {\n      background: ", ";\n    }\n\n    input {\n      padding: 8px 10px;\n      text-overflow: ellipsis;\n      color: ", ";\n      ::placeholder {\n        color: ", ";\n      }\n      background: ", ";\n    }\n  }\n"], ["\n  && {\n    border-radius: 4px;\n\n    .input-container {\n      background: ", ";\n    }\n\n    input {\n      padding: 8px 10px;\n      text-overflow: ellipsis;\n      color: ", ";\n      ::placeholder {\n        color: ", ";\n      }\n      background: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.searchInputBackground;
}, WHITE, LIGHT_GREY, function (_a) {
    var theme = _a.theme;
    return theme.colors.searchInputBackground;
});
export var inputStyles = {
    maxWidth: '200px',
    paddingLeft: '5px',
    marginBottom: '0',
    flexDirection: 'row-reverse',
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
