var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEmpty } from 'lodash';
import { reduxModules } from '@src/global-interfaces';
import { createAction, createSlice } from '@reduxjs/toolkit';
export var ExcelTypes = {
    CREATE_EXCEL_FILE: "".concat(reduxModules.excel, "-currentExcel/createExcelFile"),
};
var initialState = {
    excelQueue: null,
    excelModal: {
        isMinimized: false,
    },
};
var excelSlice = createSlice({
    name: reduxModules.excel,
    initialState: initialState,
    reducers: {
        setQueueExel: function (state, action) {
            var _a;
            state.excelQueue = __assign(__assign({}, state.excelQueue), (_a = {}, _a[action.payload.tableType] = { url: action.payload.url, id: action.payload.id }, _a));
        },
        clearExcelQueue: function (state) {
            state.excelQueue = null;
        },
        deleteOneExcelFile: function (state, action) {
            var _a = state.excelQueue, _b = action.payload.tableType, value = _a[_b], newQueue = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            if (isEmpty(newQueue))
                state.excelQueue = null;
            else
                state.excelQueue = __assign({}, newQueue);
        },
        setExelModalStateMinimize: function (state) {
            state.excelModal = __assign(__assign({}, state.excelModal), { isMinimized: !state.excelModal.isMinimized });
        },
    },
});
export var excelActions = {
    createExcelFile: createAction(ExcelTypes.CREATE_EXCEL_FILE),
    addExcelFileId: excelSlice.actions.setQueueExel,
    deleteOneExcelFile: excelSlice.actions.deleteOneExcelFile,
    clearExcelQueue: excelSlice.actions.clearExcelQueue,
    setExelModalStateMinimize: excelSlice.actions.setExelModalStateMinimize,
};
export var excelReducer = excelSlice.reducer;
