import React from 'react';
import { StyledSwitch } from '@src/components/Switch/styled';
export var ToggleSwitch = function (_a) {
    var label = _a.label, _b = _a.contentOn, contentOn = _b === void 0 ? 'On' : _b, _c = _a.contentOff, contentOff = _c === void 0 ? 'Off' : _c, checked = _a.checked, onChange = _a.onChange, _d = _a.text, text = _d === void 0 ? '' : _d;
    return (React.createElement(StyledSwitch, null,
        React.createElement("input", { type: "checkbox", className: "checkbox", name: label, id: label, checked: checked, onChange: onChange }),
        React.createElement("label", { className: "label", htmlFor: label },
            React.createElement("span", { className: "inner", "data-enabled": contentOn, "data-disabled": contentOff }),
            React.createElement("span", { className: "switch" }),
            React.createElement("p", { className: "text" }, text))));
};
