import { connect } from 'react-redux';
import DialogsPage from '@src/modules/Pages/Dialogs/Dialogs';
import { appStore } from '@src/redux/app/selector';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    isModalOpen: modalWindowsStore.getIsModalOpen(state, modalTypes.DIALOG_MESSAGES),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.DIALOG_MESSAGES, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(DialogsPage);
