var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles } from '@src/app/styled';
var WHITE = COLORS.WHITE, DARK_GREY = COLORS.DARK_GREY;
export var StyledSearchListWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    min-width: 200px;\n    max-height: 200px;\n    overflow: auto;\n    z-index: 999;\n    top: 35px;\n    padding: 20px;\n    background-color: ", ";\n    ", ";\n    box-shadow: 0 0 4px ", ";\n    border-radius: 4px;\n    ", "\n  }\n"], ["\n  && {\n    min-width: 200px;\n    max-height: 200px;\n    overflow: auto;\n    z-index: 999;\n    top: 35px;\n    padding: 20px;\n    background-color: ", ";\n    ", ";\n    box-shadow: 0 0 4px ", ";\n    border-radius: 4px;\n    ", "\n  }\n"])), WHITE, function (props) { return props.isAbsolutePosition && 'position: absolute'; }, DARK_GREY, scrollStyles);
export var StyledSearchListItemsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  && {\n    display: flex;\n    flex-direction: column;\n  }\n"])));
export var StyledSearchInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    border-radius: 4px;\n\n    input {\n      background: #f5f4f4;\n    }\n  }\n"], ["\n  && {\n    border-radius: 4px;\n\n    input {\n      background: #f5f4f4;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
