import React from 'react';
import { icons } from '@public/images';
import { Icon } from '@src/components/Icon/Icon';
import { ItemCategoryWrapper } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ItemCategory/styled';
var ItemCategory = function (props) {
    var name = props.name, number = props.number, _a = props.isSelected, isSelected = _a === void 0 ? false : _a, id = props.id, onClickCategory = props.onClickCategory, deleteCategory = props.deleteCategory, editCategory = props.editCategory;
    var clickDeleteCategory = function (e) {
        e.stopPropagation();
        deleteCategory(id);
    };
    var clickEditCategory = function (e) {
        e.stopPropagation();
        editCategory(id);
    };
    return (React.createElement(ItemCategoryWrapper, { isSelected: isSelected, onClick: function () { return onClickCategory(id); } },
        React.createElement("div", { className: "item-category-text" },
            React.createElement("div", { className: "item-category-text__number" }, "".concat(number, ". ")),
            React.createElement("div", { className: "item-category-text__name" }, name)),
        React.createElement("div", { className: "item-category-icon-box" },
            React.createElement(Icon, { src: icons.editPhrases, onClick: function (e) { return clickEditCategory(e); }, isEvent: true }),
            React.createElement(Icon, { src: icons.deletePhrases, onClick: function (e) { return clickDeleteCategory(e); }, isEvent: true }))));
};
export default ItemCategory;
