import React from 'react';
import { StyledHeaderUserName } from '@src/modules/Header/HeaderUserName/styled';
export var getAppLang = function () { return localStorage.getItem('app_lang') || 'en'; };
var HeaderUserName = function (props) {
    var avatarUrl = props.avatarUrl, profileName = props.profileName;
    return (React.createElement(StyledHeaderUserName, { language: getAppLang() },
        avatarUrl && React.createElement("img", { className: "header-user-name__icon", src: avatarUrl, alt: "ads" }),
        React.createElement("div", { className: "header-user-name__name" }, profileName)));
};
export default HeaderUserName;
