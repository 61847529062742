import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import dialogsStore from '@src/modules/Pages/Dialogs/redux/selector';
import { entityStore } from '@src/redux/entities/selectors';
import { loadingStore } from '@src/redux/loading/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import ShowDialogModal from '@src/modules/Pages/Dialogs/ShowDialogModal/ShowDialogModal';
var mapStateToProps = function (state) { return ({
    entity: entityStore.entity(state),
    messages: dialogsStore.getDialogMessages(state),
    isLoading: loadingStore.getIsLoadingMessages(state),
    audioCalls: dialogsStore.getAudioCalls(state),
    agentDictionary: dictionariesStore.dictionaries(state).agentDictionary,
    modalInfo: modalWindowsStore.getModalInfo(state, modalTypes.DIALOG_MESSAGES),
}); };
export default connect(mapStateToProps, null)(ShowDialogModal);
