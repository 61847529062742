import { useEffect } from 'react';
export var useOutsideClick = function (cb, ref, filterElementIds, outsideClick) {
    if (filterElementIds === void 0) { filterElementIds = []; }
    function handleClickOutside(event) {
        if (!outsideClick)
            return;
        var eId = !event.target.id ? '' : event.target.id;
        if (ref.current && !ref.current.contains(event.target) && filterElementIds.indexOf(eId) === -1)
            cb();
    }
    useEffect(function () {
        document.addEventListener('mousedown', handleClickOutside);
        return function () { return document.removeEventListener('mousedown', handleClickOutside); };
    }, [ref, outsideClick]);
};
