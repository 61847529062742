import { connect } from 'react-redux';
import { appStore } from '@src/redux/app/selector';
import { appActions } from '@src/redux/app/reducer';
import { notificationStore } from '@src/redux/notification/selector';
import { notificationActions } from '@src/redux/notification/reducer';
import Header from './Header';
var mapStateToProps = function (state) { return ({
    notification: notificationStore.notification(state),
    theme: appStore.theme(state),
    profile: appStore.profile(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showConfirm: function (msg) { return dispatch(notificationActions.showConfirm(msg)); },
    saveCurrentPage: function (currentPage) { return dispatch(appActions.saveCurrentPage(currentPage)); },
    setTheme: function (theme) { return dispatch(appActions.setTheme(theme)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Header);
