import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { store } from '@src/redux/storeConfig';
import { getHistory } from '@src/utils/history';
import App from './app/App';
import './app/style.less';
function Main() {
    var history = getHistory();
    return (React.createElement(Provider, { store: store },
        React.createElement(Router, { history: history },
            React.createElement(App, null))));
}
var container = document.querySelector('#root');
var root = createRoot(container);
root.render(React.createElement(Main, null));
