import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var getBrandsDictionary = function () { return wretch("".concat(apiConfig.baseUrl, "/Brand/allidentifyname"))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var getChannelsDictionary = function () { return wretch("".concat(apiConfig.baseUrl, "/Channel/allidentifyname"))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var getGroupsDictionary = function () { return wretch("".concat(apiConfig.baseUrl, "/Group/allidentifyname"))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var getTagsDictionary = function () { return wretch("".concat(apiConfig.baseUrl, "/Tag/tags/allidentifyname"))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var getAgentsDictionary = function () { return wretch("".concat(apiConfig.baseUrl, "/Agent/allidentifyname"))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
export var dictionariesApi = {
    getBrandsDictionary: getBrandsDictionary,
    getChannelsDictionary: getChannelsDictionary,
    getGroupsDictionary: getGroupsDictionary,
    getTagsDictionary: getTagsDictionary,
    getAgentsDictionary: getAgentsDictionary,
};
