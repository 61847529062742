var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { Icon } from '@src/components/Icon/Icon';
import Loader from '@src/components/Loader';
import { system } from '@src/constants/objects';
import { loadingStore } from '@src/redux/loading/selector';
import { environmentRelatedUrls } from '@src/utils/baseUrl';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import ForgotPassword from '@src/modules/Auth/ForgotPassword/ForgotPassword';
import { StyledAuthWrapper, StyledAuthContainer, StyledAuthIconWrapper, StyledAuthBottomMessage, StyledWelcomeMessageWrapper, StyledAuthLoaderWrapper, } from './styled';
import LoginButtonFacebook from './social/Facebook';
import LoginButtonGoogle from './social/Google';
import AuthForm from './AskmeAuth';
var _a = system.AUTH_FORMS, LOGIN_FORM = _a.LOGIN_FORM, REGISTER_FORM = _a.REGISTER_FORM;
var agentProfile = environmentRelatedUrls.agentAskMeUrl;
var AuthPage = function (_a) {
    var isLoading = _a.isLoading, isModalOpen = _a.isModalOpen, toggleModal = _a.toggleModal;
    var logoIconStyles = {
        width: '120px',
        height: '63px',
        marginBottom: '40px',
    };
    var _b = __read(useState(LOGIN_FORM), 2), formType = _b[0], setFormType = _b[1];
    var isLoginForm = formType === LOGIN_FORM;
    var toggleForm = function () { return (formType === LOGIN_FORM ? setFormType(REGISTER_FORM) : setFormType(LOGIN_FORM)); };
    return (React.createElement(StyledAuthWrapper, { className: "auth-wrapper" },
        React.createElement(StyledAuthContainer, null,
            React.createElement(StyledAuthIconWrapper, null, isLoading
                ? (React.createElement(StyledAuthLoaderWrapper, null,
                    React.createElement(Loader, null)))
                : React.createElement(Icon, { src: icons.askMe, styles: logoIconStyles })),
            React.createElement(StyledWelcomeMessageWrapper, null, isLoginForm
                ? (React.createElement("p", { className: "welcome-message-signIn" },
                    Messages.Message.SignIn,
                    React.createElement("a", { target: "_blank", href: agentProfile, rel: "noreferrer" }, Messages.AdditionalText.AgentProfile)))
                : (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "welcome-message" }, Messages.Message.Welcome),
                    React.createElement("p", { className: "welcome-message-signUp" },
                        Messages.Message.SignUp,
                        React.createElement("a", { target: "_blank", href: agentProfile, rel: "noreferrer" }, Messages.AdditionalText.AgentProfile))))),
            React.createElement(React.Fragment, null,
                React.createElement(AuthForm, { isLoginForm: isLoginForm }),
                React.createElement(LoginButtonGoogle, null),
                React.createElement(LoginButtonFacebook, null)),
            React.createElement(StyledAuthBottomMessage, null,
                isLoginForm && (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        Messages.Message.Register,
                        React.createElement("a", { onClick: toggleForm },
                            ' ',
                            Messages.Controls.SignUp)),
                    React.createElement("a", { onClick: toggleModal }, Messages.Message.ForgotPassword))),
                !isLoginForm && (React.createElement("p", null,
                    Messages.Message.Login,
                    React.createElement("a", { onClick: toggleForm },
                        ' ',
                        Messages.Controls.Login)))),
            isModalOpen && (React.createElement(ForgotPassword, null)))));
};
var mapStateToProps = function (state) { return ({
    isModalOpen: modalWindowsStore.getIsModalOpen(state, modalTypes.FORGOT_PASSWORD),
    isLoading: loadingStore.getIsLoading(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.FORGOT_PASSWORD, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
