import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var AgentsPageTypes = {
    SHOW_AGENT_SETTINGS: "".concat(reduxModules.agentsPage, "-currentAgent/showAgentSettings"),
    BAN_OR_UNBAN_AGENT: "".concat(reduxModules.agentsPage, "-currentAgent/banOrUnbanAgent"),
    MAKE_OR_REMOVE_TEAM_LEAD: "".concat(reduxModules.agentsPage, "-currentAgent/makeOrRemoveTeamLead"),
    ADD_AND_DELETE_AGENT_TAGS: "".concat(reduxModules.agentsPage, "-currentAgent/addAndDeleteAgentTags"),
    MAKE_OR_REMOVE_SUPER_ADMIN: "".concat(reduxModules.agentsPage, "-currentAgent/makeOrRemoveSuperAdmin"),
    MAKE_OR_REMOVE_BRAND_ADMIN: "".concat(reduxModules.agentsPage, "-currentAgent/makeOrRemoveBrandAdmin"),
    ADD_AND_DELETE_AGENT_GROUPS: "".concat(reduxModules.agentsPage, "-currentAgent/addAndDeleteAgentGroups"),
    CHANGE_AGENT_INFO: "".concat(reduxModules.agentsPage, "-currentAgent/changeAgentInfo"),
};
var initialState = {
    currentAgent: null,
    tagsCurrentAgent: null,
    currentAgentRoles: null,
    countAgentsNewBees: 0,
    currentAgentGroups: null,
    availableGroupsCurrentAgent: null,
};
var agentsSlice = createSlice({
    name: reduxModules.agentsPage,
    initialState: initialState,
    reducers: {
        setCurrentAgent: function (state, action) {
            state.currentAgent = action.payload;
        },
        setTagsCurrentAgent: function (state, action) {
            state.tagsCurrentAgent = action.payload;
        },
        setCurrentAgentRoles: function (state, action) {
            state.currentAgentRoles = action.payload;
        },
        setCurrentAgentGroups: function (state, action) {
            state.currentAgentGroups = action.payload;
        },
        setCountAgentsNewBees: function (state, action) {
            state.countAgentsNewBees = action.payload;
        },
        setAvailableGroupsCurrentAgent: function (state, action) {
            state.availableGroupsCurrentAgent = action.payload;
        },
    },
});
export var agentsActions = {
    banOrUnbanAgent: createAction(AgentsPageTypes.BAN_OR_UNBAN_AGENT),
    showAgentSettings: createAction(AgentsPageTypes.SHOW_AGENT_SETTINGS),
    makeOrRemoveTeamLead: createAction(AgentsPageTypes.MAKE_OR_REMOVE_TEAM_LEAD),
    addAndDeleteAgentTags: createAction(AgentsPageTypes.ADD_AND_DELETE_AGENT_TAGS),
    makeOrRemoveBrandAdmin: createAction(AgentsPageTypes.MAKE_OR_REMOVE_BRAND_ADMIN),
    makeOrRemoveSuperAdmin: createAction(AgentsPageTypes.MAKE_OR_REMOVE_SUPER_ADMIN),
    addAndDeleteAgentGroups: createAction(AgentsPageTypes.ADD_AND_DELETE_AGENT_GROUPS),
    changeAgentInfo: createAction(AgentsPageTypes.CHANGE_AGENT_INFO),
    setCurrentAgent: agentsSlice.actions.setCurrentAgent,
    setTagsCurrentAgent: agentsSlice.actions.setTagsCurrentAgent,
    setCurrentAgentRoles: agentsSlice.actions.setCurrentAgentRoles,
    setCountAgentsNewBees: agentsSlice.actions.setCountAgentsNewBees,
    setCurrentAgentGroups: agentsSlice.actions.setCurrentAgentGroups,
    setAvailableGroupsCurrentAgent: agentsSlice.actions.setAvailableGroupsCurrentAgent,
};
export var agentsReducer = agentsSlice.reducer;
