var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { zoomIn } from 'react-animations';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
export var StyledPickerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background: rgba(0, 0, 0, 0.75);\n  }\n"], ["\n  && {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background: rgba(0, 0, 0, 0.75);\n  }\n"])));
export var StyledPickerContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n  }\n"], ["\n  && {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n  }\n"])));
export var StyledPicker = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    max-width: max-content;\n    animation: 0.5s ", " ease-in-out;\n    max-height: max-content;\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  && {\n    max-width: max-content;\n    animation: 0.5s ", " ease-in-out;\n    max-height: max-content;\n    display: flex;\n    flex-direction: column;\n  }\n"])), keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["", ""], ["", ""])), zoomIn));
export var StyledPickerTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    font-size: 15px;\n    text-align: center;\n    color: ", ";\n  }\n"], ["\n  && {\n    font-size: 15px;\n    text-align: center;\n    color: ", ";\n  }\n"])), COLORS.GREY);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
