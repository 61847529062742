import { getHistory } from '@src/utils/history';
export var formatToQueryFilters = function (filters, query) { return filters.map(function (elem) { return "&".concat(query, "=").concat(elem); }).join(''); };
var formatToQueryDate = function (filters) {
    if (Array.isArray(filters[1]) && getHistory().location.pathname === '/reports' && filters[1].length)
        return "&DateFrom=".concat(filters[1][0], "&DateTo=").concat(filters[1][1]);
    if (Array.isArray(filters[1]) && filters[1].length)
        return "&DatePeriod.DateFrom=".concat(filters[1][0], "&DatePeriod.DateTo=").concat(filters[1][1]);
    return '';
};
export var formatToQuerySearchValue = function (filters) {
    var searchValue = '';
    if (getHistory().location.pathname === '/agents') {
        filters.forEach(function (el) {
            if (!Array.isArray(el) && typeof el === 'object') {
                searchValue += "&SearchParameter.SearchBy=name&SearchParameter.SearchValue=".concat(el.value);
            }
        });
        return searchValue;
    }
    filters.forEach(function (el) {
        if (!Array.isArray(el) && typeof el === 'object') {
            searchValue += "&".concat(el.name, "=").concat(el.value);
        }
    });
    return searchValue;
};
export var transformFiltering = function (objFilter) { return ({
    date: formatToQueryDate((objFilter === null || objFilter === void 0 ? void 0 : objFilter.date) || []),
    search: formatToQuerySearchValue((objFilter === null || objFilter === void 0 ? void 0 : objFilter.search) || []),
    tags: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.tags) || [], 'TagIds'),
    lastTags: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.lastTags) || [], 'LastTagsIds'),
    brands: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.brands) || [], 'BrandIds'),
    statuses: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.statuses) || [], 'Statuses'),
    channels: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.channels) || [], 'ChannelIds'),
    languages: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.languages) || [], 'Languages'),
    agentNames: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.agentNames) || [], 'AgentIds'),
    messengers: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.messengers) || [], 'Messenger'),
    accessLevel: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.accessLevel) || [], 'AccessLevel'),
    sessionStatus: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.sessionStatus) || [], 'sessionStatus'),
    whiteListTags: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.whiteListTags) || [], 'WhiteListTags'),
    hasActiveDialogs: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.hasActiveDialogs) || [], 'agentAssigned'),
    isNewbie: formatToQueryFilters((objFilter === null || objFilter === void 0 ? void 0 : objFilter.isNewbie) || [], 'isNewbie'),
}); };
