import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var createBrand = function (brand) {
    return wretch("".concat(apiConfig.baseUrl, "/Brand")).auth("Bearer ".concat(getAuthCookie())).post(brand).json();
};
var getBrandSettingById = function (id) {
    return wretch("".concat(apiConfig.baseUrl, "/Brand?id=").concat(id)).auth("Bearer ".concat(getAuthCookie())).get().json();
};
var updateBrandSetting = function (brand) {
    return wretch("".concat(apiConfig.baseUrl, "/Brand")).auth("Bearer ".concat(getAuthCookie())).json(brand).put().json();
};
var createInbox = function (inbox) {
    return wretch("".concat(apiConfig.baseUrl, "/Messenger")).auth("Bearer ".concat(getAuthCookie())).post(inbox).json();
};
var createSmsAccount = function (smsInbox) {
    return wretch("".concat(apiConfig.baseUrl, "/Sms/messenger")).auth("Bearer ".concat(getAuthCookie())).post(smsInbox).json();
};
var getMessengersByBrand = function (id) {
    return wretch("".concat(apiConfig.baseUrl, "/Messenger/messengers?brandId=").concat(id)).auth("Bearer ".concat(getAuthCookie())).get().json();
};
var deleteInbox = function (brandId, messengerId, messenger) {
    return wretch("".concat(apiConfig.baseUrl, "/Messenger?brandId=").concat(brandId, "&messengerId=").concat(messengerId, "&messenger=").concat(messenger))
        .auth("Bearer ".concat(getAuthCookie()))
        .delete()
        .json();
};
var updateStatus = function (inbox) {
    return wretch("".concat(apiConfig.baseUrl, "/Messenger/status")).auth("Bearer ".concat(getAuthCookie())).json(inbox).patch();
};
var mailFile = function (file) {
    return wretch("".concat(apiConfig.domain, "/mail/mailbox_auth")).auth("Bearer ".concat(getAuthCookie())).post({ data: file });
};
var mailAuth = function (login, password) {
    return wretch("".concat(apiConfig.domain, "/mail/auth")).auth("Bearer ".concat(getAuthCookie())).post({ login: login, password: password }).json();
};
var setDomainSettings = function (domain, imap, smtpTLS, smtpSSL) {
    return wretch("".concat(apiConfig.domain, "/mail/mailbox_auth")).auth("Bearer ".concat(getAuthCookie())).post({
        domain: domain,
        imap: imap,
        smtpTLS: smtpTLS,
        smtpSSL: smtpSSL,
    });
};
var getSmsAccountsSettings = function (id) {
    return wretch("".concat(apiConfig.baseUrl, "/accounts/").concat(id)).auth("Bearer ".concat(getAuthCookie())).get().json();
};
var updateSmsAccountsBulkGate = function (inbox) {
    return wretch("".concat(apiConfig.baseUrl, "/Sms/account/bulkgate")).auth("Bearer ".concat(getAuthCookie())).json(inbox).patch().json();
};
var updateSmsAccountsMessageBird = function (inbox) {
    return wretch("".concat(apiConfig.baseUrl, "/Sms/account/messagebird")).auth("Bearer ".concat(getAuthCookie())).json(inbox).patch().json();
};
var setCountriesBulkGate = function (countries) {
    return wretch("".concat(apiConfig.baseUrl, "/Sms/countries/bulkgate")).auth("Bearer ".concat(getAuthCookie())).post(countries).json();
};
var setCountriesMessageBird = function (countries) {
    return wretch("".concat(apiConfig.baseUrl, "/Sms/countries/messagebird")).auth("Bearer ".concat(getAuthCookie())).post(countries).json();
};
var changeStatusBrand = function (status, id) {
    return wretch("".concat(apiConfig.baseUrl, "/Brand/admin/brand/").concat(id, "/status"))
        .auth("Bearer ".concat(getAuthCookie()))
        .json({ status: status })
        .patch()
        .json();
};
export var brandsApi = {
    mailAuth: mailAuth,
    mailFile: mailFile,
    createInbox: createInbox,
    createBrand: createBrand,
    deleteInbox: deleteInbox,
    updateStatus: updateStatus,
    createSmsAccount: createSmsAccount,
    setDomainSettings: setDomainSettings,
    changeStatusBrand: changeStatusBrand,
    updateBrandSetting: updateBrandSetting,
    getBrandSettingById: getBrandSettingById,
    getMessengersByBrand: getMessengersByBrand,
    setCountriesBulkGate: setCountriesBulkGate,
    getSmsAccountsSettings: getSmsAccountsSettings,
    setCountriesMessageBird: setCountriesMessageBird,
    updateSmsAccountsBulkGate: updateSmsAccountsBulkGate,
    updateSmsAccountsMessageBird: updateSmsAccountsMessageBird,
};
