import DateRenderer from '@src/components/tableRenderers/DateRenderer/DateRenderer';
import TextRenderer from '@src/components/tableRenderers/TextRenderer/TextRenderer';
import TimeRenderer from '@src/components/tableRenderers/TimeRenderer/TimeRenderer';
import StatusRender from '@src/components/tableRenderers/StatusRender/StatusRender';
import AgentRenderer from '@src/components/tableRenderers/AgentRenderer/AgentRenderer';
import StatusRenderer from '@src/components/tableRenderers/StatusRenderer/StatusRenderer';
import LanguageRenderer from '@src/components/tableRenderers/LanguageRenderer/LanguageRenderer';
import ArrayTextRenderer from '@src/components/tableRenderers/ArrayTextRenderer/ArrayTextRenderer';
import MessengerRenderer from '@src/components/tableRenderers/MessengerRenderer/MessengerRenderer';
import TextEllipsisRenderer from './TextEllipsisRenderer/TextEllipsisRenderer';
export var tableRenderers = {
    TextRenderer: TextRenderer,
    TimeRenderer: TimeRenderer,
    DateRenderer: DateRenderer,
    StatusRender: StatusRender,
    AgentRenderer: AgentRenderer,
    StatusRenderer: StatusRenderer,
    LanguageRenderer: LanguageRenderer,
    ArrayTextRenderer: ArrayTextRenderer,
    MessengerRenderer: MessengerRenderer,
    TextEllipsisRenderer: TextEllipsisRenderer,
};
