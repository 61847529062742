var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledFilterWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    position: relative;\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    position: relative;\n  }\n"])));
export var StyledFiltersAddBtnWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    position: relative;\n    align-items: center;\n    margin-right: ", ";\n  }\n"], ["\n  && {\n    display: flex;\n    position: relative;\n    align-items: center;\n    margin-right: ", ";\n  }\n"])), function (props) { return (props.language === 'ar' || props.language === 'he' ? '15px' : '0'); });
export var StyledSelectedFilters = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    display: flex;\n    padding: 0 8px;\n    align-items: baseline;\n  }\n"], ["\n  && {\n    display: flex;\n    padding: 0 8px;\n    align-items: baseline;\n  }\n"])));
export var StyledFiltersContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-wrap: wrap;\n  }\n"], ["\n  && {\n    display: flex;\n    flex-wrap: wrap;\n  }\n"])));
export var StyledResetFilters = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([" && {\n  margin-", ": 15px;\n  cursor: pointer;\n  font-weight: bold;\n  color: ", ";;\n}"], [" && {\n  margin-", ": 15px;\n  cursor: pointer;\n  font-weight: bold;\n  color: ", ";;\n}"])), function (props) { return props.reverse; }, function (_a) {
    var theme = _a.theme;
    return theme.colors.resetFiltersBtn;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
