var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Messages } from '@public/locales/messages';
export var mapAgentGroupsData = function (groups, dictionaries) {
    var tagDictionary = dictionaries.tagDictionary;
    return groups.map(function (group, index) {
        var formatTags = function (tagsIds) {
            var tagNames = [];
            if (tagsIds)
                tagNames = tagsIds.map(function (tagId) { return tagDictionary[tagId]; });
            return tagNames;
        };
        return __assign(__assign({}, group), { name: group.name || Messages.Controls.NoData, description: group.description || Messages.Controls.NoData, whiteListTags: formatTags(group.whiteListTags) || Messages.Controls.NoData, access_level: group.accessLevel || Messages.Controls.NoData, index: ++index || Messages.Controls.NoData });
    });
};
