import React from 'react';
import { StyledCustomColorPickerBtn, StyledCustomColorPickerTitle, StyledCustomColorPickerWrapper, StyledCustomColorPickerBtnIcon, StyledCustomColorPickerBtnValue, StyledCustomColorPickerBrnWrapper, } from '@src/components/ColorPickerBtn/styled';
var ColorPickerBtn = function (props) {
    var title = props.title, valueColor = props.valueColor, onClick = props.onClick;
    return (React.createElement(StyledCustomColorPickerWrapper, { onClick: onClick },
        React.createElement(StyledCustomColorPickerBtn, null,
            React.createElement(StyledCustomColorPickerTitle, null, title),
            React.createElement(StyledCustomColorPickerBrnWrapper, null,
                React.createElement(StyledCustomColorPickerBtnIcon, { valueColor: valueColor }),
                React.createElement(StyledCustomColorPickerBtnValue, null, valueColor)))));
};
export default ColorPickerBtn;
