import wretch from 'wretch';
import { getAuthCookie } from '@src/utils/cookie';
import { apiConfig } from '@src/utils/baseUrl';
var getDialogs = function (_a) {
    var _b = _a.date, date = _b === void 0 ? '' : _b, _c = _a.tags, tags = _c === void 0 ? '' : _c, _d = _a.count, count = _d === void 0 ? 15 : _d, _e = _a.brands, brands = _e === void 0 ? '' : _e, _f = _a.offset, offset = _f === void 0 ? 0 : _f, _g = _a.channels, channels = _g === void 0 ? '' : _g, _h = _a.statuses, statuses = _h === void 0 ? '' : _h, _j = _a.sortOrder, sortOrder = _j === void 0 ? 'desc' : _j, _k = _a.languages, languages = _k === void 0 ? '' : _k, _l = _a.messengers, messengers = _l === void 0 ? '' : _l, _m = _a.sortingParameter, sortingParameter = _m === void 0 ? 'created_at' : _m, _o = _a.identificationRole, identificationRole = _o === void 0 ? '' : _o, _p = _a.agentNames, agentNames = _p === void 0 ? '' : _p;
    return wretch("".concat(apiConfig.baseUrl, "/Dialog/dialogs/").concat(identificationRole, "?Pagination.Count=").concat(count, "&Pagination.Offset=").concat(offset, "&Sorting.Parameter=").concat(sortingParameter, "\n&Sorting.SortOrder=").concat(sortOrder).concat(channels).concat(messengers).concat(languages).concat(statuses).concat(tags).concat(date).concat(agentNames).concat(brands))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getGroups = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.date, date = _c === void 0 ? '' : _c, _d = _b.count, count = _d === void 0 ? 15 : _d, _e = _b.offset, offset = _e === void 0 ? 0 : _e, _f = _b.brands, brands = _f === void 0 ? '' : _f, _g = _b.sortOrder, sortOrder = _g === void 0 ? 'desc' : _g, _h = _b.accessLevel, accessLevel = _h === void 0 ? '' : _h, _j = _b.whiteListTags, whiteListTags = _j === void 0 ? '' : _j, _k = _b.sortingParameter, sortingParameter = _k === void 0 ? 'created_at' : _k, _l = _b.identificationRole, identificationRole = _l === void 0 ? '' : _l;
    return wretch("".concat(apiConfig.baseUrl, "/Group/groups/").concat(identificationRole, "/brand?Pagination.Count=").concat(count, "&Pagination.Offset=").concat(offset, "&Sorting.Parameter=").concat(sortingParameter, "\n&Sorting.SortOrder=").concat(sortOrder).concat(accessLevel).concat(whiteListTags).concat(date).concat(brands))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getAgents = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.date, date = _c === void 0 ? '' : _c, _d = _b.count, count = _d === void 0 ? 15 : _d, _e = _b.search, search = _e === void 0 ? '' : _e, _f = _b.offset, offset = _f === void 0 ? 0 : _f, _g = _b.brands, brands = _g === void 0 ? '' : _g, _h = _b.sortOrder, sortOrder = _h === void 0 ? 'desc' : _h, _j = _b.sessionStatus, sessionStatus = _j === void 0 ? '' : _j, _k = _b.isNewbie, isNewbie = _k === void 0 ? '' : _k, _l = _b.sortingParameter, sortingParameter = _l === void 0 ? 'created_at' : _l;
    return wretch("".concat(apiConfig.baseUrl, "/Agent/admin/agents?Pagination.Count=").concat(count, "&Pagination.Offset=").concat(offset, "\n&Sorting.Parameter=").concat(sortingParameter, "&Sorting.SortOrder=").concat(sortOrder).concat(sessionStatus).concat(date).concat(search).concat(brands).concat(isNewbie))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getTags = function (_a) {
    var _b = _a.count, count = _b === void 0 ? 10 : _b, _c = _a.offset, offset = _c === void 0 ? 0 : _c, _d = _a.brands, brands = _d === void 0 ? '' : _d, _e = _a.sortOrder, sortOrder = _e === void 0 ? 'desc' : _e, _f = _a.sortingParameter, sortingParameter = _f === void 0 ? 'created_at' : _f;
    return wretch("".concat(apiConfig.baseUrl, "/Tag/admin/tags?Pagination.Count=").concat(count, "&Pagination.Offset=").concat(offset, "&sorting.parameter=").concat(sortingParameter, "&sorting.sortOrder=").concat(sortOrder).concat(brands))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getClients = function (_a) {
    var _b = _a.count, count = _b === void 0 ? 15 : _b, _c = _a.search, search = _c === void 0 ? '' : _c, _d = _a.brands, brands = _d === void 0 ? '' : _d, _e = _a.offset, offset = _e === void 0 ? 0 : _e, _f = _a.lastTags, lastTags = _f === void 0 ? '' : _f, _g = _a.sortOrder, sortOrder = _g === void 0 ? 'desc' : _g, _h = _a.messengers, messengers = _h === void 0 ? '' : _h, _j = _a.agentNames, agentNames = _j === void 0 ? '' : _j, _k = _a.hasActiveDialogs, hasActiveDialogs = _k === void 0 ? '' : _k, _l = _a.sortingParameter, sortingParameter = _l === void 0 ? 'entry_date' : _l, _m = _a.identificationRole, identificationRole = _m === void 0 ? '' : _m;
    return wretch("".concat(apiConfig.baseUrl, "/Client/clients/").concat(identificationRole, "?Pagination.Count=").concat(count, "&Pagination.Offset=").concat(offset, "&Sorting.Parameter=").concat(sortingParameter, "\n&Sorting.SortOrder=").concat(sortOrder).concat(search).concat(messengers).concat(lastTags).concat(agentNames).concat(brands).concat(hasActiveDialogs))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getChannels = function (_a) {
    var _b = _a.count, count = _b === void 0 ? 15 : _b, _c = _a.search, search = _c === void 0 ? '' : _c, _d = _a.brands, brands = _d === void 0 ? '' : _d, _e = _a.offset, offset = _e === void 0 ? 0 : _e, _f = _a.languages, languages = _f === void 0 ? '' : _f, _g = _a.sortOrder, sortOrder = _g === void 0 ? 'desc' : _g, _h = _a.sortingParameter, sortingParameter = _h === void 0 ? 'created_at' : _h, _j = _a.identificationRole, identificationRole = _j === void 0 ? '' : _j;
    return wretch("".concat(apiConfig.baseUrl, "/Channel/channels/").concat(identificationRole, "/brand?Pagination.Count=").concat(count, "&Pagination.Offset=").concat(offset, "&Sorting.Parameter=").concat(sortingParameter, "&Sorting.SortOrder=").concat(sortOrder).concat(languages).concat(search).concat(brands))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getBrands = function (_a) {
    var _b = _a.count, count = _b === void 0 ? 15 : _b, _c = _a.search, search = _c === void 0 ? '' : _c, _d = _a.offset, offset = _d === void 0 ? 0 : _d, _e = _a.sortOrder, sortOrder = _e === void 0 ? 'desc' : _e, _f = _a.sortingParameter, sortingParameter = _f === void 0 ? 'created_at' : _f, _g = _a.identificationRole, identificationRole = _g === void 0 ? '' : _g;
    return wretch("".concat(apiConfig.baseUrl, "/Brand/brands/").concat(identificationRole, "?Pagination.Count=").concat(count, "&Pagination.Offset=").concat(offset, "&Sorting.Parameter=").concat(sortingParameter, "&Sorting.SortOrder=").concat(sortOrder).concat(search))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getReportMessengers = function (_a) {
    var _b = _a.date, date = _b === void 0 ? '' : _b, _c = _a.brands, brands = _c === void 0 ? '' : _c, _d = _a.sortOrder, sortOrder = _d === void 0 ? 'Descending' : _d, _e = _a.sortingParameter, sortingParameter = _e === void 0 ? 'Name' : _e;
    return wretch("".concat(apiConfig.statisticUrl, "/Statistics/reports?MessengersSortTypes=").concat(sortingParameter, "&SortingOrder=").concat(sortOrder).concat(date).concat(brands))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getReportAgents = function (_a) {
    var _b = _a.date, date = _b === void 0 ? '' : _b, count = _a.count, offset = _a.offset, _c = _a.search, search = _c === void 0 ? '' : _c, _d = _a.brands, brands = _d === void 0 ? '' : _d, _e = _a.sortOrder, sortOrder = _e === void 0 ? 'Descending' : _e, _f = _a.sortingParameter, sortingParameter = _f === void 0 ? 'OnlineTime' : _f;
    return wretch("".concat(apiConfig.statisticUrl, "/Statistics/agents?AgentSortingField=").concat(sortingParameter, "&SortOrder=").concat(sortOrder, "&Count=").concat(count, "&Offset=").concat(offset).concat(search).concat(date).concat(brands))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
var getGroupsReport = function (_a) {
    var _b = _a.date, date = _b === void 0 ? '' : _b, count = _a.count, _c = _a.brands, brands = _c === void 0 ? '' : _c, offset = _a.offset, _d = _a.sortOrder, sortOrder = _d === void 0 ? 'Descending' : _d, _e = _a.sortingParameter, sortingParameter = _e === void 0 ? 'ReceivedCount' : _e;
    return wretch("".concat(apiConfig.statisticUrl, "/Statistics/groups?SortingField=").concat(sortingParameter, "&SortOrder=").concat(sortOrder, "&Count=").concat(count, "&Offset=").concat(offset).concat(date).concat(brands))
        .auth("Bearer ".concat(getAuthCookie()))
        .get()
        .json();
};
export var entitiesApi = {
    getTags: getTags,
    getBrands: getBrands,
    getAgents: getAgents,
    getGroups: getGroups,
    getDialogs: getDialogs,
    getClients: getClients,
    getChannels: getChannels,
    getReportAgents: getReportAgents,
    getGroupsReport: getGroupsReport,
    getReportMessengers: getReportMessengers,
};
