import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
import { DictionaryTypes } from './types';
var initialState = {
    brandDictionary: {},
    channelDictionary: {},
    groupDictionary: {},
    tagDictionary: {},
    agentDictionary: {},
};
var dictionarySlice = createSlice({
    name: reduxModules.dictionary,
    initialState: initialState,
    reducers: {
        setBrandDictionary: function (state, action) {
            state.brandDictionary = action.payload;
        },
        setChannelDictionary: function (state, action) {
            state.channelDictionary = action.payload;
        },
        setGroupDictionary: function (state, action) {
            state.groupDictionary = action.payload;
        },
        setTagDictionary: function (state, action) {
            state.tagDictionary = action.payload;
        },
        setAgentDictionary: function (state, action) {
            state.agentDictionary = action.payload;
        },
    },
});
export var dictionariesActions = {
    getAllDictionaries: createAction(DictionaryTypes.GET_ALL_DICTIONARIES),
    getTagDictionary: createAction(DictionaryTypes.GET_TAG_DICTIONARY),
    getBrandDictionary: createAction(DictionaryTypes.GET_BRAND_DICTIONARY),
    getAgentDictionary: createAction(DictionaryTypes.GET_AGENT_DICTIONARY),
    getGroupDictionary: createAction(DictionaryTypes.GET_GROUP_DICTIONARY),
    getChannelDictionary: createAction(DictionaryTypes.GET_CHANNEL_DICTIONARY),
    setBrandDictionary: dictionarySlice.actions.setBrandDictionary,
    setChannelDictionary: dictionarySlice.actions.setChannelDictionary,
    setGroupDictionary: dictionarySlice.actions.setGroupDictionary,
    setTagDictionary: dictionarySlice.actions.setTagDictionary,
    setAgentDictionary: dictionarySlice.actions.setAgentDictionary,
};
export var dictionariesReducer = dictionarySlice.reducer;
