var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useMemo, useState, } from 'react';
import { Field, Form } from 'react-final-form';
import { TButtonTypes } from '@src/components/Button/Button';
import { AccessLevelGroups, notificationTypes } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import Select from '@src/components/Select';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired, lengthCreator, } from '@src/utils/validations';
import { Messages } from '@public/locales/messages';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledUpdateGroupInput } from '@src/modules/Pages/Groups/GroupsUpdateModal/UpdateGroups/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var UpdateGroups = function (props) {
    var currentGroup = props.currentGroup, showNotification = props.showNotification, updateCurrentGroup = props.updateCurrentGroup, resetNotificationState = props.resetNotificationState;
    var minGroupNameLength = lengthCreator.minGroupNameLength, maxGroupNameLength = lengthCreator.maxGroupNameLength, minDescriptionGroup = lengthCreator.minDescriptionGroup, maxDescriptionGroup = lengthCreator.maxDescriptionGroup;
    var name = currentGroup.name, accessLevel = currentGroup.accessLevel, description = currentGroup.description;
    var AccessLevelGroupsSearch = function (arr) { return arr.filter(function (el) { return el.value === accessLevel; }); };
    var memoizedAccessLevelGroupsSearch = useMemo(function () { return AccessLevelGroupsSearch(AccessLevelGroups); }, [accessLevel]);
    var _a = __read(useState(memoizedAccessLevelGroupsSearch), 2), changedAccessLevel = _a[0], setChangedAccessLevel = _a[1];
    var _b = __read(useState(accessLevel), 2), currentAccessLevel = _b[0], setCurrentAccessLevel = _b[1];
    var showWarning = function () {
        showNotification({
            msg: Messages.Warning.FieldIsRequired,
            type: notificationTypes.warning,
        });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChangeAccessLevel = useCallback(function (el) {
        setCurrentAccessLevel(el.value);
        setChangedAccessLevel(el);
    }, [changedAccessLevel]);
    var groupsInput = InputWithStyles(Input, StyledUpdateGroupInput);
    var onSubmit = function (data) {
        var groupName = data.groupName, description = data.description;
        updateCurrentGroup({ groupName: groupName, accessLevel: currentAccessLevel, description: description });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { initialValues: {
                description: description,
                groupName: name,
            }, onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(StyledModalContentWrapper, null,
                        React.createElement(StyledModalContentFieldsWrapper, null,
                            React.createElement(Field, { name: "groupName", label: Messages.Labels.GroupName, showWarning: showWarning, validate: composeValidators(isRequired, minGroupNameLength, maxGroupNameLength), placeholder: Messages.Controls.GroupName, component: groupsInput }),
                            React.createElement(Field, { name: "description", showWarning: showWarning, label: Messages.Labels.Description, validate: composeValidators(isRequired, minDescriptionGroup, maxDescriptionGroup), placeholder: Messages.Controls.Description, component: groupsInput }),
                            React.createElement(Select, { initialState: changedAccessLevel, items: AccessLevelGroups, isMultiply: false, label: Messages.Labels.SelectAccessLevel, placeholder: Messages.Selects.SelectAccessLevel, stateCB: function (el) { return onChangeAccessLevel(el); } })),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit }))))));
            } })));
};
export default UpdateGroups;
