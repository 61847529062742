import { createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
import { toggleModal } from '@src/redux/modalWindows/toggleModal';
export var modalWindowsTypes = {
    TOGGLE_MODAL: "".concat(reduxModules.modalWindows, "/toggleModal"),
};
var initialState = {
    modals: [],
    modalsInfo: {},
};
var modalWindowsSlice = createSlice({
    name: reduxModules.modalWindows,
    initialState: initialState,
    reducers: null,
    extraReducers: function (builder) {
        builder.addCase(modalWindowsTypes.TOGGLE_MODAL, function (state, action) {
            toggleModal(state, action);
        });
    },
});
export var modalWindowsActions = {
    toggleModal: function (type, modalInfo) { return ({
        type: modalWindowsTypes.TOGGLE_MODAL,
        payload: { type: type, modalInfo: modalInfo },
    }); },
};
export var modalWindowsReducer = modalWindowsSlice.reducer;
