import React from 'react';
import { COLORS } from '@src/app/styled';
import Button from '@src/components/Button';
import { StyledUnBanAgent } from '@src/components/Button/styled';
import { StyledBanAgentText } from '@src/modules/Pages/Agents/AgentSettings/BanAgent/styled';
import { Messages } from '@public/locales/messages';
var RED = COLORS.RED, GREEN = COLORS.GREEN;
var BanAgent = function (props) {
    var currentAgent = props.currentAgent, modalInfo = props.modalInfo, banOrUnbanAgent = props.banOrUnbanAgent;
    var banAgentText = Messages.Labels.BanningThisAgent;
    var backgroundBtn = currentAgent.status === 'banned' ? GREEN : RED;
    var titleBtn = currentAgent.status === 'banned' ? Messages.Buttons.unban : Messages.Buttons.ban;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledBanAgentText, null, banAgentText),
        React.createElement(StyledUnBanAgent, { background: backgroundBtn },
            React.createElement(Button, { title: titleBtn, onClick: function () { return banOrUnbanAgent(modalInfo); } }))));
};
export default BanAgent;
