var Messages = {
    Success: {
        Success: 'Успех',
        ForgotPasswordSend: 'Ссылка для смены пароля учетной записи была отправлена на ваш адрес электронной почты',
        ResetPasswordSuccess: 'Ваш пароль успешно обновлен',
        BrandCreated: 'Бренд создан!',
        MailInboxCreated: 'Почтовый ящик создан!',
        PrivacyPolicyChanged: 'Политика конфиденциальности изменена!',
        SmsInboxCreated: 'Почтовый ящик для смс создан!',
        InboxDeleted: 'Почтовый ящик удалён!',
        StatusChanged: 'Статус изменен!',
        FileLoaded: 'Файл загружен!',
        AuthMail: 'Авторская почта!',
        DomainSettings: 'Настройки домена!',
        UpdatedSmsAccounts: 'Cмс аккаунты обновлены!',
        ChanelCreated: 'Канал создан!',
        DeleteMessenger: 'Удалить мессенджер!',
        AddMessenger: 'Добавьте мессенджер!',
        ChangedMessenger: 'Измененный мессенджер!',
        ClientCreated: 'Клиент создан!',
        ClientsImports: 'Импорт клиентов!',
        GroupCreated: 'Группа создана!',
        ChangedAssignedAgentsInGroups: 'Изменены назначенные агенты в группах!',
        ChangedAssignedChannelsInGroups: 'Изменены назначенные каналы в группах!',
        ChangedAssignedTagsInGroups: 'Изменены присвоенные теги в группах!',
        CreateNewCategory: 'Создать новую категорию!',
        CreateNewPhrase: 'Создать новую фразу!',
        DeleteCategory: 'Удалить категорию!',
        DeletePhrase: 'Удалить фразу!',
        UpdatePhrase: 'Обновите фразу!',
        EditCategory: 'Изменить категорию!',
        DeleteAllCategory: 'Удалить все категории!',
        TagCreated: 'Тег создан!',
        TagUpdated: 'Тег обновлен!',
        TelegramInboxCreated: 'Телеграм аккаунт создан!',
        HeaderTextChanged: 'Текст шапки изменён',
        LogoIconUrlChanged: 'URL иконки лого изменён',
        IconUrlChanged: 'URL иконки изменён',
        BackgroundImageUrlChanged: 'URL картинки заднего фона изменён',
        GreetingImageUrlChanged: 'URL картинки приветствия изменён',
        AgentImageUrlChanged: 'URL картинки агента изменён',
        InputPlaceholderChanged: 'Прототип поля ввода изменён',
        SendImageUrlChanged: 'URL картинки отправки изменён',
        AgentStatusChanged: 'Статус агента изменен',
        SelectedAgentTagsChanged: 'Выбранные теги агента изменены',
        AssignedAgentsGroupsChanged: 'Назначенные группы агентов изменены',
        TeamLeadChanged: 'Сменился лидер команды',
        AgentRoleChanged: 'Роль агента изменена',
        AgentInfoChanged: 'Информация об агенте изменена',
    },
    Errors: {
        PasswordMatching: 'Пароли не совпадают',
        IncorrectEmail: 'Введите действительный адрес электронную почту',
        IncorrectPhone: 'Введите действительный телефонний номер',
        IncorrectUrl: 'Введите действительный Url адрес',
        FieldContainSpaces: 'Поле содержит пробелы',
        FieldIsRequired: 'Поле обязательное для заполнения',
        NameValidation: 'Длина поля должна быть от 2 до 40 символов включительно.',
        NumberValidation: 'Поле должно содержать только цифри',
        PasswordValidation: 'Пароль должен содержать буквы и цифры и не может содержать пробелов.',
        InvoiceNumberValidation: 'Номер счета должен содержать латинские буквы, цифры и спецсимволы',
        MinimumLength: 'Минимальная длина {0} символов',
        MaximumLength: 'Максимальная длина {0} символов',
        ServerError: 'Ошибка сервера, попробуйте позже!',
        ServerErrorFileDownloadTimeOut: 'Ошибка сервера, время загрузки файла истекло, попробуйте позже!',
        NotValidEmailOrPassword: 'Недействительный адрес электронной почты или пароль',
        UserWasNotFound: 'Пользователь не найден!',
        UserIsAlreadyExist: 'Пользователь уже существует!',
        AgentWasBanned: 'Агент был забанен',
        TokenExpired: 'Время сессии истекло. Пожалуйста перезайдите',
        TelegramIdIsIncorrect: 'Неверный ID Телеграма',
        ErrorWrongFileFormat: 'Ошибка, неправильный формат файла!',
        FileSizeExceededWarnings: 'Размер файла превышает предупреждения!',
        BrandWithSuchNameIsAlreadyExisting: 'Бренд с таким названием уже существует!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Вы уже выбрали фильтр',
        AgentCreate: 'Вы новый агент в системе AskMe. Обратите внимание, что вы можете войти в систему только после того, как вам будет назначена роль администратора.',
        NoStatisticsForTheAgent: 'Нет статистических данных по агенту за выбранный период времени',
        FieldIsRequired: 'Поле обязательное для заполнения',
        ChannelMustContainAtLeastOneLanguage: 'Канал должен содержать хотя бы один язык',
    },
    Info: {
        Copied: 'скопировано',
        PhraseBotNameAndGreetingsChanged: 'Фразы, имя бота и приветствие изменены',
    },
    Confirm: {},
    Message: {
        Welcome: 'Добро пожаловать в системе AskMe',
        SignIn: 'Войдите в панель администратора или перейдите на ваш ',
        SignUp: 'Зарегистрируйтесь в панели администратора или перейдите на ваш ',
        Register: 'Нету аккаунта?',
        Login: 'Уже есть аккаунт?',
        Logout: 'Вы действительно хотите выйти?',
        ForgotPassword: 'Забыли пароль?',
        ExcelFilesDownloading: 'Загрузка файлов Excel',
        excelFileIs: 'файл Excel',
        Created: 'создан',
        Creating: 'создается',
    },
    Accordions: {
        MailInboxes: 'Электронная почта',
        TelegramAccounts: 'Аккаунты Telegram',
        SmsAccounts: 'SMS аккаунты',
    },
    Controls: {
        Newbie: 'Новичок',
        Name: 'Имя',
        Email: 'Электронная почта',
        Password: 'Пароль',
        RepeatPassword: 'Повторите пароль',
        Register: 'Регистрация',
        Login: 'Вход',
        Or: 'Или',
        SignUp: 'Регистрация',
        Send: 'Отправить',
        SearchFilter: 'Фильтр поиска',
        Search: 'Поиск',
        SearchMessengers: 'Поиск мессенджеров',
        SearchAgent: 'Поиск агента',
        SearchLanguage: 'Поиск языка',
        SearchChannel: 'Поиск канала',
        SearchTag: 'Поиск тега',
        SearchStatus: 'Поиск статуса',
        SearchBrand: 'Поиск бренда',
        SearchWhiteListTags: 'Поиск разрешенных тегов',
        SearchSessionStatus: 'Поиск статуса сеанса',
        SearchAccessLevel: 'Поиск уровни доступа',
        SearchHasActiveDialogs: 'Поиск наличия активных диалогов',
        GroupName: 'Название группы*',
        Description: 'Описание*',
        LinkAlias: 'Название ссылки*',
        CategoryName: 'Имя категории*',
        BrandName: 'Имя бренда',
        AccessKey: 'Ключ доступа',
        ClientName: 'Имя пользователя клиента',
        ClientFirstName: 'Имя клиента',
        ClientLastName: 'Фамилия клиента',
        ClientPhone: 'Телефон клиента',
        ClientEmail: 'Электронная почта клиента',
        PleaseWrite: 'Укажите',
        MailInbox: 'Электронная почта',
        TelegramInbox: 'Аккаунт Telegram',
        SmsInbox: 'Аккаунт SMS',
        ApplicationID: 'ID приложения',
        ApplicationToken: 'Токен приложения',
        SenderID: 'ID отправителя',
        ChangeName: 'Изменить имя',
        ChangeURL: 'Изменить URL',
        TagName: 'Название тега',
        LinkUrl: 'URL-адрес ссылки',
        Greetings: 'Приветвствие',
        FastPhrase: 'Быстрая фраза №',
        ClickOnPaperclip: 'Кликните по иконке скрепки для выбора файла',
        UserName: 'Имя пользователя',
        Domain: 'Домен*',
        Imap: 'Imap (хост:порт)*',
        SmtpTLS: 'Smtp TLS (хост:порт)*',
        SmtpSSL: 'Smtp SSL (хост:порт)*',
        AttachFile: 'Прикрепить файл',
        NoData: 'нет данных',
        NoRating: 'нет рейтинга',
        IfYouWantFinishDialog: 'Если вы хотите завершить диалог нажмите на кнопку ОК',
        AllDialogsAreVisibleToAllAgents: 'Все диалоги видны для всех агентов',
        DialogueIsAssignedAutomatically: 'Диалог автоматически привязан к агенту,у которого меньше всего диалогов',
        AllDialogsAreVisibleToSeveralAgents: 'Диалог виден для агентов,у которых меньше всего диалогов',
        On: 'Вкл',
        Off: 'Выкл',
        FirstName: 'Имя',
        InvoiceNumber: 'Номер счета',
        LastName: 'Фамилия',
        SMS: 'СМС',
        New: 'Новый',
        Active: 'Активный',
        Finished: 'Завершённый',
        Assigned: 'Назначенный',
        Online: 'В сети',
        Offline: 'Вне сети',
        Break: 'Перерыв',
        Low: 'Низкий',
        Medium: 'Средний',
        High: 'Высокий',
        True: 'Правда',
        False: 'Ложь',
        Date: 'Дата',
        AgentName: 'Имя агента',
        Messenger: 'Мессенджер',
        Language: 'Язык',
        Channel: 'Канал',
        Tag: 'Тег',
        Status: 'Статус',
        Brand: 'Бренд',
        AccessLevel: 'Уровень доступа',
        WhiteListTags: 'Теги белого списка',
        SessionStatus: 'Статус сессии',
        LastTags: 'Последние теги',
        HasActiveDialogs: 'Имеет активные диалоги',
        ChannelName: 'Имя канала',
        WriteYourFirstName: '* Пожалуйста,введите своё имя',
        WriteYourLastName: '* Пожалуйста,введите свою фамилию',
        WriteYourUserName: '* Пожалуйста,введите свой имя пользователя',
        WriteYourPhoneNumber: '* Пожалуйста,введите свой номер телефона',
        WriteYourEmail: '* Пожалуйста,введите свою электронную почту',
        WriteYourInvoiceNumber: '* Пожалуйста,введите свой номер счёта',
        WriteYourAccountNumber: '* Пожалуйста,введите свой номер аккаунта',
        BotName: 'Имя бота',
        Character: 'буква',
        Number: 'число',
        IdExample: 'Пример ID',
        XCharecter: 'X-символ',
        YNumber: 'Y-число',
        ThisOperationCanTakeLongTime: 'Операция может занять долгое время!',
        ClickTiSaveExcel: 'Кликните чтобы сохранить в Excel',
        PrivacyPolicyText: 'Текст политики приватности',
        PrivacyPolicyLink: 'Ссылка политики приватности',
        PrivacyPolicyLinkText: 'Текст ссылки политики приватности',
        AgentPhone: 'Телефон агента',
        HeaderTextTop: 'Текст верхней части шапки',
        HeaderTextBottom: 'Текст нижней части шапки',
        LogoIconUrl: 'URL иконки логотипа',
        IconUrl: 'URL иконки',
        ChatWindowsLogo: 'Логотип окна чата',
        GreetingIconBotUrl: 'URL иконки приветствия бота',
        AgentMessageIconUrl: 'URL иконки сообщения агента',
        WriteMessagesPlaceholder: 'Прототип поля написания сообщения',
        SendImageUrl: 'URL картинки отправки',
        ChannelURL: 'URL канала',
    },
    Selects: {
        SelectAccessLevel: 'Выберите уровень доступа*',
        SelectChannel: 'Выберите канал*',
        SelectChannels: 'Выберите каналы',
        SelectWhiteTagsList: 'Выберите доступние теги*',
        SelectAgents: 'Выберите агентов',
        SelectBrand: 'Выберите бренд',
        SelectGroups: 'Выберите группы',
        SelectTags: 'Выберите теги',
        ChooseProviderCountry: 'Выберите страну провайдера',
        SelectMessengers: 'Выберите мессенджеры',
        SelectTagStatus: 'Выберите статус тега',
        SelectAgent: 'Выберите агента',
        SelectEnabledLanguages: 'Выберите доступный язык',
        ChannelName: 'Имя канала',
        ChannelURL: 'URL канала',
        SelectLanguageWebChat: 'Выберите язык для Web Chat',
    },
    Labels: {
        MoreIconUrl: 'URL больше иконки',
        MinimizeIconUrl: 'URL минимизации иконки',
        SendFileUrl: 'URL отправки файла',
        NumberOfNewAgents: 'Количество новых агентов',
        TextForBulkMessaging: 'Текст для массовой рассылки',
        Name: 'Имя',
        LastName: 'Фамилия',
        Phone: 'Телефон',
        GroupName: 'Название группы*',
        SelectBrand: 'Выберите бренд',
        Channel: 'Канал*',
        Description: 'Описание*',
        AccessLevel: 'Уровень доступа*',
        WhiteTagsList: 'Список доступних тегов*',
        BrandName: 'Имя бренда',
        CategoryName: 'Имя категории*',
        ChooseProviderCountry: 'Выберите страну провайдера',
        AccessKey: 'Ключ доступа',
        SelectChannels: 'Выберите каналы',
        SelectGroups: 'Выберите группы',
        SelectAgents: 'Выберите агентов',
        SelectTags: 'Выберите теги',
        SelectAccessLevel: 'Выберите уровень доступа*',
        Password: 'Пароль',
        Login: 'Вход',
        SelectMessengers: 'Выберите мессенджери',
        UserName: 'Имя пользователя',
        ClientName: 'Имя пользователя клиента',
        ClientFirstName: 'Имя клиента',
        ClientLastName: 'Фамилия клиента',
        ClientPhone: 'Телефон клиента',
        ClientEmail: 'Электронная почта клиента',
        ClientInfo: 'Клиентская информация',
        UpdateGroup: 'Изменение группы',
        Agents: 'Агенты',
        UsefulPhrases: 'Полезные фразы',
        Channels: 'Каналы',
        Tags: 'Теги',
        AddNewPhrases: 'Добавить новые фразы',
        Phrase: 'Полезная фраза',
        SelectCategory: 'Выберите категорию',
        EditCategory: 'Редактирование категории',
        EditPhrase: 'Редактирование фразы',
        Or: 'или',
        AddNewCategory: 'Добавить новую категорию',
        Dialogs: 'Диалоги',
        TotalDuration: 'Общая продолжительность',
        Email: 'Электронная почта',
        ClientId: 'Клиентская ID',
        ClientBanType: 'Тип бана клиента',
        ClientBanTime: 'Срок бана клиента',
        Messengers: 'Мессенджеры',
        IpAddress: 'IP адрес',
        ExternalID: 'Внешний ID',
        UpdateBrand: 'Изменить бренд',
        CreateMailInbox: 'Создать аккаунт электронной почты',
        CreateTelegramInbox: 'Создать аккаунт Telegram',
        CreateSmsInbox: 'Создать SMS аккаунт',
        MailInbox: 'Электронная почта',
        TelegramInbox: 'Аккаунт Telegram',
        SmsInbox: 'Аккаунт SMS',
        ApplicationID: 'ID приложения',
        ApplicationToken: 'Токен приложения',
        SenderID: 'ID отправителя',
        ChangeName: 'Изменить имя',
        ChangeURL: 'Изменить URL',
        TagName: 'Название тега',
        SelectTagStatus: 'Выбрать статус тега',
        SelectAgent: 'Выберите агента',
        UpdateTag: 'Изменить тег',
        CreateTag: 'Создать тег',
        UsefulLinks: 'Полезные ссылки',
        LinkAlias: 'Название ссылки',
        LinkUrl: 'URL-адрес ссылки',
        SelectEnabledLanguages: 'Выберите доступный язык',
        Greetings: 'Приветвствие',
        FastPhrase: 'Быстрая фраза №',
        ClientHistory: 'История клиента',
        ChooseRequiredFields: 'Пожалуйста,выберите обязательные поля',
        GetAllDialogs: 'Получить все диалоги',
        GetCurrentDialog: 'Получить текущий диалог',
        SaveInLocalStorage: 'Сохранить в LocalStorage',
        SaveInSessionStorage: 'Сохранить в SessionStorage',
        InvoiceNumber: 'Номер счёта',
        AccountNumber: 'Номер аккаунта',
        General: 'Основные',
        Authorization: 'Авторизация',
        Languages: 'Языки',
        New: 'Новый',
        Active: 'Активный',
        Inactive: 'Не активный',
        WaitForActivation: 'Ожидающий активацию',
        ID: 'ID',
        SMS: 'СМС',
        Low: 'Низкий',
        Medium: 'Средний',
        High: 'Высокий',
        Hidden: 'Скрытый',
        Brand: 'Бренд',
        Group: 'Группа',
        CreateGroups: 'Создать группу',
        Roles: 'Роли',
        ChangeStatus: 'Изменить статус',
        AuthMail: 'Авторизация почты',
        SetDomainSettings: 'Установить настройки домена',
        Domain: 'Домен*',
        Imap: 'Imap (хост:порт)*',
        SmtpTLS: 'Smtp TLS (хост:порт)*',
        SmtpSSL: 'Smtp SSL (хост:порт)*',
        AttachFile: 'Прикрепить файл',
        Groups: 'Группы',
        TeamLead: 'Глава команды',
        Ban: 'Бан',
        ChannelSettings: 'Настройки канала',
        GeneralSettings: 'Основные настройки',
        WebChatConstructor: 'Web Chat конструктор',
        BackgroundColor: 'Цвет фона',
        MessageColor: 'Цвет сообщения',
        URL: 'URL',
        ChannelName: 'Название канала',
        ChannelURL: 'URL канала',
        AllowSMS: 'Разрешить SMS',
        AllowShowAllClientDialogs: 'Разрешить показывать все диалоги клиента',
        AllowCaptcha: 'Разрешить Captcha',
        InboxSettings: 'Настройка раздела входящих',
        DistributionOfNewDialogs: 'Распределение новых даилогов между агентами',
        ThereAreNoAvailableMessengers: 'Нет дсотупных мессенджеров для канала!Пожалуйста,создайте их для бренда,которому принадлежит канал!',
        HeaderColorScheme: 'Схема цвета верхней части',
        GreetingColorScheme: 'Схема цвета приветствия',
        QuickPhrases: 'Схема цветов быстрых фраз',
        ClientMessage: 'Схема цвета сообщения клиента',
        AgentMessage: 'Схема цвета сообщения агента',
        EndDialogModal: 'Схема цвета модального окна для конца диалога',
        IconColorScheme: 'Схема цвета иконки',
        ScrollColorScheme: 'Схема цвета скролла',
        ChatWindowColor: 'Схема цветов окна чата',
        FooterColorScheme: 'Схема цвета нижний части',
        AuthColorScheme: 'Схема цвета аутентификации',
        HeaderBackground: 'Задний фон верхней части',
        HeaderBorderColor: 'Цвет границ верхней части',
        ColorGreetingBotName: 'Цвет имени бота приветствия',
        MessageGreetingIcon: 'Задний фон иконки приветствия',
        MessageGreetingColor: 'Цвет текста приветствия',
        MessageGreetingBorderColor: 'Цвет границ для сообщения приветствия',
        MessageGreetingBackgroundColor: 'Цвет заднего фона у сообщения приветствия',
        QuickPhrasesColor: 'Цвет текста быстрых фраз',
        QuickPhrasesBackground: 'Задний фон быстрых фраз',
        QuickPhrasesBorderColor: 'Цвет границ быстрых фраз',
        QuickPhrasesColorHover: 'Цвет при наведении на быстрые фразы',
        QuickPhrasesBackgroundHover: 'Цвет заднего фона при наведении на быстрые фразы',
        QuickPhrasesBorderColorHover: 'Цвет границ при наведении на быстрые фразы',
        ClientMessageColor: 'Цвет сообщения клиента',
        ClientTimeColor: 'Цвет времени клиента',
        ClientMessageBackground: 'Цвет заднего фона у сообщения клиента',
        ClientMessageBorderColor: 'Цвет границ у сообщения клиента',
        ClientLink: 'Ссылка клиента',
        ClientLinkHover: 'Цвет при наведении на ссылку клиента',
        ClientActiveLink: 'Активная ссылка клиента',
        ClientVisitedLink: 'Посещённая ссылка клиента',
        ColorAgentNameAndTime: 'Цвет имени и времени агента',
        MessageAgentColor: 'Цвет сообщений агента',
        MessageAgentBackgroundColor: 'Задний фон сообщения агента',
        MessageAgentBorderColor: 'Цвет границ сообщения агента',
        AgentLink: 'Ссылка агента',
        AgentHoverLink: 'Цвеет при наведении на ссылку агента',
        AgentActiveLink: 'Активная ссылка агента',
        AgentVisitedLink: 'Посещённая ссылка агента',
        MessageIconBackgroundAgent: 'Задний фон иконки сообщения агента',
        TextColor: 'Цвет текста',
        BackdropColor: 'Цвет фон',
        BackdropColorHover: 'Цвет наведения наведения на фон',
        ButtonColor: 'Цвет кнопки',
        BackdropColorBtn: 'Цвет фона задний части кнопки',
        IconBackground: 'Задний фон иконки',
        ChatWindowsBodyBackground: 'Цвет заднего фона тела окон чата',
        ChatWindowsBorderColor: 'Цвет границ окон чата',
        FooterBackground: 'Цвет заднего фона нижний части',
        FooterBorderColor: 'Цвет границ нижний части',
        FooterBackgroundTextArea: 'Цвет заднего фона тесктовой области нижней части',
        InputBorderColor: 'Цвет границ ввода',
        RegistrationBackgroundColor: 'Цвет заднего фона регистрации',
        LabelColor: 'Цвет ярлыка',
        LabelErrorColor: 'Цвет ярлыка ошибки',
        InputColor: 'Цвет ввода',
        InputBackground: 'Задний фон ввода',
        SignUpColor: 'Цвет входа',
        SignUpBackground: 'Цвет заднего фона входа',
        Chat: 'Чат',
        Minimized: 'Минимизация',
        ScrollBackground: 'Задний фон скролла',
        ScrollThumbBackground: 'Задний фон бегунка скролла',
        UniqueClientIdentifier: 'Уникальный индификатор клиента',
        AgentsSettings: 'Настройки агента',
        ThisAgentSuperAdmin: 'Этот агент является суперадминистратором, нажмите кнопку, чтобы удалить права суперадминистратора',
        ThisAgentNotSuperAdmin: 'Этот агент не является суперадминистратором, нажмите кнопку, чтобы сделать его суперадминистратором',
        YouCanAssignBrandTheBrand: 'Вы можете назначить бренд админа для этого вам нужно выбрать бренд',
        Role: 'Роль',
        BanningThisAgent: 'Запрет этого агента означает, что он не сможет войти на платформу. Они по-прежнему будут отображаться в системе.',
        Report: 'Отчёт',
        SelectLanguageWebChat: 'Выберите язык для Web Chat',
        FirstName: 'Имя',
        PhoneNumber: 'Номер телефона',
        Info: 'Инфо',
        WebChatSettings: 'Настройки Web Chat',
        IntegrationWithOtherCRM: 'Интеграция с другими CRM',
        SettingUpInteractionBetweenSystems: 'Настройка взаимодействия между системами',
        QuickReplies: 'Быстрые ответы',
        BotSettings: 'Настройки бота',
        BotName: 'Имя бота',
        EnabledPrivacyPolicy: 'Разрешить политику конфиденциальности',
        PleaseChooseRequiredFields: 'Пожалуйста,выберите обязательные поля',
        ChangeGreetingsAndPhrases: 'Изменить приветствие, фразы и политику конфидециальности',
        ChangeSmsSettings: 'Изменить настройки СМС',
        ChangeCountries: 'Изменить страны',
        ContactsAreWorkingWithClientUniqueness: 'Контакты зависят от уникальности клиента.Поле обязательно в случае,если уникальность совпадает с ним.',
        ClientUniquenessIs: 'Уникальность клиента это:',
        PrivacyPolicyText: 'Текст политики приватности',
        PrivacyPolicyLink: 'Ссылка политики приватности',
        PrivacyPolicyLinkText: 'Текст ссылки политики приватности',
        AgentPhone: 'Телефон агента',
        HeaderSettings: 'Настройки заголовка',
        DisableDraggable: 'Отключить перетаскивание',
        DisableHeaderFullScreen: 'Отключить полноэкранный заголовок',
        DisableHeaderHideChatWindow: 'Отключить скрытие окна чата в заголовке',
        DisableHeaderLogo: 'Отключить логотип заголовка',
        DisableHeaderShowEndDialogModal: 'Отключить отображение заголовка в конце модального окна',
        HeaderText: 'Текст заголовка',
        HeaderTextTop: 'Текст верхней части заголовка',
        HeaderTextBottom: 'Текст нижней части заголовка',
        HeaderHeight: 'Высота заголовка',
        LogoIconUrl: 'URL иконки логотипа',
        IconUrl: 'URL иконки',
        IconSettings: 'Настройки иконки',
        ScrollSettings: 'Настройки скролла',
        ScrollWidth: 'Ширина скролла',
        ChatWindowsLogo: 'Лого окна чата',
        BackgroundImageSize: 'Размер картинки заднего фона',
        GreetingSettings: 'Настройки приветсвия',
        GreetingIconBotUrl: 'URL иконки приветствия бота',
        QuickPhrasesSettings: 'Настройки быстрых фраз',
        ClientMessageSettings: 'Настройки сообщений клиента',
        AgentMessageSettings: 'Настройки сообщений агента',
        AgentMessageIconUrl: 'URL иконки сообщения агента',
        EndDialogModalSettings: 'Натсройки модального окна окончания диалога',
        AuthWindowSettings: 'Настройки окна аутентификации',
        FooterSettings: 'Настройки нижнего колонтитула',
        WriteMessagesPlaceholder: 'Прототип поля написания сообщения',
        SendImageUrl: 'URL картинки отправки',
        SendButtonIconHeight: 'Высота иконки кнопки отправки',
        SendButtonIconWidth: 'Ширина значка кнопки отправки',
        ChatWindowSettings: 'Настройки окна чата',
        ClearAllPhrase: 'Очистить все фразы',
        AreYouSure: 'Вы уверены?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Это действие удалит все фразы и все категории',
        AddAgentToGroup: 'Добавить агентов в группу',
        CreateClient: 'Создать клиента',
        ClientsImport: 'Импорт клиентов',
        UploadExcelFile: 'Загрузить файл Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Нажмите на значок скрепки, чтобы выбрать файл Excel.',
        Contacts: 'Контакты',
        Personal: 'Персоналные',
        FileUploadHint: 'Пожалуйста, загрузите файл Excel, заполненный телефонными номерами. Файл Excel должен содержать столбец с именем «Телефоны», ' +
            'и номера телефонов должны быть введены в этот столбец. Можно импортировать только файлы .xls или .xlsx.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Изменить приветствие и бистрые фразы, Политика конфиденциальности',
    },
    AdditionalText: {
        AgentProfile: 'Профиль Агента',
        OutOf: 'из',
    },
    Pages: {
        Dialogs: {
            Title: 'Диалоги',
            TableColumns: {
                Status: 'Статус',
                Dialog: 'Диалог',
                Messenger: 'Мессенджер',
                Channel: 'Канал',
                Tag: 'Тег',
                Agent: 'Агент',
                Rating: 'Рейтинг',
                Language: 'Язык',
                MessagesCount: 'Количество сообщений',
                FirstMessage: 'Первое сообщение',
                LastActivity: 'Последняя посещаемость',
            },
            Messages: 'Сообщения',
        },
        AgentGroups: {
            Title: 'Группы агентов',
            TableColumns: {
                Groups: 'Группы',
                Description: 'Описание',
                AccessLevel: 'Уровень доступа',
                WhiteListTags: 'Доступные теги',
                CreatedAt: 'Созданно в',
                UpdatedAt: 'Обновленно в',
            },
        },
        Agents: {
            Title: 'Агенты',
            TableColumns: {
                AgentStatus: 'Статус агента',
                Agents: 'Агенты',
                Email: 'Почта',
                Groups: 'Группы',
                CreatedAt: 'Созданно в',
                LastActivity: 'Последняя посещаемость',
            },
        },
        Tags: {
            Title: 'Теги',
            TableColumns: {
                Tags: 'Теги',
                Dialogs: 'Диалоги',
                Agents: 'Агенты',
                UsefulLinks: 'Полезные фразы',
                Status: 'Статус',
                CreatedAt: 'Созданно в',
                UpdatedAt: 'Обновленно в',
            },
        },
        Clients: {
            Title: 'Клиенты',
            TableColumns: {
                SearchName: 'имя',
                AgentName: 'Имя Агента',
                Messenger: 'Мессенджер',
                LastTag: 'Последний тег',
                ActiveDialog: 'Наличие активного диалога',
                Brand: 'Бренд',
                InternalId: 'Внутренний id',
                ExternalId: 'Внешний id',
                Name: 'Имя пользователя',
                FirstName: 'Имя клиента',
                LastName: 'Фамилия клиента',
                Email: 'Почта',
                InvoiceNumber: 'Номер счета',
                DialogsCount: 'Кол-во диалогов',
                FirstMessage: 'Первое сообщение',
                LastActivity: 'Последняя посещаемость',
                Tag: 'Тег',
                Status: 'Статус',
                Channel: 'Канал',
                WriteEmail: 'почту',
            },
        },
        Channels: {
            Title: 'Каналы',
            TableColumns: {
                ChannelId: 'Id',
                ChannelKey: 'Ключ',
                ChannelName: 'Канал',
                Language: 'Язык',
                Url: 'Ссылка',
                CreatedAt: 'Созданно в',
                UpdatedAt: 'Обновленно в',
            },
        },
        Brands: {
            Title: 'Бренды',
            TableColumns: {
                BrandsId: 'id бренда',
                BrandName: 'Имя бренда',
                ClientUniqueness: 'Уникальный ключ клиента',
                BrandChannels: 'Каналы бренда',
                DialogCount: 'Количество диалогов',
                CreatedAt: 'Созданно в',
                UpdatedAt: 'Обновленно в',
            },
        },
        Reports: {
            Title: 'Отчеты',
            ByMessengersTab: {
                Title: 'Отчет по мессенджерам',
                Columns: {
                    Messenger: 'Мессенджер',
                    ReceivedDialogs: 'Полученные диалоги',
                    PositiveDialogs: 'Положительные диалоги, %',
                    NegativeDialogs: 'Отрицательные диалоги, %',
                    UnratedDialogs: 'Диалоги без рейтинга, %',
                    AvgResponseTime: 'Среднее время ответа',
                },
            },
            ByAgentsTab: {
                Title: 'Отчет по  агентам',
                Columns: {
                    agentFullName: 'ФИО агента',
                    agentSystemId: 'ID системы агента',
                    brandName: 'Имя бренда',
                    receivedDialogs: 'Полученные диалоги, %',
                    dialogsTelegram: 'Диалоги Telegram',
                    dialogsWebChat: 'Диалоги Web Chat',
                    dialogsViber: 'Диалоги Viber',
                    dialogsWhatsApp: 'Диалоги WhatsApp',
                    dialogsFacebook: 'Диалоги Facebook',
                    dialogsEmail: 'Диалоги Email',
                    dialogsSms: 'Диалоги SMS',
                    positiveDialogs: 'Положительные диалоги, %',
                    negativeDialogs: 'Отрицательные диалоги, %',
                    unratedDialogs: 'Диалоги без рейтинга, %',
                    avgResponseTime: 'Среднее время ответа',
                },
                noDataForThisDay: 'Нет данных за этот день',
            },
            ByGroupsTab: {
                Title: 'Отчет по группам',
                Columns: {
                    groupSystemId: 'Системный идентификатор',
                    groupName: 'Имя группы',
                    brandName: 'Имя бренда',
                    receivedDialogs: 'Полученные диалоги, %',
                    positiveDialogs: 'Положительные диалоги, %',
                    negativeDialogs: 'Отрицательные диалоги, %',
                    unratedDialogs: 'Диалоги без рейтинга, %',
                    avgResponseTime: 'Среднее время ответа',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Фильтр',
        reset: 'Сброс',
        createClient: 'Создать клиента',
        importClient: 'Импортировать клиентов',
        createChannel: 'Создать канал',
        next: 'Следующий',
        previous: 'Предыдущий',
        addPhrase: 'Добавить фразу',
        createBrand: 'Создать бренд',
        addTag: 'Создать тег',
        addGroup: 'Создать группу',
        save: 'Сохранить',
        send: 'Отправить',
        getStatistics: 'Получить статистику',
        setCountries: 'Выбрать страны',
        changeStatus: 'Изменить статус',
        upload: 'Загрузить',
        setDomainSettings: 'Установить настройки домена',
        login: 'Вход',
        update: 'Обновить',
        resetPassword: 'Сбростить пароль',
        addNew: 'Добавить',
        addNewPhrase: 'Добавить новую фразу',
        addNewCategory: 'Добавить новую категорию',
        clearAll: 'Очистить Всё',
        loginWithGoogle: 'Войти через Google',
        loginWithFacebook: 'Войти через Facebook',
        onPage: 'на странице',
        addNewUsefulLinks: 'Добавить полезные ссылки',
        backToChannels: 'Вернуться к каналам',
        defaultSettings: 'Сбросить настройки',
        upgradeChannel: 'Обновить канал',
        apply: 'Принять',
        remove: 'Убрать',
        removeSuperAdmin: 'Удалить суперадминистратора',
        makeSuperAdmin: 'Сделать суперадминистратором',
        makeBrandAdmin: 'Сделать администратором бренда',
        removeBrandAdmin: 'Удалить администратора бренда',
        ban: 'Забанить',
        unban: 'Разбанить',
        makeTeamLead: 'Сделать тимлидом',
        removeTeamLead: 'Удалить тимлида',
        signUp: 'Войти',
        ok: 'Ок',
        cancel: 'Отменить',
        bulkMessaging: 'Массовая рассылка сообщений',
        back: 'Назад',
    },
    Countries: {
        UnitedStates: 'Соединённые Штаты Америки',
        Canada: 'Канада',
        Australia: 'Австралия',
        CzechRepublic: 'Чехия',
        Denmark: 'Дания',
        Hungary: 'Венгрия',
        Netherlands: 'Нидерланды',
        Sweden: 'Швеция',
        UnitedKingdom: 'Великобритания',
        France: 'Франция',
        Germany: 'Германия',
        Poland: 'Польша',
        Italy: 'Италия',
        Spain: 'Испания',
    },
    Languages: {
        Ukrainian: 'Украинский',
        Russian: 'Русский',
        English: 'Английский',
        Polish: 'Польский',
        Deutsch: 'Немецкий',
        Spanish: 'Испанский',
        Vietnamese: 'Вьетнамский',
        Portuguese: 'Португальский',
        Korean: 'Корейский',
        Italian: 'Итальянский',
        Arabic: 'Арабский',
        French: 'Французкий',
        Azerbaijan: 'Азербайджанский',
        Hungarian: 'Венгерский',
    },
    BulkMessaging: {
        exclude: 'Исключить',
        typeText: 'Введите текст',
        selectClients: 'Выберите клиентов',
        uploadClients: 'Загрузите клиентов',
        sendingMessagesToNewClients: 'Рассылка сообщений новым клиентам',
        sendingMessagesToExistingClients: 'Отправка сообщений существующим клиентам',
        clientsWhoHaveDialogsWithFollowingStatus: 'Клиенты, у которых есть диалоги со следующим статусом',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Клиенты, у которых есть этот текст в имени или фамилии или имени пользователя',
        limitQuantity: 'Ограничить количество',
        quantityLimit: 'Лимит количества',
        limitQuantityText: 'Ограничьте количество в трансляции до указанного количества получателей. В список попадут новые клиенты системы.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Клиенты, которые писали в AskMe до времени последнего сообщения',
    },
};
export default Messages;
