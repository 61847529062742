var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isEqual } from 'lodash';
export var transformRoles = function (agentRoles) {
    var tempArr = [];
    agentRoles.forEach(function (el) {
        if (el.brandId) {
            var filterByBrand = agentRoles.filter(function (elem) { return elem.brandId === el.brandId; });
            var isPush = void 0;
            for (var i = 0; tempArr.length > i; i++) {
                isPush = isEqual(tempArr[i], filterByBrand);
            }
            if (!isPush) {
                var uniqueByBrand = filterByBrand.reduce(function (acc, role) { return ({
                    agentId: role.agentId,
                    id: role.id,
                    brandId: role.brandId,
                    groupId: __spreadArray(__spreadArray([], __read(acc.groupId), false), [role.groupId], false),
                    role: __spreadArray(__spreadArray([], __read(acc.role), false), [role.role], false),
                }); }, {
                    groupId: [],
                    role: [],
                });
                tempArr.push(uniqueByBrand);
            }
        }
        else {
            tempArr.push(__assign(__assign({}, el), { role: [el.role], groupId: [el.groupId] }));
        }
    });
    var roles = tempArr.reduce(function (acc, role) {
        if (acc.map[role.brandId])
            return acc;
        acc.map[role.brandId] = true;
        acc.roles.push(role);
        return acc;
    }, {
        map: {},
        roles: [],
    }).roles;
    return roles;
};
