import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { clientsActions } from '@src/modules/Pages/Clients/redux/reducer';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import BulkMessaging from '@src/modules/Pages/Clients/BulkMessaging/BulkMessaging';
var mapStateToProps = function (state) { return ({
    brandDictionary: dictionariesStore.dictionaries(state).brandDictionary,
    isLoadingImportPhones: loadingStore.getIsLoadingImportPhones(state),
    isLoadingSelectClientsBulkMessaging: loadingStore.getIsLoadingSelectClientsBulkMessaging(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.BULK_MESSAGING, null)); },
    selectClients: function (data) { return dispatch(clientsActions.selectClients(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    importPhoneForBulkMessaging: function (data) { return dispatch(clientsActions.importPhoneForBulkMessaging(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(BulkMessaging);
