var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledUpdateMailInboxWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  max-height: 55vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  max-height: 55vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledCheckboxWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    padding: 8px;\n    flex-wrap: wrap;\n    align-items: baseline;\n  }\n"], ["\n  && {\n    display: flex;\n    padding: 8px;\n    flex-wrap: wrap;\n    align-items: baseline;\n  }\n"])));
export var StyledUpdateMailInboxBtnWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: flex-end;\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: flex-end;\n  }\n"])));
export var StyledUpdateMailNameWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    text-align: center;\n    font-size: 15px;\n    padding: 5px;\n    margin: 5px 0;\n    color: ", ";\n  }\n"], ["\n  && {\n    text-align: center;\n    font-size: 15px;\n    padding: 5px;\n    margin: 5px 0;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
