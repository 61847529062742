var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var useCallbackUseful = function () {
    var setUsefulLinksElem = function (el, state, setState, toggleModal, setIsOpenTwoModal) {
        state.push(el);
        setState(state);
        toggleModal(null);
        setIsOpenTwoModal(false);
    };
    var editUsefulLinks = function (el, state, setState, toggleModal, setIsOpenTwoModal, index) {
        state.splice(index, 1, el);
        setState(__spreadArray([], __read(state), false));
        toggleModal(null);
        setIsOpenTwoModal(false);
    };
    var deleteUsefulLinks = function (index, state, setState) {
        state.splice(index, 1);
        setState(__spreadArray([], __read(state), false));
    };
    var clickEdit = function (index, setIsOpenTwoModal, state, toggleModal) {
        setIsOpenTwoModal(true);
        toggleModal(__assign(__assign({}, state[index]), { isEdit: true, indexLink: index }) || null);
    };
    return {
        clickEdit: clickEdit,
        editUsefulLinks: editUsefulLinks,
        deleteUsefulLinks: deleteUsefulLinks,
        setUsefulLinksElem: setUsefulLinksElem,
    };
};
