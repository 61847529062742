import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import GroupsTags from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsTags/GroupsTags';
var mapStateToProps = function (state) { return ({
    assignedTagsToGroup: groupsStore.getAssignedTagsToGroup(state),
    unassignedTagsToGroup: groupsStore.getUnassignedTagsToGroup(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    removeOrAddTagsFromGroup: function (data) { return dispatch(groupsActions.removeOrAddTagsFromGroup(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GroupsTags);
