import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var getCurrentAgent = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Agent?id=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var banAgent = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Agent/agents/ban/byAgentId?agentId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .put()
    .json(); };
var unBanAgent = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Agent/agents/unban/byAgentId?agentId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .put()
    .json(); };
var getAssignAndUnassignedTagsByAgentId = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Tag/tags/agent/identifyname?agentId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var addTagsToAgent = function (_a) {
    var id = _a.id, tags = _a.tags;
    return wretch("".concat(apiConfig.baseUrl, "/Agent/assignTags?agentId=").concat(id))
        .auth("Bearer ".concat(getAuthCookie()))
        .post(tags)
        .json();
};
var removeAgentTags = function (_a) {
    var id = _a.id, tags = _a.tags;
    return wretch("".concat(apiConfig.baseUrl, "/Agent/unassignTags?agentId=").concat(id))
        .auth("Bearer ".concat(getAuthCookie()))
        .json(tags)
        .delete();
};
var getAssignAndUnassignedGroupsByAgentId = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Group/groups/subscriber/byAgentId?agentId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var addAgentToGroups = function (_a) {
    var id = _a.id, group = _a.group;
    return wretch("".concat(apiConfig.baseUrl, "/Group/groups/agent?agentId=").concat(id))
        .auth("Bearer ".concat(getAuthCookie()))
        .post(group);
};
var removeAgentFromGroups = function (_a) {
    var id = _a.id, group = _a.group;
    return wretch("".concat(apiConfig.baseUrl, "/Group/groups/agent?agentId=").concat(id))
        .auth("Bearer ".concat(getAuthCookie()))
        .json(group)
        .delete();
};
var getGroupsAgent = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Group/groups/byAgentId?agentId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var makeAgentTeamLead = function (agentId, groupId) { return wretch("".concat(apiConfig.baseUrl, "/Group/lead?groupId=").concat(groupId))
    .auth("Bearer ".concat(getAuthCookie()))
    .post([agentId])
    .json(); };
var removeAgentTeamLead = function (agentId, groupId) { return wretch("".concat(apiConfig.baseUrl, "/Group/lead?groupId=").concat(groupId))
    .auth("Bearer ".concat(getAuthCookie()))
    .json([agentId])
    .delete()
    .json(); };
var getAgentRoles = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Role?agentId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var subscribeAgentSuperAdmin = function (agent) { return wretch("".concat(apiConfig.baseUrl, "/Agent/superadmin"))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(agent)
    .json(); };
var unSubscribeAgentSuperAdmin = function (agent) { return wretch("".concat(apiConfig.baseUrl, "/Agent/superadmin"))
    .auth("Bearer ".concat(getAuthCookie()))
    .json(agent)
    .delete()
    .json(); };
var subscribeAgentBrandAdmin = function (agent, brandId) { return wretch("".concat(apiConfig.baseUrl, "/Agent/brandadmin?brandId=").concat(brandId))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(agent)
    .json(); };
var unSubscribeAgentBrandAdmin = function (agent, brandId) { return wretch("".concat(apiConfig.baseUrl, "/Agent/brandadmin?brandId=").concat(brandId))
    .auth("Bearer ".concat(getAuthCookie()))
    .json(agent)
    .delete()
    .json(); };
var changeAgentInfo = function (agent) { return wretch("".concat(apiConfig.baseUrl, "/Agent"))
    .auth("Bearer ".concat(getAuthCookie()))
    .json(agent)
    .put()
    .json(); };
var getCountAgentsNewBees = function () { return wretch("".concat(apiConfig.baseUrl, "/Agent/admin/newbees/count"))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
export var agentsApi = {
    banAgent: banAgent,
    unBanAgent: unBanAgent,
    getAgentRoles: getAgentRoles,
    addTagsToAgent: addTagsToAgent,
    getGroupsAgent: getGroupsAgent,
    getCurrentAgent: getCurrentAgent,
    removeAgentTags: removeAgentTags,
    changeAgentInfo: changeAgentInfo,
    addAgentToGroups: addAgentToGroups,
    makeAgentTeamLead: makeAgentTeamLead,
    removeAgentTeamLead: removeAgentTeamLead,
    getCountAgentsNewBees: getCountAgentsNewBees,
    removeAgentFromGroups: removeAgentFromGroups,
    subscribeAgentSuperAdmin: subscribeAgentSuperAdmin,
    subscribeAgentBrandAdmin: subscribeAgentBrandAdmin,
    unSubscribeAgentBrandAdmin: unSubscribeAgentBrandAdmin,
    unSubscribeAgentSuperAdmin: unSubscribeAgentSuperAdmin,
    getAssignAndUnassignedTagsByAgentId: getAssignAndUnassignedTagsByAgentId,
    getAssignAndUnassignedGroupsByAgentId: getAssignAndUnassignedGroupsByAgentId,
};
