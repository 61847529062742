import React from 'react';
import { Field, Form } from 'react-final-form';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import ModalWindow from '@src/components/ModalWindow';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired, isSpaces, lengthCreator, } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledBrandInput } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import { StyledCreateTelegramInboxWrapper } from '@src/modules/Pages/Brands/BrandSettings/CreateTelegramInbox/styled';
import { Messages } from '@public/locales/messages';
var CreateTelegramInbox = function (props) {
    var toggleModal = props.toggleModal, showNotification = props.showNotification, setIsOpenTwoModal = props.setIsOpenTwoModal, createTelegramInbox = props.createTelegramInbox, isLoadingCreateInbox = props.isLoadingCreateInbox, resetNotificationState = props.resetNotificationState;
    var minTelegramIdLength = lengthCreator.minTelegramIdLength, maxTelegramIdLength = lengthCreator.maxTelegramIdLength;
    var closeModal = function () {
        toggleModal();
        setIsOpenTwoModal(false);
    };
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onSubmit = function (data) {
        setIsOpenTwoModal(false);
        createTelegramInbox(data.telegramInbox);
    };
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: Messages.Labels.CreateTelegramInbox },
        React.createElement(StyledCreateTelegramInboxWrapper, null, isLoadingCreateInbox ? (React.createElement(Loader, null)) : (React.createElement(React.Fragment, null,
            React.createElement("ul", { style: { marginBottom: '10px', listStyleType: 'none' } },
                React.createElement("li", null,
                    Messages.Controls.IdExample,
                    " : XXYYXXXY-XYYY-YXYY-XYXY-XYXYYXXYXYYY"),
                React.createElement("li", null, Messages.Controls.XCharecter),
                React.createElement("li", null, Messages.Controls.YNumber)),
            React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(Field, { name: "telegramInbox", label: Messages.Labels.TelegramInbox, showWarning: showWarning, validate: composeValidators(isSpaces, isRequired, minTelegramIdLength, maxTelegramIdLength), component: BrandInput, placeholder: Messages.Controls.TelegramInbox }),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit })))));
                } }))))));
};
export default CreateTelegramInbox;
