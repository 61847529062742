var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles } from '@src/app/styled';
export var TableWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  overflow-x: auto;\n  ", "\n  &::-webkit-scrollbar-track {\n    margin-right: 0;\n  }\n"], ["\n  width: 100%;\n  overflow-x: auto;\n  ", "\n  &::-webkit-scrollbar-track {\n    margin-right: 0;\n  }\n"])), function () { return scrollStyles; });
export var TableWrapperInner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-width: max-content;\n  width: 100%;\n  color: ", ";\n"], ["\n  min-width: max-content;\n  width: 100%;\n  color: ", ";\n"])), function (_a) {
    var textColor = _a.textColor, theme = _a.theme;
    return textColor || theme.colors.text;
});
var templateObject_1, templateObject_2;
