import { connect } from 'react-redux';
import UpdateMailInbox from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox/UpdateMailInbox';
import { brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import { loadingStore } from '@src/redux/loading/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { notificationActions } from '@src/redux/notification/reducer';
var mapStateToProps = function (state) { return ({
    mailInbox: modalWindowsStore.getModalInfo(state, modalTypes.UPDATE_MAIL_INBOX),
    isLoadingCreateInbox: loadingStore.getIsLoadingCreateInbox(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    authMail: function (data) { return dispatch(brandsActions.authMail(data)); },
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_MAIL_INBOX, null)); },
    uploadMailAuth: function (data) { return dispatch(brandsActions.uploadMailAuth(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    updateEmailInbox: function (data) { return dispatch(brandsActions.updateInbox(data)); },
    setDomainSettings: function (data) { return dispatch(brandsActions.setDomainSettings(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateMailInbox);
