var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
export var TableRowWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var TableRowInner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  padding-left: 56px;\n  padding-right: 10px;\n  margin-top: 4px;\n  height: 38px;\n  display: grid;\n  align-items: center;\n  grid-template-columns: ", ";\n  & div {\n    text-align: ", ";\n  }\n"], ["\n  background: ", ";\n  position: relative;\n  padding-left: 56px;\n  padding-right: 10px;\n  margin-top: 4px;\n  height: 38px;\n  display: grid;\n  align-items: center;\n  grid-template-columns: ", ";\n  & div {\n    text-align: ", ";\n  }\n"])), function (_a) {
    var rowBackground = _a.rowBackground, theme = _a.theme;
    return rowBackground || theme.colors.tableRow;
}, function (_a) {
    var rowItemsLength = _a.rowItemsLength;
    return "repeat(".concat(rowItemsLength, ", 1fr);");
}, function (_a) {
    var rowTextAlign = _a.rowTextAlign;
    return rowTextAlign || 'left';
});
export var BottomContainerWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 0 0 4px 4px;\n  margin-top: 4px;\n"], ["\n  background: ", ";\n  border-radius: 0 0 4px 4px;\n  margin-top: 4px;\n"])), function () { return COLORS.LIGHT_GREY; });
var templateObject_1, templateObject_2, templateObject_3;
