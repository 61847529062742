import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import CreateClient from '@src/modules/Pages/Clients/CreateClient/CreateClient';
var mapStateToProps = function (state) { return ({
    brandDictionary: dictionariesStore.dictionaries(state).brandDictionary,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_CLIENT, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(CreateClient);
