import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var createChannel = function (channel, brandId) {
    return wretch("".concat(apiConfig.baseUrl, "/Channel?brandId=").concat(brandId)).auth("Bearer ".concat(getAuthCookie())).post(channel).json();
};
var getChannelById = function (id) {
    return wretch("".concat(apiConfig.baseUrl, "/Channel?id=").concat(id)).auth("Bearer ".concat(getAuthCookie())).get().json();
};
var getMessengersByChannelId = function (id) {
    return wretch("".concat(apiConfig.baseUrl, "/Messenger/messengers?channelId=").concat(id)).auth("Bearer ".concat(getAuthCookie())).get().json();
};
var getMessengersByBrandId = function (id) {
    return wretch("".concat(apiConfig.baseUrl, "/Messenger/messengers?brandId=").concat(id)).auth("Bearer ".concat(getAuthCookie())).get().json();
};
var deleteMessengerFromChannel = function (_a) {
    var channelId = _a.channelId, messengerId = _a.messengerId, messenger = _a.messenger;
    return wretch("".concat(apiConfig.baseUrl, "/Messenger?channelId=").concat(channelId, "&messengerId=").concat(messengerId, "&messenger=").concat(messenger))
        .auth("Bearer ".concat(getAuthCookie()))
        .delete()
        .json();
};
var addMessengerForChannel = function (inbox) {
    return wretch("".concat(apiConfig.baseUrl, "/Messenger/channel")).auth("Bearer ".concat(getAuthCookie())).json(inbox).patch().json();
};
var addGroupToChannel = function (groups, id) {
    return wretch("".concat(apiConfig.baseUrl, "/Channel/groups?channelId=").concat(id)).auth("Bearer ".concat(getAuthCookie())).post(groups).json();
};
var deleteGroupsToChannel = function (groups, id) {
    return wretch("".concat(apiConfig.baseUrl, "/Channel/groups?channelId=").concat(id))
        .auth("Bearer ".concat(getAuthCookie()))
        .json(groups)
        .delete()
        .json();
};
var sendUpdateChannel = function (channel) {
    return wretch("".concat(apiConfig.baseUrl, "/Channel")).auth("Bearer ".concat(getAuthCookie())).json(channel).put().json();
};
var createKeyPipeDrive = function (_a) {
    var key = _a.key, channelId = _a.channelId;
    return wretch("".concat(apiConfig.baseUrl, "/Channel/pipeDrive")).auth("Bearer ".concat(getAuthCookie())).post({ channelId: channelId, key: key }).json();
};
var updateKeyPipeDrive = function (_a) {
    var key = _a.key, channelId = _a.channelId;
    return wretch("".concat(apiConfig.baseUrl, "/Channel/").concat(channelId, "/pipeDrive"))
        .auth("Bearer ".concat(getAuthCookie()))
        .json({ key: key })
        .put()
        .json();
};
var deleteKeyPipeDrive = function (channelId) {
    return wretch("".concat(apiConfig.baseUrl, "/Channel/").concat(channelId, "/pipeDrive")).auth("Bearer ".concat(getAuthCookie())).delete().json();
};
export var channelsApi = {
    createChannel: createChannel,
    getChannelById: getChannelById,
    addGroupToChannel: addGroupToChannel,
    sendUpdateChannel: sendUpdateChannel,
    createKeyPipeDrive: createKeyPipeDrive,
    updateKeyPipeDrive: updateKeyPipeDrive,
    deleteKeyPipeDrive: deleteKeyPipeDrive,
    deleteGroupsToChannel: deleteGroupsToChannel,
    addMessengerForChannel: addMessengerForChannel,
    getMessengersByBrandId: getMessengersByBrandId,
    getMessengersByChannelId: getMessengersByChannelId,
    deleteMessengerFromChannel: deleteMessengerFromChannel,
};
