var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { TAgentStatus } from '@src/global-interfaces';
export var TableContent = function (_a) {
    var columns = _a.columns, dataSource = _a.dataSource, getRowId = _a.getRowId;
    var getRowIdd = function (_a) {
        var event = _a.event, id = _a.id;
        event.stopPropagation();
        getRowId && getRowId(event, id);
    };
    return (React.createElement("div", { className: "table-content" }, dataSource.length && dataSource.map(function (elem) {
        var bannedAgent = (elem === null || elem === void 0 ? void 0 : elem.status) === TAgentStatus.banned ? 'banned-agent' : '';
        return (React.createElement("div", { key: Math.random(), onClick: function (event) { return getRowIdd({ event: event, id: elem === null || elem === void 0 ? void 0 : elem.id }); }, className: "data-row-wrapper ".concat(bannedAgent) }, columns.map(function (_a) {
            var key = _a.key, styles = _a.styles, render = _a.render, title = _a.title;
            return (React.createElement("div", { key: key, className: "table-data-cell-wrapper", style: __assign({}, styles) }, render ? render(elem[key], title) : elem[key]));
        })));
    })));
};
