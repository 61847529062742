var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
export var StyledAuthWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"], ["\n  && {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"])));
export var StyledAuthContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    width: 343px;\n    margin-bottom: 50px;\n  }\n"], ["\n  && {\n    width: 343px;\n    margin-bottom: 50px;\n  }\n"])));
export var StyledAuthIconWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    text-align: center;\n    display: flex;\n    justify-content: center;\n  }\n"], ["\n  && {\n    text-align: center;\n    display: flex;\n    justify-content: center;\n  }\n"])));
export var StyledAuthLoaderWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    margin-bottom: 40px;\n    position: relative;\n    width: 50px;\n    height: 50px;\n  }\n"], ["\n  && {\n    margin-bottom: 40px;\n    position: relative;\n    width: 50px;\n    height: 50px;\n  }\n"])));
export var StyledWelcomeMessageWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    text-align: center;\n    & .welcome-message {\n      margin-bottom: 6px;\n    }\n    & .welcome-message-signUp,\n    .welcome-message-signIn {\n      margin-bottom: 20px;\n      color: ", ";\n    }\n  }\n"], ["\n  && {\n    text-align: center;\n    & .welcome-message {\n      margin-bottom: 6px;\n    }\n    & .welcome-message-signUp,\n    .welcome-message-signIn {\n      margin-bottom: 20px;\n      color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.messageSignIn;
});
export var StyledAuthBottomMessage = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    text-align: center;\n    & p {\n      color: ", ";\n    }\n  }\n"], ["\n  && {\n    text-align: center;\n    & p {\n      color: ", ";\n    }\n  }\n"])), COLORS.BOTTOM_MSG);
export var StyledAuthInput = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    input {\n      height: 48px;\n    }\n  }\n"], ["\n  && {\n    input {\n      height: 48px;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
