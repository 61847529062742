var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from 'react';
import { getSearchItems } from '@src/components/Select/getSearchItems';
export var useSelect = function (_a) {
    var stateCB = _a.stateCB, initialState = _a.initialState, isMultiply = _a.isMultiply, items = _a.items, searchKeyName = _a.searchKeyName;
    if (!isMultiply && initialState.length)
        initialState = [initialState[0]];
    var _b = __read(useState(initialState), 2), selectedItems = _b[0], setSelectedItems = _b[1];
    var _c = __read(useState(false), 2), isOpen = _c[0], setIsOpen = _c[1];
    var _d = __read(useState(32), 2), height = _d[0], setHeight = _d[1];
    var _e = __read(useState(''), 2), searchValue = _e[0], setSearchValue = _e[1];
    var _f = __read(useState(items), 2), listItems = _f[0], setListItems = _f[1];
    var filterSearchItems = function (value) {
        if (value === '') {
            resetSearches();
            return;
        }
        setSearchValue(value);
        var filtered = getSearchItems(items, value, searchKeyName);
        setListItems(filtered);
    };
    var resetSearches = function () {
        setSearchValue('');
        setListItems(items);
    };
    var handleSetSearchValue = function (value) {
        if (value === '')
            resetSearches();
        setSearchValue(value);
    };
    var handleSetIsOpen = function () {
        if (!isOpen)
            resetSearches();
        setIsOpen(!isOpen);
    };
    var selectItem = function (item) {
        if (!isMultiply) {
            setSelectedItems([item]);
            stateCB(item);
            return;
        }
        if (!selectedItems.some(function (el) { return el.value === item.value; })) {
            var newState = __spreadArray(__spreadArray([], __read(selectedItems), false), [item], false);
            setSelectedItems(newState);
            stateCB(newState);
        }
    };
    var removeItem = function (removeValue) {
        if (!isMultiply) {
            setSelectedItems([]);
            stateCB(null);
            return;
        }
        if (selectedItems) {
            var newState = selectedItems.filter(function (_a) {
                var value = _a.value;
                return value !== removeValue;
            });
            setSelectedItems(newState);
            stateCB(newState);
        }
    };
    var handleOpenOnSelectClick = function (e) {
        var _a;
        if (!isMultiply && ((_a = e.target) === null || _a === void 0 ? void 0 : _a.offsetParent.id) === 'customSelectId')
            handleSetIsOpen();
        if (['customSelectItemsId', 'customSelectId', 'customSelectPlaceholderId'].includes(e.target.id))
            handleSetIsOpen();
    };
    return {
        isOpen: isOpen,
        height: height,
        setIsOpen: setIsOpen,
        setHeight: setHeight,
        listItems: listItems,
        removeItem: removeItem,
        selectItem: selectItem,
        searchValue: searchValue,
        selectedItems: selectedItems,
        filterSearchItems: filterSearchItems,
        handleOpenOnSelectClick: handleOpenOnSelectClick,
        setSearchValue: handleSetSearchValue,
    };
};
