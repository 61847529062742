import React from 'react';
import { messengers } from '@public/images';
import { TextWithImageRenderer } from '@src/components/tableRenderers/TextWithImageRenderer/TextWithImageRenderer';
var MessengerRenderer = function (messenger) {
    var text;
    var icon;
    switch (messenger) {
        case 'telegram':
            text = 'Telegram';
            icon = messengers.telegram;
            break;
        case 'Telegram':
            text = 'Telegram';
            icon = messengers.telegram;
            break;
        case 'sms':
            text = 'SMS';
            icon = messengers.sms;
            break;
        case 'Sms':
            text = 'SMS';
            icon = messengers.sms;
            break;
        case 'Web Chat':
            text = 'Web Library';
            icon = messengers.webUi;
            break;
        case 'viber':
            text = 'Viber';
            icon = messengers.viber;
            break;
        case 'Viber':
            text = 'Viber';
            icon = messengers.viber;
            break;
        case 'whatsapp':
            text = 'WhatsApp';
            icon = messengers.whatsapp;
            break;
        case 'Whatsapp':
            text = 'WhatsApp';
            icon = messengers.whatsapp;
            break;
        case 'facebook':
            text = 'Facebook';
            icon = messengers.facebookIcon;
            break;
        case 'messenger':
            text = 'Facebook';
            icon = messengers.facebookIcon;
            break;
        case 'Facebook':
            text = 'Facebook';
            icon = messengers.facebookIcon;
            break;
        case 'email':
            text = 'Email';
            icon = messengers.email;
            break;
        case 'Email':
            text = 'Email';
            icon = messengers.email;
            break;
        case 'metadialog':
            text = 'MetaDialog';
            icon = messengers.metaDialog;
            break;
        case 'Metadialog':
            text = 'MetaDialog';
            icon = messengers.metaDialog;
            break;
        default:
            text = 'Web Library';
            icon = messengers.webUi;
    }
    return (React.createElement(TextWithImageRenderer, { text: text, imageUrl: icon }));
};
export default MessengerRenderer;
