import React from 'react';
import Loader from '@src/components/Loader';
import { StyledTableData } from './StyledTableData';
import { TableHeader } from './TableHeader/TableHeader';
import { TableContent } from './TableContent/TableContent';
export var TableData = function (_a) {
    var columns = _a.columns, rowSettings = _a.rowSettings, dataSource = _a.dataSource, isLoading = _a.isLoading, getRowId = _a.getRowId, sorting = _a.sorting;
    return (React.createElement(StyledTableData, { showCursor: rowSettings === null || rowSettings === void 0 ? void 0 : rowSettings.showCursor, rowHover: rowSettings === null || rowSettings === void 0 ? void 0 : rowSettings.rowHover, rowsLength: dataSource.length },
        React.createElement(TableHeader, { columns: columns, sorting: sorting }),
        isLoading ? React.createElement(Loader, null)
            : (React.createElement(TableContent, { dataSource: dataSource, columns: columns, getRowId: getRowId }))));
};
