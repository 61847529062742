import React from 'react';
import { icons } from '@public/images';
export var SelectListItem = function (_a) {
    var item = _a.item, selectItem = _a.selectItem, selectedItems = _a.selectedItems, removeItem = _a.removeItem, isMultiply = _a.isMultiply, disableRemoveItem = _a.disableRemoveItem, disableRemoveItemFn = _a.disableRemoveItemFn;
    var text = item.text, icon = item.icon;
    var isItemSelected = selectedItems.some(function (el) { return el.value === item.value; });
    return (React.createElement("div", { className: "select_list__item ".concat(isItemSelected ? 'selected' : ''), onClick: function () { return selectItem(item); } },
        React.createElement("div", { className: "select_list__item-wrapper" },
            icon && React.createElement("div", { className: "select_list__item-icon" },
                React.createElement("img", { src: icon, alt: "flag" })),
            React.createElement("div", { className: "select_list__item-text" }, text)),
        isMultiply && isItemSelected && React.createElement("div", null,
            React.createElement("img", { src: icons.ex, alt: "remove", onClick: function () { return (!disableRemoveItem ? removeItem(item.value) : disableRemoveItemFn()); } }))));
};
