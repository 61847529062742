var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export var useProgressBar = function (_a) {
    var step = _a.step;
    var _b = __read(useState(0), 2), progress = _b[0], setProgress = _b[1];
    var _c = __read(useState(), 2), id = _c[0], setId = _c[1];
    useEffect(function () {
        var intervalId = setInterval(function () {
            setId(intervalId);
            setProgress(function (p) { return p + step; });
        }, 1000);
        return function () { return clearInterval(intervalId); };
    }, []);
    if (progress >= 100)
        clearInterval(id);
    return {
        setProgress: setProgress,
        progress: progress,
    };
};
