import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import GroupsAgents from './GroupsAgents';
var mapStateToProps = function (state) { return ({
    modalInfo: modalWindowsStore.getModalInfo(state, modalTypes.UPDATE_GROUPS_MODAL),
    currentGroupsAgents: groupsStore.getCurrentGroupsAgents(state),
    assignedAgentsToGroup: groupsStore.getAssignedAgentsToGroup(state),
    unassignedAgentsToGroup: groupsStore.getUnassignedAgentsToGroup(state),
    isLoadingChangeAgentsGroups: loadingStore.getIsLoadingChangeAgentsGroups(state),
    isModalOpenAddAgentsToGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.ADD_AGENTS_TO_GROUPS),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.ADD_AGENTS_TO_GROUPS, null)); },
    removeAgentFromGroup: function (data) { return dispatch(groupsActions.removeAgentFromGroup(data)); },
    removeOrAddAgentsFromGroup: function (data) { return dispatch(groupsActions.removeOrAddAgentsFromGroup(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GroupsAgents);
