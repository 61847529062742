var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, fork, put, takeLatest, } from 'redux-saga/effects';
import { resetPasswordPageActions, resetPasswordPageTypes } from '@src/modules/Pages/ResetPassword/redux/reducer';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
import { resetPasswordApi } from '@src/modules/Pages/ResetPassword/redux/api';
import { errorNotification, successNotification } from '@src/redux/notification/saga';
import { Messages } from '@public/locales/messages';
import { redirect } from '@src/utils/history';
import { system } from '@src/constants/objects';
var LOGIN = system.PAGE_PATHS.LOGIN;
function sendNewPassword(_a) {
    var e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 8]);
                return [4, call(resetPasswordApi.resetPassword, payload)];
            case 1:
                _b.sent();
                return [4, put(resetPasswordPageActions.setChangePasswordResult({
                        isResult: true,
                        isError: false,
                    }))];
            case 2:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.ResetPasswordSuccess)];
            case 3:
                _b.sent();
                return [4, call(redirect, LOGIN)];
            case 4:
                _b.sent();
                return [3, 8];
            case 5:
                e_1 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_1.text)];
            case 6:
                _b.sent();
                return [4, put(resetPasswordPageActions.setChangePasswordResult({
                        isResult: true,
                        isError: true,
                    }))];
            case 7:
                _b.sent();
                return [3, 8];
            case 8: return [4, put(setLoading(resetPasswordPageTypes.SEND_CHANGE_NEW_PASSWORD, false))];
            case 9:
                _b.sent();
                return [2];
        }
    });
}
function sendCheckIsValidToken(_a) {
    var e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4, call(resetPasswordApi.checkValidToken, payload)];
            case 1:
                _b.sent();
                return [4, put(resetPasswordPageActions.setIsValidToken(false))];
            case 2:
                _b.sent();
                return [3, 5];
            case 3:
                e_2 = _b.sent();
                return [4, put(resetPasswordPageActions.setIsValidToken(true))];
            case 4:
                _b.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
export var resetPasswordSagas = [
    takeLatest(resetPasswordPageTypes.SEND_CHECK_IS_VALID_TOKEN, loaderWrap(sendCheckIsValidToken)),
    takeLatest(resetPasswordPageTypes.SEND_CHANGE_NEW_PASSWORD, loaderWrap(sendNewPassword, false)),
];
