export function deepMerge(target, source) {
    Object.keys(source).forEach(function (key) {
        var _a, _b;
        if (typeof source[key] === 'object' && source[key] !== null) {
            if (!target[key]) {
                Object.assign(target, (_a = {}, _a[key] = {}, _a));
            }
            deepMerge(target[key], source[key]);
        }
        else {
            Object.assign(target, (_b = {}, _b[key] = source[key], _b));
        }
    });
}
