import React from 'react';
import { Messages } from '@public/locales/messages';
var Buttons = function (_a) {
    var current = _a.current, stepsLength = _a.stepsLength, signUpColor = _a.signUpColor, signUpBackground = _a.signUpBackground, onSubmit = _a.onSubmit, privacyPolicy = _a.privacyPolicy, prev = _a.prev, isPrivacyPolicy = _a.isPrivacyPolicy;
    var enabled = privacyPolicy.enabled;
    var showPP = enabled ? enabled && isPrivacyPolicy : true;
    return (React.createElement(React.Fragment, null,
        current + 1 < stepsLength && (React.createElement("div", { onClick: onSubmit, className: "steps-btn__next" }, Messages.Buttons.next)),
        current + 1 === stepsLength && (React.createElement("div", { className: "steps-btn__next", style: showPP
                ? {
                    color: signUpColor || '#fff',
                    backgroundColor: signUpBackground || '#4285F4',
                }
                : { color: '#fff', backgroundColor: '#525e74' }, onClick: onSubmit }, Messages.Labels.Authorization)),
        current > 1 && (React.createElement("div", { onClick: prev, className: "steps-btn__back" }, Messages.Buttons.previous))));
};
export default Buttons;
