var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { env } from '@mdca/wde';
import { getDefaultMinimizeIcon, getDefaultMoreIcon } from '@src/constants/defaultCustomizations';
import { Menu } from './Menu/Menu';
import { HeaderWrapper } from './StyledHeader2';
export var logo = "".concat(env('ICON_WEB_UI'), "/logo.svg");
export var minimizeImg = "".concat(env('ICON_WEB_UI'), "/minimizeIcon.svg");
var Header = function (props) {
    var _a, _b, _c, _d;
    var lang = props.lang, isAuthHeader = props.isAuthHeader, customizations = props.customizations;
    var _e = customizations.header, logoUrl = _e.logoUrl, moreIcon = _e.moreIcon, minimizeIcon = _e.minimizeIcon, headerText = _e.headerText, headerHeight = _e.headerHeight, headerBackground = _e.headerBackground, headerBorderColor = _e.headerBorderColor, disableHeaderLogo = _e.disableHeaderLogo, disableHeaderHideChatWindow = _e.disableHeaderHideChatWindow;
    var _f = __read(useState(false), 2), isMuted = _f[0], setMute = _f[1];
    var _g = __read(useState(false), 2), showMenu = _g[0], setShowMenu = _g[1];
    var _h = __read(useState(false), 2), isFullScreen = _h[0], setIsFullScreen = _h[1];
    var drugStart = function () { };
    var toggleFullScreen = function () { return setIsFullScreen(!isFullScreen); };
    var headerTextTop = headerText && lang && ((_b = headerText[(_a = lang[0]) === null || _a === void 0 ? void 0 : _a.value]) === null || _b === void 0 ? void 0 : _b.headerTextTop);
    var headerTextBottom = headerText && lang && ((_d = headerText[(_c = lang[0]) === null || _c === void 0 ? void 0 : _c.value]) === null || _d === void 0 ? void 0 : _d.headerTextBottom);
    return (React.createElement(HeaderWrapper, { headerHeight: headerHeight, headerBackground: headerBackground, disableDraggable: false, headerBorderColor: headerBorderColor, showMenu: showMenu, lang: lang },
        React.createElement(Menu, { lang: 'en', showMenu: showMenu, moreMenu: customizations.moreMenu, setShowMenu: setShowMenu, toggleFullScreen: toggleFullScreen, isMuted: isMuted, isAuthHeader: isAuthHeader, isFullScreen: isFullScreen, setMute: setMute }),
        React.createElement("div", { className: "AskMeWebLibrary-header" },
            React.createElement("div", { className: "AskMeWebLibrary-header__drug", onMouseDown: drugStart }),
            React.createElement("div", { className: "AskMeWebLibrary-header__logo" },
                disableHeaderLogo && React.createElement("img", { alt: "log", src: logoUrl || logo, width: "60" }),
                !disableHeaderLogo && headerText && (React.createElement("div", { className: "header-text" },
                    React.createElement("p", null, headerTextTop),
                    React.createElement("p", null, headerTextBottom)))),
            React.createElement("div", { className: "AskMeWebLibrary-header__buttons" },
                React.createElement("div", { className: "AskMeWebLibrary-header__buttons-minimize", onClick: function () { return setShowMenu(!showMenu); } },
                    React.createElement("img", { id: "more-button", src: moreIcon || getDefaultMoreIcon(), alt: "moreIcon", style: { cursor: 'pointer' } })),
                disableHeaderHideChatWindow && (React.createElement("div", { className: "AskMeWebLibrary-header__buttons-minimize" },
                    React.createElement("img", { id: "minimize-button", src: minimizeIcon || getDefaultMinimizeIcon(), alt: "minimize", style: { cursor: 'pointer' } })))))));
};
export default Header;
