var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
var SECONDARY_BACKGROUND = COLORS.SECONDARY_BACKGROUND;
export var StyledMessagesContainerWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 5px 10px;\n  max-height: 50vh;\n  min-height: 15vh;\n  width: 40vw;\n  border-radius: 4px;\n  background: ", ";\n  overflow: auto;\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 5px 10px;\n  max-height: 50vh;\n  min-height: 15vh;\n  width: 40vw;\n  border-radius: 4px;\n  background: ", ";\n  overflow: auto;\n  ", ";\n"])), SECONDARY_BACKGROUND, scrollStyles);
export var StyledMessagesContainerNoData = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    min-height: 10vh;\n    min-width: 10vw;\n  }\n"], ["\n  && {\n    min-height: 10vh;\n    min-width: 10vw;\n  }\n"])));
var templateObject_1, templateObject_2;
