import React from 'react';
import { SelectListWrapper } from '@src/components/Select/SelectList/styled';
import { SelectListItem } from '@src/components/Select/SelectList/SelectListItem';
import { SearchInput } from '@src/components/Select/SearchInput/SearchInput';
export var SelectList = function (_a) {
    var items = _a.items, selectItem = _a.selectItem, styles = _a.styles, currentInputHeight = _a.currentInputHeight, removeItem = _a.removeItem, isMultiply = _a.isMultiply, selectedItems = _a.selectedItems, filterSearchItems = _a.filterSearchItems, searchPlaceholder = _a.searchPlaceholder, disableSearch = _a.disableSearch, disableRemoveItem = _a.disableRemoveItem, disableRemoveItemFn = _a.disableRemoveItemFn;
    return (React.createElement(SelectListWrapper, { id: "customSelectListId", styles: styles, len: items.length, currentInputHeight: currentInputHeight },
        !disableSearch && (React.createElement(SearchInput, { filterSearchItems: filterSearchItems, searchPlaceholder: searchPlaceholder })),
        items.map(function (item, i) { return (React.createElement(SelectListItem, { disableRemoveItemFn: disableRemoveItemFn, disableRemoveItem: disableRemoveItem, key: i, selectItem: selectItem, item: item, selectedItems: selectedItems, removeItem: removeItem, isMultiply: isMultiply })); })));
};
