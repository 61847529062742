var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useState } from 'react';
import { Messages } from '@public/locales/messages';
import { transformArrForSelect } from '@src/utils/transformArrForSelect';
import Select from '@src/components/Select';
import Button from '@src/components/Button';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var AgentToTags = function (props) {
    var tagsCurrentAgent = props.tagsCurrentAgent, addAndDeleteAgentTags = props.addAndDeleteAgentTags;
    var _a = __read(useState(transformArrForSelect(tagsCurrentAgent === null || tagsCurrentAgent === void 0 ? void 0 : tagsCurrentAgent.assigned)), 2), assignedTags = _a[0], setAssignedTags = _a[1];
    var selectTags = useCallback(function (tags) {
        setAssignedTags(tags);
    }, [assignedTags]);
    return (React.createElement(StyledModalContentWrapper, null,
        React.createElement(StyledModalContentFieldsWrapper, null,
            React.createElement(Select, { label: Messages.Labels.SelectTags, placeholder: Messages.Selects.SelectTags, items: __spreadArray(__spreadArray([], __read(transformArrForSelect(tagsCurrentAgent === null || tagsCurrentAgent === void 0 ? void 0 : tagsCurrentAgent.assigned)), false), __read(transformArrForSelect(tagsCurrentAgent === null || tagsCurrentAgent === void 0 ? void 0 : tagsCurrentAgent.unAssigned)), false), stateCB: selectTags, initialState: assignedTags })),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { return addAndDeleteAgentTags(assignedTags); } })))));
};
export default AgentToTags;
