var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState, } from 'react';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes } from '@src/global-interfaces';
import Button from '@src/components/Button';
import ErrorMessage from '@src/components/ErrorMessage';
import { isRequired } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledLabelTextarea, } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalUsefulPhrase/styled';
import { StyledModalContentWrapper, StyledModalFooterOneBtnWrapper, StyledModalContentFieldsWrapper, } from '@src/app/styled';
import { StyledTextarea, StyledImportPhonesWrapper, SendFilePhonesButtonInput, StyledImportPhonesFileName, StyledUploadClientsWrapper, SendFilePhonesButtonWrapper, SendFilePhonesButtonInputLabel, StyledSendFilePhonesLabelWrapper, } from '@src/modules/Pages/Clients/BulkMessaging/styled';
var paperClip = icons.paperClip;
var UploadClients = function (props) {
    var showNotification = props.showNotification, resetNotificationState = props.resetNotificationState, importPhoneForBulkMessaging = props.importPhoneForBulkMessaging;
    var _a = __read(useState(''), 2), filePhoneName = _a[0], setFilePhoneName = _a[1];
    var _b = __read(useState(null), 2), currentPhoneFile = _b[0], setCurrentPhoneFile = _b[1];
    var _c = __read(useState(''), 2), bulkMessagingText = _c[0], setBulkMessagingText = _c[1];
    var _d = __read(useState(false), 2), isErrorText = _d[0], setIsErrorText = _d[1];
    var showWarning = function (msg) {
        showNotification({ msg: msg, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var setWarning = function (file, fileFormat, fn) {
        if (file.size > (1024 * 1024 * 20)) {
            fn("".concat(Messages.Errors.FileSizeExceededWarnings));
            return;
        }
        if (fileFormat !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            fn("".concat(Messages.Errors.ErrorWrongFileFormat));
            return;
        }
        return true;
    };
    var onChangeFilePhoneHandler = useCallback(function (e) {
        var content;
        var file = e.target.files[0];
        var reader = new FileReader();
        var _a = __read(file.type.split('/'), 2), fileFormat = _a[1];
        if (!setWarning(file, fileFormat, showWarning))
            return;
        setFilePhoneName(file.name);
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            content = reader.result;
            if (typeof content !== 'string')
                return;
            setCurrentPhoneFile(content.split('base64,')[1]);
        };
    }, []);
    var clearUploadInput = useCallback(function (e) {
        e.target.value = null;
    }, []);
    var onChange = useCallback(function (e) {
        if (!isRequired(bulkMessagingText))
            setIsErrorText(false);
        setBulkMessagingText(e.target.value);
    }, [bulkMessagingText]);
    var onSubmit = function () {
        if (isRequired(bulkMessagingText)) {
            showWarning(Messages.Errors.FieldIsRequired);
            setIsErrorText(!!isRequired(bulkMessagingText));
            return;
        }
        if (!currentPhoneFile) {
            showWarning(Messages.Errors.FieldIsRequired);
            return;
        }
        importPhoneForBulkMessaging({ message: bulkMessagingText, phonesBase64: currentPhoneFile });
    };
    return (React.createElement(StyledUploadClientsWrapper, null,
        React.createElement(StyledModalContentWrapper, null,
            React.createElement(StyledModalContentFieldsWrapper, null,
                React.createElement(StyledSendFilePhonesLabelWrapper, null, Messages.Labels.FileUploadHint),
                React.createElement(StyledLabelTextarea, null, Messages.Labels.TextForBulkMessaging),
                React.createElement(StyledTextarea, { onChange: onChange, value: bulkMessagingText, placeholder: Messages.Labels.TextForBulkMessaging }),
                isErrorText && (React.createElement(ErrorMessage, { text: isRequired(bulkMessagingText) })),
                React.createElement(StyledImportPhonesWrapper, null,
                    React.createElement(SendFilePhonesButtonWrapper, null,
                        React.createElement(SendFilePhonesButtonInputLabel, { htmlFor: "upload-file" },
                            React.createElement("img", { src: paperClip, alt: "Attach file" })),
                        React.createElement(SendFilePhonesButtonInput, { type: "file", name: "file", id: "upload-file", onChange: onChangeFilePhoneHandler, onClick: clearUploadInput })),
                    React.createElement(StyledImportPhonesFileName, null, filePhoneName || Messages.Labels.ClickOnThePaperclipIconToSelectTheExcelFile))),
            React.createElement(StyledModalFooterOneBtnWrapper, null,
                React.createElement(StyledSaveBtn, null,
                    React.createElement(Button, { type: TButtonTypes.submit, title: Messages.Buttons.send, onClick: onSubmit }))))));
};
export default UploadClients;
