var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import { isEqual } from 'lodash';
import { Messages } from '@public/locales/messages';
import Button from '@src/components/Button';
import { StyledAddFilterBtn } from '@src/components/Button/styled';
import SearchList from '@src/components/SearchList';
import { system } from '@src/constants/objects';
import SearchFilter from '@src/modules/ToolBar/ToolBarUI/Filtration/SearchFilter';
import SelectedFilter from '@src/modules/ToolBar/ToolBarUI/Filtration/SelectedFilters';
import { StyledFiltersAddBtnWrapper, StyledFiltersContent, StyledFilterWrapper, StyledResetFilters, StyledSelectedFilters, } from '@src/modules/ToolBar/ToolBarUI/Filtration/styled';
export var getAppLang = function () { return localStorage.getItem('app_lang') || 'en'; };
var Filtration = function (props) {
    var _a;
    var currentPage = props.currentPage, addFilters = props.addFilters, filters = props.filters, deleteFilters = props.deleteFilters, resetFilters = props.resetFilters, setShowSelectedFilter = props.setShowSelectedFilter;
    var _b = __read(useState(false), 2), isShowSearchFilterList = _b[0], setIsShowSearchFilterList = _b[1];
    var buttonAddFilterId = 'buttonAddFilterId';
    var onChangeShowSearchFilterList = useCallback(function () {
        setIsShowSearchFilterList(!isShowSearchFilterList);
        setShowSelectedFilter(null);
    }, [isShowSearchFilterList]);
    var selectFilters = function (data) { return addFilters({ currentPage: currentPage, filter: data }); };
    var clearFilter = function (data) { return deleteFilters({ currentPage: currentPage, filter: data }); };
    var resetAllFilters = function (data) { return resetFilters(data); };
    return (React.createElement(StyledFilterWrapper, null,
        React.createElement(StyledSelectedFilters, null,
            system.isSearchOfValue[currentPage] && React.createElement(SearchFilter, null),
            React.createElement(StyledFiltersAddBtnWrapper, { language: getAppLang() },
                React.createElement(StyledAddFilterBtn, null,
                    React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addFilter), onClick: onChangeShowSearchFilterList, id: buttonAddFilterId })),
                React.createElement(StyledResetFilters, { reverse: getAppLang() === 'ar' || getAppLang() === 'he' ? 'right' : 'left', onClick: function () { return resetAllFilters(currentPage); } }, Messages.Buttons.reset),
                isShowSearchFilterList && (React.createElement(SearchList, { multiple: true, isSearch: true, hideSearchList: setIsShowSearchFilterList, isAbsolutePosition: true, elementIsNotHideSearchList: [buttonAddFilterId], onClickItem: selectFilters, placeholder: Messages.Controls.SearchFilter, selectedItem: filters[currentPage] || [], searchListArr: system.allFilters[currentPage], onClickItemIcon: clearFilter })))),
        React.createElement(StyledFiltersContent, null, (_a = filters[currentPage]) === null || _a === void 0 ? void 0 : _a.map(function (el, index) { return (React.createElement(SelectedFilter, { key: index, title: el, clearFilter: clearFilter, setIsShowSearchFilterList: setIsShowSearchFilterList })); }))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(Filtration, areEqual);
