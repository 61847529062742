import { connect } from 'react-redux';
import ExcelModal from '@src/modules/Excel/ExcelModal/ExcelModal';
import excelStore from '@src/modules/Excel/redux/selector';
import { excelActions } from '@src/modules/Excel/redux/reducer';
var mapStateToProps = function (state) { return ({
    excelModalState: excelStore.getExcelModalState(state),
    excelQueue: excelStore.getExcelQueue(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    clearExcelQueue: function () { return dispatch(excelActions.clearExcelQueue()); },
    toggleExcelModalMinimize: function () { return dispatch(excelActions.setExelModalStateMinimize()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ExcelModal);
