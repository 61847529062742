var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createUniqueArrayOfObjects } from '@src/utils/createUniqueArrayOfObjects';
export var getChangesObjSelectedFilter = function (args) {
    var _a, _b, _c, _d;
    var currentPage = args.currentPage, SelectedFilterValue = args.SelectedFilterValue, deleteSelectedFilter = args.deleteSelectedFilter;
    var selectedFilters = args.selectedFilters;
    var filter = Object.keys(SelectedFilterValue)[0];
    if (!selectedFilters[currentPage]) {
        selectedFilters = __assign(__assign({}, selectedFilters), (_a = {}, _a[currentPage] = (_b = {},
            _b[filter] = [],
            _b), _a));
    }
    var tempArr = __spreadArray([], __read(selectedFilters[currentPage][filter] || []), false);
    if (!deleteSelectedFilter) {
        if (!Array.isArray(SelectedFilterValue[filter])) {
            Array.isArray(tempArr) && tempArr.push(SelectedFilterValue[filter]);
        }
        else {
            tempArr = SelectedFilterValue[filter];
        }
    }
    else if (Array.isArray(tempArr)) {
        if (typeof SelectedFilterValue[filter] === 'object' && !Array.isArray(SelectedFilterValue[filter])) {
            tempArr = tempArr.filter(function (el) { return el.name !== SelectedFilterValue[filter].name; });
        }
        else {
            var filterIndex = tempArr.indexOf(SelectedFilterValue[filter]);
            tempArr.splice(filterIndex, 1);
        }
    }
    var uniqueTempArr = createUniqueArrayOfObjects(tempArr);
    return __assign(__assign({}, selectedFilters), (_c = {}, _c[currentPage] = __assign(__assign({}, selectedFilters[currentPage]), (_d = {}, _d[filter] = uniqueTempArr, _d)), _c));
};
