var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
var StyledBody = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  width: 100%;\n  padding: 10px 0 10px 20px;\n  position: relative;\n  background: ", ";\n  border: 1px solid ", ";\n  transition: 0.5s;\n  & .body-background-img {\n    position: absolute;\n    transform: translate(-50%, -50%);\n    left: 50%;\n    top: 50%;\n    & img {\n      width: ", ";\n      height: ", ";\n    }\n  }\n  & .scroll-track2 {\n    position: absolute;\n    right: -1px;\n    bottom: 2px;\n    top: 0;\n    border-radius: 10px;\n  }\n  & .scroll-track3 {\n    position: absolute;\n    right: 1px;\n    bottom: 2px;\n    top: 2px;\n    border-radius: 10px;\n  }\n"], ["\n  height: ", ";\n  width: 100%;\n  padding: 10px 0 10px 20px;\n  position: relative;\n  background: ", ";\n  border: 1px solid ", ";\n  transition: 0.5s;\n  & .body-background-img {\n    position: absolute;\n    transform: translate(-50%, -50%);\n    left: 50%;\n    top: 50%;\n    & img {\n      width: ", ";\n      height: ", ";\n    }\n  }\n  & .scroll-track2 {\n    position: absolute;\n    right: -1px;\n    bottom: 2px;\n    top: 0;\n    border-radius: 10px;\n  }\n  & .scroll-track3 {\n    position: absolute;\n    right: 1px;\n    bottom: 2px;\n    top: 2px;\n    border-radius: 10px;\n  }\n"])), function (_a) {
    var bodyHeight = _a.bodyHeight;
    return bodyHeight;
}, function (_a) {
    var chatWindowsBodyBackground = _a.chatWindowsBodyBackground;
    return chatWindowsBodyBackground || 'black';
}, function (_a) {
    var chatWindowsBorderColor = _a.chatWindowsBorderColor;
    return chatWindowsBorderColor || 'black';
}, function (_a) {
    var chatWindowsLogoSize = _a.chatWindowsLogoSize;
    return chatWindowsLogoSize || 'black';
}, function (_a) {
    var chatWindowsLogoSize = _a.chatWindowsLogoSize;
    return chatWindowsLogoSize || 'black';
});
export default StyledBody;
var templateObject_1;
