var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState } from 'react';
import { Slider } from 'antd';
import moment from 'moment';
import { icons } from '@public/images';
import { StyledAudioController, StyledAudioWrapper, StyledCreateAudioTime, StyledDownloadIcon, } from '@src/components/Audio/styled';
import { formatterAudioForSlider } from '@src/utils/formatterAudioForSlider';
var PauseOutlined = icons.PauseOutlined, playAudio = icons.playAudio, downloadIcon = icons.downloadIcon;
var Audio = function (props) {
    var filePath = props.filePath, createdAt = props.createdAt, recorderState = props.recorderState, setSelectedRecords = props.setSelectedRecords;
    var audioRef = useRef(null);
    var _a = __read(useState(recorderState === filePath), 2), isPlaying = _a[0], setIsPlaying = _a[1];
    var _b = __read(useState(0), 2), duration = _b[0], setDuration = _b[1];
    var _c = __read(useState(0), 2), currentTime = _c[0], setCurrentTime = _c[1];
    var dateTime = moment(createdAt).format('DD/MM/YY H:mm');
    var play = function () {
        var audio = audioRef.current;
        if (!isPlaying) {
            setIsPlaying(true);
            if (audio) {
                audio.play();
            }
            setSelectedRecords(filePath);
        }
        if (isPlaying) {
            setIsPlaying(false);
            if (audio) {
                audio.pause();
            }
            setSelectedRecords('');
        }
    };
    var getDuration = function (e) {
        var time = e.currentTarget.currentTime;
        setCurrentTime(+time);
    };
    var recordingEnd = function (e) {
        setDuration(+e.currentTarget.duration);
    };
    useEffect(function () {
        if (currentTime > duration) {
            setDuration(currentTime);
            setSelectedRecords('');
        }
        else if (currentTime === duration) {
            setSelectedRecords('');
            setCurrentTime(0);
            setIsPlaying(false);
        }
    }, [currentTime, duration]);
    useEffect(function () {
        var audio = audioRef.current;
        if (recorderState !== filePath) {
            setIsPlaying(false);
            if (audio) {
                audio.pause();
            }
        }
    }, [recorderState]);
    var sliderChange = function (e) {
        var audio = audioRef.current;
        if (audio) {
            audio.currentTime = e;
        }
    };
    return (React.createElement(StyledAudioWrapper, null,
        React.createElement(StyledCreateAudioTime, null, dateTime),
        React.createElement(StyledAudioController, null,
            isPlaying ? (React.createElement("img", { src: PauseOutlined, className: "play_btn", onClick: play, alt: "pause" })) : (React.createElement("img", { src: playAudio, className: "play_btn", onClick: play, alt: "play" })),
            React.createElement(Slider, { value: currentTime, tipFormatter: formatterAudioForSlider, className: "recorded_slider", step: 0.001, onChange: sliderChange, max: duration }),
            React.createElement(StyledDownloadIcon, null,
                React.createElement("a", { href: filePath },
                    React.createElement("img", { src: downloadIcon, onClick: function () { }, alt: "download" })))),
        React.createElement("audio", { ref: audioRef, src: filePath, onTimeUpdate: getDuration, onLoadedData: recordingEnd },
            React.createElement("track", { default: true }))));
};
export default Audio;
