var Messages = {
    Success: {
        Success: 'Sucesso',
        ForgotPasswordSend: 'O link de alteração de senha foi enviado para seu e-mail',
        ResetPasswordSuccess: 'Sua senha foi atualizada com sucesso',
        BrandCreated: 'Marca criada!',
        MailInboxCreated: 'Caixa de entrada de e-mail criada!',
        PrivacyPolicyChanged: 'Política de privacidade alterada!',
        SmsInboxCreated: 'Caixa de entrada de sms criada!',
        InboxDeleted: 'Caixa de entrada excluída!',
        StatusChanged: 'Estado alterado!',
        FileLoaded: 'Arquivo carregado!',
        AuthMail: 'Correio de Autenticação!',
        DomainSettings: 'Configurações de domínio!',
        UpdatedSmsAccounts: 'Contas de sms atualizadas!',
        ChanelCreated: 'Canal criado!',
        DeleteMessenger: 'Excluir Mensageiro!',
        AddMessenger: 'Adicionar Mensageiro!',
        ChangedMessenger: 'Mensageiro alterado!',
        ClientCreated: 'Cliente criado!',
        ClientsImports: 'Importações de clientes!',
        GroupCreated: 'Grupo criado!',
        ChangedAssignedAgentsInGroups: 'Agentes atribuídos alterados em grupos!',
        ChangedAssignedChannelsInGroups: 'Mudou os canais atribuídos em grupos!',
        ChangedAssignedTagsInGroups: 'Alteradas as tags atribuídas nos grupos!',
        CreateNewCategory: 'Criar nova categoria!',
        CreateNewPhrase: 'Crie uma nova frase!',
        DeleteCategory: 'Excluir categoria!',
        DeletePhrase: 'Excluir frase!',
        UpdatePhrase: 'Atualizar frase!',
        EditCategory: 'Editar categoria!',
        DeleteAllCategory: 'Excluir toda a categoria!',
        TagCreated: 'Etiqueta criada!',
        TagUpdated: 'Etiqueta atualizada!',
        TelegramInboxCreated: 'Caixa de entrada do Telegram criada!',
        HeaderTextChanged: 'Texto do cabeçalho alterado',
        LogoIconUrlChanged: 'URL do ícone do logotipo alterado',
        IconUrlChanged: 'URL do ícone alterado',
        BackgroundImageUrlChanged: 'URL da imagem de fundo alterado',
        GreetingImageUrlChanged: 'URL da imagem de saudação alterado',
        AgentImageUrlChanged: 'URL da imagem do agente alterado',
        InputPlaceholderChanged: 'Marcador de entrada alterado',
        SendImageUrlChanged: 'URL de envio da imagem alterado',
        AgentStatusChanged: 'Status do agente alterado',
        SelectedAgentTagsChanged: 'Grupos de agentes atribuídos alterados',
        AssignedAgentsGroupsChanged: 'Os grupos de agentes atribuídos foram alterados',
        TeamLeadChanged: 'Líder de equipe alterado',
        AgentRoleChanged: 'Função do agente alterada',
        AgentInfoChanged: 'As informações do agente foram alteradas',
    },
    Errors: {
        PasswordMatching: 'As senhas não coincidem!',
        IncorrectEmail: 'O e-mail está incorreto',
        IncorrectPhone: 'O telefone está incorreto',
        IncorrectUrl: 'O URL está incorreto',
        FieldContainSpaces: 'O campo contém espaços',
        FieldIsRequired: 'Campo é obrigatório',
        NameValidation: 'O comprimento do campo deve ser de 2 a 40 caracteres, inclusive',
        NumberValidation: 'O campo deve conter apenas números',
        PasswordValidation: 'A senha deve conter letras e números e não pode conter espaços',
        InvoiceNumberValidation: 'O número da fatura deve conter letras latinas, números e caracteres especiais',
        MinimumLength: 'Símbolos de comprimento mínimo {0}',
        MaximumLength: 'Símbolos de comprimento máximo {0}',
        ServerError: 'Erro no servidor, tente mais tarde!',
        ServerErrorFileDownloadTimeOut: 'Erro no servidor, o download do arquivo expirou, tente mais tarde!',
        NotValidEmailOrPassword: 'E-mail ou senha inválidos',
        UserWasNotFound: 'O usuário não foi encontrado!',
        UserIsAlreadyExist: 'O usuário já existe!',
        AgentWasBanned: 'O agente foi banido',
        TokenExpired: 'A sessão expirou. Por favor, faça login novamente',
        TelegramIdIsIncorrect: 'O ID do telegrama está incorreto',
        ErrorWrongFileFormat: 'Erro, formato de arquivo errado!',
        FileSizeExceededWarnings: 'O tamanho do arquivo excedeu os avisos!',
        BrandWithSuchNameIsAlreadyExisting: 'Marca com esse nome já existe!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Você já selecionou um filtro',
        AgentCreate: 'Você é um novo agente no sistema AskMe. Observe que você só pode fazer login depois de receber a função de administrador!',
        NoStatisticsForTheAgent: 'Não há dados estatísticos para o agente para o período selecionado',
        FieldIsRequired: 'Campo é obrigatório',
        ChannelMustContainAtLeastOneLanguage: 'O canal deve conter pelo menos um idioma',
    },
    Info: {
        Copied: 'copiada',
        PhraseBotNameAndGreetingsChanged: 'Frases, nome do bot e saudação alterados',
    },
    Confirm: {},
    Message: {
        Welcome: 'Bem-vindo ao AskMe',
        SignIn: 'Faça login no painel de administração ou vá para o seu ',
        SignUp: 'Inscreva-se no painel de administração ou vá para o seu ',
        Register: 'Não tem uma conta conosco?',
        Login: 'já tem uma conta?',
        Logout: 'Você realmente quer ir embora?',
        ForgotPassword: 'Esqueceu a senha?',
        ExcelFilesDownloading: 'Download de arquivos do Excel',
        excelFileIs: 'arquivo excel',
        Created: 'é criado',
        Creating: 'está criando',
    },
    Accordions: {
        MailInboxes: 'Caixas de entrada de correio',
        TelegramAccounts: 'Contas de telegrama',
        SmsAccounts: 'Contas SMS',
    },
    Controls: {
        Newbie: 'Novata',
        Name: 'Nome',
        Email: 'Email',
        Password: 'Senha',
        RepeatPassword: 'Repita a senha',
        Register: 'Registro',
        Login: 'Conecte-se',
        Or: 'OU',
        SignUp: 'Inscrever-se',
        Send: 'Mandar',
        SearchFilter: 'Filtros de pesquisa',
        Search: 'Procurar',
        SearchMessengers: 'Pesquisar mensageiros',
        SearchAgent: 'Agente de pesquisa',
        SearchLanguage: 'Idioma de pesquisa',
        SearchChannel: 'Pesquisar canal',
        SearchTag: 'Tag de pesquisa',
        SearchStatus: 'Status da pesquisa',
        SearchBrand: 'Pesquisar marca',
        SearchWhiteListTags: 'Pesquisar tags de lista branca',
        SearchSessionStatus: 'Status da sessão de pesquisa',
        SearchAccessLevel: 'Nível de acesso de pesquisa',
        SearchHasActiveDialogs: 'A pesquisa tem caixas de diálogo ativas',
        GroupName: 'Nome do grupo*',
        Description: 'Descrição*',
        LinkAlias: 'Alias do link*',
        CategoryName: 'Nome da Categoria*',
        BrandName: 'Marca',
        AccessKey: 'Chave de acesso',
        ClientName: 'Nome do cliente',
        ClientFirstName: 'Primeiro nome do cliente',
        ClientLastName: 'Sobrenome do cliente',
        ClientPhone: 'Telefone do cliente',
        ClientEmail: 'Email do cliente',
        PleaseWrite: 'Por favor, escreva',
        MailInbox: 'Caixa de entrada de correio',
        SmsInbox: 'Caixa de entrada de SMS',
        TelegramInbox: 'Caixa de entrada do telegrama',
        ApplicationID: 'ID do aplicativo',
        ApplicationToken: 'Token de aplicativo',
        SenderID: 'ID do remetente',
        ChangeName: 'Mude o nome',
        ChangeURL: 'Alterar URL',
        TagName: 'Nome da etiqueta',
        LinkUrl: 'URL do link*',
        Greetings: 'Saudações',
        FastPhrase: 'Frase rápida №',
        ClickOnPaperclip: 'Clique no ícone do clipe de papel para selecionar o arquivo',
        UserName: 'Conecte-se',
        Domain: 'Domínio*',
        Imap: 'Imap (hospedeiro:porta)*',
        SmtpTLS: 'Smtp TLS (hospedeiro:porta)*',
        SmtpSSL: 'Smtp SSL (hospedeiro:porta)*',
        AttachFile: 'Anexar arquivo',
        NoData: 'sem dados',
        NoRating: 'sem avaliação',
        IfYouWantFinishDialog: 'Se você quiser terminar a caixa de diálogo clique no botão OK',
        AllDialogsAreVisibleToAllAgents: 'Todas as caixas de diálogo são visíveis para todos os agentes',
        DialogueIsAssignedAutomatically: 'O diálogo é atribuído automaticamente ao agente que tem o menor número de diálogos',
        AllDialogsAreVisibleToSeveralAgents: 'Os diálogos são visíveis para vários agentes com o menor número de diálogos',
        On: 'Ligar',
        Off: 'Desligar',
        FirstName: 'Primeiro nome',
        InvoiceNumber: 'Número da fatura',
        LastName: 'Último nome',
        SMS: 'SMS',
        New: 'Novo',
        Active: 'Ativo',
        Finished: 'Finalizado',
        Assigned: 'Atribuído',
        Online: 'Ligado',
        Offline: 'Desligado',
        Break: 'Pausa',
        Low: 'Baixo',
        Medium: 'Médio',
        High: 'Alto',
        True: 'Verdadeiro',
        False: 'Falso',
        Date: 'Encontro: Data',
        AgentName: 'Nome do agente',
        Messenger: 'Mensageiro',
        Language: 'Linguagem',
        Channel: 'Canal',
        Tag: 'Marcação',
        Status: 'Status',
        Brand: 'Marca',
        AccessLevel: 'Nível de acesso',
        WhiteListTags: 'Tags da lista branca',
        SessionStatus: 'Status da sessão',
        LastTags: 'Últimas tags',
        HasActiveDialogs: 'Tem diálogos ativos',
        ChannelName: 'Nome do canal',
        WriteYourFirstName: '* Por favor, escreva seu primeiro nome',
        WriteYourLastName: '* Por favor, escreva seu sobrenome',
        WriteYourUserName: '* Por favor, escreva o nome de usuário',
        WriteYourPhoneNumber: '* Por favor, escreva o número de telefone',
        WriteYourEmail: '* Por favor, escreva seu e-mail',
        WriteYourInvoiceNumber: '* Por favor, escreva o número da sua fatura',
        WriteYourAccountNumber: '* Por favor, escreva o número da sua conta',
        BotName: 'Nome do bot',
        Character: 'caráter',
        Number: 'número',
        IdExample: 'Exemplo de ID',
        XCharecter: 'X-character',
        YNumber: 'Número Y',
        ThisOperationCanTakeLongTime: 'Esta operação pode levar muito tempo!',
        ClickTiSaveExcel: 'Clique para salvar no Excel',
        PrivacyPolicyText: 'Texto da política de privacidade',
        PrivacyPolicyLink: 'Link da política de privacidade',
        PrivacyPolicyLinkText: 'Texto do link da política de privacidade',
        AgentPhone: 'Telefone do agente',
        HeaderTextTop: 'Parte superior do texto do cabeçalho',
        HeaderTextBottom: 'Parte inferior do texto do cabeçalho',
        LogoIconUrl: 'URL do ícone do logotipo',
        IconUrl: 'URL do ícone',
        ChatWindowsLogo: 'Logotipo do bate-papo',
        GreetingIconBotUrl: 'Url do bot do ícone de saudação',
        AgentMessageIconUrl: 'URL do ícone da mensagem do agente',
        WriteMessagesPlaceholder: 'Escreva o espaço reservado para mensagens',
        SendImageUrl: 'Enviar URL da imagem',
        ChannelURL: 'URL do canal',
    },
    Selects: {
        SelectAccessLevel: 'Selecione o nível de acesso*',
        SelectChannel: 'Selecionar canal*',
        SelectChannels: 'Selecionar canais',
        SelectWhiteTagsList: 'Selecione a lista de tags brancas*',
        SelectAgents: 'Selecionar agentes',
        SelectGroups: 'Selecionar grupos',
        SelectBrand: 'Selecionar marca',
        SelectTags: 'Selecionar etiquetas',
        ChooseProviderCountry: 'Escolha o país do provedor',
        SelectMessengers: 'Selecionar mensageiros',
        SelectTagStatus: 'Selecione o estado da etiqueta',
        SelectAgent: 'Selecionar agente',
        SelectEnabledLanguages: 'Selecione os idiomas ativados',
        ChannelName: 'Nome do canal',
        ChannelURL: 'URL do canal',
        SelectLanguageWebChat: 'Selecionar idiomas Webchat',
    },
    Labels: {
        MoreIconUrl: 'Mais ícone UR',
        MinimizeIconUrl: 'Minimizar ícone URL',
        SendFileUrl: 'Enviar URL do arquivo',
        NumberOfNewAgents: 'Número de novos agentes',
        TextForBulkMessaging: 'Texto para mensagens em massa',
        Name: 'Nome',
        LastName: 'Último nome',
        Phone: 'Telefone',
        GroupName: 'Nome do grupo*',
        SelectBrand: 'Selecionar marca',
        Channel: 'Canal*',
        Description: 'Descrição*',
        AccessLevel: 'Nível de acesso*',
        WhiteTagsList: 'Lista de tags brancas*',
        BrandName: 'Marca',
        CategoryName: 'Nome da Categoria*',
        ChooseProviderCountry: 'Escolha o país do provedor',
        AccessKey: 'Chave de acesso',
        SelectChannels: 'Selecionar canais',
        SelectGroups: 'Selecionar grupos',
        SelectAgents: 'Selecionar agentes',
        SelectTags: 'Selecionar etiquetas',
        SelectAccessLevel: 'Selecione o nível de acesso*',
        Password: 'Senha*',
        Login: 'Conecte-se',
        SelectMessengers: 'Selecionar mensageiros',
        UserName: 'Nome do usuário',
        ClientName: 'Nome do cliente',
        ClientFirstName: 'Nome do cliente',
        ClientLastName: 'Sobrenome do cliente',
        ClientPhone: 'Telefone do cliente',
        ClientEmail: 'Email do cliente',
        ClientInfo: 'Informações do cliente',
        UpdateGroup: 'Atualizar grupo',
        Agents: 'Agentes',
        UsefulPhrases: 'Frases úteis',
        Channels: 'Canais',
        Tags: 'Tag',
        AddNewPhrases: 'Adicionar novas frases',
        Phrase: 'Frase',
        SelectCategory: 'Selecione a Categoria',
        EditCategory: 'Editar categoria',
        EditPhrase: 'Editar frase',
        Or: 'ou',
        AddNewCategory: 'Adicionar nova categoria',
        Dialogs: 'Diálogos',
        TotalDuration: 'Duração total',
        Email: 'Email',
        ClientId: 'ID do Cliente',
        ClientBanType: 'Tipo de banimento do cliente',
        ClientBanTime: 'Tempo de banimento do cliente',
        Messengers: 'Mensageiros',
        IpAddress: 'endereço de IP',
        ExternalID: 'ID externo',
        UpdateBrand: 'Atualizar marca',
        CreateMailInbox: 'Criar conta de caixa de entrada de email',
        CreateTelegramInbox: 'Criar conta no Telegram',
        CreateSmsInbox: 'Criar conta SMS',
        MailInbox: 'Caixa de entrada de correio',
        TelegramInbox: 'Caixa de entrada do telegrama',
        SmsInbox: 'Caixa de entrada de SMS',
        ApplicationID: 'ID do aplicativo',
        ApplicationToken: 'Token de aplicativo',
        SenderID: 'ID do remetente',
        ChangeName: 'Mude o nome',
        ChangeURL: 'Alterar URL',
        TagName: 'Nome da etiqueta',
        SelectTagStatus: 'Selecione o estado da etiqueta',
        SelectAgent: 'Selecionar agente',
        UpdateTag: 'Atualizar etiqueta',
        CreateTag: 'Criar etiqueta',
        UsefulLinks: 'Links Úteis',
        LinkAlias: 'Alias do link',
        LinkUrl: 'URL do link',
        Roles: 'Funções',
        Groups: 'Grupos',
        CreateGroups: 'Criar grupos',
        TeamLead: 'Liderança da equipe',
        Ban: 'Ban',
        SelectEnabledLanguages: 'Selecione os idiomas ativados',
        Greetings: 'Saudações',
        FastPhrase: 'Frase rápida №',
        ClientHistory: 'Histórico do cliente',
        ChooseRequiredFields: 'Por favor, escolha os campos obrigatórios',
        GetAllDialogs: 'Obter todas as caixas de diálogo',
        GetCurrentDialog: 'Obter diálogo atual',
        SaveInLocalStorage: 'Salvar no LocalStorage',
        SaveInSessionStorage: 'Salvar em SessionStorage',
        InvoiceNumber: 'Número da fatura',
        AccountNumber: 'Número de conta',
        General: 'Em geral',
        Authorization: 'Autorização',
        Languages: 'Línguas',
        New: 'Novo',
        Active: 'Ativo',
        Inactive: 'Inativo',
        WaitForActivation: 'Aguarde a ativação',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Baixo',
        Medium: 'Médio',
        High: 'Alto',
        Hidden: 'Escondido',
        Brand: 'Marca',
        Group: 'Grupo',
        ChangeStatus: 'Alterar status',
        AuthMail: 'Correio de autenticação',
        SetDomainSettings: 'Definir configurações de domínio',
        Domain: 'Domínio*',
        Imap: 'Imap (hospedeiro:porta)*',
        SmtpTLS: 'Smtp TLS (hospedeiro:porta)*',
        SmtpSSL: 'Smtp SSL (hospedeiro:porta)*',
        AttachFile: 'Anexar arquivo',
        ChannelSettings: 'Configurações do canal',
        GeneralSettings: 'Configurações Gerais',
        WebChatConstructor: 'Construtor de bate-papo na Web',
        BackgroundColor: 'Cor de fundo',
        MessageColor: 'Cor da mensagem',
        URL: 'URL',
        ChannelName: 'Nome do canal',
        ChannelURL: 'URL do canal',
        AllowSMS: 'Permitir SMS',
        AllowShowAllClientDialogs: 'Permitir mostrar todas as caixas de diálogo do cliente',
        AllowCaptcha: 'Permitir Captcha',
        InboxSettings: 'Configurações da caixa de entrada',
        DistributionOfNewDialogs: 'Distribuição de novos diálogos entre agentes online',
        ThereAreNoAvailableMessengers: 'Não há mensageiros disponíveis para o canal! Por favor, crie-os para a marca que possui este canal!',
        HeaderColorScheme: 'Esquema de cores do cabeçalho',
        GreetingColorScheme: 'Esquema de cores de saudação',
        QuickPhrases: 'Esquema de cores de frases rápidas',
        ClientMessage: 'Esquema de cores da mensagem do cliente',
        AgentMessage: 'Esquema de cores da mensagem do agente',
        EndDialogModal: 'Esquema de cores modal da caixa de diálogo final',
        IconColorScheme: 'Esquema de cores do ícone',
        ScrollColorScheme: 'Esquema de cores de rolagem',
        ChatWindowColor: 'Esquema de cores da janela de bate-papo',
        FooterColorScheme: 'Esquema de cores do rodapé',
        AuthColorScheme: 'Esquema de cores de autenticação',
        HeaderBackground: 'Plano de fundo do cabeçalho',
        HeaderBorderColor: 'Cor da borda do cabeçalho',
        ColorGreetingBotName: 'Cor do nome do bot de saudação',
        MessageGreetingIcon: 'Fundo do ícone de saudação',
        MessageGreetingColor: 'Cor do texto de saudação',
        MessageGreetingBorderColor: 'Cor da borda da mensagem de saudação',
        MessageGreetingBackgroundColor: 'Cor de fundo da mensagem de saudação',
        QuickPhrasesColor: 'Cor do texto da frase rápida',
        QuickPhrasesBackground: 'Frase rápida de fundo',
        QuickPhrasesBorderColor: 'Cor da borda da frase rápida',
        QuickPhrasesColorHover: 'Cor do hover com frase rápida',
        QuickPhrasesBackgroundHover: 'Passagem de fundo de frase rápida',
        QuickPhrasesBorderColorHover: 'Passagem rápida da cor da borda da frase',
        ClientMessageColor: 'Cor do texto da mensagem do cliente',
        ClientTimeColor: 'Cor do horário do cliente',
        ClientMessageBackground: 'Cor de fundo da mensagem do cliente',
        ClientMessageBorderColor: 'Cor da borda da mensagem do cliente',
        ClientLink: 'Link do cliente',
        ClientLinkHover: 'Passagem do link do cliente',
        ClientActiveLink: 'Link ativo do cliente',
        ClientVisitedLink: 'Link visitado pelo cliente',
        ColorAgentNameAndTime: 'Nome do agente e cor da hora',
        MessageAgentColor: 'Cor da mensagem do agente',
        MessageAgentBackgroundColor: 'Plano de fundo da mensagem do agente',
        MessageAgentBorderColor: 'Cor da borda da mensagem do agente',
        AgentLink: 'Link do agente',
        AgentHoverLink: 'Link de passagem do agente',
        AgentActiveLink: 'Link ativo do agente',
        AgentVisitedLink: 'Link do agente visitado',
        MessageIconBackgroundAgent: 'Cor de fundo do ícone da mensagem do agente',
        TextColor: 'Cor do texto',
        BackdropColor: 'Cor do pano de fundo',
        BackdropColorHover: 'Passar a cor do pano de fundo',
        ButtonColor: 'Cor do botao',
        BackdropColorBtn: 'Cor de fundo do botão Pano de fundo',
        IconBackground: 'Plano de fundo do ícone',
        ChatWindowsBodyBackground: 'Cor de fundo do corpo das janelas de bate-papo',
        ChatWindowsBorderColor: 'Cor da borda das janelas de bate-papo',
        FooterBackground: 'Cor de fundo do rodapé',
        FooterBorderColor: 'Cor da borda do rodapé',
        FooterBackgroundTextArea: 'Cor de fundo da área de texto do rodapé',
        InputBorderColor: 'Cor da borda de entrada',
        RegistrationBackgroundColor: 'Cor de fundo do registro',
        LabelColor: 'Cor do rótulo',
        LabelErrorColor: 'Cor do erro do rótulo',
        InputColor: 'Cor de entrada',
        InputBackground: 'Inserir plano de fundo',
        SignUpColor: 'Cor de inscrição',
        SignUpBackground: 'Cor de fundo da inscrição',
        Chat: 'Bater papo',
        Minimized: 'Minimizado',
        ScrollBackground: 'Rolar plano de fundo',
        ScrollThumbBackground: 'Rolar o fundo do polegar',
        UniqueClientIdentifier: 'Identificador exclusivo do cliente',
        AgentsSettings: 'Configurações de agentes',
        ThisAgentSuperAdmin: 'Este agente é superadministrador, clique no botão para remover os direitos de superadministrador',
        ThisAgentNotSuperAdmin: 'Este agente não é um super administrador, clique no botão para torná-lo super administrador',
        YouCanAssignBrandTheBrand: 'Você pode atribuir a marca do administrador para isso, você precisa selecionar a marca',
        Role: 'Função',
        BanningThisAgent: 'Banir este agente significa que ele não poderá fazer login na plataforma. Eles ainda vão aparecer no sistema.',
        Report: 'Relatório',
        SelectLanguageWebChat: 'Selecionar idiomas Webchat',
        FirstName: 'Primeiro nome',
        PhoneNumber: 'Número de telefone',
        Info: 'Informações',
        WebChatSettings: 'Configurações de bate-papo na Web',
        IntegrationWithOtherCRM: 'Integração com outros CRM',
        SettingUpInteractionBetweenSystems: 'Configurando a interação entre os sistemas',
        QuickReplies: 'Respostas rápidas',
        BotSettings: 'Configurações de bot',
        BotName: 'Nome do bot',
        EnabledPrivacyPolicy: 'Política de privacidade ativada',
        PleaseChooseRequiredFields: 'Por favor, escolha os campos obrigatórios',
        ChangeGreetingsAndPhrases: 'Alterar saudações e frases, política de privacidade',
        ChangeSmsSettings: 'Alterar configurações de sms',
        ChangeCountries: 'Alterar países',
        ContactsAreWorkingWithClientUniqueness: 'Os contatos estão trabalhando com exclusividade do cliente. O campo é obrigatório se a exclusividade do cliente for igual a ele.',
        ClientUniquenessIs: 'A exclusividade do cliente é:',
        PrivacyPolicyText: 'Texto da política de privacidade',
        PrivacyPolicyLink: 'Link da política de privacidade',
        PrivacyPolicyLinkText: 'Texto do link da política de privacidade',
        AgentPhone: 'Telefone do agente',
        HeaderSettings: 'Configurações de cabeçalho',
        DisableDraggable: 'Desativar arrastável',
        DisableHeaderFullScreen: 'Desativar cabeçalho em tela cheia',
        DisableHeaderHideChatWindow: 'Desabilitar ocultar janela de bate-papo do cabeçalho',
        DisableHeaderLogo: 'Desativar logotipo do cabeçalho',
        DisableHeaderShowEndDialogModal: 'Desabilitar o modal de diálogo de fim de exibição de cabeçalho',
        HeaderText: 'Texto do cabeçalho',
        HeaderTextTop: 'Parte superior do texto do cabeçalho',
        HeaderTextBottom: 'Parte inferior do texto do cabeçalho',
        HeaderHeight: 'Altura do cabeçalho',
        LogoIconUrl: 'URL do ícone do logotipo',
        IconUrl: 'URL do ícone',
        IconSettings: 'Configurações do ícone',
        ScrollSettings: 'Configurações de rolagem',
        ScrollWidth: 'Largura da rolagem',
        ChatWindowsLogo: 'Logotipo do bate-papo',
        BackgroundImageSize: 'Tamanho da imagem de fundo',
        GreetingSettings: 'Configurações de saudação',
        GreetingIconBotUrl: 'URL do bot do ícone de saudação',
        QuickPhrasesSettings: 'Configurações de frases rápidas',
        ClientMessageSettings: 'Configurações de mensagem do cliente',
        AgentMessageSettings: 'Configurações de mensagem do agente',
        AgentMessageIconUrl: 'URL do ícone da mensagem do agente',
        EndDialogModalSettings: 'Finalizar configurações modais da caixa de diálogo',
        AuthWindowSettings: 'Configurações da janela de autenticação',
        FooterSettings: 'Configurações de rodapé',
        WriteMessagesPlaceholder: 'Escreva o espaço reservado para mensagens',
        SendImageUrl: 'Enviar URL da imagem',
        SendButtonIconHeight: 'Enviar altura do ícone do botão',
        SendButtonIconWidth: 'Largura do ícone do botão de envio',
        ChatWindowSettings: 'Configurações da janela de bate-papo',
        ClearAllPhrase: 'Limpar toda a frase',
        AreYouSure: 'Tem certeza?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Esta ação removerá todas as frases e todas as categorias',
        AddAgentToGroup: 'Adicionar agentes a um grupo',
        CreateClient: 'Criar cliente',
        ClientsImport: 'Importação de clientes',
        UploadExcelFile: 'Carregar arquivo Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Clique no ícone do clipe de papel para selecionar o arquivo Excel',
        Contacts: 'Contatos',
        Personal: 'Pessoal',
        FileUploadHint: 'Faça o upload de um arquivo Excel preenchido com números de telefone. O arquivo Excel deve conter uma coluna chamada “Telefones” ' +
            'e os números de telefone devem ser inseridos nesta coluna. Somente arquivos .xls ou .xlsx podem ser importados.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Alterar saudações e frases, política de privacidade',
    },
    AdditionalText: {
        AgentProfile: 'Perfil do agente',
        OutOf: 'de',
    },
    Pages: {
        Dialogs: {
            Title: 'Diálogos',
            TableColumns: {
                Status: 'Status',
                Dialog: 'Diálogo',
                Messenger: 'Mensageiro',
                Channel: 'Canal',
                Tag: 'Marcação',
                Agent: 'Agente',
                Rating: 'Avaliação',
                Language: 'Língua',
                MessagesCount: 'Contagem de mensagens',
                FirstMessage: 'Primeira Mensagem',
                LastActivity: 'ultima atividade',
            },
            Messages: 'Mensagens',
        },
        AgentGroups: {
            Title: 'Grupos de agentes',
            TableColumns: {
                Groups: 'Grupos',
                Description: 'Descrição',
                AccessLevel: 'Nível de acesso',
                WhiteListTags: 'Tags de lista branca',
                CreatedAt: 'Criado em',
                UpdatedAt: 'Atualizado em',
            },
        },
        Agents: {
            Title: 'Agentes',
            TableColumns: {
                AgentStatus: 'Status do Agente',
                Agents: 'Agentes',
                Email: 'Email',
                Groups: 'Grupos',
                CreatedAt: 'Criado em',
                LastActivity: 'Ultima atividade',
            },
        },
        Tags: {
            Title: 'Marcações',
            TableColumns: {
                Tags: 'Marcações',
                Dialogs: 'Diálogos',
                Agents: 'Agentes',
                UsefulLinks: 'Links Úteis',
                Status: 'Status',
                CreatedAt: 'Criado em',
                UpdatedAt: 'Atualizado em',
            },
        },
        Clients: {
            Title: 'Clientes',
            TableColumns: {
                SearchName: 'nome',
                AgentName: 'Nome do agente',
                Messenger: 'Mensageiro',
                LastTag: 'Última etiqueta',
                ActiveDialog: 'Caixa de diálogo ativa',
                Brand: 'Marca',
                InternalId: 'Id interno',
                ExternalId: 'Id externo',
                Name: 'Nome do cliente',
                FirstName: 'Primeiro nome',
                LastName: 'Último nome',
                Email: 'Email',
                InvoiceNumber: 'Número da fatura',
                DialogsCount: 'Contagem de diálogos',
                FirstMessage: 'Primeira Mensagem',
                LastActivity: 'ultima atividade',
                Tag: 'Marcação',
                Status: 'Status',
                Channel: 'Canal',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Canais',
            TableColumns: {
                ChannelId: 'Id do canal',
                ChannelKey: 'Chave do canal',
                ChannelName: 'Nome do canal',
                Language: 'Língua',
                Url: 'Url',
                CreatedAt: 'Criado em',
                UpdatedAt: 'Atualizado em',
            },
        },
        Brands: {
            Title: 'Marcas',
            TableColumns: {
                BrandsId: 'ID da marca',
                BrandName: 'Marca',
                ClientUniqueness: 'Exclusividade do cliente',
                BrandChannels: 'Canais de marca',
                DialogCount: 'Contagem de diálogos',
                CreatedAt: 'Criado em',
                UpdatedAt: 'Atualizado em',
            },
        },
        Reports: {
            Title: 'Relatórios',
            ByMessengersTab: {
                Title: 'Relatório por mensageiros',
                Columns: {
                    Messenger: 'Mensageiro',
                    ReceivedDialogs: 'Diálogos recebidos',
                    PositiveDialogs: 'Diálogos positivos, %',
                    NegativeDialogs: 'Diálogos negativos, %',
                    UnratedDialogs: 'Caixas de diálogo sem classificação, %',
                    AvgResponseTime: 'Tempo médio de resposta',
                },
            },
            ByAgentsTab: {
                Title: 'Relatório por agentes',
                Columns: {
                    agentFullName: 'Nome completo do agente',
                    agentSystemId: 'ID do sistema do agente',
                    brandName: 'Marca',
                    receivedDialogs: 'Caixas de diálogo recebidas',
                    dialogsTelegram: 'Diálogos Telegram',
                    dialogsWebChat: 'Diálogos Web Chat',
                    dialogsViber: 'Diálogos Viber',
                    dialogsWhatsApp: 'Diálogos WhatsApp',
                    dialogsFacebook: 'Diálogos Facebook',
                    dialogsEmail: 'Diálogos Email',
                    dialogsSms: 'Diálogos SMS',
                    positiveDialogs: 'Diálogos positivos, %',
                    negativeDialogs: 'Diálogos negativos, %',
                    unratedDialogs: 'Caixas de diálogo sem classificação, %',
                    avgResponseTime: 'Tempo médio de resposta',
                },
                noDataForThisDay: 'Sem dados para este dia',
            },
            ByGroupsTab: {
                Title: 'Relatório por grupos',
                Columns: {
                    groupSystemId: 'ID do sistema do grupo',
                    groupName: 'Nome do grupo',
                    brandName: 'Marca',
                    receivedDialogs: 'Diálogos recebidos',
                    positiveDialogs: 'Diálogos positivos, %',
                    negativeDialogs: 'Diálogos negativos, %',
                    unratedDialogs: 'Caixas de diálogo sem classificação, %',
                    avgResponseTime: 'Tempo médio de resposta',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Adicionar filtro',
        reset: 'Redefinir',
        createClient: 'Criar cliente',
        importClient: 'Importar clientes',
        createChannel: 'Criar canal',
        previous: 'Anterior',
        next: 'Próximo',
        addPhrase: 'Adicionar frase',
        addGroup: 'Adicionar grupo',
        createBrand: 'Criar marca',
        addTag: 'Adicionar etiqueta',
        save: 'Salve ',
        send: 'Mandar',
        getStatistics: 'Obter estatísticas',
        setCountries: 'Definir países',
        changeStatus: 'Alterar status',
        upload: 'Envio',
        setDomainSettings: 'Definir configurações de domínio',
        login: 'Conecte-se',
        update: 'Atualizar',
        resetPassword: 'Redefinir senha',
        addNew: 'Adicionar novo',
        addNewPhrase: 'Adicionar nova frase',
        addNewCategory: 'Adicionar nova categoria',
        clearAll: 'Limpar tudo',
        loginWithGoogle: 'Entrar com o Google',
        loginWithFacebook: 'Entrar com o Facebook',
        onPage: 'na página',
        addNewUsefulLinks: 'Adicionar novos links úteis',
        backToChannels: 'Voltar aos canais',
        defaultSettings: 'Configurações padrão',
        upgradeChannel: 'Atualizar canal',
        apply: 'Aplicar',
        remove: 'Remover',
        removeSuperAdmin: 'Remover superadministrador',
        makeSuperAdmin: 'Tornar superadministrador',
        makeBrandAdmin: 'Tornar administrador da marca',
        removeBrandAdmin: 'Remover administrador de marca',
        ban: 'Ban',
        unban: 'Desbanir',
        makeTeamLead: 'Fazer líder de equipe',
        removeTeamLead: 'Remover líder de equipe',
        signUp: 'Inscrever-se',
        ok: 'Ok',
        cancel: 'Cancelar',
        bulkMessaging: 'Mensagens em massa',
        back: 'De volta',
    },
    Countries: {
        UnitedStates: 'Estados Unidos',
        Canada: 'Canadá',
        Australia: 'Austrália',
        CzechRepublic: 'República Checa',
        Denmark: 'Dinamarca',
        Hungary: 'Hungria',
        Netherlands: 'Países Baixos',
        Sweden: 'Suécia',
        UnitedKingdom: 'Reino Unido',
        France: 'França',
        Germany: 'Alemanha',
        Poland: 'Polônia',
        Italy: 'Itália',
        Spain: 'Espanha',
    },
    Languages: {
        Ukrainian: 'Ucraniano',
        Russian: 'Russo',
        English: 'Inglês',
        Polish: 'Polonês',
        Deutsch: 'Alemão',
        Spanish: 'Espanhol',
        Vietnamese: 'Vietnamita',
        Portuguese: 'Português',
        Korean: 'Coreano',
        Italian: 'Italiano',
        Arabic: 'árabe',
        French: 'Francês',
        Azerbaijan: 'Azerbaijão',
        Hungarian: 'Húngara',
    },
    BulkMessaging: {
        exclude: 'Excluir',
        typeText: 'Digite o texto',
        selectClients: 'Selecionar clientes',
        uploadClients: 'Carregar clientes',
        sendingMessagesToNewClients: 'Envio de mensagens para novos clientes',
        sendingMessagesToExistingClients: 'Enviando mensagens para clientes existentes',
        clientsWhoHaveDialogsWithFollowingStatus: 'Clientes, que têm diálogos com o seguinte status',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Clientes, que têm este texto em nome ou sobrenome ou nome de usuário',
        limitQuantity: 'Quantidade limite',
        quantityLimit: 'Limite de quantidade',
        limitQuantityText: 'Limite a quantidade na transmissão a não mais que o número especificado de destinatários. Clientes mais novos do sistema entrarão na lista.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Clientes que escreveram para o AskMe antes da hora da última mensagem',
    },
};
export default Messages;
