import React from 'react';
import { connect } from 'react-redux';
import { tableOptionsActions } from '@src/redux/tableOptions/reducer';
import { Messages } from '@public/locales/messages';
import { Pagination } from './Pagination/Pagination';
import { SizeChanger } from './SizeChanger/SizeChanger';
import { StyledPaginationContainer } from './StyledPaginationContainer';
var PaginationContainer = function (_a) {
    var pagination = _a.pagination, rowsCount = _a.rowsCount, isLoading = _a.isLoading, updatePagination = _a.updatePagination;
    var offset = pagination.offset, count = pagination.count, overallCount = pagination.overallCount, showSizeChanger = pagination.showSizeChanger;
    var currentDataView = {
        from: ((offset - 1) * count) + 1,
        to: (offset - 1) * count + rowsCount,
    };
    return (React.createElement(StyledPaginationContainer, null,
        React.createElement("div", { className: "left-side-pagination" },
            React.createElement("div", null, "".concat(currentDataView.from, " - ").concat(currentDataView.to, " ").concat(Messages.AdditionalText.OutOf, " ").concat(overallCount))),
        React.createElement("div", { className: "right-side-pagination" },
            React.createElement(Pagination, { pagination: pagination, isLoading: isLoading, updatePagination: updatePagination }),
            showSizeChanger
                && (React.createElement(SizeChanger, { pagination: pagination, updatePagination: updatePagination })))));
};
var mapDispatchToProps = function (dispatch) { return ({
    updatePagination: function (pagination) { return dispatch(tableOptionsActions.updatePagination(pagination)); },
}); };
export default connect(null, mapDispatchToProps)(PaginationContainer);
