export default (function (url) {
    if (url === void 0) { url = ''; }
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    var obj = {};
    if (queryString) {
        var arr = queryString.split('&');
        var _loop_1 = function (i) {
            var a = arr[i].split('=');
            var paramNum;
            var paramName = a[0].replace(/\[\d*\]/, function (v) {
                paramNum = v.slice(1, -1);
                return '';
            });
            obj[paramName] = typeof (a[1]) === 'undefined' ? true : a[1];
        };
        for (var i = 0; i < arr.length; i++) {
            _loop_1(i);
        }
    }
    return obj;
});
