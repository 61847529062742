var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { icons } from '@public/images';
import { connect } from 'react-redux';
import { Messages } from '@public/locales/messages';
import { isSpaces, isRequired, lengthCreator, nameValidation, isCorrectEmail, composeValidators, passwordValidation, } from '@src/utils/validations';
import { authActions } from '@src/modules/Auth/redux/reducer';
import Input from '@src/components/Input';
import Space from '@src/components/Space';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledAuthButton } from '@src/components/Button/styled';
import { StyledAuthInput } from '@src/modules/Auth/styled';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
var AuthForm = function (_a) {
    var isLoginForm = _a.isLoginForm, registerLocal = _a.registerLocal, loginLocal = _a.loginLocal;
    var maxEmailLength = lengthCreator.maxEmailLength, maxNameLength = lengthCreator.maxNameLength, maxPasswordLength = lengthCreator.maxPasswordLength, minEmailLength = lengthCreator.minEmailLength, minNameLength = lengthCreator.minNameLength, minPasswordLength = lengthCreator.minPasswordLength;
    var onSubmit = function (data) {
        if (isLoginForm)
            loginLocal(data);
        if (!isLoginForm)
            registerLocal(data);
    };
    var _b = __read(useState({
        password: 'password',
        repeatPassword: 'password',
    }), 2), passwordInput = _b[0], setPasswordInput = _b[1];
    var showPassword = function (inputName) {
        var _a;
        var value = passwordInput[inputName] === 'text' ? 'password' : 'text';
        setPasswordInput(__assign(__assign({}, passwordInput), (_a = {}, _a[inputName] = value, _a)));
    };
    var passwordMatching = function (values) {
        var errors = {};
        if (!isLoginForm && values.password !== values.repeatPassword) {
            Object.assign(errors, { repeatPassword: Messages.Errors.PasswordMatching });
        }
        return errors;
    };
    var AuthInput = InputWithStyles(Input, StyledAuthInput);
    return (React.createElement(Form, { onSubmit: onSubmit, validate: passwordMatching, render: function (_a) {
            var handleSubmit = _a.handleSubmit, submitting = _a.submitting, pristine = _a.pristine;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Field, { name: "email", component: AuthInput, placeholder: Messages.Controls.Email, validate: composeValidators(isSpaces, isRequired, isCorrectEmail, minEmailLength, maxEmailLength) }),
                !isLoginForm
                    && (React.createElement(Field, { name: "name", component: AuthInput, placeholder: Messages.Controls.Name, validate: composeValidators(isRequired, nameValidation, minNameLength, maxNameLength) })),
                React.createElement(Field, { name: "password", type: passwordInput.password, component: AuthInput, placeholder: Messages.Controls.Password, validate: composeValidators(isSpaces, isRequired, passwordValidation, minPasswordLength, maxPasswordLength), icon: icons.showPassword, iconOnclick: showPassword }),
                !isLoginForm
                    && (React.createElement(Field, { name: "repeatPassword", type: passwordInput.repeatPassword, component: AuthInput, placeholder: Messages.Controls.RepeatPassword, validate: composeValidators(isSpaces, isRequired, passwordValidation, minPasswordLength, maxPasswordLength), icon: icons.showPassword, iconOnclick: showPassword })),
                React.createElement(StyledAuthButton, null,
                    React.createElement(Button, { type: TButtonTypes.submit, title: isLoginForm ? Messages.Controls.Login : Messages.Controls.Register, disabled: submitting || pristine, onClick: function () { } })),
                React.createElement(Space, { text: Messages.Controls.Or })));
        } }));
};
var mapDispatchToProps = function (dispatch) { return ({
    registerLocal: function (data) { return dispatch(authActions.registerLocal(data)); },
    loginLocal: function (data) { return dispatch(authActions.loginLocal(data)); },
}); };
export default connect(null, mapDispatchToProps)(AuthForm);
