var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var GREEN_DARKER = COLORS.GREEN_DARKER, ORANGE_HOT = COLORS.ORANGE_HOT, BLUE_VIVID = COLORS.BLUE_VIVID, YELLOW = COLORS.YELLOW;
export var StyledProgressBar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: max-content;\n"], ["\n  width: 100%;\n  height: max-content;\n"])));
export var Inner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 10px;\n  background: #e1e4e8;\n  border-radius: 3px;\n  overflow: hidden;\n\n  @keyframes progress-animation {\n    0% {\n      background-position: 100%;\n    }\n    100% {\n      background-position: 0;\n    }\n  }\n\n  .progress-bar {\n    display: block;\n    height: 100%;\n    background: linear-gradient(\n      90deg,\n      ", ",\n      ", " 17%,\n      ", " 34%,\n      ", " 51%,\n      ", " 68%,\n      ", " 85%,\n      ", "\n    );\n    background-size: 300% 100%;\n    animation: ", ";\n    transition: 0.5s ease-in-out;\n  }\n"], ["\n  width: 100%;\n  height: 10px;\n  background: #e1e4e8;\n  border-radius: 3px;\n  overflow: hidden;\n\n  @keyframes progress-animation {\n    0% {\n      background-position: 100%;\n    }\n    100% {\n      background-position: 0;\n    }\n  }\n\n  .progress-bar {\n    display: block;\n    height: 100%;\n    background: linear-gradient(\n      90deg,\n      ", ",\n      ", " 17%,\n      ", " 34%,\n      ", " 51%,\n      ", " 68%,\n      ", " 85%,\n      ", "\n    );\n    background-size: 300% 100%;\n    animation: ", ";\n    transition: 0.5s ease-in-out;\n  }\n"])), ORANGE_HOT, BLUE_VIVID, GREEN_DARKER, YELLOW, ORANGE_HOT, BLUE_VIVID, GREEN_DARKER, function (_a) {
    var isFinished = _a.isFinished;
    return (isFinished ? 'none' : 'progress-animation 2s linear infinite');
});
export var DownloadButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 20px;\n  border-radius: 4px;\n  cursor: ", ";\n  img {\n    width: 20px;\n    filter: ", ";\n  }\n"], ["\n  margin-left: 20px;\n  border-radius: 4px;\n  cursor: ", ";\n  img {\n    width: 20px;\n    filter: ", ";\n  }\n"])), function (_a) {
    var isFinished = _a.isFinished;
    return (isFinished ? 'pointer' : 'wait');
}, function (_a) {
    var theme = _a.theme;
    return theme.blackInvert;
});
var templateObject_1, templateObject_2, templateObject_3;
