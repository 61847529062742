var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { AllAgentSettings } from '@src/global-interfaces';
import Loader from '@src/components/Loader';
import { Tabs, Tab } from '@src/components/Tabs/Tabs';
import BanAgent from '@src/modules/Pages/Agents/AgentSettings/BanAgent';
import TeamLead from '@src/modules/Pages/Agents/AgentSettings/TeamLead';
import AgentInfo from '@src/modules/Pages/Agents/AgentSettings/AgentInfo';
import AgentRoles from '@src/modules/Pages/Agents/AgentSettings/AgentRoles';
import AgentToTags from '@src/modules/Pages/Agents/AgentSettings/AgentToTags';
import AgentToGroups from '@src/modules/Pages/Agents/AgentSettings/AgentToGroups';
import { StyledAgentSettingWrapper } from '@src/modules/Pages/Agents/AgentSettings/styled';
var AgentSettings = function (props) {
    var isLoading = props.isLoading;
    var _a = __read(useState(AllAgentSettings.roles), 2), currentTab = _a[0], setCurrentTab = _a[1];
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    return (React.createElement(StyledAgentSettingWrapper, null,
        React.createElement(Tabs, { callback: onTabChangeHandler, currentTab: currentTab, isShowBottomLine: true },
            React.createElement(Tab, { tabKey: AllAgentSettings.roles, title: AllAgentSettings.roles }, isLoading ? (React.createElement(Loader, null)) : (React.createElement(AgentRoles, null))),
            React.createElement(Tab, { tabKey: AllAgentSettings.groups, title: AllAgentSettings.groups }, isLoading ? (React.createElement(Loader, null)) : (React.createElement(AgentToGroups, null))),
            React.createElement(Tab, { tabKey: AllAgentSettings.teamLead, title: AllAgentSettings.teamLead }, isLoading ? (React.createElement(Loader, null)) : (React.createElement(TeamLead, null))),
            React.createElement(Tab, { tabKey: AllAgentSettings.tags, title: AllAgentSettings.tags }, isLoading ? (React.createElement(Loader, null)) : (React.createElement(AgentToTags, null))),
            React.createElement(Tab, { tabKey: AllAgentSettings.info, title: AllAgentSettings.info }, isLoading ? (React.createElement(Loader, null)) : (React.createElement(AgentInfo, null))),
            React.createElement(Tab, { tabKey: AllAgentSettings.ban, title: AllAgentSettings.ban }, isLoading ? (React.createElement(Loader, null)) : (React.createElement(BanAgent, null))))));
};
export default AgentSettings;
