import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import UpdateGroups from './UpdateGroups';
var mapStateToProps = function (state) { return ({
    currentGroup: groupsStore.getCurrentGroup(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    updateCurrentGroup: function (data) { return dispatch(groupsActions.updateCurrentGroup(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroups);
