import React from 'react';
export var TimeRenderer = function (secondsCount) {
    var hours = Math.floor(secondsCount / 60 / 60);
    var minutes = Math.floor(secondsCount / 60) - (hours * 60);
    var seconds = secondsCount % 60;
    var isHours = hours ? "".concat(hours, " h, ") : '';
    var isMinutes = minutes ? "".concat(minutes, " m, ") : '';
    var isSeconds = seconds ? "".concat(seconds, " s") : '';
    var result = isHours + isMinutes + isSeconds;
    return React.createElement("span", null, result || 0);
};
export default TimeRenderer;
