var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useMemo, useState, } from 'react';
import { Messages } from '@public/locales/messages';
import { notificationTypes } from '@src/global-interfaces';
import { isRequired } from '@src/utils/validations';
import Select from '@src/components/Select';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import ErrorMessage from '@src/components/ErrorMessage';
import { StyledAddNewBtn, StyledSaveBtn } from '@src/components/Button/styled';
import { StyledLabelTextarea, StyledPhraseWrapper, StyledOrAddCategoryBox, StyledTextarea, } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalUsefulPhrase/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var UsefulPhraseModal = function (props) {
    var _a;
    var _b = props.isEdit, isEdit = _b === void 0 ? false : _b, _c = props.phraseId, phraseId = _c === void 0 ? '' : _c, _d = props.categoryId, categoryId = _d === void 0 ? '' : _d, currentGroup = props.currentGroup, updatePhrase = props.updatePhrase, createNewPhrase = props.createNewPhrase, showNotification = props.showNotification, setIsOpenTwoModal = props.setIsOpenTwoModal, toggleModalNewCategory = props.toggleModalNewCategory, resetNotificationState = props.resetNotificationState, isLoadingUsefulPhrases = props.isLoadingUsefulPhrases, setIsOpeSomethingModal = props.setIsOpeSomethingModal;
    var mapCategoriesForSelect = function (categories) { return categories === null || categories === void 0 ? void 0 : categories.map(function (el) { return ({ text: el.name, value: el.id }); }); };
    var memoizedCategories = useMemo(function () { return mapCategoriesForSelect(currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.usefulPhrases); }, [currentGroup]);
    var defaultCategory = useMemo(function () {
        var _a;
        var currentCategory = (_a = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.usefulPhrases) === null || _a === void 0 ? void 0 : _a.find(function (el) { return el.id === categoryId; });
        if (!currentCategory)
            return undefined;
        return [{ text: currentCategory.name, value: currentCategory.id }];
    }, [currentGroup]);
    var defaultText = useMemo(function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.usefulPhrases) === null || _a === void 0 ? void 0 : _a.find(function (el) { return el.id === categoryId; })) === null || _b === void 0 ? void 0 : _b.phrases.find(function (el) { return el.id === phraseId; })) === null || _c === void 0 ? void 0 : _c.text;
    }, [currentGroup]);
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var _e = __read(useState(defaultCategory ? (_a = defaultCategory[0]) === null || _a === void 0 ? void 0 : _a.value : ''), 2), categories = _e[0], setCategories = _e[1];
    var _f = __read(useState(defaultText || ''), 2), phraseText = _f[0], setPhraseText = _f[1];
    var _g = __read(useState(false), 2), isErrorText = _g[0], setIsErrorText = _g[1];
    var _h = __read(useState(false), 2), isCategories = _h[0], setIsCategories = _h[1];
    var onChange = useCallback(function (e) {
        if (!isRequired(phraseText))
            setIsErrorText(false);
        setPhraseText(e.target.value);
    }, [phraseText]);
    var onChangeSelectAgent = useCallback(function (data) {
        setCategories(data.value);
        setIsCategories(false);
    }, [categories]);
    var onChangePhrase = useCallback(function () {
        if (isRequired(phraseText)) {
            showWarning();
            setIsErrorText(!!isRequired(phraseText));
        }
        if (!categories) {
            showWarning();
            setIsCategories(true);
        }
        if (isRequired(phraseText) || !categories)
            return;
        if (!isEdit)
            createNewPhrase({ text: phraseText, categoryId: categories });
        if (isEdit) {
            updatePhrase({ text: phraseText, id: phraseId, categoryId: categories });
            setIsOpenTwoModal(false);
        }
        setPhraseText('');
    }, [categories, phraseText]);
    return (React.createElement(StyledPhraseWrapper, null, isLoadingUsefulPhrases ? (React.createElement(Loader, null)) : (React.createElement(StyledModalContentWrapper, null,
        React.createElement(StyledModalContentFieldsWrapper, null,
            React.createElement(StyledLabelTextarea, null, Messages.Labels.Phrase),
            React.createElement(StyledTextarea, { onChange: onChange, value: phraseText, isErrorText: isErrorText }),
            isErrorText && (React.createElement(ErrorMessage, { text: isRequired(phraseText) })),
            React.createElement(Select, { initialState: defaultCategory || [], items: memoizedCategories, isMultiply: false, error: isCategories, label: Messages.Labels.SelectCategory, placeholder: Messages.Selects.SelectChannels, stateCB: function (el) { return onChangeSelectAgent(el); } }),
            React.createElement(StyledOrAddCategoryBox, null,
                React.createElement("div", { className: "or-add-new-category-box-text" }, Messages.Labels.Or),
                React.createElement(StyledAddNewBtn, null,
                    React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addNewCategory), onClick: function () {
                            setIsOpenTwoModal(true);
                            setIsOpeSomethingModal(true);
                            toggleModalNewCategory();
                        } })))),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.save, onClick: onChangePhrase })))))));
};
export default UsefulPhraseModal;
