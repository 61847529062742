import { connect } from 'react-redux';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { agentsActions } from '@src/modules/Pages/Agents/redux/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import AgentInfo from './AgentInfo';
var mapStateToProps = function (state) { return ({
    currentAgent: agentsStore.getCurrentAgent(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    changeAgentInfo: function (data) { return dispatch(agentsActions.changeAgentInfo(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AgentInfo);
