import React from 'react';
import { SessionStatus } from '@src/global-interfaces';
import { SessionStatuses } from '@src/modules/Pages/Reports/AgentReport/components/SessionStatuses';
import { DayBlockWrapper } from '@src/modules/Pages/Reports/AgentReport/components/styled';
export var DayBlock = function (_a) {
    var setAgentReportBlockDay = _a.setAgentReportBlockDay, date = _a.date, timeBySessionStatuses = _a.timeBySessionStatuses, checkedReportDayDate = _a.checkedReportDayDate, checkedStatuses = _a.checkedStatuses;
    return (React.createElement(DayBlockWrapper, { isChecked: checkedReportDayDate === date, onClick: setAgentReportBlockDay },
        React.createElement("div", { className: "day-block__day" },
            React.createElement("span", null, date)),
        React.createElement("div", { className: "day-block__time-by-status" },
            checkedStatuses.online && (React.createElement("div", null,
                React.createElement(SessionStatuses, { size: "small", status: SessionStatus.online, title: timeBySessionStatuses.online }))),
            checkedStatuses.break && (React.createElement("div", null,
                React.createElement(SessionStatuses, { size: "small", status: SessionStatus.break, title: timeBySessionStatuses.break }))),
            checkedStatuses.offline && (React.createElement("div", null,
                React.createElement(SessionStatuses, { size: "small", status: SessionStatus.offline, title: timeBySessionStatuses.offline }))))));
};
