var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from 'react';
import moment from 'moment';
export var useAgentReport = function (_a) {
    var agentId = _a.agentId, getAgentReports = _a.getAgentReports, clearAgentReports = _a.clearAgentReports, clearCheckedReportBlockDay = _a.clearCheckedReportBlockDay;
    var _b = __read(useState({
        online: true,
        break: true,
        offline: true,
    }), 2), checkedStatuses = _b[0], setCheckedStatuses = _b[1];
    var _c = __read(useState(false), 2), isReportsLading = _c[0], setIsReportsLading = _c[1];
    var handleSetChecked = function (status) {
        var _a;
        setCheckedStatuses(__assign(__assign({}, checkedStatuses), (_a = {}, _a[status] = !checkedStatuses[status], _a)));
    };
    var currentYear = moment().format('YYYY');
    var currentMonth = moment().format('MMMM');
    var _d = __read(useState(currentYear), 2), checkedYear = _d[0], setCheckedYear = _d[1];
    var _e = __read(useState(currentMonth), 2), checkedMonth = _e[0], setCheckedMonth = _e[1];
    var getYears = function () {
        var startYear = 2017;
        var yearsCount = +currentYear - +startYear + 1;
        return Array.from({ length: yearsCount }, function (v, i) {
            var year = (startYear + i).toString();
            return { value: year, text: year };
        }).reverse();
    };
    var getMonths = function () { return moment.months().map(function (month) { return ({ value: month, text: month }); }); };
    var getAgentReport = function () {
        var date = moment("".concat(checkedMonth, " ").concat(checkedYear)).format('YYYY-MM-DD');
        setIsReportsLading(true);
        clearAgentReports();
        clearCheckedReportBlockDay();
        getAgentReports(agentId, date);
    };
    return {
        checkedStatuses: checkedStatuses,
        setCheckedYear: setCheckedYear,
        getAgentReport: getAgentReport,
        setCheckedMonth: setCheckedMonth,
        isReportsLading: isReportsLading,
        years: getYears(),
        setIsReportsLading: setIsReportsLading,
        months: getMonths(),
        setCheckedStatuses: handleSetChecked,
        currentYear: getYears().filter(function (year) { return year.value === currentYear; }),
        currentMonth: getMonths().filter(function (month) { return month.value === currentMonth; }),
    };
};
