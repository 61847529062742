var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Messages } from '@public/locales/messages';
import { DayBlock } from '@src/modules/Pages/Reports/AgentReport/components/DayBlock';
import { useStatistics } from '@src/modules/Pages/Reports/AgentReport/components/Statistics/useStatistics';
import { StatisticsForDay } from '@src/modules/Pages/Reports/AgentReport/components/StatisticsForDay';
import { StatisticsByDaysStyledWrapper, StatisticsForDayStyledWrapper, StyledStatistics } from '../styled';
export var Statistics = function (_a) {
    var checkedStatuses = _a.checkedStatuses, checkedReportBlockDay = _a.checkedReportBlockDay, setAgentReportBlockDay = _a.setAgentReportBlockDay, reports = _a.reports;
    var dayBlock = useStatistics({ reports: reports, setAgentReportBlockDay: setAgentReportBlockDay }).dayBlock;
    return (React.createElement(StyledStatistics, null,
        React.createElement(StatisticsByDaysStyledWrapper, null, dayBlock.map(function (dayReport, i) { return (React.createElement(DayBlock, __assign({ setAgentReportBlockDay: function () { return setAgentReportBlockDay(dayReport); }, checkedReportDayDate: checkedReportBlockDay === null || checkedReportBlockDay === void 0 ? void 0 : checkedReportBlockDay.date, key: i, checkedStatuses: checkedStatuses }, dayReport))); })),
        React.createElement(StatisticsForDayStyledWrapper, null, !checkedReportBlockDay || checkedReportBlockDay.sessions.length === 0
            ? "".concat(Messages.Pages.Reports.ByAgentsTab.noDataForThisDay)
            : checkedReportBlockDay.sessions
                .filter(function (session) { return checkedStatuses[session.type]; })
                .map(function (session, i) { return React.createElement(StatisticsForDay, { key: i, time: session.time, sessionStatusType: session.type }); }))));
};
