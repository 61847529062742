var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest, } from 'redux-saga/effects';
import { IPages, SORTING_TYPES, } from '@src/global-interfaces';
import { errorNotification } from '@src/redux/notification/saga';
import { Messages } from '@public/locales/messages';
import { loaderWrap } from '@src/redux/loading/loaderWrap';
import { EntityTypes } from '@src/redux/entities/types';
import { calculateOffset } from '@src/utils/additional';
import { getEntityRequest } from '@src/utils/Entitites';
import { setFiltering, setPagination, setSorting } from '@src/redux/tableOptions/saga';
import { entitiesActions } from '@src/redux/entities/reducers';
import { getCurrentPage, getFiltering, getPagination, getSorting, getUserRole, } from '@src/redux/app/saga';
import { TableOptionTypes, } from '@src/redux/tableOptions/types';
import { transformFiltering } from '@src/utils/transformFiltering';
import { transformSortingParameter } from '@src/utils/transformSortingParameter';
export function setTableData(_a) {
    var data = _a.data, pageKey = _a.pageKey;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, put(entitiesActions.setDataSource({ data: data, pageKey: pageKey }))];
            case 1:
                _b.sent();
                return [2];
        }
    });
}
function transformSorting(_a) {
    var currentPage;
    var column = _a.column, order = _a.order;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!column && !order)
                    return [2, ''];
                return [4, getCurrentPage()];
            case 1:
                currentPage = _b.sent();
                if (currentPage === IPages.messengerReports || currentPage === IPages.agentsReports || currentPage === IPages.groupsReports) {
                    if (order === SORTING_TYPES.DESC)
                        order = 'Descending';
                    if (order === SORTING_TYPES.ASC)
                        order = 'Ascending';
                }
                return [2, ({
                        sortingParameter: transformSortingParameter(column),
                        sortOrder: order,
                    })];
        }
    });
}
function getTableData(action, _a) {
    var _b, pagination, sorting, filtering, count, offset, sortingObject, filteringObject, tableData, e_1;
    var request = _a.request, identificationRole = _a.identificationRole;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _b = action.payload, pagination = _b.pagination, sorting = _b.sorting, filtering = _b.filtering;
                count = pagination.count, offset = pagination.offset;
                return [4, transformSorting(sorting)];
            case 1:
                sortingObject = _c.sent();
                return [4, transformFiltering(filtering)];
            case 2:
                filteringObject = _c.sent();
                _c.label = 3;
            case 3:
                _c.trys.push([3, 5, , 7]);
                return [4, call(request, __assign(__assign({ identificationRole: identificationRole, count: count, offset: calculateOffset(offset, count) }, sortingObject), filteringObject))];
            case 4:
                tableData = _c.sent();
                return [2, tableData];
            case 5:
                e_1 = _c.sent();
                return [4, call(errorNotification, e_1.text || Messages.Errors.ServerError)];
            case 6:
                _c.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
function setTableDataPage(action) {
    var _a, pagination, sorting, filtering, identificationRole, pageKey, request, _b, data, overallCount;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = action.payload, pagination = _a.pagination, sorting = _a.sorting, filtering = _a.filtering;
                return [4, getUserRole()];
            case 1:
                identificationRole = _c.sent();
                return [4, getCurrentPage()];
            case 2:
                pageKey = _c.sent();
                request = getEntityRequest(pageKey);
                return [4, getTableData(action, { request: request, identificationRole: identificationRole })];
            case 3:
                _b = _c.sent(), data = _b.data, overallCount = _b.overallCount;
                return [4, updateStore(data, pageKey, __assign(__assign({}, pagination), { overallCount: overallCount }), sorting, filtering)];
            case 4:
                _c.sent();
                return [2];
        }
    });
}
function updateStore(data, pageKey, pagination, sorting, filtering) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, setTableData({ data: data, pageKey: pageKey })];
            case 1:
                _a.sent();
                return [4, setPagination({ pagination: pagination, pageKey: pageKey })];
            case 2:
                _a.sent();
                return [4, setSorting({ sorting: sorting, pageKey: pageKey })];
            case 3:
                _a.sent();
                return [4, setFiltering({ filtering: filtering, pageKey: pageKey })];
            case 4:
                _a.sent();
                return [2];
        }
    });
}
function updateSorting(action) {
    var filtering, pagination;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getFiltering()];
            case 1:
                filtering = _a.sent();
                return [4, getPagination()];
            case 2:
                pagination = _a.sent();
                return [4, put(entitiesActions.getTableData({ pagination: pagination, sorting: action.payload, filtering: filtering }))];
            case 3:
                _a.sent();
                return [2];
        }
    });
}
function updateFiltering(action) {
    var sorting, pagination;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getSorting()];
            case 1:
                sorting = _a.sent();
                return [4, getPagination()];
            case 2:
                pagination = _a.sent();
                return [4, put(entitiesActions.getTableData({ pagination: pagination, sorting: sorting, filtering: action.payload }))];
            case 3:
                _a.sent();
                return [2];
        }
    });
}
function updatePagination(action) {
    var sorting, filtering;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getSorting()];
            case 1:
                sorting = _a.sent();
                return [4, getFiltering()];
            case 2:
                filtering = _a.sent();
                return [4, put(entitiesActions.getTableData({ pagination: action.payload, sorting: sorting, filtering: filtering }))];
            case 3:
                _a.sent();
                return [2];
        }
    });
}
function updateTableData() {
    var sorting, filtering, pagination;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getSorting()];
            case 1:
                sorting = _a.sent();
                return [4, getFiltering()];
            case 2:
                filtering = _a.sent();
                return [4, getPagination()];
            case 3:
                pagination = _a.sent();
                return [4, put(entitiesActions.getTableData({ sorting: sorting, filtering: filtering, pagination: pagination }))];
            case 4:
                _a.sent();
                return [2];
        }
    });
}
export var entitySagas = [
    takeLatest(EntityTypes.GET_TABLE_DATA, loaderWrap(setTableDataPage)),
    takeLatest(TableOptionTypes.UPDATE_SORTING, updateSorting),
    takeLatest(TableOptionTypes.UPDATE_FILTERING, updateFiltering),
    takeLatest(TableOptionTypes.UPDATE_PAGINATION, updatePagination),
    takeLatest(EntityTypes.UPDATE_TABLE_DATA, updateTableData),
];
