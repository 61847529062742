var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { availableCountriesBulkGate, messengerProvider, notificationTypes } from '@src/global-interfaces';
import { TButtonTypes } from '@src/components/Button/Button';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Select from '@src/components/Select';
import Button from '@src/components/Button';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isNumberValue, isRequired, lengthCreator, } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledBrandInput } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { StyledLoaderWrapper } from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/styled';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import { Messages } from '@public/locales/messages';
var BulkGate = function (props) {
    var createSmsInbox = props.createSmsInbox, showNotification = props.showNotification, resetNotificationState = props.resetNotificationState, isLoadingCreateInbox = props.isLoadingCreateInbox;
    var minSenderIDBulkGate = lengthCreator.minSenderIDBulkGate, maxSenderIDBulkGate = lengthCreator.maxSenderIDBulkGate, maxApplicationIDBulkGate = lengthCreator.maxApplicationIDBulkGate, minApplicationIDBulkGate = lengthCreator.minApplicationIDBulkGate, minApplicationTokenBulkGate = lengthCreator.minApplicationTokenBulkGate, maxApplicationTokenBulkGate = lengthCreator.maxApplicationTokenBulkGate;
    var _a = __read(useState(null), 2), selectedCountries = _a[0], setSelectedCountries = _a[1];
    var _b = __read(useState(true), 2), isSelectedCountries = _b[0], setIsSelectedCountries = _b[1];
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onSubmit = function (data) {
        if (selectedCountries.length <= 0) {
            setIsSelectedCountries(false);
            return;
        }
        setIsSelectedCountries(true);
        createSmsInbox(__assign(__assign({}, data), { provider: messengerProvider.bulkGate, listCountriesBulkGate: selectedCountries.map(function (el) { return el.value; }) }));
    };
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(React.Fragment, null, isLoadingCreateInbox ? (React.createElement(StyledLoaderWrapper, null,
        React.createElement(Loader, null))) : (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Field, { validate: composeValidators(isRequired, minApplicationIDBulkGate, maxApplicationIDBulkGate), name: "applicationId", label: Messages.Labels.ApplicationID, showWarning: showWarning, component: BrandInput, placeholder: Messages.Controls.ApplicationID }),
                React.createElement(Field, { validate: composeValidators(isRequired, minApplicationTokenBulkGate, maxApplicationTokenBulkGate), name: "applicationToken", label: Messages.Labels.ApplicationToken, showWarning: showWarning, component: BrandInput, placeholder: Messages.Controls.ApplicationToken }),
                React.createElement(Field, { validate: composeValidators(isRequired, isNumberValue, minSenderIDBulkGate, maxSenderIDBulkGate), name: "senderId", label: Messages.Labels.SenderID, showWarning: showWarning, component: BrandInput, placeholder: Messages.Controls.SenderID }),
                React.createElement(Select, { label: Messages.Labels.ChooseProviderCountry, placeholder: Messages.Selects.ChooseProviderCountry, error: !isSelectedCountries, items: availableCountriesBulkGate, stateCB: function (el) { return setSelectedCountries(el); } }),
                React.createElement(StyledModalFooterOneBtnWrapper, null,
                    React.createElement(StyledSaveBtn, null,
                        React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit })))));
        } }))));
};
export default BulkGate;
