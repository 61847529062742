import { connect } from 'react-redux';
import AgentsPage from '@src/modules/Pages/Agents/Agents';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { appStore } from '@src/redux/app/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    currentAgent: agentsStore.getCurrentAgent(state),
    isModalOpen: modalWindowsStore.getIsModalOpen(state, modalTypes.AGENT_MODAL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.AGENT_MODAL, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AgentsPage);
