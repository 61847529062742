import React from 'react';
import { DropDownMenu } from '@src/components/DropDownMenu/DropDownMenu';
import { Messages } from '@public/locales/messages';
import { StyledSizeChanger } from './StyledSizeChanger';
export var SizeChanger = function (_a) {
    var pagination = _a.pagination, updatePagination = _a.updatePagination;
    var pageSizeOptions = pagination.pageSizeOptions, count = pagination.count, overallCount = pagination.overallCount;
    var changeTableElementsSize = function (size) {
        if (size === count)
            return;
        var pagination = {
            offset: 1,
            count: size,
            overallCount: overallCount,
        };
        updatePagination(pagination);
    };
    var formatPSO = pageSizeOptions.map(function (el) { return ({
        title: "".concat(el, " ").concat(Messages.Buttons.onPage),
        onClick: function () { return changeTableElementsSize(el); },
    }); });
    var position = {
        bottom: '30px',
        left: '-8px',
    };
    return (React.createElement(StyledSizeChanger, null,
        React.createElement(DropDownMenu, { list: formatPSO, activeItem: "".concat(count, " ").concat(Messages.Buttons.onPage), position: position })));
};
