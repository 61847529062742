import React from 'react';
import { StyledTableComponent } from './StyledTableComponent';
import { TableData } from './TableData/TableData';
import PaginationContainer from './PaginationContainer/PaginationContainer';
export var TableComponent = function (_a) {
    var columns = _a.columns, dataSource = _a.dataSource, rowSettings = _a.rowSettings, pagination = _a.pagination, sorting = _a.sorting, isLoading = _a.isLoading, getRowId = _a.getRowId;
    var rowsCount = dataSource.length;
    return (React.createElement(StyledTableComponent, null,
        React.createElement(TableData, { dataSource: dataSource, columns: columns, sorting: sorting, rowSettings: rowSettings, isLoading: isLoading, getRowId: getRowId }),
        React.createElement(PaginationContainer, { pagination: pagination, rowsCount: rowsCount, isLoading: isLoading })));
};
