import React, { useRef } from 'react';
import { env } from '@mdca/wde';
import { getDefaultExitIcon, getDefaultMuteIcon, getDefaultUnionIcon, getDefaultUnMuteIcon, } from '@src/constants/defaultCustomizations';
import { useOutsideClick } from '@src/utils/useOutsideClick';
import { StyledMenu } from './StyledMenu';
export var mute = "".concat(env('ICON_WEB_UI'), "/mute.svg");
export var unMute = "".concat(env('ICON_WEB_UI'), "/unMute.svg");
export var exit = "".concat(env('ICON_WEB_UI'), "/exit.svg");
export var fullScreen = "".concat(env('ICON_WEB_UI'), "/union.svg");
export var Menu = function (_a) {
    var lang = _a.lang, showMenu = _a.showMenu, isFullScreen = _a.isFullScreen, setShowMenu = _a.setShowMenu, setMute = _a.setMute, toggleFullScreen = _a.toggleFullScreen, isMuted = _a.isMuted, isAuthHeader = _a.isAuthHeader, moreMenu = _a.moreMenu;
    var ref = useRef(null);
    useOutsideClick(function () { return setShowMenu(false); }, ref, ['kebab-menu'], true);
    var handleLogOut = function () { };
    var handleFullScreen = function () {
        toggleFullScreen();
    };
    return (React.createElement(StyledMenu, { lang: lang, isAuthHeader: isAuthHeader, ref: ref, state: showMenu, background: moreMenu === null || moreMenu === void 0 ? void 0 : moreMenu.backdropColor, backgroundHoverItem: moreMenu === null || moreMenu === void 0 ? void 0 : moreMenu.backdropItemHoverColor, color: moreMenu === null || moreMenu === void 0 ? void 0 : moreMenu.textColor },
        React.createElement("div", { className: "AskMeWebLibrary-header-menu__item", onClick: function () { return setMute(!isMuted); } },
            React.createElement("img", { src: isMuted ? moreMenu.unMuteIcon || getDefaultUnMuteIcon() : moreMenu.muteIcon || getDefaultMuteIcon(), alt: "mute state" }),
            isMuted ? 'Unmute' : 'Mute'),
        React.createElement("div", { className: "AskMeWebLibrary-header-menu__item", onClick: handleFullScreen },
            React.createElement("div", { className: "AskMeWebLibrary-header-menu__item-fullscreen" },
                React.createElement("img", { src: moreMenu.unionIcon || getDefaultUnionIcon(), alt: "" }),
                isFullScreen && React.createElement("img", { src: moreMenu.unionIcon || getDefaultUnionIcon(), alt: "" })),
            isFullScreen ? 'WindowedMode' : 'Fullscreen'),
        !isAuthHeader && (React.createElement("div", { className: "AskMeWebLibrary-header-menu__item", onClick: handleLogOut },
            React.createElement("img", { src: moreMenu.exitIcon || getDefaultExitIcon(), alt: "" }),
            "LogOut"))));
};
