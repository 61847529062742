var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { messengerProvider } from '@src/global-interfaces';
import ModalWindow from '@src/components/ModalWindow';
import { Tab, Tabs } from '@src/components/Tabs/Tabs';
import BulkGate from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/BulkGate';
import MessageBird from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/MessageBird';
import BulkGateAndMessageBird from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/BulkGateAndMessageBird';
import { StyledCreateSmsInboxWrapper } from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/styled';
import { Messages } from '@public/locales/messages';
var CreateSmsInbox = function (props) {
    var toggleModal = props.toggleModal, setIsOpenTwoModal = props.setIsOpenTwoModal;
    var _a = __read(useState(messengerProvider.bulkGate), 2), currentTab = _a[0], setCurrentTab = _a[1];
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    var closeModal = function () {
        toggleModal();
        setIsOpenTwoModal(false);
    };
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: Messages.Labels.CreateSmsInbox },
        React.createElement(StyledCreateSmsInboxWrapper, null,
            React.createElement(Tabs, { callback: onTabChangeHandler, currentTab: currentTab, isShowBottomLine: true },
                React.createElement(Tab, { tabKey: messengerProvider.bulkGate, title: messengerProvider.bulkGate },
                    React.createElement(BulkGate, null)),
                React.createElement(Tab, { tabKey: messengerProvider.messageBird, title: messengerProvider.messageBird },
                    React.createElement(MessageBird, null)),
                React.createElement(Tab, { tabKey: messengerProvider.bulkGateAndMessageBird, title: messengerProvider.bulkGateAndMessageBird },
                    React.createElement(BulkGateAndMessageBird, null))))));
};
export default CreateSmsInbox;
