import { createSlice, createAction } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var ClientPageTypes = {
    SHOW_CLIENT_INFO: "".concat(reduxModules.clientsPage, "-currentClient/showClientInfo"),
    SHOW_CLIENT_HISTORY_DIALOG: "".concat(reduxModules.clientsPage, "-currentDialogHistory/showClientHistoryDialog"),
    GET_BRAND_SETTINGS: "".concat(reduxModules.clientsPage, "-createClientBrandSettings/getBrandSettings"),
    CREATE_CLIENT: "".concat(reduxModules.clientsPage, "-createClient/createClient"),
    IMPORT_CLIENTS: "".concat(reduxModules.clientsPage, "-importClients/importClients"),
    IMPORT_PHONE_FOR_BULK_MESSAGING: "".concat(reduxModules.clientsPage, "-importPhones/importPhoneForBulkMessaging"),
    SELECT_CLIENTS_FOR_BULK_MESSAGING: "".concat(reduxModules.clientsPage, "-selectClients/selectClientsForBulkMessaging"),
};
var initialState = {
    clientDialogs: null,
    currentAudioCalls: {},
    currentDialogMessages: {},
    currentBrandSettings: null,
};
var clientsSlice = createSlice({
    name: reduxModules.clientsPage,
    initialState: initialState,
    reducers: {
        setClientDialogs: function (state, action) {
            state.clientDialogs = action.payload;
        },
        setCurrentAudioCalls: function (state, action) {
            state.currentAudioCalls = action.payload;
        },
        setCurrentDialogMessages: function (state, action) {
            state.currentDialogMessages = action.payload;
        },
        setCurrentBrandSettings: function (state, action) {
            state.currentBrandSettings = action.payload;
        },
    },
});
export var clientsActions = {
    showClientInfo: createAction(ClientPageTypes.SHOW_CLIENT_INFO),
    showClientHistoryDialog: createAction(ClientPageTypes.SHOW_CLIENT_HISTORY_DIALOG),
    getBrandSettings: createAction(ClientPageTypes.GET_BRAND_SETTINGS),
    createClient: createAction(ClientPageTypes.CREATE_CLIENT),
    importClients: createAction(ClientPageTypes.IMPORT_CLIENTS),
    importPhoneForBulkMessaging: createAction(ClientPageTypes.IMPORT_PHONE_FOR_BULK_MESSAGING),
    selectClients: createAction(ClientPageTypes.SELECT_CLIENTS_FOR_BULK_MESSAGING),
    setClientDialogs: clientsSlice.actions.setClientDialogs,
    setCurrentAudioCalls: clientsSlice.actions.setCurrentAudioCalls,
    setCurrentBrandSettings: clientsSlice.actions.setCurrentBrandSettings,
    setCurrentDialogMessages: clientsSlice.actions.setCurrentDialogMessages,
};
export var clientsReducer = clientsSlice.reducer;
