var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, smooth } from '@src/app/styled';
var LIGHT_GREY_BLUE = COLORS.LIGHT_GREY_BLUE, WHITE = COLORS.WHITE, BLUE_VIVID = COLORS.BLUE_VIVID;
export var StyledDropDownMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    z-index: 1;\n    white-space: nowrap;\n    cursor: pointer;\n    .items-block {\n      position: relative;\n\n      .active-item {\n        display: flex;\n        align-items: center;\n\n        .active-item-name {\n          color: ", ";\n          margin-bottom: 0;\n          transition: 0.2s color;\n        }\n      }\n    }\n  }\n"], ["\n  && {\n    z-index: 1;\n    white-space: nowrap;\n    cursor: pointer;\n    .items-block {\n      position: relative;\n\n      .active-item {\n        display: flex;\n        align-items: center;\n\n        .active-item-name {\n          color: ", ";\n          margin-bottom: 0;\n          transition: 0.2s color;\n        }\n      }\n    }\n  }\n"])), BLUE_VIVID);
export var StyledList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-direction: column;\n    color: ", ";\n    position: absolute;\n    border-radius: 4px;\n    background: ", ";\n    ", ";\n\n    .item {\n      display: flex;\n      padding: 8px 8px 4px 8px;\n      transition: color 0.2s;\n\n      img {\n        height: 12px;\n        width: 18px;\n      }\n\n      .title {\n        margin-left: 5px;\n      }\n\n      &:first-of-type {\n        padding-top: 12px;\n      }\n\n      &:last-child {\n        padding-bottom: 12px;\n      }\n\n      &:hover {\n        color: ", ";\n      }\n    }\n    ", ";\n  }\n"], ["\n  && {\n    display: flex;\n    flex-direction: column;\n    color: ", ";\n    position: absolute;\n    border-radius: 4px;\n    background: ", ";\n    ", ";\n\n    .item {\n      display: flex;\n      padding: 8px 8px 4px 8px;\n      transition: color 0.2s;\n\n      img {\n        height: 12px;\n        width: 18px;\n      }\n\n      .title {\n        margin-left: 5px;\n      }\n\n      &:first-of-type {\n        padding-top: 12px;\n      }\n\n      &:last-child {\n        padding-bottom: 12px;\n      }\n\n      &:hover {\n        color: ", ";\n      }\n    }\n    ", ";\n  }\n"])), LIGHT_GREY_BLUE, function (_a) {
    var theme = _a.theme;
    return theme.colors.headerBackground;
}, function (props) { return props.position; }, WHITE, smooth);
var templateObject_1, templateObject_2;
