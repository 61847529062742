import React from 'react';
export var TButtonTypes;
(function (TButtonTypes) {
    TButtonTypes["button"] = "button";
    TButtonTypes["submit"] = "submit";
    TButtonTypes["reset"] = "reset";
})(TButtonTypes || (TButtonTypes = {}));
export function Button(_a) {
    var title = _a.title, styles = _a.styles, onClick = _a.onClick, _b = _a.type, type = _b === void 0 ? TButtonTypes.button : _b, children = _a.children, dataId = _a.dataId, id = _a.id;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: type, style: styles, onClick: function () {
                if (onClick) {
                    onClick(dataId);
                }
            }, id: id },
            title,
            ' '),
        children));
}
