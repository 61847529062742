import React from 'react';
import { COLORS } from '@src/app/styled';
import { StyledStatusRenderer } from './styled';
var DODGER_BLUE = COLORS.DODGER_BLUE, LIGHT_GREY_BLUE = COLORS.LIGHT_GREY_BLUE, GREEN_DARKER = COLORS.GREEN_DARKER, YELLOW = COLORS.YELLOW;
var statusColor = {
    new: GREEN_DARKER,
    active: DODGER_BLUE,
    finished: LIGHT_GREY_BLUE,
    assigned: YELLOW,
};
var StatusRenderer = function (status) { return (React.createElement(StyledStatusRenderer, { iconColor: statusColor[status] },
    React.createElement("span", { className: "circle" }),
    React.createElement("p", { className: "text" }, status.toUpperCase()))); };
export default StatusRenderer;
