import React from 'react';
import Header from '../Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
function Chat(props) {
    var greeting = props.greeting, customizations = props.customizations, botNameLocales = props.botNameLocales, currentLanguage = props.currentLanguage;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { customizations: customizations, lang: currentLanguage }),
        React.createElement(Body, { customizations: customizations, greeting: greeting, botNameLocales: botNameLocales, currentLanguage: currentLanguage }),
        React.createElement(Footer, { customizations: customizations })));
}
export default Chat;
