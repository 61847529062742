var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, put, call, fork, select, all, delay, } from 'redux-saga/effects';
import { difference } from 'lodash';
import { Messages } from '@public/locales/messages';
import { agentsApi } from '@src/modules/Pages/Agents/redux/api';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { entitiesActions } from '@src/redux/entities/reducers';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { agentsActions, AgentsPageTypes } from '@src/modules/Pages/Agents/redux/reducer';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
import { errorNotification, successNotification } from '@src/redux/notification/saga';
import { appActions } from '@src/redux/app/reducer';
import { authStore } from '@src/modules/Auth/redux/selector';
import { appStore } from '@src/redux/app/selector';
import { IPages } from '@src/global-interfaces';
function getCurrentAgent(id) {
    var currentAgent, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(agentsApi.getCurrentAgent, id)];
            case 1:
                currentAgent = _a.sent();
                return [4, put(agentsActions.setCurrentAgent(currentAgent))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_1 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_1.text)];
            case 4:
                _a.sent();
                console.error(e_1.text);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getCurrentAgentGroups(id) {
    var currentAgentGroups, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(agentsApi.getGroupsAgent, id)];
            case 1:
                currentAgentGroups = (_a.sent()).data;
                return [4, put(agentsActions.setCurrentAgentGroups(currentAgentGroups))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_2 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_2.text)];
            case 4:
                _a.sent();
                console.error(e_2);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getAssignAndUnassignedTagsCurrentAgent(id) {
    var assignAndUnassignedTagsCurrentAgent, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(agentsApi.getAssignAndUnassignedTagsByAgentId, id)];
            case 1:
                assignAndUnassignedTagsCurrentAgent = _a.sent();
                return [4, put(agentsActions.setTagsCurrentAgent(assignAndUnassignedTagsCurrentAgent))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_3 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_3.text)];
            case 4:
                _a.sent();
                console.error(e_3.text);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getAssignAndUnassignedGroupsByCurrentAgent(id) {
    var assignAndUnassignedGroupsCurrentAgent, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(agentsApi.getAssignAndUnassignedGroupsByAgentId, id)];
            case 1:
                assignAndUnassignedGroupsCurrentAgent = _a.sent();
                return [4, put(agentsActions.setAvailableGroupsCurrentAgent(assignAndUnassignedGroupsCurrentAgent))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_4 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_4.text)];
            case 4:
                _a.sent();
                console.error(e_4.text);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getCurrentAgentRoles(id) {
    var agentRoles, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(agentsApi.getAgentRoles, id)];
            case 1:
                agentRoles = (_a.sent()).data;
                return [4, put(agentsActions.setCurrentAgentRoles(agentRoles))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_5 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_5.text)];
            case 4:
                _a.sent();
                console.error(e_5.text);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getCurrentAgentSettings(id) {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4, call(getCurrentAgent, id)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4, call(getCurrentAgentRoles, id)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4, call(getCurrentAgentGroups, id)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4, call(getAssignAndUnassignedTagsCurrentAgent, id)];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4, call(getAssignAndUnassignedGroupsByCurrentAgent, id)];
            case 5: return [4, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 6:
                _c.sent();
                return [2];
        }
    });
}
function makeAgentTeamLead(agentId, groupId) {
    var e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4, call(agentsApi.makeAgentTeamLead, agentId, groupId)];
            case 1:
                _a.sent();
                return [3, 4];
            case 2:
                e_6 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_6.text)];
            case 3:
                _a.sent();
                console.error(e_6.text);
                return [3, 4];
            case 4: return [2];
        }
    });
}
function removeAgentTeamLead(agentId, groupId) {
    var e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4, call(agentsApi.removeAgentTeamLead, agentId, groupId)];
            case 1:
                _a.sent();
                return [3, 4];
            case 2:
                e_7 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_7.text)];
            case 3:
                _a.sent();
                console.error(e_7.text);
                return [3, 4];
            case 4: return [2];
        }
    });
}
function makeBrandAdmin(agent, brandId) {
    var e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4, call(agentsApi.subscribeAgentBrandAdmin, agent, brandId)];
            case 1:
                _a.sent();
                return [3, 4];
            case 2:
                e_8 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_8.text)];
            case 3:
                _a.sent();
                console.error(e_8.text);
                return [3, 4];
            case 4: return [2];
        }
    });
}
function removeBrandAdmin(agent, brandId) {
    var e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4, call(agentsApi.unSubscribeAgentBrandAdmin, agent, brandId)];
            case 1:
                _a.sent();
                return [3, 4];
            case 2:
                e_9 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_9.text)];
            case 3:
                _a.sent();
                console.error(e_9.text);
                return [3, 4];
            case 4: return [2];
        }
    });
}
function makeSuperAdmin(agent) {
    var e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4, call(agentsApi.subscribeAgentSuperAdmin, agent)];
            case 1:
                _a.sent();
                return [3, 4];
            case 2:
                e_10 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_10.text)];
            case 3:
                _a.sent();
                console.error(e_10.text);
                return [3, 4];
            case 4: return [2];
        }
    });
}
function removeSuperAdmin(agent) {
    var e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4, call(agentsApi.unSubscribeAgentSuperAdmin, agent)];
            case 1:
                _a.sent();
                return [3, 4];
            case 2:
                e_11 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_11.text)];
            case 3:
                _a.sent();
                console.error(e_11.text);
                return [3, 4];
            case 4: return [2];
        }
    });
}
function showAgentSettings(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, put(modalWindowsActions.toggleModal(modalTypes.AGENT_MODAL, payload))];
            case 1:
                _b.sent();
                return [4, call(getCurrentAgentSettings, payload)];
            case 2:
                _b.sent();
                return [2];
        }
    });
}
function banOrUnbanAgent(_a) {
    var status, e_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(agentsStore.getCurrentAgent)];
            case 1:
                status = (_b.sent()).status;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 10, , 12]);
                if (!(status === 'banned')) return [3, 4];
                return [4, call(agentsApi.unBanAgent, payload)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                if (!(status === 'active')) return [3, 6];
                return [4, call(agentsApi.banAgent, payload)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [4, fork(successNotification, Messages.Success.AgentStatusChanged)];
            case 7:
                _b.sent();
                return [4, call(getCurrentAgentSettings, payload)];
            case 8:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 9:
                _b.sent();
                return [3, 12];
            case 10:
                e_12 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_12.text)];
            case 11:
                _b.sent();
                console.error(e_12.text);
                return [3, 12];
            case 12: return [4, put(setLoading(AgentsPageTypes.BAN_OR_UNBAN_AGENT, false))];
            case 13:
                _b.sent();
                return [2];
        }
    });
}
function addAndDeleteAgentTags(_a) {
    var _b, assigned, id, tagsId, assignedTagsId, deleteTags, addTags, e_13;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, select(agentsStore.getTagsCurrentAgent)];
            case 1:
                _b = _c.sent(), assigned = _b.assigned, id = _b.id;
                tagsId = payload.map((function (el) { return el.value; }));
                assignedTagsId = assigned.map((function (el) { return el.id; }));
                deleteTags = difference(assignedTagsId, tagsId);
                addTags = difference(tagsId, assignedTagsId);
                _c.label = 2;
            case 2:
                _c.trys.push([2, 9, , 11]);
                if (!(deleteTags.length > 0)) return [3, 4];
                return [4, call(agentsApi.removeAgentTags, { tags: deleteTags, id: id })];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4:
                if (!(addTags.length > 0)) return [3, 6];
                return [4, call(agentsApi.addTagsToAgent, { tags: addTags, id: id })];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6: return [4, fork(successNotification, Messages.Success.SelectedAgentTagsChanged)];
            case 7:
                _c.sent();
                return [4, call(getCurrentAgentSettings, id)];
            case 8:
                _c.sent();
                return [3, 11];
            case 9:
                e_13 = _c.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_13.text)];
            case 10:
                _c.sent();
                console.error(e_13.text);
                return [3, 11];
            case 11: return [4, put(setLoading(AgentsPageTypes.ADD_AND_DELETE_AGENT_TAGS, false))];
            case 12:
                _c.sent();
                return [2];
        }
    });
}
function addAndDeleteAgentGroups(_a) {
    var _b, assigned, id, groupsId, assignedGroupsId, deleteGroups, addGroups, e_14;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, select(agentsStore.getAvailableGroupsCurrentAgent)];
            case 1:
                _b = _c.sent(), assigned = _b.assigned, id = _b.id;
                groupsId = payload.map((function (el) { return el.value; }));
                assignedGroupsId = assigned.map((function (el) { return el.id; }));
                deleteGroups = difference(assignedGroupsId, groupsId);
                addGroups = difference(groupsId, assignedGroupsId);
                _c.label = 2;
            case 2:
                _c.trys.push([2, 9, , 11]);
                if (!(deleteGroups.length > 0)) return [3, 4];
                return [4, call(agentsApi.removeAgentFromGroups, { group: deleteGroups, id: id })];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4:
                if (!(addGroups.length > 0)) return [3, 6];
                return [4, call(agentsApi.addAgentToGroups, { group: addGroups, id: id })];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6: return [4, fork(successNotification, Messages.Success.AssignedAgentsGroupsChanged)];
            case 7:
                _c.sent();
                return [4, call(getCurrentAgentSettings, id)];
            case 8:
                _c.sent();
                return [3, 11];
            case 9:
                e_14 = _c.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_14.text)];
            case 10:
                _c.sent();
                console.error(e_14.text);
                return [3, 11];
            case 11: return [4, put(setLoading(AgentsPageTypes.ADD_AND_DELETE_AGENT_GROUPS, false))];
            case 12:
                _c.sent();
                return [2];
        }
    });
}
function makeOrRemoveAgentTeamLead(_a) {
    var agentId, groupId, isTeamLead;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                agentId = payload.agentId, groupId = payload.groupId, isTeamLead = payload.isTeamLead;
                if (!isTeamLead) return [3, 2];
                return [4, call(removeAgentTeamLead, agentId, groupId)];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                if (!!isTeamLead) return [3, 4];
                return [4, call(makeAgentTeamLead, agentId, groupId)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [4, call(getCurrentAgentSettings, agentId)];
            case 5:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.TeamLeadChanged)];
            case 6:
                _b.sent();
                return [4, put(setLoading(AgentsPageTypes.MAKE_OR_REMOVE_TEAM_LEAD, false))];
            case 7:
                _b.sent();
                return [2];
        }
    });
}
function makeOrRemoveBrandAdmin(_a) {
    var brandId, isRemove, currentAgent;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                brandId = payload.brandId, isRemove = payload.isRemove;
                return [4, select(agentsStore.getCurrentAgent)];
            case 1:
                currentAgent = _b.sent();
                if (!isRemove) return [3, 3];
                return [4, call(removeBrandAdmin, currentAgent, brandId)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                if (!!isRemove) return [3, 5];
                return [4, call(makeBrandAdmin, currentAgent, brandId)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [4, call(getCurrentAgentSettings, currentAgent.id)];
            case 6:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.AgentRoleChanged)];
            case 7:
                _b.sent();
                return [4, put(setLoading(AgentsPageTypes.MAKE_OR_REMOVE_BRAND_ADMIN, false))];
            case 8:
                _b.sent();
                return [2];
        }
    });
}
function makeOrRemoveSuperAdmin(_a) {
    var isRemove, currentAgent;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                isRemove = payload.isRemove;
                return [4, select(agentsStore.getCurrentAgent)];
            case 1:
                currentAgent = _b.sent();
                if (!isRemove) return [3, 3];
                return [4, call(removeSuperAdmin, currentAgent)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                if (!!isRemove) return [3, 5];
                return [4, call(makeSuperAdmin, currentAgent)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [4, call(getCurrentAgentSettings, currentAgent.id)];
            case 6:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.AgentRoleChanged)];
            case 7:
                _b.sent();
                return [4, put(setLoading(AgentsPageTypes.MAKE_OR_REMOVE_SUPER_ADMIN, false))];
            case 8:
                _b.sent();
                return [2];
        }
    });
}
function changeAgentInfo(_a) {
    var currentAgent, response, e_15;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(agentsStore.getCurrentAgent)];
            case 1:
                currentAgent = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4, call(agentsApi.changeAgentInfo, __assign(__assign({}, currentAgent), payload))];
            case 3:
                response = _b.sent();
                return [4, put(agentsActions.setCurrentAgent(response))];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.AgentInfoChanged)];
            case 5:
                _b.sent();
                return [3, 8];
            case 6:
                e_15 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_15.text)];
            case 7:
                _b.sent();
                console.error(e_15.text);
                return [3, 8];
            case 8: return [4, put(setLoading(AgentsPageTypes.CHANGE_AGENT_INFO, false))];
            case 9:
                _b.sent();
                return [2];
        }
    });
}
function getCountAgentsNewBees() {
    var roles, response, e_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, select(authStore.roles)];
            case 1:
                roles = _a.sent();
                if (!roles.find(function (el) { return el.role === 'superAdmin'; }))
                    return [2];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4, call(agentsApi.getCountAgentsNewBees)];
            case 3:
                response = _a.sent();
                return [4, put(agentsActions.setCountAgentsNewBees(response))];
            case 4:
                _a.sent();
                return [3, 7];
            case 5:
                e_16 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_16.text)];
            case 6:
                _a.sent();
                console.error(e_16.text);
                return [3, 7];
            case 7: return [2];
        }
    });
}
export function getCountAgentsNewBeesWorker() {
    var currentPage, response, e_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3, 9];
                return [4, select(appStore.currentPage)];
            case 1:
                currentPage = _a.sent();
                if (!(currentPage === IPages.agents)) return [3, 7];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4, call(agentsApi.getCountAgentsNewBees)];
            case 3:
                response = _a.sent();
                return [4, put(agentsActions.setCountAgentsNewBees(response))];
            case 4:
                _a.sent();
                return [3, 7];
            case 5:
                e_17 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_17.text)];
            case 6:
                _a.sent();
                console.error(e_17.text);
                return [3, 7];
            case 7: return [4, delay(10000)];
            case 8:
                _a.sent();
                return [3, 0];
            case 9: return [2];
        }
    });
}
export var agentsPageSagas = [
    takeEvery(AgentsPageTypes.SHOW_AGENT_SETTINGS, loaderWrap(showAgentSettings)),
    takeEvery(AgentsPageTypes.BAN_OR_UNBAN_AGENT, loaderWrap(banOrUnbanAgent, false)),
    takeEvery(AgentsPageTypes.ADD_AND_DELETE_AGENT_TAGS, loaderWrap(addAndDeleteAgentTags, false)),
    takeEvery(AgentsPageTypes.ADD_AND_DELETE_AGENT_GROUPS, loaderWrap(addAndDeleteAgentGroups, false)),
    takeEvery(AgentsPageTypes.MAKE_OR_REMOVE_TEAM_LEAD, loaderWrap(makeOrRemoveAgentTeamLead, false)),
    takeEvery(AgentsPageTypes.MAKE_OR_REMOVE_BRAND_ADMIN, loaderWrap(makeOrRemoveBrandAdmin, false)),
    takeEvery(AgentsPageTypes.MAKE_OR_REMOVE_SUPER_ADMIN, loaderWrap(makeOrRemoveSuperAdmin, false)),
    takeEvery(AgentsPageTypes.CHANGE_AGENT_INFO, loaderWrap(changeAgentInfo, false)),
    takeEvery(appActions.saveCurrentPage.type, getCountAgentsNewBees),
];
