import { connect } from 'react-redux';
import UsefulLinksModal from '@src/modules/Pages/Tags/UsefulLinksModal/UsefulLinksModal';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { notificationActions } from '@src/redux/notification/reducer';
var mapStateToProps = function (state) { return ({
    usefulLinks: modalWindowsStore.getModalInfo(state, modalTypes.USEFUL_LINKS_MODAL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    toggleModalUsefulLinks: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.USEFUL_LINKS_MODAL, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UsefulLinksModal);
