import { createSelector } from '@reduxjs/toolkit';
var Root = function (state) { return state; };
var paginationSelector = function () { return createSelector(Root, function (state) {
    var currentPage = state.app.currentPage;
    return state.tableOptions[currentPage].pagination;
}); };
var sortingSelector = function () { return createSelector(Root, function (state) {
    var currentPage = state.app.currentPage;
    return state.tableOptions[currentPage].sorting;
}); };
var filteringSelector = function () { return createSelector(Root, function (state) {
    var currentPage = state.app.currentPage;
    return state.tableOptions[currentPage].filtering;
}); };
export var tableOptionsStore = {
    pagination: paginationSelector(),
    sorting: sortingSelector(),
    filtering: filteringSelector(),
};
