var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Messages } from '@public/locales/messages';
import Button from '@src/components/Button';
import { StyledCountAgentsNewBeesBtn } from '@src/components/Button/styled';
import CustomPopover from '@src/components/CustomPopover';
import { tableRenderers } from '@src/components/tableRenderers';
import { authStore } from '@src/modules/Auth/redux/selector';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { toolBarActions } from '@src/modules/ToolBar/redux/reducer';
import { toolBarStore } from '@src/modules/ToolBar/redux/selector';
import { appStore } from '@src/redux/app/selector';
import { copyText } from '@src/utils/copyText';
var _a = Messages.Pages.Agents.TableColumns, Agents = _a.Agents, AgentStatus = _a.AgentStatus, Email = _a.Email, CreatedAt = _a.CreatedAt, Groups = _a.Groups, LastActivity = _a.LastActivity;
var AgentsTitle = function (props) {
    var _a, _b;
    var agents = props.agents, roles = props.roles, addFilters = props.addFilters, currentPage = props.currentPage, deleteFilters = props.deleteFilters, countAgentsNewBees = props.countAgentsNewBees, selectedAllFilters = props.selectedAllFilters, saveValueSelectedFilter = props.saveValueSelectedFilter, deleteValueSelectedFilter = props.deleteValueSelectedFilter;
    var _c = __read(useState(Array.isArray((_a = selectedAllFilters === null || selectedAllFilters === void 0 ? void 0 : selectedAllFilters.agents) === null || _a === void 0 ? void 0 : _a.isNewbie) ? (_b = selectedAllFilters === null || selectedAllFilters === void 0 ? void 0 : selectedAllFilters.agents) === null || _b === void 0 ? void 0 : _b.isNewbie[0] : false), 2), selectedNewbieAgents = _c[0], setSelectedNewbieAgents = _c[1];
    useEffect(function () {
        var _a, _b;
        setSelectedNewbieAgents(Array.isArray((_a = selectedAllFilters === null || selectedAllFilters === void 0 ? void 0 : selectedAllFilters.agents) === null || _a === void 0 ? void 0 : _a.isNewbie) ? (_b = selectedAllFilters === null || selectedAllFilters === void 0 ? void 0 : selectedAllFilters.agents) === null || _b === void 0 ? void 0 : _b.isNewbie[0] : false);
    }, [selectedAllFilters]);
    var showNewbieAgents = function () {
        saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { isNewbie: true } });
        addFilters({ currentPage: currentPage, filter: 'isNewbie' });
    };
    var hideNewbieAgents = function () {
        deleteValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { isNewbie: [] } });
        deleteFilters({ currentPage: currentPage, filter: 'isNewbie' });
    };
    var onCLickChangeNewbieAgents = function () { return (selectedNewbieAgents ? hideNewbieAgents() : showNewbieAgents()); };
    return (React.createElement("div", null, roles.find(function (el) { return el.role === 'superAdmin'; }) && (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
        React.createElement(CustomPopover, { text: Messages.Labels.NumberOfNewAgents, isHeader: false, iconClick: copyText, titleText: Messages.Labels.NumberOfNewAgents },
            React.createElement(StyledCountAgentsNewBeesBtn, { onClick: onCLickChangeNewbieAgents, selectedNewbieAgents: selectedNewbieAgents },
                React.createElement(Button, { title: countAgentsNewBees.toString(), onClick: function () { } }))),
        React.createElement("div", null, agents)))));
};
var mapStateToProps = function (state) { return ({
    roles: authStore.roles(state),
    selectedAllFilters: toolBarStore.getSelectedFilter(state),
    currentPage: appStore.currentPage(state),
    countAgentsNewBees: agentsStore.getCountAgentsNewBees(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    addFilters: function (data) { return dispatch(toolBarActions.addFilters(data)); },
    deleteFilters: function (data) { return dispatch(toolBarActions.deleteFilters(data)); },
    saveValueSelectedFilter: function (data) { return dispatch(toolBarActions.saveValueSelectedFilter(data)); },
    deleteValueSelectedFilter: function (data) { return dispatch(toolBarActions.deleteValueSelectedFilter(data)); },
}); };
var AgentsTitleConnect = connect(mapStateToProps, mapDispatchToProps)(AgentsTitle);
export var agentColumns = [
    {
        title: React.createElement(AgentsTitleConnect, { agents: Agents }),
        key: 'name',
        render: tableRenderers.AgentRenderer,
        sortingEnabled: true,
    },
    {
        title: AgentStatus,
        key: 'sessionStatus',
        render: tableRenderers.StatusRender,
    },
    {
        title: Email,
        key: 'email',
        render: tableRenderers.TextEllipsisRenderer,
        sortingEnabled: true,
    },
    {
        title: Groups,
        key: 'groups',
        render: tableRenderers.ArrayTextRenderer,
    },
    {
        title: CreatedAt,
        key: 'created_at',
        render: tableRenderers.DateRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: LastActivity,
        key: 'lastActivity',
        render: tableRenderers.DateRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
];
