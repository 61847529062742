var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var MAIN_DARK = COLORS.MAIN_DARK, WHITE = COLORS.WHITE, ALERT = COLORS.ALERT;
export var InputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    max-width: 100%;\n    display: flex;\n    justify-content: space-between;\n    border-radius: 5px;\n    color: ", ";\n    background: ", ";\n    margin-bottom: ", ";\n    padding-right: 10px;\n    border: 1px solid ", ";\n    ", ";\n\n    input {\n      border-radius: 5px;\n    }\n\n    img {\n      margin: 2px;\n      height: 20px;\n      width: 20px;\n    }\n  }\n"], ["\n  && {\n    max-width: 100%;\n    display: flex;\n    justify-content: space-between;\n    border-radius: 5px;\n    color: ", ";\n    background: ", ";\n    margin-bottom: ", ";\n    padding-right: 10px;\n    border: 1px solid ", ";\n    ", ";\n\n    input {\n      border-radius: 5px;\n    }\n\n    img {\n      margin: 2px;\n      height: 20px;\n      width: 20px;\n    }\n  }\n"])), MAIN_DARK, WHITE, function (props) { return (props.error ? '0' : '8px'); }, function (props) { return (props.error ? ALERT : 'none'); }, function (props) { return props.styles; });
export var StyledInputLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    padding-bottom: 5px;\n  }\n"], ["\n  && {\n    color: ", ";\n    padding-bottom: 5px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1, templateObject_2;
