var Messages = {
    Success: {
        Success: 'Siker',
        ForgotPasswordSend: 'Jelszómódosító linket küldtünk az email címedre',
        ResetPasswordSuccess: 'A jelszavad sikeresen frissítve',
        BrandCreated: 'Márka létrejött!',
        MailInboxCreated: 'Postafiók létrehozva!',
        PrivacyPolicyChanged: 'Az adatvédelmi szabályzat megváltozott!',
        SmsInboxCreated: 'Az SMS postafiók elkészült!',
        InboxDeleted: 'Beérkezett üzenetek törölve!',
        StatusChanged: 'Az állapot megváltozott!',
        FileLoaded: 'Fájl betöltve!',
        AuthMail: 'Auth Mail!',
        DomainSettings: 'Domain beállítások!',
        UpdatedSmsAccounts: 'Frissített sms fiókok!',
        ChanelCreated: 'Csatorna létrehozva!',
        DeleteMessenger: 'Messenger törlése!',
        AddMessenger: 'Add hozzá a Messengert!',
        ChangedMessenger: 'Megváltozott Messenger!',
        ClientCreated: 'Kliens létrehozva!',
        ClientsImports: 'Ügyfelek import!',
        GroupCreated: 'Csoport létrehozva!',
        ChangedAssignedAgentsInGroups: 'Megváltoztatták a csoportokhoz rendelt ügynököket!',
        ChangedAssignedChannelsInGroups: 'A csoportokhoz rendelt csatornák megváltoztak!',
        ChangedAssignedTagsInGroups: 'A csoportokhoz rendelt címkék megváltoztak!',
        CreateNewCategory: 'Hozz létre új kategóriát!',
        CreateNewPhrase: 'Hozz létre új kifejezést!',
        DeleteCategory: 'Kategória törlése!',
        DeletePhrase: 'Törölje a kifejezést!',
        UpdatePhrase: 'Frissítse a kifejezést!',
        EditCategory: 'Kategória szerkesztése!',
        DeleteAllCategory: 'Delete all category!',
        TagCreated: 'Címke létrehozva!',
        TagUpdated: 'Címke frissítve!',
        TelegramInboxCreated: 'A távirati postafiók elkészült!',
        HeaderTextChanged: 'A fejléc szövege megváltozott',
        LogoIconUrlChanged: 'A logó ikon URL-je megváltozott',
        IconUrlChanged: 'Az ikon URL-je megváltozott',
        BackgroundImageUrlChanged: 'A háttérkép URL-je megváltozott',
        GreetingImageUrlChanged: 'Az üdvözlő kép URL-je megváltozott',
        AgentImageUrlChanged: 'Az ügynök képének URL-je megváltozott',
        InputPlaceholderChanged: 'Bemeneti helyőrző módosult',
        SendImageUrlChanged: 'A kép küldésének URL-je megváltozott',
        AgentStatusChanged: 'Az ügynök státusza megváltozott',
        SelectedAgentTagsChanged: 'A kiválasztott ügynökcímkék módosultak',
        AssignedAgentsGroupsChanged: 'A hozzárendelt ügynökcsoportok megváltoztak',
        TeamLeadChanged: 'Csapatvezető cserélt',
        AgentRoleChanged: 'Az ügynök szerepe megváltozott',
        AgentInfoChanged: 'Az ügynök adatai megváltoztak',
    },
    Errors: {
        PasswordMatching: 'A jelszavak nem egyeznek!',
        IncorrectEmail: 'Az e-mail cím hibás',
        IncorrectPhone: 'A telefon helytelen',
        IncorrectUrl: 'Az URL helytelen',
        FieldContainSpaces: 'A mező szóközöket tartalmaz',
        FieldIsRequired: 'Kötelező mező',
        NameValidation: 'A mező hosszának 2 és 40 karakter között kell lennie',
        NumberValidation: 'A mező csak számokat tartalmazhat',
        PasswordValidation: 'A jelszónak ábécét és számokat kell tartalmaznia, és nem tartalmazhat szóközt',
        InvoiceNumberValidation: 'A számlaszámnak latin betűket, számokat és speciális karaktereket kell tartalmaznia',
        MinimumLength: 'Minimális hosszúság: {0} szimbólum',
        MaximumLength: 'Maximális hosszúság: {0} szimbólum',
        ServerError: 'Szerverhiba, próbálja meg később!',
        ServerErrorFileDownloadTimeOut: 'Szerverhiba, lejárt a fájlletöltés, próbálkozzon később!',
        NotValidEmailOrPassword: 'Érvénytelen e-mail cím vagy jelszó',
        UserWasNotFound: 'Felhasználó nem található!',
        UserIsAlreadyExist: 'A felhasználó már létezik!',
        AgentWasBanned: 'Az ügynököt kitiltották',
        TokenExpired: 'A munkamenet lejárt. Kérjük, jelentkezzen be újra',
        TelegramIdIsIncorrect: 'A távirat azonosítója hibás',
        ErrorWrongFileFormat: 'Hiba, rossz fájlformátum!',
        FileSizeExceededWarnings: 'A fájl mérete meghaladja a figyelmeztetéseket!',
        BrandWithSuchNameIsAlreadyExisting: 'Már létezik ilyen nevű márka!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Már kiválasztott egy szűrőt',
        AgentCreate: 'Ön új ügynök az AskMe rendszerben. Felhívjuk figyelmét, hogy csak a rendszergazdai szerepkör hozzárendelése után tud bejelentkezni!',
        NoStatisticsForTheAgent: 'Nincsenek statisztikai adatok az ügynökről a kiválasztott időszakra vonatkozóan',
        FieldIsRequired: 'Kötelező mező',
        ChannelMustContainAtLeastOneLanguage: 'A csatornának legalább egy nyelvet kell tartalmaznia',
    },
    Info: {
        Copied: 'másolt',
        PhraseBotNameAndGreetingsChanged: 'A kifejezések, a robotnév és az üdvözlés módosultak',
    },
    Confirm: {},
    Message: {
        Welcome: 'Welcome to AskMe',
        SignIn: 'Log in to Admin Panel or go to your ',
        SignUp: 'Sign up to Admin Panel or go to your ',
        Register: 'Don’t have an account with us?',
        Login: 'Already have an account?',
        Logout: 'Tényleg el akarsz menni?',
        ForgotPassword: 'Forgot password?',
        ExcelFilesDownloading: 'Excel fájlok letöltése',
        excelFileIs: 'Excel fájl',
        Created: 'létrehozva',
        Creating: 'alkot',
    },
    Accordions: {
        MailInboxes: 'Postafiókok',
        TelegramAccounts: 'Telegram fiókok',
        SmsAccounts: 'SMS fiókok',
    },
    Controls: {
        Newbie: 'Újonc',
        Name: 'Név',
        Email: 'Email',
        Password: 'Jelszó',
        RepeatPassword: 'Jelszó újra',
        Register: 'Regisztráció',
        Login: 'Belépés',
        Or: 'VAGY',
        SignUp: 'Regisztrálj',
        Send: 'Küld',
        SearchFilter: 'Keresési szűrők',
        Search: 'Keresés',
        SearchMessengers: 'Hírnökök keresése',
        SearchAgent: 'Ügynök keresése',
        SearchLanguage: 'Keresés nyelve',
        SearchChannel: 'Csatorna keresése',
        SearchTag: 'Címke keresése',
        SearchStatus: 'Keresés állapota',
        SearchBrand: 'Márka keresése',
        SearchWhiteListTags: 'Keresés a fehér lista címkéi között',
        SearchSessionStatus: 'Munkamenet állapotának keresése',
        SearchAccessLevel: 'Keresési hozzáférési szint',
        SearchHasActiveDialogs: 'A keresésnek aktív párbeszédpaneljei vannak',
        GroupName: 'Csoport név*',
        Description: 'Leírás*',
        LinkAlias: 'Link alias*',
        CategoryName: 'Kategória név*',
        BrandName: 'Márkanév',
        AccessKey: 'Hozzáférési kulcs',
        ClientName: 'Ügyfél Neve',
        ClientFirstName: 'Ügyfél keresztneve',
        ClientLastName: 'Ügyfél vezetékneve',
        ClientPhone: 'Ügyfél telefon',
        ClientEmail: 'Ügyfél e-mail',
        PleaseWrite: 'Kérlek írj',
        MailInbox: 'Postafiók',
        SmsInbox: 'SMS postafiók',
        TelegramInbox: 'Távirat postafiók',
        ApplicationID: 'Alkalmazásazonosító',
        ApplicationToken: 'Alkalmazási token',
        SenderID: 'Feladó azonosítója',
        ChangeName: 'Név módosítása',
        ChangeURL: 'URL módosítása',
        TagName: 'Címke neve',
        LinkUrl: 'Link url*',
        Greetings: 'Üdvözlet',
        FastPhrase: 'Gyors kifejezés №',
        ClickOnPaperclip: 'Kattintson a gémkapocs ikonra a fájl kiválasztásához',
        UserName: 'Belépés',
        Domain: 'Tartomány*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'Fájl csatolása',
        NoData: 'nincs adat',
        NoRating: 'nincs minősítés',
        IfYouWantFinishDialog: 'Ha be szeretné fejezni a párbeszédpanelt, kattintson az OK gombra',
        AllDialogsAreVisibleToAllAgents: 'Az összes párbeszédpanel minden ügynök számára látható',
        DialogueIsAssignedAutomatically: 'A párbeszéd automatikusan hozzá van rendelve ahhoz az ügynökhöz, akinek a legkevesebb párbeszéde van',
        AllDialogsAreVisibleToSeveralAgents: 'A párbeszédpanelek több olyan ügynök számára láthatók, amelyeknek a legkevesebb párbeszéde van',
        On: 'On',
        Off: 'Off',
        FirstName: 'Keresztnév',
        InvoiceNumber: 'Számlaszám',
        LastName: 'Vezetéknév',
        SMS: 'SMS',
        New: 'Új',
        Active: 'Aktív',
        Finished: 'Befejezett',
        Assigned: 'Kijelölt',
        Online: 'Online',
        Offline: 'Offline',
        Break: 'Szünet',
        Low: 'Alacsony',
        Medium: 'Közepes',
        High: 'Magas',
        True: 'Igaz',
        False: 'Hazugság',
        Date: 'Dátum',
        AgentName: 'Ügynök neve',
        Messenger: 'Hírnök',
        Language: 'Nyelv',
        Channel: 'Csatorna',
        Tag: 'Címke',
        Status: 'Állapot',
        Brand: 'Márka',
        AccessLevel: 'Hozzáférési szint',
        WhiteListTags: 'Fehér lista címkék',
        SessionStatus: 'Munkamenet állapota',
        LastTags: 'Utolsó címkék',
        HasActiveDialogs: 'Aktív párbeszédablakai vannak',
        ChannelName: 'Csatorna neve',
        WriteYourFirstName: '* Kérem, írja be a keresztnevét',
        WriteYourLastName: '* Kérem, írja be a vezetéknevét',
        WriteYourUserName: '* Kérem, írja be a felhasználó nevét',
        WriteYourPhoneNumber: '* Kérem írjon telefonszámot',
        WriteYourEmail: '* Kérlek írd meg az email címed',
        WriteYourInvoiceNumber: '* Kérjük, írja meg a számla számát',
        WriteYourAccountNumber: '* Kérem, írja be a számlaszámát',
        BotName: 'Bot neve',
        Character: 'karakter',
        Number: 'szám',
        IdExample: 'ID példa',
        XCharecter: 'X-karakter',
        YNumber: 'Y-szám',
        ThisOperationCanTakeLongTime: 'Ez a művelet sokáig tarthat!',
        ClickTiSaveExcel: 'Kattintson az Excel mentéséhez',
        PrivacyPolicyText: 'Adatvédelmi szabályzat szövege',
        PrivacyPolicyLink: 'Adatvédelmi szabályzat link',
        PrivacyPolicyLinkText: 'Adatvédelmi szabályzat hivatkozás szövege',
        AgentPhone: 'Ügynök telefon',
        HeaderTextTop: 'Fejléc szöveg felső',
        HeaderTextBottom: 'Fejléc szöveg alján',
        LogoIconUrl: 'Logo ikon URL',
        IconUrl: 'Ikon URL',
        ChatWindowsLogo: 'Chat windows logó',
        GreetingIconBotUrl: 'Üdvözlő ikon bot URL-je',
        AgentMessageIconUrl: 'Ügynöki üzenet ikon URL-je',
        WriteMessagesPlaceholder: 'Üzenetek írása helyőrző',
        SendImageUrl: 'Kép URL küldése',
        ChannelURL: 'Csatorna URL-je',
    },
    Selects: {
        SelectAccessLevel: 'Válassza ki a hozzáférési szintet*',
        SelectChannel: 'Válassza ki a csatornát*',
        SelectChannels: 'Válasszon csatornákat',
        SelectWhiteTagsList: 'Válassza ki a fehér címkék listáját*',
        SelectAgents: 'Válasszon ügynököket',
        SelectGroups: 'Válasszon csoportokat',
        SelectBrand: 'Válasszon márkát',
        SelectTags: 'Válassza ki a címkéket',
        ChooseProviderCountry: 'Válassza ki a szolgáltató országát',
        SelectMessengers: 'Válassza ki a hírnököket',
        SelectTagStatus: 'Válassza ki a címke állapotát',
        SelectAgent: 'Válassza ki az ügynököt',
        SelectEnabledLanguages: 'Válassza ki az engedélyezett nyelveket',
        ChannelName: 'Csatorna neve',
        ChannelURL: 'Csatorna URL-je',
        SelectLanguageWebChat: 'Válasszon nyelveket Web Chat',
    },
    Labels: {
        MoreIconUrl: 'Több ikon URL',
        MinimizeIconUrl: 'Kis ikon URL',
        SendFileUrl: 'Fájl URL küldése',
        NumberOfNewAgents: 'Új ügynökök száma',
        TextForBulkMessaging: 'Szöveg tömeges üzenetküldéshez',
        Name: 'Név',
        LastName: 'Vezetéknév',
        Phone: 'Telefon',
        GroupName: 'Csoport név*',
        SelectBrand: 'Válasszon márkát',
        Channel: 'Csatorna*',
        Description: 'Leírás*',
        AccessLevel: 'Hozzáférési szint*',
        WhiteTagsList: 'Fehér címkék listája*',
        BrandName: 'Márkanév',
        CategoryName: 'Kategória név*',
        ChooseProviderCountry: 'Válassza ki a szolgáltató országát',
        AccessKey: 'Hozzáférési kulcs',
        SelectChannels: 'Válasszon csatornákat',
        SelectGroups: 'Válasszon csoportokat',
        SelectAgents: 'Válasszon ügynököket',
        SelectTags: 'Válassza ki a címkéket',
        SelectAccessLevel: 'Válassza ki a hozzáférési szintet*',
        Password: 'Jelszó*',
        Login: 'Belépés',
        SelectMessengers: 'Válassza ki a hírnököket',
        UserName: 'Ki a hírnököket',
        ClientName: 'Ügyfél Neve',
        ClientFirstName: 'Ügyfél keresztneve',
        ClientLastName: 'Ügyfél vezetékneve',
        ClientPhone: 'Ügyfél telefon',
        ClientEmail: 'Ügyfél e-mail',
        ClientInfo: 'Ügyfél információ',
        UpdateGroup: 'Csoport frissítése',
        Agents: 'Ügynökök',
        UsefulPhrases: 'Hasznos kifejezések',
        Channels: 'Csatornák',
        Tags: 'Címkék',
        AddNewPhrases: 'Új kifejezések hozzáadása',
        Phrase: 'Kifejezés',
        SelectCategory: 'Válassz kategóriát',
        EditCategory: 'Kategória szerkesztése',
        EditPhrase: 'Fázis szerkesztése',
        Or: 'vagy',
        AddNewCategory: 'Új kategória hozzáadása',
        Dialogs: 'Párbeszédek',
        TotalDuration: 'Teljes időtartam',
        Email: 'Email',
        ClientId: 'Ügyfélazonosító',
        ClientBanType: 'Ügyféltiltás típusa',
        ClientBanTime: 'Ügyfél kitiltási idő',
        Messengers: 'Hírnökök',
        IpAddress: 'IP-cím',
        ExternalID: 'Külső azonosító',
        UpdateBrand: 'Frissítse a márkát',
        CreateMailInbox: 'Hozzon létre postafiók fiókot',
        CreateTelegramInbox: 'Hozzon létre Telegram-fiókot',
        CreateSmsInbox: 'Hozzon létre SMS-fiókot',
        MailInbox: 'Postafiók',
        TelegramInbox: 'Távirat postafiók',
        SmsInbox: 'SMS postafiók',
        ApplicationID: 'Alkalmazásazonosító',
        ApplicationToken: 'Alkalmazási token',
        SenderID: 'Feladó azonosítója',
        ChangeName: 'Név módosítása',
        ChangeURL: 'URL módosítása',
        TagName: 'Címke neve',
        SelectTagStatus: 'Válassza ki a címke állapotát',
        SelectAgent: 'Válassza ki az ügynököt',
        UpdateTag: 'Címke frissítése',
        CreateTag: 'Címke létrehozása',
        UsefulLinks: 'Hasznos Linkek',
        LinkAlias: 'Link alias',
        LinkUrl: 'Link url',
        Roles: 'Szerepek',
        Groups: 'Csoportok',
        CreateGroups: 'Csoportok létrehozása',
        TeamLead: 'Csoport vezetés',
        Ban: 'Tilalom',
        SelectEnabledLanguages: 'Válassza ki az engedélyezett nyelveket',
        Greetings: 'Üdvözlet',
        FastPhrase: 'Gyors kifejezés №',
        ClientHistory: 'Ügyféltörténet',
        ChooseRequiredFields: 'Kérjük, válassza ki a kötelező mezőket',
        GetAllDialogs: 'Az összes párbeszédpanel lekérése',
        GetCurrentDialog: 'Az aktuális párbeszédpanel lekérése',
        SaveInLocalStorage: 'Mentse a LocalStorage-ba',
        SaveInSessionStorage: 'Mentse a SessionStorage-ba',
        InvoiceNumber: 'Számlaszám',
        AccountNumber: 'Számlaszám',
        General: 'Generál',
        Authorization: 'Engedélyezés',
        Languages: 'Nyelvek',
        New: 'Új',
        Active: 'Aktív',
        Inactive: 'Inaktív',
        WaitForActivation: 'Várja meg az aktiválást',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Alacsony',
        Medium: 'Közepes',
        High: 'Magas',
        Hidden: 'Rejtett',
        Brand: 'Márka',
        Group: 'Csoport',
        ChangeStatus: 'Állapotváltozás',
        AuthMail: 'Auth Mail',
        SetDomainSettings: 'Domain beállítások megadása',
        Domain: 'Tartomány*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'Fájl csatolása',
        ChannelSettings: 'Csatornabeállítások',
        GeneralSettings: 'Általános beállítások',
        WebChatConstructor: 'Web Chat Constructor',
        BackgroundColor: 'Háttérszín',
        MessageColor: 'Üzenet színe',
        URL: 'URL',
        ChannelName: 'Csatorna neve',
        ChannelURL: 'Csatorna URL-je',
        AllowSMS: 'SMS engedélyezése',
        AllowShowAllClientDialogs: 'Az összes kliens párbeszédpanel megjelenítésének engedélyezése',
        AllowCaptcha: 'Captcha engedélyezése',
        InboxSettings: 'Beérkezett üzenetek beállításai',
        DistributionOfNewDialogs: 'Új párbeszédpanelek elosztása az online ügynökök között',
        ThereAreNoAvailableMessengers: 'Nincsenek elérhető üzenetküldők a csatornához! Kérjük, hozza létre azokat a csatornát birtokló márka számára!',
        HeaderColorScheme: 'Fejléc színséma',
        GreetingColorScheme: 'Üdvözlő színséma',
        QuickPhrases: 'Gyors kifejezések színséma',
        ClientMessage: 'Ügyfélüzenet színséma',
        AgentMessage: 'Ügynök üzenet színséma',
        EndDialogModal: 'Modális színséma befejezése párbeszédpanelen',
        IconColorScheme: 'Ikon színséma',
        ScrollColorScheme: 'Görgetés színséma',
        ChatWindowColor: 'Chat ablak színséma',
        FooterColorScheme: 'Lábléc színséma',
        AuthColorScheme: 'Auth színséma',
        HeaderBackground: 'Fejléc háttér',
        HeaderBorderColor: 'Fejléc Szegély színe',
        ColorGreetingBotName: 'Üdvözlő Bot név színe',
        MessageGreetingIcon: 'Üdvözlés ikon háttér',
        MessageGreetingColor: 'Üdvözlő szöveg színe',
        MessageGreetingBorderColor: 'Üdvözlő üzenet Szegély színe',
        MessageGreetingBackgroundColor: 'Üdvözlő üzenet Háttérszín',
        QuickPhrasesColor: 'Gyors kifejezés Szöveg színe',
        QuickPhrasesBackground: 'Gyors kifejezés háttér',
        QuickPhrasesBorderColor: 'Gyors kifejezés Határszín',
        QuickPhrasesColorHover: 'Gyors kifejezés Lebegő szín',
        QuickPhrasesBackgroundHover: 'Gyors kifejezés Háttér lebeg',
        QuickPhrasesBorderColorHover: 'Gyors kifejezés Szegélyszín lebeg',
        ClientMessageColor: 'Ügyfél üzenet Szöveg színe',
        ClientTimeColor: 'Ügyfélidő színe',
        ClientMessageBackground: 'Ügyfél üzenete Háttérszín',
        ClientMessageBorderColor: 'Ügyfél üzenete Szegély színe',
        ClientLink: 'Ügyféllink',
        ClientLinkHover: 'Mutasson az ügyféllinkre',
        ClientActiveLink: 'Aktív ügyfél hivatkozás',
        ClientVisitedLink: 'Ügyfél látogatott linkje',
        ColorAgentNameAndTime: 'Ügynök neve és idő színe',
        MessageAgentColor: 'Ügynök üzenet színe',
        MessageAgentBackgroundColor: 'Ügynök üzenet háttér',
        MessageAgentBorderColor: 'Ügynök üzenet Szegély színe',
        AgentLink: 'Ügynök hivatkozás',
        AgentHoverLink: 'Agent Hover link',
        AgentActiveLink: 'Aktív ügynök hivatkozás',
        AgentVisitedLink: 'Ügynök Látogatott link',
        MessageIconBackgroundAgent: 'Ügynök üzenet ikon háttérszíne',
        TextColor: 'Szöveg szín',
        BackdropColor: 'Háttér színe',
        BackdropColorHover: 'Háttérszín lebeg',
        ButtonColor: 'Gomb színe',
        BackdropColorBtn: 'Háttér gomb háttérszíne',
        IconBackground: 'Ikon háttér',
        ChatWindowsBodyBackground: 'Chat ablakok test háttérszíne',
        ChatWindowsBorderColor: 'A chat ablakok szegélyszíne',
        FooterBackground: 'Lábléc háttérszíne',
        FooterBorderColor: 'A lábléc szegélyének színe',
        FooterBackgroundTextArea: 'A lábléc szövegterületének háttérszíne',
        InputBorderColor: 'Szegély színének bevitele',
        RegistrationBackgroundColor: 'Regisztráció háttérszíne',
        LabelColor: 'Címke színe',
        LabelErrorColor: 'Címkehiba színe',
        InputColor: 'Beviteli szín',
        InputBackground: 'Bemeneti háttér',
        SignUpColor: 'Regisztráljon szín',
        SignUpBackground: 'Regisztráljon háttérszínt',
        Chat: 'Csevegés',
        Minimized: 'Minimalizált',
        ScrollBackground: 'Görgetés a háttérben',
        ScrollThumbBackground: 'Lapozás hüvelykujj háttér',
        UniqueClientIdentifier: 'Egyedi ügyfélazonosító',
        AgentsSettings: 'Ügynökök beállításai',
        ThisAgentSuperAdmin: 'Ez az ügynök kiemelt rendszergazda, kattintson a gombra a kiemelt rendszergazdai jogok eltávolításához',
        ThisAgentNotSuperAdmin: 'Ez az ügynök nem szuper admin, kattintson a gombra, hogy szuper adminisztrátor legyen',
        YouCanAssignBrandTheBrand: 'Ehhez hozzárendelheti az admin márkáját, ehhez ki kell választania a márkát',
        Role: 'Szerep',
        BanningThisAgent: 'Az ügynök kitiltása azt jelenti, hogy nem tudnak majd bejelentkezni a platformra. Továbbra is megjelennek a rendszerben.',
        Report: 'Jelentés',
        SelectLanguageWebChat: 'Válasszon nyelveket Web Chat',
        FirstName: 'Keresztnév',
        PhoneNumber: 'Telefonszám',
        Info: 'Info',
        WebChatSettings: 'Webes csevegés beállításai',
        IntegrationWithOtherCRM: 'Integráció más CRM-mel',
        SettingUpInteractionBetweenSystems: 'A rendszerek közötti interakció beállítása',
        QuickReplies: 'Gyors válaszok',
        BotSettings: 'Bot beállítások',
        BotName: 'Bot neve',
        EnabledPrivacyPolicy: 'Engedélyezett adatvédelmi szabályzat',
        PleaseChooseRequiredFields: 'Kérjük, válassza ki a kötelező mezőket',
        ChangeGreetingsAndPhrases: 'Üdvözlet és kifejezés módosítása, Adatvédelmi szabályzat',
        ChangeSmsSettings: 'Módosítsa az SMS beállításokat',
        ChangeCountries: 'Válts országot',
        ContactsAreWorkingWithClientUniqueness: 'A kapcsolattartók az ügyfél egyediségével dolgoznak. A mező kitöltése kötelező, ha az ügyfél egyedisége megegyezik vele.',
        ClientUniquenessIs: 'Az ügyfél egyedisége:',
        PrivacyPolicyText: 'Adatvédelmi szabályzat szövege',
        PrivacyPolicyLink: 'Adatvédelmi szabályzat link',
        PrivacyPolicyLinkText: 'Adatvédelmi szabályzat hivatkozás szövege',
        AgentPhone: 'Ügynök telefon',
        HeaderSettings: 'Fejléc beállításai',
        DisableDraggable: 'A húzhatóság letiltása',
        DisableHeaderFullScreen: 'Teljes képernyős fejléc letiltása',
        DisableHeaderHideChatWindow: 'A fejléc elrejtése csevegőablak letiltása',
        DisableHeaderLogo: 'Fejléc logójának letiltása',
        DisableHeaderShowEndDialogModal: 'A fejléc megjelenítésének vége párbeszédpanel mód letiltása',
        HeaderText: 'Fejléc',
        HeaderTextTop: 'Fejléc szöveg felső',
        HeaderTextBottom: 'Fejléc szöveg alján',
        HeaderHeight: 'Fejléc magassága',
        LogoIconUrl: 'Logo ikon URL',
        IconUrl: 'Ikon URL',
        IconSettings: 'Ikonbeállítások',
        ScrollSettings: 'Görgessen Beállítások',
        ScrollWidth: 'Görgetés szélessége',
        ChatWindowsLogo: 'Chat windows logó',
        BackgroundImageSize: 'Háttér kép mérete',
        GreetingSettings: 'Üdvözlő beállítások',
        GreetingIconBotUrl: 'Üdvözlő ikon bot URL-je',
        QuickPhrasesSettings: 'Gyors kifejezések beállításai',
        ClientMessageSettings: 'Ügyfél üzenet beállításai',
        AgentMessageSettings: 'Ügynök üzenet beállításai',
        AgentMessageIconUrl: 'Ügynöki üzenet ikon URL-je',
        EndDialogModalSettings: 'Befejezés párbeszédpanel modális beállítások',
        AuthWindowSettings: 'Auth ablak beállításai',
        FooterSettings: 'Lábléc beállításai',
        WriteMessagesPlaceholder: 'Üzenetek írása helyőrző',
        SendImageUrl: 'Kép URL küldése',
        SendButtonIconHeight: 'Küldés gomb ikon magassága',
        SendButtonIconWidth: 'Küldés gomb ikon szélessége',
        ChatWindowSettings: 'Csevegőablak beállításai',
        ClearAllPhrase: 'Az összes kifejezés törlése',
        AreYouSure: 'Biztos vagy ebben?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Ez a művelet az összes kifejezést és kategóriát eltávolítja',
        AddAgentToGroup: 'Ügynökök hozzáadása egy csoporthoz',
        CreateClient: 'Kliens létrehozása',
        ClientsImport: 'Ügyfelek importálása',
        UploadExcelFile: 'Töltse fel az Excel fájlt',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Kattintson a gémkapocs ikonra az Excel fájl kiválasztásához',
        Contacts: 'Kapcsolatok',
        Personal: 'Személyes',
        FileUploadHint: 'Kérjük, töltsön fel egy excel fájlt telefonszámokkal. Az Excel fájlnak tartalmaznia kell egy „Telefonok” nevű oszlopot, és ebbe az oszlopba kell beírni a telefonszámokat. Csak .xls vagy .xlsx fájlok importálhatók.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Üdvözlet és kifejezés módosítása, Adatvédelmi szabályzat',
    },
    AdditionalText: {
        AgentProfile: 'Ügynök profil',
        OutOf: 'kívül',
    },
    Pages: {
        Dialogs: {
            Title: 'Párbeszédek',
            TableColumns: {
                Status: 'Állapot',
                Dialog: 'Párbeszéd',
                Messenger: 'Hírnök',
                Channel: 'Csatorna',
                Tag: 'Címke',
                Agent: 'Ügynök',
                Rating: 'Értékelés',
                Language: 'Nyelv',
                MessagesCount: 'Az üzenetek számítanak',
                FirstMessage: 'Első üzenet',
                LastActivity: 'Last Tevékenység',
            },
            Messages: 'Üzenetek',
        },
        AgentGroups: {
            Title: 'Ügynökcsoportok',
            TableColumns: {
                Groups: 'Csoportok',
                Description: 'Leírás',
                AccessLevel: 'Hozzáférési szint',
                WhiteListTags: 'Fehér lista címkék',
                CreatedAt: 'Létrehozva:',
                UpdatedAt: 'Frissítve',
            },
        },
        Agents: {
            Title: 'Ügynökök',
            TableColumns: {
                AgentStatus: 'Ügynök állapota',
                Agents: 'Ügynökök',
                Email: 'Email',
                Groups: 'Csoportok',
                CreatedAt: 'Létrehozva',
                LastActivity: 'Frissítve',
            },
        },
        Tags: {
            Title: 'Címkék',
            TableColumns: {
                Tags: 'Tags',
                Dialogs: 'Párbeszédek',
                Agents: 'Ügynökök',
                UsefulLinks: 'Hasznos Linkek',
                Status: 'Állapot',
                CreatedAt: 'Létrehozva',
                UpdatedAt: 'Frissítve',
            },
        },
        Clients: {
            Title: 'Ügyfelek',
            TableColumns: {
                SearchName: 'név',
                AgentName: 'Ügynök neve',
                Messenger: 'Hírnök',
                LastTag: 'Utolsó címke',
                ActiveDialog: 'Aktív rendelkezik',
                Brand: 'Márka',
                InternalId: 'Belső azonosító',
                ExternalId: 'Külső azonosító',
                Name: 'Ügyfél Neve',
                FirstName: 'Keresztnév',
                LastName: 'Vezetéknév',
                Email: 'Email',
                InvoiceNumber: 'Számlaszám',
                DialogsCount: 'Párbeszédek száma',
                FirstMessage: 'Első üzenet',
                LastActivity: 'Frissítve',
                Tag: 'Címke',
                Status: 'Állapot',
                Channel: 'Csatorna',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Csatornák',
            TableColumns: {
                ChannelId: 'Csatornaazonosító',
                ChannelKey: 'Csatorna gomb',
                ChannelName: 'Csatorna neve',
                Language: 'Nyelv',
                Url: 'Url',
                CreatedAt: 'Létrehozva',
                UpdatedAt: 'Frissítve',
            },
        },
        Brands: {
            Title: 'Márkák',
            TableColumns: {
                BrandsId: 'Márkaazonosító',
                BrandName: 'Márkanév',
                ClientUniqueness: 'Ügyfél egyedisége',
                BrandChannels: 'Márkacsatornák',
                DialogCount: 'Párbeszédek számítanak',
                CreatedAt: 'Létrehozva',
                UpdatedAt: 'Frissítve',
            },
        },
        Reports: {
            Title: 'Jelentések',
            ByMessengersTab: {
                Title: 'A hírnökök jelentése',
                Columns: {
                    Messenger: 'Hírnök',
                    ReceivedDialogs: 'Fogadott párbeszédpanelek',
                    PositiveDialogs: 'Pozitív párbeszédek, %',
                    NegativeDialogs: 'Negatív párbeszédpanelek, %',
                    UnratedDialogs: 'Besorolatlan párbeszédpanelek, %',
                    AvgResponseTime: 'Átlagos válaszidő',
                },
            },
            ByAgentsTab: {
                Title: 'Ügynökök jelentése',
                Columns: {
                    agentFullName: 'Az ügynök teljes neve',
                    agentSystemId: 'Ügynökrendszer azonosítója',
                    brandName: 'Márkanév',
                    receivedDialogs: 'Fogadott párbeszédpanelek',
                    dialogsTelegram: 'Dialógusok Telegram',
                    dialogsWebChat: 'Dialógusok Web Chat',
                    dialogsViber: 'Dialógusok Viber',
                    dialogsWhatsApp: 'Párbeszédek WhatsApp',
                    dialogsFacebook: 'Párbeszédek Facebook',
                    dialogsEmail: 'Párbeszédek Email',
                    dialogsSms: 'Párbeszédek SMS',
                    positiveDialogs: 'Pozitív párbeszédek, %',
                    negativeDialogs: 'Negatív párbeszédpanelek, %',
                    unratedDialogs: 'Besorolatlan párbeszédpanelek, %',
                    avgResponseTime: 'Átlagos válaszidő',
                },
                noDataForThisDay: 'Nincs adat erre a napra',
            },
            ByGroupsTab: {
                Title: 'Jelentés csoportonként',
                Columns: {
                    groupSystemId: 'Csoportrendszer azonosító',
                    groupName: 'Csoport név',
                    brandName: 'Márkanév',
                    receivedDialogs: 'Fogadott párbeszédpanelek',
                    positiveDialogs: 'Pozitív párbeszédpanelek, %',
                    negativeDialogs: 'Negatív párbeszédpanelek, %',
                    unratedDialogs: 'Besorolatlan párbeszédpanelek, %',
                    avgResponseTime: 'Átlagos válaszidő',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Szűrő hozzáadása',
        reset: 'Visszaállítás',
        createClient: 'Kliens létrehozása',
        importClient: 'Ügyfelek importálása',
        createChannel: 'Csatorna létrehozása',
        previous: 'Előző',
        next: 'Következő',
        addPhrase: 'Kifejezés hozzáadása',
        addGroup: 'Сsoport hozzáadása',
        createBrand: 'Márka létrehozása',
        addTag: 'Címke hozzáadása',
        save: 'Megment',
        send: 'Küld',
        getStatistics: 'Szerezzen statisztikákat',
        setCountries: 'Állítsa be az országokat',
        changeStatus: 'Állapotváltozás',
        upload: 'Feltöltés',
        setDomainSettings: 'Állítsa be a domain beállításokat',
        login: 'Belépés',
        update: 'Frissítés',
        resetPassword: 'Jelszó visszaállítása',
        addNew: 'Új hozzáadása',
        addNewPhrase: 'Új kifejezés hozzáadása',
        addNewCategory: 'Új kategória hozzáadása',
        clearAll: 'Mindent kitöröl',
        loginWithGoogle: 'Jelentkezzen be a Google-lal',
        loginWithFacebook: 'Jelentkezz be facebookal',
        onPage: 'az oldalon',
        addNewUsefulLinks: 'Új hasznos linkek hozzáadása',
        backToChannels: 'Vissza a csatornákhoz',
        defaultSettings: 'Alapbeállítások',
        upgradeChannel: 'Csatorna frissítése',
        apply: 'Alkalmaz',
        remove: 'Távolítsa el',
        removeSuperAdmin: 'Szuper adminisztrátor eltávolítása',
        makeSuperAdmin: 'Legyen szuper admin',
        makeBrandAdmin: 'Legyen márkaadminisztrátor',
        removeBrandAdmin: 'Márkaadminisztrátor eltávolítása',
        ban: 'Tilalom',
        unban: 'Feloldja a kitiltást',
        makeTeamLead: 'Legyen csapatvezető',
        removeTeamLead: 'Távolítsa el a csapatvezetőt',
        signUp: 'Regisztrálj',
        ok: 'Ok',
        cancel: 'Megszünteti',
        bulkMessaging: 'Bulk Messaging',
        back: 'Vissza',
    },
    Countries: {
        UnitedStates: 'Egyesült Államok',
        Canada: 'Kanada',
        Australia: 'Ausztrália',
        CzechRepublic: 'Cseh Köztársaság',
        Denmark: 'Dánia',
        Hungary: 'Magyarország',
        Netherlands: 'Hollandia',
        Sweden: 'Svédország',
        UnitedKingdom: 'Egyesült Királyság',
        France: 'Franciaország',
        Germany: 'Németország',
        Poland: 'Lengyelország',
        Italy: 'Olaszország',
        Spain: 'Spanyolország',
    },
    Languages: {
        Ukrainian: 'Ukrán',
        Russian: 'Orosz',
        English: 'Angol',
        Polish: 'Fényesít',
        Deutsch: 'Deutsch',
        Spanish: 'Spanyol',
        Vietnamese: 'Vietnami',
        Portuguese: 'Portugál',
        Korean: 'Koreai',
        Italian: 'Olasz',
        Arabic: 'Arab',
        French: 'Francia',
        Azerbaijan: 'Azerbajdzsán',
        Hungarian: 'Magyar',
    },
    BulkMessaging: {
        exclude: 'Kizárás',
        typeText: 'Írjon be szöveget',
        selectClients: 'Válasszon ügyfeleket',
        uploadClients: 'Ügyfelek feltöltése',
        sendingMessagesToNewClients: 'Üzenetek küldése új ügyfeleknek',
        sendingMessagesToExistingClients: 'Üzenetek küldése meglévő ügyfeleknek',
        clientsWhoHaveDialogsWithFollowingStatus: 'Ügyfelek, akiknek a következő állapotú párbeszédablakai vannak',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Ügyfelek, akiknél ez a szöveg szerepel a névben, vezetéknévben vagy felhasználónévben',
        limitQuantity: 'Korlátozza a mennyiséget',
        quantityLimit: 'Mennyiségi korlát',
        limitQuantityText: 'Korlátozza az adás mennyiségét a megadott számú címzettnél nem többre. A rendszer újabb kliensei bekerülnek a listába.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Ügyfelek, akik az utolsó üzenetküldés előtt írtak az AskMe-nek',
    },
};
export default Messages;
