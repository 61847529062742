import React from 'react';
import Icon from '@src/components/Icon';
import { StyledList } from './styled';
export var List = function (_a) {
    var list = _a.list, position = _a.position;
    return (React.createElement(StyledList, { position: position },
        React.createElement("div", { className: "items-list" }, list.map(function (_a) {
            var title = _a.title, onClick = _a.onClick, icon = _a.icon;
            return (React.createElement("div", { key: title, className: "item", onClick: onClick },
                icon && React.createElement(Icon, { src: icon }),
                React.createElement("p", { className: "title" }, title)));
        }))));
};
