import React from 'react';
import { connect } from 'react-redux';
import { notifications } from '@public/images';
import { Messages } from '@public/locales/messages';
import Button from '@src/components/Button';
import { CancelButton, OkButton } from '@src/components/Button/styled';
import Icon from '@src/components/Icon';
import { notificationTypes } from '@src/global-interfaces';
import { notificationActions } from '@src/redux/notification/reducer';
import { StyledNotification } from './styled';
var getNotificationIcon = function (type) {
    var error = notifications.error, info = notifications.info, success = notifications.success, warning = notifications.warning;
    var icon;
    switch (type) {
        case notificationTypes.error: {
            icon = error;
            break;
        }
        case notificationTypes.success: {
            icon = success;
            break;
        }
        case notificationTypes.warning: {
            icon = warning;
            break;
        }
        case notificationTypes.info: {
            icon = info;
            break;
        }
        default: {
            icon = '';
        }
    }
    return icon;
};
export function Notification(_a) {
    var text = _a.text, type = _a.type;
    var icon = getNotificationIcon(type);
    var iconStyles = {
        width: '17px',
        height: '17px',
    };
    return (React.createElement(StyledNotification, null,
        React.createElement("div", { className: "notify-wrapper" },
            React.createElement(Icon, { src: icon, styles: iconStyles }),
            React.createElement("p", null, text))));
}
function Confirm(_a) {
    var text = _a.text, resetNotificationState = _a.resetNotificationState, action = _a.action;
    var icon = getNotificationIcon(notificationTypes.warning);
    var iconStyles = {
        width: '17px',
        height: '17px',
    };
    var onOk = function () {
        action();
        resetNotificationState();
    };
    return (React.createElement(StyledNotification, null,
        React.createElement("div", { className: "notify-wrapper" },
            React.createElement(Icon, { src: icon, styles: iconStyles }),
            React.createElement("p", null, text)),
        React.createElement("div", { className: "buttons-block" },
            React.createElement(CancelButton, null,
                React.createElement(Button, { onClick: resetNotificationState, title: Messages.Buttons.cancel })),
            React.createElement(OkButton, null,
                React.createElement(Button, { onClick: onOk, title: Messages.Buttons.ok })))));
}
var mapDispatchToProps = function (dispatch) { return ({
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(null, mapDispatchToProps)(Confirm);
