import React from 'react';
import { StyledAskMeWebLibraryWrapper } from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatWidows/Auth/styled';
import Header from '../Header/Header';
import Steps from './components/Steps';
var Auth = function (props) {
    var _a;
    var lang = props.lang, privacyPolicy = props.privacyPolicy, customizations = props.customizations, requiredFields = props.requiredFields;
    var auth = customizations.auth;
    var registrationBackgroundColor = auth.registrationBackgroundColor;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { lang: lang, customizations: customizations, isAuthHeader: true }),
        React.createElement(StyledAskMeWebLibraryWrapper, { className: "AskMeWebLibrary-auth-window", ref: function (el) {
                if (el)
                    el.style.setProperty('background-color', registrationBackgroundColor || '#F5F5F5', 'important');
            } },
            React.createElement(Steps, { auth: auth, privacyPolicy: privacyPolicy, requiredFields: requiredFields, lang: ((_a = lang[0]) === null || _a === void 0 ? void 0 : _a.value) || '' }))));
};
export default Auth;
