var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledCategoryWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    padding: 10px;\n    display: flex;\n    user-select: none;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    img {\n      max-width: 50px;\n      max-height: 50px;\n      filter: ", ";\n    }\n    p {\n      padding-top: 10px;\n      text-align: center;\n      color: ", ";\n    }\n  }\n"], ["\n  && {\n    padding: 10px;\n    display: flex;\n    user-select: none;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    img {\n      max-width: 50px;\n      max-height: 50px;\n      filter: ", ";\n    }\n    p {\n      padding-top: 10px;\n      text-align: center;\n      color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.invert;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1;
