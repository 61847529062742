export var responsesBotAnswerType = [
    { text: 'Type response off', value: '' },
    { text: 'Text to text', value: 'textToText' },
    { text: 'Text to audio', value: 'textToAudio' },
    { text: 'Audio to audio', value: 'audioToAudio' },
    { text: 'Audio to text', value: 'audioToText' },
    { text: 'Picture to improved picture', value: 'pictureToImprovedPicture' },
    { text: 'Picture to colorization', value: 'pictureToColorization' },
    { text: 'Translation of the file', value: 'translationOfTheFile' },
    { text: 'File summary', value: 'fileSummary' },
    { text: 'Summary of the picture', value: 'summaryOfThePicture' },
    { text: 'Search for a picture', value: 'SearchForAPicture' },
];
export var mimeTypes = {
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'text/plain': ['.txt'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'image/webp': ['.webp'],
};
export var mimeTypesList = {
    '.pdf': 'application/pdf',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.txt': 'text/plain',
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.webp': 'image/webp',
};
