import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import GroupsChannel from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsChannel/GroupsChannel';
var mapStateToProps = function (state) { return ({
    assignedChannelsToGroup: groupsStore.getAssignedChannelsToGroup(state),
    unassignedChannelsToGroup: groupsStore.getUnassignedChannelsToGroup(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    removeOrAddChannelsFromGroup: function (data) { return dispatch(groupsActions.removeOrAddChannelsFromGroup(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GroupsChannel);
