import { Messages } from '@public/locales/messages';
import { tableRenderers } from '@src/components/tableRenderers';
var _a = Messages.Pages.AgentGroups.TableColumns, AccessLevel = _a.AccessLevel, Description = _a.Description, Groups = _a.Groups, CreatedAt = _a.CreatedAt, UpdatedAt = _a.UpdatedAt, WhiteListTags = _a.WhiteListTags;
export var groupsColumns = [
    {
        title: Groups,
        key: 'name',
        render: tableRenderers.TextEllipsisRenderer,
        sortingEnabled: true,
    },
    {
        title: Description,
        key: 'description',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: AccessLevel,
        key: 'access_level',
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: WhiteListTags,
        key: 'whiteListTags',
        render: tableRenderers.ArrayTextRenderer,
    },
    {
        title: CreatedAt,
        key: 'createdAt',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: UpdatedAt,
        key: 'updatedAt',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
];
