import { connect } from 'react-redux';
import { appStore } from '@src/redux/app/selector';
import { toolBarStore } from '@src/modules/ToolBar/redux/selector';
import { toolBarActions } from '@src/modules/ToolBar/redux/reducer';
import Filtration from '@src/modules/ToolBar/ToolBarUI/Filtration/Filtration';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    filters: toolBarStore.getFilters(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    addFilters: function (data) { return dispatch(toolBarActions.addFilters(data)); },
    deleteFilters: function (data) { return dispatch(toolBarActions.deleteFilters(data)); },
    resetFilters: function (data) { return dispatch(toolBarActions.resetFilters(data)); },
    setShowSelectedFilter: function (data) { return dispatch(toolBarActions.setShowSelectedFilter(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Filtration);
