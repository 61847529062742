import { createSelector } from '@reduxjs/toolkit';
var agentsState = function (state) { return state.pages.agents; };
var agentsStore = {
    getCurrentAgent: createSelector(agentsState, function (state) { return state.currentAgent; }),
    getTagsCurrentAgent: createSelector(agentsState, function (state) { return state.tagsCurrentAgent; }),
    getCurrentAgentRoles: createSelector(agentsState, function (state) { return state.currentAgentRoles; }),
    getCurrentAgentGroups: createSelector(agentsState, function (state) { return state.currentAgentGroups; }),
    getAvailableGroupsCurrentAgent: createSelector(agentsState, function (state) { return state.availableGroupsCurrentAgent; }),
    getCountAgentsNewBees: createSelector(agentsState, function (state) { return state.countAgentsNewBees; }),
};
export default agentsStore;
