import React from 'react';
import Header from '@src/modules/Header';
import Button from '@src/components/Button';
import Toolbar from '@src/modules/ToolBar';
import TableComponent from '@src/modules/Pages/TableComponent';
import CreateGroupsModal from '@src/modules/Pages/Groups/GroupsCreateModal';
import GroupsUpdateModal from '@src/modules/Pages/Groups/GroupsUpdateModal';
import { StyledAddBtn } from '@src/components/Button/styled';
import { StyledContent, StyledPageWrapper } from '@src/app/styled';
import { Messages } from '@public/locales/messages';
var GroupsPage = function (props) {
    var toggleModal = props.toggleModal, currentPage = props.currentPage, isModalOpenUpdateGroups = props.isModalOpenUpdateGroups, isModalOpenCreateGroups = props.isModalOpenCreateGroups;
    return (React.createElement(StyledPageWrapper, { className: "groups-page" },
        React.createElement(Header, null),
        React.createElement(StyledContent, { currentPage: currentPage },
            React.createElement(StyledAddBtn, null,
                React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addGroup), onClick: toggleModal })),
            React.createElement(Toolbar, null),
            React.createElement(TableComponent, null)),
        isModalOpenCreateGroups && React.createElement(CreateGroupsModal, null),
        isModalOpenUpdateGroups && React.createElement(GroupsUpdateModal, null)));
};
export default GroupsPage;
