var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
var StepsContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  direction: ", "\n  text-align: ", "\n"], ["\n  direction: ", "\n  text-align: ", "\n"])), function (_a) {
    var lang = _a.lang;
    return (lang === 'ar' ? 'rtl' : 'ltr');
}, function (_a) {
    var lang = _a.lang;
    return (lang === 'ar' ? 'right' : 'left');
});
export default StepsContent;
var templateObject_1;
