import { IPages } from '@src/global-interfaces';
import { entitiesApi } from '@src/redux/entities/api';
export var getEntityRequest = function (pageKey) {
    switch (pageKey) {
        case IPages.dialogs: {
            return entitiesApi.getDialogs;
        }
        case IPages.groups: {
            return entitiesApi.getGroups;
        }
        case IPages.agents: {
            return entitiesApi.getAgents;
        }
        case IPages.tags: {
            return entitiesApi.getTags;
        }
        case IPages.clients: {
            return entitiesApi.getClients;
        }
        case IPages.channels: {
            return entitiesApi.getChannels;
        }
        case IPages.brands: {
            return entitiesApi.getBrands;
        }
        case IPages.messengerReports: {
            return entitiesApi.getReportMessengers;
        }
        case IPages.agentsReports: {
            return entitiesApi.getReportAgents;
        }
        case IPages.groupsReports: {
            return entitiesApi.getGroupsReport;
        }
        default:
            throw new Error('No matches pageKey with api request => Entities.ts');
    }
};
