var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Messages } from '@public/locales/messages';
export var mapMessengersData = function (messengers) { return messengers.map(function (messenger) { return (__assign(__assign({}, messenger), { name: messenger.name, receivedCount: messenger.receivedCount, positivePercent: messenger.positivePercent, negativePercent: messenger.negativePercent, unratedPercent: messenger.unratedPercent, avgResponseTime: messenger.avgResponseTime })); }); };
export var mapAgentsReportData = function (agentReport, dictionaries) {
    var brandDictionary = dictionaries.brandDictionary, agentDictionary = dictionaries.agentDictionary;
    return agentReport.map(function (agentReport) { return (__assign(__assign({}, agentReport), { agentName: agentDictionary[agentReport.agentId] || Messages.Controls.NoData, id: agentReport.agentId, brandName: brandDictionary[agentReport.brandId] || Messages.Controls.NoData, onlineTime: agentReport.onlineTime, breakTime: agentReport.breakTime, receivedCount: agentReport.receivedCount, telegram: agentReport.messengers.telegram, smartbot: agentReport.messengers.smartbot, viber: agentReport.messengers.viber, whatsApp: agentReport.messengers.whatsapp, email: agentReport.messengers.email, sms: agentReport.messengers.sms, messenger: agentReport.messengers.messenger, positivePercent: agentReport.positivePercent, negativePercent: agentReport.negativePercent, unratedPercent: agentReport.unratedPercent, avgResponseTime: agentReport.avgResponseTime })); });
};
export var mapGroupsReportData = function (groupsReports, dictionaries) {
    var brandDictionary = dictionaries.brandDictionary, groupDictionary = dictionaries.groupDictionary;
    return groupsReports.map(function (groupReports) { return (__assign(__assign({}, groupReports), { groupId: groupReports.groupId || Messages.Controls.NoData, groupName: groupDictionary[groupReports.groupId] || Messages.Controls.NoData, brandName: brandDictionary[groupReports.brandId] || Messages.Controls.NoData, missedPercent: groupReports.missedPercent, positivePercent: groupReports.positivePercent, negativePercent: groupReports.negativePercent, unratedPercent: groupReports.unratedPercent, avgResponseTime: groupReports.avgResponseTime, receivedCount: groupReports.receivedCount })); });
};
