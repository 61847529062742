var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
var WHITE = COLORS.WHITE, ALERT = COLORS.ALERT, MAIN_DARK = COLORS.MAIN_DARK;
export var StyledBulkMessagingWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  min-height: 25vh;\n  max-height: 55vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  min-height: 25vh;\n  max-height: 55vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledBulkMessagingInput = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    .input-container {\n      background: ", ";\n    }\n    input {\n      background: ", ";\n      height: 32px;\n    }\n  }\n"], ["\n  && {\n    .input-container {\n      background: ", ";\n    }\n    input {\n      background: ", ";\n      height: 32px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.backgroundBulkMessagingInput;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.backgroundBulkMessagingInput;
});
export var StyledImportPhonesWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    background: ", ";\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n    padding: 10px 12px;\n  }\n"], ["\n  && {\n    background: ", ";\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n    padding: 10px 12px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.backgroundBulkMessagingInput;
});
export var StyledImportPhonesFileName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    margin-left: 8px;\n  }\n"], ["\n  && {\n    margin-left: 8px;\n  }\n"])));
export var SendFilePhonesButtonWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    max-width: max-content;\n    max-height: max-content;\n    border-radius: 8px;\n    background: transparent;\n    cursor: pointer;\n    transition: 0.5s;\n  }\n"], ["\n  && {\n    max-width: max-content;\n    max-height: max-content;\n    border-radius: 8px;\n    background: transparent;\n    cursor: pointer;\n    transition: 0.5s;\n  }\n"])));
export var SendFilePhonesButtonInputLabel = styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    justify-content: center;\n\n    img {\n      width: 15px;\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    justify-content: center;\n\n    img {\n      width: 15px;\n    }\n  }\n"])));
export var SendFilePhonesButtonInput = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    opacity: 0;\n    width: 0;\n    height: 0;\n    position: absolute;\n    z-index: -1;\n  }\n"], ["\n  && {\n    opacity: 0;\n    width: 0;\n    height: 0;\n    position: absolute;\n    z-index: -1;\n  }\n"])));
export var StyledUploadClientsWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n    padding: 15px 40px;\n  }\n"], ["\n  && {\n    padding: 15px 40px;\n  }\n"])));
export var StyledBulkMessagingSelectWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    padding: 10px 0 0;\n  }\n"], ["\n  && {\n    padding: 10px 0 0;\n  }\n"])));
export var StyledBulkMessagingCheckboxWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  && {\n    padding: 5px 0 0 0;\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  && {\n    padding: 5px 0 0 0;\n    display: flex;\n    flex-direction: column;\n  }\n"])));
export var StyledSendFilePhonesLabelWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  && {\n    margin-top: 15px;\n    text-align: justify;\n    padding-bottom: 5px;\n  }\n"], ["\n  && {\n    margin-top: 15px;\n    text-align: justify;\n    padding-bottom: 5px;\n  }\n"])));
export var StyledTextarea = styled.textarea(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    resize: none;\n    color: ", ";\n    padding: 10px;\n    background: ", ";\n    border: 1px solid ", ";\n    outline: none;\n    min-height: 70px;\n    ", "\n  }\n"], ["\n  && {\n    width: 100%;\n    resize: none;\n    color: ", ";\n    padding: 10px;\n    background: ", ";\n    border: 1px solid ", ";\n    outline: none;\n    min-height: 70px;\n    ", "\n  }\n"])), MAIN_DARK, function (_a) {
    var theme = _a.theme;
    return theme.colors.backgroundBulkMessagingInput;
}, function (_a) {
    var isErrorText = _a.isErrorText;
    return (isErrorText ? ALERT : WHITE);
}, scrollStyles);
export var StyledTitle = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  && {\n    text-align: center;\n    padding: 15px 0 5px 0;\n    line-height: 16px;\n    font-size: 14px;\n    font-weight: 500;\n    color: ", ";\n  }\n"], ["\n  && {\n    text-align: center;\n    padding: 15px 0 5px 0;\n    line-height: 16px;\n    font-size: 14px;\n    font-weight: 500;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.modalTitle;
});
export var StyledLimitQuantityText = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  && {\n    text-align: justify;\n    padding-bottom: 5px;\n  }\n"], ["\n  && {\n    text-align: justify;\n    padding-bottom: 5px;\n  }\n"])));
export var StyledLoaderWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  && {\n    position: relative;\n    height: 15vh;\n  }\n"], ["\n  && {\n    position: relative;\n    height: 15vh;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
