import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var ChannelPageTypes = {
    CREATE_CHANNEL: "".concat(reduxModules.channelsPage, "-createChannel/createChannel"),
    SHOW_CURRENT_CHANNEL: "".concat(reduxModules.channelsPage, "-currentChannel/showCurrentChannel"),
    DELETE_MESSENGER_FROM_CHANNEL: "".concat(reduxModules.channelsPage, "-changeMessengerChannel/deleteMessengerFromChannel"),
    ADD_MESSENGER_FOR_CHANNEL: "".concat(reduxModules.channelsPage, "-changeMessengerChannel/addMessengerForChannel"),
    DELETE_AND_ADD_MESSENGER_FROM_CHANNEL: "".concat(reduxModules.channelsPage, "-changeMessengerChannel/deleteAndAddMessengerForChannel"),
    UPDATE_CHANNEL: "".concat(reduxModules.channelsPage, "-currentChannel/updateChannel"),
    UPDATE_DEFAULT_CUSTOMIZATION: "".concat(reduxModules.channelsPage, "-layoutWebChat/updateDefaultCustomizations"),
    CREATE_PIPE_DRIVE: "".concat(reduxModules.channelsPage, "-pipeDrive/createPipeDrive"),
    UPDATE_PIPE_DRIVE: "".concat(reduxModules.channelsPage, "-pipeDrive/updatePipeDrive"),
    DELETE_PIPE_DRIVE: "".concat(reduxModules.channelsPage, "-pipeDrive/deletePipeDrive"),
};
var initialState = {
    currentChannel: null,
    currentBrandMessengers: null,
    currentChannelMessengers: null,
};
var channelsSlice = createSlice({
    name: reduxModules.channelsPage,
    initialState: initialState,
    reducers: {
        setCurrentChannel: function (state, action) {
            state.currentChannel = action.payload;
        },
        setCurrentBrandMessengers: function (state, action) {
            state.currentBrandMessengers = action.payload;
        },
        setCurrentChannelMessengers: function (state, action) {
            state.currentChannelMessengers = action.payload;
        },
    },
});
export var channelsActions = {
    updateChannel: createAction(ChannelPageTypes.UPDATE_CHANNEL),
    createChannel: createAction(ChannelPageTypes.CREATE_CHANNEL),
    showCurrentCurrent: createAction(ChannelPageTypes.SHOW_CURRENT_CHANNEL),
    addMessengerForChannel: createAction(ChannelPageTypes.ADD_MESSENGER_FOR_CHANNEL),
    deleteMessengerFromChannel: createAction(ChannelPageTypes.DELETE_MESSENGER_FROM_CHANNEL),
    deleteAndAddMessengerForChannel: createAction(ChannelPageTypes.DELETE_AND_ADD_MESSENGER_FROM_CHANNEL),
    updateDefaultCustomizations: createAction(ChannelPageTypes.UPDATE_DEFAULT_CUSTOMIZATION),
    createPipeDrive: createAction(ChannelPageTypes.CREATE_PIPE_DRIVE),
    updatePipeDrive: createAction(ChannelPageTypes.UPDATE_PIPE_DRIVE),
    deletePipeDrive: createAction(ChannelPageTypes.DELETE_PIPE_DRIVE),
    setCurrentChannel: channelsSlice.actions.setCurrentChannel,
    setCurrentBrandMessengers: channelsSlice.actions.setCurrentBrandMessengers,
    setCurrentChannelMessengers: channelsSlice.actions.setCurrentChannelMessengers,
};
export var channelsReducer = channelsSlice.reducer;
