var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import AgentMessage from './AgentMessage/AgentMessage';
import BotMessage from './BotMessage/BotMessage';
import ClientMessage from './ClientMessage/ClientMessage';
import QuickPhrase from './QuickPhrases/QuickPhrases';
import StyledBody from './StyledBody';
function Body(props) {
    var _a, _b;
    var greeting = props.greeting, customizations = props.customizations, botNameLocales = props.botNameLocales, currentLanguage = props.currentLanguage;
    var scroll = customizations.scroll, header = customizations.header, clientMsg = customizations.clientMsg, chatWindow = customizations.chatWindow, agentMessage = customizations.agentMessage, quickPhrases = customizations.quickPhrases, customizationGreeting = customizations.greeting;
    var chatWindowsLogo = chatWindow.chatWindowsLogo, chatWindowsLogoSize = chatWindow.chatWindowsLogoSize, chatWindowsBorderColor = chatWindow.chatWindowsBorderColor, chatWindowsBodyBackground = chatWindow.chatWindowsBodyBackground;
    var scrollBackground = scroll.scrollBackground, scrollThumbBackground = scroll.scrollThumbBackground, scrollWidth = scroll.scrollWidth;
    var renderThumb = function () { return (React.createElement("div", { className: "scroll-track2", style: { background: scrollBackground || 'black', width: scrollWidth || '10px' } })); };
    var renderTrack = function (props) { return (React.createElement("div", __assign({}, props, { className: "scroll-track3", style: { background: scrollThumbBackground || 'white', width: scrollWidth || '10px' } }))); };
    var headerHeight = header.headerHeight;
    headerHeight = Number.parseInt(headerHeight, 10);
    var bodyHeight = "".concat(350 + (50 - headerHeight), "px");
    return (React.createElement(StyledBody, { bodyHeight: bodyHeight, chatWindowsLogoSize: chatWindowsLogoSize, chatWindowsBorderColor: chatWindowsBorderColor, chatWindowsBodyBackground: chatWindowsBodyBackground },
        React.createElement("div", { className: "body-background-img" }, chatWindowsLogo && React.createElement("img", { src: chatWindowsLogo, alt: "background_chat" })),
        React.createElement(Scrollbars, { renderThumbVertical: renderThumb, renderTrackHorizontal: renderTrack },
            React.createElement(BotMessage, { botNameByLocale: currentLanguage && botNameLocales[currentLanguage[0].value], greetingByCurrentLocale: currentLanguage && ((_a = greeting.find(function (el) { return el.locale === currentLanguage[0].value; })) === null || _a === void 0 ? void 0 : _a.text), customizationGreeting: customizationGreeting }),
            React.createElement(QuickPhrase, { phrasesByCurrentLocale: currentLanguage && ((_b = greeting.find(function (el) { return el.locale === currentLanguage[0].value; })) === null || _b === void 0 ? void 0 : _b.phrases), quickPhrases: quickPhrases }),
            React.createElement(ClientMessage, { clientMsg: clientMsg }),
            React.createElement(AgentMessage, { agentMessage: agentMessage }))));
}
export default Body;
