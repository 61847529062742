var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import Audio from '@src/components/Audio';
import Loader from '@src/components/Loader';
import Msg from '@src/components/MessagesContainer/msg/Msg';
import { StyledHistoryComponentLoader } from '@src/modules/Pages/Clients/HistoryDialog/styled';
var HistoryComponent = function (props) {
    var _a, _b;
    var dialogId = props.dialogId, clientName = props.clientName, agentDictionary = props.agentDictionary, currentAudioCalls = props.currentAudioCalls, currentDialogMessages = props.currentDialogMessages, showClientHistoryDialog = props.showClientHistoryDialog, isLoadingCurrentDialogHistory = props.isLoadingCurrentDialogHistory;
    var _c = __read(useState(''), 2), selectedRecords = _c[0], setSelectedRecords = _c[1];
    useEffect(function () {
        showClientHistoryDialog(dialogId);
    }, [dialogId]);
    return (React.createElement(React.Fragment, null, isLoadingCurrentDialogHistory ? (React.createElement(StyledHistoryComponentLoader, null,
        React.createElement(Loader, null))) : (React.createElement(React.Fragment, null, (_a = currentDialogMessages[dialogId]) === null || _a === void 0 ? void 0 :
        _a.map(function (msg, index) {
            return msg.sender !== 'system' && (React.createElement(Msg, { key: index, text: msg.text, sender: msg.sender, time: msg.time, agentName: agentDictionary[msg.sender], clientName: clientName }));
        }), (_b = currentAudioCalls[dialogId]) === null || _b === void 0 ? void 0 :
        _b.map(function (el) { return (React.createElement(Audio, { createdAt: el.createdAt, filePath: el.filePath, key: el.filePath, recorderState: selectedRecords, setSelectedRecords: setSelectedRecords })); })))));
};
export default HistoryComponent;
