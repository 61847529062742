import React from 'react';
import ReactDOM from 'react-dom';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { DownloadButton } from '@src/components/ProgressBar/styled';
import { useExcelModal } from '@src/modules/Excel/ExcelModal/useExcelModal';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { ModalWindowContent, ModalWindowContentWrap, ModalWindowHeader, ModalWindowWrapper, ProgressBars, } from './styled';
var ExcelModal = function (_a) {
    var excelModalState = _a.excelModalState, excelQueue = _a.excelQueue, clearExcelQueue = _a.clearExcelQueue, toggleExcelModalMinimize = _a.toggleExcelModalMinimize;
    var _b = useExcelModal({ excelQueue: excelQueue, clearExcelQueue: clearExcelQueue, toggleExcelModalMinimize: toggleExcelModalMinimize }), container = _b.container, downloadList = _b.downloadList;
    return ReactDOM.createPortal(React.createElement(ModalWindowWrapper, null,
        React.createElement(ModalWindowContentWrap, null,
            React.createElement(ModalWindowContent, { isMinimized: excelModalState.isMinimized },
                React.createElement(ModalWindowHeader, { isMinimized: excelModalState.isMinimized },
                    React.createElement("div", { className: "excel-modal__head" },
                        React.createElement("img", { src: icons.close, onClick: clearExcelQueue, alt: "closeExcelModal" }),
                        React.createElement("div", { className: "excel-modal__head-title" }, Messages.Message.ExcelFilesDownloading)),
                    React.createElement("div", { className: "excel-modal__icons", onClick: toggleExcelModalMinimize },
                        React.createElement("img", { src: icons.downArrowWhite, alt: "minimizeExcelModal" }))),
                React.createElement(ProgressBars, { isMinimized: excelModalState.isMinimized }, downloadList.map(function (_a) {
                    var id = _a.id, url = _a.url, tableName = _a.tableName;
                    var name = '';
                    switch (tableName) {
                        case 'dialogs': {
                            name = Messages.Pages.Dialogs.Title;
                            break;
                        }
                        case 'groups': {
                            name = Messages.Pages.AgentGroups.Title;
                            break;
                        }
                        case 'agents': {
                            name = Messages.Pages.Agents.Title;
                            break;
                        }
                        case 'tags': {
                            name = Messages.Pages.Tags.Title;
                            break;
                        }
                        case 'clients': {
                            name = Messages.Pages.Clients.Title;
                            break;
                        }
                        case 'channels': {
                            name = Messages.Pages.Channels.Title;
                            break;
                        }
                        case 'brands': {
                            name = Messages.Pages.Brands.Title;
                            break;
                        }
                        case 'messengerReports': {
                            name = Messages.Pages.Reports.ByMessengersTab.Title;
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    return (React.createElement("div", { key: id },
                        React.createElement("div", { className: "progressWrapper" },
                            React.createElement("div", null, "".concat(name, ": ").concat(Messages.Message.excelFileIs, "  ").concat(url ? Messages.Message.Created : Messages.Message.Creating)),
                            React.createElement(ProgressBar, { isFinished: !!url })),
                        React.createElement("a", { href: url, download: true },
                            React.createElement(DownloadButton, { isFinished: !!url },
                                React.createElement("img", { src: icons.downloadIcon, alt: "downloadExcelFile" })))));
                }))))), container);
};
export default ExcelModal;
