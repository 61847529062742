import React from 'react';
import { TableHead } from './TableHead/TableHead';
import { TableRow } from './TableRow/TableRow';
import { TableWrapper, TableWrapperInner } from './styled';
export var CustomClientInfoTable = function (_a) {
    var headNames = _a.headNames, style = _a.style, rows = _a.rows;
    return (React.createElement(TableWrapper, null,
        React.createElement(TableWrapperInner, { textColor: style === null || style === void 0 ? void 0 : style.textColor },
            React.createElement(TableHead, { headNames: headNames, headTextAlign: style === null || style === void 0 ? void 0 : style.headTextAlign, headBackground: style === null || style === void 0 ? void 0 : style.headBackground }),
            rows.map(function (row, i) { return (React.createElement(TableRow, { key: i, rowTextAlign: style === null || style === void 0 ? void 0 : style.rowTextAlign, rowBackground: style === null || style === void 0 ? void 0 : style.rowBackground, rowData: row })); }))));
};
