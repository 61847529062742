var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var ExpandButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 1px;\n  width: 12.5px;\n  height: 12.5px;\n  position: absolute;\n  left: 21.75px;\n  cursor: pointer;\n  & > div {\n    background: ", ";\n    border-radius: 0.5px;\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n  & > div.plus {\n    height: 6px;\n    width: 1px;\n  }\n  & > div.minus {\n    height: 1px;\n    width: 6px;\n  }\n"], ["\n  background: ", ";\n  border-radius: 1px;\n  width: 12.5px;\n  height: 12.5px;\n  position: absolute;\n  left: 21.75px;\n  cursor: pointer;\n  & > div {\n    background: ", ";\n    border-radius: 0.5px;\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n  & > div.plus {\n    height: 6px;\n    width: 1px;\n  }\n  & > div.minus {\n    height: 1px;\n    width: 6px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.clientInfoTableExpandButton;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.tableRow;
});
var templateObject_1;
