import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var GroupsPageTypes = {
    CREATE_AGENT_GROUPS: "".concat(reduxModules.groupsPage, "-createGroups/createAgentGroups"),
    SHOW_GROUPS_SETTINGS: "".concat(reduxModules.groupsPage, "-currentGroups/showGroupsSettings"),
    UPDATE_CURRENT_GROUP: "".concat(reduxModules.groupsPage, "-currentGroups/updateCurrentGroup"),
    REMOVE_AGENT_FROM_GROUP: "".concat(reduxModules.groupsPage, "-currentGroups/removeAgentFromGroup"),
    REMOVE_OR_ADD_TAGS_FROM_GROUP: "".concat(reduxModules.groupsPage, "-currentGroups/removeOrAddTagsFromGroup"),
    REMOVE_OR_ADD_AGENTS_FROM_GROUP: "".concat(reduxModules.groupsPage, "-changeAgentsGroups/removeOrAddAgentsFromGroup"),
    REMOVE_OR_ADD_CHANNELS_FROM_GROUP: "".concat(reduxModules.groupsPage, "-currentGroups/removeOrAddChannelsFromGroup"),
    CREATE_NEW_PHRASE: "".concat(reduxModules.groupsPage, "-usefulPhrases/createNewPhrase"),
    CREATE_NEW_CATEGORY: "".concat(reduxModules.groupsPage, "-usefulPhrases/createNewCategory"),
    UPDATE_PHRASE: "".concat(reduxModules.groupsPage, "-usefulPhrases/updatePhrase"),
    UPDATE_CATEGORY: "".concat(reduxModules.groupsPage, "-usefulPhrases/updateCategory"),
    DELETE_PHRASE: "".concat(reduxModules.groupsPage, "-currentGroups/deletePhrase"),
    DELETE_CATEGORY: "".concat(reduxModules.groupsPage, "-currentGroups/deleteCategory"),
    DELETE_ALL_CATEGORY: "".concat(reduxModules.groupsPage, "-currentGroups/deleteAllCategory"),
};
var initialState = {
    currentGroup: null,
    currentGroupsAgents: null,
    assignedTagsToGroup: null,
    unassignedTagsToGroup: null,
    assignedAgentsToGroup: null,
    unassignedAgentsToGroup: null,
    assignedChannelsToGroup: null,
    unassignedChannelsToGroup: null,
};
var groupsSlice = createSlice({
    name: reduxModules.groupsPage,
    initialState: initialState,
    reducers: {
        setCurrentGroupsAgents: function (state, action) {
            state.currentGroupsAgents = action.payload;
        },
        setCurrentGroup: function (state, action) {
            state.currentGroup = action.payload;
        },
        setAssignedAgentsToGroup: function (state, action) {
            state.assignedAgentsToGroup = action.payload;
        },
        setUnassignedAgentsToGroup: function (state, action) {
            state.unassignedAgentsToGroup = action.payload;
        },
        setAssignedTagsToGroup: function (state, action) {
            state.assignedTagsToGroup = action.payload;
        },
        setUnassignedTagsToGroup: function (state, action) {
            state.unassignedTagsToGroup = action.payload;
        },
        setAssignedChannelsToGroup: function (state, action) {
            state.assignedChannelsToGroup = action.payload;
        },
        setUnassignedChannelsToGroup: function (state, action) {
            state.unassignedChannelsToGroup = action.payload;
        },
    },
});
export var groupsActions = {
    createAgentGroups: createAction(GroupsPageTypes.CREATE_AGENT_GROUPS),
    showGroupsSettings: createAction(GroupsPageTypes.SHOW_GROUPS_SETTINGS),
    updateCurrentGroup: createAction(GroupsPageTypes.UPDATE_CURRENT_GROUP),
    removeAgentFromGroup: createAction(GroupsPageTypes.REMOVE_AGENT_FROM_GROUP),
    removeOrAddTagsFromGroup: createAction(GroupsPageTypes.REMOVE_OR_ADD_TAGS_FROM_GROUP),
    removeOrAddAgentsFromGroup: createAction(GroupsPageTypes.REMOVE_OR_ADD_AGENTS_FROM_GROUP),
    removeOrAddChannelsFromGroup: createAction(GroupsPageTypes.REMOVE_OR_ADD_CHANNELS_FROM_GROUP),
    createNewPhrase: createAction(GroupsPageTypes.CREATE_NEW_PHRASE),
    createNewCategory: createAction(GroupsPageTypes.CREATE_NEW_CATEGORY),
    deletePhrase: createAction(GroupsPageTypes.DELETE_PHRASE),
    deleteCategory: createAction(GroupsPageTypes.DELETE_CATEGORY),
    updatePhrase: createAction(GroupsPageTypes.UPDATE_PHRASE),
    updateCategory: createAction(GroupsPageTypes.UPDATE_CATEGORY),
    deleteAllCategory: createAction(GroupsPageTypes.DELETE_ALL_CATEGORY),
    setCurrentGroup: groupsSlice.actions.setCurrentGroup,
    setCurrentGroupsAgents: groupsSlice.actions.setCurrentGroupsAgents,
    setAssignedTagsToGroup: groupsSlice.actions.setAssignedTagsToGroup,
    setAssignedAgentsToGroup: groupsSlice.actions.setAssignedAgentsToGroup,
    setUnassignedTagsToGroup: groupsSlice.actions.setUnassignedTagsToGroup,
    setUnassignedAgentsToGroup: groupsSlice.actions.setUnassignedAgentsToGroup,
    setAssignedChannelsToGroup: groupsSlice.actions.setAssignedChannelsToGroup,
    setUnassignedChannelsToGroup: groupsSlice.actions.setUnassignedChannelsToGroup,
};
export var groupsReducer = groupsSlice.reducer;
