var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import Sorting from './Sorting/Sorting';
export var TableHeader = function (_a) {
    var columns = _a.columns, sorting = _a.sorting;
    var _b = __read(useState(sorting.column), 2), activeColumn = _b[0], setActiveColumn = _b[1];
    return (React.createElement("div", { className: "table-header" },
        React.createElement("div", { className: "data-row-wrapper" }, columns.map(function (column) {
            var title = column.title, key = column.key, styles = column.styles, sortingEnabled = column.sortingEnabled;
            return (React.createElement("div", { key: key, className: "table-data-cell-wrapper", style: __assign({}, styles) },
                React.createElement("div", { className: "text-and-hints-wrapper" },
                    React.createElement("div", null, title),
                    sortingEnabled
                        && (React.createElement(Sorting, { columnKey: key, sorting: sorting, activeColumn: activeColumn, setActiveColumn: setActiveColumn })))));
        }))));
};
