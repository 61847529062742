var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledCreateBrandWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 40vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 40vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledCheckboxWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  && {\n    display: flex;\n    flex-direction: column;\n  }\n"])));
export var StyledBrandInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"], ["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
