import { createSlice } from '@reduxjs/toolkit';
import { getThemeLS } from '@src/utils/theme';
import { IPages, reduxModules } from '@src/global-interfaces';
import { THEME_TYPES } from './types';
var initialState = {
    theme: getThemeLS() || THEME_TYPES.dark,
    profile: {},
    currentPage: IPages.dialogs,
};
var appSlice = createSlice({
    name: reduxModules.appPage,
    initialState: initialState,
    reducers: {
        saveCurrentPage: function (state, action) {
            state.currentPage = action.payload;
        },
        setTheme: function (state, action) {
            state.theme = action.payload;
        },
        setUser: function (state, action) {
            state.profile = action.payload;
        },
    },
});
export var appActions = {
    setUser: appSlice.actions.setUser,
    setTheme: appSlice.actions.setTheme,
    saveCurrentPage: appSlice.actions.saveCurrentPage,
};
export var appReducer = appSlice.reducer;
