import React from 'react';
import Toolbar from '@src/modules/ToolBar';
import { Tabs, Tab } from '@src/components/Tabs/Tabs';
import TableComponent from '@src/modules/Pages/TableComponent';
import { IPages } from '@src/global-interfaces';
import { Messages } from '@public/locales/messages';
import { StyledContent } from '@src/app/styled';
import { StyledReportTables } from '@src/modules/Pages/Reports/components/ReportsTables/styled';
var _a = Messages.Pages.Reports, ByMessengersTab = _a.ByMessengersTab, ByAgentsTab = _a.ByAgentsTab, ByGroupsTab = _a.ByGroupsTab;
export var ReportsTables = function (_a) {
    var saveCurrentPage = _a.saveCurrentPage;
    var onTabChangeHandler = function (tab) { return saveCurrentPage(tab); };
    return (React.createElement(StyledReportTables, null,
        React.createElement(Tabs, { callback: onTabChangeHandler, isShowBottomLine: false },
            React.createElement(Tab, { tabKey: IPages.messengerReports, title: ByMessengersTab.Title },
                React.createElement(StyledContent, null,
                    React.createElement(Toolbar, null),
                    React.createElement(TableComponent, null))),
            React.createElement(Tab, { tabKey: IPages.agentsReports, title: ByAgentsTab.Title },
                React.createElement(StyledContent, null,
                    React.createElement(Toolbar, null),
                    React.createElement(TableComponent, null))),
            React.createElement(Tab, { tabKey: IPages.groupsReports, title: ByGroupsTab.Title },
                React.createElement(StyledContent, null,
                    React.createElement(Toolbar, null),
                    React.createElement(TableComponent, null))))));
};
