import React from 'react';
import Header from '@src/modules/Header';
import Toolbar from '@src/modules/ToolBar';
import TableComponent from '@src/modules/Pages/TableComponent';
import ModalWindow from '@src/components/ModalWindow';
import ModalContent from '@src/modules/Pages/Dialogs/ShowDialogModal';
import { StyledContent, StyledPageWrapper } from '@src/app/styled';
import { Messages } from '@public/locales/messages';
var DialogsPage = function (props) {
    var currentPage = props.currentPage, isModalOpen = props.isModalOpen, toggleModal = props.toggleModal;
    return (React.createElement(StyledPageWrapper, { className: "dialogs-page" },
        React.createElement(Header, null),
        React.createElement(StyledContent, { currentPage: currentPage },
            React.createElement(Toolbar, null),
            React.createElement(TableComponent, null)),
        isModalOpen && (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: Messages.Pages.Dialogs.Messages },
            React.createElement(ModalContent, null)))));
};
export default DialogsPage;
