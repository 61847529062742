import { COLORS } from '@src/app/styled';
import { THEME_TYPES } from '@src/redux/app/types';
export var setThemeLS = function (theme) { return localStorage.setItem('theme', theme); };
export var getThemeLS = function () { return localStorage.getItem('theme'); };
export var darkTheme = {
    colors: {
        modalTitle: COLORS.MIDDLE_WHITE,
        scroll: COLORS.LIGHT_DARK_BLUE,
        headerBackground: COLORS.MAIN_DARK,
        spaceLine: COLORS.WHITE,
        spaceText: COLORS.WHITE,
        background: COLORS.MAIN_BACKGROUND,
        socialBtn: COLORS.WHITE,
        tableHeader: COLORS.TOOLBAR,
        tableRow: COLORS.TABLE_ROW,
        text: COLORS.GREY,
        accent: COLORS.DODGER_BLUE,
        bannedAgent: COLORS.BANNED,
        resetFiltersBtn: COLORS.LIGHT_GREY,
        messageSignIn: COLORS.SECONDARY_TOOLBAR,
        searchInputBackground: COLORS.VERY_DARK_BLUE,
        audioSliderRail: COLORS.LIGHT_GREY,
        audioSliderTrack: COLORS.BLUE_VIVID,
        selectBackground: COLORS.SECONDARY_BACKGROUND,
        selectListBackground: COLORS.SECONDARY_BACKGROUND,
        selectItemColor: COLORS.WHITE,
        selectItemBackground: COLORS.DODGER_BLUE,
        clientInfoTableExpandButton: COLORS.WHITE,
        addBtnBackground: COLORS.WHITE,
        addBtnColor: COLORS.VERY_DARK_BLUE,
        isSelectedItemCategoryBackground: COLORS.TABLE_ROW,
        createBtn: COLORS.DIRTY_GREEN,
        steps: COLORS.WHITE,
        excelModalBackground: COLORS.TABLE_ROW,
        checkbox: COLORS.DODGER_BLUE,
        accordionBackground: COLORS.TABLE_ROW,
        checkboxBackground: COLORS.LIGHT_DARK_BLUE,
        bulkMessagingBtn: COLORS.LIGHT_DARK_BLUE,
        backgroundBulkMessagingInput: COLORS.WHITE,
    },
    invert: 'none',
    blackInvert: 'invert()',
    name: 'dark',
};
export var lightTheme = {
    colors: {
        modalTitle: COLORS.VERY_DARK_BLUE,
        scroll: COLORS.MAIN_BACKGROUND,
        headerBackground: COLORS.MAIN_BACKGROUND,
        spaceLine: COLORS.DODGER_BLUE,
        spaceText: COLORS.DODGER_BLUE,
        socialBtn: COLORS.DODGER_BLUE,
        background: COLORS.SECONDARY_BACKGROUND,
        tableHeader: COLORS.SECONDARY_TOOLBAR,
        tableRow: COLORS.SECONDARY_TABLE_ROW,
        text: COLORS.VERY_DARK_BLUE,
        accent: COLORS.DODGER_BLUE,
        bannedAgent: COLORS.SECONDARY_BANNED,
        resetFiltersBtn: COLORS.VERY_DARK_BLUE,
        messageSignIn: COLORS.VERY_DARK_BLUE,
        searchInputBackground: COLORS.DARK_GREY,
        audioSliderRail: COLORS.VERY_DARK_BLUE,
        audioSliderTrack: COLORS.DODGER_BLUE,
        selectBackground: COLORS.MIDDLE_WHITE,
        selectListBackground: COLORS.LIGHT_GREY,
        selectItemColor: COLORS.WHITE,
        selectItemBackground: COLORS.TOOLBAR,
        addBtnBackground: COLORS.DIRTY_GREEN,
        addBtnColor: COLORS.WHITE,
        isSelectedItemCategoryBackground: COLORS.GREY,
        clientInfoTableExpandButton: COLORS.LIGHT_GREY_BLUE,
        createBtn: COLORS.LIGHT_DARK_BLUE,
        steps: COLORS.VERY_DARK_BLUE,
        checkbox: COLORS.LIGHT_DARK_BLUE,
        accordionBackground: COLORS.WHITE,
        excelModalBackground: COLORS.SECONDARY_TABLE_ROW,
        checkboxBackground: COLORS.SECONDARY_BACKGROUND,
        bulkMessagingBtn: COLORS.DARK_BLUE,
        backgroundBulkMessagingInput: COLORS.MIDDLE_WHITE,
    },
    blackInvert: 'none',
    invert: 'invert()',
    name: 'light',
};
export var getThemeOptions = function (name) { return (name === THEME_TYPES.light ? lightTheme : darkTheme); };
