var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, FONT } from '@src/app/styled';
var MIDDLE_WHITE = COLORS.MIDDLE_WHITE, DODGER_BLUE = COLORS.DODGER_BLUE, SECONDARY_TEXT = COLORS.SECONDARY_TEXT, VERY_DARK_BLUE = COLORS.VERY_DARK_BLUE;
export var MsgAgentWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    padding: 10px;\n    display: flex;\n    justify-content: flex-end;\n  }\n"], ["\n  && {\n    padding: 10px;\n    display: flex;\n    justify-content: flex-end;\n  }\n"])));
export var MsgClientWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    padding: 10px;\n    display: flex;\n    justify-content: flex-start;\n  }\n"], ["\n  && {\n    padding: 10px;\n    display: flex;\n    justify-content: flex-start;\n  }\n"])));
export var MsgBotWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    padding: 10px;\n    display: flex;\n    justify-content: flex-end;\n  }\n"], ["\n  && {\n    padding: 10px;\n    display: flex;\n    justify-content: flex-end;\n  }\n"])));
export var MsgContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    max-width: 200px;\n  }\n"], ["\n  && {\n    max-width: 200px;\n  }\n"])));
export var MsgBotText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    padding: 10px 10px;\n    font-size: ", ";\n    border-radius: 8px;\n    background: ", ";\n    color: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n    text-align: end;\n  }\n"], ["\n  && {\n    padding: 10px 10px;\n    font-size: ", ";\n    border-radius: 8px;\n    background: ", ";\n    color: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n    text-align: end;\n  }\n"])), FONT.DEFAULT_FONT_SIZE, SECONDARY_TEXT, MIDDLE_WHITE);
export var MsgAgentText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    padding: 10px 10px;\n    font-size: ", ";\n    background: ", ";\n    border-radius: 8px;\n    color: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n    text-align: end;\n  }\n"], ["\n  && {\n    padding: 10px 10px;\n    font-size: ", ";\n    background: ", ";\n    border-radius: 8px;\n    color: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n    text-align: end;\n  }\n"])), FONT.DEFAULT_FONT_SIZE, MIDDLE_WHITE, VERY_DARK_BLUE);
export var MsgClientText = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    padding: 10px 10px;\n    color: ", ";\n    border-radius: 8px;\n    font-size: ", ";\n    background: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n  }\n"], ["\n  && {\n    padding: 10px 10px;\n    color: ", ";\n    border-radius: 8px;\n    font-size: ", ";\n    background: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n  }\n"])), MIDDLE_WHITE, FONT.DEFAULT_FONT_SIZE, DODGER_BLUE);
export var NameWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n    color: ", ";\n  }\n"], ["\n  && {\n    color: ", ";\n  }\n"])), VERY_DARK_BLUE);
export var TimeWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    color: ", ";\n  }\n"], ["\n  && {\n    color: ", ";\n  }\n"])), VERY_DARK_BLUE);
export var TimeAndNameContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-direction: column;\n    justify-content: end;\n  }\n"], ["\n  && {\n    display: flex;\n    flex-direction: column;\n    justify-content: end;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
