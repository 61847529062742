var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledClientInfoWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: 50vh;\n  min-height: 15vh;\n  overflow: auto;\n  padding: 10px 20px;\n  width: 50vw;\n  color: ", ";\n  ", "\n\n  & .client-info {\n    padding-bottom: 10px;\n  }\n"], ["\n  max-height: 50vh;\n  min-height: 15vh;\n  overflow: auto;\n  padding: 10px 20px;\n  width: 50vw;\n  color: ", ";\n  ", "\n\n  & .client-info {\n    padding-bottom: 10px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
var templateObject_1;
