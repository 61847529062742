var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes, uniqueClient } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import ModalWindow from '@src/components/ModalWindow';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired, lengthCreator } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledBrandInput, StyledCheckboxWrapper, StyledCreateBrandWrapper, } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var CreateBrand = function (props) {
    var toggleModal = props.toggleModal, createBrand = props.createBrand, showNotification = props.showNotification, isLoadingCreateBrand = props.isLoadingCreateBrand, resetNotificationState = props.resetNotificationState;
    var _a = __read(useState(''), 2), clientIdentifier = _a[0], setClientIdentifier = _a[1];
    var minNameLength = lengthCreator.minNameLength, maxNameLength = lengthCreator.maxNameLength;
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var handleUniqueClientChange = function (changeEvent) {
        setClientIdentifier(changeEvent.target.value);
    };
    var onSubmit = function (data) {
        if (!clientIdentifier) {
            showWarning();
            return;
        }
        createBrand({ name: data.brandName, clientUniqueness: clientIdentifier });
    };
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: Messages.Buttons.createBrand },
        React.createElement(StyledCreateBrandWrapper, null, isLoadingCreateBrand ? (React.createElement(Loader, null)) : (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(StyledModalContentWrapper, null,
                        React.createElement(StyledModalContentFieldsWrapper, null,
                            React.createElement(Field, { name: "brandName", label: Messages.Labels.BrandName, validate: composeValidators(isRequired, minNameLength, maxNameLength), component: BrandInput, placeholder: Messages.Controls.BrandName, showWarning: showWarning }),
                            React.createElement(StyledCheckboxWrapper, null,
                                React.createElement("div", null, Messages.Labels.UniqueClientIdentifier),
                                uniqueClient.map(function (el, index) { return (React.createElement(Checkbox, { key: index, checked: clientIdentifier === uniqueClient[index].value, label: el.text, onChange: handleUniqueClientChange, value: el.value })); }))),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit }))))));
            } })))));
};
export default CreateBrand;
