import React from 'react';
import Button from '@src/components/Button';
import Header from '@src/modules/Header';
import Toolbar from '@src/modules/ToolBar';
import CreateBrand from '@src/modules/Pages/Brands/CreateBrand';
import BrandSettings from '@src/modules/Pages/Brands/BrandSettings';
import TableComponent from '@src/modules/Pages/TableComponent';
import { StyledAddBtn } from '@src/components/Button/styled';
import { StyledContent, StyledPageWrapper } from '@src/app/styled';
import { Messages } from '@public/locales/messages';
var BrandsPage = function (props) {
    var roles = props.roles, currentPage = props.currentPage, toggleModalCreateBrand = props.toggleModalCreateBrand, isModalOpenCreateBrand = props.isModalOpenCreateBrand, isModalOpenUpdateBrand = props.isModalOpenUpdateBrand;
    return (React.createElement(StyledPageWrapper, { className: "brands-page" },
        React.createElement(Header, null),
        React.createElement(StyledContent, { currentPage: currentPage },
            roles.find(function (el) { return el.role === 'superAdmin'; }) && (React.createElement(StyledAddBtn, null,
                React.createElement(Button, { title: Messages.Buttons.createBrand, onClick: toggleModalCreateBrand }))),
            React.createElement(Toolbar, null),
            React.createElement(TableComponent, null)),
        isModalOpenCreateBrand && React.createElement(CreateBrand, null),
        isModalOpenUpdateBrand && React.createElement(BrandSettings, null)));
};
export default BrandsPage;
