var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var GREY = COLORS.GREY, WHITE = COLORS.WHITE, BLUE_VIVID = COLORS.BLUE_VIVID, ORANGE_HOT = COLORS.ORANGE_HOT, DODGER_BLUE = COLORS.DODGER_BLUE, LIGHT_DARK_BLUE = COLORS.LIGHT_DARK_BLUE, MIDDLE_LIGHT_GREY = COLORS.MIDDLE_LIGHT_GREY, DATE_PICKER_BACKGROUND = COLORS.DATE_PICKER_BACKGROUND;
export var StyledAuthButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    button {\n      border: none;\n      max-width: 343px;\n      width: 100%;\n      height: 48px;\n      padding: 10px 24px;\n      margin-top: 8px;\n      background: ", ";\n      color: ", ";\n      cursor: pointer;\n      border-radius: 5px;\n    }\n  }\n"], ["\n  && {\n    button {\n      border: none;\n      max-width: 343px;\n      width: 100%;\n      height: 48px;\n      padding: 10px 24px;\n      margin-top: 8px;\n      background: ", ";\n      color: ", ";\n      cursor: pointer;\n      border-radius: 5px;\n    }\n  }\n"])), DODGER_BLUE, WHITE);
export var OkButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    margin-left: 10px;\n    border-radius: 4px;\n\n    button {\n      height: 100%;\n      background: ", ";\n      color: ", ";\n      border-radius: 4px;\n      width: 100%;\n      padding: 6px 15px;\n      transition: all 0.2s;\n\n      &:hover {\n        background: ", ";\n      }\n    }\n  }\n"], ["\n  && {\n    margin-left: 10px;\n    border-radius: 4px;\n\n    button {\n      height: 100%;\n      background: ", ";\n      color: ", ";\n      border-radius: 4px;\n      width: 100%;\n      padding: 6px 15px;\n      transition: all 0.2s;\n\n      &:hover {\n        background: ", ";\n      }\n    }\n  }\n"])), BLUE_VIVID, WHITE, DODGER_BLUE);
export var CancelButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    button {\n      border-radius: 4px;\n      height: 100%;\n      background-color: ", ";\n      border: 1px solid ", ";\n      width: 100%;\n      padding: 6px 15px;\n      transition: all 0.2s;\n\n      &:hover {\n        border: 1px solid ", ";\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      border-radius: 4px;\n      height: 100%;\n      background-color: ", ";\n      border: 1px solid ", ";\n      width: 100%;\n      padding: 6px 15px;\n      transition: all 0.2s;\n\n      &:hover {\n        border: 1px solid ", ";\n        color: ", ";\n      }\n    }\n  }\n"])), WHITE, GREY, BLUE_VIVID, BLUE_VIVID);
export var StyledAddButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    button {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      padding: 6px 12px 6px 8px;\n      transition: 0.3s;\n\n      :before {\n        content: '+';\n        margin-right: 5px;\n      }\n\n      &:hover {\n        outline: 1px solid ", ";\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      padding: 6px 12px 6px 8px;\n      transition: 0.3s;\n\n      :before {\n        content: '+';\n        margin-right: 5px;\n      }\n\n      &:hover {\n        outline: 1px solid ", ";\n        color: ", ";\n      }\n    }\n  }\n"])), BLUE_VIVID, BLUE_VIVID);
export var StyledAddFilterBtn = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    cursor: pointer;\n    max-width: max-content;\n    border-radius: 4px;\n    line-height: 26px;\n    font-size: 12px;\n    background: ", ";\n    padding: 1px 12px;\n\n    button {\n      background: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    color: ", ";\n    cursor: pointer;\n    max-width: max-content;\n    border-radius: 4px;\n    line-height: 26px;\n    font-size: 12px;\n    background: ", ";\n    padding: 1px 12px;\n\n    button {\n      background: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), WHITE, LIGHT_DARK_BLUE, LIGHT_DARK_BLUE);
export var StyledSelectedFilterBtn = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    line-height: 26px;\n    font-size: 12px;\n    background: ", ";\n    padding: 1px 12px;\n    border-radius: 16px;\n    margin: 10px 8px 0;\n    display: flex;\n    align-items: center;\n\n    button {\n      margin-right: 5px;\n      background: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    color: ", ";\n    line-height: 26px;\n    font-size: 12px;\n    background: ", ";\n    padding: 1px 12px;\n    border-radius: 16px;\n    margin: 10px 8px 0;\n    display: flex;\n    align-items: center;\n\n    button {\n      margin-right: 5px;\n      background: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), WHITE, DODGER_BLUE, DODGER_BLUE);
export var StyledApplyBtn = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      width: max-content;\n      font-size: 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      width: max-content;\n      font-size: 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), DODGER_BLUE);
export var StyledResetBtn = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      width: max-content;\n      font-size: 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      width: max-content;\n      font-size: 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), ORANGE_HOT);
export var StyledSetDateBtn = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    button {\n      border: 1px solid ", ";\n      background: ", ";\n      color: ", ";\n      padding: 7px 10px;\n      border-radius: 16px;\n      line-height: 11px;\n      font-size: 12px;\n\n      &:hover {\n        border: 1px solid ", ";\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      border: 1px solid ", ";\n      background: ", ";\n      color: ", ";\n      padding: 7px 10px;\n      border-radius: 16px;\n      line-height: 11px;\n      font-size: 12px;\n\n      &:hover {\n        border: 1px solid ", ";\n        color: ", ";\n      }\n    }\n  }\n"])), GREY, DATE_PICKER_BACKGROUND, MIDDLE_LIGHT_GREY, DODGER_BLUE, DODGER_BLUE);
export var StyledUnBanAgent = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  && {\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), function (_a) {
    var background = _a.background;
    return background;
}, WHITE);
export var StyledSaveBtn = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  && {\n    max-width: max-content;\n    padding-top: 10px;\n\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      min-width: max-content;\n      font-size: 12px;\n      color: ", ";\n      transition: all 0.2s;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    max-width: max-content;\n    padding-top: 10px;\n\n    button {\n      background: ", ";\n      padding: 5px 12px;\n      min-width: max-content;\n      font-size: 12px;\n      color: ", ";\n      transition: all 0.2s;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), LIGHT_DARK_BLUE, WHITE);
export var StyledAddOrRemoveTeam = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  && {\n    button {\n      width: 150px;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      width: 150px;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), function (_a) {
    var background = _a.background;
    return background;
}, WHITE);
export var StyledMakeOrRemoveBrandAdmin = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  && {\n    button {\n      max-width: 150px;\n      width: 100%;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      max-width: 150px;\n      width: 100%;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), function (_a) {
    var background = _a.background;
    return background;
}, WHITE);
export var StyledMakeOrRemoveSuperAdmin = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  && {\n    button {\n      max-width: 150px;\n      width: 100%;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    button {\n      max-width: 150px;\n      width: 100%;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), function (_a) {
    var background = _a.background;
    return background;
}, WHITE);
export var StyledMakeBrandAdmin = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  && {\n    padding-top: 10px;\n    display: flex;\n    justify-content: space-between;\n\n    button {\n      max-width: 150px;\n      width: 100%;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    padding-top: 10px;\n    display: flex;\n    justify-content: space-between;\n\n    button {\n      max-width: 150px;\n      width: 100%;\n      background: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n      color: ", ";\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), DODGER_BLUE, WHITE);
export var StyledAddBtn = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  && {\n    padding-bottom: 15px;\n\n    img {\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n    }\n\n    button {\n      background-color: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    padding-bottom: 15px;\n\n    img {\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n    }\n\n    button {\n      background-color: ", ";\n      padding: 5px 12px;\n      font-size: 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.createBtn;
});
export var StyledAddNewBtn = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  && {\n    max-width: max-content;\n    padding-top: 10px;\n\n    button {\n      border: 1px solid ", ";\n      background: ", ";\n      color: ", ";\n      border-radius: 4px;\n      min-width: max-content;\n      padding: 4px 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"], ["\n  && {\n    max-width: max-content;\n    padding-top: 10px;\n\n    button {\n      border: 1px solid ", ";\n      background: ", ";\n      color: ", ";\n      border-radius: 4px;\n      min-width: max-content;\n      padding: 4px 12px;\n\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n"])), DODGER_BLUE, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, DODGER_BLUE);
export var StyledAddBtnWithImg = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  && {\n    cursor: pointer;\n    padding: 5px 12px;\n    display: flex;\n    border-radius: 5px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n\n    img {\n      margin-right: 5px;\n      width: 10px;\n      height: 10px;\n      filter: ", ";\n    }\n\n    button {\n      max-width: max-content;\n      background-color: ", ";\n      font-size: 12px;\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    cursor: pointer;\n    padding: 5px 12px;\n    display: flex;\n    border-radius: 5px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n\n    img {\n      margin-right: 5px;\n      width: 10px;\n      height: 10px;\n      filter: ", ";\n    }\n\n    button {\n      max-width: max-content;\n      background-color: ", ";\n      font-size: 12px;\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.addBtnBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.addBtnBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.addBtnColor;
});
export var StyledCountAgentsNewBeesBtn = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  && {\n    cursor: pointer;\n    padding: 2px 8px;\n    display: flex;\n    margin-right: 5px;\n    border-radius: 5px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n    color: ", ";\n\n    button {\n      max-width: max-content;\n      color: ", ";\n      background-color: ", ";\n      font-size: 12px;\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    cursor: pointer;\n    padding: 2px 8px;\n    display: flex;\n    margin-right: 5px;\n    border-radius: 5px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n    color: ", ";\n\n    button {\n      max-width: max-content;\n      color: ", ";\n      background-color: ", ";\n      font-size: 12px;\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, selectedNewbieAgents = _a.selectedNewbieAgents;
    return selectedNewbieAgents ? theme.colors.createBtn : theme.colors.bulkMessagingBtn;
}, WHITE, WHITE, function (_a) {
    var theme = _a.theme, selectedNewbieAgents = _a.selectedNewbieAgents;
    return selectedNewbieAgents ? theme.colors.createBtn : theme.colors.bulkMessagingBtn;
});
export var StyledCreateBtnWithImg = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  && {\n    cursor: pointer;\n    padding: 5px 12px;\n    display: flex;\n    border-radius: 5px;\n    margin-right: 10px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n\n    img {\n      margin-right: 5px;\n      width: 10px;\n      height: 10px;\n    }\n\n    button {\n      max-width: max-content;\n      background-color: ", ";\n      font-size: 12px;\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    cursor: pointer;\n    padding: 5px 12px;\n    display: flex;\n    border-radius: 5px;\n    margin-right: 10px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n\n    img {\n      margin-right: 5px;\n      width: 10px;\n      height: 10px;\n    }\n\n    button {\n      max-width: max-content;\n      background-color: ", ";\n      font-size: 12px;\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.createBtn;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.createBtn;
}, WHITE);
export var StyledPreviousBtn = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  && {\n    max-width: max-content;\n    padding-top: 10px;\n\n    button {\n      max-width: max-content;\n      padding: 4px 12px;\n      border-radius: 4px;\n      background-color: ", ";\n      border: 2px solid ", ";\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    max-width: max-content;\n    padding-top: 10px;\n\n    button {\n      max-width: max-content;\n      padding: 4px 12px;\n      border-radius: 4px;\n      background-color: ", ";\n      border: 2px solid ", ";\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledBulkMessagingBtn = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  && {\n    cursor: pointer;\n    padding: 5px 12px;\n    display: flex;\n    margin-left: 10px;\n    border-radius: 5px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n\n    img {\n      margin-right: 5px;\n      width: 10px;\n      height: 10px;\n      filter: invert();\n    }\n\n    button {\n      max-width: max-content;\n      background-color: ", ";\n      font-size: 12px;\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    cursor: pointer;\n    padding: 5px 12px;\n    display: flex;\n    margin-left: 10px;\n    border-radius: 5px;\n    justify-content: space-around;\n    align-items: center;\n    background-color: ", ";\n    max-width: max-content;\n\n    img {\n      margin-right: 5px;\n      width: 10px;\n      height: 10px;\n      filter: invert();\n    }\n\n    button {\n      max-width: max-content;\n      background-color: ", ";\n      font-size: 12px;\n      color: ", ";\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bulkMessagingBtn;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bulkMessagingBtn;
}, WHITE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;
