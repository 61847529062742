import React from 'react';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes } from '@src/global-interfaces';
import Button from '@src/components/Button';
import Input from '@src/components/Input';
import ModalWindow from '@src/components/ModalWindow';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired, lengthCreator, urlCorrect, } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledUsefulLinksInput, StyledUsefulLinksModalWrapper } from '@src/modules/Pages/Tags/UsefulLinksModal/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var UsefulLinksModal = function (props) {
    var _a = props.usefulLinks, usefulLinks = _a === void 0 ? null : _a, setUsefulLinks = props.setUsefulLinks, showNotification = props.showNotification, setIsOpenTwoModal = props.setIsOpenTwoModal, onEditUsefulLinks = props.onEditUsefulLinks, resetNotificationState = props.resetNotificationState, toggleModalUsefulLinks = props.toggleModalUsefulLinks;
    var maxNameLength = lengthCreator.maxNameLength, minNameLength = lengthCreator.minNameLength;
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onSubmit = function (data) {
        if (usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.isEdit)
            onEditUsefulLinks({ alias: data.linkAlias, url: data.linkUrl }, usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.indexLink);
        if (!(usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.isEdit))
            setUsefulLinks({ alias: data.linkAlias, url: data.linkUrl });
    };
    var usefulLinksInput = InputWithStyles(Input, StyledUsefulLinksInput);
    return (React.createElement(ModalWindow, { closeModalWindow: function () {
            toggleModalUsefulLinks();
            setIsOpenTwoModal(false);
        }, title: Messages.Labels.UsefulLinks },
        React.createElement(StyledUsefulLinksModalWrapper, null,
            React.createElement(Form, { initialValues: {
                    linkAlias: (usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.alias) || '',
                    linkUrl: (usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.url) || '',
                }, onSubmit: onSubmit, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(StyledModalContentWrapper, null,
                            React.createElement(StyledModalContentFieldsWrapper, null,
                                React.createElement(Field, { name: "linkAlias", label: "".concat(Messages.Labels.LinkAlias, "*"), showWarning: showWarning, validate: composeValidators(isRequired, minNameLength, maxNameLength), placeholder: Messages.Controls.LinkAlias, component: usefulLinksInput }),
                                React.createElement(Field, { name: "linkUrl", label: "".concat(Messages.Labels.LinkUrl, "*"), showWarning: showWarning, validate: composeValidators(isRequired, urlCorrect), placeholder: Messages.Controls.LinkUrl, component: usefulLinksInput })),
                            React.createElement(StyledModalFooterOneBtnWrapper, null,
                                React.createElement(StyledSaveBtn, null,
                                    React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit }))))));
                } }))));
};
export default UsefulLinksModal;
