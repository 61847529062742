import React from 'react';
import StyledClientMessage from './StyledClientMessage';
function ClientMessage(_a) {
    var clientMsg = _a.clientMsg;
    var clientMessageColor = clientMsg.clientMessageColor, clientTimeColor = clientMsg.clientTimeColor, clientMessageBackground = clientMsg.clientMessageBackground, clientMessageBorderColor = clientMsg.clientMessageBorderColor, clientLink = clientMsg.clientLink, clientHoverLink = clientMsg.clientHoverLink, clientActiveLink = clientMsg.clientActiveLink, clientVisitedLink = clientMsg.clientVisitedLink;
    return (React.createElement(StyledClientMessage, { clientTimeColor: clientTimeColor, clientLink: clientLink, clientMessageColor: clientMessageColor, clientMessageBackground: clientMessageBackground, clientMessageBorderColor: clientMessageBorderColor, clientHoverLink: clientHoverLink, clientActiveLink: clientActiveLink, clientVisitedLink: clientVisitedLink },
        React.createElement("div", { className: "client-message-wrap" },
            React.createElement("p", { className: "client-time" }, "14:07"),
            React.createElement("div", { className: "client-message" }, "Client message")),
        React.createElement("div", { className: "client-message-wrap" },
            React.createElement("p", { className: "client-time" }, "14:07"),
            React.createElement("div", { className: "client-message" }, "Client message message Client message Client message link:"))));
}
export default ClientMessage;
