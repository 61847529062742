import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { loadingStore } from '@src/redux/loading/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import CategoryModal from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalCategory/CategoryModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
var mapStateToProps = function (state) { return ({
    currentGroup: groupsStore.getCurrentGroup(state),
    categoryId: modalWindowsStore.getModalInfo(state, modalTypes.EDIT_CATEGORY_GROUPS),
    isLoadingUsefulPhrases: loadingStore.getIsLoadingUsefulPhrases(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    deletePhrase: function (data) { return dispatch(groupsActions.deletePhrase(data)); },
    updateCategory: function (data) { return dispatch(groupsActions.updateCategory(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    createNewCategory: function (data) { return dispatch(groupsActions.createNewCategory(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);
