import { connect } from 'react-redux';
import UpdateTelegramInbox from '@src/modules/Pages/Brands/BrandSettings/UpdateTelegramInbox/UpdateTelegramInbox';
import { brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import { loadingStore } from '@src/redux/loading/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
var mapStateToProps = function (state) { return ({
    isLoadingCreateInbox: loadingStore.getIsLoadingCreateInbox(state),
    telegramInbox: modalWindowsStore.getModalInfo(state, modalTypes.UPDATE_TELEGRAM_INBOX),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    updateTelegramInbox: function (data) { return dispatch(brandsActions.updateInbox(data)); },
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_TELEGRAM_INBOX, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTelegramInbox);
