import { checkIsObject } from '@src/utils/isObject';
export var isEqualObject = function (object1, object2) {
    if (!checkIsObject(object1) || !checkIsObject(object2))
        return false;
    var props1 = Object.getOwnPropertyNames(object1);
    var props2 = Object.getOwnPropertyNames(object2);
    if (props1.length !== props2.length)
        return false;
    for (var i = 0; i < props1.length; i += 1) {
        var prop = props1[i];
        if (object1[prop] !== object2[prop])
            return false;
    }
    return true;
};
