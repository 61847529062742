import { connect } from 'react-redux';
import UpdateSmsInbox from '@src/modules/Pages/Brands/BrandSettings/UpdateSmsInbox/UpdateSmsInbox';
import { brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import brandsStore from '@src/modules/Pages/Brands/redux/selector';
import { loadingStore } from '@src/redux/loading/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { notificationActions } from '@src/redux/notification/reducer';
var mapStateToProps = function (state) { return ({
    smsInbox: modalWindowsStore.getModalInfo(state, modalTypes.UPDATE_SMS_INBOX),
    isLoadingCreateInbox: loadingStore.getIsLoadingCreateInbox(state),
    smsAccountsSettings: brandsStore.getSmsAccountsSettings(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    setListCountrySms: function (data) { return dispatch(brandsActions.setListCountrySms(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    updateSmsInbox: function (data) { return dispatch(brandsActions.updateInbox(data)); },
    updateSmsAccounts: function (data) { return dispatch(brandsActions.updateSmsAccounts(data)); },
    getSmsAccountsSettings: function (id) { return dispatch(brandsActions.getSmsAccountsSettings(id)); },
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_SMS_INBOX, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSmsInbox);
