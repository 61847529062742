var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { slideInLeft } from 'react-animations';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
export var ModalWindowWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    position: fixed;\n    bottom: 25px;\n    left: 25px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n  }\n"], ["\n  && {\n    position: fixed;\n    bottom: 25px;\n    left: 25px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n  }\n"])));
export var ModalWindowContentWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    animation: 0.5s ", " ease-in-out;\n  }\n"], ["\n  && {\n    animation: 0.5s ", " ease-in-out;\n  }\n"])), keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["", ""], ["", ""])), slideInLeft));
export var ModalWindowContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    overflow: auto;\n    transition: 0.2s;\n    padding: 0 20px ", " 20px;\n    border-radius: 5px;\n    background-color: ", ";\n    width: 100%;\n    min-width: 300px;\n    color: ", ";\n  }\n"], ["\n  && {\n    overflow: auto;\n    transition: 0.2s;\n    padding: 0 20px ", " 20px;\n    border-radius: 5px;\n    background-color: ", ";\n    width: 100%;\n    min-width: 300px;\n    color: ", ";\n  }\n"])), function (_a) {
    var isMinimized = _a.isMinimized;
    return (isMinimized ? 0 : '20px');
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.excelModalBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var ModalWindowHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    display: flex;\n    padding: 20px 0;\n    user-select: none;\n    justify-content: space-between;\n    color: ", ";\n    img {\n      filter: ", ";\n      width: 20px;\n      height: 20px;\n      cursor: pointer;\n    }\n    .excel-modal__head {\n      display: flex;\n      align-items: center;\n      &-title {\n        height: max-content;\n        margin-left: 10px;\n      }\n    }\n    .excel-modal__icons {\n      img:last-child {\n        margin-left: 15px;\n      }\n      img {\n        width: 15px;\n        height: 15px;\n        cursor: pointer;\n        transition: 0.2s ease-in-out;\n        transform: rotate(", "deg);\n      }\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    padding: 20px 0;\n    user-select: none;\n    justify-content: space-between;\n    color: ", ";\n    img {\n      filter: ", ";\n      width: 20px;\n      height: 20px;\n      cursor: pointer;\n    }\n    .excel-modal__head {\n      display: flex;\n      align-items: center;\n      &-title {\n        height: max-content;\n        margin-left: 10px;\n      }\n    }\n    .excel-modal__icons {\n      img:last-child {\n        margin-left: 15px;\n      }\n      img {\n        width: 15px;\n        height: 15px;\n        cursor: pointer;\n        transition: 0.2s ease-in-out;\n        transform: rotate(", "deg);\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
}, function (_a) {
    var isMinimized = _a.isMinimized;
    return (isMinimized ? 270 : 90);
});
export var ProgressBars = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    & > div {\n      width: 100%;\n      display: flex;\n      align-items: center;\n      overflow: hidden;\n      transition: height 1.15s ease-out;\n      height: ", ";\n      margin-bottom: ", ";\n      &:last-child {\n        margin-bottom: 0;\n      }\n      .progressWrapper {\n        width: 100%;\n      }\n    }\n  }\n"], ["\n  && {\n    & > div {\n      width: 100%;\n      display: flex;\n      align-items: center;\n      overflow: hidden;\n      transition: height 1.15s ease-out;\n      height: ", ";\n      margin-bottom: ", ";\n      &:last-child {\n        margin-bottom: 0;\n      }\n      .progressWrapper {\n        width: 100%;\n      }\n    }\n  }\n"])), function (_a) {
    var isMinimized = _a.isMinimized;
    return (isMinimized ? 0 : 'auto');
}, function (_a) {
    var isMinimized = _a.isMinimized;
    return (isMinimized ? '0' : '20px');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
