import { connect } from 'react-redux';
import { toolBarActions } from '@src/modules/ToolBar/redux/reducer';
import { appStore } from '@src/redux/app/selector';
import { toolBarStore } from '@src/modules/ToolBar/redux/selector';
import SelectedFilter from './SelectedFilters';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    showSelectedFilter: toolBarStore.getShowSelectedFilter(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onChangeShowSelectedFilter: function (data) { return dispatch(toolBarActions.changeShowSelectedFilter(data)); },
    setShowSelectedFilter: function (data) { return dispatch(toolBarActions.setShowSelectedFilter(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SelectedFilter);
