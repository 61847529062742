import { env } from '@mdca/wde';
export var getDefaultLogo = function () { return "".concat(env('ICON_WEB_UI'), "/logo.svg"); };
export var getDefaultIconChatImg = function () { return "".concat(env('ICON_WEB_UI'), "/icon.svg"); };
export var getDefaultSendIcon = function () { return "".concat(env('ICON_WEB_UI'), "/new-send.svg"); };
export var getDefaultBotIcon = function () { return "".concat(env('ICON_WEB_UI'), "/client.svg"); };
export var getDefaultPaperClip = function () { return "".concat(env('ICON_WEB_UI'), "/paper-clip.svg"); };
export var getDefaultMinimizeIcon = function () { return "".concat(env('ICON_WEB_UI'), "/minimizeIcon.svg"); };
export var getDefaultMoreIcon = function () { return "".concat(env('ICON_WEB_UI'), "/moreIcon.svg"); };
export var getDefaultMuteIcon = function () { return "".concat(env('ICON_WEB_UI'), "/mute.svg"); };
export var getDefaultUnMuteIcon = function () { return "".concat(env('ICON_WEB_UI'), "/unMute.svg"); };
export var getDefaultUnionIcon = function () { return "".concat(env('ICON_WEB_UI'), "/union.svg"); };
export var getDefaultExitIcon = function () { return "".concat(env('ICON_WEB_UI'), "/exit.svg"); };
var MAIN_COLOR = 'rgb(229, 229, 229)';
var BLACK = '#000';
var WHITE = '#fff';
var RED = 'red';
var BLUE = '#1972f5';
var COLOR_A = '#F5F5F5';
var DEFAULT_AUTH_BACKGROUND = 'rgb(245, 245, 245)';
var DEFAULT_VISITED_LINK = '#A3DCFF';
var HEADER_COLORS = '#12171e';
export var getDefaultCustomizations = function () { return ({
    header: {
        logoUrl: getDefaultLogo(),
        minimizeIcon: getDefaultMinimizeIcon(),
        moreIcon: getDefaultMoreIcon(),
        headerHeight: '50px',
        disableDraggable: true,
        headerBackground: HEADER_COLORS,
        headerBorderColor: HEADER_COLORS,
        disableHeaderLogo: true,
        disableHeaderFullScreen: true,
        disableHeaderShowEndDialogModal: true,
        disableHeaderHideChatWindow: true,
        headerText: {
            ua: { headerTextTop: '', headerTextBottom: '' },
            ru: { headerTextTop: '', headerTextBottom: '' },
            en: { headerTextTop: '', headerTextBottom: '' },
            pl: { headerTextTop: '', headerTextBottom: '' },
            de: { headerTextTop: '', headerTextBottom: '' },
            es: { headerTextTop: '', headerTextBottom: '' },
            vi: { headerTextTop: '', headerTextBottom: '' },
            pt: { headerTextTop: '', headerTextBottom: '' },
            ko: { headerTextTop: '', headerTextBottom: '' },
            it: { headerTextTop: '', headerTextBottom: '' },
            ar: { headerTextTop: '', headerTextBottom: '' },
            fr: { headerTextTop: '', headerTextBottom: '' },
            hu: { headerTextTop: '', headerTextBottom: '' },
        },
    },
    moreMenu: {
        backdropColor: COLOR_A,
        backdropItemHoverColor: DEFAULT_VISITED_LINK,
        textColor: HEADER_COLORS,
        muteIcon: getDefaultMuteIcon(),
        unMuteIcon: getDefaultUnMuteIcon(),
        unionIcon: getDefaultUnionIcon(),
        exitIcon: getDefaultExitIcon(),
    },
    greeting: {
        colorGreetingBotName: 'rgb(67, 67, 67)',
        messageGreetingIconUrlBot: getDefaultBotIcon(),
        messageGreetingIconBackgroundBot: MAIN_COLOR,
        messageGreetingColor: 'rgb(67, 67, 67)',
        messageGreetingBorderColor: MAIN_COLOR,
        messageGreetingBackgroundColor: MAIN_COLOR,
    },
    quickPhrases: {
        quickPhrasesColor: BLUE,
        quickPhrasesBackground: COLOR_A,
        quickPhrasesBorderColor: BLUE,
        quickPhrasesColorHover: MAIN_COLOR,
        quickPhrasesBackgroundHover: BLUE,
        quickPhrasesBorderColorHover: BLUE,
    },
    clientMsg: {
        clientMessageColor: MAIN_COLOR,
        clientTimeColor: '#4f4343',
        clientMessageBackground: '#75808d',
        clientMessageBorderColor: '#75808d',
        clientLink: MAIN_COLOR,
        clientHoverLink: BLACK,
        clientActiveLink: BLACK,
        clientVisitedLink: DEFAULT_VISITED_LINK,
    },
    agentMessage: {
        colorAgentNameAndTime: BLACK,
        messageAgentColor: '#fff7f8',
        messageAgentBackgroundColor: BLUE,
        messageAgentBorderColor: BLUE,
        agentLink: WHITE,
        agentHoverLink: BLACK,
        agentActiveLink: BLACK,
        agentVisitedLink: DEFAULT_VISITED_LINK,
        messageIconUrlAgent: getDefaultBotIcon(),
        messageIconBackgroundAgent: MAIN_COLOR,
    },
    endDialogModal: {
        textColor: 'white',
        backdropColor: 'rgba(29, 41, 51, 0.65)',
        backdropColorHover: 'rgba(29, 41, 51, 1)',
        colorBtn: '#12171e',
        backdropColorBtn: '#F5F5F5',
    },
    isMobileVersionSize: {
        height: '0',
        width: '0',
    },
    icon: {
        iconUrl: getDefaultIconChatImg(),
        iconBackground: '#4285F4',
    },
    scroll: {
        scrollWidth: '10px',
        scrollBackground: 'rgb(18, 23, 30)',
        scrollThumbBackground: COLOR_A,
    },
    chatWindow: {
        chatWindowsLogo: '',
        chatWindowsLogoSize: '150px',
        chatWindowsBodyBackground: COLOR_A,
        chatWindowsBorderColor: MAIN_COLOR,
    },
    footer: {
        sendImage: getDefaultSendIcon(),
        sendFileImage: getDefaultPaperClip(),
        inputColor: BLACK,
        inputPlaceholder: '',
        footerBackground: COLOR_A,
        footerBorderColor: COLOR_A,
        sendButtonIconWidth: '25px',
        sendButtonIconHeight: '25px',
        footerBackgroundTextArea: COLOR_A,
        inputBorderColor: '#E5E5E5',
    },
    auth: {
        registrationBackgroundColor: DEFAULT_AUTH_BACKGROUND,
        labelColor: BLACK,
        labelErrorColor: RED,
        inputColor: BLACK,
        inputBackground: WHITE,
        signUpColor: WHITE,
        signUpBackground: '#4285F4',
    },
    botChannel: {
        responseType: '',
    },
    footerElements: {
        isDisableTextArea: false,
        isDisableAttachmentFile: false,
        isDisableMakePhoto: false,
        isDisableMicrophone: false,
    },
    mimeTypesPermission: {},
}); };
