var Messages = {
    Success: {
        Success: 'הַצלָחָה',
        ForgotPasswordSend: 'הקישור לשינוי סיסמה נשלח לאימייל שלך',
        ResetPasswordSuccess: 'הסיסמה שלך עודכנה בהצלחה',
        BrandCreated: 'מותג נוצר!',
        MailInboxCreated: 'נוצרה תיבת דואר דואר!',
        PrivacyPolicyChanged: 'מדיניות הפרטיות השתנתה!',
        SmsInboxCreated: 'תיבת SMS נוצרה!',
        InboxDeleted: 'תיבת הדואר הנכנס נמחקה!',
        StatusChanged: 'הסטטוס השתנה!',
        FileLoaded: 'הקובץ נטען!',
        AuthMail: 'Auth Mail!',
        DomainSettings: 'הגדרות דומיין!',
        UpdatedSmsAccounts: 'חשבונות sms מעודכנים!',
        ChanelCreated: 'הערוץ נוצר!',
        DeleteMessenger: "מחק מסנג'ר!",
        AddMessenger: 'הוסף Messenger!',
        ChangedMessenger: "שינה מסנג'ר!",
        ClientCreated: 'הלקוח נוצר!',
        ClientsImports: 'יבוא לקוחות!',
        GroupCreated: 'הקבוצה נוצרה!',
        ChangedAssignedAgentsInGroups: 'שינו סוכנים שהוקצו בקבוצות!',
        ChangedAssignedChannelsInGroups: 'שינו ערוצים שהוקצו בקבוצות!',
        ChangedAssignedTagsInGroups: 'שינו תגים שהוקצו בקבוצות!',
        CreateNewCategory: 'צור קטגוריה חדשה!',
        CreateNewPhrase: 'צור ביטוי חדש!',
        DeleteCategory: 'מחק קטגוריה!',
        DeletePhrase: 'מחק את הביטוי!',
        UpdatePhrase: 'עדכן את הביטוי!',
        EditCategory: 'ערוך קטגוריה!',
        DeleteAllCategory: 'מחק את כל הקטגוריות!',
        TagCreated: 'התג נוצר!',
        TagUpdated: 'התג עודכן!',
        TelegramInboxCreated: 'תיבת הדואר הנכנס של טלגרם נוצרה!',
        HeaderTextChanged: 'טקסט הכותרת השתנה',
        LogoIconUrlChanged: 'כתובת האתר של סמל הלוגו השתנתה',
        IconUrlChanged: 'כתובת ה-URL של הסמל השתנתה',
        BackgroundImageUrlChanged: 'כתובת האתר של תמונת רקע השתנתה',
        GreetingImageUrlChanged: 'כתובת ה-URL של תמונת הברכה השתנתה',
        AgentImageUrlChanged: 'כתובת ה-URL של תמונת הסוכן השתנתה',
        InputPlaceholderChanged: 'מציין מיקום קלט שונה',
        SendImageUrlChanged: 'כתובת ה-URL של שליחת התמונה השתנתה',
        AgentStatusChanged: 'סטטוס הסוכן השתנה',
        SelectedAgentTagsChanged: 'תגי הסוכן שנבחרו השתנו',
        AssignedAgentsGroupsChanged: 'קבוצות סוכנים שהוקצו השתנו',
        TeamLeadChanged: 'החליף ראש צוות',
        AgentRoleChanged: 'תפקיד הסוכן השתנה',
        AgentInfoChanged: 'מידע הסוכן השתנה',
    },
    Errors: {
        PasswordMatching: 'סיסמאות לא תואמות!',
        IncorrectEmail: 'האימייל שגוי',
        IncorrectPhone: 'הטלפון לא תקין',
        IncorrectUrl: 'כתובת האתר שגויה',
        FieldContainSpaces: 'שדה מכיל רווחים',
        FieldIsRequired: 'שדה נדרש',
        NameValidation: 'אורך השדה חייב להיות בין 2 ל-40 תווים כולל',
        NumberValidation: 'השדה חייב להכיל מספרים בלבד',
        PasswordValidation: 'הסיסמה חייבת להכיל אלפבית ומספרים ואינה יכולה להכיל רווחים',
        InvoiceNumberValidation: 'מספר החשבונית חייב להכיל אותיות לטיניות, מספרים ותווים מיוחדים',
        MinimumLength: 'אורך מינימלי {0} סמלים',
        MaximumLength: 'אורך מקסימלי של {0} סמלים',
        ServerError: 'שגיאת שרת, אנא נסה זאת מאוחר יותר!',
        ServerErrorFileDownloadTimeOut: 'שגיאת שרת, הזמן הקצוב להורדת הקובץ, אנא נסה זאת מאוחר יותר!',
        NotValidEmailOrPassword: 'אימייל או סיסמה לא חוקיים',
        UserWasNotFound: 'המשתמש לא נמצא!',
        UserIsAlreadyExist: 'המשתמש כבר קיים!',
        AgentWasBanned: 'הסוכן נאסר',
        TokenExpired: 'פג תוקף ההפעלה. אנא התחבר מחדש',
        TelegramIdIsIncorrect: 'מזהה טלגרם שגוי',
        ErrorWrongFileFormat: 'שגיאה, פורמט קובץ שגוי!',
        FileSizeExceededWarnings: 'גודל הקובץ חורג מהאזהרות!',
        BrandWithSuchNameIsAlreadyExisting: 'מותג עם שם כזה כבר קיים!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'כבר בחרת מסנן',
        AgentCreate: 'אתה סוכן חדש במערכת AskMe. שים לב שאתה יכול להיכנס רק לאחר שהוקצה לך תפקיד המנהל!',
        NoStatisticsForTheAgent: 'אין נתונים סטטיסטיים עבור הסוכן עבור תקופת הזמן שנבחרה',
        FieldIsRequired: 'שדה נדרש',
        ChannelMustContainAtLeastOneLanguage: 'הערוץ חייב להכיל לפחות שפה אחת',
    },
    Info: {
        Copied: 'מוּעֲתָק',
        PhraseBotNameAndGreetingsChanged: 'ביטויים, שם הבוט וברכה השתנו',
    },
    Confirm: {},
    Message: {
        Welcome: 'ברוכים הבאים ל- AskMe',
        SignIn: 'היכנס ללוח הניהול או עבור אל ',
        SignUp: 'הירשם ללוח הניהול או עבור אל שלך ',
        Register: 'אין לך חשבון איתנו?',
        Login: 'כבר יש לך חשבון?',
        Logout: 'אתה באמת רוצה לעזוב?',
        ForgotPassword: 'שכחת את הסיסמא?',
        ExcelFilesDownloading: 'הורדת קבצי אקסל',
        excelFileIs: 'קובץ אקסל',
        Created: 'נוצר',
        Creating: 'יוצר',
    },
    Accordions: {
        MailInboxes: 'תיבות דואר דואר',
        TelegramAccounts: 'חשבונות טלגרם',
        SmsAccounts: 'חשבונות SMS',
    },
    Controls: {
        Newbie: 'מתחיל',
        Name: 'שֵׁם',
        Email: 'אימייל',
        Password: 'סיסמה',
        RepeatPassword: 'חזור על הסיסמה',
        Register: 'להירשם',
        Login: 'התחברות',
        Or: 'אוֹ',
        SignUp: 'הירשם',
        Send: 'לִשְׁלוֹחַ',
        SearchFilter: 'חיפוש מסננים',
        Search: 'לחפש',
        SearchMessengers: 'חפש שליחים',
        SearchAgent: 'סוכן חיפוש',
        SearchLanguage: 'שפת חיפוש',
        SearchChannel: 'חפש ערוץ',
        SearchTag: 'תג חיפוש',
        SearchStatus: 'סטטוס חיפוש',
        SearchBrand: 'חפש מותג',
        SearchWhiteListTags: 'חפש תגיות של רשימה לבנה',
        SearchSessionStatus: 'חיפוש סטטוס הפעלה',
        SearchAccessLevel: 'רמת גישה לחיפוש',
        SearchHasActiveDialogs: 'לחיפוש יש דיאלוגים פעילים',
        GroupName: 'שם קבוצה*',
        Description: 'תיאור*',
        LinkAlias: 'קישור כינוי*',
        CategoryName: 'שם קטגוריה*',
        BrandName: 'שם מותג',
        AccessKey: 'מפתח גישה',
        ClientName: 'שם הלקוח',
        ClientFirstName: 'שם פרטי הלקוח',
        ClientLastName: 'שם משפחה של הלקוח',
        ClientPhone: 'טלפון לקוח',
        ClientEmail: 'מייל לקוח',
        PleaseWrite: 'בבקשה כתוב',
        MailInbox: 'תיבת דואר',
        SmsInbox: 'תיבת דואר SMS',
        TelegramInbox: 'תיבת דואר נכנס של טלגרם',
        ApplicationID: 'מזהה בקשה',
        ApplicationToken: 'אסימון אפליקציה',
        SenderID: 'מספר זהות השולח',
        ChangeName: 'שנה שם',
        ChangeURL: 'שנה כתובת URL',
        TagName: 'תג שם',
        LinkUrl: 'כתובת אתר של קישור*',
        Greetings: 'ברכות',
        FastPhrase: 'ביטוי מהיר №',
        ClickOnPaperclip: 'לחץ על סמל מהדק כדי לבחור קובץ',
        UserName: 'התחברות',
        Domain: 'תְחוּם*',
        Imap: 'Imap (מארח:פורט)*',
        SmtpTLS: 'Smtp TLS (מארח:יציאה)*',
        SmtpSSL: 'Smtp SSL (מארח:יציאה)*',
        AttachFile: 'לצרף קובץ',
        NoData: 'אין מידע',
        NoRating: 'בלי דירוג',
        IfYouWantFinishDialog: 'אם ברצונך לסיים דו-שיח לחץ על כפתור אישור',
        AllDialogsAreVisibleToAllAgents: 'כל תיבות הדו-שיח גלויות לכל הסוכנים',
        DialogueIsAssignedAutomatically: 'דיאלוג מוקצה אוטומטית לסוכן שיש לו את המספר הנמוך ביותר של דיאלוגים',
        AllDialogsAreVisibleToSeveralAgents: 'דיאלוגים גלויים למספר סוכנים עם המספר הנמוך ביותר של דיאלוגים',
        On: 'עַל',
        Off: 'כבוי',
        FirstName: 'שם פרטי',
        InvoiceNumber: 'מספר חשבונית',
        LastName: 'שם משפחה',
        SMS: 'סמס',
        New: 'חָדָשׁ',
        Active: 'פָּעִיל',
        Finished: 'גָמוּר',
        Assigned: 'שהוקצה',
        Online: 'באינטרנט',
        Offline: 'לא מקוון',
        Break: 'לשבור',
        Low: 'נָמוּך',
        Medium: 'בינוני',
        High: 'גָבוֹהַ',
        True: 'נָכוֹן',
        False: 'שֶׁקֶר',
        Date: 'תַאֲרִיך',
        AgentName: 'שם סוכן',
        Messenger: 'שָׁלִיחַ',
        Language: 'שפה',
        Channel: 'עָרוּץ',
        Tag: 'תָג',
        Status: 'סטָטוּס',
        Brand: 'מותג',
        AccessLevel: 'רמת גישה',
        WhiteListTags: 'תגי רשימה לבנה',
        SessionStatus: 'סטטוס הפעלה',
        LastTags: 'תגים אחרונים',
        HasActiveDialogs: 'יש דיאלוגים פעילים',
        ChannelName: 'שם הערוץ',
        WriteYourFirstName: '* בבקשה, כתוב את שמך הפרטי',
        WriteYourLastName: '* בבקשה, כתוב את שם המשפחה שלך',
        WriteYourUserName: '* בבקשה, כתוב שם משתמש',
        WriteYourPhoneNumber: '* בבקשה, כתוב מספר טלפון',
        WriteYourEmail: '* בבקשה, כתוב את המייל שלך',
        WriteYourInvoiceNumber: '* אנא כתוב את מספר החשבונית שלך',
        WriteYourAccountNumber: '* אנא כתוב את מספר החשבון שלך',
        BotName: 'שם הבוט',
        Character: 'אופי',
        Number: 'מספר',
        IdExample: 'דוגמה לתעודה מזהה',
        XCharecter: 'X-character',
        YNumber: 'מספר Y',
        ThisOperationCanTakeLongTime: 'פעולה זו עשויה להימשך זמן רב!',
        ClickTiSaveExcel: 'לחץ כדי לשמור באקסל',
        PrivacyPolicyText: 'טקסט מדיניות הפרטיות',
        PrivacyPolicyLink: 'קישור למדיניות הפרטיות',
        PrivacyPolicyLinkText: 'טקסט קישור למדיניות הפרטיות',
        AgentPhone: 'הטלפון של הסוכן',
        HeaderTextTop: 'טקסט כותרת למעלה',
        HeaderTextBottom: 'טקסט כותרת למטה',
        LogoIconUrl: 'כתובת אתר של סמל לוגו',
        IconUrl: 'כתובת אתר של סמל',
        ChatWindowsLogo: "לוגו של חלונות צ'אט",
        GreetingIconBotUrl: 'כתובת האתר של סמל ברכה',
        AgentMessageIconUrl: 'כתובת URL של סמל הודעה של סוכן',
        WriteMessagesPlaceholder: 'כתוב מציין מיקום של הודעות',
        SendImageUrl: 'שלח כתובת אתר של תמונה',
        ChannelURL: 'כתובת האתר של הערוץ',
    },
    Selects: {
        SelectAccessLevel: 'בחר רמת גישה*',
        SelectChannel: 'בחר ערוץ*',
        SelectChannels: 'בחר ערוצים',
        SelectWhiteTagsList: 'בחר רשימת תגים לבנים*',
        SelectAgents: 'בחר סוכנים',
        SelectGroups: 'בחר קבוצות',
        SelectBrand: 'בחר מותג',
        SelectTags: 'בחר תגים',
        ChooseProviderCountry: 'בחר ארץ ספק',
        SelectMessengers: 'בחר שליחים',
        SelectTagStatus: 'בחר סטטוס תג',
        SelectAgent: 'בחר סוכן',
        SelectEnabledLanguages: 'בחר שפות מופעלות',
        ChannelName: 'שם הערוץ',
        ChannelURL: 'כתובת האתר של הערוץ',
        SelectLanguageWebChat: "בחר שפות צ'אט אינטרנט",
    },
    Labels: {
        MoreIconUrl: 'כתובת אתר יותר סמל',
        MinimizeIconUrl: 'כתובת אתר סמל למזעור',
        SendFileUrl: 'שלח כתובת אתר של קובץ',
        NumberOfNewAgents: 'מספר סוכנים חדשים',
        TextForBulkMessaging: 'טקסט להעברת הודעות בכמות גדולה',
        Name: 'שֵׁם',
        LastName: 'שם משפחה',
        Phone: 'מכשיר טלפון',
        GroupName: 'שם קבוצה*',
        SelectBrand: 'בחר מותג',
        Channel: 'עָרוּץ*',
        Description: 'תיאור*',
        AccessLevel: 'רמת גישה*',
        WhiteTagsList: 'רשימת התגים הלבנים*',
        BrandName: 'שם מותג',
        CategoryName: 'שם קטגוריה*',
        ChooseProviderCountry: 'בחר ארץ ספק',
        AccessKey: 'מפתח גישה',
        SelectChannels: 'בחר ערוצים',
        SelectGroups: 'בחר קבוצות',
        SelectAgents: 'בחר סוכנים',
        SelectTags: 'בחר תגים',
        SelectAccessLevel: 'בחר רמת גישה*',
        Password: 'סיסמה*',
        Login: 'התחברות',
        SelectMessengers: 'בחר שליחים',
        UserName: 'שם משתמש',
        ClientName: 'שם הלקוח',
        ClientFirstName: 'שם פרטי הלקוח',
        ClientLastName: 'שם משפחה של הלקוח',
        ClientPhone: 'טלפון לקוח',
        ClientEmail: 'מייל לקוח',
        ClientInfo: 'פרטי לקוח',
        UpdateGroup: 'עדכן קבוצה',
        Agents: 'סוכנים',
        UsefulPhrases: 'משפטים שימושיים',
        Channels: 'ערוצים',
        Tags: 'תגים',
        AddNewPhrases: 'הוסף ביטויים חדשים',
        Phrase: 'מִשׁפָּט',
        SelectCategory: 'בחר קטגוריה',
        EditCategory: 'ערוך קטגוריה',
        EditPhrase: 'ערוך את הביטוי',
        Or: 'אוֹ',
        AddNewCategory: 'הוסף קטגוריה חדשה',
        Dialogs: 'דיאלוגים',
        TotalDuration: 'משך זמן כולל',
        Email: 'אימייל',
        ClientId: 'זיהוי לקוח',
        ClientBanType: 'סוג איסור לקוח',
        ClientBanTime: 'זמן חסימת לקוחות',
        Messengers: 'שליחים',
        IpAddress: 'כתובת ה - IP',
        ExternalID: 'תעודה מזהה חיצונית',
        UpdateBrand: 'עדכן את המותג',
        CreateMailInbox: 'צור חשבון תיבת דואר',
        CreateTelegramInbox: 'צור חשבון טלגרם',
        CreateSmsInbox: 'צור חשבון SMS',
        MailInbox: 'תיבת דואר',
        TelegramInbox: 'תיבת דואר נכנס של טלגרם',
        SmsInbox: 'תיבת דואר SMS',
        ApplicationID: 'מזהה בקשה',
        ApplicationToken: 'אסימון אפליקציה',
        SenderID: 'מספר זהות השולח',
        ChangeName: 'שנה שם',
        ChangeURL: 'שנה כתובת URL',
        TagName: 'תג שם',
        SelectTagStatus: 'בחר סטטוס תג',
        SelectAgent: 'בחר סוכן',
        UpdateTag: 'עדכן תג',
        CreateTag: 'צור תג',
        UsefulLinks: 'קישורים שימושיים',
        LinkAlias: 'קישור כינוי',
        LinkUrl: 'כתובת אתר של קישור',
        Roles: 'תפקידים',
        Groups: 'קבוצות',
        CreateGroups: 'צור קבוצות',
        TeamLead: 'ראש צוות',
        Ban: 'לֶאֱסוֹר',
        SelectEnabledLanguages: 'בחר שפות מופעלות',
        Greetings: 'ברכות',
        FastPhrase: 'ביטוי מהיר №',
        ClientHistory: 'היסטוריית לקוח',
        ChooseRequiredFields: 'נא לבחור שדות חובה',
        GetAllDialogs: 'קבל את כל דיאלוגים',
        GetCurrentDialog: 'קבל דו-שיח נוכחי',
        SaveInLocalStorage: 'שמור ב-LocalStorage',
        SaveInSessionStorage: 'שמור ב-SessionStorage',
        InvoiceNumber: 'מספר חשבונית',
        AccountNumber: 'מספר חשבון',
        General: 'כללי',
        Authorization: 'הרשאה',
        Languages: 'שפות',
        New: 'חָדָשׁ',
        Active: 'פָּעִיל',
        Inactive: 'לֹא פָּעִיל',
        WaitForActivation: 'המתן להפעלה',
        ID: 'תְעוּדַת זֶהוּת',
        SMS: 'סמס',
        Low: 'נָמוּך',
        Medium: 'בינוני',
        High: 'גָבוֹהַ',
        Hidden: 'מוּסתָר',
        Brand: 'מותג',
        Group: 'קְבוּצָה',
        ChangeStatus: 'לשנות סטטוס',
        AuthMail: 'דואר אימות',
        SetDomainSettings: 'הגדר הגדרות דומיין',
        Domain: 'תְחוּם*',
        Imap: 'Imap (מארח:פורט)*',
        SmtpTLS: 'Smtp TLS (מארח:יציאה)*',
        SmtpSSL: 'Smtp SSL (מארח:יציאה)*',
        AttachFile: 'לצרף קובץ',
        ChannelSettings: 'הגדרות ערוץ',
        GeneralSettings: 'הגדרות כלליות',
        WebChatConstructor: "בונה צ'אט אינטרנט",
        BackgroundColor: 'צבע רקע',
        MessageColor: 'צבע ההודעה',
        URL: 'כתובת אתר',
        ChannelName: 'שם הערוץ',
        ChannelURL: 'כתובת האתר של הערוץ',
        AllowSMS: 'אפשר SMS',
        AllowShowAllClientDialogs: 'אפשר להציג את כל תיבות הדו-שיח של הלקוח',
        AllowCaptcha: 'אפשר Captcha',
        InboxSettings: 'הגדרות תיבת דואר נכנס',
        DistributionOfNewDialogs: 'הפצת דיאלוגים חדשים בין סוכנים מקוונים',
        ThereAreNoAvailableMessengers: 'אין שליחים זמינים לערוץ! אנא צור אותם עבור המותג שבבעלותו הערוץ הזה!',
        HeaderColorScheme: 'ערכת צבעי כותרות',
        GreetingColorScheme: 'ערכת צבעי ברכה',
        QuickPhrases: 'ערכת צבעים של ביטויים מהירים',
        ClientMessage: 'ערכת הצבעים של הודעת הלקוח',
        AgentMessage: 'ערכת הצבעים של הודעת הסוכן',
        EndDialogModal: 'סיום ערכת צבעים מודאלית של דיאלוג',
        IconColorScheme: 'ערכת צבעי אייקונים',
        ScrollColorScheme: 'ערכת צבעים לגלילה',
        ChatWindowColor: "ערכת הצבעים של חלון הצ'אט",
        FooterColorScheme: 'ערכת צבעים של כותרת תחתונה',
        AuthColorScheme: 'ערכת צבעים אותנטית',
        HeaderBackground: 'רקע כותרת',
        HeaderBorderColor: 'צבע גבול כותרת',
        ColorGreetingBotName: 'ברכה צבע שם בוט',
        MessageGreetingIcon: 'רקע סמל ברכה',
        MessageGreetingColor: 'צבע טקסט ברכה',
        MessageGreetingBorderColor: 'הודעת ברכה צבע גבול',
        MessageGreetingBackgroundColor: 'הודעת ברכה צבע רקע',
        QuickPhrasesColor: 'ביטוי מהיר צבע טקסט',
        QuickPhrasesBackground: 'רקע ביטוי מהיר',
        QuickPhrasesBorderColor: 'ביטוי מהיר צבע גבול',
        QuickPhrasesColorHover: 'ביטוי מהיר צבע רחף',
        QuickPhrasesBackgroundHover: 'ביטוי מהיר ריחוף ברקע',
        QuickPhrasesBorderColorHover: 'ביטוי מהיר ריחוף צבע גבול',
        ClientMessageColor: 'הודעת לקוח צבע טקסט',
        ClientTimeColor: 'צבע זמן הלקוח',
        ClientMessageBackground: 'הודעת לקוח צבע רקע',
        ClientMessageBorderColor: 'הודעת לקוח צבע גבול',
        ClientLink: 'קישור ללקוח',
        ClientLinkHover: 'ריחוף קישור לקוח',
        ClientActiveLink: 'קישור פעיל ללקוח',
        ClientVisitedLink: 'קישור ביקור לקוח',
        ColorAgentNameAndTime: 'שם הסוכן וצבע הזמן',
        MessageAgentColor: 'צבע הודעת סוכן',
        MessageAgentBackgroundColor: 'רקע הודעת סוכן',
        MessageAgentBorderColor: 'הודעת סוכן צבע גבול',
        AgentLink: 'קישור לסוכן',
        AgentHoverLink: 'קישור סוכן רחף',
        AgentActiveLink: 'קישור פעיל לסוכן',
        AgentVisitedLink: 'קישור ביקור סוכן',
        MessageIconBackgroundAgent: 'צבע רקע של סמל הודעת סוכן',
        TextColor: 'צבע טקסט',
        BackdropColor: 'צבע רקע',
        BackdropColorHover: 'ריחוף צבע רקע',
        ButtonColor: 'צבע כפתור',
        BackdropColorBtn: 'צבע רקע לחצן רקע',
        IconBackground: 'רקע סמל',
        ChatWindowsBodyBackground: "צבע רקע גוף של חלונות צ'אט",
        ChatWindowsBorderColor: "צבע הגבול של חלונות צ'אט",
        FooterBackground: 'צבע רקע של כותרת תחתונה',
        FooterBorderColor: 'צבע הגבול של כותרת תחתונה',
        FooterBackgroundTextArea: 'צבע רקע של אזור הטקסט בכותרת התחתונה',
        InputBorderColor: 'קלט צבע גבול',
        RegistrationBackgroundColor: 'צבע רקע לרישום',
        LabelColor: 'צבע התווית',
        LabelErrorColor: 'צבע שגיאת תווית',
        InputColor: 'צבע קלט',
        InputBackground: 'רקע קלט',
        SignUpColor: 'צבע הרשמה',
        SignUpBackground: 'הרשמה צבע רקע',
        Chat: 'לְשׂוֹחֵחַ',
        Minimized: 'ממוזער',
        ScrollBackground: 'רקע גלילה',
        ScrollThumbBackground: 'גלול רקע אגודל',
        UniqueClientIdentifier: 'מזהה לקוח ייחודי',
        AgentsSettings: 'הגדרות סוכנים',
        ThisAgentSuperAdmin: 'סוכן זה הוא סופר אדמין, לחץ על הלחצן כדי להסיר זכויות סופר אדמין',
        ThisAgentNotSuperAdmin: 'סוכן זה אינו סופר אדמין, לחץ על הכפתור כדי להפוך אותו לסופר אדמין',
        YouCanAssignBrandTheBrand: 'אתה יכול להקצות את המותג של המנהל בשביל זה אתה צריך לבחור את המותג',
        Role: 'תַפְקִיד',
        BanningThisAgent: 'איסור על סוכן זה אומר שהם לא יוכלו להיכנס לפלטפורמה. הם עדיין הולכים להופיע במערכת.',
        Report: 'להגיש תלונה',
        SelectLanguageWebChat: "בחר שפות צ'אט אינטרנט",
        FirstName: 'שם פרטי',
        PhoneNumber: 'מספר טלפון',
        Info: 'מידע',
        WebChatSettings: "הגדרות צ'אט אינטרנט",
        IntegrationWithOtherCRM: 'אינטגרציה עם CRM אחר',
        SettingUpInteractionBetweenSystems: 'הקמת אינטראקציה בין מערכות',
        QuickReplies: 'תשובות מהירות',
        BotSettings: 'הגדרות הבוט',
        BotName: 'שם הבוט',
        EnabledPrivacyPolicy: 'מדיניות פרטיות מופעלת',
        PleaseChooseRequiredFields: 'אנא בחר שדות חובה',
        ChangeGreetingsAndPhrases: 'שנה ברכות וביטויים, מדיניות פרטיות',
        ChangeSmsSettings: 'שנה הגדרות SMS',
        ChangeCountries: 'שנה מדינות',
        ContactsAreWorkingWithClientUniqueness: 'אנשי הקשר עובדים עם ייחודיות הלקוח. נדרש שדה, אם ייחודיות הלקוח שווה לו.',
        ClientUniquenessIs: 'ייחוד הלקוח הוא:',
        PrivacyPolicyText: 'טקסט מדיניות הפרטיות',
        PrivacyPolicyLink: 'קישור למדיניות הפרטיות',
        PrivacyPolicyLinkText: 'טקסט קישור למדיניות הפרטיות',
        AgentPhone: 'הטלפון של הסוכן',
        HeaderSettings: 'הגדרות כותרת',
        DisableDraggable: 'השבת ניתנת לגרירה',
        DisableHeaderFullScreen: 'השבת מסך מלא של כותרת',
        DisableHeaderHideChatWindow: "השבת את חלון הצ'אט של הסתר כותרת",
        DisableHeaderLogo: 'השבת את לוגו הכותרת',
        DisableHeaderShowEndDialogModal: 'השבת את שיטת הדו-שיח לסיום הצגת כותרת',
        HeaderText: 'טקסט כותרת',
        HeaderTextTop: 'טקסט כותרת למעלה',
        HeaderTextBottom: 'טקסט כותרת למטה',
        HeaderHeight: 'גובה כותרת',
        LogoIconUrl: 'כתובת אתר של סמל לוגו',
        IconUrl: 'כתובת אתר של סמל',
        IconSettings: 'הגדרות סמל',
        ScrollSettings: 'הגדרות גלילה',
        ScrollWidth: 'רוחב הגלילה',
        ChatWindowsLogo: "לוגו של חלונות צ'אט",
        BackgroundImageSize: 'גודל תמונת רקע',
        GreetingSettings: 'הגדרות ברכה',
        GreetingIconBotUrl: 'כתובת האתר של הבוט של סמל ברכה',
        QuickPhrasesSettings: 'הגדרות ביטויים מהירים',
        ClientMessageSettings: 'הגדרות הודעת לקוח',
        AgentMessageSettings: 'הגדרות הודעת סוכן',
        AgentMessageIconUrl: 'כתובת URL של סמל הודעה של סוכן',
        EndDialogModalSettings: 'סיום הגדרות מודאליות של דו-שיח',
        AuthWindowSettings: 'הגדרות חלון אימות',
        FooterSettings: 'הגדרות כותרת תחתונה',
        WriteMessagesPlaceholder: 'כתוב מציין מיקום של הודעות',
        SendImageUrl: 'שלח כתובת אתר של תמונה',
        SendButtonIconHeight: 'גובה סמל כפתור שלח',
        SendButtonIconWidth: 'רוחב סמל כפתור שלח',
        ChatWindowSettings: "הגדרות חלון צ'אט",
        ClearAllPhrase: 'נקה את כל הביטויים',
        AreYouSure: 'האם אתה בטוח?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'פעולה זו תסיר את כל הביטויים וכל הקטגוריות',
        AddAgentToGroup: 'הוסף סוכנים לקבוצה',
        CreateClient: 'צור לקוח',
        ClientsImport: 'ייבוא לקוחות',
        UploadExcelFile: 'העלה קובץ אקסל',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'לחץ על סמל מהדק כדי לבחור את קובץ האקסל',
        Contacts: 'אנשי קשר',
        Personal: 'אישי',
        FileUploadHint: 'נא להעלות קובץ אקסל מלא במספרי טלפון. קובץ אקסל צריך להכיל עמודה בשם "טלפונים" ויש להזין מספרי טלפון בעמודה זו. ניתן לייבא רק קובצי .xls או .xlsx.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'שנה ברכות וביטויים, מדיניות פרטיות',
    },
    AdditionalText: {
        AgentProfile: 'פרופיל סוכן',
        OutOf: 'מִתוֹך',
    },
    Pages: {
        Dialogs: {
            Title: 'דיאלוגים',
            TableColumns: {
                Status: 'סטָטוּס',
                Dialog: 'דיאלוג',
                Messenger: 'שָׁלִיחַ',
                Channel: 'עָרוּץ',
                Tag: 'תָג',
                Agent: 'סוֹכֵן',
                Rating: 'דֵרוּג',
                Language: 'שפה',
                MessagesCount: 'הודעות נחשבות',
                FirstMessage: 'הודעה ראשונה',
                LastActivity: 'פעילות אחרונה',
            },
            Messages: 'הודעות',
        },
        AgentGroups: {
            Title: 'קבוצות סוכנים',
            TableColumns: {
                Groups: 'קבוצות',
                Description: 'תיאור',
                AccessLevel: 'רמת גישה',
                WhiteListTags: 'תגי רשימה לבנה',
                CreatedAt: 'נוצר ב',
                UpdatedAt: 'עודכן ב',
            },
        },
        Agents: {
            Title: 'סוכנים',
            TableColumns: {
                AgentStatus: 'סטטוס סוכן',
                Agents: 'סוכנים',
                Email: 'אימייל',
                Groups: 'קבוצות',
                CreatedAt: 'נוצר ב',
                LastActivity: 'פעילות אחרונה',
            },
        },
        Tags: {
            Title: 'תגים',
            TableColumns: {
                Tags: 'תגים',
                Dialogs: 'דיאלוגים',
                Agents: 'סוכנים',
                UsefulLinks: 'קישורים שימושיים',
                Status: 'סטָטוּס',
                CreatedAt: 'נוצר ב',
                UpdatedAt: 'עודכן ב',
            },
        },
        Clients: {
            Title: 'לקוחות',
            TableColumns: {
                SearchName: 'שֵׁם',
                AgentName: 'שם סוכן',
                Messenger: 'שָׁלִיחַ',
                LastTag: 'תג אחרון',
                ActiveDialog: 'בעל דיאלוג פעיל',
                Brand: 'מותג',
                InternalId: 'זיהוי פנימי',
                ExternalId: 'זיהוי חיצוני',
                Name: 'שם הלקוח',
                FirstName: 'שם פרטי',
                LastName: 'שם משפחה',
                Email: 'אימייל',
                InvoiceNumber: 'מספר חשבונית',
                DialogsCount: 'ספירת דיאלוגים',
                FirstMessage: 'הודעה ראשונה',
                LastActivity: 'פעילות אחרונה',
                Tag: 'תָג',
                Status: 'סטָטוּס',
                Channel: 'עָרוּץ',
                WriteEmail: 'אימייל',
            },
        },
        Channels: {
            Title: 'ערוצים',
            TableColumns: {
                ChannelId: 'מזהה ערוץ',
                ChannelKey: 'מפתח ערוץ',
                ChannelName: 'שם הערוץ',
                Language: 'שפה',
                Url: 'כתובת אתר',
                CreatedAt: 'נוצר ב',
                UpdatedAt: 'עודכן ב',
            },
        },
        Brands: {
            Title: 'מותגים',
            TableColumns: {
                BrandsId: 'מזהה מותג',
                BrandName: 'שם מותג',
                ClientUniqueness: 'ייחודיות הלקוח',
                BrandChannels: 'ערוצי מותג',
                DialogCount: 'דיאלוגים נחשבים',
                CreatedAt: 'נוצר ב',
                UpdatedAt: 'עודכן ב',
            },
        },
        Reports: {
            Title: 'דיווחים',
            ByMessengersTab: {
                Title: 'דיווח על ידי שליחים',
                Columns: {
                    Messenger: 'שָׁלִיחַ',
                    ReceivedDialogs: 'תיבות דו-שיח שהתקבלו',
                    PositiveDialogs: 'דיאלוגים חיוביים, %',
                    NegativeDialogs: 'תיבות דו-שיח שליליות, %',
                    UnratedDialogs: 'דיאלוגים לא מדורגים, %',
                    AvgResponseTime: 'זמן תגובה ממוצע',
                },
            },
            ByAgentsTab: {
                Title: 'דיווח של סוכנים',
                Columns: {
                    agentFullName: 'שם מלא של הסוכן',
                    agentSystemId: 'מזהה מערכת הסוכן',
                    brandName: 'שם מותג',
                    receivedDialogs: 'דיאלוגים שהתקבלו',
                    dialogsTelegram: 'דיאלוגים Telegram',
                    dialogsWebChat: 'דיאלוגים Web Chat',
                    dialogsViber: 'דיאלוגים Viber',
                    dialogsWhatsApp: 'דיאלוגים WhatsApp',
                    dialogsFacebook: 'דיאלוגים Facebook',
                    dialogsEmail: 'דיאלוגים Email',
                    dialogsSms: 'דיאלוגים SMS',
                    positiveDialogs: 'דיאלוגים חיוביים, %',
                    negativeDialogs: 'תיבות דו-שיח שליליות, %',
                    unratedDialogs: 'דיאלוגים לא מדורגים, %',
                    avgResponseTime: 'זמן תגובה ממוצע',
                },
                noDataForThisDay: 'אין נתונים ליום זה',
            },
            ByGroupsTab: {
                Title: 'דיווח לפי קבוצות',
                Columns: {
                    groupSystemId: 'מזהה מערכת הקבוצה',
                    groupName: 'שם קבוצה',
                    brandName: 'שם מותג',
                    receivedDialogs: 'דיאלוגים שהתקבלו',
                    positiveDialogs: 'דיאלוגים חיוביים, %',
                    negativeDialogs: 'תיבות דו-שיח שליליות, %',
                    unratedDialogs: 'דיאלוגים לא מדורגים, %',
                    avgResponseTime: 'זמן תגובה ממוצע',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'הוסף מסנן',
        reset: 'אִתחוּל',
        createClient: 'צור לקוח',
        importClient: 'ייבוא לקוחות',
        createChannel: 'צור ערוץ',
        previous: 'קודם',
        next: 'הַבָּא',
        addPhrase: 'הוסף ביטוי',
        addGroup: 'הוסף קבוצה',
        createBrand: 'צור מותג',
        addTag: 'הוסף תגית',
        save: 'להציל',
        send: 'לִשְׁלוֹחַ',
        getStatistics: 'קבל סטטיסטיקה',
        setCountries: 'קבע מדינות',
        changeStatus: 'לשנות סטטוס',
        upload: 'העלה',
        setDomainSettings: 'הגדר הגדרות דומיין',
        login: 'התחברות',
        update: 'עדכון',
        resetPassword: 'לאפס את הסיסמה',
        addNew: 'הוסף חדש',
        addNewPhrase: 'הוסף ביטוי חדש',
        addNewCategory: 'הוסף קטגוריה חדשה',
        clearAll: 'נקה הכל',
        loginWithGoogle: 'התחבר עם גוגל',
        loginWithFacebook: 'התחבר עם פייסבוק',
        onPage: 'בעמוד',
        addNewUsefulLinks: 'הוסף קישורים שימושיים חדשים',
        backToChannels: 'חזרה לערוצים',
        defaultSettings: 'הגדרות ברירת מחדל',
        upgradeChannel: 'שדרג ערוץ',
        apply: 'להגיש מועמדות',
        remove: 'לְהַסִיר',
        removeSuperAdmin: 'הסר מנהל-על',
        makeSuperAdmin: 'הפוך לסופר אדמין',
        makeBrandAdmin: 'הפוך את המותג למנהל',
        removeBrandAdmin: 'הסר את מנהל המותג',
        ban: 'לֶאֱסוֹר',
        unban: 'בטל חסימה',
        makeTeamLead: 'להוביל צוות',
        removeTeamLead: 'הסר את ראש הצוות',
        signUp: 'הירשם',
        ok: 'בסדר',
        cancel: 'לְבַטֵל',
        bulkMessaging: 'העברת הודעות בתפזורת',
        back: 'חזור',
    },
    Countries: {
        UnitedStates: 'ארצות הברית',
        Canada: 'קנדה',
        Australia: 'אוֹסטְרַלִיָה',
        CzechRepublic: "הרפובליקה הצ'כית",
        Denmark: 'דנמרק',
        Hungary: 'הונגריה',
        Netherlands: 'הולנד',
        Sweden: 'שבדיה',
        UnitedKingdom: 'הממלכה המאוחדת',
        France: 'צָרְפַת',
        Germany: 'גֶרמָנִיָה',
        Poland: 'פּוֹלִין',
        Italy: 'אִיטַלִיָה',
        Spain: 'סְפָרַד',
    },
    Languages: {
        Ukrainian: 'אוקראינית',
        Russian: 'רוּסִי',
        English: 'אנגלית',
        Polish: 'פולני',
        Deutsch: 'דויטש',
        Spanish: 'ספרדית',
        Vietnamese: 'וייטנאמית',
        Portuguese: 'פורטוגזית',
        Korean: 'קוריאנית',
        Italian: 'אִיטַלְקִית',
        Arabic: 'עֲרָבִית',
        French: 'צָרְפָתִית',
        Azerbaijan: "אזרבייג'ן",
        Hungarian: 'הוּנגָרִי',
    },
    BulkMessaging: {
        exclude: 'אל תכלול',
        typeText: 'הקלד טקסט',
        selectClients: 'בחר לקוחות',
        uploadClients: 'העלה לקוחות',
        sendingMessagesToNewClients: 'שליחת הודעות ללקוחות חדשים',
        sendingMessagesToExistingClients: 'שליחת הודעות ללקוחות קיימים',
        clientsWhoHaveDialogsWithFollowingStatus: 'לקוחות, שיש להם דיאלוגים עם הסטטוס הבא',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'לקוחות, בעלי הטקסט הזה בשם או שם משפחה או שם משתמש',
        limitQuantity: 'הגבל כמות',
        quantityLimit: 'הגבלת כמות',
        limitQuantityText: 'הגבל את הכמות בשידור ללא יותר ממספר הנמענים שצוין. לקוחות חדשים יותר של המערכת יכנסו לרשימה.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'לקוחות, שכתבו ל-AskMe לפני זמן ההודעה האחרונה',
    },
};
export default Messages;
