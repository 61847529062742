import React from 'react';
import { icons } from '@public/images';
import { Icon } from '@src/components/Icon/Icon';
import { ItemPhrasesWrapper } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ItemPhrases/styled';
var ItemPhrases = function (props) {
    var id = props.id, text = props.text, number = props.number, phraseId = props.phraseId, categoryId = props.categoryId, editPhrases = props.editPhrases, deletePhrase = props.deletePhrase, setCurrentPhraseId = props.setCurrentPhraseId;
    return (React.createElement(ItemPhrasesWrapper, { onClick: function () { return setCurrentPhraseId(id); } },
        React.createElement("div", { className: "item-phrases-text" },
            React.createElement("div", { className: "item-phrases-text__number" }, "".concat(number, ". ")),
            React.createElement("div", { className: "item-phrases-text__text" }, text)),
        React.createElement("div", { className: "item-phrases-icon-box" },
            React.createElement(Icon, { src: icons.editPhrases, onClick: editPhrases }),
            React.createElement(Icon, { src: icons.deletePhrases, onClick: function () { return deletePhrase({ phraseId: phraseId, categoryId: categoryId }); } }))));
};
export default ItemPhrases;
