var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes, messengerProvider, availableCountriesBulkGate, availableCountriesMessageBird, } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Select from '@src/components/Select';
import Button from '@src/components/Button';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isNumberValue, isRequired, lengthCreator, } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledBrandInput } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import { StyledMessageNameWrapper, StyledLoaderWrapper } from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/styled';
import { Messages } from '@public/locales/messages';
var BulkGateAndMessageBird = function (props) {
    var createSmsInbox = props.createSmsInbox, showNotification = props.showNotification, resetNotificationState = props.resetNotificationState, isLoadingCreateInbox = props.isLoadingCreateInbox;
    var minSenderIDBulkGate = lengthCreator.minSenderIDBulkGate, maxSenderIDBulkGate = lengthCreator.maxSenderIDBulkGate, maxAccessKeyMessageBird = lengthCreator.maxAccessKeyMessageBird, minAccessKeyMessageBird = lengthCreator.minAccessKeyMessageBird, maxApplicationIDBulkGate = lengthCreator.maxApplicationIDBulkGate, minApplicationIDBulkGate = lengthCreator.minApplicationIDBulkGate, minApplicationTokenBulkGate = lengthCreator.minApplicationTokenBulkGate, maxApplicationTokenBulkGate = lengthCreator.maxApplicationTokenBulkGate;
    var _a = __read(useState(null), 2), selectedCountriesBulkGate = _a[0], setSelectedCountriesBulkGate = _a[1];
    var _b = __read(useState(true), 2), isSelectedCountriesBulkGate = _b[0], setIsSelectedCountriesBulkGate = _b[1];
    var _c = __read(useState(null), 2), selectedCountriesMessageBird = _c[0], setSelectedCountriesMessageBird = _c[1];
    var _d = __read(useState(true), 2), isSelectedCountriesMessageBird = _d[0], setIsSelectedCountriesMessageBird = _d[1];
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onSubmit = function (data) {
        if (selectedCountriesBulkGate.length <= 0) {
            setIsSelectedCountriesBulkGate(false);
            return;
        }
        if (selectedCountriesMessageBird.length <= 0) {
            setIsSelectedCountriesMessageBird(false);
            return;
        }
        setIsSelectedCountriesBulkGate(true);
        setIsSelectedCountriesMessageBird(true);
        createSmsInbox(__assign(__assign({}, data), { provider: messengerProvider.bulkGateAndMessageBird, listCountriesBulkGate: selectedCountriesBulkGate.map(function (el) { return el.value; }), listCountriesMessageBird: selectedCountriesMessageBird.map(function (el) { return el.value; }) }));
    };
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(React.Fragment, null, isLoadingCreateInbox ? (React.createElement(StyledLoaderWrapper, null,
        React.createElement(Loader, null))) : (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(StyledMessageNameWrapper, null, messengerProvider.bulkGate),
                React.createElement(Field, { validate: composeValidators(isRequired, minApplicationIDBulkGate, maxApplicationIDBulkGate), name: "applicationId", showWarning: showWarning, label: Messages.Labels.ApplicationID, component: BrandInput, placeholder: Messages.Controls.ApplicationID }),
                React.createElement(Field, { validate: composeValidators(isRequired, minApplicationTokenBulkGate, maxApplicationTokenBulkGate), name: "applicationToken", showWarning: showWarning, label: Messages.Labels.ApplicationToken, component: BrandInput, placeholder: Messages.Controls.ApplicationToken }),
                React.createElement(Field, { validate: composeValidators(isRequired, isNumberValue, minSenderIDBulkGate, maxSenderIDBulkGate), name: "senderId", showWarning: showWarning, label: Messages.Labels.SenderID, component: BrandInput, placeholder: Messages.Controls.SenderID }),
                React.createElement(Select, { label: Messages.Labels.ChooseProviderCountry, placeholder: Messages.Selects.ChooseProviderCountry, items: availableCountriesBulkGate, error: !isSelectedCountriesBulkGate, stateCB: function (el) { return setSelectedCountriesBulkGate(el); } }),
                React.createElement(StyledMessageNameWrapper, null, messengerProvider.messageBird),
                React.createElement(Field, { name: "accessKey", showWarning: showWarning, validate: composeValidators(isRequired, minAccessKeyMessageBird, maxAccessKeyMessageBird), label: Messages.Labels.AccessKey, component: BrandInput, placeholder: Messages.Controls.AccessKey }),
                React.createElement(Select, { label: Messages.Labels.ChooseProviderCountry, placeholder: Messages.Selects.ChooseProviderCountry, items: availableCountriesMessageBird, error: !isSelectedCountriesMessageBird, stateCB: function (el) { return setSelectedCountriesMessageBird(el); } }),
                React.createElement(StyledModalFooterOneBtnWrapper, null,
                    React.createElement(StyledSaveBtn, null,
                        React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit })))));
        } }))));
};
export default BulkGateAndMessageBird;
