import { connect } from 'react-redux';
import ResetPassword from '@src/modules/Pages/ResetPassword/ResetPassword';
import { resetPasswordPageActions } from '@src/modules/Pages/ResetPassword/redux/reducer';
import resetPasswordPageStore from './redux/selector';
var mapStateToProps = function (state) { return ({
    isLoadingChangePassword: resetPasswordPageStore.getIsLoadingChangePassword(state),
    changePasswordResult: resetPasswordPageStore.getChangePasswordResult(state),
    isValidToken: resetPasswordPageStore.getIsValidToken(state),
    isLoadingCheckValidLoader: resetPasswordPageStore.getIsLoadingCheckValidLoader(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    sendNewPassword: function (payload) { return dispatch(resetPasswordPageActions.sendNewPassword(payload)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
