import React from 'react';
import { getDefaultPaperClip, getDefaultSendIcon } from '@src/constants/defaultCustomizations';
import StyledFooter from './StyledFooter';
function Footer(_a) {
    var customizations = _a.customizations;
    var footer = customizations.footer;
    var sendImage = footer.sendImage, sendFileImage = footer.sendFileImage, inputColor = footer.inputColor, inputPlaceholder = footer.inputPlaceholder, footerBackground = footer.footerBackground, footerBorderColor = footer.footerBorderColor, sendButtonIconWidth = footer.sendButtonIconWidth, sendButtonIconHeight = footer.sendButtonIconHeight, footerBackgroundTextArea = footer.footerBackgroundTextArea, inputBorderColor = footer.inputBorderColor;
    return (React.createElement(StyledFooter, { inputColor: inputColor, footerBackground: footerBackground, footerBorderColor: footerBorderColor, sendButtonIconWidth: sendButtonIconWidth, sendButtonIconHeight: sendButtonIconHeight, footerBackgroundTextArea: footerBackgroundTextArea, inputBorderColor: inputBorderColor },
        React.createElement("div", { className: "textarea-wrap" },
            React.createElement("div", { className: "send-image-wrap" },
                React.createElement("img", { className: "send-image", src: sendFileImage || getDefaultPaperClip(), alt: "paperClip", style: { marginRight: '10px' } }),
                React.createElement("img", { src: sendImage || getDefaultSendIcon(), alt: "sendImg" })),
            React.createElement("textarea", { placeholder: inputPlaceholder || 'Type your message' }))));
}
export default Footer;
