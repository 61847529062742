import React from 'react';
import Icon from '@src/components/Icon/';
import { icons } from '@public/images';
import { StyledListItem, StyledListItemText } from './styled';
export var ListItem = function (props) {
    var el = props.el, onClick = props.onClick, isSelected = props.isSelected, onClickItemIcon = props.onClickItemIcon, value = props.value;
    return (React.createElement(StyledListItem, { isSelected: isSelected },
        React.createElement(StyledListItemText, { onClick: onClick }, el),
        React.createElement(Icon, { src: icons.close, styles: { width: '10px' }, onClick: function () { return onClickItemIcon(value); } })));
};
