import { createSelector } from '@reduxjs/toolkit';
var channelsState = function (state) { return state.pages.channels; };
var channelsStore = {
    getCurrentChannel: createSelector(channelsState, function (state) { return state.currentChannel; }),
    getCurrentBrandSmsMessengers: createSelector(channelsState, function (state) { return state.currentBrandMessengers.filter(function (el) { return el.messenger === 'sms'; }); }),
    getCurrentBrandEmailMessengers: createSelector(channelsState, function (state) { return state.currentBrandMessengers.filter(function (el) { return el.messenger === 'email'; }); }),
    getCurrentBrandTelegramPrivateMessengers: createSelector(channelsState, function (state) { return state.currentBrandMessengers.filter(function (el) { return el.messenger === 'telegramPrivate'; }); }),
    getCurrentChannelMessengers: createSelector(channelsState, function (state) { return state.currentChannelMessengers; }),
};
export default channelsStore;
