var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { authActions } from '@src/modules/Auth/redux/reducer';
import { system } from '@src/constants/objects';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { StyledSocialButton, StyledSocialButtonText } from './styled';
var _a = system.AUTH_PROVIDERS.GOOGLE, CLIENT_ID = _a.CLIENT_ID, NAME = _a.NAME;
var LoginButtonGoogle = function (_a) {
    var loginSocial = _a.loginSocial;
    var loginGoogle = function (code) { return loginSocial(__assign(__assign({}, code), { serviceName: NAME })); };
    return (React.createElement(GoogleLogin, { responseType: "code", render: function (renderProps) { return (React.createElement(StyledSocialButton, { onClick: renderProps.onClick },
            React.createElement("img", { src: icons.google, alt: "Google Logo" }),
            React.createElement(StyledSocialButtonText, null, Messages.Buttons.loginWithGoogle))); }, clientId: CLIENT_ID, onSuccess: loginGoogle, cookiePolicy: "single_host_origin" }));
};
var mapDispatchToProps = function (dispatch) { return ({
    loginSocial: function (data) { return dispatch(authActions.loginSocial(data)); },
}); };
export default connect(null, mapDispatchToProps)(LoginButtonGoogle);
