var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from 'react';
import { icons } from '@public/images';
import { StyledAccordion, StyledAccordionContentWrapper, StyledAccordionSecondaryTitle, StyledAccordionTitle, StyledAccordionWrapper, } from '@src/components/Accordion/styled';
import Icon from '@src/components/Icon';
export var Accordion = function (props) {
    var title = props.title, children = props.children, isPadding = props.isPadding, secondBackground = props.secondBackground, _a = props.firstIcon, firstIcon = _a === void 0 ? true : _a, isBoldTitle = props.isBoldTitle, secondaryIcon = props.secondaryIcon, isBorderBottom = props.isBorderBottom, secondaryTitle = props.secondaryTitle, secondaryIconClick = props.secondaryIconClick;
    var _b = __read(useState(false), 2), isOpenAccordion = _b[0], setIsOpenAccordion = _b[1];
    var toggleAccordion = function () {
        setIsOpenAccordion(function (prevState) { return !prevState; });
    };
    var contentRef = useRef(null);
    var onContentHandler = function (e) {
        e.stopPropagation();
    };
    var onClickToggleAccordion = function (e) {
        e.stopPropagation();
        toggleAccordion();
    };
    var onClickSecondaryIconClick = function (e) {
        e.stopPropagation();
        if (secondaryIconClick) {
            secondaryIconClick();
        }
    };
    return (React.createElement(StyledAccordionWrapper, { onClick: toggleAccordion },
        React.createElement(StyledAccordion, { isPadding: isPadding, isBorderBottom: isBorderBottom, secondBackground: secondBackground, isOpenAccordion: isOpenAccordion, secondaryIcon: secondaryIcon },
            React.createElement("div", { className: "title-wrapper" },
                React.createElement(StyledAccordionTitle, { isBoldTitle: isBoldTitle }, title),
                secondaryTitle && (React.createElement(StyledAccordionSecondaryTitle, { className: "secondary-title" }, secondaryTitle))),
            React.createElement("div", { className: "accordion__icon" },
                secondaryIcon && React.createElement(Icon, { src: icons.plus, onClick: onClickSecondaryIconClick, isEvent: true }),
                firstIcon && React.createElement(Icon, { src: icons.downArrow, onClick: onClickToggleAccordion, isEvent: true }))),
        React.createElement(StyledAccordionContentWrapper, { ref: contentRef, isOpenAccordion: isOpenAccordion, secondBackground: secondBackground },
            React.createElement("div", { className: "content", onClick: onContentHandler }, children))));
};
