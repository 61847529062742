import { connect } from 'react-redux';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { groupsActions } from '@src/modules/Pages/Groups/redux/reducer';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import UsefulPhraseModal from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalUsefulPhrase/UsefulPhraseModal';
var mapStateToProps = function (state) { return ({
    currentGroup: groupsStore.getCurrentGroup(state),
    isLoadingUsefulPhrases: loadingStore.getIsLoadingUsefulPhrases(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    createNewPhrase: function (data) { return dispatch(groupsActions.createNewPhrase(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    updatePhrase: function (data) { return dispatch(groupsActions.updatePhrase(data)); },
    toggleModalNewCategory: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.NEW_CATEGORY_GROUPS, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UsefulPhraseModal);
