var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
var StyledIcon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  width: 65px;\n  height: 65px;\n  border-radius: 50%;\n  cursor: pointer;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  img {\n    border-radius: 50%;\n    width: 34px;\n    height: 34px;\n  }\n"], ["\n  background: ", ";\n  width: 65px;\n  height: 65px;\n  border-radius: 50%;\n  cursor: pointer;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  img {\n    border-radius: 50%;\n    width: 34px;\n    height: 34px;\n  }\n"])), function (_a) {
    var iconBackground = _a.iconBackground;
    return iconBackground || '#4a87e5';
});
export default StyledIcon;
var templateObject_1;
