var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { icons, localeFlags } from '@public/images';
import { getAppLang, Messages, setAppLang } from '@public/locales/messages';
import { DropDownMenu as LocalesMenu } from '@src/components/DropDownMenu/DropDownMenu';
import { Icon as HeaderLogo, Icon as LogoutIcon } from '@src/components/Icon/Icon';
import { ToggleSwitch } from '@src/components/Switch/Switch';
import { system } from '@src/constants/objects';
import { IPages } from '@src/global-interfaces';
import HeaderUserName from '@src/modules/Header/HeaderUserName/HeaderUserName';
import { THEME_TYPES } from '@src/redux/app/types';
import { redirect } from '@src/utils/history';
import { setThemeLS } from '@src/utils/theme';
import { Navigation } from './Navigation';
import { StyledHeader } from './styled';
var Logout = Messages.Message.Logout;
var ROOT = system.PAGE_PATHS.ROOT, LOCALES = system.LOCALES;
var Header = function (props) {
    var _a, _b;
    var showConfirm = props.showConfirm, saveCurrentPage = props.saveCurrentPage, setTheme = props.setTheme, theme = props.theme, profile = props.profile;
    var locales = [
        {
            icon: localeFlags.en,
            label: LOCALES.en.name,
            title: LOCALES.en.fullName,
            onClick: function () { return setLocale(LOCALES.en.fullName, localeFlags.en, LOCALES.en.name); },
        },
        {
            icon: localeFlags.ua,
            label: LOCALES.ua.name,
            title: LOCALES.ua.fullName,
            onClick: function () { return setLocale(LOCALES.ua.fullName, localeFlags.ua, LOCALES.ua.name); },
        },
        {
            icon: localeFlags.es,
            label: LOCALES.es.name,
            title: LOCALES.es.fullName,
            onClick: function () { return setLocale(LOCALES.es.fullName, localeFlags.es, LOCALES.es.name); },
        },
        {
            icon: localeFlags.de,
            label: LOCALES.de.name,
            title: LOCALES.de.fullName,
            onClick: function () { return setLocale(LOCALES.de.fullName, localeFlags.de, LOCALES.de.name); },
        },
        {
            icon: localeFlags.fr,
            label: LOCALES.fr.name,
            title: LOCALES.fr.fullName,
            onClick: function () { return setLocale(LOCALES.fr.fullName, localeFlags.fr, LOCALES.fr.name); },
        },
        {
            icon: localeFlags.pl,
            label: LOCALES.pl.name,
            title: LOCALES.pl.fullName,
            onClick: function () { return setLocale(LOCALES.pl.fullName, localeFlags.pl, LOCALES.pl.name); },
        },
        {
            icon: localeFlags.pt,
            label: LOCALES.pt.name,
            title: LOCALES.pt.fullName,
            onClick: function () { return setLocale(LOCALES.pt.fullName, localeFlags.pt, LOCALES.pt.name); },
        },
        {
            icon: localeFlags.vi,
            label: LOCALES.vi.name,
            title: LOCALES.vi.fullName,
            onClick: function () { return setLocale(LOCALES.vi.fullName, localeFlags.vi, LOCALES.vi.name); },
        },
        {
            icon: localeFlags.it,
            label: LOCALES.it.name,
            title: LOCALES.it.fullName,
            onClick: function () { return setLocale(LOCALES.it.fullName, localeFlags.it, LOCALES.it.name); },
        },
        {
            icon: localeFlags.ko,
            label: LOCALES.ko.name,
            title: LOCALES.ko.fullName,
            onClick: function () { return setLocale(LOCALES.ko.fullName, localeFlags.ko, LOCALES.ko.name); },
        },
        {
            icon: localeFlags.ar,
            label: LOCALES.ar.name,
            title: LOCALES.ar.fullName,
            onClick: function () { return setLocale(LOCALES.ar.fullName, localeFlags.ar, LOCALES.ar.name); },
        },
        {
            icon: localeFlags.he,
            label: LOCALES.he.name,
            title: LOCALES.he.fullName,
            onClick: function () { return setLocale(LOCALES.he.fullName, localeFlags.he, LOCALES.he.name); },
        },
        {
            icon: localeFlags.hu,
            label: LOCALES.hu.name,
            title: LOCALES.hu.fullName,
            onClick: function () { return setLocale(LOCALES.hu.fullName, localeFlags.hu, LOCALES.hu.name); },
        },
    ];
    var lang = (_a = locales.find(function (el) { return el.label === getAppLang(); })) === null || _a === void 0 ? void 0 : _a.title;
    var langIcon = (_b = locales.find(function (el) { return el.label === getAppLang(); })) === null || _b === void 0 ? void 0 : _b.icon;
    var _c = __read(useState(lang || LOCALES.en.fullName), 2), language = _c[0], setLanguage = _c[1];
    var _d = __read(useState(langIcon || localeFlags.en), 2), languageIcon = _d[0], setLanguageIcon = _d[1];
    var setLocale = function (lang, imgSrc, label) {
        setLanguage(lang);
        setLanguageIcon(imgSrc);
        setAppLang(label);
        window.location.reload();
    };
    var headerLogoStyles = {
        padding: '0 30px',
        marginTop: '3px',
    };
    var logoutIconStyles = {
        marginRight: '20px',
    };
    var position = {
        top: '41px',
    };
    var headerLogoIconHandler = function () {
        saveCurrentPage(IPages.dialogs);
        redirect(ROOT);
    };
    var changeTheme = function (e) {
        if (e.target.checked) {
            setTheme(THEME_TYPES.dark);
            setThemeLS(THEME_TYPES.dark);
        }
        else {
            setTheme(THEME_TYPES.light);
            setThemeLS(THEME_TYPES.light);
        }
    };
    return (React.createElement(StyledHeader, null,
        React.createElement(HeaderLogo, { onClick: headerLogoIconHandler, src: icons.askMe, styles: headerLogoStyles }),
        React.createElement(Navigation, { saveCurrentPage: saveCurrentPage }),
        React.createElement(HeaderUserName, { profileName: profile.name, avatarUrl: profile.avatar }),
        React.createElement("div", { className: "options" },
            React.createElement(LocalesMenu, { list: locales, activeItem: language, activeIconItem: languageIcon, position: position }),
            React.createElement("div", { style: { direction: 'ltr' } },
                React.createElement(ToggleSwitch, { label: "switchTheme", onChange: changeTheme, checked: THEME_TYPES.dark === theme, contentOn: "\uD83C\uDF1C", contentOff: "\uD83C\uDF1E" })),
            React.createElement(LogoutIcon, { onClick: function () { return showConfirm({ msg: Logout }); }, src: icons.logout, styles: logoutIconStyles }))));
};
export default Header;
