var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledSocialButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 14px 24px;\n    width: 100%;\n    font-weight: bold;\n    letter-spacing: 0.5px;\n    background: none;\n    border: 1px solid ", ";\n    color: ", ";\n    margin-bottom: 15px;\n\n    & > img {\n      margin-right: auto;\n      height: 20px;\n      width: 20px;\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 14px 24px;\n    width: 100%;\n    font-weight: bold;\n    letter-spacing: 0.5px;\n    background: none;\n    border: 1px solid ", ";\n    color: ", ";\n    margin-bottom: 15px;\n\n    & > img {\n      margin-right: auto;\n      height: 20px;\n      width: 20px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.socialBtn;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.socialBtn;
});
export var StyledSocialButtonText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    width: 100%;\n  }\n"], ["\n  && {\n    width: 100%;\n  }\n"])));
var templateObject_1, templateObject_2;
