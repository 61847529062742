import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
var getUser = function (accessToken) { return wretch("".concat(apiConfig.baseUrl, "/agent/me"))
    .auth("Bearer ".concat(accessToken))
    .get()
    .json(); };
var getUserRoles = function (accessToken, userId) { return wretch("".concat(apiConfig.baseUrl, "/role?agentId=").concat(userId))
    .auth("Bearer ".concat(accessToken))
    .get()
    .json(); };
var register = function (body) { return wretch("".concat(apiConfig.authUrl, "/auth/registration"))
    .post(body)
    .json(); };
var loginLocal = function (body) { return wretch("".concat(apiConfig.authUrl, "/auth/local"))
    .post(body)
    .json(); };
var loginGoogle = function (code) { return wretch("".concat(apiConfig.authUrl, "/auth/external-response"))
    .post(code)
    .json(); };
var loginFacebook = function (accessToken) { return wretch("".concat(apiConfig.authUrl, "/auth/facebook-response"))
    .post(accessToken)
    .json(); };
var forgotPassword = function (email) { return wretch("".concat(apiConfig.authUrl, "/auth/forgot-password"))
    .post(email)
    .json(); };
export var authApi = {
    getUser: getUser,
    register: register,
    loginLocal: loginLocal,
    loginGoogle: loginGoogle,
    getUserRoles: getUserRoles,
    loginFacebook: loginFacebook,
    forgotPassword: forgotPassword,
};
