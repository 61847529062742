import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var DialogsPageTypes = {
    GET_MESSAGES_DIALOG: "".concat(reduxModules.dialogsPage, "-messages/getMessagesDialog"),
};
var initialState = {
    dialogMessages: [],
    audioCalls: [],
};
var dialogSlice = createSlice({
    name: reduxModules.dialogsPage,
    initialState: initialState,
    reducers: {
        setDialogMessages: function (state, action) {
            state.dialogMessages = action.payload;
        },
        setAudioCalls: function (state, action) {
            state.audioCalls = action.payload;
        },
    },
});
export var dialogActions = {
    getMessagesDialog: createAction(DialogsPageTypes.GET_MESSAGES_DIALOG),
    setDialogMessages: dialogSlice.actions.setDialogMessages,
    setAudioCalls: dialogSlice.actions.setAudioCalls,
};
export var dialogsReducer = dialogSlice.reducer;
