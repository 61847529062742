var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { FONT } from '@src/app/styled';
export var StyledAccordionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    max-width: 800px;\n    margin-top: 5px;\n  }\n"], ["\n  && {\n    max-width: 800px;\n    margin-top: 5px;\n  }\n"])));
export var StyledAccordion = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    max-width: 800px;\n    padding: ", ";\n    background: ", ";\n    border-radius: ", ";\n    border-bottom: ", ";\n    cursor: pointer;\n\n    .title-wrapper {\n      display: flex;\n      flex-direction: column;\n    }\n\n    & .accordion__icon {\n      user-select: none;\n      margin-left: 10px;\n      display: flex;\n\n      img {\n        width: 15px;\n        height: 15px;\n        filter: ", ";\n        cursor: pointer;\n      }\n\n      & div:nth-of-type(", ") {\n        margin-left: 5px;\n        transition: transform 0.2s ease-out;\n        transform: ", ";\n      }\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    max-width: 800px;\n    padding: ", ";\n    background: ", ";\n    border-radius: ", ";\n    border-bottom: ", ";\n    cursor: pointer;\n\n    .title-wrapper {\n      display: flex;\n      flex-direction: column;\n    }\n\n    & .accordion__icon {\n      user-select: none;\n      margin-left: 10px;\n      display: flex;\n\n      img {\n        width: 15px;\n        height: 15px;\n        filter: ", ";\n        cursor: pointer;\n      }\n\n      & div:nth-of-type(", ") {\n        margin-left: 5px;\n        transition: transform 0.2s ease-out;\n        transform: ", ";\n      }\n    }\n  }\n"])), function (_a) {
    var isPadding = _a.isPadding;
    return (isPadding ? '24px 30px 24px 50px' : '5px 10px');
}, function (_a) {
    var theme = _a.theme, secondBackground = _a.secondBackground;
    return secondBackground ? theme.colors.background : theme.colors.accordionBackground;
}, function (_a) {
    var isOpenAccordion = _a.isOpenAccordion;
    return (isOpenAccordion ? '5px 5px 0 0' : '5px');
}, function (_a) {
    var theme = _a.theme, isBorderBottom = _a.isBorderBottom, isOpenAccordion = _a.isOpenAccordion;
    return isBorderBottom && isOpenAccordion && "1px solid ".concat(theme.colors.text);
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
}, function (_a) {
    var secondaryIcon = _a.secondaryIcon;
    return (secondaryIcon ? 2 : 1);
}, function (_a) {
    var isOpenAccordion = _a.isOpenAccordion;
    return (isOpenAccordion ? 'rotate(0deg)' : 'rotate(-90deg)');
});
export var StyledAccordionTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    font-size: ", ";\n    font-weight: ", ";\n    color: ", ";\n  }\n"], ["\n  && {\n    font-size: ", ";\n    font-weight: ", ";\n    color: ", ";\n  }\n"])), function (_a) {
    var isBoldTitle = _a.isBoldTitle;
    return (isBoldTitle ? '14px' : FONT.DEFAULT_FONT_SIZE);
}, function (_a) {
    var isBoldTitle = _a.isBoldTitle;
    return (isBoldTitle ? 500 : 400);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledAccordionSecondaryTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    font-size: ", ";\n    text-align: justify;\n    font-weight: ", ";\n    color: ", ";\n  }\n"], ["\n  && {\n    font-size: ", ";\n    text-align: justify;\n    font-weight: ", ";\n    color: ", ";\n  }\n"])), FONT.DEFAULT_FONT_SIZE, 400, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledAccordionContentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    background: ", ";\n    border-radius: ", ";\n    transition: max-height 0.2s ease-out;\n    max-height: ", ";\n    overflow: hidden;\n\n    & .content {\n      padding: 5px 10px;\n    }\n  }\n"], ["\n  && {\n    background: ", ";\n    border-radius: ", ";\n    transition: max-height 0.2s ease-out;\n    max-height: ", ";\n    overflow: hidden;\n\n    & .content {\n      padding: 5px 10px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, secondBackground = _a.secondBackground;
    return secondBackground ? theme.colors.background : theme.colors.accordionBackground;
}, function (_a) {
    var isOpenAccordion = _a.isOpenAccordion;
    return (isOpenAccordion ? '0 0 5px 5px' : '0');
}, function (_a) {
    var isOpenAccordion = _a.isOpenAccordion;
    return (isOpenAccordion ? 'max-content' : '0');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
