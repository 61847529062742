import React from 'react';
import { icons } from '@public/images';
import Icon from '@src/components/Icon';
import { UsefulLinksItemWrapper } from '@src/modules/Pages/Tags/UsefulLinksItem/styled';
var UsefulLinksItem = function (props) {
    var name = props.name, onClickDelete = props.onClickDelete, onClickEdit = props.onClickEdit;
    return (React.createElement(UsefulLinksItemWrapper, null,
        React.createElement("div", { className: "item-useful-links-text" }, name),
        React.createElement("div", { className: "item-useful-links-icon-box" },
            React.createElement(Icon, { src: icons.editPhrases, onClick: onClickEdit }),
            React.createElement(Icon, { src: icons.deletePhrases, onClick: onClickDelete }))));
};
export default UsefulLinksItem;
