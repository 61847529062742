var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { PaginationTriggerEnum } from '../pagination-trigger.enum';
import { StyledArrow } from './StyledArrow';
export function Arrow(_a) {
    var type = _a.type, iconUrl = _a.iconUrl, skipCount = _a.skipCount, styles = _a.styles, offset = _a.offset, count = _a.count, overallCount = _a.overallCount, updatePagination = _a.updatePagination;
    var getNewPage = function () {
        switch (type) {
            case PaginationTriggerEnum.BACK: {
                return offset - skipCount;
            }
            case PaginationTriggerEnum.FORWARD: {
                return offset + skipCount;
            }
            default: {
                return offset;
            }
        }
    };
    var onArrowHandler = function () {
        var pagination = {
            offset: getNewPage(),
            count: count,
            overallCount: overallCount,
        };
        updatePagination(pagination);
    };
    return (React.createElement(StyledArrow, { onClick: onArrowHandler },
        React.createElement("img", { src: iconUrl, style: __assign({}, styles), alt: "Icon" })));
}
