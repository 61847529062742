var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Messages } from '@public/locales/messages';
export var mapClientsData = function (clients, dictionaries) {
    var brandDictionary = dictionaries.brandDictionary, tagDictionary = dictionaries.tagDictionary, agentDictionary = dictionaries.agentDictionary;
    return clients.map(function (client) { return (__assign(__assign({}, client), { id: client.id || Messages.Controls.NoData, messengerId: client.messengerId || Messages.Controls.NoData, username: client.username || Messages.Controls.NoData, firstName: client.firstName || Messages.Controls.NoData, lastName: client.lastName || Messages.Controls.NoData, email: client.email || Messages.Controls.NoData, invoiceNumber: client.invoiceNumber || Messages.Controls.NoData, brand: brandDictionary[client.brandId] || Messages.Controls.NoData, messenger: client.messenger === 'smartbot' ? 'Web Chat' : client.messenger || Messages.Controls.NoData, dialogsCount: String(client.dialogsCount) || Messages.Controls.NoData, agentAssigned: String(client.agentAssigned) || Messages.Controls.NoData, entryDate: client.entryDate || Messages.Controls.NoData, lastTag: tagDictionary[client.lastTag] || Messages.Controls.NoData, agentName: agentDictionary[client.agentId] || Messages.Controls.NoData })); });
};
