import React from 'react';
import { COLORS } from '@src/app/styled';
import Button from '@src/components/Button';
import TextEllipsisRenderer from '@src/components/tableRenderers/TextEllipsisRenderer/TextEllipsisRenderer';
import { StyledTeamLeadWrapper, StyledAddOrRemoveTeamLeadWrapper, } from '@src/modules/Pages/Agents/AgentSettings/TeamLead/styled';
import { StyledAddOrRemoveTeam } from '@src/components/Button/styled';
import { Messages } from '@public/locales/messages';
var RED = COLORS.RED, DIRTY_GREEN = COLORS.DIRTY_GREEN;
var TeamLead = function (props) {
    var groupsCurrentAgent = props.groupsCurrentAgent, currentAgentGroups = props.currentAgentGroups, makeOrRemoveTeamLead = props.makeOrRemoveTeamLead;
    return (React.createElement(StyledTeamLeadWrapper, null, currentAgentGroups.map(function (el, index) {
        var _a;
        var isTeamLead = (_a = el === null || el === void 0 ? void 0 : el.leadId) === null || _a === void 0 ? void 0 : _a.includes(groupsCurrentAgent.id);
        var backgroundBtn = isTeamLead ? RED : DIRTY_GREEN;
        var titleBtn = isTeamLead ? Messages.Buttons.removeTeamLead : Messages.Buttons.makeTeamLead;
        var data = {
            isTeamLead: isTeamLead,
            agentId: groupsCurrentAgent.id,
            groupId: el.id,
        };
        return (React.createElement(StyledAddOrRemoveTeamLeadWrapper, { key: index },
            TextEllipsisRenderer(el.name, Messages.Labels.GroupName, false),
            React.createElement(StyledAddOrRemoveTeam, { background: backgroundBtn },
                React.createElement(Button, { title: titleBtn, onClick: function () { return makeOrRemoveTeamLead(data); } }))));
    })));
};
export default TeamLead;
