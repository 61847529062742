import React from 'react';
import { MsgBotText, MsgContainer, MsgAgentText, MsgClientText, MsgBotWrapper, MsgAgentWrapper, MsgClientWrapper, NameWrapper, TimeWrapper, TimeAndNameContainer, } from '@src/components/MessagesContainer/msg/styled';
var Msg = function (props) {
    var sender = props.sender, text = props.text, _a = props.agentName, agentName = _a === void 0 ? 'Agent Ask Me' : _a, time = props.time, clientName = props.clientName;
    var date = new Date(time);
    switch (sender) {
        case 'client':
            return (React.createElement(MsgClientWrapper, null,
                React.createElement(MsgContainer, null,
                    React.createElement(TimeAndNameContainer, null,
                        React.createElement(NameWrapper, null, clientName),
                        React.createElement(TimeWrapper, null, date.toUTCString())),
                    React.createElement(MsgClientText, null, text))));
        case 'bot':
            return (React.createElement(MsgBotWrapper, null,
                React.createElement(MsgContainer, null,
                    React.createElement(TimeAndNameContainer, null,
                        React.createElement(NameWrapper, { style: { textAlign: 'end' } }, "Bot"),
                        React.createElement(TimeWrapper, { style: { textAlign: 'end' } }, date.toUTCString())),
                    React.createElement(MsgBotText, null, text))));
        default:
            return (React.createElement(MsgAgentWrapper, null,
                React.createElement(MsgContainer, null,
                    React.createElement(TimeAndNameContainer, null,
                        React.createElement(NameWrapper, { style: { textAlign: 'end' } }, agentName),
                        React.createElement(TimeWrapper, { style: { textAlign: 'end' } }, date.toUTCString())),
                    React.createElement(MsgAgentText, null, text))));
    }
};
export default Msg;
