var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { icons, pageIcons } from '@public/images';
import { Messages } from '@public/locales/messages';
import DropDownMenu from '@src/components/DropDownMenu';
import { system } from '@src/constants/objects';
import { IPages } from '@src/global-interfaces';
import { redirect } from '@src/utils/history';
import { useWindowSize } from '@src/utils/windowSize';
import { StyledNavigation, StyledNavigationWrapper } from './styled';
var _a = system.PAGE_PATHS, ROOT = _a.ROOT, TAGS = _a.TAGS, BRANDS = _a.BRANDS, AGENTS = _a.AGENTS, GROUPS = _a.GROUPS, REPORTS = _a.REPORTS, CLIENTS = _a.CLIENTS, CHANNELS = _a.CHANNELS;
var _b = Messages.Pages, AgentGroups = _b.AgentGroups, Agents = _b.Agents, Brands = _b.Brands, Channels = _b.Channels, Clients = _b.Clients, Dialogs = _b.Dialogs, Reports = _b.Reports, Tags = _b.Tags;
var dialog = pageIcons.dialog, group = pageIcons.group, agent = pageIcons.agent, tags = pageIcons.tags, client = pageIcons.client, channel = pageIcons.channel, brand = pageIcons.brand, report = pageIcons.report;
export var getAppLang = function () { return localStorage.getItem('app_lang') || 'en'; };
export function Navigation(_a) {
    var saveCurrentPage = _a.saveCurrentPage;
    var routeName = window.location.pathname;
    var pageClickHandler = function (path, currentPage) {
        saveCurrentPage(currentPage);
        redirect(path);
    };
    var getActivePage = function () {
        switch (routeName) {
            case ROOT: {
                return Dialogs.Title;
            }
            case GROUPS: {
                return AgentGroups.Title;
            }
            case AGENTS: {
                return Agents.Title;
            }
            case TAGS: {
                return Tags.Title;
            }
            case CLIENTS: {
                return Clients.Title;
            }
            case CHANNELS: {
                return Channels.Title;
            }
            case BRANDS: {
                return Brands.Title;
            }
            case REPORTS: {
                return Reports.Title;
            }
            default: {
                return Dialogs.Title;
            }
        }
    };
    var activePage = getActivePage();
    var navigationMenuItems = [
        {
            title: Dialogs.Title,
            onClick: function () { return pageClickHandler(ROOT, IPages.dialogs); },
            icon: dialog,
        },
        {
            title: AgentGroups.Title,
            onClick: function () { return pageClickHandler(GROUPS, IPages.groups); },
            icon: group,
        },
        {
            title: Agents.Title,
            onClick: function () { return pageClickHandler(AGENTS, IPages.agents); },
            icon: agent,
        },
        {
            title: Tags.Title,
            onClick: function () { return pageClickHandler(TAGS, IPages.tags); },
            icon: tags,
        },
        {
            title: Clients.Title,
            onClick: function () { return pageClickHandler(CLIENTS, IPages.clients); },
            icon: client,
        },
        {
            title: Channels.Title,
            onClick: function () { return pageClickHandler(CHANNELS, IPages.channels); },
            icon: channel,
        },
        {
            title: Brands.Title,
            onClick: function () { return pageClickHandler(BRANDS, IPages.brands); },
            icon: brand,
        },
        {
            title: Reports.Title,
            onClick: function () { return pageClickHandler(REPORTS, IPages.messengerReports); },
            icon: report,
        },
    ];
    var _b = __read(useWindowSize(), 1), width = _b[0];
    var position = {
        top: '41px',
    };
    return (React.createElement(StyledNavigationWrapper, { language: getAppLang() }, width < 1480 ? (React.createElement(DropDownMenu, { activeIconItem: icons.settings, activeItem: activePage, list: navigationMenuItems, position: position })) : (React.createElement(StyledNavigation, null, navigationMenuItems.map(function (_a) {
        var title = _a.title, onClick = _a.onClick;
        return (React.createElement("a", { key: title, className: "".concat(activePage === title ? 'active-page' : 'menu-item'), onClick: onClick }, title));
    })))));
}
