import { createSelector } from '@reduxjs/toolkit';
var isLoadingState = function (state) { return state.isLoading; };
export var loadingStore = {
    getIsLoading: createSelector(isLoadingState, function (state) { return state.isLoading; }),
    getIsLoadingMessages: createSelector(isLoadingState, function (state) { return state.isLoadingMessages; }),
    getIsLoadingCurrentTags: createSelector(isLoadingState, function (state) { return state.isLoadingCurrentTags; }),
    getIsLoadingCreatingTag: createSelector(isLoadingState, function (state) { return state.isLoadingCreatingTag; }),
    getIsLoadingCurrentAgent: createSelector(isLoadingState, function (state) { return state.isLoadingCurrentAgent; }),
    getIsLoadingCreateGroups: createSelector(isLoadingState, function (state) { return state.isLoadingCreateGroups; }),
    getIsLoadingCurrentGroups: createSelector(isLoadingState, function (state) { return state.isLoadingCurrentGroups; }),
    getIsLoadingUsefulPhrases: createSelector(isLoadingState, function (state) { return state.isLoadingUsefulPhrases; }),
    getIsLoadingChangeAgentsGroups: createSelector(isLoadingState, function (state) { return state.isLoadingChangeAgentsGroups; }),
    getIsChangePasswordLoading: createSelector(isLoadingState, function (state) { return state.isChangePasswordLoading; }),
    getIsLoadingCurrentClient: createSelector(isLoadingState, function (state) { return state.isLoadingCurrentClient; }),
    getIsLoadingCurrentDialogHistory: createSelector(isLoadingState, function (state) { return state.isLoadingCurrentDialogHistory; }),
    getIsLoadingCreateClientBrandSettings: createSelector(isLoadingState, function (state) { return state.isLoadingCreateClientBrandSettings; }),
    getIsLoadingCreateClient: createSelector(isLoadingState, function (state) { return state.isLoadingCreateClient; }),
    getIsLoadingImportClients: createSelector(isLoadingState, function (state) { return state.isLoadingImportClients; }),
    getIsLoadingCreateBrand: createSelector(isLoadingState, function (state) { return state.isLoadingCreateBrand; }),
    getIsLoadingCurrentBrand: createSelector(isLoadingState, function (state) { return state.isLoadingCurrentBrand; }),
    getIsLoadingCreateInbox: createSelector(isLoadingState, function (state) { return state.isLoadingCreateInbox; }),
    getIsLoadingCreateChannel: createSelector(isLoadingState, function (state) { return state.isLoadingCreateChannel; }),
    getIsLoadingCurrentChannel: createSelector(isLoadingState, function (state) { return state.isLoadingCurrentChannel; }),
    getIsLoadingChangeMessengerChannel: createSelector(isLoadingState, function (state) { return state.isLoadingChangeMessengerChannel; }),
    getIsLoadingLayoutWebChat: createSelector(isLoadingState, function (state) { return state.isLoadingLayoutWebChat; }),
    getIsLoadingImportPhones: createSelector(isLoadingState, function (state) { return state.isLoadingImportPhones; }),
    getIsLoadingSelectClientsBulkMessaging: createSelector(isLoadingState, function (state) { return state.isLoadingSelectClientsBulkMessaging; }),
    getIsLoadingPipeDrive: createSelector(isLoadingState, function (state) { return state.isLoadingPipeDrive; }),
};
