import React from 'react';
import { isEqual } from 'lodash';
import { Messages } from '@public/locales/messages';
import CustomDatePicker from '@src/components/CustomDatePicker';
import SearchList from '@src/components/SearchList';
import { AccessLevelFilters, ActiveDialogsFilters, isNewbieFilters, LanguageFilters, MessengersFilters, sessionStatusFilters, StatusesFilters, } from '@src/constants/allFilters';
import { transformDictionary } from '@src/utils/transformDictionary';
var ComponentsFiltration = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var setShow = props.setShow, dictionaries = props.dictionaries, currentPage = props.currentPage, selectedFilter = props.selectedFilter, selectedAllFilters = props.selectedAllFilters, saveValueSelectedFilter = props.saveValueSelectedFilter, deleteValueSelectedFilter = props.deleteValueSelectedFilter;
    switch (selectedFilter) {
        case 'date': {
            return (React.createElement(CustomDatePicker, { currentPage: currentPage, selectedData: (_a = selectedAllFilters[currentPage]) === null || _a === void 0 ? void 0 : _a.date, getValueSelectedFilter: saveValueSelectedFilter }));
        }
        case 'messengers': {
            return (React.createElement(SearchList, { multiple: true, isSearch: false, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: MessengersFilters, selectedItem: ((_b = selectedAllFilters[currentPage]) === null || _b === void 0 ? void 0 : _b.messengers) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { messengers: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { messengers: data } }); }, placeholder: Messages.Controls.SearchMessengers }));
        }
        case 'agentNames': {
            return (React.createElement(SearchList, { multiple: true, isSearch: true, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: transformDictionary(dictionaries.agentDictionary, 'name', 'value'), selectedItem: ((_c = selectedAllFilters[currentPage]) === null || _c === void 0 ? void 0 : _c.agentNames) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { agentNames: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { agentNames: data } }); }, placeholder: Messages.Controls.SearchAgent }));
        }
        case 'languages': {
            return (React.createElement(SearchList, { multiple: true, isSearch: false, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: LanguageFilters, selectedItem: ((_d = selectedAllFilters[currentPage]) === null || _d === void 0 ? void 0 : _d.languages) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { languages: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { languages: data } }); }, placeholder: Messages.Controls.SearchLanguage }));
        }
        case 'channels': {
            return (React.createElement(SearchList, { multiple: true, isSearch: true, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: transformDictionary(dictionaries.channelDictionary, 'name', 'value'), selectedItem: ((_e = selectedAllFilters[currentPage]) === null || _e === void 0 ? void 0 : _e.channels) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { channels: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { channels: data } }); }, placeholder: Messages.Controls.SearchChannel }));
        }
        case 'tags': {
            return (React.createElement(SearchList, { multiple: true, isSearch: true, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: transformDictionary(dictionaries.tagDictionary, 'name', 'value'), selectedItem: ((_f = selectedAllFilters[currentPage]) === null || _f === void 0 ? void 0 : _f.tags) || [], onClickItemIcon: function (data) { return deleteValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { tags: data } }); }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { tags: data } }); }, placeholder: Messages.Controls.SearchTag }));
        }
        case 'lastTags': {
            return (React.createElement(SearchList, { multiple: true, isSearch: true, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: transformDictionary(dictionaries.tagDictionary, 'name', 'value'), selectedItem: ((_g = selectedAllFilters[currentPage]) === null || _g === void 0 ? void 0 : _g.lastTags) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { lastTags: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { lastTags: data } }); }, placeholder: Messages.Controls.SearchTag }));
        }
        case 'whiteListTags': {
            return (React.createElement(SearchList, { multiple: true, isSearch: true, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: transformDictionary(dictionaries.tagDictionary, 'name', 'value'), selectedItem: ((_h = selectedAllFilters[currentPage]) === null || _h === void 0 ? void 0 : _h.whiteListTags) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { whiteListTags: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { whiteListTags: data } }); }, placeholder: Messages.Controls.SearchWhiteListTags }));
        }
        case 'statuses': {
            return (React.createElement(SearchList, { multiple: true, isSearch: false, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: StatusesFilters, selectedItem: ((_j = selectedAllFilters[currentPage]) === null || _j === void 0 ? void 0 : _j.statuses) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { statuses: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { statuses: data } }); }, placeholder: Messages.Controls.SearchStatus }));
        }
        case 'sessionStatus': {
            return (React.createElement(SearchList, { multiple: true, isSearch: false, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: sessionStatusFilters, selectedItem: ((_k = selectedAllFilters[currentPage]) === null || _k === void 0 ? void 0 : _k.sessionStatus) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { sessionStatus: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { sessionStatus: data } }); }, placeholder: Messages.Controls.SearchSessionStatus }));
        }
        case 'brands': {
            return (React.createElement(SearchList, { multiple: true, isSearch: true, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: transformDictionary(dictionaries.brandDictionary, 'name', 'value'), selectedItem: ((_l = selectedAllFilters[currentPage]) === null || _l === void 0 ? void 0 : _l.brands) || [], onClickItemIcon: function (data) { return deleteValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { brands: data } }); }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { brands: data } }); }, placeholder: Messages.Controls.SearchBrand }));
        }
        case 'accessLevel': {
            return (React.createElement(SearchList, { multiple: true, isSearch: false, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: AccessLevelFilters, selectedItem: ((_m = selectedAllFilters[currentPage]) === null || _m === void 0 ? void 0 : _m.accessLevel) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { accessLevel: data },
                    });
                }, onClickItem: function (data) { return saveValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { accessLevel: data } }); }, placeholder: Messages.Controls.SearchAccessLevel }));
        }
        case 'hasActiveDialogs': {
            return (React.createElement(SearchList, { multiple: false, isSearch: false, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: ActiveDialogsFilters, selectedItem: ((_o = selectedAllFilters[currentPage]) === null || _o === void 0 ? void 0 : _o.hasActiveDialogs) || [], onClickItemIcon: function (data) {
                    return deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { hasActiveDialogs: data },
                    });
                }, onClickItem: function (data) {
                    return saveValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { hasActiveDialogs: data },
                    });
                }, placeholder: Messages.Controls.SearchHasActiveDialogs }));
        }
        case 'isNewbie': {
            return (React.createElement(SearchList, { multiple: false, isSearch: false, hideSearchList: setShow, isAbsolutePosition: false, searchListArr: isNewbieFilters, selectedItem: ((_p = selectedAllFilters[currentPage]) === null || _p === void 0 ? void 0 : _p.isNewbie) || [], onClickItemIcon: function (data) {
                    deleteValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { isNewbie: data },
                    });
                }, onClickItem: function (data) {
                    return saveValueSelectedFilter({
                        currentPage: currentPage,
                        SelectedFilterValue: { isNewbie: data },
                    });
                }, placeholder: Messages.Controls.Newbie }));
        }
        default: {
            return null;
        }
    }
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(ComponentsFiltration, areEqual);
