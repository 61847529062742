var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledSpace = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 20px 0;\n    .line {\n      height: 1px;\n      width: 100%;\n      background-color: ", ";\n    }\n    .text {\n      white-space: nowrap;\n      color: ", ";\n      padding: ", ";\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 20px 0;\n    .line {\n      height: 1px;\n      width: 100%;\n      background-color: ", ";\n    }\n    .text {\n      white-space: nowrap;\n      color: ", ";\n      padding: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.spaceLine;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.spaceText;
}, function (_a) {
    var text = _a.text;
    return (text ? '0 20px' : '');
});
var templateObject_1;
