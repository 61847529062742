var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useState, } from 'react';
import { Field, Form } from 'react-final-form';
import { isEqual } from 'lodash';
import { Messages } from '@public/locales/messages';
import { notificationTypes, statusTag } from '@src/global-interfaces';
import { TButtonTypes } from '@src/components/Button/Button';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Select from '@src/components/Select';
import Button from '@src/components/Button';
import ModalWindow from '@src/components/ModalWindow';
import UsefulLinksItem from '@src/modules/Pages/Tags/UsefulLinksItem';
import UsefulLinksModal from '@src/modules/Pages/Tags/UsefulLinksModal';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { useCallbackUseful } from '@src/modules/Pages/Tags/UsefulLinksModal/useCallbackUseful';
import { transformArrForSelect } from '@src/utils/transformArrForSelect';
import { composeValidators, isRequired, lengthCreator } from '@src/utils/validations';
import { StyledUpdateTagModalWrapper } from '@src/modules/Pages/Tags/TagsUpdateModal/styled';
import { StyledAddNewBtn, StyledSaveBtn } from '@src/components/Button/styled';
import { StyledTagInput, StyleUsefulLinksWrapper } from '@src/modules/Pages/Tags/TagsCreateModal/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var TagsUpdateModal = function (props) {
    var currentTag = props.currentTag, resetCurrentTag = props.resetCurrentTag, showNotification = props.showNotification, updateTagSettings = props.updateTagSettings, assignedAgentTags = props.assignedAgentTags, unAssignedAgentTags = props.unAssignedAgentTags, isLoadingCurrentTags = props.isLoadingCurrentTags, toggleModalUpdateTags = props.toggleModalUpdateTags, isModalOpenUsefulLinks = props.isModalOpenUsefulLinks, toggleModalUsefulLinks = props.toggleModalUsefulLinks, resetNotificationState = props.resetNotificationState;
    var _a = useCallbackUseful(), clickEdit = _a.clickEdit, setUsefulLinksElem = _a.setUsefulLinksElem, editUsefulLinks = _a.editUsefulLinks, deleteUsefulLinks = _a.deleteUsefulLinks;
    var minNameTag = lengthCreator.minNameTag, maxNameTag = lengthCreator.maxNameTag;
    var _b = __read(useState(''), 2), nameTag = _b[0], setNameTag = _b[1];
    var _c = __read(useState((currentTag === null || currentTag === void 0 ? void 0 : currentTag.usefulLinks) || []), 2), usefulLinks = _c[0], setUsefulLinks = _c[1];
    var _d = __read(useState(false), 2), isOpenTwoModal = _d[0], setIsOpenTwoModal = _d[1];
    var _e = __read(useState([]), 2), assignedAgents = _e[0], setAssignedAgents = _e[1];
    var _f = __read(useState([]), 2), selectedStatusTag = _f[0], setSelectedStatusTag = _f[1];
    useEffect(function () {
        setNameTag(currentTag === null || currentTag === void 0 ? void 0 : currentTag.name);
        setUsefulLinks(currentTag === null || currentTag === void 0 ? void 0 : currentTag.usefulLinks);
        setAssignedAgents(transformArrForSelect(assignedAgentTags));
        var status = statusTag.find(function (el) { return el.value === (currentTag === null || currentTag === void 0 ? void 0 : currentTag.status); });
        setSelectedStatusTag([status]);
    }, [currentTag, assignedAgentTags, unAssignedAgentTags]);
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChangeSetUsefulLinks = useCallback(function (el) {
        var tempUsefulLinks = __spreadArray([], __read(usefulLinks), false);
        setUsefulLinksElem(el, tempUsefulLinks, setUsefulLinks, toggleModalUsefulLinks, setIsOpenTwoModal);
    }, [usefulLinks]);
    var onEditUsefulLinks = useCallback(function (el, index) {
        var tempUsefulLinks = __spreadArray([], __read(usefulLinks), false);
        editUsefulLinks(el, tempUsefulLinks, setUsefulLinks, toggleModalUsefulLinks, setIsOpenTwoModal, index);
    }, [usefulLinks]);
    var deleteUsefulLink = useCallback(function (index) {
        var tempUsefulLinks = __spreadArray([], __read(usefulLinks), false);
        deleteUsefulLinks(index, tempUsefulLinks, setUsefulLinks);
    }, [usefulLinks]);
    var onClickEdit = useCallback(function (index) {
        var tempUsefulLinks = __spreadArray([], __read(usefulLinks), false);
        clickEdit(index, setIsOpenTwoModal, tempUsefulLinks, toggleModalUsefulLinks);
    }, [usefulLinks]);
    var onSelectedStatus = useCallback(function (el) {
        setSelectedStatusTag([el]);
    }, [selectedStatusTag]);
    var onSelectedAgent = useCallback(function (el) {
        setAssignedAgents(el);
    }, [assignedAgents]);
    var onSubmit = function (data) {
        var _a;
        updateTagSettings({
            name: data.tagName,
            usefulLinks: usefulLinks,
            assignedAgents: (assignedAgents === null || assignedAgents === void 0 ? void 0 : assignedAgents.map(function (el) { return el.value; })) || [],
            status: (_a = selectedStatusTag === null || selectedStatusTag === void 0 ? void 0 : selectedStatusTag.find(function (el) { return el.value; })) === null || _a === void 0 ? void 0 : _a.value,
        });
    };
    var closeModal = function () {
        toggleModalUpdateTags();
        resetCurrentTag(null);
    };
    var tagsInput = InputWithStyles(Input, StyledTagInput);
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: "".concat(Messages.Labels.UpdateTag, " ").concat(currentTag ? "- ".concat(currentTag === null || currentTag === void 0 ? void 0 : currentTag.name) : ''), outsideClick: !isOpenTwoModal },
        React.createElement(StyledUpdateTagModalWrapper, null, (isLoadingCurrentTags) ? (React.createElement(Loader, null)) : (React.createElement(Form, { onSubmit: onSubmit, initialValues: { tagName: nameTag }, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(StyledModalContentWrapper, null,
                        React.createElement(StyledModalContentFieldsWrapper, null,
                            React.createElement(Field, { name: "tagName", label: "".concat(Messages.Labels.TagName, "*"), component: tagsInput, validate: composeValidators(isRequired, minNameTag, maxNameTag), placeholder: "".concat(Messages.Controls.TagName, "*"), showWarning: showWarning }),
                            React.createElement(Select, { items: statusTag, initialState: selectedStatusTag, label: "".concat(Messages.Labels.SelectTagStatus, "*"), isMultiply: false, placeholder: "".concat(Messages.Selects.SelectTagStatus, "*"), stateCB: function (el) { return onSelectedStatus(el); } }),
                            React.createElement("div", { style: { paddingTop: '5px' } },
                                React.createElement(Select, { items: __spreadArray(__spreadArray([], __read(transformArrForSelect(assignedAgentTags)), false), __read(transformArrForSelect(unAssignedAgentTags)), false), initialState: assignedAgents, label: "".concat(Messages.Labels.SelectAgent, "*"), placeholder: "".concat(Messages.Selects.SelectAgent, "*"), stateCB: function (el) { return onSelectedAgent(el); } })),
                            !!(usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.length) && (React.createElement(StyleUsefulLinksWrapper, null,
                                React.createElement("div", { className: "useful-links-name" }, Messages.Labels.UsefulLinks),
                                React.createElement("div", { className: "useful-links-content" }, usefulLinks === null || usefulLinks === void 0 ? void 0 : usefulLinks.map(function (el, index) { return (React.createElement(UsefulLinksItem, { name: el.alias, key: index, onClickDelete: function () { return deleteUsefulLink(index); }, onClickEdit: function () { return onClickEdit(index); } })); })))),
                            React.createElement(StyledAddNewBtn, null,
                                React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addNewUsefulLinks), onClick: function () {
                                        toggleModalUsefulLinks(null);
                                        setIsOpenTwoModal(true);
                                    } }))),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit }))))));
            } }))),
        isModalOpenUsefulLinks && (React.createElement(UsefulLinksModal, { setIsOpenTwoModal: setIsOpenTwoModal, setUsefulLinks: onChangeSetUsefulLinks, onEditUsefulLinks: onEditUsefulLinks }))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(TagsUpdateModal, areEqual);
