import { connect } from 'react-redux';
import { loadingStore } from '@src/redux/loading/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { tagsActions } from '@src/modules/Pages/Tags/redux/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { notificationActions } from '@src/redux/notification/reducer';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import TagsCreateModal from '@src/modules/Pages/Tags/TagsCreateModal/TagsCreateModal';
var mapStateToProps = function (state) { return ({
    isLoadingCreatingTag: loadingStore.getIsLoadingCreatingTag(state),
    groupDictionary: dictionariesStore.dictionaries(state).groupDictionary,
    isModalOpenUsefulLinks: modalWindowsStore.getIsModalOpen(state, modalTypes.USEFUL_LINKS_MODAL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    createTag: function (data) { return dispatch(tagsActions.createTag(data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    toggleModalCreateTags: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_TAGS_MODAL, null)); },
    toggleModalUsefulLinks: function (data) { return dispatch(modalWindowsActions.toggleModal(modalTypes.USEFUL_LINKS_MODAL, data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TagsCreateModal);
