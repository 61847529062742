import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var createTag = function (tag, groupIds) { return wretch("".concat(apiConfig.baseUrl, "/Tag").concat(groupIds))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(tag)
    .json(); };
var getTagById = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Tag?id=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var getAgentsForTag = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Agent/agents/byTagId?tagId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var updateTag = function (tag) { return wretch("".concat(apiConfig.baseUrl, "/Tag?superadmin"))
    .auth("Bearer ".concat(getAuthCookie()))
    .json(tag)
    .put()
    .json(); };
var assignTagToAgents = function (tagId, assignedAgentsIds) { return wretch("".concat(apiConfig.baseUrl, "/Tag/tag/agents?tagId=").concat(tagId))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(assignedAgentsIds); };
var unAssignTagFromAgents = function (tagId, agentId) { return wretch("".concat(apiConfig.baseUrl, "/Agent/unassignTags?agentId=").concat(agentId))
    .auth("Bearer ".concat(getAuthCookie()))
    .json([tagId])
    .delete(); };
export var tagsApi = {
    createTag: createTag,
    updateTag: updateTag,
    getTagById: getTagById,
    getAgentsForTag: getAgentsForTag,
    assignTagToAgents: assignTagToAgents,
    unAssignTagFromAgents: unAssignTagFromAgents,
};
