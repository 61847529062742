import React from 'react';
import { Messages } from '@public/locales/messages';
import Header from '@src/modules/Header';
import Toolbar from '@src/modules/ToolBar';
import ModalWindow from '@src/components/ModalWindow';
import TableComponent from '@src/modules/Pages/TableComponent';
import AgentSettings from '@src/modules/Pages/Agents/AgentSettings';
import { StyledContent, StyledPageWrapper } from '@src/app/styled';
var AgentsPage = function (props) {
    var currentPage = props.currentPage, isModalOpen = props.isModalOpen, toggleModal = props.toggleModal, currentAgent = props.currentAgent;
    return (React.createElement(StyledPageWrapper, { className: "agents-page" },
        React.createElement(Header, null),
        React.createElement(StyledContent, { currentPage: currentPage },
            React.createElement(Toolbar, null),
            React.createElement(TableComponent, null)),
        isModalOpen && (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: "".concat(Messages.Labels.AgentsSettings, " ").concat(currentAgent ? "- ".concat(currentAgent === null || currentAgent === void 0 ? void 0 : currentAgent.name) : '') },
            React.createElement(AgentSettings, null)))));
};
export default AgentsPage;
