import React from 'react';
import { ClientInfoTableHeadNames } from '@src/global-interfaces';
import Loader from '@src/components/Loader';
import NoData from '@src/components/NoData';
import ModalWindow from '@src/components/ModalWindow';
import DateRenderer from '@src/components/tableRenderers/DateRenderer/DateRenderer';
import StatusRenderer from '@src/components/tableRenderers/StatusRenderer/StatusRenderer';
import MessengerRenderer from '@src/components/tableRenderers/MessengerRenderer/MessengerRenderer';
import TextEllipsisRenderer from '@src/components/tableRenderers/TextEllipsisRenderer/TextEllipsisRenderer';
import CustomClientInfoTable from '@src/components/CustomClientInfoTable';
import { dialogDuration } from '@src/utils/dialogDuration';
import HistoryComponent from '@src/modules/Pages/Clients/HistoryDialog';
import { StyledClientInfoWrapper } from '@src/modules/Pages/Clients/ClientInfo/styled';
import { Messages } from '@public/locales/messages';
var ClientInfo = function (props) {
    var _a, _b, _c, _d;
    var toggleModal = props.toggleModal, isLoadingCurrentClient = props.isLoadingCurrentClient, clientDialogs = props.clientDialogs, tagDictionary = props.tagDictionary, channelDictionary = props.channelDictionary, agentDictionary = props.agentDictionary;
    var clientInfoTableData = clientDialogs === null || clientDialogs === void 0 ? void 0 : clientDialogs.map(function (el) {
        var _a, _b, _c;
        return ({
            tag: TextEllipsisRenderer(tagDictionary[el.Tag], 'Tag', false),
            status: StatusRenderer(el.status),
            channel: TextEllipsisRenderer(channelDictionary[el.channelId], 'Channel', false),
            messenger: MessengerRenderer(el.messenger),
            agentName: TextEllipsisRenderer(agentDictionary[el.managerId], 'Agent name', false),
            lastActivity: DateRenderer(el.lastActivity, 'Last activity', false),
            dialogHistoryComponent: React.createElement(HistoryComponent, { dialogId: el.id, clientName: ((_a = el === null || el === void 0 ? void 0 : el.clientInfo) === null || _a === void 0 ? void 0 : _a.username) || ((_b = el === null || el === void 0 ? void 0 : el.clientInfo) === null || _b === void 0 ? void 0 : _b.lastName) || ((_c = el === null || el === void 0 ? void 0 : el.clientInfo) === null || _c === void 0 ? void 0 : _c.firstName) || Messages.Controls.NoData }),
        });
    });
    var date = {
        created_at: Array.isArray(clientDialogs) ? (_b = (_a = clientDialogs[0]) === null || _a === void 0 ? void 0 : _a.clientInfo) === null || _b === void 0 ? void 0 : _b.entryDate : '',
        lastActivity: Array.isArray(clientDialogs) ? (_d = (_c = clientDialogs[0]) === null || _c === void 0 ? void 0 : _c.clientInfo) === null || _d === void 0 ? void 0 : _d.lastMsgTime : '',
    };
    return (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: Messages.Labels.ClientInfo }, isLoadingCurrentClient ? (React.createElement(Loader, null)) : (React.createElement(StyledClientInfoWrapper, null, Array.isArray(clientDialogs) && clientDialogs.length >= 1 ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "client-info" },
            React.createElement("div", null, "".concat(Messages.Labels.Dialogs, ": ").concat(clientDialogs[0].clientInfo.dialogsCount)),
            React.createElement("div", null, "".concat(Messages.Labels.TotalDuration, ": ").concat(dialogDuration(date))),
            React.createElement("div", null, "".concat(Messages.Labels.Email, ": ").concat(clientDialogs[0].clientInfo.email)),
            React.createElement("div", null, "".concat(Messages.Labels.ClientId, ": ").concat(clientDialogs[0].clientInfo.id))),
        React.createElement(CustomClientInfoTable, { headNames: ClientInfoTableHeadNames, rows: clientInfoTableData, style: { headTextAlign: 'center', rowTextAlign: 'center' } }))) : (React.createElement(NoData, null))))));
};
export default ClientInfo;
