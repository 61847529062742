import { createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
import { SET_VISIBLE_LOADER } from '@src/redux/loading/loaderWrap';
var initialState = {
    isLoading: false,
    isLoadingMessages: false,
    isLoadingCreateGroups: false,
    isLoadingCurrentAgent: false,
    isLoadingCurrentGroups: false,
    isLoadingUsefulPhrases: false,
    isLoadingChangeAgentsGroups: false,
    isLoadingCurrentTags: false,
    isLoadingCreatingTag: false,
    isChangePasswordLoading: false,
    isLoadingCurrentClient: false,
    isLoadingCurrentDialogHistory: false,
    isLoadingCreateClientBrandSettings: false,
    isLoadingCreateClient: false,
    isLoadingImportClients: false,
    isLoadingCreateBrand: false,
    isLoadingCurrentBrand: false,
    isLoadingCreateInbox: false,
    isLoadingCreateChannel: false,
    isLoadingCurrentChannel: false,
    isLoadingChangeMessengerChannel: false,
    isLoadingLayoutWebChat: false,
    isLoadingImportPhones: false,
    isLoadingSelectClientsBulkMessaging: false,
    isLoadingPipeDrive: false,
};
var isLoadingSlice = createSlice({
    name: reduxModules.isLoading,
    initialState: initialState,
    reducers: null,
    extraReducers: function (builder) {
        builder.addCase(SET_VISIBLE_LOADER, function (state, action) {
            var _a = action.payload, appModule = _a.appModule, isLoading = _a.isLoading;
            if (Object.values(reduxModules).includes(appModule))
                state.isLoading = isLoading;
            if (appModule === "".concat(reduxModules.dialogsPage, "-messages"))
                state.isLoadingMessages = isLoading;
            if (appModule === "".concat(reduxModules.agentsPage, "-currentAgent"))
                state.isLoadingCurrentAgent = isLoading;
            if (appModule === "".concat(reduxModules.groupsPage, "-createGroups"))
                state.isLoadingCreateGroups = isLoading;
            if (appModule === "".concat(reduxModules.groupsPage, "-currentGroups"))
                state.isLoadingCurrentGroups = isLoading;
            if (appModule === "".concat(reduxModules.groupsPage, "-usefulPhrases"))
                state.isLoadingUsefulPhrases = isLoading;
            if (appModule === "".concat(reduxModules.groupsPage, "-changeAgentsGroups"))
                state.isLoadingChangeAgentsGroups = isLoading;
            if (appModule === "".concat(reduxModules.tagsPage, "-currentTags"))
                state.isLoadingCurrentTags = isLoading;
            if (appModule === "".concat(reduxModules.tagsPage, "-creatingTag"))
                state.isLoadingCreatingTag = isLoading;
            if (appModule === "".concat(reduxModules.clientsPage, "-currentClient"))
                state.isLoadingCurrentClient = isLoading;
            if (appModule === "".concat(reduxModules.clientsPage, "-currentDialogHistory"))
                state.isLoadingCurrentDialogHistory = isLoading;
            if (appModule === "".concat(reduxModules.clientsPage, "-createClientBrandSettings"))
                state.isLoadingCreateClientBrandSettings = isLoading;
            if (appModule === "".concat(reduxModules.clientsPage, "-createClient"))
                state.isLoadingCreateClient = isLoading;
            if (appModule === "".concat(reduxModules.clientsPage, "-importClients"))
                state.isLoadingImportClients = isLoading;
            if (appModule === "".concat(reduxModules.clientsPage, "-importPhones"))
                state.isLoadingImportPhones = isLoading;
            if (appModule === "".concat(reduxModules.clientsPage, "-selectClients"))
                state.isLoadingSelectClientsBulkMessaging = isLoading;
            if (appModule === "".concat(reduxModules.brandsPage, "-createBrand"))
                state.isLoadingCreateBrand = isLoading;
            if (appModule === "".concat(reduxModules.brandsPage, "-currentBrand"))
                state.isLoadingCurrentBrand = isLoading;
            if (appModule === "".concat(reduxModules.brandsPage, "-createInbox"))
                state.isLoadingCreateInbox = isLoading;
            if (appModule === "".concat(reduxModules.resetPasswordPage, "-sendChange"))
                state.isChangePasswordLoading = isLoading;
            if (appModule === "".concat(reduxModules.channelsPage, "-createChannel"))
                state.isLoadingCreateChannel = isLoading;
            if (appModule === "".concat(reduxModules.channelsPage, "-currentChannel"))
                state.isLoadingCurrentChannel = isLoading;
            if (appModule === "".concat(reduxModules.channelsPage, "-changeMessengerChannel"))
                state.isLoadingChangeMessengerChannel = isLoading;
            if (appModule === "".concat(reduxModules.channelsPage, "-layoutWebChat"))
                state.isLoadingLayoutWebChat = isLoading;
            if (appModule === "".concat(reduxModules.channelsPage, "-pipeDrive"))
                state.isLoadingPipeDrive = isLoading;
        });
    },
});
export var isLoadingReducer = isLoadingSlice.reducer;
