var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, FONT, scrollStyles } from '@src/app/styled';
var DEFAULT_FONT_SIZE = FONT.DEFAULT_FONT_SIZE;
var VERY_DARK_BLUE = COLORS.VERY_DARK_BLUE, MAIN_DARK = COLORS.MAIN_DARK;
export var StyledPopoverWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    padding: 10px;\n  }\n"], ["\n  && {\n    padding: 10px;\n  }\n"])));
export var StyledTitlePopover = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    user-select: none;\n    color: ", ";\n    font-size: ", ";\n    font-weight: bold;\n    img {\n      cursor: pointer;\n      margin-left: 5px;\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    user-select: none;\n    color: ", ";\n    font-size: ", ";\n    font-weight: bold;\n    img {\n      cursor: pointer;\n      margin-left: 5px;\n    }\n  }\n"])), MAIN_DARK, DEFAULT_FONT_SIZE);
export var StyledContentPopover = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    font-size: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n    margin-top: ", ";\n    line-height: 2.4;\n    max-height: 200px;\n    overflow: auto;\n    ", "\n  }\n"], ["\n  && {\n    color: ", ";\n    font-size: ", ";\n    word-break: break-word;\n    white-space: pre-line;\n    margin-top: ", ";\n    line-height: 2.4;\n    max-height: 200px;\n    overflow: auto;\n    ", "\n  }\n"])), VERY_DARK_BLUE, DEFAULT_FONT_SIZE, function (_a) {
    var isHeader = _a.isHeader;
    return (isHeader ? '10px' : '0px');
}, scrollStyles);
var templateObject_1, templateObject_2, templateObject_3;
