import { connect } from 'react-redux';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import ColorPicker from './CustomColorPicker';
var mapStateToProps = function (state) { return ({
    modalInfo: modalWindowsStore.getModalInfo(state, modalTypes.CHANGE_COLOR_PICKER),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CHANGE_COLOR_PICKER, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker);
