var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Messages } from '@public/locales/messages';
import { selectMessenger } from '@src/global-interfaces';
import Select from '@src/components/Select';
import Button from '@src/components/Button';
import { transformDictionary } from '@src/utils/transformDictionary';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledStepWrapper } from '@src/modules/Pages/Clients/CreateClient/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var StepOne = function (props) {
    var currentStep = props.currentStep, selectedBrand = props.selectedBrand, setCurrentStep = props.setCurrentStep, brandDictionary = props.brandDictionary, setSelectedBrand = props.setSelectedBrand, selectedMessengers = props.selectedMessengers, setSelectedMessengers = props.setSelectedMessengers;
    var _a = __read(useState(true), 2), isSelectedBrand = _a[0], setIsSelectedBrand = _a[1];
    var _b = __read(useState(true), 2), isSelectedMessengers = _b[0], setIsSelectedMessengers = _b[1];
    var clickNextStep = function () {
        if (!selectedBrand.value)
            setIsSelectedBrand(false);
        if (!selectedMessengers.value)
            setIsSelectedMessengers(false);
        if (selectedBrand.value)
            setIsSelectedBrand(true);
        if (selectedMessengers.value)
            setIsSelectedMessengers(true);
        if (!selectedBrand.value || !selectedMessengers.value)
            return;
        setCurrentStep(currentStep + 1);
    };
    var selectBrand = function (el) {
        setSelectedBrand(el);
        setIsSelectedBrand(true);
    };
    var selectMessengers = function (el) {
        setSelectedMessengers(el);
        setIsSelectedMessengers(true);
    };
    return (React.createElement(StyledModalContentWrapper, null,
        React.createElement(StyledModalContentFieldsWrapper, null,
            React.createElement(StyledStepWrapper, { isPadding: isSelectedBrand || isSelectedMessengers },
                React.createElement(Select, { label: Messages.Labels.SelectBrand, initialState: !selectedBrand.value ? [] : [selectedBrand], placeholder: Messages.Selects.SelectBrand, items: transformDictionary(brandDictionary, 'text', 'value'), stateCB: function (el) { return selectBrand(el); }, error: !isSelectedBrand, isMultiply: false }),
                React.createElement(Select, { label: Messages.Labels.SelectMessengers, initialState: !selectedMessengers.value ? [] : [selectedMessengers], placeholder: Messages.Selects.SelectMessengers, items: selectMessenger, error: !isSelectedMessengers, stateCB: function (el) { return selectMessengers(el); }, isMultiply: false }))),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.next, onClick: clickNextStep })))));
};
export default StepOne;
