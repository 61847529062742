import React from 'react';
import { icons } from '@public/images';
import Icon from '@src/components/Icon';
import { StyledErrorMessage } from './styled';
export var ErrorMessage = function (_a) {
    var text = _a.text;
    return (React.createElement(StyledErrorMessage, null,
        React.createElement(Icon, { src: icons.errorIcon }),
        React.createElement("div", { className: "text" }, text)));
};
