import React from 'react';
import { StyledPage } from './StyledPage';
export var Page = function (_a) {
    var pageNumber = _a.pageNumber, offset = _a.offset, count = _a.count, overallCount = _a.overallCount, updatePagination = _a.updatePagination;
    var onPageHandler = function () {
        if (pageNumber !== offset) {
            var pagination = {
                offset: pageNumber,
                count: count,
                overallCount: overallCount,
            };
            updatePagination(pagination);
        }
    };
    return (React.createElement(StyledPage, { pageNumber: pageNumber, offset: offset, onClick: onPageHandler }, pageNumber));
};
