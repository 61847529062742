var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledUpdateInboxWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 10px 20px;\n  max-height: 50vh;\n  width: 35vw;\n  color: ", ";\n  ", "\n"], ["\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  padding: 10px 20px;\n  max-height: 50vh;\n  width: 35vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
var templateObject_1;
