var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Messages } from '@public/locales/messages';
import Loader from '@src/components/Loader';
import ModalWindow from '@src/components/ModalWindow';
import { Accordion } from '@src/components/Accordion/Accordion';
import UploadClients from '@src/modules/Pages/Clients/BulkMessaging/UploadClients/UploadClients';
import SelectClients from '@src/modules/Pages/Clients/BulkMessaging/SelectClients/SelectClients';
import { StyledLoaderWrapper, StyledBulkMessagingWrapper, } from '@src/modules/Pages/Clients/BulkMessaging/styled';
var BulkMessaging = function (props) {
    var toggleModal = props.toggleModal, selectClients = props.selectClients, brandDictionary = props.brandDictionary, showNotification = props.showNotification, isLoadingImportPhones = props.isLoadingImportPhones, resetNotificationState = props.resetNotificationState, importPhoneForBulkMessaging = props.importPhoneForBulkMessaging, isLoadingSelectClientsBulkMessaging = props.isLoadingSelectClientsBulkMessaging;
    var _a = __read(useState(false), 2), isOutsideClick = _a[0], setIsOutsideClick = _a[1];
    return (React.createElement(ModalWindow, { title: Messages.Buttons.bulkMessaging, outsideClick: !isOutsideClick, closeModalWindow: toggleModal },
        React.createElement(StyledBulkMessagingWrapper, null,
            React.createElement(Accordion, { title: Messages.BulkMessaging.uploadClients, isPadding: true, isBoldTitle: true, secondaryTitle: Messages.BulkMessaging.sendingMessagesToNewClients, isBorderBottom: true }, isLoadingImportPhones ? (React.createElement(StyledLoaderWrapper, null,
                React.createElement(Loader, null))) : (React.createElement(UploadClients, { showNotification: showNotification, resetNotificationState: resetNotificationState, importPhoneForBulkMessaging: importPhoneForBulkMessaging }))),
            React.createElement(Accordion, { title: Messages.BulkMessaging.selectClients, isPadding: true, isBoldTitle: true, secondaryTitle: Messages.BulkMessaging.sendingMessagesToExistingClients, isBorderBottom: true }, isLoadingSelectClientsBulkMessaging ? (React.createElement(StyledLoaderWrapper, null,
                React.createElement(Loader, null))) : (React.createElement(SelectClients, { selectClients: selectClients, isOutsideClick: isOutsideClick, setIsOutsideClick: setIsOutsideClick, brandDictionary: brandDictionary, showNotification: showNotification, resetNotificationState: resetNotificationState }))))));
};
export default BulkMessaging;
