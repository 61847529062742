var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledCreateClientWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 40vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  height: 40vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledStepWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    padding-top: 50px;\n    height: 100%;\n\n    & div:nth-of-type(3) {\n      padding-top: ", ";\n    }\n  }\n"], ["\n  && {\n    padding-top: 50px;\n    height: 100%;\n\n    & div:nth-of-type(3) {\n      padding-top: ", ";\n    }\n  }\n"])), function (_a) {
    var isPadding = _a.isPadding;
    return (isPadding ? '8px' : '0px');
});
export var StyledCreateClientInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"], ["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
