var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var WHITE = COLORS.WHITE, DARK_GREY = COLORS.DARK_GREY, BLUE_VIVID = COLORS.BLUE_VIVID, LIGHT_GREY = COLORS.LIGHT_GREY, DODGER_BLUE = COLORS.DODGER_BLUE;
export var StyledListItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    padding: 7px 10px;\n    width: 100%;\n    word-wrap: break-word;\n    display: flex;\n    justify-content: space-between;\n    font-size: 11px;\n    margin-top: 7px;\n    color: ", ";\n    border-radius: 16px;\n    background: ", ";\n\n    :hover {\n      background: ", ";\n    }\n  }\n"], ["\n  && {\n    padding: 7px 10px;\n    width: 100%;\n    word-wrap: break-word;\n    display: flex;\n    justify-content: space-between;\n    font-size: 11px;\n    margin-top: 7px;\n    color: ", ";\n    border-radius: 16px;\n    background: ", ";\n\n    :hover {\n      background: ", ";\n    }\n  }\n"])), function (props) { return (props.isSelected ? WHITE : DARK_GREY); }, function (props) { return (props.isSelected ? DODGER_BLUE : WHITE); }, function (props) { return (props.isSelected ? BLUE_VIVID : LIGHT_GREY); });
export var StyledListItemText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    cursor: pointer;\n  }\n"], ["\n  && {\n    cursor: pointer;\n  }\n"])));
var templateObject_1, templateObject_2;
