import { connect } from 'react-redux';
import { appStore } from '@src/redux/app/selector';
import { authStore } from '@src/modules/Auth/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import BrandsPage from '@src/modules/Pages/Brands/Brands';
var mapStateToProps = function (state) { return ({
    roles: authStore.roles(state),
    currentPage: appStore.currentPage(state),
    isModalOpenCreateBrand: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_BRAND),
    isModalOpenUpdateBrand: modalWindowsStore.getIsModalOpen(state, modalTypes.SETTINGS_BRAND),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModalCreateBrand: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_BRAND, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(BrandsPage);
