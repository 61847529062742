import React from 'react';
import { Messages } from '@public/locales/messages';
import ErrorValidMessage from '@src/modules/Pages/Channels/ChannelUpdate/components/WebChatWidows/Auth/components/ErrorValidMessage/ErrorValidMessage';
import AuthInput from '../components/AuthInput/AuthInput';
import Buttons from '../components/Buttons';
import StepsContent from '../components/StepsContent';
var UserName = function (_a) {
    var lang = _a.lang, stepsLength = _a.stepsLength, isPrivacyPolicy = _a.isPrivacyPolicy, PP = _a.PP, current = _a.current, auth = _a.auth, setCurrent = _a.setCurrent, privacyPolicy = _a.privacyPolicy;
    var inputColor = auth.inputColor, inputBackground = auth.inputBackground, signUpColor = auth.signUpColor, signUpBackground = auth.signUpBackground, labelColor = auth.labelColor;
    var onSubmit = function () {
        if (current + 1 === stepsLength) {
            return;
        }
        setCurrent(current + 1);
    };
    var prev = function () {
        setCurrent(current - 1);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepsContent, { lang: lang, className: "steps-content" },
            React.createElement(ErrorValidMessage, { text: Messages.Labels.UserName, lang: lang, style: { color: labelColor || '#434343' } }),
            React.createElement(AuthInput, { maxLength: 40, placeholder: Messages.Controls.WriteYourUserName, style: { color: inputColor || 'rgba(0, 0, 0, 0.65)', backgroundColor: inputBackground || '#fff' } })),
        PP,
        React.createElement("div", { className: "steps-btn" },
            React.createElement(Buttons, { lang: lang, prev: prev, current: current, onSubmit: onSubmit, signUpColor: signUpColor, stepsLength: stepsLength, privacyPolicy: privacyPolicy, isPrivacyPolicy: isPrivacyPolicy, signUpBackground: signUpBackground }))));
};
export default UserName;
