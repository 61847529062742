var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Messages } from '@public/locales/messages';
var formatChannelNames = function (channel) { return (channel.length ? channel.map(function (elem) { return elem.name; }) : ['no channels']); };
export var mapBrandsData = function (brands) { return brands.map(function (brand, index) { return (__assign(__assign({}, brands), { index: ++index, id: brand.id || Messages.Controls.NoData, status: brand.status || Messages.Controls.NoData, name: brand.name || Messages.Controls.NoData, channels: formatChannelNames(brand.identifyChannels) || Messages.Controls.NoData, createdAt: brand.createdAt || Messages.Controls.NoData, updatedAt: brand.updatedAt || Messages.Controls.NoData, dialogsCount: brand.dialogsCount.toString() || Messages.Controls.NoData, clientUniqueness: brand.clientUniqueness || Messages.Controls.NoData })); }); };
