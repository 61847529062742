import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var createGroup = function (group, channelId) { return wretch("".concat(apiConfig.baseUrl, "/Group?channelId=").concat(channelId))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(group)
    .json(); };
var getGroupById = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Group?id=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var getAgentsByGroupId = function (identificationRole, groupId) { return wretch("".concat(apiConfig.baseUrl, "/Agent/getByGroup?").concat(identificationRole, "&groupId=").concat(groupId, "&count=").concat(2147483647))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var getAssignedAndUnassignedAgentsToGroup = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Agent/agents/byGroupId?groupId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var deleteAgentsFromGroup = function (groupId, agentsIds) { return wretch("".concat(apiConfig.baseUrl, "/Group/groups/agents?groupId=").concat(groupId))
    .auth("Bearer ".concat(getAuthCookie()))
    .json(agentsIds)
    .delete()
    .text(); };
var addAgentsToGroup = function (groupId, agentsIds) { return wretch("".concat(apiConfig.baseUrl, "/Group/groups/agents?groupId=").concat(groupId))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(agentsIds)
    .text(); };
var updateGroup = function (currentGroup) { return wretch("".concat(apiConfig.baseUrl, "/Group"))
    .auth("Bearer ".concat(getAuthCookie()))
    .json(currentGroup)
    .put()
    .json(); };
var getAssignedAndUnassignedTagsToGroup = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Tag/tags/group/identifyname?groupId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var addTagsToGroup = function (groupId, tagsToAdd) { return wretch("".concat(apiConfig.baseUrl, "/Group/groups/tags?groupId=").concat(groupId))
    .auth("Bearer ".concat(getAuthCookie()))
    .post(tagsToAdd); };
var deleteTagsFromGroup = function (groupId, tagsToAdd) { return wretch("".concat(apiConfig.baseUrl, "/Group/groups/tags?groupId=").concat(groupId))
    .auth("Bearer ".concat(getAuthCookie()))
    .json(tagsToAdd)
    .delete(); };
var getAssignedAndUnassignedChannelsToGroup = function (id) { return wretch("".concat(apiConfig.baseUrl, "/Channel/channels/byGroupId?groupId=").concat(id))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json(); };
var addGroupsToChannel = function (channelId, groupId) { return wretch("".concat(apiConfig.baseUrl, "/Channel/groups?channelId=").concat(channelId))
    .auth("Bearer ".concat(getAuthCookie()))
    .post([groupId])
    .json(); };
var deleteChannelsFromGroup = function (channelId, groupId) { return wretch("".concat(apiConfig.baseUrl, "/Channel/groups?channelId=").concat(channelId))
    .auth("Bearer ".concat(getAuthCookie()))
    .json([groupId])
    .delete()
    .json(); };
var createCategory = function (name, groupId) { return wretch("".concat(apiConfig.baseUrl, "/PhrasesCategory/category"))
    .auth("Bearer ".concat(getAuthCookie()))
    .post({ name: name, groupId: groupId })
    .json(); };
var updateCategory = function (name, categoryId) { return wretch("".concat(apiConfig.baseUrl, "/PhrasesCategory/category"))
    .auth("Bearer ".concat(getAuthCookie()))
    .json({ name: name, id: categoryId })
    .patch()
    .json(); };
var deleteCategory = function (categoryId) { return wretch("".concat(apiConfig.baseUrl, "/PhrasesCategory/category?id=").concat(categoryId))
    .auth("Bearer ".concat(getAuthCookie()))
    .delete()
    .json(); };
var deleteAllCategories = function (groupId) { return wretch("".concat(apiConfig.baseUrl, "/PhrasesCategory/categories?groupId=").concat(groupId))
    .auth("Bearer ".concat(getAuthCookie()))
    .delete()
    .json(); };
var createPhrase = function (text, categoryId) { return wretch("".concat(apiConfig.baseUrl, "/PhrasesCategory/phrase"))
    .auth("Bearer ".concat(getAuthCookie()))
    .post({ text: text, categoryId: categoryId })
    .json(); };
var updatePhrase = function (id, text, categoryId) { return wretch("".concat(apiConfig.baseUrl, "/PhrasesCategory/phrase"))
    .auth("Bearer ".concat(getAuthCookie()))
    .json({ id: id, text: text, categoryId: categoryId })
    .patch()
    .json(); };
var deletePhrase = function (phraseId, categoryId) { return wretch("".concat(apiConfig.baseUrl, "/PhrasesCategory/phrase?id=").concat(phraseId, "&CategoryId=").concat(categoryId))
    .auth("Bearer ".concat(getAuthCookie()))
    .delete()
    .json(); };
export var groupsApi = {
    createGroup: createGroup,
    updateGroup: updateGroup,
    deletePhrase: deletePhrase,
    createPhrase: createPhrase,
    getGroupById: getGroupById,
    updatePhrase: updatePhrase,
    createCategory: createCategory,
    deleteCategory: deleteCategory,
    updateCategory: updateCategory,
    addTagsToGroup: addTagsToGroup,
    addAgentsToGroup: addAgentsToGroup,
    addGroupsToChannel: addGroupsToChannel,
    getAgentsByGroupId: getAgentsByGroupId,
    deleteAllCategories: deleteAllCategories,
    deleteTagsFromGroup: deleteTagsFromGroup,
    deleteAgentsFromGroup: deleteAgentsFromGroup,
    deleteChannelsFromGroup: deleteChannelsFromGroup,
    getAssignedAndUnassignedTagsToGroup: getAssignedAndUnassignedTagsToGroup,
    getAssignedAndUnassignedAgentsToGroup: getAssignedAndUnassignedAgentsToGroup,
    getAssignedAndUnassignedChannelsToGroup: getAssignedAndUnassignedChannelsToGroup,
};
