var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { AccessLevelGroups, notificationTypes } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Select from '@src/components/Select';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import ModalWindow from '@src/components/ModalWindow';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { transformDictionary } from '@src/utils/transformDictionary';
import { composeValidators, isRequired, lengthCreator } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledCreateGroupInput, StyledCreateGroupSelect, StyledCreateGroupsModalWrapper, StyledCreateGroupsModalContent, } from '@src/modules/Pages/Groups/GroupsCreateModal/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var GroupsCreateModal = function (props) {
    var toggleModal = props.toggleModal, tagDictionary = props.tagDictionary, showNotification = props.showNotification, channelDictionary = props.channelDictionary, createAgentGroups = props.createAgentGroups, isLoadingCreateGroups = props.isLoadingCreateGroups, resetNotificationState = props.resetNotificationState;
    var minNameLength = lengthCreator.minNameLength, maxNameLength = lengthCreator.maxNameLength, minDescriptionGroup = lengthCreator.minDescriptionGroup, maxDescriptionGroup = lengthCreator.maxDescriptionGroup;
    var _a = __read(useState(null), 2), channel = _a[0], setChannel = _a[1];
    var _b = __read(useState(null), 2), accessLevel = _b[0], setAccessLevel = _b[1];
    var _c = __read(useState(null), 2), whiteTagsList = _c[0], setWhiteTagsList = _c[1];
    var _d = __read(useState(true), 2), isChannel = _d[0], setIsChannel = _d[1];
    var _e = __read(useState(true), 2), isAccessLevel = _e[0], setIsAccessLevel = _e[1];
    var _f = __read(useState(true), 2), isWhiteTagsList = _f[0], setIsWhiteTagsList = _f[1];
    var groupsInput = InputWithStyles(Input, StyledCreateGroupInput);
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChangeChannel = useCallback(function (data) {
        setChannel(data);
        setIsChannel(true);
    }, [channel]);
    var onChangeAccessLevel = useCallback(function (data) {
        setAccessLevel(data);
        setIsAccessLevel(true);
    }, [accessLevel]);
    var onChangeWhiteTagsList = useCallback(function (data) {
        if ((data === null || data === void 0 ? void 0 : data.length) === 0) {
            setWhiteTagsList(null);
            setIsWhiteTagsList(false);
        }
        else {
            setIsWhiteTagsList(true);
            setWhiteTagsList(data);
        }
    }, [whiteTagsList]);
    var onSubmit = function (data) {
        if (!checkIsSelectedValue())
            return;
        createAgentGroups({
            groupName: data === null || data === void 0 ? void 0 : data.groupName,
            description: data === null || data === void 0 ? void 0 : data.description,
            channel: channel === null || channel === void 0 ? void 0 : channel.value,
            accessLevel: accessLevel === null || accessLevel === void 0 ? void 0 : accessLevel.value,
            whiteTagsList: whiteTagsList === null || whiteTagsList === void 0 ? void 0 : whiteTagsList.map(function (el) { return el.value; }),
        });
    };
    var checkIsSelectedValue = function () {
        (channel === null) ? setIsChannel(false) : setIsChannel(true);
        (accessLevel === null) ? setIsAccessLevel(false) : setIsAccessLevel(true);
        (whiteTagsList === null) ? setIsWhiteTagsList(false) : setIsWhiteTagsList(true);
        if (channel === null || accessLevel === null || whiteTagsList === null) {
            showWarning();
            return false;
        }
        return true;
    };
    return (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: Messages.Labels.CreateGroups },
        React.createElement(StyledCreateGroupsModalWrapper, { isLoadingCreateGroups: isLoadingCreateGroups }, isLoadingCreateGroups ? React.createElement(Loader, null)
            : (React.createElement(StyledCreateGroupsModalContent, null,
                React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                        var handleSubmit = _a.handleSubmit;
                        return (React.createElement("form", { onSubmit: handleSubmit },
                            React.createElement(StyledModalContentWrapper, null,
                                React.createElement(StyledModalContentFieldsWrapper, null,
                                    React.createElement(Field, { name: "groupName", label: Messages.Labels.GroupName, showWarning: showWarning, validate: composeValidators(isRequired, minNameLength, maxNameLength), placeholder: Messages.Controls.GroupName, component: groupsInput }),
                                    React.createElement(Field, { name: "description", showWarning: showWarning, label: Messages.Labels.Description, validate: composeValidators(isRequired, minDescriptionGroup, maxDescriptionGroup), placeholder: Messages.Controls.Description, component: groupsInput }),
                                    React.createElement(StyledCreateGroupSelect, { padding: isChannel },
                                        React.createElement(Select, { items: transformDictionary(channelDictionary, 'text', 'value'), label: Messages.Labels.Channel, error: !isChannel, isMultiply: false, placeholder: Messages.Selects.SelectChannel, stateCB: function (el) { return onChangeChannel(el); } })),
                                    React.createElement(StyledCreateGroupSelect, { padding: isAccessLevel },
                                        React.createElement(Select, { items: AccessLevelGroups, label: Messages.Labels.AccessLevel, error: !isAccessLevel, isMultiply: false, placeholder: Messages.Selects.SelectAccessLevel, stateCB: function (el) { return onChangeAccessLevel(el); } })),
                                    React.createElement(StyledCreateGroupSelect, { padding: isWhiteTagsList },
                                        React.createElement(Select, { label: Messages.Labels.WhiteTagsList, error: !isWhiteTagsList, placeholder: Messages.Selects.SelectWhiteTagsList, items: transformDictionary(tagDictionary, 'text', 'value'), stateCB: function (el) { return onChangeWhiteTagsList(el); } }))),
                                React.createElement(StyledModalFooterOneBtnWrapper, null,
                                    React.createElement(StyledSaveBtn, null,
                                        React.createElement(Button, { type: TButtonTypes.submit, title: Messages.Buttons.save, onClick: checkIsSelectedValue }))))));
                    } }))))));
};
export default GroupsCreateModal;
