import { connect } from 'react-redux';
import { appStore } from '@src/redux/app/selector';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import ClientsPage from '@src/modules/Pages/Clients/Clients';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    isModalOpen: modalWindowsStore.getIsModalOpen(state, modalTypes.CLIENT_INFO_MODAL),
    isCreteClientOpen: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_CLIENT),
    isCreteImportClient: modalWindowsStore.getIsModalOpen(state, modalTypes.IMPORT_CLIENTS),
    isModalOpenBulkMessaging: modalWindowsStore.getIsModalOpen(state, modalTypes.BULK_MESSAGING),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModalCreateClient: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_CLIENT, null)); },
    toggleModalImportClient: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.IMPORT_CLIENTS, null)); },
    toggleModalBulkMessaging: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.BULK_MESSAGING, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
