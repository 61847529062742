import { connect } from 'react-redux';
import { appStore } from '@src/redux/app/selector';
import { toolBarStore } from '@src/modules/ToolBar/redux/selector';
import { toolBarActions } from '@src/modules/ToolBar/redux/reducer';
import SearchFilter from './SearchFilter';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    selectedAllFilters: toolBarStore.getSelectedFilter(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    deleteValueSelectedFilter: function (data) { return dispatch(toolBarActions.deleteValueSelectedFilter(data)); },
    saveValueSelectedFilter: function (data) { return dispatch(toolBarActions.saveValueSelectedFilter(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
