import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var BrandPageTypes = {
    CREATE_BRAND: "".concat(reduxModules.brandsPage, "-createBrand/createBrand"),
    SHOW_CURRENT_BRAND: "".concat(reduxModules.brandsPage, "-currentBrand/showCurrentBrand"),
    UPDATE_CURRENT_BRAND: "".concat(reduxModules.brandsPage, "-currentBrand/updateCurrentBrand"),
    CREATE_MAIL_INBOX: "".concat(reduxModules.brandsPage, "-createInbox/createMailInbox"),
    CREATE_TELEGRAM_INBOX: "".concat(reduxModules.brandsPage, "-createInbox/createTelegramInbox"),
    CREATE_SMS_INBOX: "".concat(reduxModules.brandsPage, "-createInbox/createSmsInbox"),
    DELETE_INBOX: "".concat(reduxModules.brandsPage, "-currentBrand/deleteInbox"),
    UPDATE_INBOX: "".concat(reduxModules.brandsPage, "-createInbox/updateInbox"),
    UPLOAD_MAIL_AUTH: "".concat(reduxModules.brandsPage, "-createInbox/uploadMailAuth"),
    AUTH_MAIL: "".concat(reduxModules.brandsPage, "-createInbox/authMail"),
    SET_DOMAIN_SETTINGS: "".concat(reduxModules.brandsPage, "-createInbox/setDomainSettings"),
    GET_SMS_ACCOUNTS_SETTINGS: "".concat(reduxModules.brandsPage, "-createInbox/getSmsAccountsSettings"),
    SET_LIST_COUNTRY_SMS: "".concat(reduxModules.brandsPage, "-createInbox/setListCountrySms"),
    UPDATE_SMS_ACCOUNTS: "".concat(reduxModules.brandsPage, "-createInbox/updateSmsAccounts"),
};
var initialState = {
    currentBrand: null,
    currentBrandMessengers: [],
    smsAccountsSettings: null,
};
var BrandsSlice = createSlice({
    name: reduxModules.brandsPage,
    initialState: initialState,
    reducers: {
        setCurrentBrand: function (state, action) {
            state.currentBrand = action.payload;
        },
        setCurrentBrandMessengers: function (state, action) {
            state.currentBrandMessengers = action.payload;
        },
        setSmsAccountsSettings: function (state, action) {
            state.smsAccountsSettings = action.payload;
        },
    },
});
export var brandsActions = {
    authMail: createAction(BrandPageTypes.AUTH_MAIL),
    createBrand: createAction(BrandPageTypes.CREATE_BRAND),
    deleteInbox: createAction(BrandPageTypes.DELETE_INBOX),
    updateInbox: createAction(BrandPageTypes.UPDATE_INBOX),
    createSmsInbox: createAction(BrandPageTypes.CREATE_SMS_INBOX),
    uploadMailAuth: createAction(BrandPageTypes.UPLOAD_MAIL_AUTH),
    createMailInbox: createAction(BrandPageTypes.CREATE_MAIL_INBOX),
    showCurrentBrand: createAction(BrandPageTypes.SHOW_CURRENT_BRAND),
    setDomainSettings: createAction(BrandPageTypes.SET_DOMAIN_SETTINGS),
    updateSmsAccounts: createAction(BrandPageTypes.UPDATE_SMS_ACCOUNTS),
    setListCountrySms: createAction(BrandPageTypes.SET_LIST_COUNTRY_SMS),
    updateCurrentBrand: createAction(BrandPageTypes.UPDATE_CURRENT_BRAND),
    createTelegramInbox: createAction(BrandPageTypes.CREATE_TELEGRAM_INBOX),
    getSmsAccountsSettings: createAction(BrandPageTypes.GET_SMS_ACCOUNTS_SETTINGS),
    setCurrentBrand: BrandsSlice.actions.setCurrentBrand,
    setCurrentBrandMessengers: BrandsSlice.actions.setCurrentBrandMessengers,
    setSmsAccountsSettings: BrandsSlice.actions.setSmsAccountsSettings,
};
export var brandsReducer = BrandsSlice.reducer;
