import { connect } from 'react-redux';
import ReportsPage from '@src/modules/Pages/Reports/Reports';
import { appStore } from '@src/redux/app/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    isModalOpen: modalWindowsStore.getIsModalOpen(state, modalTypes.SHOW_AGENT_REPORT),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.SHOW_AGENT_REPORT, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
