import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { notificationActions } from '@src/redux/notification/reducer';
import CreateTelegramInbox from '@src/modules/Pages/Brands/BrandSettings/CreateTelegramInbox/CreateTelegramInbox';
var mapStateToProps = function (state) { return ({
    isLoadingCreateInbox: loadingStore.getIsLoadingCreateInbox(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    createTelegramInbox: function (telegram) { return dispatch(brandsActions.createTelegramInbox(telegram)); },
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_TELEGRAM_INBOX, null)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(CreateTelegramInbox);
