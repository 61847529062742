var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { delay, put } from 'redux-saga/effects';
import { notificationTypes } from '@src/global-interfaces';
import { system } from '@src/constants/objects';
import { notificationActions } from './reducer';
var DEFAULT_NOTIFICATION_TIME = system.DEFAULT_NOTIFICATION_TIME;
function showNotification(msg, delayMS, type) {
    if (delayMS === void 0) { delayMS = DEFAULT_NOTIFICATION_TIME; }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, put(notificationActions.showNotification({ msg: msg, type: type }))];
            case 1:
                _a.sent();
                return [4, delay(delayMS)];
            case 2:
                _a.sent();
                return [4, put(notificationActions.resetNotificationState())];
            case 3:
                _a.sent();
                return [2];
        }
    });
}
export function errorNotification(msg, delayMS) {
    if (delayMS === void 0) { delayMS = DEFAULT_NOTIFICATION_TIME; }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, showNotification(msg, delayMS, notificationTypes.error)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
export function successNotification(msg, delayMS) {
    if (delayMS === void 0) { delayMS = DEFAULT_NOTIFICATION_TIME; }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, showNotification(msg, delayMS, notificationTypes.success)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
export function warningNotification(msg, delayMS) {
    if (delayMS === void 0) { delayMS = DEFAULT_NOTIFICATION_TIME; }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, showNotification(msg, delayMS, notificationTypes.warning)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
export function infoNotification(msg, delayMS) {
    if (delayMS === void 0) { delayMS = DEFAULT_NOTIFICATION_TIME; }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, showNotification(msg, delayMS, notificationTypes.info)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
