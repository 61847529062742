var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { StyledModalContentFieldsWrapper, StyledModalContentWrapper, StyledModalFooterTwoBtnWrapper, } from '@src/app/styled';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledAddNewBtn, StyledPreviousBtn, StyledSaveBtn } from '@src/components/Button/styled';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import Select from '@src/components/Select';
import { Tab, Tabs } from '@src/components/Tabs/Tabs';
import { enabledLanguagesChannel, notificationTypes } from '@src/global-interfaces';
import { StyledChannelInput, StyledFastPhraseWrapper, StyledStepWrapper, } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { notificationActions } from '@src/redux/notification/reducer';
import { processingPhraseAndGreetings } from '@src/utils/processingPhraseAndGreetings';
var StepThree = function (props) {
    var currentStep = props.currentStep, phraseNumbers = props.phraseNumbers, setCurrentStep = props.setCurrentStep, onCreateChannel = props.onCreateChannel, setPhraseNumbers = props.setPhraseNumbers, showNotification = props.showNotification, selectedLanguages = props.selectedLanguages, greetingsAndPhrase = props.greetingsAndPhrase, setSelectedLanguages = props.setSelectedLanguages, setGreetingsAndPhrase = props.setGreetingsAndPhrase, resetNotificationState = props.resetNotificationState;
    var _a = __read(useState(''), 2), currentTab = _a[0], setCurrentTab = _a[1];
    var _b = __read(useState(true), 2), isSelectedLanguages = _b[0], setIsSelectedLanguages = _b[1];
    var onChangeSetSelectedLanguages = function (el) {
        setSelectedLanguages(el);
        setIsSelectedLanguages(true);
    };
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    var addPhrase = function () {
        var _a;
        var _b, _c, _d, _e;
        if (((_b = phraseNumbers[currentTab]) === null || _b === void 0 ? void 0 : _b.length) >= 3)
            return;
        var number = ((_c = phraseNumbers[currentTab]) === null || _c === void 0 ? void 0 : _c.length)
            ? ((_d = phraseNumbers[currentTab]) === null || _d === void 0 ? void 0 : _d.length) + 1
            : +!!((_e = phraseNumbers[currentTab]) === null || _e === void 0 ? void 0 : _e.length) + 1;
        setPhraseNumbers(__assign(__assign({}, phraseNumbers), (_a = {}, _a[currentTab] = __spreadArray(__spreadArray([], __read((phraseNumbers[currentTab] || [])), false), [number], false), _a)));
    };
    var deletePhrase = function (phrase) {
        var _a, _b;
        var _c;
        var deletedPhrase = (_c = greetingsAndPhrase[currentTab]) === null || _c === void 0 ? void 0 : _c.phrases;
        if (deletedPhrase) {
            deletedPhrase.splice(phrase - 1, 1);
            setGreetingsAndPhrase(__assign(__assign({}, greetingsAndPhrase), (_a = {}, _a[currentTab] = __assign(__assign({}, greetingsAndPhrase[currentTab]), { phrases: deletedPhrase }), _a)));
        }
        setPhraseNumbers(__assign(__assign({}, phraseNumbers), (_b = {}, _b[currentTab] = phraseNumbers[currentTab].filter(function (el) { return el !== phrase; }), _b)));
    };
    var getInitValue = function () {
        var _a, _b, _c;
        var tempObj = {};
        var _loop_1 = function (props_1) {
            tempObj["greetings/".concat(props_1)] = (_a = greetingsAndPhrase[props_1]) === null || _a === void 0 ? void 0 : _a.greetings;
            (_c = (_b = greetingsAndPhrase[props_1]) === null || _b === void 0 ? void 0 : _b.phrases) === null || _c === void 0 ? void 0 : _c.forEach(function (el, index) {
                tempObj["fastPhrase\u2116".concat(index + 1, "/").concat(props_1)] = el;
            });
        };
        for (var props_1 in greetingsAndPhrase) {
            _loop_1(props_1);
        }
        return tempObj;
    };
    var savePhrase = function (phrase) {
        setGreetingsAndPhrase(processingPhraseAndGreetings(phrase));
        return processingPhraseAndGreetings(phrase);
    };
    var onSubmit = function (data) {
        var phrases = savePhrase(data);
        if (selectedLanguages.length <= 0) {
            setIsSelectedLanguages(false);
            showWarning();
            return;
        }
        onCreateChannel(phrases);
        setCurrentStep(currentStep + 1);
    };
    var ChannelInput = InputWithStyles(Input, StyledChannelInput);
    return (React.createElement(StyledStepWrapper, null,
        React.createElement(Select, { initialState: selectedLanguages, error: !isSelectedLanguages, label: Messages.Labels.SelectEnabledLanguages, placeholder: Messages.Selects.SelectEnabledLanguages, items: enabledLanguagesChannel, stateCB: function (el) { return onChangeSetSelectedLanguages(el); } }),
        React.createElement(StyledFastPhraseWrapper, null,
            React.createElement(Form, { onSubmit: onSubmit, initialValues: getInitValue(), render: function (_a) {
                    var handleSubmit = _a.handleSubmit, values = _a.values;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(StyledModalContentWrapper, null,
                            React.createElement(StyledModalContentFieldsWrapper, null, selectedLanguages.length > 0 && (React.createElement(Tabs, { isShowBottomLine: true, callback: onTabChangeHandler, currentTab: currentTab, callbackTwo: function () { return savePhrase(values); } }, selectedLanguages === null || selectedLanguages === void 0 ? void 0 : selectedLanguages.map(function (el, index) {
                                var _a;
                                return (React.createElement(Tab, { tabKey: el.value, title: el.text, key: index },
                                    React.createElement(Field, { name: "greetings/".concat(el.value), label: Messages.Labels.Greetings, showWarning: showWarning, placeholder: Messages.Controls.Greetings, component: ChannelInput }), (_a = phraseNumbers[currentTab]) === null || _a === void 0 ? void 0 :
                                    _a.map(function (phrase, index) { return (React.createElement(Field, { key: index, name: "fastPhrase\u2116".concat(index + 1, "/").concat(el.value), icon: icons.trash, iconOnclick: function () { return deletePhrase(phrase); }, label: "".concat(Messages.Labels.FastPhrase).concat(index + 1), showWarning: showWarning, placeholder: "".concat(Messages.Controls.FastPhrase).concat(index + 1), component: ChannelInput })); }),
                                    React.createElement(StyledAddNewBtn, null,
                                        React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addPhrase), onClick: addPhrase }))));
                            })))),
                            React.createElement(StyledModalFooterTwoBtnWrapper, null,
                                React.createElement(StyledPreviousBtn, null,
                                    React.createElement(Button, { title: Messages.Buttons.previous, onClick: function () {
                                            savePhrase(values);
                                            setCurrentStep(currentStep - 1);
                                        } })),
                                React.createElement(StyledSaveBtn, null,
                                    React.createElement(Button, { title: Messages.Buttons.createChannel, type: TButtonTypes.submit, onClick: function () { } }))))));
                } }))));
};
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(null, mapDispatchToProps)(StepThree);
