var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { system } from '@src/constants/objects';
var DEFAULT_PAGINATION_STATE = system.DEFAULT_PAGINATION_STATE, DEFAULT_SORTING_STATE = system.DEFAULT_SORTING_STATE;
export var createTableOptionsStore = function (entityKeys) {
    var tableOptionsStore = {};
    var tableOption = {
        filtering: {},
        pagination: __assign({}, DEFAULT_PAGINATION_STATE),
        sorting: __assign({}, DEFAULT_SORTING_STATE),
    };
    entityKeys.map(function (tableEntity) {
        var _a;
        return Object.assign(tableOptionsStore, (_a = {},
            _a[tableEntity] = tableOption,
            _a));
    });
    return tableOptionsStore;
};
export var calculateOffset = function (offset, count) { return (offset - 1) * count; };
