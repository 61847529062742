var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { ExpandButton } from '../expandButton/expandButton';
import { BottomContainer } from './bottomContainer';
import { TableRowInner, TableRowWrapper } from './styled';
export var TableRow = function (_a) {
    var rowData = _a.rowData, rowBackground = _a.rowBackground, rowTextAlign = _a.rowTextAlign;
    var _b = __read(useState(false), 2), expandDialog = _b[0], setExpandDialog = _b[1];
    var Dialog = rowData.dialogHistoryComponent;
    var rowItems = Object.entries(rowData)
        .filter(function (el) { return el[0] !== 'dialogHistoryComponent'; })
        .map(function (el, i) { return React.createElement("div", { key: i }, el[1]); });
    return (React.createElement(TableRowWrapper, null,
        React.createElement(TableRowInner, { rowBackground: rowBackground, rowTextAlign: rowTextAlign, rowItemsLength: rowItems.length },
            React.createElement(ExpandButton, { state: expandDialog, setExpandDialog: setExpandDialog }),
            rowItems),
        expandDialog && React.createElement(BottomContainer, { Child: Dialog })));
};
