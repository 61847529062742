var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var BLUE_VIVID = COLORS.BLUE_VIVID;
export var StyledPage = styled('span')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  margin: 0 3px;\n  color: ", ";\n  opacity: 0.8;\n  transition: 0.2s;\n  &:hover {\n    opacity: 1;\n  }\n"], ["\n  cursor: pointer;\n  margin: 0 3px;\n  color: ", ";\n  opacity: 0.8;\n  transition: 0.2s;\n  &:hover {\n    opacity: 1;\n  }\n"])), function (_a) {
    var offset = _a.offset, pageNumber = _a.pageNumber, theme = _a.theme;
    return (offset === pageNumber ? "".concat(BLUE_VIVID) : "".concat(theme.colors.text));
});
var templateObject_1;
