var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { zoomIn } from 'react-animations';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
export var ModalWindowWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    width: 100vw;\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.85);\n    position: fixed;\n    top: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n  }\n"], ["\n  && {\n    width: 100vw;\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.85);\n    position: fixed;\n    top: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n  }\n"])));
export var ModalWindowContentWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    animation: 0.5s ", " ease-in-out;\n  }\n"], ["\n  && {\n    animation: 0.5s ", " ease-in-out;\n  }\n"])), keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["", ""], ["", ""])), zoomIn));
export var ModalWindowContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    overflow: auto;\n    padding: 0 20px 20px;\n    border-radius: 12px;\n    background-color: ", ";\n  }\n"], ["\n  && {\n    overflow: auto;\n    padding: 0 20px 20px;\n    border-radius: 12px;\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
export var ModalWindowHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    display: flex;\n    padding: 20px 0 0 0;\n    user-select: none;\n    justify-content: end;\n    color: ", ";\n\n    img {\n      width: 20px;\n      height: 20px;\n      cursor: pointer;\n      filter: ", ";\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    padding: 20px 0 0 0;\n    user-select: none;\n    justify-content: end;\n    color: ", ";\n\n    img {\n      width: 20px;\n      height: 20px;\n      cursor: pointer;\n      filter: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
});
export var ModalWindowTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    text-align: center;\n    padding: 5px 0 20px 0;\n    line-height: 16px;\n    font-size: 14px;\n    font-weight: 500;\n    color: ", ";\n  }\n"], ["\n  && {\n    text-align: center;\n    padding: 5px 0 20px 0;\n    line-height: 16px;\n    font-size: 14px;\n    font-weight: 500;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.modalTitle;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
