var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
var StyledQuickPhrase = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 70%;\n  text-align: right;\n  margin-top: 5px;\n  margin-left: auto;\n  margin-right: 20px;\n  & .quick-phrase {\n    color: ", ";\n    background: ", ";\n    border: 1px solid ", ";\n    font-family: Roboto, sans-serif;\n    font-weight: 100;\n    font-size: 13px;\n    line-height: 14px;\n    border-radius: 16px;\n    padding: 10px 15px;\n    overflow-wrap: break-word;\n    white-space: pre-line;\n    user-select: none;\n    margin: 0 0 7px auto;\n    cursor: pointer;\n    transition: all 0.3s ease 0s;\n    max-width: 100%;\n    width: fit-content;\n    word-wrap: break-word;\n    word-break: break-word;\n    &:hover {\n      color: ", ";\n      background: ", ";\n      border: 1px solid ", ";\n    }\n  }\n"], ["\n  width: 70%;\n  text-align: right;\n  margin-top: 5px;\n  margin-left: auto;\n  margin-right: 20px;\n  & .quick-phrase {\n    color: ", ";\n    background: ", ";\n    border: 1px solid ", ";\n    font-family: Roboto, sans-serif;\n    font-weight: 100;\n    font-size: 13px;\n    line-height: 14px;\n    border-radius: 16px;\n    padding: 10px 15px;\n    overflow-wrap: break-word;\n    white-space: pre-line;\n    user-select: none;\n    margin: 0 0 7px auto;\n    cursor: pointer;\n    transition: all 0.3s ease 0s;\n    max-width: 100%;\n    width: fit-content;\n    word-wrap: break-word;\n    word-break: break-word;\n    &:hover {\n      color: ", ";\n      background: ", ";\n      border: 1px solid ", ";\n    }\n  }\n"])), function (_a) {
    var quickPhrasesColor = _a.quickPhrasesColor;
    return quickPhrasesColor || 'black';
}, function (_a) {
    var quickPhrasesBackground = _a.quickPhrasesBackground;
    return quickPhrasesBackground || 'black';
}, function (_a) {
    var quickPhrasesBorderColor = _a.quickPhrasesBorderColor;
    return quickPhrasesBorderColor || 'black';
}, function (_a) {
    var quickPhrasesColorHover = _a.quickPhrasesColorHover;
    return quickPhrasesColorHover || 'black';
}, function (_a) {
    var quickPhrasesBackgroundHover = _a.quickPhrasesBackgroundHover;
    return quickPhrasesBackgroundHover || 'black';
}, function (_a) {
    var quickPhrasesBorderColorHover = _a.quickPhrasesBorderColorHover;
    return quickPhrasesBorderColorHover || 'black';
});
export default StyledQuickPhrase;
var templateObject_1;
