import { createAction, createSlice } from '@reduxjs/toolkit';
import { IUserRole } from '@src/DTO/entities/Auth';
import { reduxModules } from '@src/global-interfaces';
import { AuthTypes, } from './types';
var initialState = {
    roles: [],
    identificationRole: IUserRole.newbie,
    contentReady: false,
};
var authSlice = createSlice({
    name: reduxModules.authPage,
    initialState: initialState,
    reducers: {
        setUserRoles: function (state, action) {
            state.roles = action.payload;
        },
        setIdentificationRole: function (state, action) {
            state.identificationRole = action.payload;
        },
        setContentReady: function (state, action) {
            state.contentReady = action.payload;
        },
    },
});
export var authActions = {
    logout: createAction(AuthTypes.LOGOUT),
    initApp: createAction(AuthTypes.INIT_APP),
    loginLocal: createAction(AuthTypes.LOGIN_LOCAL),
    loginSocial: createAction(AuthTypes.LOGIN_SOCIAL),
    registerLocal: createAction(AuthTypes.REGISTER),
    forgotPassword: createAction(AuthTypes.FORGOT_PASSWORD),
    setUserRoles: authSlice.actions.setUserRoles,
    setContentReady: authSlice.actions.setContentReady,
    setIdentificationRole: authSlice.actions.setIdentificationRole,
};
export var authReducer = authSlice.reducer;
