var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from 'react';
import { Messages } from '@public/locales/messages';
import Button from '@src/components/Button';
import { StyledApplyBtn, StyledResetBtn, StyledSetDateBtn } from '@src/components/Button/styled';
import Calendar from '@src/components/CustomDatePicker/Calendar';
import { StyledDatePickerWrapper } from '@src/components/CustomDatePicker/styled';
import { useCustomDatePickerHide } from '@src/components/CustomDatePicker/useCustomDatePickerHide';
import { notificationTypes } from '@src/global-interfaces';
import { getLastMoment, getLastMomentString } from '@src/utils/getLastMoment';
var CustomDatePicker = function (props) {
    var currentPage = props.currentPage, getValueSelectedFilter = props.getValueSelectedFilter, _a = props.selectedData, selectedData = _a === void 0 ? [] : _a, showNotification = props.showNotification, resetNotificationState = props.resetNotificationState, setShowSelectedFilter = props.setShowSelectedFilter;
    var ref = useRef(null);
    var _b = __read(useState({
        moment: selectedData[0] || [],
        string: selectedData[0] || [],
    }), 2), datePickerValue = _b[0], setDatePickerValue = _b[1];
    var handleOnCalendarChange = function (moment, _a) {
        var _b = __read(_a, 2), from = _b[0], to = _b[1];
        return setDatePickerValue({ moment: moment, string: [from, to] });
    };
    var resetDate = function () {
        setDatePickerValue({ moment: [], string: [] });
        getValueSelectedFilter({ currentPage: currentPage, SelectedFilterValue: { date: [[], []] } });
    };
    var OnClickCalendarBtn = function (title) {
        switch (title) {
            case 'Yesterday': {
                setDatePickerValue({
                    moment: getLastMoment(-1, 'days'),
                    string: getLastMomentString(-1, 'days'),
                });
                break;
            }
            case 'Today': {
                setDatePickerValue({
                    moment: getLastMoment(null, null),
                    string: getLastMomentString(null, null),
                });
                break;
            }
            default: {
                setDatePickerValue({
                    moment: getLastMoment(-7, 'days'),
                    string: getLastMomentString(-7, 'days'),
                });
            }
        }
    };
    var OnClickApplyBtn = function () {
        if (datePickerValue.moment.length === 0) {
            showNotification({ msg: 'Please select date', type: notificationTypes.warning });
            setTimeout(function () { return resetNotificationState(); }, 1000);
        }
        else {
            getValueSelectedFilter({
                currentPage: currentPage,
                SelectedFilterValue: { date: [datePickerValue.moment, datePickerValue.string] },
            });
        }
    };
    var datePickerBtnArr = [
        { title: 'Yesterday' },
        { title: 'Today' },
        { title: 'This week' },
    ];
    useCustomDatePickerHide({ setShowSelectedFilter: setShowSelectedFilter, ref: ref });
    return (React.createElement(StyledDatePickerWrapper, { ref: ref },
        React.createElement("div", { className: "date-picker-btn-wrapper" }, datePickerBtnArr.map(function (el, index) { return (React.createElement(StyledSetDateBtn, { key: index },
            React.createElement(Button, { title: el.title, onClick: function () { return OnClickCalendarBtn(el.title); } }))); })),
        React.createElement("div", { className: "calendar-wrapper" },
            React.createElement(Calendar, { handleOnCalendarChange: handleOnCalendarChange, momentDate: datePickerValue.moment })),
        React.createElement("div", { className: "date-picker-btn-apply" },
            React.createElement(StyledResetBtn, null,
                React.createElement(Button, { title: Messages.Buttons.reset, onClick: resetDate })),
            React.createElement(StyledApplyBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: OnClickApplyBtn })))));
};
export default CustomDatePicker;
