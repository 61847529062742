import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { clientsStore } from '@src/modules/Pages/Clients/redux/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import ClientInfo from '@src/modules/Pages/Clients/ClientInfo/ClientInfo';
var mapStateToProps = function (state) { return ({
    clientDialogs: clientsStore.getClientDialogs(state),
    tagDictionary: dictionariesStore.dictionaries(state).tagDictionary,
    agentDictionary: dictionariesStore.dictionaries(state).agentDictionary,
    channelDictionary: dictionariesStore.dictionaries(state).channelDictionary,
    isLoadingCurrentClient: loadingStore.getIsLoadingCurrentClient(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CLIENT_INFO_MODAL, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo);
