var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useState } from 'react';
import { Messages } from '@public/locales/messages';
import { transformArrForSelect } from '@src/utils/transformArrForSelect';
import Select from '@src/components/Select';
import Button from '@src/components/Button';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var AgentToGroups = function (props) {
    var availableGroupsCurrentAgent = props.availableGroupsCurrentAgent, addAndDeleteAgentGroups = props.addAndDeleteAgentGroups;
    var _a = __read(useState(transformArrForSelect(availableGroupsCurrentAgent === null || availableGroupsCurrentAgent === void 0 ? void 0 : availableGroupsCurrentAgent.assigned)), 2), assignedGroups = _a[0], setAssignedGroups = _a[1];
    var selectGroups = useCallback(function (groups) {
        setAssignedGroups(groups);
    }, [assignedGroups]);
    return (React.createElement(StyledModalContentWrapper, null,
        React.createElement(StyledModalContentFieldsWrapper, null,
            React.createElement(Select, { label: Messages.Labels.SelectGroups, placeholder: Messages.Selects.SelectGroups, items: __spreadArray(__spreadArray([], __read(transformArrForSelect(availableGroupsCurrentAgent === null || availableGroupsCurrentAgent === void 0 ? void 0 : availableGroupsCurrentAgent.assigned)), false), __read(transformArrForSelect(availableGroupsCurrentAgent === null || availableGroupsCurrentAgent === void 0 ? void 0 : availableGroupsCurrentAgent.unAssigned)), false), stateCB: selectGroups, initialState: assignedGroups })),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { return addAndDeleteAgentGroups(assignedGroups); } })))));
};
export default AgentToGroups;
