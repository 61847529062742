import { connect } from 'react-redux';
import { appStore } from '@src/redux/app/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import TagsPage from '@src/modules/Pages/Tags/Tags';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    isModalOpenCreateTags: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_TAGS_MODAL),
    isModalOpenUpdateTags: modalWindowsStore.getIsModalOpen(state, modalTypes.UPDATE_TAGS_MODAL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_TAGS_MODAL, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TagsPage);
