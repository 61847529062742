import { ITables } from '@src/global-interfaces';
import { COLORS } from '@src/app/styled';
export var useToolBar = function (_a) {
    var excelQueue = _a.excelQueue, currentPage = _a.currentPage, createExcelFile = _a.createExcelFile;
    var isFinished = function () {
        if (!excelQueue)
            return false;
        return Object
            .entries(excelQueue)
            .some(function (el) { return ((el[0] === ITables[currentPage]) && (el[1].url === null)); });
    };
    var handleExcelDownload = function () {
        if (!excelQueue) {
            createExcelFile();
            return;
        }
        if (!isFinished())
            createExcelFile();
    };
    var btnSaveExcelStyle = {
        color: '#FFFFFF',
        borderRadius: '4px',
        lineHeight: '26px',
        fontSize: '11px',
        background: isFinished() ? COLORS.RED : COLORS.DIRTY_GREEN,
        padding: '1px 12px',
    };
    return {
        btnSaveExcelStyle: btnSaveExcelStyle,
        handleExcelDownload: handleExcelDownload,
    };
};
