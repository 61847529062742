var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledAudioWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    padding: 10px 0 0;\n    background-color: ", ";\n    width: 100%;\n  }\n"], ["\n  && {\n    padding: 10px 0 0;\n    background-color: ", ";\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
export var StyledCreateAudioTime = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    color: ", ";\n  }\n"], ["\n  && {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledAudioController = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    width: 100%;\n\n    & .play_btn {\n      color: #4285f4;\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n    }\n\n    & .recorded_slider {\n      width: 100%;\n\n      .ant-slider-handle {\n        opacity: 0;\n      }\n\n      .ant-slider-track {\n        background: ", ";\n      }\n\n      .ant-slider-rail {\n        background: ", ";\n      }\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    width: 100%;\n\n    & .play_btn {\n      color: #4285f4;\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n    }\n\n    & .recorded_slider {\n      width: 100%;\n\n      .ant-slider-handle {\n        opacity: 0;\n      }\n\n      .ant-slider-track {\n        background: ", ";\n      }\n\n      .ant-slider-rail {\n        background: ", ";\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.audioSliderTrack;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.audioSliderRail;
});
export var StyledDownloadIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    filter: ", ";\n    img {\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n    }\n  }\n"], ["\n  && {\n    filter: ", ";\n    img {\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.blackInvert;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
