import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var resetPasswordPageTypes = {
    SEND_CHANGE_NEW_PASSWORD: "".concat(reduxModules.resetPasswordPage, "-sendChange/NewPassword"),
    SEND_CHECK_IS_VALID_TOKEN: "".concat(reduxModules.resetPasswordPage, "-sendCheckIsValidToken/NewPassword"),
};
var initialState = {
    isLoadingChangePassword: false,
    isValidToken: true,
    isLoadingCheckValidLoader: false,
    changePasswordResult: {
        isResult: false,
        isError: false,
    },
};
var resetPasswordPageSlice = createSlice({
    name: reduxModules.resetPasswordPage,
    initialState: initialState,
    reducers: {
        setChangePasswordResult: function (state, action) {
            state.changePasswordResult = action.payload;
        },
        setIsValidToken: function (state, action) {
            state.isValidToken = action.payload;
        },
    },
});
export var resetPasswordPageActions = {
    sendNewPassword: createAction(resetPasswordPageTypes.SEND_CHANGE_NEW_PASSWORD),
    sendCheckIsValidToken: createAction(resetPasswordPageTypes.SEND_CHECK_IS_VALID_TOKEN),
    setChangePasswordResult: resetPasswordPageSlice.actions.setChangePasswordResult,
    setIsValidToken: resetPasswordPageSlice.actions.setIsValidToken,
};
export var resetPasswordPageReducer = resetPasswordPageSlice.reducer;
