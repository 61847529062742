import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
export var TagsPageTypes = {
    CREATE_TAG: "".concat(reduxModules.tagsPage, "-creatingTag/createTag"),
    SHOW_TAGS_SETTINGS: "".concat(reduxModules.tagsPage, "-currentTags/showTagsSettings"),
    UPDATE_TAG_SETTINGS: "".concat(reduxModules.tagsPage, "-currentTags/updateTagSettings"),
};
var initialState = {
    currentTag: null,
    assignedAgent: null,
    unAssignedAgent: null,
};
var tagsSlice = createSlice({
    name: reduxModules.tagsPage,
    initialState: initialState,
    reducers: {
        setCurrentTag: function (state, action) {
            state.currentTag = action.payload;
        },
        setAssignedAgent: function (state, action) {
            state.assignedAgent = action.payload;
        },
        setUnAssignedAgent: function (state, action) {
            state.unAssignedAgent = action.payload;
        },
    },
});
export var tagsActions = {
    createTag: createAction(TagsPageTypes.CREATE_TAG),
    showTagsSettings: createAction(TagsPageTypes.SHOW_TAGS_SETTINGS),
    updateTagSettings: createAction(TagsPageTypes.UPDATE_TAG_SETTINGS),
    setCurrentTag: tagsSlice.actions.setCurrentTag,
    setAssignedAgent: tagsSlice.actions.setAssignedAgent,
    setUnAssignedAgent: tagsSlice.actions.setUnAssignedAgent,
};
export var tagsReducer = tagsSlice.reducer;
