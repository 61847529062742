var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
export var StyledCustomSliderWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    & .ant-slider-handle {\n      background: ", ";\n    }\n\n    & .ant-slider-step {\n      background: ", ";\n    }\n\n    & .ant-slider-mark-text {\n      color: ", ";\n    }\n  }\n"], ["\n  && {\n    & .ant-slider-handle {\n      background: ", ";\n    }\n\n    & .ant-slider-step {\n      background: ", ";\n    }\n\n    & .ant-slider-mark-text {\n      color: ", ";\n    }\n  }\n"])), COLORS.LIGHT_DARK_BLUE, COLORS.LIGHT_DARK_BLUE, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1;
