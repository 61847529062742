import React from 'react';
import { copyText } from '@src/utils/copyText';
import CustomPopover from '@src/components/CustomPopover';
import { convertDate } from '@src/utils/convertDate';
import { Messages } from '@public/locales/messages';
var DateRenderer = function (stringDate, title, isHeaderPopover) {
    if (isHeaderPopover === void 0) { isHeaderPopover = true; }
    var date = new Date(stringDate);
    if (stringDate) {
        var _a = convertDate(stringDate), day = _a.day, year = _a.year, month = _a.month, hours = _a.hours, minutes = _a.minutes;
        var localDate = "".concat(day, "/").concat(month, "/").concat(year, ", ").concat(hours, ":").concat(minutes);
        if (localDate === '0NaN/0NaN/NaN, 0NaN:0NaN')
            return Messages.Controls.NoData;
        return (React.createElement(CustomPopover, { text: date.toUTCString(), iconClick: copyText, titleText: title, isHeader: isHeaderPopover },
            React.createElement("span", null, "".concat(day, "/").concat(month, "/").concat(year, ", ").concat(hours, ":").concat(minutes))));
    }
    return (React.createElement("span", null, Messages.Controls.NoData));
};
export default DateRenderer;
