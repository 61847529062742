var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles } from '@src/app/styled';
var DODGER_BLUE = COLORS.DODGER_BLUE;
export var StyledGroupsUsefulBtnWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: space-between;\n  }\n"])));
export var StyledClearAllBtn = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    display: flex;\n    font-size: 14px;\n    cursor: pointer;\n    align-items: center;\n    padding-top: 10px;\n    user-select: none;\n    line-height: 15px;\n    justify-content: center;\n  }\n"], ["\n  && {\n    color: ", ";\n    display: flex;\n    font-size: 14px;\n    cursor: pointer;\n    align-items: center;\n    padding-top: 10px;\n    user-select: none;\n    line-height: 15px;\n    justify-content: center;\n  }\n"])), DODGER_BLUE);
export var StyledGroupsUsefulContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    margin-top: 15px;\n    display: flex;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    margin-top: 15px;\n    display: flex;\n    justify-content: space-between;\n  }\n"])));
export var StyledUsefulCategories = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    width: 35%;\n    padding: 2px;\n    height: 35vh;\n    overflow: auto;\n    ", "\n  }\n"], ["\n  && {\n    width: 35%;\n    padding: 2px;\n    height: 35vh;\n    overflow: auto;\n    ", "\n  }\n"])), scrollStyles);
export var StyledUsefulPhrases = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    width: 60%;\n    padding: 2px;\n    height: 35vh;\n    overflow: auto;\n    ", "\n  }\n"], ["\n  && {\n    width: 60%;\n    padding: 2px;\n    height: 35vh;\n    overflow: auto;\n    ", "\n  }\n"])), scrollStyles);
export var StyledClearCategoriesWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    width: 15vw;\n    text-align: center;\n\n    & div:first-of-type {\n      padding: 8px;\n      color: ", ";\n    }\n\n    & div:nth-of-type(2) {\n      padding: 8px;\n      color: ", ";\n    }\n  }\n"], ["\n  && {\n    width: 15vw;\n    text-align: center;\n\n    & div:first-of-type {\n      padding: 8px;\n      color: ", ";\n    }\n\n    & div:nth-of-type(2) {\n      padding: 8px;\n      color: ", ";\n    }\n  }\n"])), DODGER_BLUE, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
