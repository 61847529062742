import React from 'react';
import { StyledCheckbox, StyledCheckboxWrapper } from '@src/components/Checkbox/styled';
var Checkbox = function (props) {
    var onChange = props.onChange, checked = props.checked, value = props.value, label = props.label, _a = props.isCircle, isCircle = _a === void 0 ? true : _a;
    return (React.createElement(StyledCheckboxWrapper, null,
        React.createElement(StyledCheckbox, { className: "checkbox-label-wrapper", isCircle: isCircle },
            React.createElement("input", { type: "checkbox", checked: checked, onChange: onChange, value: value, className: "checkbox" }),
            React.createElement("span", { className: "checkbox-text" }, label))));
};
export default Checkbox;
