import { useOutsideClick } from '@src/utils/useOutsideClick';
export var useCustomDatePickerHide = function (_a) {
    var setShowSelectedFilter = _a.setShowSelectedFilter, ref = _a.ref;
    var datePickerCloseHandler = function () {
        var isRangePickerOnDocument = document.getElementById('rangePickerDropdownId');
        if (isRangePickerOnDocument === null || isRangePickerOnDocument === void 0 ? void 0 : isRangePickerOnDocument.offsetParent)
            return;
        setShowSelectedFilter(null);
    };
    useOutsideClick(datePickerCloseHandler, ref, [], true);
};
