import React from 'react';
import Button from '@src/components/Button';
import Filtration from '@src/modules/ToolBar/ToolBarUI/Filtration';
import CustomPopover from '@src/components/CustomPopover';
import { useToolBar } from '@src/modules/ToolBar/ToolBarUI/useToolBar';
import { copyText } from '@src/utils/copyText';
import { Messages } from '@public/locales/messages';
import { StyledSaveExcelWrapper, StyledToolBarWrapper } from '@src/modules/ToolBar/ToolBarUI/styled';
var Toolbar = function (_a) {
    var excelQueue = _a.excelQueue, currentPage = _a.currentPage, createExcelFile = _a.createExcelFile;
    var _b = useToolBar({ createExcelFile: createExcelFile, currentPage: currentPage, excelQueue: excelQueue }), handleExcelDownload = _b.handleExcelDownload, btnSaveExcelStyle = _b.btnSaveExcelStyle;
    return (React.createElement(StyledToolBarWrapper, null,
        React.createElement(Filtration, null),
        React.createElement(StyledSaveExcelWrapper, null,
            React.createElement(CustomPopover, { text: Messages.Controls.ThisOperationCanTakeLongTime, isHeader: false, iconClick: copyText, titleText: Messages.Controls.ClickTiSaveExcel },
                React.createElement("span", null,
                    React.createElement(Button, { title: "Excel", styles: btnSaveExcelStyle, onClick: handleExcelDownload }))))));
};
export default Toolbar;
