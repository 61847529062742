export var searchItemsList = function (array, searchItem) {
    var matchedItems = [];
    var searchArray = array.map(function (el, index) { return el.name; });
    searchItem.toLowerCase();
    searchArray.forEach(function (item) {
        if (typeof item !== 'boolean' && (item === null || item === void 0 ? void 0 : item.toLowerCase().indexOf(searchItem.toLowerCase())) !== -1) {
            matchedItems.push(item);
        }
    });
    var foundItems = [];
    var _loop_1 = function (i) {
        array.forEach(function (el) {
            if (el.name === matchedItems[i]) {
                foundItems.push(el);
            }
        });
    };
    for (var i = 0; i < matchedItems.length; i++) {
        _loop_1(i);
    }
    return Array.from(new Set(foundItems));
};
