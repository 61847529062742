import { Messages } from '@public/locales/messages';
import { tableRenderers } from '@src/components/tableRenderers';
var _a = Messages.Pages.Tags.TableColumns, Agents = _a.Agents, Dialogs = _a.Dialogs, Status = _a.Status, Tags = _a.Tags, UsefulLinks = _a.UsefulLinks, CreatedAt = _a.CreatedAt, UpdatedAt = _a.UpdatedAt;
export var tagColumns = [
    {
        title: Tags,
        key: 'name',
        render: tableRenderers.TextEllipsisRenderer,
        sortingEnabled: true,
    },
    {
        title: Dialogs,
        key: 'dialoguesCount',
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: Agents,
        key: 'agentsCount',
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: UsefulLinks,
        key: 'usefulLinks',
        render: tableRenderers.ArrayTextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: Status,
        key: 'status',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: CreatedAt,
        key: 'createdAt',
        render: tableRenderers.DateRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: UpdatedAt,
        key: 'updatedAt',
        render: tableRenderers.DateRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
];
