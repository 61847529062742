import React from 'react';
import { Messages } from '@public/locales/messages';
import CustomPopover from '@src/components/CustomPopover';
import { system } from '@src/constants/objects';
import { copyText } from '@src/utils/copyText';
var DEFAULT_MAX_TEXT_SYMBOLS = system.DEFAULT_MAX_TEXT_SYMBOLS;
function ArrayTextRenderer(array, title, isHeaderPopover) {
    var str = '';
    if (!Array.isArray(array)) {
        return React.createElement("span", null, Messages.Controls.NoData);
    }
    if (array.length === 0) {
        return React.createElement("span", null, Messages.Controls.NoData);
    }
    try {
        array.sort().forEach(function (el) {
            str += " ".concat(el, " \n");
        });
    }
    catch (_a) {
        array.forEach(function (el) {
            str += " ".concat(el, " \n");
        });
    }
    return (React.createElement(CustomPopover, { text: str, iconClick: copyText, titleText: title, isHeader: isHeaderPopover },
        React.createElement("span", null, "".concat(array[0].slice(0, DEFAULT_MAX_TEXT_SYMBOLS), "..."))));
}
export default ArrayTextRenderer;
