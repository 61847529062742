import { Messages } from '@public/locales/messages';
import { tableRenderers } from '@src/components/tableRenderers';
var _a = Messages.Pages.Channels.TableColumns, ChannelId = _a.ChannelId, ChannelKey = _a.ChannelKey, ChannelName = _a.ChannelName, Language = _a.Language, Url = _a.Url, CreatedAt = _a.CreatedAt, UpdatedAt = _a.UpdatedAt;
export var channelsColumns = [
    {
        title: ChannelName,
        key: 'name',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ChannelId,
        key: 'id',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ChannelKey,
        key: 'key',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: Language,
        key: 'enabledLang',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: Url,
        key: 'url',
        render: tableRenderers.TextEllipsisRenderer,
        sortingEnabled: true,
    },
    {
        title: CreatedAt,
        key: 'createdAt',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: UpdatedAt,
        key: 'updatedAt',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
];
