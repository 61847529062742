import React from 'react';
import { isEqual } from 'lodash';
import Step from '@src/components/Steps/Step';
import { StepsWrapper } from '@src/components/Steps/styled';
var Steps = function (_a) {
    var current = _a.current, items = _a.items;
    return (React.createElement(StepsWrapper, null, items.map(function (el, i) { return React.createElement(Step, { key: i, number: i, current: current, title: el.title }); })));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(Steps, areEqual);
