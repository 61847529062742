var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState, } from 'react';
import { DatePicker } from 'antd';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import Select from '@src/components/Select';
import Checkbox from '@src/components/Checkbox';
import ErrorMessage from '@src/components/ErrorMessage';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { transformDictionary } from '@src/utils/transformDictionary';
import { composeValidators, isNumberValue, isRequired } from '@src/utils/validations';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledLabelTextarea, } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalUsefulPhrase/styled';
import { StyledTitle, StyledTextarea, StyledLimitQuantityText, StyledBulkMessagingInput, StyledUploadClientsWrapper, StyledBulkMessagingSelectWrapper, StyledBulkMessagingCheckboxWrapper, } from '@src/modules/Pages/Clients/BulkMessaging/styled';
import { StyledModalContentWrapper, StyledModalFooterOneBtnWrapper, StyledModalContentFieldsWrapper, } from '@src/app/styled';
import { selectMessenger, notificationTypes, clientsDialogsStatus, enabledLanguagesChannel, } from '@src/global-interfaces';
var SelectClients = function (props) {
    var selectClients = props.selectClients, isOutsideClick = props.isOutsideClick, brandDictionary = props.brandDictionary, showNotification = props.showNotification, setIsOutsideClick = props.setIsOutsideClick, resetNotificationState = props.resetNotificationState;
    var _a = __read(useState(''), 2), bulkMessagingText = _a[0], setBulkMessagingText = _a[1];
    var _b = __read(useState(false), 2), isErrorText = _b[0], setIsErrorText = _b[1];
    var _c = __read(useState({ value: 'telegram', text: 'Telegram' }), 2), selectedMessengers = _c[0], setSelectedMessengers = _c[1];
    var _d = __read(useState(true), 2), isSelectedMessengers = _d[0], setIsSelectedMessengers = _d[1];
    var _e = __read(useState({ value: '', text: '' }), 2), selectedBrand = _e[0], setSelectedBrand = _e[1];
    var _f = __read(useState(true), 2), isSelectedBrand = _f[0], setIsSelectedBrand = _f[1];
    var _g = __read(useState({ value: '', text: '' }), 2), selectedEnabledLanguages = _g[0], setSelectedEnabledLanguages = _g[1];
    var _h = __read(useState(true), 2), isSelectedEnabledLanguages = _h[0], setIsSelectedEnabledLanguages = _h[1];
    var _j = __read(useState([]), 2), selectedClientsDialogsStatus = _j[0], setSelectedClientsDialogsStatus = _j[1];
    var _k = __read(useState(false), 2), isShowDate = _k[0], setIsShowDate = _k[1];
    var _l = __read(useState(''), 2), selectedDate = _l[0], setSelectedDate = _l[1];
    var _m = __read(useState(false), 2), isShowExcludeName = _m[0], setIsShowExcludeName = _m[1];
    var _o = __read(useState(false), 2), isShowCount = _o[0], setIsShowCount = _o[1];
    var onChangeBulkMessagingText = useCallback(function (e) {
        if (!isRequired(bulkMessagingText))
            setIsErrorText(false);
        setBulkMessagingText(e.target.value);
    }, [bulkMessagingText]);
    var showWarning = function (msg) {
        showNotification({ msg: msg, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var selectMessengers = function (el) {
        setSelectedMessengers(el);
        setIsSelectedMessengers(true);
    };
    var selectBrand = function (el) {
        setSelectedBrand(el);
        setIsSelectedBrand(true);
    };
    var selectEnabledLanguages = function (el) {
        setSelectedEnabledLanguages(el);
        setIsSelectedEnabledLanguages(true);
    };
    var selectClientsDialogsStatus = function (el) {
        setSelectedClientsDialogsStatus(el);
    };
    var onChangeDate = function (date, dateString) {
        if (!dateString)
            return;
        setSelectedDate(new Date(dateString).toISOString());
    };
    var onSubmit = function (data) {
        if (isRequired(bulkMessagingText)) {
            showWarning(Messages.Errors.FieldIsRequired);
            setIsErrorText(!!isRequired(bulkMessagingText));
            return;
        }
        if (!selectedMessengers.value) {
            showWarning(Messages.Errors.FieldIsRequired);
            setIsSelectedMessengers(false);
            return;
        }
        if (!selectedBrand.value) {
            showWarning(Messages.Errors.FieldIsRequired);
            setIsSelectedBrand(false);
            return;
        }
        if (!selectedEnabledLanguages.value) {
            showWarning(Messages.Errors.FieldIsRequired);
            setIsSelectedEnabledLanguages(false);
            return;
        }
        var clients = {
            message: bulkMessagingText,
            messenger: selectedMessengers.value,
            language: selectedEnabledLanguages.value,
            brandId: selectedBrand.value,
            excludeByTime: selectedDate,
            excludeName: data.ExcludeName,
            excludeDialogStatuses: selectedClientsDialogsStatus.map(function (el) { return el.value; }),
            count: parseInt(data.countClients, 10) || 0,
        };
        selectClients(clients);
    };
    var bulkMessagingInput = InputWithStyles(Input, StyledBulkMessagingInput);
    return (React.createElement(StyledUploadClientsWrapper, null,
        React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(StyledModalContentWrapper, null,
                        React.createElement(StyledModalContentFieldsWrapper, null,
                            React.createElement(StyledLabelTextarea, null, Messages.Labels.TextForBulkMessaging),
                            React.createElement(StyledTextarea, { onChange: onChangeBulkMessagingText, value: bulkMessagingText, placeholder: Messages.Labels.TextForBulkMessaging }),
                            isErrorText && (React.createElement(ErrorMessage, { text: isRequired(bulkMessagingText) })),
                            React.createElement(StyledBulkMessagingSelectWrapper, null,
                                React.createElement(Select, { label: Messages.Labels.SelectMessengers, placeholder: Messages.Selects.SelectMessengers, items: selectMessenger, initialState: [selectedMessengers], error: !isSelectedMessengers, stateCB: function (el) { return selectMessengers(el); }, isMultiply: false })),
                            React.createElement(StyledBulkMessagingSelectWrapper, null,
                                React.createElement(Select, { label: Messages.Labels.SelectEnabledLanguages, placeholder: Messages.Selects.SelectEnabledLanguages, items: enabledLanguagesChannel, error: !isSelectedEnabledLanguages, stateCB: function (el) { return selectEnabledLanguages(el); }, isMultiply: false })),
                            React.createElement(StyledBulkMessagingSelectWrapper, null,
                                React.createElement(Select, { label: Messages.Labels.SelectBrand, placeholder: Messages.Selects.SelectBrand, items: transformDictionary(brandDictionary, 'text', 'value'), stateCB: function (el) { return selectBrand(el); }, error: !isSelectedBrand, isMultiply: false })),
                            React.createElement(StyledTitle, null, Messages.BulkMessaging.exclude),
                            React.createElement(StyledBulkMessagingSelectWrapper, null,
                                React.createElement(Select, { label: Messages.BulkMessaging.clientsWhoHaveDialogsWithFollowingStatus, placeholder: Messages.BulkMessaging.clientsWhoHaveDialogsWithFollowingStatus, items: clientsDialogsStatus, stateCB: function (el) { return selectClientsDialogsStatus(el); } })),
                            React.createElement(StyledBulkMessagingCheckboxWrapper, null,
                                React.createElement(Checkbox, { value: "Clients, who wrote to AskMe before last message time", onChange: function () { return setIsShowDate(!isShowDate); }, checked: isShowDate, label: Messages.BulkMessaging.ClientsWhoWroteToAskMeBeforeLastMessageTime, isCircle: false }),
                                isShowDate && (React.createElement(DatePicker, { onOpenChange: function () { return setIsOutsideClick(!isOutsideClick); }, panelRender: function (node) { return React.createElement("div", { id: "datePickerPickerDropdownID" }, node); }, onChange: onChangeDate }))),
                            React.createElement(StyledBulkMessagingCheckboxWrapper, null,
                                React.createElement(Checkbox, { value: "Exclude name", onChange: function () { return setIsShowExcludeName(!isShowExcludeName); }, checked: isShowExcludeName, label: Messages.BulkMessaging.clientsWhoHaveThisTextInNameOrLastNameOrUserName, isCircle: false }),
                                isShowExcludeName && (React.createElement(Field, { name: "ExcludeName", validate: composeValidators(isRequired), placeholder: Messages.BulkMessaging.typeText, component: bulkMessagingInput }))),
                            React.createElement(StyledBulkMessagingCheckboxWrapper, null,
                                React.createElement(Checkbox, { value: "CountClients", onChange: function () { return setIsShowCount(!isShowCount); }, checked: isShowCount, label: Messages.BulkMessaging.limitQuantity, isCircle: false }),
                                isShowCount && (React.createElement(React.Fragment, null,
                                    React.createElement(StyledLimitQuantityText, null, Messages.BulkMessaging.limitQuantityText),
                                    React.createElement(Field, { name: "countClients", validate: composeValidators(isRequired, isNumberValue), placeholder: Messages.BulkMessaging.quantityLimit, component: bulkMessagingInput }))))),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { type: TButtonTypes.submit, title: Messages.Buttons.send, onClick: function () { } }))))));
            } })));
};
export default SelectClients;
