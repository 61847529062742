import { useEffect } from 'react';
import moment from 'moment';
export var useStatistics = function (_a) {
    var reports = _a.reports, setAgentReportBlockDay = _a.setAgentReportBlockDay;
    useEffect(function () {
        if (dayBlock.length) {
            setAgentReportBlockDay(dayBlock[0]);
        }
    }, []);
    var getTime = function (milliseconds) {
        var d = moment.duration(milliseconds, 'seconds');
        var hours = Math.floor(d.asHours());
        var mins = Math.floor(d.asMinutes()) - hours * 60;
        return "".concat(hours ? "".concat(hours, "h") : '', " ").concat(mins ? "".concat(mins, "m") : '').trim();
    };
    var sessionsPerDay = function (report) {
        var sessions = JSON.parse(report.sessions);
        var sessionsKeys = Object.keys(sessions);
        if (sessionsKeys.length) {
            return sessionsKeys.map(function (key) { return ({
                time: moment(key).format('hh:mm'),
                type: sessions[key].Type,
            }); });
        }
        return [];
    };
    var dayBlock = reports.map(function (report) { return ({
        date: moment(report.date).format('ddd MMM D'),
        timeBySessionStatuses: {
            online: getTime(report.onlineTime),
            break: getTime(report.breakTime),
            offline: getTime(report.offlineTime),
        },
        sessions: sessionsPerDay(report),
    }); });
    return {
        dayBlock: dayBlock,
    };
};
