var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import ErrorMessage from '@src/components/ErrorMessage';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired, phoneValidation } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledAgentInfoInput } from '@src/modules/Pages/Agents/AgentSettings/AgentInfo/styled';
import { StyledTextarea, StyledLabelTextarea, } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalUsefulPhrase/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var AgentInfo = function (props) {
    var currentAgent = props.currentAgent, changeAgentInfo = props.changeAgentInfo, showNotification = props.showNotification, resetNotificationState = props.resetNotificationState;
    var _a = __read(useState(false), 2), isErrorText = _a[0], setIsErrorText = _a[1];
    var _b = __read(useState(currentAgent.agentInfo || ''), 2), agentInfoText = _b[0], setAgentInfoText = _b[1];
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChange = useCallback(function (e) {
        if (!isRequired(agentInfoText))
            setIsErrorText(false);
        setAgentInfoText(e.target.value);
    }, [agentInfoText]);
    var onSubmit = function (data) {
        if (isRequired(agentInfoText)) {
            showWarning();
            setIsErrorText(!!isRequired(agentInfoText));
            return;
        }
        changeAgentInfo({ agentInfo: agentInfoText, phone: data.agentPhone });
    };
    var AgentInput = InputWithStyles(Input, StyledAgentInfoInput);
    return (React.createElement(Form, { onSubmit: onSubmit, initialValues: { agentPhone: currentAgent.phone || '' }, render: function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(StyledModalContentWrapper, null,
                    React.createElement(StyledModalContentFieldsWrapper, null,
                        React.createElement(Field, { name: "agentPhone", label: Messages.Labels.AgentPhone, showWarning: showWarning, validate: composeValidators(isRequired, phoneValidation), component: AgentInput, placeholder: Messages.Controls.AgentPhone }),
                        React.createElement(StyledLabelTextarea, null, Messages.Labels.Phrase),
                        React.createElement(StyledTextarea, { onChange: onChange, value: agentInfoText, isErrorText: isErrorText }),
                        isErrorText && (React.createElement(ErrorMessage, { text: isRequired(agentInfoText) }))),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.save, onClick: function () {
                                }, type: TButtonTypes.submit }))))));
        } }));
};
export default AgentInfo;
