var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Messages } from '@public/locales/messages';
import { COLORS } from '@src/app/styled';
import Button from '@src/components/Button';
import { StyledMakeBrandAdmin, StyledMakeOrRemoveBrandAdmin, StyledMakeOrRemoveSuperAdmin, } from '@src/components/Button/styled';
import Select from '@src/components/Select';
import ArrayTextRenderer from '@src/components/tableRenderers/ArrayTextRenderer/ArrayTextRenderer';
import TextEllipsisRenderer from '@src/components/tableRenderers/TextEllipsisRenderer/TextEllipsisRenderer';
import { IUserRole } from '@src/DTO/entities/Auth';
import { AgentRolesTableColumns } from '@src/global-interfaces';
import { StyledAgentRole, StyledAgentRolesContent, StyledAgentRolesHeader, StyledAgentRolesHeaderContent, StyledAgentRolesHeaderElement, StyledAgentRolesWrapper, StyledAgentRoleWrapper, StyledMakeBrandAdminWrapper, StyledMakeOrRemoveSuperAdminWrapper, StyledRole, StyledRowNumber, } from '@src/modules/Pages/Agents/AgentSettings/AgentRoles/styled';
import { getNamesByIds } from '@src/utils/getNamesByIds';
import { transformDictionary } from '@src/utils/transformDictionary';
import { transformRoles } from '@src/utils/transformRoles';
var RED = COLORS.RED, DIRTY_GREEN = COLORS.DIRTY_GREEN;
var AgentRoles = function (props) {
    var groupDictionary = props.groupDictionary, brandDictionary = props.brandDictionary, currentAgentRoles = props.currentAgentRoles, makeOrRemoveBrandAdmin = props.makeOrRemoveBrandAdmin, makeOrRemoveSuperAdmin = props.makeOrRemoveSuperAdmin;
    var _a = __read(useState(null), 2), selectedBrand = _a[0], setSelectedBrand = _a[1];
    var _b = __read(useState(false), 2), isSuperAdmin = _b[0], setIsSuperAdmin = _b[1];
    useEffect(function () {
        currentAgentRoles.forEach(function (el) {
            if (el.role === 'superAdmin') {
                setIsSuperAdmin(true);
            }
        });
    }, [isSuperAdmin, currentAgentRoles]);
    var removeMakeOrSuperAdmin = function (superAdmin) {
        if (superAdmin) {
            makeOrRemoveSuperAdmin({ isRemove: true });
        }
        else {
            makeOrRemoveSuperAdmin({ isRemove: false });
        }
    };
    var makeBrandAdmin = function (brandId) {
        if (!brandId)
            return;
        makeOrRemoveBrandAdmin({ brandId: brandId, isRemove: false });
    };
    var removeBrandAdmin = function (brandId) {
        makeOrRemoveBrandAdmin({ brandId: brandId, isRemove: true });
    };
    return (React.createElement(StyledAgentRolesWrapper, null,
        React.createElement(StyledMakeOrRemoveSuperAdminWrapper, null,
            React.createElement(StyledMakeOrRemoveSuperAdmin, { background: isSuperAdmin ? RED : DIRTY_GREEN },
                React.createElement(Button, { title: isSuperAdmin ? Messages.Buttons.removeSuperAdmin : Messages.Buttons.makeSuperAdmin, onClick: function () { return removeMakeOrSuperAdmin(isSuperAdmin); } })),
            React.createElement("div", { className: "make-or-remove-super-admin-text" }, isSuperAdmin ? Messages.Labels.ThisAgentSuperAdmin : Messages.Labels.ThisAgentNotSuperAdmin)),
        React.createElement(StyledMakeBrandAdminWrapper, null,
            React.createElement(Select, { label: Messages.Labels.SelectBrand, placeholder: Messages.Selects.SelectBrand, items: transformDictionary(brandDictionary, 'text', 'value'), stateCB: function (el) { return setSelectedBrand(el); }, isMultiply: false }),
            React.createElement(StyledMakeBrandAdmin, null,
                React.createElement("div", { className: "make-brand-admin-text" }, Messages.Labels.YouCanAssignBrandTheBrand),
                React.createElement(Button, { title: Messages.Buttons.makeBrandAdmin, onClick: function () { return makeBrandAdmin(selectedBrand === null || selectedBrand === void 0 ? void 0 : selectedBrand.value); } }))),
        React.createElement(StyledAgentRolesHeader, null,
            React.createElement(StyledAgentRolesHeaderContent, null, AgentRolesTableColumns.map(function (el, index) { return (React.createElement(StyledAgentRolesHeaderElement, { key: index }, el)); }))),
        React.createElement(StyledAgentRolesContent, null, transformRoles(currentAgentRoles).map(function (el, index) { return (React.createElement(StyledAgentRole, { key: index },
            React.createElement(StyledAgentRoleWrapper, null,
                React.createElement(StyledRowNumber, null, "".concat(index + 1, ".")),
                React.createElement(StyledRole, null, TextEllipsisRenderer(brandDictionary[el === null || el === void 0 ? void 0 : el.brandId], Messages.Labels.BrandName, false)),
                React.createElement(StyledRole, null, ArrayTextRenderer(getNamesByIds(el === null || el === void 0 ? void 0 : el.groupId, groupDictionary), Messages.Labels.GroupName, false)),
                React.createElement(StyledRole, null, ArrayTextRenderer(__spreadArray([], __read(new Set(el === null || el === void 0 ? void 0 : el.role)), false), Messages.Labels.Role, false))),
            (el === null || el === void 0 ? void 0 : el.brandId) ? ((el === null || el === void 0 ? void 0 : el.role.includes(IUserRole.brandAdmin)) ? (React.createElement(StyledMakeOrRemoveBrandAdmin, { background: RED },
                React.createElement(Button, { title: Messages.Buttons.removeBrandAdmin, onClick: function () { return removeBrandAdmin(el === null || el === void 0 ? void 0 : el.brandId); } }))) : (React.createElement(StyledMakeOrRemoveBrandAdmin, { background: DIRTY_GREEN },
                React.createElement(Button, { title: Messages.Buttons.makeBrandAdmin, onClick: function () { return makeBrandAdmin(el === null || el === void 0 ? void 0 : el.brandId); } })))) : null)); }))));
};
export default AgentRoles;
