import ar from './flags/ar.png';
import az from './flags/az.png';
import de from './flags/de.png';
import en from './flags/en.png';
import es from './flags/es.png';
import fr from './flags/fr.png';
import he from './flags/he.png';
import hu from './flags/hu.png';
import it from './flags/it.png';
import ko from './flags/ko.png';
import pl from './flags/pl.png';
import pt from './flags/pt.png';
import ua from './flags/ua.png';
import vi from './flags/vi.png';
import downArrow from './icons/arrow-down.svg';
import askMe from './icons/askme.svg';
import breakIcon from './icons/break.svg';
import changeSearch from './icons/changeSearch.svg';
import checkMark from './icons/checkMark.svg';
import clientIcon from './icons/clientIcon.svg';
import close from './icons/close.svg';
import copy from './icons/copy.svg';
import defaultAvatar from './icons/default-avatar.svg';
import deletePhrases from './icons/deletePhrases.svg';
import downloadIcon from './icons/download-icon.svg';
import downArrowWhite from './icons/drop-down-white.svg';
import edit from './icons/edit.svg';
import editPhrases from './icons/editPhrases.svg';
import errorIcon from './icons/error-icon.svg';
import ex from './icons/ex.svg';
import logout from './icons/exit.svg';
import facebook from './icons/facebook.svg';
import google from './icons/google.svg';
import email from './icons/messengers/email.svg';
import facebookIcon from './icons/messengers/facebook.svg';
import metaDialog from './icons/messengers/metaDialog.svg';
import sms from './icons/messengers/sms.svg';
import telegram from './icons/messengers/telegram.svg';
import viber from './icons/messengers/viber.svg';
import webUi from './icons/messengers/webUi.svg';
import whatsapp from './icons/messengers/whatsapp.svg';
import noData from './icons/noData.svg';
import notShowPassword from './icons/not-show-password.svg';
import error from './icons/notifications/error.svg';
import info from './icons/notifications/info.svg';
import success from './icons/notifications/success.svg';
import warning from './icons/notifications/warning.svg';
import offline from './icons/offline.svg';
import online from './icons/online.svg';
import agent from './icons/pages/agent.svg';
import brand from './icons/pages/brand.svg';
import channel from './icons/pages/channel.svg';
import client from './icons/pages/client.svg';
import dialog from './icons/pages/dialog.svg';
import group from './icons/pages/group.svg';
import report from './icons/pages/report.svg';
import tags from './icons/pages/tags.svg';
import paperClip from './icons/paperClip.svg';
import PauseOutlined from './icons/PauseOutlined.svg';
import playAudio from './icons/playAudio.svg';
import plus from './icons/plus.svg';
import search from './icons/search.svg';
import settings from './icons/settings.svg';
import showPassword from './icons/show-password.svg';
import smsIcon from './icons/smsIcon.svg';
import trash from './icons/trash.svg';
import user from './icons/user.svg';
export var localeFlags = {
    en: en,
    es: es,
    pl: pl,
    he: he,
    ar: ar,
    de: de,
    vi: vi,
    pt: pt,
    ko: ko,
    ua: ua,
    it: it,
    fr: fr,
    az: az,
    hu: hu,
};
export var icons = {
    askMe: askMe,
    google: google,
    facebook: facebook,
    logout: logout,
    online: online,
    offline: offline,
    breakIcon: breakIcon,
    downArrow: downArrow,
    edit: edit,
    plus: plus,
    trash: trash,
    user: user,
    showPassword: showPassword,
    notShowPassword: notShowPassword,
    errorIcon: errorIcon,
    settings: settings,
    defaultAvatar: defaultAvatar,
    search: search,
    close: close,
    ex: ex,
    downArrowWhite: downArrowWhite,
    checkMark: checkMark,
    copy: copy,
    PauseOutlined: PauseOutlined,
    playAudio: playAudio,
    deletePhrases: deletePhrases,
    editPhrases: editPhrases,
    noData: noData,
    downloadIcon: downloadIcon,
    clientIcon: clientIcon,
    paperClip: paperClip,
    changeSearch: changeSearch,
};
export var notifications = {
    success: success,
    warning: warning,
    error: error,
    info: info,
};
export var messengers = {
    sms: sms,
    facebookIcon: facebookIcon,
    telegram: telegram,
    viber: viber,
    webUi: webUi,
    whatsapp: whatsapp,
    email: email,
    smsIcon: smsIcon,
    metaDialog: metaDialog,
};
export var pageIcons = {
    dialog: dialog,
    group: group,
    agent: agent,
    tags: tags,
    client: client,
    channel: channel,
    brand: brand,
    report: report,
};
