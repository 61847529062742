var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
export var StyledTabs = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n\n  .tab-list {\n    border-bottom: ", ";\n  }\n\n  .tab-list-item {\n    display: inline-block;\n    margin-right: 32px;\n    cursor: pointer;\n    font-size: 14px;\n    color: ", ";\n  }\n\n  .tab-list-active {\n    padding-bottom: 6px;\n    color: ", ";\n    border-bottom: 5px solid ", ";\n  }\n\n  .tab-content {\n    width: 100%;\n    height: 85%;\n  }\n"], ["\n  width: 100%;\n  height: 100%;\n\n  .tab-list {\n    border-bottom: ", ";\n  }\n\n  .tab-list-item {\n    display: inline-block;\n    margin-right: 32px;\n    cursor: pointer;\n    font-size: 14px;\n    color: ", ";\n  }\n\n  .tab-list-active {\n    padding-bottom: 6px;\n    color: ", ";\n    border-bottom: 5px solid ", ";\n  }\n\n  .tab-content {\n    width: 100%;\n    height: 85%;\n  }\n"])), function (_a) {
    var isShowBottomLine = _a.isShowBottomLine, theme = _a.theme;
    return (isShowBottomLine ? "1px solid ".concat(theme.colors.text) : 'none');
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, COLORS.DODGER_BLUE, COLORS.DODGER_BLUE);
var templateObject_1;
