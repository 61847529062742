var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, put, fork, call, select, } from 'redux-saga/effects';
import { messengerProvider, smsInbox } from '@src/global-interfaces';
import { apiConfig } from '@src/utils/baseUrl';
import { Messages } from '@public/locales/messages';
import { brandsApi } from '@src/modules/Pages/Brands/redux/api';
import brandsStore from '@src/modules/Pages/Brands/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { entitiesActions } from '@src/redux/entities/reducers';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { BrandPageTypes, brandsActions } from '@src/modules/Pages/Brands/redux/reducer';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
import { infoNotification, errorNotification, successNotification, warningNotification, } from '@src/redux/notification/saga';
function createBrand(_a) {
    var clientUniqueness, name, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                clientUniqueness = payload.clientUniqueness, name = payload.name;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 9]);
                return [4, call(brandsApi.createBrand, {
                        clientUniqueness: clientUniqueness,
                        name: name,
                        clientBanTime: 24, clientBanType: 'id',
                    })];
            case 2:
                _b.sent();
                return [4, fork(successNotification, "".concat(Messages.Success.BrandCreated))];
            case 3:
                _b.sent();
                return [3, 9];
            case 4:
                e_1 = _b.sent();
                console.error(e_1);
                if (!(e_1.text === 'Name must be unique.')) return [3, 6];
                return [4, fork(warningNotification, Messages.Errors.BrandWithSuchNameIsAlreadyExisting || e_1.text)];
            case 5:
                _b.sent();
                return [3, 8];
            case 6: return [4, fork(errorNotification, Messages.Errors.ServerError || e_1.text)];
            case 7:
                _b.sent();
                _b.label = 8;
            case 8: return [3, 9];
            case 9: return [4, put(entitiesActions.updateTableData())];
            case 10:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.CREATE_BRAND, false))];
            case 11:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_BRAND, null))];
            case 12:
                _b.sent();
                return [2];
        }
    });
}
function getCurrentBrand(id) {
    var response, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(brandsApi.getBrandSettingById, id)];
            case 1:
                response = _a.sent();
                return [4, put(brandsActions.setCurrentBrand(response))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_2 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_2.text)];
            case 4:
                _a.sent();
                console.error(e_2);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getMessengersByBrand(id) {
    var response, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(brandsApi.getMessengersByBrand, id)];
            case 1:
                response = _a.sent();
                return [4, put(brandsActions.setCurrentBrandMessengers(response))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_3 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_3.text)];
            case 4:
                _a.sent();
                console.error(e_3);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function showCurrentBrand(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, put(modalWindowsActions.toggleModal(modalTypes.SETTINGS_BRAND, null))];
            case 1:
                _b.sent();
                return [4, call(getCurrentBrand, payload)];
            case 2:
                _b.sent();
                return [4, call(getMessengersByBrand, payload)];
            case 3:
                _b.sent();
                return [2];
        }
    });
}
function updateCurrentBrand(_a) {
    var clientBanType, clientBanTime, name, selectedStatusBrand, currentBrand, e_4, response, e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                clientBanType = payload.clientBanType, clientBanTime = payload.clientBanTime, name = payload.name, selectedStatusBrand = payload.selectedStatusBrand;
                return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                if (!(selectedStatusBrand !== 'no data')) return [3, 5];
                return [4, call(brandsApi.changeStatusBrand, selectedStatusBrand, currentBrand.id)];
            case 3:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.StatusChanged)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5: return [4, fork(warningNotification, Messages.Labels.ChangeStatus)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3, 10];
            case 8:
                e_4 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_4.text)];
            case 9:
                _b.sent();
                console.error(e_4);
                return [3, 10];
            case 10:
                _b.trys.push([10, 15, , 17]);
                return [4, call(brandsApi.updateBrandSetting, __assign(__assign({}, currentBrand), { clientBanType: clientBanType, clientBanTime: clientBanTime, name: name }))];
            case 11:
                response = _b.sent();
                return [4, put(brandsActions.setCurrentBrand(response))];
            case 12:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 13:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.Success)];
            case 14:
                _b.sent();
                return [3, 17];
            case 15:
                e_5 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_5.text)];
            case 16:
                _b.sent();
                console.error(e_5);
                return [3, 17];
            case 17: return [4, put(setLoading(BrandPageTypes.UPDATE_CURRENT_BRAND, false))];
            case 18:
                _b.sent();
                return [2];
        }
    });
}
function createMailInbox(_a) {
    var currentBrand, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 7]);
                return [4, call(brandsApi.createInbox, {
                        brandId: currentBrand.id, externalId: payload, messenger: 'email', status: 1,
                    })];
            case 3:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.MailInboxCreated)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_6 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_6.text)];
            case 6:
                _b.sent();
                console.error(e_6);
                return [3, 7];
            case 7: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 8:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.CREATE_MAIL_INBOX, false))];
            case 9:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_MAIL_INBOX, null))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function createTelegramInbox(_a) {
    var currentBrand, e_7;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 7]);
                return [4, call(brandsApi.createInbox, {
                        brandId: currentBrand.id, externalId: payload, messenger: 'telegramPrivate', status: 1,
                    })];
            case 3:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.TelegramInboxCreated)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_7 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.TelegramIdIsIncorrect || e_7.text)];
            case 6:
                _b.sent();
                console.error(e_7);
                return [3, 7];
            case 7: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 8:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.CREATE_TELEGRAM_INBOX, false))];
            case 9:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_TELEGRAM_INBOX, null))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function createSmsBulkGateProvider(inbox, brandId) {
    var response, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(brandsApi.createSmsAccount, __assign(__assign({}, smsInbox), { brandId: brandId, bulkgate: inbox, providerType: 1 }))];
            case 1:
                response = _a.sent();
                return [4, fork(successNotification, Messages.Success.SmsInboxCreated, 1000)];
            case 2:
                _a.sent();
                return [2, response];
            case 3:
                e_8 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_8.text)];
            case 4:
                _a.sent();
                console.error(e_8);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function createSmsMessageBirdProvider(inbox, brandId) {
    var response, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(brandsApi.createSmsAccount, __assign(__assign({}, smsInbox), { brandId: brandId, messagebird: inbox, providerType: 2 }))];
            case 1:
                response = _a.sent();
                return [4, fork(successNotification, Messages.Success.SmsInboxCreated, 1000)];
            case 2:
                _a.sent();
                return [2, response];
            case 3:
                e_9 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_9.text)];
            case 4:
                _a.sent();
                console.error(e_9);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function createSmsMessageBirdAndBulkGateProvider(inbox, brandId) {
    var response, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(brandsApi.createSmsAccount, __assign(__assign(__assign(__assign({}, smsInbox), { brandId: brandId }), inbox), { providerType: 3 }))];
            case 1:
                response = _a.sent();
                return [4, fork(successNotification, Messages.Success.SmsInboxCreated, 1000)];
            case 2:
                _a.sent();
                return [2, response];
            case 3:
                e_10 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_10.text)];
            case 4:
                _a.sent();
                console.error(e_10);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function createSmsInbox(_a) {
    var currentBrand, messenger, _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _c.sent();
                _b = payload.provider;
                switch (_b) {
                    case messengerProvider.bulkGate: return [3, 2];
                    case messengerProvider.messageBird: return [3, 5];
                    case messengerProvider.bulkGateAndMessageBird: return [3, 8];
                }
                return [3, 11];
            case 2: return [4, call(createSmsBulkGateProvider, {
                    senderId: +payload.senderId,
                    applicationId: payload.applicationId,
                    listCountries: payload.listCountriesBulkGate,
                    applicationToken: payload.applicationToken,
                }, currentBrand.id)];
            case 3:
                messenger = _c.sent();
                return [4, fork(infoNotification, "Your WebHook ".concat(apiConfig.baseUrl, "/bulkgate/").concat(messenger.externalId), 10000)];
            case 4:
                _c.sent();
                return [3, 12];
            case 5: return [4, call(createSmsMessageBirdProvider, {
                    accessKey: payload.accessKey,
                    listCountries: payload.listCountriesMessageBird,
                }, currentBrand.id)];
            case 6:
                messenger = _c.sent();
                return [4, fork(infoNotification, "Your WebHook ".concat(apiConfig.baseUrl, "/messagebird/").concat(messenger.externalId), 10000)];
            case 7:
                _c.sent();
                return [3, 12];
            case 8: return [4, call(createSmsMessageBirdAndBulkGateProvider, {
                    bulkgate: {
                        senderId: payload.senderId,
                        applicationId: payload.applicationId,
                        listCountries: payload.listCountriesBulkGate,
                        applicationToken: payload.applicationToken,
                    },
                    messagebird: {
                        accessKey: payload.accessKey,
                        listCountries: payload.listCountriesMessageBird,
                    },
                }, currentBrand.id)];
            case 9:
                messenger = _c.sent();
                return [4, fork(infoNotification, "Your WebHook ".concat(apiConfig.baseUrl, "/messagebird/").concat(messenger.externalId, " \n Your WebHook ").concat(apiConfig.baseUrl, "/bulkgate/").concat(messenger.externalId), 10000)];
            case 10:
                _c.sent();
                return [3, 12];
            case 11: return [2];
            case 12: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 13:
                _c.sent();
                return [4, put(setLoading(BrandPageTypes.CREATE_SMS_INBOX, false))];
            case 14:
                _c.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_SMS_INBOX, null))];
            case 15:
                _c.sent();
                return [2];
        }
    });
}
function deleteInbox(_a) {
    var currentBrand, messenger, messengerId, response, e_11;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                messenger = payload.messenger, messengerId = payload.messengerId;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4, call(brandsApi.deleteInbox, currentBrand.id, messengerId, messenger)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 5];
                return [4, fork(successNotification, Messages.Success.InboxDeleted)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3, 8];
            case 6:
                e_11 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_11.text)];
            case 7:
                _b.sent();
                console.error(e_11);
                return [3, 8];
            case 8: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 9:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.UPDATE_CURRENT_BRAND, false))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function updateStatus(_a) {
    var currentBrand, response, e_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4, call(brandsApi.updateStatus, payload)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 5];
                return [4, fork(successNotification, Messages.Success.StatusChanged)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3, 8];
            case 6:
                e_12 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_12.text)];
            case 7:
                _b.sent();
                console.error(e_12);
                return [3, 8];
            case 8: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 9:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.UPDATE_INBOX, false))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function uploadMailAuth(_a) {
    var currentBrand, response, e_13;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4, call(brandsApi.mailFile, payload)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 5];
                return [4, fork(successNotification, Messages.Success.FileLoaded)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3, 8];
            case 6:
                e_13 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_13.text)];
            case 7:
                _b.sent();
                console.error(e_13);
                return [3, 8];
            case 8: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 9:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.UPLOAD_MAIL_AUTH, false))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function authMail(_a) {
    var currentBrand, password, login, response, e_14;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                password = payload.password, login = payload.login;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4, call(brandsApi.mailAuth, login, password)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 5];
                return [4, fork(successNotification, Messages.Success.AuthMail)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3, 8];
            case 6:
                e_14 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_14.text)];
            case 7:
                _b.sent();
                console.error(e_14);
                return [3, 8];
            case 8: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 9:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.AUTH_MAIL, false))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function setDomainSettings(_a) {
    var currentBrand, smtpSSL, domain, imap, smtpTLS, response, e_15;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _b.sent();
                smtpSSL = payload.smtpSSL, domain = payload.domain, imap = payload.imap, smtpTLS = payload.smtpTLS;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4, call(brandsApi.setDomainSettings, domain, imap, smtpTLS, smtpSSL)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 5];
                return [4, fork(successNotification, Messages.Success.DomainSettings)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3, 8];
            case 6:
                e_15 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_15.text)];
            case 7:
                _b.sent();
                console.error(e_15);
                return [3, 8];
            case 8: return [4, call(getMessengersByBrand, currentBrand.id)];
            case 9:
                _b.sent();
                return [4, put(setLoading(BrandPageTypes.SET_DOMAIN_SETTINGS, false))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function getSmsAccountsSettings(_a) {
    var response, e_16;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4, call(brandsApi.getSmsAccountsSettings, payload)];
            case 1:
                response = _b.sent();
                return [4, put(brandsActions.setSmsAccountsSettings(response))];
            case 2:
                _b.sent();
                return [3, 5];
            case 3:
                e_16 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_16.text)];
            case 4:
                _b.sent();
                console.error(e_16);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function updateSmsAccounts(_a) {
    var currentBrand, _b, e_17;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _c.sent();
                _c.label = 2;
            case 2:
                _c.trys.push([2, 11, , 13]);
                _b = payload.provider;
                switch (_b) {
                    case messengerProvider.bulkGate: return [3, 3];
                    case messengerProvider.messageBird: return [3, 5];
                }
                return [3, 7];
            case 3: return [4, call(brandsApi.updateSmsAccountsBulkGate, {
                    senderId: +payload.senderId,
                    externalId: payload.externalId,
                    applicationId: payload.applicationId,
                    applicationToken: payload.applicationToken,
                })];
            case 4:
                _c.sent();
                return [3, 8];
            case 5: return [4, call(brandsApi.updateSmsAccountsMessageBird, {
                    accessKey: payload.accessKey,
                    externalId: payload.externalId,
                })];
            case 6:
                _c.sent();
                return [3, 8];
            case 7: return [2];
            case 8: return [4, fork(successNotification, Messages.Success.UpdatedSmsAccounts)];
            case 9:
                _c.sent();
                return [4, call(getMessengersByBrand, currentBrand.id)];
            case 10:
                _c.sent();
                return [3, 13];
            case 11:
                e_17 = _c.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_17.text)];
            case 12:
                _c.sent();
                console.error(e_17);
                return [3, 13];
            case 13: return [4, put(setLoading(BrandPageTypes.UPDATE_SMS_ACCOUNTS, false))];
            case 14:
                _c.sent();
                return [2];
        }
    });
}
function setListCountrySms(_a) {
    var currentBrand, _b, e_18;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, select(brandsStore.getCurrentBrand)];
            case 1:
                currentBrand = _c.sent();
                _c.label = 2;
            case 2:
                _c.trys.push([2, 11, , 13]);
                _b = payload.provider;
                switch (_b) {
                    case messengerProvider.bulkGate: return [3, 3];
                    case messengerProvider.messageBird: return [3, 5];
                }
                return [3, 7];
            case 3: return [4, call(brandsApi.setCountriesBulkGate, {
                    externalId: payload.externalId,
                    listCountry: payload.listCountry,
                    providerType: payload.providerType,
                })];
            case 4:
                _c.sent();
                return [3, 8];
            case 5: return [4, call(brandsApi.setCountriesMessageBird, {
                    externalId: payload.externalId,
                    listCountry: payload.listCountry,
                    providerType: payload.providerType,
                })];
            case 6:
                _c.sent();
                return [3, 8];
            case 7: return [2];
            case 8: return [4, fork(successNotification, 'Set Countries!')];
            case 9:
                _c.sent();
                return [4, call(getMessengersByBrand, currentBrand.id)];
            case 10:
                _c.sent();
                return [3, 13];
            case 11:
                e_18 = _c.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_18.text)];
            case 12:
                _c.sent();
                console.error(e_18);
                return [3, 13];
            case 13: return [4, put(setLoading(BrandPageTypes.SET_LIST_COUNTRY_SMS, false))];
            case 14:
                _c.sent();
                return [2];
        }
    });
}
export var brandPageSagas = [
    takeEvery(BrandPageTypes.CREATE_BRAND, loaderWrap(createBrand, false)),
    takeEvery(BrandPageTypes.SHOW_CURRENT_BRAND, loaderWrap(showCurrentBrand)),
    takeEvery(BrandPageTypes.UPDATE_CURRENT_BRAND, loaderWrap(updateCurrentBrand, false)),
    takeEvery(BrandPageTypes.CREATE_MAIL_INBOX, loaderWrap(createMailInbox, false)),
    takeEvery(BrandPageTypes.CREATE_TELEGRAM_INBOX, loaderWrap(createTelegramInbox, false)),
    takeEvery(BrandPageTypes.CREATE_SMS_INBOX, loaderWrap(createSmsInbox, false)),
    takeEvery(BrandPageTypes.DELETE_INBOX, loaderWrap(deleteInbox, false)),
    takeEvery(BrandPageTypes.UPDATE_INBOX, loaderWrap(updateStatus, false)),
    takeEvery(BrandPageTypes.UPLOAD_MAIL_AUTH, loaderWrap(uploadMailAuth, false)),
    takeEvery(BrandPageTypes.AUTH_MAIL, loaderWrap(authMail, false)),
    takeEvery(BrandPageTypes.SET_DOMAIN_SETTINGS, loaderWrap(setDomainSettings, false)),
    takeEvery(BrandPageTypes.GET_SMS_ACCOUNTS_SETTINGS, loaderWrap(getSmsAccountsSettings)),
    takeEvery(BrandPageTypes.UPDATE_SMS_ACCOUNTS, loaderWrap(updateSmsAccounts, false)),
    takeEvery(BrandPageTypes.SET_LIST_COUNTRY_SMS, loaderWrap(setListCountrySms, false)),
];
