var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Messages } from '@public/locales/messages';
import { StyledModalContentFieldsWrapper, StyledModalContentWrapper, StyledModalFooterOneBtnWrapper, } from '@src/app/styled';
import Button from '@src/components/Button';
import { StyledAddNewBtn, StyledSaveBtn } from '@src/components/Button/styled';
import ModalWindow from '@src/components/ModalWindow';
import ItemCategory from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ItemCategory';
import ItemPhrases from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ItemPhrases';
import CategoryModal from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalCategory';
import UsefulPhraseModal from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/ModalUsefulPhrase';
import { StyledClearAllBtn, StyledClearCategoriesWrapper, StyledGroupsUsefulBtnWrapper, StyledGroupsUsefulContent, StyledUsefulCategories, StyledUsefulPhrases, } from '@src/modules/Pages/Groups/GroupsUpdateModal/GroupsUsefulPhrases/styled';
var GroupsUsefulPhrases = function (props) {
    var _a, _b;
    var currentGroup = props.currentGroup, isNewPhraseGroups = props.isNewPhraseGroups, isEditPhraseGroups = props.isEditPhraseGroups, isNewCategoryGroups = props.isNewCategoryGroups, isEditCategoryGroups = props.isEditCategoryGroups, isClearCategoriesGroups = props.isClearCategoriesGroups, deletePhrase = props.deletePhrase, deleteCategory = props.deleteCategory, setIsOpenTwoModal = props.setIsOpenTwoModal, deleteAllCategory = props.deleteAllCategory, toggleModalNewPhrase = props.toggleModalNewPhrase, toggleModalEditPhrase = props.toggleModalEditPhrase, toggleModalNewCategory = props.toggleModalNewCategory, toggleModalEditCategory = props.toggleModalEditCategory, toggleModalClearCategories = props.toggleModalClearCategories;
    var _c = __read(useState(false), 2), isOpeSomethingModal = _c[0], setIsOpeSomethingModal = _c[1];
    var _d = __read(useState(''), 2), currentPhraseId = _d[0], setCurrentPhraseId = _d[1];
    var _e = __read(useState(((_a = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.usefulPhrases[0]) === null || _a === void 0 ? void 0 : _a.id) || ''), 2), currentCategoryId = _e[0], setCurrentCategoryId = _e[1];
    var isShowEditCategoryModal = function (categoryId) {
        toggleModalEditCategory(categoryId);
        setIsOpenTwoModal(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledGroupsUsefulBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addNewPhrase), onClick: function () {
                        toggleModalNewPhrase();
                        setIsOpenTwoModal(true);
                    } })),
            React.createElement(StyledClearAllBtn, { onClick: function () {
                    toggleModalClearCategories();
                    setIsOpenTwoModal(true);
                } }, Messages.Buttons.clearAll)),
        React.createElement(StyledGroupsUsefulContent, null,
            React.createElement(StyledUsefulCategories, null,
                React.createElement(StyledAddNewBtn, null,
                    React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addNewCategory), onClick: function () {
                            toggleModalNewCategory();
                            setIsOpenTwoModal(true);
                        } })), currentGroup === null || currentGroup === void 0 ? void 0 :
                currentGroup.usefulPhrases.map(function (el, index) { return (React.createElement(ItemCategory, { id: el.id, key: index, name: el.name, number: index + 1, isSelected: currentCategoryId === el.id, editCategory: isShowEditCategoryModal, deleteCategory: deleteCategory, onClickCategory: setCurrentCategoryId })); })),
            React.createElement(StyledUsefulPhrases, null, (_b = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.usefulPhrases.find(function (el) { return el.id === currentCategoryId; })) === null || _b === void 0 ? void 0 : _b.phrases.map(function (el, index) { return (React.createElement(ItemPhrases, { id: el.id, key: index, text: el.text, number: index + 1, phraseId: el.id, categoryId: currentCategoryId, editPhrases: function () {
                    toggleModalEditPhrase();
                    setIsOpenTwoModal(true);
                }, deletePhrase: deletePhrase, setCurrentPhraseId: setCurrentPhraseId })); }))),
        isNewCategoryGroups && (React.createElement(ModalWindow, { title: Messages.Labels.AddNewCategory, closeModalWindow: function () {
                toggleModalNewCategory();
                setIsOpeSomethingModal(false);
                if (!isOpeSomethingModal)
                    setIsOpenTwoModal(false);
            } },
            React.createElement(CategoryModal, null))),
        isNewPhraseGroups && (React.createElement(ModalWindow, { outsideClick: !isOpeSomethingModal, title: Messages.Labels.UsefulPhrases, closeModalWindow: function () {
                toggleModalNewPhrase();
                setIsOpenTwoModal(false);
            } },
            React.createElement(UsefulPhraseModal, { setIsOpeSomethingModal: setIsOpeSomethingModal, setIsOpenTwoModal: setIsOpenTwoModal }))),
        isEditCategoryGroups && (React.createElement(ModalWindow, { title: Messages.Labels.EditCategory, closeModalWindow: function () {
                toggleModalEditCategory(null);
                setIsOpenTwoModal(false);
            } },
            React.createElement(CategoryModal, { categoryId: currentCategoryId, isEdit: true }))),
        isEditPhraseGroups && (React.createElement(ModalWindow, { title: Messages.Labels.EditPhrase, outsideClick: !isOpeSomethingModal, closeModalWindow: function () {
                toggleModalEditPhrase();
                setIsOpenTwoModal(false);
            } },
            React.createElement(UsefulPhraseModal, { isEdit: true, phraseId: currentPhraseId, categoryId: currentCategoryId, setIsOpenTwoModal: setIsOpenTwoModal, setIsOpeSomethingModal: setIsOpeSomethingModal }))),
        isClearCategoriesGroups && (React.createElement(ModalWindow, { title: Messages.Labels.ClearAllPhrase, closeModalWindow: function () {
                toggleModalClearCategories();
                setIsOpenTwoModal(false);
            } },
            React.createElement(StyledClearCategoriesWrapper, null,
                React.createElement(StyledModalContentWrapper, null,
                    React.createElement(StyledModalContentFieldsWrapper, null,
                        React.createElement("div", { className: "clear-categories" }, Messages.Labels.AreYouSure),
                        React.createElement("div", null, Messages.Labels.ThisActionWillDeleteAllPhrasesAndAllCategories)),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.clearAll, onClick: function () {
                                    deleteAllCategory();
                                    toggleModalClearCategories();
                                    setIsOpenTwoModal(false);
                                } })))))))));
};
export default GroupsUsefulPhrases;
