import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import Input from '@src/components/Input';
import { composeValidators, isCorrectEmail, isSpaces, lengthCreator, } from '@src/utils/validations';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { Messages } from '@public/locales/messages';
import { authActions } from '@src/modules/Auth/redux/reducer';
import ModalWindow from '@src/components/ModalWindow';
import { StyledAuthInput, StyledButtonWrapper, StyledForgotPassModal, } from '@src/modules/Auth/ForgotPassword/styled';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
var ForgotPassword = function (_a) {
    var sendEmail = _a.sendEmail, toggleModal = _a.toggleModal;
    var maxEmailLength = lengthCreator.maxEmailLength, minEmailLength = lengthCreator.minEmailLength;
    var AuthInput = InputWithStyles(Input, StyledAuthInput);
    var onSubmit = function (data) {
        sendEmail(data.email);
    };
    return (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: "Restore Password" },
        React.createElement(StyledForgotPassModal, null,
            React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                    var handleSubmit = _a.handleSubmit, submitting = _a.submitting;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(StyledAuthInput, null,
                            React.createElement(Field, { name: "email", component: AuthInput, placeholder: Messages.Controls.Email, validate: composeValidators(isCorrectEmail, isSpaces, minEmailLength, maxEmailLength) })),
                        React.createElement(StyledButtonWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { type: TButtonTypes.submit, title: Messages.Buttons.send, disabled: !submitting, onClick: function () { } })))));
                } }))));
};
var mapDispatchToProps = function (dispatch) { return ({
    sendEmail: function (data) { return dispatch(authActions.forgotPassword(data)); },
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.FORGOT_PASSWORD, null)); },
}); };
export default connect(null, mapDispatchToProps)(ForgotPassword);
