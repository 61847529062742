var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var ItemCategoryWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    display: flex;\n    cursor: pointer;\n    background: ", ";\n    align-items: center;\n    border-radius: 4px;\n    justify-content: space-between;\n    padding: 8px;\n    margin: 5px 0;\n    color: ", ";\n    transition: all 0.1s;\n\n    :hover {\n      background: ", ";\n    }\n\n    .item-category-text {\n      display: flex;\n      align-items: baseline;\n      &__number {\n        padding-right: 5px;\n      }\n      &__name {\n        word-break: break-word;\n        white-space: pre-line;\n      }\n    }\n\n    .item-category-icon-box {\n      display: flex;\n    }\n\n    img {\n      margin-left: 5px;\n      filter: ", ";\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    cursor: pointer;\n    background: ", ";\n    align-items: center;\n    border-radius: 4px;\n    justify-content: space-between;\n    padding: 8px;\n    margin: 5px 0;\n    color: ", ";\n    transition: all 0.1s;\n\n    :hover {\n      background: ", ";\n    }\n\n    .item-category-text {\n      display: flex;\n      align-items: baseline;\n      &__number {\n        padding-right: 5px;\n      }\n      &__name {\n        word-break: break-word;\n        white-space: pre-line;\n      }\n    }\n\n    .item-category-icon-box {\n      display: flex;\n    }\n\n    img {\n      margin-left: 5px;\n      filter: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme, isSelected = _a.isSelected;
    return isSelected && theme.colors.isSelectedItemCategoryBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.isSelectedItemCategoryBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
});
var templateObject_1;
