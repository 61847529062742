var Messages = {
    Success: {
        Success: 'Successo',
        ForgotPasswordSend: 'Il link per la modifica della password è stato inviato alla tua email',
        ResetPasswordSuccess: 'La tua password è stata aggiornata con successo',
        BrandCreated: 'Marchio creato!',
        MailInboxCreated: 'Posta in arrivo creata!',
        PrivacyPolicyChanged: 'Informativa sulla privacy cambiata!',
        SmsInboxCreated: 'Posta in arrivo SMS creata!',
        InboxDeleted: 'Posta in arrivo eliminata!',
        StatusChanged: 'Stato cambiato!',
        FileLoaded: 'File caricato!',
        AuthMail: 'Posta di autenticazione!',
        DomainSettings: 'Impostazioni del dominio!',
        UpdatedSmsAccounts: 'Account SMS aggiornati!',
        ChanelCreated: 'Canale creato!',
        DeleteMessenger: 'Elimina Messenger!',
        AddMessenger: 'Aggiungi Messenger!',
        ChangedMessenger: 'Messaggero cambiato!',
        ClientCreated: 'Cliente creato!',
        ClientsImports: 'Importazioni dei clienti!',
        GroupCreated: 'Gruppo creato!',
        ChangedAssignedAgentsInGroups: 'Agenti assegnati cambiati nei gruppi!',
        ChangedAssignedChannelsInGroups: 'Canali assegnati cambiati nei gruppi!',
        ChangedAssignedTagsInGroups: 'Modificati i tag assegnati nei gruppi!',
        CreateNewCategory: 'Crea una nuova categoria!',
        CreateNewPhrase: 'Crea una nuova frase!',
        DeleteCategory: 'Elimina categoria!',
        DeletePhrase: 'Elimina frase!',
        UpdatePhrase: 'Aggiorna la frase!',
        EditCategory: 'Modifica categoria!',
        DeleteAllCategory: 'Elimina tutta la categoria!',
        TagCreated: 'Tag creato!',
        TagUpdated: 'Tag aggiornato!',
        TelegramInboxCreated: 'Posta in arrivo di Telegram creata!',
        HeaderTextChanged: "Il testo dell'intestazione è cambiato",
        LogoIconUrlChanged: 'URL icona logo cambiato',
        IconUrlChanged: 'URL icona cambiato',
        BackgroundImageUrlChanged: "L'URL dell'immagine di sfondo è cambiato",
        GreetingImageUrlChanged: "L'URL dell'immagine di saluto è cambiato",
        AgentImageUrlChanged: 'URL immagine agente modificato',
        InputPlaceholderChanged: 'Segnaposto di input modificato',
        SendImageUrlChanged: 'Invia URL immagine modificato',
        AgentStatusChanged: "Lo stato dell'agente è cambiato",
        SelectedAgentTagsChanged: "I tag dell'agente selezionati sono cambiati",
        AssignedAgentsGroupsChanged: 'I gruppi di agenti assegnati sono cambiati',
        TeamLeadChanged: 'Caposquadra cambiato',
        AgentRoleChanged: "Il ruolo dell'agente è cambiato",
        AgentInfoChanged: "Le informazioni sull'agente sono cambiate",
    },
    Errors: {
        PasswordMatching: 'Le passwords non corrispondono!',
        IncorrectEmail: "L'e-mail non è corretta",
        IncorrectPhone: 'Il telefono non è corretto',
        IncorrectUrl: "L'URL non è corretto",
        FieldContainSpaces: 'Il campo contiene spazi',
        FieldIsRequired: 'Il campo è obbligatiorio',
        NameValidation: 'La lunghezza del campo deve essere compresa tra 2 e 40 caratteri inclusi',
        NumberValidation: 'Il campo deve contenere solo numeri',
        PasswordValidation: 'La password deve contenere alfabeti e numeri e non può contenere spazi',
        InvoiceNumberValidation: 'Il numero di fattura deve contenere lettere latine, numeri e caratteri speciali',
        MinimumLength: 'Simboli di lunghezza minima {0}',
        MaximumLength: 'Simboli di lunghezza massima {0}',
        ServerError: 'Errore del server, per favore riprova più tardi!',
        ServerErrorFileDownloadTimeOut: 'Errore del server, download del file scaduto, riprova più tardi!',
        NotValidEmailOrPassword: 'Email o password non valide',
        UserWasNotFound: "L'utente non è stato trovato!",
        UserIsAlreadyExist: "L'utente esiste già!",
        AgentWasBanned: "L'agente è stato bannato",
        TokenExpired: 'La sessione è scaduta. Si prega di effettuare nuovamente il login',
        TelegramIdIsIncorrect: "L'ID del telegramma non è corretto",
        ErrorWrongFileFormat: 'Errore, formato file errato!',
        FileSizeExceededWarnings: 'La dimensione del file ha superato gli avvisi!',
        BrandWithSuchNameIsAlreadyExisting: 'Il marchio con tale nome esiste già!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Hai già selezionato un filtro',
        AgentCreate: 'Sei un nuovo agente nel sistema AskMe. Tieni presente che puoi accedere solo dopo che ti è stato assegnato il ruolo di amministratore!',
        NoStatisticsForTheAgent: "Nessun dato statistico per l'agente per il periodo di tempo selezionato",
        FieldIsRequired: 'Il campo è obbligatiorio',
        ChannelMustContainAtLeastOneLanguage: 'Il canale deve contenere almeno una lingua',
    },
    Info: {
        Copied: 'copiato',
        PhraseBotNameAndGreetingsChanged: 'Frasi, nome del bot e saluto sono cambiati',
    },
    Confirm: {},
    Message: {
        Welcome: 'Benvenuto in AskMe',
        SignIn: 'Accedi al pannello di amministrazione o vai al tuo ',
        SignUp: 'Iscriviti al Pannello di amministrazione o vai al tuo ',
        Register: 'Non hai un account con noi?',
        Login: 'Hai già un account?',
        Logout: 'Vuoi davvero partire?',
        ForgotPassword: 'Ha dimenticato la password?',
        ExcelFilesDownloading: 'Download di file Excel',
        excelFileIs: 'file Excel',
        Created: 'è creato',
        Creating: 'sta creando',
    },
    Accordions: {
        MailInboxes: 'Caselle di posta',
        TelegramAccounts: 'Account Telegram',
        SmsAccounts: 'Conti SMS',
    },
    Controls: {
        Newbie: 'Novizia',
        Name: 'Nome',
        Email: 'Email',
        Password: "Parola d'ordine",
        RepeatPassword: 'Ripeti la password',
        Register: 'Registrati',
        Login: 'Accesso',
        Or: 'O',
        SignUp: 'Iscrizione',
        Send: 'Spedire',
        SearchFilter: 'Filtri di ricerca',
        Search: 'Ricerca',
        SearchMessengers: 'Cerca messaggeri',
        SearchAgent: 'Cerca agente',
        SearchLanguage: 'Cerca lingua',
        SearchChannel: 'Cerca canale',
        SearchTag: 'Tag di ricerca',
        SearchStatus: 'Stato della ricerca',
        SearchBrand: 'Cerca marchio',
        SearchWhiteListTags: 'Cerca tag nella lista bianca',
        SearchSessionStatus: 'Stato della sessione di ricerca',
        SearchAccessLevel: 'Livello di accesso alla ricerca',
        SearchHasActiveDialogs: 'La ricerca ha finestre di dialogo attive',
        GroupName: 'Nome del gruppo*',
        Description: 'Descrizione*',
        LinkAlias: 'Link alias*',
        CategoryName: 'Nome della categoria*',
        BrandName: 'Marchio',
        AccessKey: 'Chiave di accesso',
        ClientName: 'Nome del cliente',
        ClientFirstName: 'Nome di battesimo del cliente',
        ClientLastName: 'Cognome cliente',
        ClientPhone: 'Telefono del cliente',
        ClientEmail: 'Email del cliente',
        PleaseWrite: 'Per favore scrivi',
        MailInbox: 'Posta in arrivo',
        SmsInbox: 'Posta in arrivo SMS',
        TelegramInbox: 'Posta in arrivo di Telegramma',
        ApplicationID: 'ID applicazione',
        ApplicationToken: "Token dell'applicazione",
        SenderID: 'identità del mittente',
        ChangeName: 'Cambia nome',
        ChangeURL: 'Cambia URL',
        TagName: 'Nome etichetta',
        LinkUrl: 'URL di collegamento*',
        Greetings: 'Saluti',
        FastPhrase: 'Frase veloce №',
        ClickOnPaperclip: "Fare clic sull'icona della graffetta per selezionare il file",
        UserName: 'Login',
        Domain: 'Dominio*',
        Imap: 'Imap (host:porta)*',
        SmtpTLS: 'Smtp TLS (host:porta)*',
        SmtpSSL: 'Smtp SSL (host:porta)*',
        AttachFile: 'Allega file',
        NoData: 'nessun dato',
        NoRating: 'nessuna valutazione',
        IfYouWantFinishDialog: 'Se si desidera terminare la finestra di dialogo, fare clic sul pulsante OK',
        AllDialogsAreVisibleToAllAgents: 'Tutte le finestre di dialogo sono visibili a tutti gli agenti',
        DialogueIsAssignedAutomatically: "Il dialogo viene assegnato automaticamente all'agente che ha il minor numero di dialoghi",
        AllDialogsAreVisibleToSeveralAgents: 'I dialoghi sono visibili a diversi agenti con il minor numero di dialoghi',
        On: 'Su',
        Off: 'Spento',
        FirstName: 'nome di battesimo',
        InvoiceNumber: 'Numero di fattura',
        LastName: 'Cognome',
        SMS: 'SMS',
        New: 'Nuovo',
        Active: 'Attivo',
        Finished: 'Finito',
        Assigned: 'Assegnato',
        Online: 'in linea',
        Offline: 'Disconnesso',
        Break: 'Rottura',
        Low: 'Basso',
        Medium: 'Medio',
        High: 'Alto',
        True: 'Vero',
        False: 'Falso',
        Date: 'Data',
        AgentName: 'Nome agente',
        Messenger: 'Messaggero',
        Language: 'Lingua',
        Channel: 'Canale',
        Tag: 'Etichetta',
        Status: 'Stato',
        Brand: 'Marca',
        AccessLevel: 'Livello di accesso',
        WhiteListTags: 'Tag della lista bianca',
        SessionStatus: 'Stato della sessione',
        LastTags: 'Ultimi tag',
        HasActiveDialogs: 'Ha finestre di dialogo attive',
        ChannelName: 'Nome del canale',
        WriteYourFirstName: '* Per favore, scrivi il tuo nome',
        WriteYourLastName: '* Per favore, scrivi il tuo cognome',
        WriteYourUserName: '* Per favore, scrivi il nome utente',
        WriteYourPhoneNumber: '* Per favore, scrivi il numero di telefono',
        WriteYourEmail: '* Per favore, scrivi la tua email',
        WriteYourInvoiceNumber: '* Per favore, scrivi il tuo numero di fattura',
        WriteYourAccountNumber: '* Per favore, scrivi il tuo numero di conto',
        BotName: 'Nome del bot',
        Character: 'carattere',
        Number: 'numero',
        IdExample: 'Esempio di identificazione',
        XCharecter: 'X-carattere',
        YNumber: 'Numero Y',
        ThisOperationCanTakeLongTime: 'Questa operazione può richiedere molto tempo!',
        ClickTiSaveExcel: 'Fare clic per salvare in Excel',
        PrivacyPolicyText: "Testo dell'informativa sulla privacy",
        PrivacyPolicyLink: "Collegamento all'informativa sulla privacy",
        PrivacyPolicyLinkText: "Testo del collegamento all'informativa sulla privacy",
        AgentPhone: 'Telefono agente',
        HeaderTextTop: "Testo dell'intestazione in alto",
        HeaderTextBottom: 'Testo di intestazione in basso',
        LogoIconUrl: 'URL icona logo',
        IconUrl: 'URL icona',
        ChatWindowsLogo: 'Logo delle finestre di chat',
        GreetingIconBotUrl: 'Icona di saluto bot Url',
        AgentMessageIconUrl: 'URL icona messaggio agente',
        WriteMessagesPlaceholder: 'Scrivi un segnaposto per i messaggi',
        SendImageUrl: 'Invia URL immagine',
        ChannelURL: 'URL del canale',
    },
    Selects: {
        SelectAccessLevel: 'Seleziona il livello di accesso*',
        SelectChannel: 'Seleziona il canale*',
        SelectChannels: 'Seleziona i canali',
        SelectWhiteTagsList: "Seleziona l'elenco dei tag bianchi*",
        SelectAgents: 'Seleziona agenti',
        SelectGroups: 'Seleziona i gruppi',
        SelectBrand: 'Seleziona marca',
        SelectTags: 'Seleziona tag',
        ChooseProviderCountry: 'Scegli il paese del fornitore',
        SelectMessengers: 'Seleziona messaggeri',
        SelectTagStatus: 'Seleziona lo stato del tag',
        SelectAgent: 'Seleziona agente',
        SelectEnabledLanguages: 'Seleziona le lingue abilitate',
        ChannelName: 'Nome del canale',
        ChannelURL: 'URL del canale',
        SelectLanguageWebChat: 'Seleziona le lingue Web Chat',
    },
    Labels: {
        MoreIconUrl: 'URL icona di più',
        MinimizeIconUrl: 'URL icona minimizza',
        SendFileUrl: 'Invia URL file',
        NumberOfNewAgents: 'Numero di nuovi agenti',
        TextForBulkMessaging: 'Testo per messaggistica in blocco',
        Name: 'Nome',
        LastName: 'Cognome',
        Phone: 'Telefono',
        GroupName: 'Nome del gruppo*',
        SelectBrand: 'Seleziona marca',
        Channel: 'Canale*',
        Description: 'Descrizione*',
        AccessLevel: 'Livello di accesso*',
        WhiteTagsList: 'Elenco di tag bianchi*',
        BrandName: 'Marchio',
        CategoryName: 'Nome della categoria*',
        ChooseProviderCountry: 'Scegli il paese del fornitore',
        AccessKey: 'Chiave di accesso',
        SelectChannels: 'Seleziona i canali',
        SelectGroups: 'Seleziona i gruppi',
        SelectAgents: 'Seleziona agenti',
        SelectTags: 'Seleziona tag',
        SelectAccessLevel: 'Seleziona il livello di accesso*',
        Password: "Parola d'ordine*",
        Login: 'Accesso',
        SelectMessengers: 'Seleziona messaggeri',
        UserName: 'Nome utente',
        ClientName: 'Nome del cliente',
        ClientFirstName: 'Nome di battesimo del cliente',
        ClientLastName: 'Cognome cliente',
        ClientPhone: 'Telefono del cliente',
        ClientEmail: 'Email del cliente',
        ClientInfo: 'Informazioni sul cliente',
        UpdateGroup: 'Aggiorna gruppo',
        Agents: 'Agenti',
        UsefulPhrases: 'Frasi utili',
        Channels: 'Canali',
        Tags: 'Tag',
        AddNewPhrases: 'Aggiungi nuove frasi',
        Phrase: 'Frase',
        SelectCategory: 'Seleziona categoria',
        EditCategory: 'Modifica categoria',
        EditPhrase: 'Modifica frase',
        Or: 'o',
        AddNewCategory: 'Aggiungi nuova categoria',
        Dialogs: 'Dialoghi',
        TotalDuration: 'Durata totale',
        Email: 'Email',
        ClientId: 'Identificativo cliente',
        ClientBanType: 'Tipo di divieto del cliente',
        ClientBanTime: 'Tempo di ban del cliente',
        Messengers: 'Messaggeri',
        IpAddress: 'indirizzo IP',
        ExternalID: 'ID esterno',
        UpdateBrand: 'Aggiorna il marchio',
        CreateMailInbox: 'Crea un account di posta in arrivo',
        CreateTelegramInbox: 'Crea un account Telegram',
        CreateSmsInbox: 'Crea un account SMS',
        MailInbox: 'Posta in arrivo',
        TelegramInbox: 'Posta in arrivo di Telegramma',
        SmsInbox: 'Posta in arrivo SMS',
        ApplicationID: 'ID applicazione',
        ApplicationToken: "Token dell'applicazione",
        SenderID: 'identità del mittente',
        ChangeName: 'Cambia nome',
        ChangeURL: 'Cambia URL',
        TagName: 'Nome etichetta',
        SelectTagStatus: 'Seleziona lo stato del tag',
        SelectAgent: 'Seleziona agente',
        UpdateTag: 'Aggiorna tag',
        CreateTag: 'Crea etichetta',
        UsefulLinks: 'link utili',
        LinkAlias: 'Link alias',
        LinkUrl: 'URL di collegamento',
        Roles: 'Ruoli',
        Groups: 'Gruppi',
        CreateGroups: 'Crea gruppi',
        TeamLead: 'Caposquadra',
        Ban: 'Ban',
        SelectEnabledLanguages: 'Seleziona le lingue abilitate',
        Greetings: 'Saluti',
        FastPhrase: 'Frase veloce №',
        ClientHistory: 'Storia del cliente',
        ChooseRequiredFields: 'Si prega di scegliere i campi obbligatori',
        GetAllDialogs: 'Ottieni tutti i dialoghi',
        GetCurrentDialog: 'Ottieni la finestra di dialogo corrente',
        SaveInLocalStorage: 'Salva in LocalStorage',
        SaveInSessionStorage: 'Salva in SessionStorage',
        InvoiceNumber: 'Numero di fattura',
        AccountNumber: 'Numero di conto',
        General: 'Generale',
        Authorization: 'Autorizzazione',
        Languages: 'Le lingue',
        New: 'Nuovo',
        Active: 'Attivo',
        Inactive: 'Inattivo',
        WaitForActivation: "Attendi l'attivazione",
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Basso',
        Medium: 'Medio',
        High: 'Alto',
        Hidden: 'Nascosto',
        Brand: 'Marca',
        Group: 'Gruppo',
        ChangeStatus: 'Cambiare stato',
        AuthMail: 'Posta di autenticazione',
        SetDomainSettings: 'Imposta le impostazioni del dominio',
        Domain: 'Dominio*',
        Imap: 'Imap (host:porta)*',
        SmtpTLS: 'Smtp TLS (host:porta)*',
        SmtpSSL: 'Smtp SSL (host:porta)*',
        AttachFile: 'Allega file',
        ChannelSettings: 'Impostazioni del canale',
        GeneralSettings: 'impostazioni generali',
        WebChatConstructor: 'Costruttore di chat web',
        BackgroundColor: 'Colore di sfondo',
        MessageColor: 'Colore del messaggio',
        URL: 'URL',
        ChannelName: 'Nome del canale',
        ChannelURL: 'URL del canale',
        AllowSMS: 'Consenti SMS',
        AllowShowAllClientDialogs: 'Consenti mostra tutte le finestre di dialogo del client',
        AllowCaptcha: 'Consenti Captcha',
        InboxSettings: 'Impostazioni della posta in arrivo',
        DistributionOfNewDialogs: 'Distribuzione di nuovi dialoghi tra agenti online',
        ThereAreNoAvailableMessengers: 'Non ci sono messenger disponibili per il canale! Si prega di crearli per il marchio che possiede questo canale!',
        HeaderColorScheme: "Combinazione di colori dell'intestazione",
        GreetingColorScheme: 'Combinazione di colori di saluto',
        QuickPhrases: 'Combinazione di colori di frasi rapide',
        ClientMessage: 'Combinazione di colori del messaggio del cliente',
        AgentMessage: "Combinazione di colori del messaggio dell'agente",
        EndDialogModal: 'Fine della combinazione di colori modali della finestra di dialogo',
        IconColorScheme: "Combinazione di colori dell'icona",
        ScrollColorScheme: 'Scorri la combinazione di colori',
        ChatWindowColor: 'Combinazione di colori della finestra di chat',
        FooterColorScheme: 'Combinazione di colori del piè di pagina',
        AuthColorScheme: 'Combinazione di colori autentica',
        HeaderBackground: 'Sfondo di intestazione',
        HeaderBorderColor: "Colore del bordo dell'intestazione",
        ColorGreetingBotName: 'Saluto il colore del nome del Bot',
        MessageGreetingIcon: "Sfondo dell'icona di saluto",
        MessageGreetingColor: 'Saluto Colore del testo',
        MessageGreetingBorderColor: 'Messaggio di saluto Colore del bordo',
        MessageGreetingBackgroundColor: 'Messaggio di saluto Colore di sfondo',
        QuickPhrasesColor: 'Frase veloce Colore del testo',
        QuickPhrasesBackground: 'Sfondo di frase veloce',
        QuickPhrasesBorderColor: 'Frase veloce Colore del bordo',
        QuickPhrasesColorHover: 'Frase veloce Colore al passaggio del mouse',
        QuickPhrasesBackgroundHover: 'Frase veloce Passa il mouse sullo sfondo',
        QuickPhrasesBorderColorHover: 'Frase veloce Colore del bordo al passaggio del mouse',
        ClientMessageColor: 'Messaggio del cliente Colore del testo',
        ClientTimeColor: "Colore dell'ora del cliente",
        ClientMessageBackground: 'Messaggio del cliente Colore di sfondo',
        ClientMessageBorderColor: 'Messaggio del cliente Colore del bordo',
        ClientLink: 'Collegamento del cliente',
        ClientLinkHover: 'Collegamento del cliente al passaggio del mouse',
        ClientActiveLink: 'Collegamento attivo del cliente',
        ClientVisitedLink: 'Collegamento Visitato dal Cliente',
        ColorAgentNameAndTime: "Nome dell'agente e colore dell'ora",
        MessageAgentColor: 'Colore messaggio agente',
        MessageAgentBackgroundColor: "Messaggio dell'agente Sfondo",
        MessageAgentBorderColor: 'Messaggio agente Colore bordo',
        AgentLink: 'Collegamento agente',
        AgentHoverLink: "Collegamento dell'agente al passaggio del mouse",
        AgentActiveLink: 'Collegamento agente attivo',
        AgentVisitedLink: "Link visitato dall'agente",
        MessageIconBackgroundAgent: "Colore di sfondo dell'icona del messaggio dell'agente",
        TextColor: 'Colore del testo',
        BackdropColor: 'Colore dello sfondo',
        BackdropColorHover: 'Colore dello sfondo al passaggio del mouse',
        ButtonColor: 'Colore pulsante',
        BackdropColorBtn: 'Sfondo Colore di sfondo del pulsante',
        IconBackground: "Sfondo dell'icona",
        ChatWindowsBodyBackground: 'Colore di sfondo del corpo delle finestre di chat',
        ChatWindowsBorderColor: 'Colore del bordo delle finestre di chat',
        FooterBackground: 'Colore di sfondo del piè di pagina',
        FooterBorderColor: 'Colore del bordo del piè di pagina',
        FooterBackgroundTextArea: "Colore di sfondo dell'area di testo del piè di pagina",
        InputBorderColor: 'Inserisci il colore del bordo',
        RegistrationBackgroundColor: 'Colore di sfondo della registrazione',
        LabelColor: "Colore dell'etichetta",
        LabelErrorColor: 'Colore errore etichetta',
        InputColor: 'Inserisci il colore',
        InputBackground: 'Sfondo di input',
        SignUpColor: 'Iscriviti a colori',
        SignUpBackground: 'Iscriviti colore di sfondo',
        Chat: 'Chiacchierata',
        Minimized: 'Minimizzato',
        ScrollBackground: 'Scorri lo sfondo',
        ScrollThumbBackground: 'Scorri lo sfondo del pollice',
        UniqueClientIdentifier: 'Identificatore cliente univoco',
        AgentsSettings: 'Impostazioni agenti',
        ThisAgentSuperAdmin: 'Questo agente è un super amministratore, fai clic sul pulsante per rimuovere i diritti di super amministratore',
        ThisAgentNotSuperAdmin: 'Questo agente non è un super amministratore, fai clic sul pulsante per renderlo super amministratore',
        YouCanAssignBrandTheBrand: "Puoi assegnare il marchio dell'amministratore per questo è necessario selezionare il marchio",
        Role: 'Ruolo',
        BanningThisAgent: 'Bandire questo agente significa che non saranno in grado di accedere alla piattaforma. Appariranno ancora nel sistema.',
        Report: 'Rapporto',
        SelectLanguageWebChat: 'Seleziona le lingue Web Chat',
        FirstName: 'nome di battesimo',
        PhoneNumber: 'Numero di telefono',
        Info: 'Informazioni',
        WebChatSettings: 'Impostazioni della chat web',
        IntegrationWithOtherCRM: 'Integrazione con altri CRM',
        SettingUpInteractionBetweenSystems: "Impostazione dell'interazione tra i sistemi",
        QuickReplies: 'Risposte rapide',
        BotSettings: 'Impostazioni del bot',
        BotName: 'Nome del bot',
        EnabledPrivacyPolicy: 'Informativa sulla privacy abilitata',
        PleaseChooseRequiredFields: 'Si prega di scegliere i campi obbligatori',
        ChangeGreetingsAndPhrases: 'Cambia saluti e frase, Informativa sulla privacy',
        ChangeSmsSettings: 'Modifica le impostazioni degli sms',
        ChangeCountries: 'Cambia paesi',
        ContactsAreWorkingWithClientUniqueness: "I contatti stanno lavorando con l'unicità del client. Il campo è obbligatorio, se l'unicità del client è uguale ad esso.",
        ClientUniquenessIs: "L'unicità del cliente è:",
        PrivacyPolicyText: "Testo dell'informativa sulla privacy",
        PrivacyPolicyLink: "Collegamento all'informativa sulla privacy",
        PrivacyPolicyLinkText: "Testo del collegamento all'informativa sulla privacy",
        AgentPhone: 'Telefono agente',
        HeaderSettings: "Impostazioni dell'intestazione",
        DisableDraggable: 'Disattiva trascinabile',
        DisableHeaderFullScreen: "Disabilita l'intestazione a schermo intero",
        DisableHeaderHideChatWindow: 'Disabilita nascondi la finestra della chat di intestazione',
        DisableHeaderLogo: "Disabilita il logo dell'intestazione",
        DisableHeaderShowEndDialogModal: 'Disabilita intestazione mostra finestra di dialogo finale modale',
        HeaderText: 'Titolo',
        HeaderTextTop: "Testo dell'intestazione in alto",
        HeaderTextBottom: 'Testo di intestazione in basso',
        HeaderHeight: 'Altezza della testata',
        LogoIconUrl: 'URL icona logo',
        IconUrl: 'URL icona',
        IconSettings: 'Impostazioni icona',
        ScrollSettings: 'Scorri le impostazioni',
        ScrollWidth: 'Larghezza di scorrimento',
        ChatWindowsLogo: 'Logo delle finestre di chat',
        BackgroundImageSize: "Dimensione dell'immagine di sfondo",
        GreetingSettings: 'Impostazioni di saluto',
        GreetingIconBotUrl: "URL del bot dell'icona di saluto",
        QuickPhrasesSettings: 'Impostazioni delle frasi rapide',
        ClientMessageSettings: 'Impostazioni dei messaggi del cliente',
        AgentMessageSettings: "Impostazioni dei messaggi dell'agente",
        AgentMessageIconUrl: 'URL icona messaggio agente',
        EndDialogModalSettings: 'Fine finestra di dialogo Impostazioni modali',
        AuthWindowSettings: 'Impostazioni della finestra di autenticazione',
        FooterSettings: 'Impostazioni del piè di pagina',
        WriteMessagesPlaceholder: 'Scrivi un segnaposto per i messaggi',
        SendImageUrl: 'Invia URL immagine',
        SendButtonIconHeight: 'Altezza icona pulsante Invia',
        SendButtonIconWidth: 'Larghezza icona pulsante Invia',
        ChatWindowSettings: 'Impostazioni della finestra di chat',
        ClearAllPhrase: 'Cancella tutta la frase',
        AreYouSure: 'Sei sicuro?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Questa azione rimuoverà tutte le frasi e tutte le categorie',
        AddAgentToGroup: 'Aggiungi agenti a un gruppo',
        CreateClient: 'Crea cliente',
        ClientsImport: 'Importazione clienti',
        UploadExcelFile: 'Carica file Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: "Fare clic sull'icona della graffetta per selezionare il file Excel",
        Contacts: 'Contatti',
        Personal: 'Personale',
        FileUploadHint: 'Carica un file excel pieno di numeri di telefono. Il file Excel deve contenere una colonna denominata "Telefoni" ' +
            'e i numeri di telefono devono essere inseriti in questa colonna. È possibile importare solo file .xls o .xlsx.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Cambia saluti e frase, Informativa sulla privacy',
    },
    AdditionalText: {
        AgentProfile: 'Profilo agente',
        OutOf: 'fuori da',
    },
    Pages: {
        Dialogs: {
            Title: 'Dialoghi',
            TableColumns: {
                Status: 'Stato',
                Dialog: 'Dialogo',
                Messenger: 'Messaggero',
                Channel: 'Canale',
                Tag: 'Tag',
                Agent: 'Agente',
                Rating: 'Valutazione',
                Language: 'Lingua',
                MessagesCount: 'I messaggi contano',
                FirstMessage: 'Primo messaggio',
                LastActivity: "L'ultima attività",
            },
            Messages: 'Messaggi',
        },
        AgentGroups: {
            Title: 'Gruppi di agenti',
            TableColumns: {
                Groups: 'Gruppi',
                Description: 'Descrizione',
                AccessLevel: 'Livello di accesso',
                WhiteListTags: 'Tag della lista bianca',
                CreatedAt: 'Creato a',
                UpdatedAt: 'Aggiornato a',
            },
        },
        Agents: {
            Title: 'Agenti',
            TableColumns: {
                AgentStatus: 'Stato agente',
                Agents: 'Agenti',
                Email: 'Email',
                Groups: 'Gruppi',
                CreatedAt: 'Creato a',
                LastActivity: "L'ultima attività",
            },
        },
        Tags: {
            Title: 'Tag',
            TableColumns: {
                Tags: 'Tag',
                Dialogs: 'Dialoghi',
                Agents: 'Agenti',
                UsefulLinks: 'Link utili',
                Status: 'Stato',
                CreatedAt: 'Creato a',
                UpdatedAt: 'Aggiornato a',
            },
        },
        Clients: {
            Title: 'Clienti',
            TableColumns: {
                SearchName: 'nome',
                AgentName: "Nome dell'agente",
                Messenger: 'Messaggero',
                LastTag: 'Ultimo tag',
                ActiveDialog: 'Ha una finestra di dialogo attiva',
                Brand: 'Marca',
                InternalId: 'ID interno',
                ExternalId: 'ID esterno',
                Name: 'Nome del cliente',
                FirstName: 'nome di battesimo',
                LastName: 'Cognome',
                Email: 'Email',
                InvoiceNumber: 'Numero di fattura',
                DialogsCount: 'Conteggio dialoghi',
                FirstMessage: 'Primo messaggio',
                LastActivity: "L'ultima attività",
                Tag: 'Etichetta',
                Status: 'Stato',
                Channel: 'Canale',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Canali',
            TableColumns: {
                ChannelId: 'Canale ID',
                ChannelKey: 'Tasto canale',
                ChannelName: 'Nome del canale',
                Language: 'Lingua',
                Url: 'Url',
                CreatedAt: 'Creato a',
                UpdatedAt: 'Aggiornato a',
            },
        },
        Brands: {
            Title: 'Marche',
            TableColumns: {
                BrandsId: 'ID marca',
                BrandName: 'Marchio',
                ClientUniqueness: 'Unicità del cliente',
                BrandChannels: 'Canali di marca',
                DialogCount: 'Dialoghi contano',
                CreatedAt: 'Creato a',
                UpdatedAt: 'Aggiornato a',
            },
        },
        Reports: {
            Title: 'Rapporti',
            ByMessengersTab: {
                Title: 'Segnalazione tramite messaggeri',
                Columns: {
                    Messenger: 'Messaggero',
                    ReceivedDialogs: 'Finestre di dialogo ricevute',
                    PositiveDialogs: 'Dialoghi positivi, %',
                    NegativeDialogs: 'Finestre di dialogo negative, %',
                    UnratedDialogs: 'Finestre di dialogo non classificate, %',
                    AvgResponseTime: 'Tempo di risposta medio',
                },
            },
            ByAgentsTab: {
                Title: 'Segnalazione degli agenti',
                Columns: {
                    agentFullName: "Nome completo dell'agente",
                    agentSystemId: 'ID sistema agente',
                    brandName: 'Marchio',
                    receivedDialogs: 'Dialoghi ricevuti',
                    dialogsTelegram: 'Dialoghi Telegram',
                    dialogsWebChat: 'Dialoghi Web Chat',
                    dialogsViber: 'Dialoghi Viber',
                    dialogsWhatsApp: 'Dialoghi WhatsApp',
                    dialogsFacebook: 'Dialoghi Facebook',
                    dialogsEmail: 'Dialoghi Email',
                    dialogsSms: 'Dialoghi SMS',
                    positiveDialogs: 'Dialoghi positivi, %',
                    negativeDialogs: 'Finestre di dialogo negative, %',
                    unratedDialogs: 'Finestre di dialogo non classificate, %',
                    avgResponseTime: 'Tempo di risposta medio',
                },
                noDataForThisDay: 'Nessun dato per questo giorno',
            },
            ByGroupsTab: {
                Title: 'Report by groups',
                Columns: {
                    groupSystemId: 'ID di sistema del gruppo',
                    groupName: 'Nome del gruppo',
                    brandName: 'Marchio',
                    receivedDialogs: 'Dialoghi ricevuti',
                    positiveDialogs: 'Dialoghi positivi, %',
                    negativeDialogs: 'Finestre di dialogo negative, %',
                    unratedDialogs: 'Finestre di dialogo non classificate, %',
                    avgResponseTime: 'Tempo di risposta medio',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Aggiungi filtro',
        reset: 'Ripristina',
        createClient: 'Crea cliente',
        importClient: 'Importa clienti',
        createChannel: 'Crea canale',
        previous: 'Precedente',
        next: 'Prossimo',
        addPhrase: 'Aggiungi una frase',
        addGroup: 'Aggiungere gruppo',
        createBrand: 'Crea marchio',
        addTag: 'Aggiungi Tag',
        save: 'Salva',
        send: 'Spedire',
        getStatistics: 'Ottieni statistiche',
        setCountries: 'Imposta i paesi',
        changeStatus: 'Cambiare stato',
        upload: 'Caricamento',
        setDomainSettings: 'Imposta le impostazioni del dominio',
        login: 'Login',
        update: 'Aggiornare',
        resetPassword: 'Resetta la password',
        addNew: 'Aggiungere nuova',
        addNewPhrase: 'Aggiungi una nuova frase',
        addNewCategory: 'Aggiungi nuova categoria',
        clearAll: 'Cancella tutto',
        loginWithGoogle: 'Accedi con Google',
        loginWithFacebook: 'Fai il login con facebook',
        onPage: 'Alla pagina',
        addNewUsefulLinks: 'Aggiungi nuovi link utili',
        backToChannels: 'Torna a Canali',
        defaultSettings: 'Impostazioni predefinite',
        upgradeChannel: 'Aggiorna il canale',
        apply: 'Applicare',
        remove: 'Rimuovere',
        removeSuperAdmin: 'Rimuovi super amministratore',
        makeSuperAdmin: 'Diventa super amministratore',
        makeBrandAdmin: 'Rendi amministratore del marchio',
        removeBrandAdmin: "Rimuovi l'amministratore del marchio",
        ban: 'Ban',
        unban: 'Annullare il divieto',
        makeTeamLead: 'Fai il caposquadra',
        removeTeamLead: 'Rimuovi Team Lead',
        signUp: 'Iscrizione',
        ok: 'Ok',
        cancel: 'Annulla',
        bulkMessaging: 'Messaggistica in blocco',
        back: 'Indietro',
    },
    Countries: {
        UnitedStates: 'Stati Uniti',
        Canada: 'Canada',
        Australia: 'Australia',
        CzechRepublic: 'Repubblica Ceca',
        Denmark: 'Danimarca',
        Hungary: 'Ungheria',
        Netherlands: 'Olanda',
        Sweden: 'Svezia',
        UnitedKingdom: 'Regno Unito',
        France: 'Francia',
        Germany: 'Germania',
        Poland: 'Polonia',
        Italy: 'Italia',
        Spain: 'Spagna',
    },
    Languages: {
        Ukrainian: 'Ucraino',
        Russian: 'Russo',
        English: 'Inglese',
        Polish: 'Polacco',
        Deutsch: 'Tedesco',
        Spanish: 'Spagnolo',
        Vietnamese: 'Vietnamita',
        Portuguese: 'Portoghese',
        Korean: 'Coreano',
        Italian: 'Italiano',
        Arabic: 'Arabo',
        French: 'Francese',
        Azerbaijan: 'Azerbaigian',
        Hungarian: 'Ungherese',
    },
    BulkMessaging: {
        exclude: 'Escludere',
        typeText: 'Digita il testo',
        selectClients: 'Seleziona i clienti',
        uploadClients: 'Carica clienti',
        sendingMessagesToNewClients: 'Invio di messaggi a nuovi clienti',
        sendingMessagesToExistingClients: 'Invio di messaggi ai clienti esistenti',
        clientsWhoHaveDialogsWithFollowingStatus: 'Clienti, che hanno finestre di dialogo con il seguente stato',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Ügyfelek, akiknél ez a szöveg szerepel a névben, vezetéknévben vagy felhasználónévben',
        limitQuantity: 'Limita la quantità',
        quantityLimit: 'Limite di quantità',
        limitQuantityText: "Limitare la quantità nella trasmissione a non più del numero specificato di destinatari. I client più recenti del sistema entreranno nell'elenco.",
        ClientsWhoWroteToAskMeBeforeLastMessageTime: "Clienti che hanno scritto ad AskMe prima dell'ora dell'ultimo messaggio",
    },
};
export default Messages;
