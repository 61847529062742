var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles } from '@src/app/styled';
var WHITE = COLORS.WHITE;
export var StyledCategoryWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    width: 25vw;\n    padding: 0 20px 10px;\n  }\n"], ["\n  && {\n    width: 25vw;\n    padding: 0 20px 10px;\n  }\n"])));
export var StyledCategoryInput = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    input {\n      height: 31px;\n    }\n  }\n"], ["\n  && {\n    input {\n      height: 31px;\n    }\n  }\n"])));
export var StyledPhrasesName = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    padding-bottom: 5px;\n    color: ", ";\n  }\n"], ["\n  && {\n    padding-bottom: 5px;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledPhrasesWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    background: ", ";\n    padding: 2px;\n    max-height: 35vh;\n    overflow: auto;\n    ", "\n  }\n"], ["\n  && {\n    background: ", ";\n    padding: 2px;\n    max-height: 35vh;\n    overflow: auto;\n    ", "\n  }\n"])), WHITE, scrollStyles);
export var StyledPhrases = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 16px;\n    img {\n      margin-left: 5px;\n      filter: invert();\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 16px;\n    img {\n      margin-left: 5px;\n      filter: invert();\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
