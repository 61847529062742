import React from 'react';
import { system } from '@src/constants/objects';
import { copyText } from '@src/utils/copyText';
import CustomPopover from '@src/components/CustomPopover';
var DEFAULT_MAX_TEXT_SYMBOLS = system.DEFAULT_MAX_TEXT_SYMBOLS;
export var TextEllipsisRenderer = function (text, title, isHeaderPopover) {
    if (text) {
        if (text.length > DEFAULT_MAX_TEXT_SYMBOLS) {
            return (React.createElement(CustomPopover, { text: text, iconClick: copyText, titleText: title, isHeader: isHeaderPopover },
                React.createElement("span", null, "".concat(text.slice(0, DEFAULT_MAX_TEXT_SYMBOLS), "..."))));
        }
        return React.createElement("span", null, text);
    }
    return text;
};
export default TextEllipsisRenderer;
