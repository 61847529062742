import { localeFlags } from '@public/images';
import { SORTING_TYPES } from '@src/global-interfaces';
import { allFilters, isSearchOfValue } from '@src/constants/allFilters';
export var system = {
    allFilters: allFilters,
    isSearchOfValue: isSearchOfValue,
    PAGE_PATHS: {
        ROOT: '/dialogs',
        TAGS: '/tags',
        LOGIN: '/login',
        BRANDS: '/brands',
        AGENTS: '/agents',
        GROUPS: '/groups',
        REPORTS: '/reports',
        CLIENTS: '/clients',
        CHANNELS: '/channels',
        RESET_PASSWORD: '/reset-password',
    },
    AUTH_PROVIDERS: {
        FACEBOOK: {
            NAME: 'facebook',
            CLIENT_ID: '233657217741925',
        },
        GOOGLE: {
            NAME: 'google',
            CLIENT_ID: '828930518933-fst5t8175ae502dj2gao1ne69senbhfm.apps.googleusercontent.com',
        },
    },
    AUTH_FORMS: {
        LOGIN_FORM: 'loginForm',
        REGISTER_FORM: 'registerForm',
    },
    DEFAULT_NOTIFICATION_TIME: 2000,
    DEFAULT_MAX_TEXT_SYMBOLS: 20,
    DEFAULT_PAGINATION_STATE: {
        offset: 1,
        count: 20,
        overallCount: 1,
        showSizeChanger: true,
        pageSizeOptions: [20, 50, 100],
    },
    DEFAULT_SORTING_STATE: {
        column: '',
        order: SORTING_TYPES.DEFAULT,
    },
    DEFAULT_SELECTED_SEARCH_FILTER: 'name',
    LOCALES: {
        en: {
            name: 'en', fullName: 'English', regName: 'en-US', icon: localeFlags.en,
        },
        ua: {
            name: 'ua', fullName: 'Ukrainian', regName: 'uk-UA', icon: localeFlags.ua,
        },
        es: {
            name: 'es', fullName: 'Español', regName: 'es-ES', icon: localeFlags.es,
        },
        pl: {
            name: 'pl', fullName: 'Polskie', regName: 'pl-PL', icon: localeFlags.pl,
        },
        he: {
            name: 'he', fullName: 'עִברִית', regName: 'he-IL', icon: localeFlags.he,
        },
        ar: {
            name: 'ar', fullName: 'عربي', regName: 'ar-AE', icon: localeFlags.ar,
        },
        de: {
            name: 'de', fullName: 'Deutsche', regName: 'de-DE', icon: localeFlags.de,
        },
        vi: {
            name: 'vi', fullName: 'Tiếng Việt', regName: 'vi-VN', icon: localeFlags.vi,
        },
        pt: {
            name: 'pt', fullName: 'Português', regName: 'pt-PT', icon: localeFlags.pt,
        },
        ko: {
            name: 'ko', fullName: '한국어', regName: 'ko-KR', icon: localeFlags.ko,
        },
        it: {
            name: 'it', fullName: 'Italiano', regName: 'it-IT', icon: localeFlags.it,
        },
        fr: {
            name: 'fr', fullName: 'Français', regName: 'fr-FR', icon: localeFlags.fr,
        },
        az: {
            name: 'az', fullName: 'Azərbaycan', regName: 'az-AZ', icon: localeFlags.az,
        },
        hu: {
            name: 'hu', fullName: 'Hungarian', regName: 'hu-HU', icon: localeFlags.hu,
        },
    },
};
