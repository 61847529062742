import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
import wretch from 'wretch';
var getAgentReport = function (id, date) { return wretch("".concat(apiConfig.statisticUrl, "/Statistics/agents/activity?agentId=").concat(id, "&dateFrom=").concat(date))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .json()
    .catch(function () { return undefined; }); };
export var reportsApi = {
    getAgentReport: getAgentReport,
};
