var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Field, Form } from 'react-final-form';
import { TButtonTypes } from '@src/components/Button/Button';
import { statusInbox, notificationTypes, messengerProvider, availableCountriesBulkGate, availableCountriesMessageBird, } from '@src/global-interfaces';
import { composeValidators, isNumberValue, isRequired, lengthCreator, } from '@src/utils/validations';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import Select from '@src/components/Select';
import Checkbox from '@src/components/Checkbox';
import ModalWindow from '@src/components/ModalWindow';
import { Tab, Tabs } from '@src/components/Tabs/Tabs';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledBrandInput } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { StyledLoaderWrapper } from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/styled';
import { StyledUpdateInboxWrapper } from '@src/modules/Pages/Brands/BrandSettings/UpdateSmsInbox/styled';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import { StyledCheckboxWrapper, StyledUpdateMailInboxBtnWrapper, StyledUpdateMailNameWrapper, } from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox/styled';
import { Messages } from '@public/locales/messages';
var UpdateSmsInbox = function (props) {
    var _a, _b, _c, _d;
    var smsInbox = props.smsInbox, toggleModal = props.toggleModal, updateSmsInbox = props.updateSmsInbox, showNotification = props.showNotification, setListCountrySms = props.setListCountrySms, setIsOpenTwoModal = props.setIsOpenTwoModal, updateSmsAccounts = props.updateSmsAccounts, isLoadingCreateInbox = props.isLoadingCreateInbox, smsAccountsSettings = props.smsAccountsSettings, resetNotificationState = props.resetNotificationState, getSmsAccountsSettings = props.getSmsAccountsSettings;
    var minSenderIDBulkGate = lengthCreator.minSenderIDBulkGate, maxSenderIDBulkGate = lengthCreator.maxSenderIDBulkGate, minAccessKeyMessageBird = lengthCreator.minAccessKeyMessageBird, maxAccessKeyMessageBird = lengthCreator.maxAccessKeyMessageBird, maxApplicationIDBulkGate = lengthCreator.maxApplicationIDBulkGate, minApplicationIDBulkGate = lengthCreator.minApplicationIDBulkGate, minApplicationTokenBulkGate = lengthCreator.minApplicationTokenBulkGate, maxApplicationTokenBulkGate = lengthCreator.maxApplicationTokenBulkGate;
    var _e = __read(useState(messengerProvider.bulkGate), 2), currentTab = _e[0], setCurrentTab = _e[1];
    var _f = __read(useState(smsInbox.status), 2), inboxStatus = _f[0], setInboxStatus = _f[1];
    var _g = __read(useState(null), 2), listCountriesBulkGate = _g[0], setListCountriesBulkGate = _g[1];
    var _h = __read(useState(true), 2), isListCountriesBulkGate = _h[0], setIsListCountriesBulkGate = _h[1];
    var _j = __read(useState(null), 2), listCountriesMessageBird = _j[0], setListCountriesMessageBird = _j[1];
    var _k = __read(useState(true), 2), isListCountriesMessageBird = _k[0], setIsListCountriesMessageBird = _k[1];
    useEffect(function () {
        var _a, _b;
        var memoizedListCountriesBulkGate = (_a = smsAccountsSettings === null || smsAccountsSettings === void 0 ? void 0 : smsAccountsSettings.bulkgate) === null || _a === void 0 ? void 0 : _a.listCountries.map(function (elem) { return availableCountriesBulkGate.find(function (el) { return el.value === elem; }); });
        var memoizedListCountriesMessageBird = (_b = smsAccountsSettings === null || smsAccountsSettings === void 0 ? void 0 : smsAccountsSettings.messagebird) === null || _b === void 0 ? void 0 : _b.listCountries.map(function (elem) { return availableCountriesMessageBird.find(function (el) { return el.value === elem; }); });
        setListCountriesBulkGate(memoizedListCountriesBulkGate || []);
        setListCountriesMessageBird(memoizedListCountriesMessageBird || []);
    }, [smsAccountsSettings]);
    useEffect(function () {
        getSmsAccountsSettings(smsInbox.externalId);
    }, [smsInbox]);
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var closeModal = function () {
        toggleModal();
        setIsOpenTwoModal(false);
    };
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    var handleInboxStatusChange = function (changeEvent) {
        setInboxStatus(+changeEvent.target.value);
    };
    var onSubmitMessageBird = function (data) {
        updateSmsAccounts(__assign(__assign({}, data), { externalId: smsInbox.externalId, provider: messengerProvider.messageBird }));
    };
    var onSubmitBulkGate = function (data) {
        updateSmsAccounts(__assign(__assign({}, data), { provider: messengerProvider.bulkGate, externalId: smsInbox.externalId }));
    };
    var setCountriesBulkGate = function () {
        if (listCountriesBulkGate.length <= 0) {
            setIsListCountriesBulkGate(false);
            return;
        }
        setListCountrySms({
            providerType: 1,
            provider: messengerProvider.bulkGate,
            listCountry: listCountriesBulkGate.map(function (el) { return el.value; }),
            externalId: smsInbox.externalId,
        });
    };
    var setCountriesMessageBird = function () {
        if (listCountriesMessageBird.length <= 0) {
            setIsListCountriesMessageBird(false);
            return;
        }
        setListCountrySms({
            providerType: 2,
            provider: messengerProvider.messageBird,
            listCountry: listCountriesMessageBird.map(function (el) { return el.value; }),
            externalId: smsInbox.externalId,
        });
    };
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: Messages.Labels.SmsInbox },
        React.createElement(StyledUpdateInboxWrapper, null, isLoadingCreateInbox ? (React.createElement(StyledLoaderWrapper, null,
            React.createElement(Loader, null))) : (React.createElement(React.Fragment, null,
            React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.ChangeStatus),
            React.createElement(StyledCheckboxWrapper, null, statusInbox.map(function (el, index) { return (React.createElement(Checkbox, { key: index + el.value + el.text, checked: inboxStatus === +statusInbox[index].value, label: el.text, onChange: handleInboxStatusChange, value: el.value })); })),
            React.createElement(StyledUpdateMailInboxBtnWrapper, null,
                React.createElement(StyledSaveBtn, null,
                    React.createElement(Button, { title: Messages.Buttons.changeStatus, onClick: function () { return updateSmsInbox(__assign(__assign({}, smsInbox), { status: inboxStatus })); }, type: TButtonTypes.submit }))),
            React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.ChangeSmsSettings),
            React.createElement(Tabs, { callback: onTabChangeHandler, currentTab: currentTab, isShowBottomLine: true },
                React.createElement(Tab, { tabKey: messengerProvider.bulkGate, title: messengerProvider.bulkGate },
                    React.createElement(Form, { onSubmit: onSubmitBulkGate, initialValues: {
                            senderId: ((_a = smsAccountsSettings === null || smsAccountsSettings === void 0 ? void 0 : smsAccountsSettings.bulkgate) === null || _a === void 0 ? void 0 : _a.senderId.toString()) || '',
                            applicationId: ((_b = smsAccountsSettings === null || smsAccountsSettings === void 0 ? void 0 : smsAccountsSettings.bulkgate) === null || _b === void 0 ? void 0 : _b.applicationId) || '',
                            applicationToken: ((_c = smsAccountsSettings === null || smsAccountsSettings === void 0 ? void 0 : smsAccountsSettings.bulkgate) === null || _c === void 0 ? void 0 : _c.applicationToken) || '',
                        }, render: function (_a) {
                            var handleSubmit = _a.handleSubmit;
                            return (React.createElement("form", { onSubmit: handleSubmit },
                                React.createElement(Field, { validate: composeValidators(isRequired, minApplicationIDBulkGate, maxApplicationIDBulkGate), name: "applicationId", label: Messages.Labels.ApplicationID, showWarning: showWarning, component: BrandInput, placeholder: Messages.Controls.ApplicationID }),
                                React.createElement(Field, { validate: composeValidators(isRequired, minApplicationTokenBulkGate, maxApplicationTokenBulkGate), name: "applicationToken", label: Messages.Labels.ApplicationToken, showWarning: showWarning, component: BrandInput, placeholder: Messages.Controls.ApplicationToken }),
                                React.createElement(Field, { validate: composeValidators(isRequired, isNumberValue, minSenderIDBulkGate, maxSenderIDBulkGate), name: "senderId", label: Messages.Labels.SenderID, showWarning: showWarning, component: BrandInput, placeholder: Messages.Controls.SenderID }),
                                React.createElement(StyledUpdateMailInboxBtnWrapper, null,
                                    React.createElement(StyledSaveBtn, null,
                                        React.createElement(Button, { title: "".concat(Messages.Buttons.update, " ").concat(messengerProvider.bulkGate), onClick: function () { }, type: TButtonTypes.submit })))));
                        } }),
                    React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.ChangeCountries),
                    React.createElement(Select, { initialState: listCountriesBulkGate || [], label: Messages.Labels.ChooseProviderCountry, placeholder: Messages.Selects.ChooseProviderCountry, error: !isListCountriesBulkGate, items: availableCountriesBulkGate, stateCB: function (el) { return setListCountriesBulkGate(el); } }),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: "".concat(Messages.Buttons.setCountries, " ").concat(messengerProvider.bulkGate), onClick: setCountriesBulkGate, type: TButtonTypes.submit })))),
                React.createElement(Tab, { tabKey: messengerProvider.messageBird, title: messengerProvider.messageBird },
                    React.createElement(Form, { onSubmit: onSubmitMessageBird, initialValues: { accessKey: ((_d = smsAccountsSettings === null || smsAccountsSettings === void 0 ? void 0 : smsAccountsSettings.messagebird) === null || _d === void 0 ? void 0 : _d.accessKey) || '' }, render: function (_a) {
                            var handleSubmit = _a.handleSubmit;
                            return (React.createElement("form", { onSubmit: handleSubmit },
                                React.createElement(Field, { validate: composeValidators(isRequired, minAccessKeyMessageBird, maxAccessKeyMessageBird), name: "accessKey", showWarning: showWarning, label: Messages.Labels.AccessKey, component: BrandInput, placeholder: Messages.Controls.AccessKey }),
                                React.createElement(StyledUpdateMailInboxBtnWrapper, null,
                                    React.createElement(StyledSaveBtn, null,
                                        React.createElement(Button, { title: "".concat(Messages.Buttons.update, " ").concat(messengerProvider.messageBird), onClick: function () { }, type: TButtonTypes.submit })))));
                        } }),
                    React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.ChangeCountries),
                    React.createElement(Select, { initialState: listCountriesMessageBird || [], label: Messages.Labels.ChooseProviderCountry, placeholder: Messages.Selects.ChooseProviderCountry, error: !isListCountriesMessageBird, items: availableCountriesMessageBird, stateCB: function (el) { return setListCountriesMessageBird(el); } }),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: "Set countries ".concat(messengerProvider.messageBird), onClick: setCountriesMessageBird, type: TButtonTypes.submit }))))))))));
};
var areEqual = function (prevProps, nextProps) { return isEqual(prevProps, nextProps); };
export default React.memo(UpdateSmsInbox, areEqual);
