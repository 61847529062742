import { connect } from 'react-redux';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { agentsActions } from '@src/modules/Pages/Agents/redux/reducer';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import AgentRoles from '@src/modules/Pages/Agents/AgentSettings/AgentRoles/AgentRoles';
var mapStateToProps = function (state) { return ({
    currentAgentRoles: agentsStore.getCurrentAgentRoles(state),
    brandDictionary: dictionariesStore.dictionaries(state).brandDictionary,
    groupDictionary: dictionariesStore.dictionaries(state).groupDictionary,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    makeOrRemoveBrandAdmin: function (data) { return dispatch(agentsActions.makeOrRemoveBrandAdmin(data)); },
    makeOrRemoveSuperAdmin: function (data) { return dispatch(agentsActions.makeOrRemoveSuperAdmin(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AgentRoles);
