var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledPaginationContainer = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 15px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n  color: ", ";\n  user-select: none;\n  & .left-side-pagination{\n    margin-left: 10px;\n    cursor: default;\n  }\n  & .right-side-pagination{\n    margin-right: 25px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n    width: auto;\n    & .pagination-pages-wrap{\n      display: flex;\n    }\n  }\n"], ["\n  margin-top: 15px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n  color: ", ";\n  user-select: none;\n  & .left-side-pagination{\n    margin-left: 10px;\n    cursor: default;\n  }\n  & .right-side-pagination{\n    margin-right: 25px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n    width: auto;\n    & .pagination-pages-wrap{\n      display: flex;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1;
