import { connect } from 'react-redux';
import { loadingStore } from '@src/redux/loading/selector';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import AgentSettings from './AgentSettings';
var mapStateToProps = function (state) { return ({
    isLoading: loadingStore.getIsLoadingCurrentAgent(state),
    modalInfo: modalWindowsStore.getModalInfo(state, modalTypes.AGENT_MODAL),
}); };
export default connect(mapStateToProps, null)(AgentSettings);
