var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Messages } from '@public/locales/messages';
export var mapDialogsData = function (dialogs, dictionaries) {
    var agentDictionary = dictionaries.agentDictionary, tagDictionary = dictionaries.tagDictionary, channelDictionary = dictionaries.channelDictionary;
    return dialogs.map(function (dialog) { return (__assign(__assign({}, dialog), { id: dialog.id || Messages.Controls.NoData, messenger: dialog.messenger === 'smartbot' ? 'Web Chat' : dialog.messenger || Messages.Controls.NoData, channel: channelDictionary[dialog === null || dialog === void 0 ? void 0 : dialog.channelId] || Messages.Controls.NoData, createdAt: dialog.createdAt || Messages.Controls.NoData, statuses: dialog.status || Messages.Controls.NoData, lastActivity: dialog.lastActivity || Messages.Controls.NoData, rating: dialog.rating || Messages.Controls.NoRating, tag: tagDictionary[dialog.Tag] || Messages.Controls.NoData, agent: agentDictionary[dialog.managerId] || Messages.Controls.NoData, language: dialog.language, msgCount: dialog.msgCount })); });
};
