import React from 'react';
import { icons } from '@public/images';
import { StepsEnum } from '@src/components/Steps/steps.enum';
import { StepWrapper } from '@src/components/Steps/styled';
function Step(_a) {
    var title = _a.title, number = _a.number, current = _a.current;
    var stepState = current > number ? StepsEnum.passed : current < number ? StepsEnum.notPassed : StepsEnum.this;
    return (React.createElement(StepWrapper, { number: number, current: current, stepState: stepState },
        React.createElement("div", { className: "custom-steps-itemHr" }),
        React.createElement("div", { className: "custom-steps-circle" },
            React.createElement("div", { className: "custom-steps-circle__circle" }, stepState === StepsEnum.passed ? React.createElement("img", { src: icons.checkMark, alt: "check" }) : number + 1),
            React.createElement("div", { className: "custom-steps-circle__text" }, title)),
        React.createElement("div", { className: "custom-steps-itemHr" })));
}
export default React.memo(Step);
