import wretch from 'wretch';
import { apiConfig } from '@src/utils/baseUrl';
import { getAuthCookie } from '@src/utils/cookie';
var getExcelFileId = function (data) { return wretch("".concat(apiConfig.baseUrl, "/Report/").concat(data))
    .auth("Bearer ".concat(getAuthCookie()))
    .post()
    .text(); };
var checkIsExcelFileReady = function (excelFileId) { return wretch("".concat(apiConfig.baseUrl, "/Report/check?requestId=").concat(excelFileId))
    .auth("Bearer ".concat(getAuthCookie()))
    .get()
    .text(); };
export var excelApi = {
    getExcelFileId: getExcelFileId,
    checkIsExcelFileReady: checkIsExcelFileReady,
};
