var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';
var sagaMiddleWare = createSagaMiddleware();
export var store = configureStore({
    reducer: rootReducer,
    middleware: function (getDefaultMiddleware) { return __spreadArray(__spreadArray([], __read(getDefaultMiddleware({ serializableCheck: true })), false), [sagaMiddleWare], false); },
    devTools: Boolean(process.env.IS_DEV),
});
sagaMiddleWare.run(rootSaga);
export var useAppDispatch = useDispatch;
export var useAppSelector = useSelector;
