var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import { Accordion } from '@src/components/Accordion/Accordion';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledSaveBtn } from '@src/components/Button/styled';
import Checkbox from '@src/components/Checkbox';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import Loader from '@src/components/Loader';
import ModalWindow from '@src/components/ModalWindow';
import Select from '@src/components/Select';
import { clientBanTypes, notificationTypes, selectBrandStatus } from '@src/global-interfaces';
import CreateMailInbox from '@src/modules/Pages/Brands/BrandSettings/CreateMailInbox';
import CreateSmsInbox from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox';
import CreateTelegramInbox from '@src/modules/Pages/Brands/BrandSettings/CreateTelegramInbox';
import InboxItem from '@src/modules/Pages/Brands/BrandSettings/InboxItem';
import { StyledBrandStatusWrapper, StyledClientBanTimeWrapper, StyledUpdateBrandWrapper, } from '@src/modules/Pages/Brands/BrandSettings/styled';
import UpdateMailInbox from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox';
import UpdateSmsInbox from '@src/modules/Pages/Brands/BrandSettings/UpdateSmsInbox';
import UpdateTelegramInbox from '@src/modules/Pages/Brands/BrandSettings/UpdateTelegramInbox';
import { StyledBrandInput, StyledCheckboxWrapper } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { composeValidators, isRequired, lengthCreator } from '@src/utils/validations';
var BrandSettings = function (props) {
    var roles = props.roles, toggleModal = props.toggleModal, deleteInbox = props.deleteInbox, currentBrand = props.currentBrand, smsMessengers = props.smsMessengers, emailMessengers = props.emailMessengers, setCurrentBrand = props.setCurrentBrand, showNotification = props.showNotification, updateCurrentBrand = props.updateCurrentBrand, isLoadingCreateInbox = props.isLoadingCreateInbox, isLoadingCurrentBrand = props.isLoadingCurrentBrand, resetNotificationState = props.resetNotificationState, toggleModalUpdateSmsInbox = props.toggleModalUpdateSmsInbox, isModalOpenUpdateSmsInbox = props.isModalOpenUpdateSmsInbox, telegramPrivateMessengers = props.telegramPrivateMessengers, isModalOpenCreateSmsInbox = props.isModalOpenCreateSmsInbox, toggleModalCreateSmsInbox = props.toggleModalCreateSmsInbox, toggleModalUpdateMailInbox = props.toggleModalUpdateMailInbox, toggleModalCreateMailInbox = props.toggleModalCreateMailInbox, isModalOpenUpdateMailInbox = props.isModalOpenUpdateMailInbox, isModalOpenCreateMailInbox = props.isModalOpenCreateMailInbox, toggleModalUpdateTelegramInbox = props.toggleModalUpdateTelegramInbox, toggleModalCreateTelegramInbox = props.toggleModalCreateTelegramInbox, isModalOpenCreateTelegramInbox = props.isModalOpenCreateTelegramInbox, isModalOpenUpdateTelegramInbox = props.isModalOpenUpdateTelegramInbox;
    var minNameLength = lengthCreator.minNameLength, maxNameLength = lengthCreator.maxNameLength;
    var _a = __read(useState(''), 2), brandName = _a[0], setBrandName = _a[1];
    var _b = __read(useState(''), 2), clientBanType = _b[0], setClientBanType = _b[1];
    var _c = __read(useState(0), 2), clientBanTime = _c[0], setClientBanTime = _c[1];
    var _d = __read(useState(false), 2), isOpenTwoModal = _d[0], setIsOpenTwoModal = _d[1];
    var _e = __read(useState({}), 2), selectedStatusBrand = _e[0], setSelectedStatusBrand = _e[1];
    useEffect(function () {
        setSelectedStatusBrand((currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.status)
            ? {
                value: currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.status,
                text: selectBrandStatus.find(function (el) { return el.value === (currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.status); }).text,
            }
            : { value: 'no data', text: 'no data' });
        setBrandName(currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.name);
        setClientBanType(currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.clientBanType);
        setClientBanTime(currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.clientBanTime);
    }, [currentBrand]);
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChangeBanTime = function (e) {
        var value = e.target.value;
        if (value >= 0 && value <= 24) {
            setClientBanTime(value);
        }
        else {
            setClientBanTime(24);
        }
    };
    var handleUniqueClientChange = function (changeEvent) {
        setClientBanType(changeEvent.target.value);
    };
    var onToggleModalCreateMailInbox = function () {
        setIsOpenTwoModal(true);
        toggleModalCreateMailInbox();
    };
    var onToggleModalCreateTelegramInbox = function () {
        setIsOpenTwoModal(true);
        toggleModalCreateTelegramInbox();
    };
    var onToggleModalCreateSmsInbox = function () {
        setIsOpenTwoModal(true);
        toggleModalCreateSmsInbox();
    };
    var onToggleModalUpdateMailInbox = function (inbox) {
        setIsOpenTwoModal(true);
        toggleModalUpdateMailInbox(inbox);
    };
    var onToggleModalUpdateTelegram = function (inbox) {
        setIsOpenTwoModal(true);
        toggleModalUpdateTelegramInbox(inbox);
    };
    var onToggleModalUpdateSms = function (inbox) {
        setIsOpenTwoModal(true);
        toggleModalUpdateSmsInbox(inbox);
    };
    var onSubmit = function (data) {
        return updateCurrentBrand({
            name: data.brandName,
            clientBanType: clientBanType,
            clientBanTime: clientBanTime,
            selectedStatusBrand: selectedStatusBrand.value,
        });
    };
    var closeModal = function () {
        toggleModal();
        setCurrentBrand(null);
    };
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: "".concat(Messages.Labels.UpdateBrand, " ").concat(currentBrand ? "- ".concat(currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.name) : ''), outsideClick: !isOpenTwoModal },
        React.createElement(StyledUpdateBrandWrapper, null, isLoadingCurrentBrand ? (React.createElement(Loader, null)) : (React.createElement(Form, { initialValues: { brandName: brandName }, onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: "brandName", label: Messages.Labels.BrandName, validate: composeValidators(isRequired, minNameLength, maxNameLength), component: BrandInput, placeholder: Messages.Controls.BrandName, showWarning: showWarning }),
                    React.createElement("div", null, Messages.Labels.Messengers),
                    React.createElement(Accordion, { title: Messages.Accordions.MailInboxes, secondaryIcon: true, secondaryIconClick: onToggleModalCreateMailInbox, firstIcon: !!(emailMessengers === null || emailMessengers === void 0 ? void 0 : emailMessengers.length) }, isLoadingCreateInbox ? (React.createElement(Loader, null)) : (emailMessengers.map(function (el, index) { return (React.createElement(InboxItem, { key: index, name: el.externalId, onClickDelete: function () { return deleteInbox({ messenger: 'email', messengerId: el.id }); }, onClickEdit: function () { return onToggleModalUpdateMailInbox(el); } })); }))),
                    React.createElement(Accordion, { title: Messages.Accordions.TelegramAccounts, secondaryIcon: true, secondaryIconClick: onToggleModalCreateTelegramInbox, firstIcon: !!(telegramPrivateMessengers === null || telegramPrivateMessengers === void 0 ? void 0 : telegramPrivateMessengers.length) }, isLoadingCreateInbox ? (React.createElement(Loader, null)) : (telegramPrivateMessengers.map(function (el, index) { return (React.createElement(InboxItem, { key: index, name: el.externalId, onClickDelete: function () { return deleteInbox({ messenger: 'telegramPrivate', messengerId: el.id }); }, onClickEdit: function () { return onToggleModalUpdateTelegram(el); } })); }))),
                    React.createElement(Accordion, { title: Messages.Accordions.SmsAccounts, secondaryIcon: true, secondaryIconClick: onToggleModalCreateSmsInbox, firstIcon: !!(smsMessengers === null || smsMessengers === void 0 ? void 0 : smsMessengers.length) }, isLoadingCreateInbox ? (React.createElement(Loader, null)) : (smsMessengers.map(function (el, index) { return (React.createElement(InboxItem, { key: index, name: el.externalId, onClickDelete: function () { return deleteInbox({ messenger: 'sms', messengerId: el.id }); }, onClickEdit: function () { return onToggleModalUpdateSms(el); } })); }))),
                    React.createElement(StyledCheckboxWrapper, null,
                        React.createElement("div", null, Messages.Labels.ClientBanType),
                        clientBanTypes.map(function (el, index) { return (React.createElement(Checkbox, { key: index, checked: clientBanType === clientBanTypes[index].value, label: el.text, onChange: handleUniqueClientChange, value: el.value })); })),
                    React.createElement(StyledClientBanTimeWrapper, null,
                        React.createElement("div", { className: "label" }, Messages.Labels.ClientBanTime),
                        React.createElement("input", { min: "1", max: "24", type: "number", value: clientBanTime, onChange: onChangeBanTime })),
                    roles.find(function (el) { return el.role === 'superAdmin'; }) && (React.createElement(StyledBrandStatusWrapper, null,
                        React.createElement(Select, { initialState: Array.isArray(selectedStatusBrand) ? selectedStatusBrand : [selectedStatusBrand], label: Messages.Labels.ChangeStatus, disableSearch: true, placeholder: Messages.Labels.ChangeStatus, items: __spreadArray(__spreadArray([], __read(selectBrandStatus), false), [
                                !(currentBrand === null || currentBrand === void 0 ? void 0 : currentBrand.status) && { value: 'no data', text: 'no data' },
                            ], false).filter(function (el) { return typeof el !== 'boolean'; }), stateCB: function (el) { return setSelectedStatusBrand(el); }, isMultiply: false }))),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit })))));
            } }))),
        isModalOpenCreateSmsInbox && React.createElement(CreateSmsInbox, { setIsOpenTwoModal: setIsOpenTwoModal }),
        isModalOpenUpdateSmsInbox && React.createElement(UpdateSmsInbox, { setIsOpenTwoModal: setIsOpenTwoModal }),
        isModalOpenCreateMailInbox && React.createElement(CreateMailInbox, { setIsOpenTwoModal: setIsOpenTwoModal }),
        isModalOpenUpdateMailInbox && React.createElement(UpdateMailInbox, { setIsOpenTwoModal: setIsOpenTwoModal }),
        isModalOpenCreateTelegramInbox && React.createElement(CreateTelegramInbox, { setIsOpenTwoModal: setIsOpenTwoModal }),
        isModalOpenUpdateTelegramInbox && React.createElement(UpdateTelegramInbox, { setIsOpenTwoModal: setIsOpenTwoModal })));
};
export default BrandSettings;
