var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { StyledModalContentFieldsWrapper, StyledModalContentWrapper, StyledModalFooterOneBtnWrapper, } from '@src/app/styled';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { Icon } from '@src/components/Icon/Icon';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import Loader from '@src/components/Loader';
import ModalWindow from '@src/components/ModalWindow';
import { notificationTypes } from '@src/global-interfaces';
import { ItemKeyPipeDrive, StyledBackWrapper, StyledIconWrapper, StyledPipeDriveLoaderWrapper, StyledPipeDriveWrapper, } from '@src/modules/Pages/Channels/ChannelUpdate/components/IntegrationCRM/styled';
import { StyledChannelInput } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { channelsActions } from '@src/modules/Pages/Channels/redux/reducer';
import channelsStore from '@src/modules/Pages/Channels/redux/selector';
import { loadingStore } from '@src/redux/loading/selector';
import { notificationActions } from '@src/redux/notification/reducer';
import { composeValidators, isRequired } from '@src/utils/validations';
var PipeDrive = function (props) {
    var currentChannel = props.currentChannel, createPipeDrive = props.createPipeDrive, deletePipeDrive = props.deletePipeDrive, updatePipeDrive = props.updatePipeDrive, showNotification = props.showNotification, isLoadingPipeDrive = props.isLoadingPipeDrive, resetNotificationState = props.resetNotificationState;
    var isPipeDrive = currentChannel.isPipeDrive, id = currentChannel.id;
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var _a = __read(useState(false), 2), isShowSettings = _a[0], setIsShowSettings = _a[1];
    var _b = __read(useState('create'), 2), typeSubmit = _b[0], setTypeSubmit = _b[1];
    var toggleModal = function () {
        setIsShowSettings(!isShowSettings);
    };
    var onSubmit = function (data) {
        var pipeDriveKey = data.pipeDriveKey;
        if (typeSubmit === 'create') {
            createPipeDrive({ key: pipeDriveKey, channelId: id });
        }
        else {
            updatePipeDrive({ key: pipeDriveKey, channelId: id });
        }
    };
    var PipeDriveInput = InputWithStyles(Input, StyledChannelInput);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledIconWrapper, { onClick: toggleModal }, "Pipedrive"),
        isShowSettings && (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: "Pipedrive" },
            React.createElement(StyledPipeDriveWrapper, null, isLoadingPipeDrive ? (React.createElement(StyledPipeDriveLoaderWrapper, null,
                React.createElement(Loader, null))) : (React.createElement(React.Fragment, null,
                typeSubmit === 'update' && (React.createElement(StyledBackWrapper, { onClick: function () { return setTypeSubmit('create'); } },
                    React.createElement("img", { src: icons.downArrow, alt: "downArrow" }),
                    React.createElement("div", null, Messages.Buttons.back))),
                (!isPipeDrive || typeSubmit === 'update') && (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                        var handleSubmit = _a.handleSubmit;
                        return (React.createElement("form", { onSubmit: handleSubmit },
                            React.createElement(StyledModalContentWrapper, null,
                                React.createElement(StyledModalContentFieldsWrapper, null,
                                    React.createElement(Field, { name: "pipeDriveKey", label: Messages.Controls.AccessKey, validate: composeValidators(isRequired), component: PipeDriveInput, placeholder: Messages.Controls.AccessKey, showWarning: showWarning })),
                                React.createElement(StyledModalFooterOneBtnWrapper, null,
                                    React.createElement(StyledSaveBtn, null,
                                        React.createElement(Button, { title: Messages.Buttons.save, onClick: function () { }, type: TButtonTypes.submit }))))));
                    } })),
                isPipeDrive && typeSubmit === 'create' && (React.createElement(ItemKeyPipeDrive, null,
                    React.createElement("div", { className: "item-pipeDrive-text" }, "****************"),
                    React.createElement("div", { className: "item-pipeDrive-icon-box" },
                        React.createElement(Icon, { src: icons.editPhrases, onClick: function () { return setTypeSubmit('update'); } }),
                        React.createElement(Icon, { src: icons.deletePhrases, onClick: function () { return deletePipeDrive(id); } })))))))))));
};
var mapStateToProps = function (state) { return ({
    currentChannel: channelsStore.getCurrentChannel(state),
    isLoadingPipeDrive: loadingStore.getIsLoadingPipeDrive(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    createPipeDrive: function (data) { return dispatch(channelsActions.createPipeDrive(data)); },
    updatePipeDrive: function (data) { return dispatch(channelsActions.updatePipeDrive(data)); },
    deletePipeDrive: function (id) { return dispatch(channelsActions.deletePipeDrive(id)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(PipeDrive);
