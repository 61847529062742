import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { AgentReport } from '@src/modules/Pages/Reports/AgentReport/AgentReport';
import { reportsActions, ReportsPageTypes } from '@src/modules/Pages/Reports/redux/reducer';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import reportsStore from '@src/modules/Pages/Reports/redux/selector';
var mapStateToProps = function (state) { return ({
    agentId: modalWindowsStore.getModalInfo(state, modalTypes.SHOW_AGENT_REPORT),
    agentName: dictionariesStore.dictionaries(state).agentDictionary[modalWindowsStore.getModalInfo(state, modalTypes.SHOW_AGENT_REPORT)],
    reports: reportsStore.agentReports(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    getAgentReports: function (id, date) { return dispatch(reportsActions.getAgentReports(ReportsPageTypes.GET_AGENT_REPORTS, { id: id, date: date })); },
    clearAgentReports: function () { return dispatch(reportsActions.setAgentReports(null)); },
    clearCheckedReportBlockDay: function () { return dispatch(reportsActions.setAgentReportBlockDay(null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AgentReport);
