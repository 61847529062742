import { connect } from 'react-redux';
import { toolBarActions } from '@src/modules/ToolBar/redux/reducer';
import { toolBarStore } from '@src/modules/ToolBar/redux/selector';
import { appStore } from '@src/redux/app/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import ComponentsFiltration from './ComponentsFiltration';
var mapStateToProps = function (state) { return ({
    dictionaries: dictionariesStore.dictionaries(state),
    currentPage: appStore.currentPage(state),
    selectedAllFilters: toolBarStore.getSelectedFilter(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    saveValueSelectedFilter: function (data) { return dispatch(toolBarActions.saveValueSelectedFilter(data)); },
    deleteValueSelectedFilter: function (data) { return dispatch(toolBarActions.deleteValueSelectedFilter(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ComponentsFiltration);
