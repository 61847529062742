import ar from './ar';
import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import he from './he';
import hu from './hu';
import { Locales } from './interfaces';
import it from './it';
import ko from './ko';
import pl from './pl';
import pt from './pt';
import ru from './ru';
import ua from './ua';
import vi from './vi';
export var setAppLang = function (lang) { return localStorage.setItem('app_lang', lang); };
export var getAppLang = function () { return localStorage.getItem('app_lang') || 'en'; };
var getLocalization = function () {
    var lang = getAppLang();
    var Messages;
    switch (lang) {
        case Locales.en: {
            Messages = en;
            break;
        }
        case Locales.ru: {
            Messages = ru;
            break;
        }
        case Locales.de: {
            Messages = de;
            break;
        }
        case Locales.es: {
            Messages = es;
            break;
        }
        case Locales.fr: {
            Messages = fr;
            break;
        }
        case Locales.pl: {
            Messages = pl;
            break;
        }
        case Locales.pt: {
            Messages = pt;
            break;
        }
        case Locales.vi: {
            Messages = vi;
            break;
        }
        case Locales.it: {
            Messages = it;
            break;
        }
        case Locales.ko: {
            Messages = ko;
            break;
        }
        case Locales.ar: {
            Messages = ar;
            break;
        }
        case Locales.he: {
            Messages = he;
            break;
        }
        case Locales.ua: {
            Messages = ua;
            break;
        }
        case Locales.hu: {
            Messages = hu;
            break;
        }
        default: {
            Messages = en;
        }
    }
    return Messages;
};
export var Messages = getLocalization();
