import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
var RangePicker = DatePicker.RangePicker;
var Calendar = function (_a) {
    var handleOnCalendarChange = _a.handleOnCalendarChange, momentDate = _a.momentDate;
    return (React.createElement(RangePicker, { disabled: false, value: momentDate, size: "small", onCalendarChange: handleOnCalendarChange, panelRender: function (node) { return React.createElement("div", { id: "rangePickerDropdownId" }, node); }, allowClear: false, ranges: {
            Yesterday: [moment().add(-1, 'days'), moment().add(-1, 'days')],
            Today: [moment(), moment()],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'Last Week': [moment().add(-7, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().add(-1, 'month'), moment()],
            'Last 3 Months': [moment().add(-3, 'month'), moment()],
            'Last Year': [moment().add(-1, 'years'), moment()],
        } }));
};
export default Calendar;
