import React from 'react';
import { icons } from '@public/images';
import { SessionStatus } from '@src/global-interfaces';
import { TextWithImageRenderer } from '@src/components/tableRenderers/TextWithImageRenderer/TextWithImageRenderer';
var offline = icons.offline, breakIcon = icons.breakIcon, online = icons.online;
var StatusRenderer = function (status) {
    var icon = '';
    switch (status) {
        case SessionStatus.offline:
            icon = offline;
            break;
        case SessionStatus.online:
            icon = online;
            break;
        case SessionStatus.break:
            icon = breakIcon;
            break;
        default:
            icon = '';
    }
    return React.createElement(TextWithImageRenderer, { text: status === null || status === void 0 ? void 0 : status.toUpperCase(), imageUrl: icon });
};
export default StatusRenderer;
