var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles } from '@src/app/styled';
var ALERT = COLORS.ALERT, WHITE = COLORS.WHITE;
export var StyledPhraseWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    width: 25vw;\n    max-height: 35vh;\n    overflow: auto;\n    padding: 0 20px 10px;\n    ", "\n  }\n"], ["\n  && {\n    width: 25vw;\n    max-height: 35vh;\n    overflow: auto;\n    padding: 0 20px 10px;\n    ", "\n  }\n"])), scrollStyles);
export var StyledOrAddCategoryBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    margin-top: 15px;\n    display: flex;\n    align-items: center;\n\n    .or-add-new-category-box-text {\n      font-size: 15px;\n      padding-right: 10px;\n      line-height: 15px;\n      color: ", ";\n    }\n  }\n"], ["\n  && {\n    margin-top: 15px;\n    display: flex;\n    align-items: center;\n\n    .or-add-new-category-box-text {\n      font-size: 15px;\n      padding-right: 10px;\n      line-height: 15px;\n      color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledLabelTextarea = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    padding-bottom: 5px;\n    color: ", ";\n  }\n"], ["\n  && {\n    padding-bottom: 5px;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledTextarea = styled.textarea(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid ", ";\n    outline: none;\n    min-height: 120px;\n    ", "\n  }\n"], ["\n  && {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid ", ";\n    outline: none;\n    min-height: 120px;\n    ", "\n  }\n"])), function (_a) {
    var isErrorText = _a.isErrorText;
    return (isErrorText ? ALERT : WHITE);
}, scrollStyles);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
