var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles } from '@src/app/styled';
export var StylerLoaderModalContentWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    max-height: 50vh;\n    min-height: 10vh;\n    min-width: 10vw;\n    max-width: 40vw;\n  }\n"], ["\n  && {\n    max-height: 50vh;\n    min-height: 10vh;\n    min-width: 10vw;\n    max-width: 40vw;\n  }\n"])));
export var StylerAudioContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    flex-direction: column;\n    padding: 10px;\n    display: flex;\n    max-height: 25vh;\n    overflow: auto;\n    ", "\n  }\n"], ["\n  && {\n    flex-direction: column;\n    padding: 10px;\n    display: flex;\n    max-height: 25vh;\n    overflow: auto;\n    ", "\n  }\n"])), scrollStyles);
export var StylerAudioContentTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    font-size: 18px;\n    color: ", ";\n  }\n"], ["\n  && {\n    font-size: 18px;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1, templateObject_2, templateObject_3;
