var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { env } from '@mdca/wde';
import { getBaseUrl } from '@src/utils/getBaseUrl';
export var environmentRelatedUrls = {
    baseUrl: env('API_BASE_URL') || getBaseUrl(),
    domain: env('API_BASE_URL') || getBaseUrl(),
    agentAskMeUrl: env('AGENT_ASKME_URL') || getBaseUrl(),
};
var apiPrefixes = {
    apiPrefix: env('API_PREFIX') || '/asp-admin-backend',
    apiPrefixAuth: env('API_PREFIX_AUTH') || '/asp-auth-api',
    apiPrefixStatistic: env('API_PREFIX_STATISTIC') || '/asp-admin-statistic',
};
export var apiConfig = __assign(__assign(__assign({}, apiPrefixes), environmentRelatedUrls), { baseUrl: environmentRelatedUrls.baseUrl + apiPrefixes.apiPrefix, authUrl: environmentRelatedUrls.baseUrl + apiPrefixes.apiPrefixAuth, statisticUrl: environmentRelatedUrls.baseUrl + apiPrefixes.apiPrefixStatistic });
