import { connect } from 'react-redux';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { agentsActions } from '@src/modules/Pages/Agents/redux/reducer';
import TeamLead from './TeamLead';
var mapStateToProps = function (state) { return ({
    currentAgentGroups: agentsStore.getCurrentAgentGroups(state),
    groupsCurrentAgent: agentsStore.getAvailableGroupsCurrentAgent(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    makeOrRemoveTeamLead: function (data) { return dispatch(agentsActions.makeOrRemoveTeamLead(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TeamLead);
