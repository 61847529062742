var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes, statusInbox } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import Loader from '@src/components/Loader';
import Checkbox from '@src/components/Checkbox';
import ModalWindow from '@src/components/ModalWindow';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired } from '@src/utils/validations';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledBrandInput } from '@src/modules/Pages/Brands/CreateBrand/styled';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import { StyledCheckboxWrapper, StyledUpdateMailNameWrapper, StyledUpdateMailInboxWrapper, StyledUpdateMailInboxBtnWrapper, } from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox/styled';
import { SendFileButtonInput, SendFileButtonWrapper, SendFileButtonInputLabel, StyledImportClientsWrapper, StyledImportClientsFileName, } from '@src/modules/Pages/Clients/ClientsImport/styled';
var paperClip = icons.paperClip;
var UpdateMailInbox = function (props) {
    var authMail = props.authMail, mailInbox = props.mailInbox, toggleModal = props.toggleModal, uploadMailAuth = props.uploadMailAuth, showNotification = props.showNotification, updateEmailInbox = props.updateEmailInbox, setIsOpenTwoModal = props.setIsOpenTwoModal, setDomainSettings = props.setDomainSettings, isLoadingCreateInbox = props.isLoadingCreateInbox, resetNotificationState = props.resetNotificationState;
    var _a = __read(useState(mailInbox.status), 2), inboxStatus = _a[0], setInboxStatus = _a[1];
    var _b = __read(useState(''), 2), fileName = _b[0], setFileName = _b[1];
    var _c = __read(useState(null), 2), currentFile = _c[0], setCurrentFile = _c[1];
    var showWarning = function (msg) {
        showNotification({ msg: msg || Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var handleInboxStatusChange = function (changeEvent) {
        setInboxStatus(+changeEvent.target.value);
    };
    var closeModal = function () {
        toggleModal();
        setIsOpenTwoModal(false);
    };
    var onAuthMail = function (data) {
        var password = data.password, login = data.login;
        authMail({ password: password, login: login });
    };
    var onSetDomainSettings = function (data) {
        var domain = data.domain, imap = data.imap, smtpSSL = data.smtpSSL, smtpTLS = data.smtpTLS;
        setDomainSettings({
            domain: domain,
            imap: imap,
            smtpSSL: smtpSSL,
            smtpTLS: smtpTLS,
        });
    };
    var onChangeHandler = useCallback(function (e) {
        var content;
        var file = e.target.files[0];
        var reader = new FileReader();
        setFileName(file.name);
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            content = reader.result;
            if (typeof content !== 'string')
                return;
            setCurrentFile(content);
        };
    }, []);
    var clearUploadInput = useCallback(function (e) {
        e.target.value = null;
    }, []);
    var onUploadMailAuth = useCallback(function () {
        if (!currentFile) {
            showWarning('Selected value');
            return;
        }
        uploadMailAuth(currentFile);
    }, [currentFile]);
    var BrandInput = InputWithStyles(Input, StyledBrandInput);
    return (React.createElement(ModalWindow, { closeModalWindow: closeModal, title: Messages.Labels.MailInbox },
        React.createElement(StyledUpdateMailInboxWrapper, null, isLoadingCreateInbox ? (React.createElement(Loader, null)) : (React.createElement(React.Fragment, null,
            React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.ChangeStatus),
            React.createElement(StyledCheckboxWrapper, null, statusInbox.map(function (el, index) { return (React.createElement(Checkbox, { key: index + el.value + el.text, checked: inboxStatus === +statusInbox[index].value, label: el.text, onChange: handleInboxStatusChange, value: el.value })); })),
            React.createElement(StyledUpdateMailInboxBtnWrapper, null,
                React.createElement(StyledSaveBtn, null,
                    React.createElement(Button, { title: Messages.Buttons.changeStatus, onClick: function () { return updateEmailInbox(__assign(__assign({}, mailInbox), { status: inboxStatus })); }, type: TButtonTypes.submit }))),
            React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.AttachFile),
            React.createElement(StyledImportClientsWrapper, null,
                React.createElement(SendFileButtonWrapper, null,
                    React.createElement(SendFileButtonInputLabel, { htmlFor: "upload-file" },
                        React.createElement("img", { src: paperClip, alt: Messages.Controls.AttachFile })),
                    React.createElement(SendFileButtonInput, { type: "file", name: "file", id: "upload-file", onChange: onChangeHandler, onClick: clearUploadInput })),
                React.createElement(StyledImportClientsFileName, null, fileName || Messages.Controls.ClickOnPaperclip)),
            React.createElement(StyledUpdateMailInboxBtnWrapper, null,
                React.createElement(StyledSaveBtn, null,
                    React.createElement(Button, { title: Messages.Buttons.upload, onClick: onUploadMailAuth, type: TButtonTypes.submit }))),
            React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.AuthMail),
            React.createElement(Form, { onSubmit: onAuthMail, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(Field, { name: "login", label: "".concat(Messages.Labels.UserName, "*"), showWarning: showWarning, validate: composeValidators(isRequired), placeholder: "".concat(Messages.Controls.UserName, "*"), component: BrandInput }),
                        React.createElement(Field, { name: "password", type: "password", label: Messages.Labels.Password, showWarning: showWarning, validate: composeValidators(isRequired), placeholder: Messages.Controls.Password, component: BrandInput }),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.login, onClick: function () { }, type: TButtonTypes.submit })))));
                } }),
            React.createElement(StyledUpdateMailNameWrapper, null, Messages.Labels.SetDomainSettings),
            React.createElement(Form, { onSubmit: onSetDomainSettings, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(Field, { name: "domain", label: Messages.Labels.Domain, showWarning: showWarning, validate: composeValidators(isRequired), placeholder: Messages.Controls.Domain, component: BrandInput }),
                        React.createElement(Field, { name: "imap", label: Messages.Labels.Imap, showWarning: showWarning, validate: composeValidators(isRequired), placeholder: Messages.Controls.Imap, component: BrandInput }),
                        React.createElement(Field, { name: "smtpTLS", label: Messages.Labels.SmtpTLS, showWarning: showWarning, validate: composeValidators(isRequired), placeholder: Messages.Controls.SmtpTLS, component: BrandInput }),
                        React.createElement(Field, { name: "smtpSSL", label: Messages.Labels.SmtpSSL, showWarning: showWarning, validate: composeValidators(isRequired), placeholder: Messages.Labels.SmtpSSL, component: BrandInput }),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.setDomainSettings, onClick: function () { }, type: TButtonTypes.submit })))));
                } }))))));
};
export default UpdateMailInbox;
