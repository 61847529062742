var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, call, put, takeEvery, } from 'redux-saga/effects';
import { dictionariesApi } from '@src/redux/dictionaries/api';
import { authActions } from '@src/modules/Auth/redux/reducer';
import { dictionariesActions } from './reducer';
import { DictionaryTypes } from './types';
var filterDictionary = function (obj) {
    var filteredDictionary = {};
    for (var key in obj) {
        if (obj[key]) {
            filteredDictionary[key] = obj[key];
        }
    }
    return filteredDictionary;
};
function getDictionaryWrap(request, action) {
    var dictionary_1, filteredDictionary, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4, call(request)];
            case 1:
                dictionary_1 = _a.sent();
                return [4, call(function () { return filterDictionary(dictionary_1); })];
            case 2:
                filteredDictionary = _a.sent();
                return [4, put(action(filteredDictionary))];
            case 3:
                _a.sent();
                return [3, 5];
            case 4:
                e_1 = _a.sent();
                console.error("".concat(request, " saga"));
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getBrandEntityDictionarySaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getDictionaryWrap(dictionariesApi.getBrandsDictionary, dictionariesActions.setBrandDictionary)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
function getChannelEntityDictionarySaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getDictionaryWrap(dictionariesApi.getChannelsDictionary, dictionariesActions.setChannelDictionary)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
function getGroupEntityDictionarySaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getDictionaryWrap(dictionariesApi.getGroupsDictionary, dictionariesActions.setGroupDictionary)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
function getTagEntityDictionarySaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getDictionaryWrap(dictionariesApi.getTagsDictionary, dictionariesActions.setTagDictionary)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
function getAgentEntityDictionarySaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getDictionaryWrap(dictionariesApi.getAgentsDictionary, dictionariesActions.setAgentDictionary)];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
export function getAllDictionaries() {
    var _a, _b, e_2;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 8, , 9]);
                _a = all;
                return [4, call(function () { return getDictionaryWrap(dictionariesApi.getBrandsDictionary, dictionariesActions.setBrandDictionary); })];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4, call(function () { return getDictionaryWrap(dictionariesApi.getChannelsDictionary, dictionariesActions.setChannelDictionary); })];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4, call(function () { return getDictionaryWrap(dictionariesApi.getGroupsDictionary, dictionariesActions.setGroupDictionary); })];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4, call(function () { return getDictionaryWrap(dictionariesApi.getTagsDictionary, dictionariesActions.setTagDictionary); })];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4, call(function () { return getDictionaryWrap(dictionariesApi.getAgentsDictionary, dictionariesActions.setAgentDictionary); })];
            case 5: return [4, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 6:
                _c.sent();
                return [4, put(authActions.setContentReady(true))];
            case 7:
                _c.sent();
                return [3, 9];
            case 8:
                e_2 = _c.sent();
                console.error(e_2, 'getAllDictionaries');
                return [3, 9];
            case 9: return [2];
        }
    });
}
export var dictionarySagas = [
    takeEvery(DictionaryTypes.GET_ALL_DICTIONARIES, getAllDictionaries),
    takeEvery(DictionaryTypes.GET_TAG_DICTIONARY, getTagEntityDictionarySaga),
    takeEvery(DictionaryTypes.GET_BRAND_DICTIONARY, getBrandEntityDictionarySaga),
    takeEvery(DictionaryTypes.GET_AGENT_DICTIONARY, getAgentEntityDictionarySaga),
    takeEvery(DictionaryTypes.GET_GROUP_DICTIONARY, getGroupEntityDictionarySaga),
    takeEvery(DictionaryTypes.GET_CHANNEL_DICTIONARY, getChannelEntityDictionarySaga),
];
