import { Messages } from '@public/locales/messages';
import { formatMessage } from './formatMessage';
export var composeValidators = function () {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (value) { return validators.reduce(function (error, validator) { return error || validator(value); }, undefined); };
};
export var isCorrectEmail = function (value) {
    var emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (emailRegEx.test(String(value).toLowerCase()))
        return undefined;
    return Messages.Errors.IncorrectEmail;
};
export var isSpaces = function (value) {
    var isSpaces = value === null || value === void 0 ? void 0 : value.split('').find(function (el) { return el === ' '; });
    if (!(isSpaces === ' '))
        return undefined;
    return Messages.Errors.FieldContainSpaces;
};
export var isRequired = function (value) {
    if (value)
        return undefined;
    return Messages.Warning.FieldIsRequired;
};
export var minLength = function (minLength) { return function (value) {
    if ((value === null || value === void 0 ? void 0 : value.length) < minLength && (value === null || value === void 0 ? void 0 : value.length) !== 0)
        return formatMessage(Messages.Errors.MinimumLength, [minLength]);
}; };
export var maxLength = function (maxLength) { return function (value) {
    if ((value === null || value === void 0 ? void 0 : value.length) > maxLength)
        return formatMessage(Messages.Errors.MaximumLength, [maxLength]);
}; };
export var passwordValidation = function (value) {
    var passwordRegExp = /(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*_]/g;
    if (passwordRegExp.test(value))
        return undefined;
    return Messages.Errors.PasswordValidation;
};
export var nameValidation = function (value) {
    var nameRegExp = /[0-9a-zA-Z!@#$%^&*_ ]/g;
    if (nameRegExp.test(value))
        return undefined;
    return Messages.Errors.NameValidation;
};
export var urlValidation = function (url) {
    var urlRegEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return urlRegEx.test(String(url).toLowerCase());
};
export var isNumberValue = function (value) {
    var urlRegEx = /\d+/g;
    if (urlRegEx.test(value))
        return undefined;
    return Messages.Errors.NumberValidation;
};
export var urlCorrect = function (url) {
    var urlRegEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (urlRegEx.test(url))
        return undefined;
    return Messages.Errors.IncorrectUrl;
};
export var invoiceNumberValidation = function (value) {
    var invoiceNumberRegExp = /[0-9a-zA-Z!@#$%^&*_ ]/g;
    if (invoiceNumberRegExp.test(value))
        return undefined;
    return Messages.Errors.InvoiceNumberValidation;
};
export var phoneValidation = function (value) {
    var phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    if (phoneRegExp.test(value))
        return undefined;
    return Messages.Errors.IncorrectPhone;
};
export var arrayToString = function (arr) { return arr.join(', '); };
export var lengthCreator = {
    minGroupNameLength: minLength(1),
    maxGroupNameLength: maxLength(30),
    minEmailLength: minLength(6),
    maxEmailLength: maxLength(254),
    minPasswordLength: minLength(5),
    maxPasswordLength: maxLength(50),
    minNameLength: minLength(2),
    maxNameLength: maxLength(40),
    minFirstNameLength: minLength(2),
    maxFirstNameLength: maxLength(40),
    minLastNameLength: minLength(2),
    maxLastNameLength: maxLength(40),
    minChannelNameLength: minLength(3),
    maxChannelNameLength: maxLength(30),
    minBrandNameLength: minLength(1),
    maxBrandNameLength: maxLength(50),
    minInvoiceNumberLength: minLength(1),
    maxInvoiceNumberLength: maxLength(30),
    minDescriptionGroup: minLength(1),
    maxDescriptionGroup: maxLength(200),
    minNameCategory: minLength(1),
    maxNameCategory: maxLength(50),
    minNameTag: minLength(1),
    maxNameTag: maxLength(30),
    minPhoneNumber: minLength(10),
    maxPhoneNumber: maxLength(14),
    minApplicationIDBulkGate: minLength(5),
    maxApplicationIDBulkGate: maxLength(5),
    minApplicationTokenBulkGate: minLength(50),
    maxApplicationTokenBulkGate: maxLength(50),
    minSenderIDBulkGate: minLength(4),
    maxSenderIDBulkGate: maxLength(4),
    minAccessKeyMessageBird: minLength(25),
    maxAccessKeyMessageBird: maxLength(25),
    minGreetingLength: minLength(3),
    maxGreetingLength: maxLength(256),
    minBotNameLength: minLength(1),
    maxBotNameLength: maxLength(30),
    minFastPhraseLength: minLength(1),
    maxFastPhraseLength: maxLength(2000),
    minTelegramIdLength: minLength(1),
    maxTelegramIdLength: maxLength(36),
};
