import React from 'react';
import { Inner, StyledProgressBar } from '@src/components/ProgressBar/styled';
import { useProgressBar } from '@src/components/ProgressBar/useProgressBar';
export var ProgressBar = function (_a) {
    var isFinished = _a.isFinished, _b = _a.step, step = _b === void 0 ? 10 : _b;
    var _c = useProgressBar({ step: step }), progress = _c.progress, setProgress = _c.setProgress;
    return (React.createElement(StyledProgressBar, null,
        React.createElement(Inner, { isFinished: isFinished, onClick: function () { return setProgress(progress + 5); } },
            React.createElement("span", { className: "progress-bar", style: { width: "".concat(isFinished ? 100 : progress, "%") } }))));
};
