var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import StyledAuthInput from './StyledAuthInput';
import './AuthInput.less';
var AuthInput = function (_a) {
    var register = _a.register, placeholder = _a.placeholder, style = _a.style, maxLength = _a.maxLength;
    return (React.createElement(StyledAuthInput, null,
        React.createElement("input", __assign({ style: style }, register, { autoComplete: "off", className: "input-form", maxLength: maxLength, placeholder: placeholder }))));
};
export default AuthInput;
