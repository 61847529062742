export var Locales;
(function (Locales) {
    Locales["en"] = "en";
    Locales["ru"] = "ru";
    Locales["de"] = "de";
    Locales["es"] = "es";
    Locales["fr"] = "fr";
    Locales["pl"] = "pl";
    Locales["pt"] = "pt";
    Locales["vi"] = "vi";
    Locales["it"] = "it";
    Locales["ko"] = "ko";
    Locales["ar"] = "ar";
    Locales["he"] = "he";
    Locales["ua"] = "ua";
    Locales["hu"] = "hu";
})(Locales || (Locales = {}));
