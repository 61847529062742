var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, scrollStyles } from '@src/app/styled';
var DODGER_BLUE = COLORS.DODGER_BLUE, WHITE = COLORS.WHITE;
export var StyledAgentReportWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 330px;\n  margin-top: -20px;\n  overflow: auto;\n"], ["\n  width: 330px;\n  margin-top: -20px;\n  overflow: auto;\n"])));
export var InnerStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 300px;\n  color: ", ";\n  .report__agent-name {\n    font-size: 14px;\n    margin-bottom: 24px;\n    text-align: center;\n  }\n\n  .report__select-date {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    margin-bottom: 13px;\n    &_month {\n      width: 120px;\n    }\n    &_year {\n      width: 105px;\n    }\n  }\n\n  .report__statuses-filter {\n    display: flex;\n    align-content: center;\n    justify-content: space-evenly;\n    margin-bottom: 13px;\n  }\n  width: 100%;\n  overflow-x: auto;\n  ", "\n"], ["\n  min-height: 300px;\n  color: ", ";\n  .report__agent-name {\n    font-size: 14px;\n    margin-bottom: 24px;\n    text-align: center;\n  }\n\n  .report__select-date {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    margin-bottom: 13px;\n    &_month {\n      width: 120px;\n    }\n    &_year {\n      width: 105px;\n    }\n  }\n\n  .report__statuses-filter {\n    display: flex;\n    align-content: center;\n    justify-content: space-evenly;\n    margin-bottom: 13px;\n  }\n  width: 100%;\n  overflow-x: auto;\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledGetStatsBtn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: ", ";\n    cursor: pointer;\n    width: 90px;\n    height: 100%;\n    min-height: 32px;\n    border-radius: 4px;\n    background: ", ";\n\n    button {\n      height: 100%;\n      background: ", ";\n      width: 100%;\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: ", ";\n    cursor: pointer;\n    width: 90px;\n    height: 100%;\n    min-height: 32px;\n    border-radius: 4px;\n    background: ", ";\n\n    button {\n      height: 100%;\n      background: ", ";\n      width: 100%;\n    }\n\n    &:hover {\n      opacity: 0.9;\n    }\n  }\n"])), WHITE, DODGER_BLUE, DODGER_BLUE);
var templateObject_1, templateObject_2, templateObject_3;
