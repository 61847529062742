import React from 'react';
import Icon from '@src/components/Icon';
import { icons } from '@public/images';
import { StyledInboxItemWrapper } from '@src/modules/Pages/Brands/BrandSettings/InboxItem/styled';
var InboxItem = function (props) {
    var name = props.name, onClickDelete = props.onClickDelete, onClickEdit = props.onClickEdit;
    return (React.createElement(StyledInboxItemWrapper, null,
        React.createElement("div", { className: "inbox-item-text" }, name),
        React.createElement("div", { className: "inbox-item-icon-box" },
            React.createElement(Icon, { src: icons.editPhrases, onClick: onClickEdit }),
            React.createElement(Icon, { src: icons.deletePhrases, onClick: onClickDelete }))));
};
export default InboxItem;
