var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS } from '@src/app/styled';
var WHITE = COLORS.WHITE;
export var SelectedFilterWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    position: relative;\n  }\n"], ["\n  && {\n    position: relative;\n  }\n"])));
export var SelectFilterDataContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    position: absolute;\n    top: 55px;\n    left: 15px;\n    z-index: 999;\n    background: ", ";\n  }\n"], ["\n  && {\n    position: absolute;\n    top: 55px;\n    left: 15px;\n    z-index: 999;\n    background: ", ";\n  }\n"])), WHITE);
var templateObject_1, templateObject_2;
