import { connect } from 'react-redux';
import GroupsPage from '@src/modules/Pages/Groups/Groups';
import { appStore } from '@src/redux/app/selector';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    isModalOpenCreateGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_GROUPS_MODAL),
    isModalOpenUpdateGroups: modalWindowsStore.getIsModalOpen(state, modalTypes.UPDATE_GROUPS_MODAL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_GROUPS_MODAL, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
