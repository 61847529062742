import moment from 'moment';
import { convertDate } from '@src/utils/convertDate';
export var convertDateToArray = function (stringDate) {
    var _a = convertDate(stringDate), day = _a.day, year = _a.year, month = _a.month, hours = _a.hours, minutes = _a.minutes;
    return [+year, +month, +day, +hours, +minutes];
};
export var getObjectTime = function (date) { return ({ dateFormat: (convertDateToArray(date)) }); };
export var dialogDuration = function (dialog) {
    if (!dialog.created_at || !dialog.lastActivity)
        return;
    var lastMsg = getObjectTime(dialog === null || dialog === void 0 ? void 0 : dialog.lastActivity);
    var firstMsg = getObjectTime(dialog === null || dialog === void 0 ? void 0 : dialog.created_at);
    var defaultTime = '1m';
    var days = moment(lastMsg.dateFormat).diff(moment(firstMsg.dateFormat), 'days');
    var hours = moment(lastMsg.dateFormat).diff(moment(firstMsg.dateFormat), 'hours');
    var minutes = moment(lastMsg.dateFormat).diff(moment(firstMsg.dateFormat), 'minutes');
    if (hours)
        minutes %= (hours * 60);
    if (days)
        hours %= (days * 24);
    days = days ? "".concat(days, "d ") : '';
    hours = hours ? "".concat(hours, "h ") : '';
    minutes = minutes ? "".concat(minutes, "m ") : '';
    return "".concat(days).concat(hours).concat(minutes) || defaultTime;
};
