var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { takeEvery, call, fork, put, select, delay, } from 'redux-saga/effects';
import { difference } from 'lodash';
import { Messages } from '@public/locales/messages';
import { groupsApi } from '@src/modules/Pages/Groups/redux/api';
import { filterBrokenAgent } from '@src/utils/sagaHelpers/filterBrokenAgent';
import { getUserRole } from '@src/redux/app/saga';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
import { errorNotification, successNotification } from '@src/redux/notification/saga';
import groupsStore from '@src/modules/Pages/Groups/redux/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { entitiesActions } from '@src/redux/entities/reducers';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { groupsActions, GroupsPageTypes } from '@src/modules/Pages/Groups/redux/reducer';
function executeRequests(channelsArray, id, request) {
    var channelsArray_1, channelsArray_1_1, channel, e_1_1;
    var e_1, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, 7, 8]);
                channelsArray_1 = __values(channelsArray), channelsArray_1_1 = channelsArray_1.next();
                _b.label = 1;
            case 1:
                if (!!channelsArray_1_1.done) return [3, 5];
                channel = channelsArray_1_1.value;
                return [4, call(request, channel, id)];
            case 2:
                _b.sent();
                return [4, delay(100)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                channelsArray_1_1 = channelsArray_1.next();
                return [3, 1];
            case 5: return [3, 8];
            case 6:
                e_1_1 = _b.sent();
                e_1 = { error: e_1_1 };
                return [3, 8];
            case 7:
                try {
                    if (channelsArray_1_1 && !channelsArray_1_1.done && (_a = channelsArray_1.return)) _a.call(channelsArray_1);
                }
                finally { if (e_1) throw e_1.error; }
                return [7];
            case 8: return [2];
        }
    });
}
function createAgentGroups(_a) {
    var groupName, description, accessLevel, whiteTagsList, channelId, group, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                groupName = payload.groupName, description = payload.description, accessLevel = payload.accessLevel, whiteTagsList = payload.whiteTagsList, channelId = payload.channel;
                group = {
                    leadId: [],
                    description: description,
                    accessLevel: accessLevel,
                    name: groupName,
                    usefulPhrases: [],
                    whiteListTags: whiteTagsList,
                };
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4, call(groupsApi.createGroup, group, channelId)];
            case 2:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.GroupCreated)];
            case 3:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_2 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_2.text)];
            case 6:
                _b.sent();
                console.error(e_2);
                return [3, 7];
            case 7: return [4, put(setLoading(GroupsPageTypes.CREATE_AGENT_GROUPS, false))];
            case 8:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_GROUPS_MODAL, null))];
            case 9:
                _b.sent();
                return [2];
        }
    });
}
function showGroupsSettings(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, put(modalWindowsActions.toggleModal(modalTypes.UPDATE_GROUPS_MODAL, payload))];
            case 1:
                _b.sent();
                return [4, call(updateGroupsSettings, payload, false)];
            case 2:
                _b.sent();
                return [4, put(setLoading(GroupsPageTypes.SHOW_GROUPS_SETTINGS, false))];
            case 3:
                _b.sent();
                return [2];
        }
    });
}
function getCurrentGroup(id) {
    var currentGroup, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(groupsApi.getGroupById, id)];
            case 1:
                currentGroup = _a.sent();
                return [4, put(groupsActions.setCurrentGroup(currentGroup))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_3 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_3.text)];
            case 4:
                _a.sent();
                console.error(e_3.text);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getCurrentGroupAgents(id) {
    var identificationRole, currentGroupsAgents, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getUserRole()];
            case 1:
                identificationRole = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4, call(groupsApi.getAgentsByGroupId, identificationRole, id)];
            case 3:
                currentGroupsAgents = (_a.sent()).data;
                return [4, put(groupsActions.setCurrentGroupsAgents(currentGroupsAgents))];
            case 4:
                _a.sent();
                return [3, 7];
            case 5:
                e_4 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_4.text)];
            case 6:
                _a.sent();
                console.error(e_4.text);
                return [3, 7];
            case 7: return [2];
        }
    });
}
function getAssignedAndUnassignedAgentsToGroup(id) {
    var _a, assigned, unAssigned, e_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 8]);
                return [4, call(groupsApi.getAssignedAndUnassignedAgentsToGroup, id)];
            case 1:
                _a = _b.sent(), assigned = _a.assigned, unAssigned = _a.unAssigned;
                return [4, call(filterBrokenAgent, assigned)];
            case 2:
                assigned = _b.sent();
                return [4, call(filterBrokenAgent, unAssigned)];
            case 3:
                unAssigned = _b.sent();
                return [4, put(groupsActions.setAssignedAgentsToGroup(assigned))];
            case 4:
                _b.sent();
                return [4, put(groupsActions.setUnassignedAgentsToGroup(unAssigned))];
            case 5:
                _b.sent();
                return [3, 8];
            case 6:
                e_5 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_5.text)];
            case 7:
                _b.sent();
                console.error(e_5.text);
                return [3, 8];
            case 8: return [2];
        }
    });
}
function getAssignedAndUnassignedTagsToGroup(id) {
    var _a, assigned, unAssigned, e_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, call(groupsApi.getAssignedAndUnassignedTagsToGroup, id)];
            case 1:
                _a = _b.sent(), assigned = _a.assigned, unAssigned = _a.unAssigned;
                return [4, put(groupsActions.setAssignedTagsToGroup(assigned))];
            case 2:
                _b.sent();
                return [4, put(groupsActions.setUnassignedTagsToGroup(unAssigned))];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                e_6 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_6.text)];
            case 5:
                _b.sent();
                console.error(e_6.text);
                return [3, 6];
            case 6: return [2];
        }
    });
}
function getAssignedAndUnassignedChannelsToGroup(id) {
    var _a, assigned, unAssigned, e_7;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, call(groupsApi.getAssignedAndUnassignedChannelsToGroup, id)];
            case 1:
                _a = _b.sent(), assigned = _a.assigned, unAssigned = _a.unAssigned;
                return [4, put(groupsActions.setAssignedChannelsToGroup(assigned))];
            case 2:
                _b.sent();
                return [4, put(groupsActions.setUnassignedChannelsToGroup(unAssigned))];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                e_7 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_7.text)];
            case 5:
                _b.sent();
                console.error(e_7.text);
                return [3, 6];
            case 6: return [2];
        }
    });
}
function updateGroupsSettings(id, isUpdateTable) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, call(getAssignedAndUnassignedTagsToGroup, id)];
            case 1:
                _a.sent();
                return [4, call(getAssignedAndUnassignedAgentsToGroup, id)];
            case 2:
                _a.sent();
                return [4, call(getAssignedAndUnassignedChannelsToGroup, id)];
            case 3:
                _a.sent();
                return [4, call(getCurrentGroup, id)];
            case 4:
                _a.sent();
                return [4, call(getCurrentGroupAgents, id)];
            case 5:
                _a.sent();
                if (!isUpdateTable) return [3, 7];
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [2];
        }
    });
}
function removeAgentFromGroup(_a) {
    var groupId, agentsId, e_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                groupId = payload.groupId, agentsId = payload.agentsId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4, call(groupsApi.deleteAgentsFromGroup, groupId, [agentsId])];
            case 2:
                _b.sent();
                return [4, call(updateGroupsSettings, groupId, true)];
            case 3:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.ChangedAssignedAgentsInGroups)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_8 = _b.sent();
                return [4, fork(errorNotification, e_8.text || Messages.Errors.ServerError)];
            case 6:
                _b.sent();
                console.error(e_8.text);
                return [3, 7];
            case 7: return [4, put(setLoading(GroupsPageTypes.REMOVE_AGENT_FROM_GROUP, false))];
            case 8:
                _b.sent();
                return [2];
        }
    });
}
function removeOrAddAgentsFromGroup(_a) {
    var agentsGroups, id, newAgents, currentAgents, deleteAgents, addAgents, e_9;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(groupsStore.getAssignedAgentsToGroup)];
            case 1:
                agentsGroups = _b.sent();
                return [4, select(groupsStore.getCurrentGroup)];
            case 2:
                id = (_b.sent()).id;
                newAgents = payload.map(function (el) { return el.value; });
                currentAgents = agentsGroups.map(function (el) { return el.id; });
                deleteAgents = difference(currentAgents, newAgents);
                addAgents = difference(newAgents, currentAgents);
                _b.label = 3;
            case 3:
                _b.trys.push([3, 10, , 12]);
                if (!(deleteAgents.length > 0)) return [3, 5];
                return [4, call(groupsApi.deleteAgentsFromGroup, id, deleteAgents)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!(addAgents.length > 0)) return [3, 7];
                return [4, call(groupsApi.addAgentsToGroup, id, addAgents)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4, fork(successNotification, Messages.Success.ChangedAssignedAgentsInGroups)];
            case 8:
                _b.sent();
                return [4, call(updateGroupsSettings, id, true)];
            case 9:
                _b.sent();
                return [3, 12];
            case 10:
                e_9 = _b.sent();
                return [4, fork(errorNotification, e_9.text || Messages.Errors.ServerError)];
            case 11:
                _b.sent();
                console.error(e_9);
                return [3, 12];
            case 12: return [4, put(setLoading(GroupsPageTypes.REMOVE_OR_ADD_AGENTS_FROM_GROUP, false))];
            case 13:
                _b.sent();
                return [2];
        }
    });
}
function updateCurrentGroup(_a) {
    var groupName, description, accessLevel, currentGroups, group, e_10;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                groupName = payload.groupName, description = payload.description, accessLevel = payload.accessLevel;
                return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                currentGroups = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 7, , 9]);
                return [4, call(groupsApi.updateGroup, __assign(__assign({}, currentGroups), { accessLevel: accessLevel, description: description, name: groupName }))];
            case 3:
                group = _b.sent();
                return [4, put(groupsActions.setCurrentGroup(group))];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.ChangedAssignedAgentsInGroups)];
            case 5:
                _b.sent();
                return [4, call(updateGroupsSettings, currentGroups.id, true)];
            case 6:
                _b.sent();
                return [3, 9];
            case 7:
                e_10 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_10.text)];
            case 8:
                _b.sent();
                console.error(e_10);
                return [3, 9];
            case 9: return [4, put(setLoading(GroupsPageTypes.UPDATE_CURRENT_GROUP, false))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function removeOrAddChannelsFromGroup(_a) {
    var id, channelsGroups, newChannels, currentChannels, deleteChannels, addChannels, e_11;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_b.sent()).id;
                return [4, select(groupsStore.getAssignedChannelsToGroup)];
            case 2:
                channelsGroups = _b.sent();
                newChannels = payload.map(function (el) { return el.value; });
                currentChannels = channelsGroups.map(function (el) { return el.id; });
                deleteChannels = difference(currentChannels, newChannels);
                addChannels = difference(newChannels, currentChannels);
                _b.label = 3;
            case 3:
                _b.trys.push([3, 10, , 12]);
                if (!(deleteChannels.length > 0)) return [3, 5];
                return [4, call(executeRequests, deleteChannels, id, groupsApi.deleteChannelsFromGroup)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!(addChannels.length > 0)) return [3, 7];
                return [4, call(executeRequests, addChannels, id, groupsApi.addGroupsToChannel)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4, call(updateGroupsSettings, id, true)];
            case 8:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.ChangedAssignedChannelsInGroups)];
            case 9:
                _b.sent();
                return [3, 12];
            case 10:
                e_11 = _b.sent();
                return [4, fork(errorNotification, e_11.text || Messages.Errors.ServerError)];
            case 11:
                _b.sent();
                console.error(e_11);
                return [3, 12];
            case 12: return [4, put(setLoading(GroupsPageTypes.REMOVE_OR_ADD_CHANNELS_FROM_GROUP, false))];
            case 13:
                _b.sent();
                return [2];
        }
    });
}
function removeOrAddTagsFromGroup(_a) {
    var id, tagsGroups, newTags, currentTags, deleteTags, addTags, e_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_b.sent()).id;
                return [4, select(groupsStore.getAssignedTagsToGroup)];
            case 2:
                tagsGroups = _b.sent();
                newTags = payload.map(function (el) { return el.value; });
                currentTags = tagsGroups.map(function (el) { return el.id; });
                deleteTags = difference(currentTags, newTags);
                addTags = difference(newTags, currentTags);
                _b.label = 3;
            case 3:
                _b.trys.push([3, 10, , 12]);
                if (!(deleteTags.length > 0)) return [3, 5];
                return [4, call(groupsApi.deleteTagsFromGroup, id, deleteTags)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!(addTags.length > 0)) return [3, 7];
                return [4, call(groupsApi.addTagsToGroup, id, addTags)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4, fork(successNotification, Messages.Success.ChangedAssignedTagsInGroups)];
            case 8:
                _b.sent();
                return [4, call(updateGroupsSettings, id, true)];
            case 9:
                _b.sent();
                return [3, 12];
            case 10:
                e_12 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_12.text)];
            case 11:
                _b.sent();
                console.error(e_12);
                return [3, 12];
            case 12: return [4, put(setLoading(GroupsPageTypes.REMOVE_OR_ADD_TAGS_FROM_GROUP, false))];
            case 13:
                _b.sent();
                return [2];
        }
    });
}
function createNewCategory(_a) {
    var id, response, e_13;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_b.sent()).id;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4, call(groupsApi.createCategory, payload, id)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 7];
                return [4, call(getCurrentGroup, id)];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.CreateNewCategory)];
            case 5:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3, 10];
            case 8:
                e_13 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_13.text)];
            case 9:
                _b.sent();
                console.error(e_13);
                return [3, 10];
            case 10: return [4, put(setLoading(GroupsPageTypes.CREATE_NEW_CATEGORY, false))];
            case 11:
                _b.sent();
                return [2];
        }
    });
}
function createNewPhrase(_a) {
    var text, categoryId, id, response, e_14;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                text = payload.text, categoryId = payload.categoryId;
                return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_b.sent()).id;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4, call(groupsApi.createPhrase, text, categoryId)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 7];
                return [4, call(getCurrentGroup, id)];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.CreateNewPhrase)];
            case 5:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3, 10];
            case 8:
                e_14 = _b.sent();
                return [4, fork(errorNotification, e_14.text || Messages.Errors.ServerError)];
            case 9:
                _b.sent();
                console.error(e_14);
                return [3, 10];
            case 10: return [4, put(setLoading(GroupsPageTypes.CREATE_NEW_PHRASE, false))];
            case 11:
                _b.sent();
                return [2];
        }
    });
}
function deleteCategory(_a) {
    var id, response, e_15;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_b.sent()).id;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4, call(groupsApi.deleteCategory, payload)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 7];
                return [4, call(getCurrentGroup, id)];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.DeleteCategory)];
            case 5:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3, 10];
            case 8:
                e_15 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_15.text)];
            case 9:
                _b.sent();
                console.error(e_15);
                return [3, 10];
            case 10: return [4, put(setLoading(GroupsPageTypes.DELETE_CATEGORY, false))];
            case 11:
                _b.sent();
                return [2];
        }
    });
}
function deletePhrase(_a) {
    var phraseId, categoryId, id, response, e_16;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                phraseId = payload.phraseId, categoryId = payload.categoryId;
                return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_b.sent()).id;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4, call(groupsApi.deletePhrase, phraseId, categoryId)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 7];
                return [4, call(getCurrentGroup, id)];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.DeletePhrase)];
            case 5:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3, 10];
            case 8:
                e_16 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_16.text)];
            case 9:
                _b.sent();
                console.error(e_16);
                return [3, 10];
            case 10: return [4, put(setLoading(GroupsPageTypes.DELETE_PHRASE, false))];
            case 11:
                _b.sent();
                return [2];
        }
    });
}
function updatePhrase(_a) {
    var id, text, categoryId, croupId, response, e_17;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, text = payload.text, categoryId = payload.categoryId;
                return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                croupId = (_b.sent()).id;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4, call(groupsApi.updatePhrase, id, text, categoryId)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 7];
                return [4, call(getCurrentGroup, croupId)];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.UpdatePhrase)];
            case 5:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3, 10];
            case 8:
                e_17 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_17.text)];
            case 9:
                _b.sent();
                console.error(e_17);
                return [3, 10];
            case 10: return [4, put(modalWindowsActions.toggleModal(modalTypes.EDIT_PHRASE_GROUPS, null))];
            case 11:
                _b.sent();
                return [4, put(setLoading(GroupsPageTypes.UPDATE_PHRASE, false))];
            case 12:
                _b.sent();
                return [2];
        }
    });
}
function updateCategory(_a) {
    var categoryId, name, id, response, e_18;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                categoryId = payload.categoryId, name = payload.name;
                return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_b.sent()).id;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4, call(groupsApi.updateCategory, name, categoryId)];
            case 3:
                response = _b.sent();
                if (!response) return [3, 7];
                return [4, call(getCurrentGroup, id)];
            case 4:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.EditCategory)];
            case 5:
                _b.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3, 10];
            case 8:
                e_18 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_18.text)];
            case 9:
                _b.sent();
                console.error(e_18);
                return [3, 10];
            case 10: return [4, put(setLoading(GroupsPageTypes.UPDATE_CATEGORY, false))];
            case 11:
                _b.sent();
                return [2];
        }
    });
}
function deleteAllCategory() {
    var id, response, e_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, select(groupsStore.getCurrentGroup)];
            case 1:
                id = (_a.sent()).id;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4, call(groupsApi.deleteAllCategories, id)];
            case 3:
                response = _a.sent();
                if (!response) return [3, 7];
                return [4, call(getCurrentGroup, id)];
            case 4:
                _a.sent();
                return [4, fork(successNotification, Messages.Success.DeleteAllCategory)];
            case 5:
                _a.sent();
                return [4, put(entitiesActions.updateTableData())];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3, 10];
            case 8:
                e_19 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_19.text)];
            case 9:
                _a.sent();
                console.error(e_19);
                return [3, 10];
            case 10: return [4, put(setLoading(GroupsPageTypes.DELETE_ALL_CATEGORY, false))];
            case 11:
                _a.sent();
                return [2];
        }
    });
}
export var groupsPageSagas = [
    takeEvery(GroupsPageTypes.CREATE_AGENT_GROUPS, loaderWrap(createAgentGroups, false)),
    takeEvery(GroupsPageTypes.UPDATE_CURRENT_GROUP, loaderWrap(updateCurrentGroup, false)),
    takeEvery(GroupsPageTypes.SHOW_GROUPS_SETTINGS, loaderWrap(showGroupsSettings, false)),
    takeEvery(GroupsPageTypes.REMOVE_AGENT_FROM_GROUP, loaderWrap(removeAgentFromGroup, false)),
    takeEvery(GroupsPageTypes.REMOVE_OR_ADD_TAGS_FROM_GROUP, loaderWrap(removeOrAddTagsFromGroup, false)),
    takeEvery(GroupsPageTypes.REMOVE_OR_ADD_AGENTS_FROM_GROUP, loaderWrap(removeOrAddAgentsFromGroup, false)),
    takeEvery(GroupsPageTypes.REMOVE_OR_ADD_CHANNELS_FROM_GROUP, loaderWrap(removeOrAddChannelsFromGroup, false)),
    takeEvery(GroupsPageTypes.CREATE_NEW_PHRASE, loaderWrap(createNewPhrase, false)),
    takeEvery(GroupsPageTypes.CREATE_NEW_CATEGORY, loaderWrap(createNewCategory, false)),
    takeEvery(GroupsPageTypes.DELETE_CATEGORY, loaderWrap(deleteCategory, false)),
    takeEvery(GroupsPageTypes.DELETE_PHRASE, loaderWrap(deletePhrase, false)),
    takeEvery(GroupsPageTypes.UPDATE_PHRASE, loaderWrap(updatePhrase, false)),
    takeEvery(GroupsPageTypes.UPDATE_CATEGORY, loaderWrap(updateCategory, false)),
    takeEvery(GroupsPageTypes.DELETE_ALL_CATEGORY, loaderWrap(deleteAllCategory, false)),
];
