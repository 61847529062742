import { Messages } from '@public/locales/messages';
import { tableRenderers } from '@src/components/tableRenderers';
var _a = Messages.Pages.Reports, ByMessengersTab = _a.ByMessengersTab, ByAgentsTab = _a.ByAgentsTab, ByGroupsTab = _a.ByGroupsTab;
export var reportMessengerColumns = [
    {
        title: ByMessengersTab.Columns.Messenger,
        key: 'name',
        sortingEnabled: true,
        render: tableRenderers.MessengerRenderer,
    },
    {
        title: ByMessengersTab.Columns.ReceivedDialogs,
        key: 'receivedCount',
        render: tableRenderers.TextRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByMessengersTab.Columns.PositiveDialogs,
        key: 'positivePercent',
        render: tableRenderers.TextRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByMessengersTab.Columns.NegativeDialogs,
        key: 'negativePercent',
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByMessengersTab.Columns.UnratedDialogs,
        key: 'unratedPercent',
        render: tableRenderers.TextRenderer,
        sortingEnabled: true,
        styles: {
            width: '195px',
            justifyContent: 'center',
        },
    },
    {
        title: ByMessengersTab.Columns.AvgResponseTime,
        key: 'avgResponseTime',
        sortingEnabled: true,
        render: tableRenderers.TimeRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
];
export var reportAgentsColumns = [
    {
        title: ByAgentsTab.Columns.agentFullName,
        key: 'agentName',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ByAgentsTab.Columns.agentSystemId,
        key: 'id',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ByAgentsTab.Columns.brandName,
        key: 'brandName',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ByAgentsTab.Columns.receivedDialogs,
        key: 'receivedCount',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.dialogsTelegram,
        key: 'telegram',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.dialogsWebChat,
        key: 'smartbot',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.dialogsViber,
        key: 'viber',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.dialogsWhatsApp,
        key: 'whatsApp',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.dialogsFacebook,
        key: 'messenger',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.dialogsEmail,
        key: 'email',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.dialogsSms,
        key: 'sms',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.positiveDialogs,
        key: 'positivePercent',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.negativeDialogs,
        key: 'negativePercent',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            width: '195px',
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.unratedDialogs,
        key: 'unratedPercent',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            width: '195px',
            justifyContent: 'center',
        },
    },
    {
        title: ByAgentsTab.Columns.avgResponseTime,
        key: 'avgResponseTime',
        sortingEnabled: true,
        render: tableRenderers.TimeRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
];
export var reportGroupsColumns = [
    {
        title: ByGroupsTab.Columns.brandName,
        key: 'brandName',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ByGroupsTab.Columns.groupName,
        key: 'groupName',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ByGroupsTab.Columns.groupSystemId,
        key: 'groupId',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ByGroupsTab.Columns.receivedDialogs,
        key: 'receivedCount',
        sortingEnabled: true,
        render: tableRenderers.TimeRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByGroupsTab.Columns.positiveDialogs,
        key: 'positivePercent',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByGroupsTab.Columns.negativeDialogs,
        key: 'negativePercent',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByGroupsTab.Columns.unratedDialogs,
        key: 'unratedPercent',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: ByGroupsTab.Columns.avgResponseTime,
        key: 'avgResponseTime',
        sortingEnabled: true,
        render: tableRenderers.TimeRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
];
