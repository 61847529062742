var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledAgentRolesWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n  }\n"], ["\n  && {\n  }\n"])));
export var StyledAgentRolesHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    border-radius: 4px 4px 0 0;\n    display: flex;\n    padding: 5px 115px 5px 20px;\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  && {\n    border-radius: 4px 4px 0 0;\n    display: flex;\n    padding: 5px 115px 5px 20px;\n    color: ", ";\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.tableHeader;
});
export var StyledAgentRolesHeaderContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    max-width: 370px;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    max-width: 370px;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n"])));
export var StyledAgentRolesHeaderElement = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    width: 100px;\n    text-align: center;\n    padding: 0 5px;\n  }\n"], ["\n  && {\n    width: 100px;\n    text-align: center;\n    padding: 0 5px;\n  }\n"])));
export var StyledAgentRolesContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n  }\n"], ["\n  && {\n  }\n"])));
export var StyledRowNumber = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    max-width: 20px;\n    width: 100%;\n  }\n"], ["\n  && {\n    max-width: 20px;\n    width: 100%;\n  }\n"])));
export var StyledAgentRole = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    border-bottom: 1px solid ", ";\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 5px 5px;\n    color: ", ";\n  }\n"], ["\n  && {\n    border-bottom: 1px solid ", ";\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 5px 5px;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledAgentRoleWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n    display: flex;\n    max-width: 400px;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    display: flex;\n    max-width: 400px;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n  }\n"])));
export var StyledRole = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    padding: 5px;\n    max-width: 100px;\n    width: 100%;\n  }\n"], ["\n  && {\n    padding: 5px;\n    max-width: 100px;\n    width: 100%;\n  }\n"])));
export var StyledMakeOrRemoveSuperAdminWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    display: flex;\n    padding: 10px 0;\n    align-items: center;\n    justify-content: space-between;\n\n    &.make-or-remove-super-admin-text {\n      padding: 5px;\n    }\n  }\n"], ["\n  && {\n    color: ", ";\n    display: flex;\n    padding: 10px 0;\n    align-items: center;\n    justify-content: space-between;\n\n    &.make-or-remove-super-admin-text {\n      padding: 5px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledMakeBrandAdminWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    padding-bottom: 20px;\n    & .make-brand-admin-text {\n      padding: 5px 0;\n    }\n  }\n"], ["\n  && {\n    color: ", ";\n    padding-bottom: 20px;\n    & .make-brand-admin-text {\n      padding: 5px 0;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
