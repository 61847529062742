var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest, fork, select, } from 'redux-saga/effects';
import { IPages } from '@src/global-interfaces';
import { IUserRole } from '@src/DTO/entities/Auth';
import { getAuthCookie, setAuthCookie } from '@src/utils/cookie';
import { authApi } from '@src/modules/Auth/redux/api';
import { system } from '@src/constants/objects';
import { redirect } from '@src/utils/history';
import Cookies from 'js-cookie';
import { getAppLang, Messages, setAppLang } from '@public/locales/messages';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
import { getAllDictionaries } from '@src/redux/dictionaries/saga';
import { appActions } from '@src/redux/app/reducer';
import { errorNotification, successNotification, warningNotification } from '@src/redux/notification/saga';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import getAllUrlParams from '@src/utils/getAllUrlParams';
import { getCountAgentsNewBeesWorker } from '@src/modules/Pages/Agents/redux/saga';
import { authStore } from '@src/modules/Auth/redux/selector';
import { authActions } from './reducer';
import { AuthTypes, } from './types';
var _a = system.PAGE_PATHS, LOGIN = _a.LOGIN, RESET_PASSWORD = _a.RESET_PASSWORD, _b = system.AUTH_PROVIDERS, GOOGLE = _b.GOOGLE, FACEBOOK = _b.FACEBOOK;
function initApp(action) {
    var pathName, resetPasswordToken, accessToken, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                pathName = action.payload;
                return [4, call(getAllUrlParams)];
            case 1:
                resetPasswordToken = _a.sent();
                setAppLang(getAppLang() || 'en');
                accessToken = getAuthCookie();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 8]);
                if (!resetPasswordToken.token) return [3, 4];
                return [4, call(redirect, "".concat(RESET_PASSWORD, "?token=").concat(resetPasswordToken.token))];
            case 3:
                _a.sent();
                return [2];
            case 4:
                if (!accessToken) {
                    redirect(LOGIN);
                    return [2];
                }
                return [4, authPreparing(accessToken, pathName)];
            case 5:
                _a.sent();
                return [3, 8];
            case 6:
                e_1 = _a.sent();
                return [4, call(errorNotification, e_1.text)];
            case 7:
                _a.sent();
                return [3, 8];
            case 8: return [2];
        }
    });
}
function authPreparing(accessToken, pathName) {
    var identificationRole, roles, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                setAuthCookie(accessToken);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 17, , 19]);
                return [4, getUserRole(accessToken)];
            case 2:
                identificationRole = _a.sent();
                if (!!identificationRole) return [3, 4];
                return [4, put(authActions.setContentReady(true))];
            case 3:
                _a.sent();
                return [2];
            case 4:
                if (!(identificationRole !== IUserRole.newbie && identificationRole !== IUserRole.regular)) return [3, 12];
                return [4, put(authActions.setIdentificationRole(identificationRole))];
            case 5:
                _a.sent();
                return [4, call(getAllDictionaries)];
            case 6:
                _a.sent();
                return [4, saveCurrentPage(getPageFromQuery(pathName))];
            case 7:
                _a.sent();
                return [4, call(redirect, pathName)];
            case 8:
                _a.sent();
                return [4, select(authStore.roles)];
            case 9:
                roles = _a.sent();
                if (!roles.find(function (el) { return el.role === 'superAdmin'; })) return [3, 11];
                return [4, fork(getCountAgentsNewBeesWorker)];
            case 10:
                _a.sent();
                _a.label = 11;
            case 11: return [3, 16];
            case 12: return [4, put(setLoading(AuthTypes.REGISTER, false))];
            case 13:
                _a.sent();
                return [4, warningNotification(Messages.Warning.AgentCreate, 10000)];
            case 14:
                _a.sent();
                return [4, logout()];
            case 15:
                _a.sent();
                _a.label = 16;
            case 16: return [3, 19];
            case 17:
                e_2 = _a.sent();
                return [4, call(errorNotification, e_2.text)];
            case 18:
                _a.sent();
                return [3, 19];
            case 19: return [2];
        }
    });
}
function getUserRole(accessToken) {
    var user, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 7]);
                return [4, call(authApi.getUser, accessToken)];
            case 1:
                user = _a.sent();
                return [4, put(appActions.setUser(user))];
            case 2:
                _a.sent();
                return [4, getUserRoles(accessToken, user.id)];
            case 3: return [2, _a.sent()];
            case 4:
                e_3 = _a.sent();
                return [4, logout()];
            case 5:
                _a.sent();
                return [4, call(errorNotification, Messages.Errors.TokenExpired)];
            case 6:
                _a.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
function getUserRoles(accessToken, userId) {
    var userRoles, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, authApi.getUserRoles(accessToken, userId)];
            case 1:
                userRoles = (_a.sent()).data;
                return [4, put(authActions.setUserRoles(userRoles))];
            case 2:
                _a.sent();
                return [2, getRole(userRoles)];
            case 3:
                e_4 = _a.sent();
                return [4, call(errorNotification, e_4.text)];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
function loginSocial(action) {
    var _a, code, serviceName, serviceUrl, responseLogin, accessToken, e_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 7]);
                _a = action.payload, code = _a.code, serviceName = _a.serviceName;
                return [4, getLoginService(serviceName)];
            case 1:
                serviceUrl = _b.sent();
                return [4, call(serviceUrl, code)];
            case 2:
                responseLogin = _b.sent();
                accessToken = responseLogin.accessToken;
                return [4, authPreparing(accessToken, '')];
            case 3:
                _b.sent();
                return [3, 7];
            case 4:
                e_5 = _b.sent();
                return [4, put(setLoading(action.type, false))];
            case 5:
                _b.sent();
                return [4, call(errorNotification, Messages.Errors.NotValidEmailOrPassword)];
            case 6:
                _b.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
function loginLocal(action) {
    var _a, email, password, accessToken, e_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                _a = action.payload, email = _a.email, password = _a.password;
                return [4, call(authApi.loginLocal, { email: email, password: password })];
            case 1:
                accessToken = (_b.sent()).accessToken;
                return [4, authPreparing(accessToken, '')];
            case 2:
                _b.sent();
                return [3, 6];
            case 3:
                e_6 = _b.sent();
                return [4, put(setLoading(action.type, false))];
            case 4:
                _b.sent();
                return [4, call(errorNotification, Messages.Errors.NotValidEmailOrPassword)];
            case 5:
                _b.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
function registerHandle(action) {
    var _a, email, password, name, accessToken, e_7;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                _a = action.payload, email = _a.email, password = _a.password, name = _a.name;
                return [4, call(authApi.register, { email: email, password: password, name: name })];
            case 1:
                _b.sent();
                return [4, call(authApi.loginLocal, { email: email, password: password })];
            case 2:
                accessToken = (_b.sent()).accessToken;
                return [4, authPreparing(accessToken, '')];
            case 3:
                _b.sent();
                return [4, warningNotification(Messages.Warning.AgentCreate, 10000)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_7 = _b.sent();
                return [4, call(errorNotification, e_7.text)];
            case 6:
                _b.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
function logout() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                Cookies.remove('connect.sid');
                return [4, call(redirect, LOGIN)];
            case 1:
                _a.sent();
                window.location.reload();
                return [2];
        }
    });
}
function saveCurrentPage(currentPage) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(currentPage === IPages.reports)) return [3, 2];
                return [4, put(appActions.saveCurrentPage(IPages.messengerReports))];
            case 1:
                _a.sent();
                return [2];
            case 2:
                if (!(currentPage in IPages)) return [3, 4];
                return [4, put(appActions.saveCurrentPage(currentPage))];
            case 3:
                _a.sent();
                return [3, 6];
            case 4: return [4, put(appActions.saveCurrentPage(IPages.dialogs))];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [2];
        }
    });
}
var getLoginService = function (authServiceName) {
    switch (authServiceName) {
        case GOOGLE.NAME:
            return authApi.loginGoogle;
        case FACEBOOK.NAME:
            return authApi.loginFacebook;
        default:
            return function () {
            };
    }
};
var getPageFromQuery = function (pathName) { return pathName.slice(1); };
var getRole = function (roles) {
    var _a, _b, _c;
    var superAdmin = (_a = roles.find(function (role) { return role.role === IUserRole.superAdmin; })) === null || _a === void 0 ? void 0 : _a.role;
    var brandAdmin = (_b = roles.find(function (role) { return role.role === IUserRole.brandAdmin; })) === null || _b === void 0 ? void 0 : _b.role;
    var regular = (_c = roles.find(function (role) { return role.role === IUserRole.regular; })) === null || _c === void 0 ? void 0 : _c.role;
    return superAdmin || brandAdmin || regular || IUserRole.newbie;
};
function forgotPassword(_a) {
    var response, e_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4, call(authApi.forgotPassword, { email: payload })];
            case 1:
                response = _b.sent();
                if (!response) return [3, 3];
                return [4, fork(successNotification, Messages.Success.ForgotPasswordSend)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [4, put(modalWindowsActions.toggleModal(modalTypes.FORGOT_PASSWORD, null))];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                e_8 = _b.sent();
                return [4, fork(errorNotification, e_8.text)];
            case 6:
                _b.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
export var authSagas = [
    takeLatest(AuthTypes.INIT_APP, loaderWrap(initApp)),
    takeLatest(AuthTypes.REGISTER, registerHandle),
    takeLatest(AuthTypes.LOGIN_SOCIAL, loaderWrap(loginSocial)),
    takeLatest(AuthTypes.LOGIN_LOCAL, loaderWrap(loginLocal)),
    takeLatest(AuthTypes.LOGOUT, logout),
    takeLatest(AuthTypes.FORGOT_PASSWORD, forgotPassword),
];
