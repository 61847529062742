var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from 'react';
import ErrorMessage from '@src/components/ErrorMessage';
import Icon from '@src/components/Icon';
import { InputContainer, StyledInputLabel } from './styled';
export var Input = function (props) {
    var meta = props.meta, icon = props.icon, data = props.data, input = props.input, styles = props.styles, label = props.label, secondIcon = props.secondIcon, placeholder = props.placeholder, iconOnclick = props.iconOnclick, showWarning = props.showWarning, secondIconOnclick = props.secondIconOnclick, maxLength = props.maxLength;
    var touched = meta.touched, error = meta.error;
    var hasError = touched && error;
    var inputRef = useRef(null);
    useEffect(function () {
        if (hasError)
            showWarning && showWarning();
    }, [hasError]);
    return (React.createElement(React.Fragment, null,
        label && React.createElement(StyledInputLabel, null, label),
        React.createElement(InputContainer, { error: hasError, styles: styles, className: "input-container" },
            React.createElement("input", __assign({}, input, { placeholder: placeholder, maxLength: maxLength, ref: inputRef })),
            icon && React.createElement(Icon, { onClick: iconOnclick, src: icon, data: input.name || data }),
            secondIcon && React.createElement(Icon, { onClick: secondIconOnclick, src: secondIcon, data: input.name || data })),
        hasError && React.createElement(ErrorMessage, { text: error })));
};
