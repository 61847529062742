var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var processingPhraseAndGreetings = function (phrase) {
    var _a, _b;
    var tempObj = {};
    for (var prop in phrase) {
        var currentGreetings = '';
        if (prop.split('/')[0] === 'greetings') {
            currentGreetings = phrase[prop];
        }
        var currentPhrases = '';
        if (prop.split('№')[0] === 'fastPhrase') {
            currentPhrases = phrase[prop];
        }
        tempObj[prop.split('/')[1]] = {
            greetings: currentGreetings || ((_a = tempObj[prop.split('/')[1]]) === null || _a === void 0 ? void 0 : _a.greetings),
            phrases: __spreadArray(__spreadArray([], __read(((_b = tempObj[prop.split('/')[1]]) === null || _b === void 0 ? void 0 : _b.phrases) || []), false), [currentPhrases], false).filter(function (el) { return el !== ''; }),
        };
    }
    return tempObj;
};
