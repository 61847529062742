import { Statistics } from '@src/modules/Pages/Reports/AgentReport/components/Statistics/Statistics';
import { connect } from 'react-redux';
import reportsStore from '@src/modules/Pages/Reports/redux/selector';
import { reportsActions } from '@src/modules/Pages/Reports/redux/reducer';
var mapStateToProps = function (state) { return ({
    reports: reportsStore.agentReports(state),
    checkedReportBlockDay: reportsStore.checkedReportBlockDay(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setAgentReportBlockDay: function (blockDay) { return dispatch(reportsActions.setAgentReportBlockDay(blockDay)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
