import { createAction, createSlice } from '@reduxjs/toolkit';
import { reduxModules } from '@src/global-interfaces';
import { EntityTypes } from '@src/redux/entities/types';
var initialState = {
    dialogs: [],
    brands: [],
    groups: [],
    agents: [],
    tags: [],
    clients: [],
    channels: [],
    messengerReports: [],
    agentsReports: [],
    groupsReports: [],
};
var entitiesSlice = createSlice({
    name: reduxModules.entity,
    initialState: initialState,
    reducers: {
        setDataSource: function (state, action) {
            var _a = action.payload, data = _a.data, pageKey = _a.pageKey;
            state[pageKey] = data;
        },
    },
});
export var entitiesActions = {
    setDataSource: entitiesSlice.actions.setDataSource,
    getTableData: createAction(EntityTypes.GET_TABLE_DATA),
    updateTableData: createAction(EntityTypes.UPDATE_TABLE_DATA),
};
export var entitiesReducer = entitiesSlice.reducer;
