import Toolbar from '@src/modules/ToolBar/ToolBarUI/Toolbar';
import { connect } from 'react-redux';
import excelStore from '@src/modules/Excel/redux/selector';
import { appStore } from '@src/redux/app/selector';
import { excelActions } from '@src/modules/Excel/redux/reducer';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    excelQueue: excelStore.getExcelQueue(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    createExcelFile: function () { return dispatch(excelActions.createExcelFile()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
