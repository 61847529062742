var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, put, call, fork, } from 'redux-saga/effects';
import { Messages } from '@public/locales/messages';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { dialogActions, DialogsPageTypes } from '@src/modules/Pages/Dialogs/redux/reducer';
import { dialogsApi } from '@src/modules/Pages/Dialogs/redux/api';
import { loaderWrap } from '@src/redux/loading/loaderWrap';
import { errorNotification } from '@src/redux/notification/saga';
export function getMessagesByDialogId(dialogId) {
    var overallCount, dialogMessages, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4, call(dialogsApi.getMessagesByDialogId, { count: 0, offset: 0, dialogId: dialogId })];
            case 1:
                overallCount = (_a.sent()).overallCount;
                return [4, call(dialogsApi.getMessagesByDialogId, { count: overallCount, offset: 0, dialogId: dialogId })];
            case 2:
                dialogMessages = (_a.sent()).data;
                return [4, put(dialogActions.setDialogMessages(dialogMessages))];
            case 3:
                _a.sent();
                return [3, 6];
            case 4:
                e_1 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_1.text)];
            case 5:
                _a.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
export function getAudioCallsByDialogId(dialogId) {
    var audioCalls, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(dialogsApi.getAudioCallsByDialogId, dialogId)];
            case 1:
                audioCalls = (_a.sent()).data;
                return [4, put(dialogActions.setAudioCalls(audioCalls))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                e_2 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || e_2.text)];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
export function getMessagesDialog(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, put(modalWindowsActions.toggleModal(modalTypes.DIALOG_MESSAGES, payload))];
            case 1:
                _b.sent();
                return [4, call(getMessagesByDialogId, payload)];
            case 2:
                _b.sent();
                return [4, call(getAudioCallsByDialogId, payload)];
            case 3:
                _b.sent();
                return [2];
        }
    });
}
export var dialogsPageSagas = [
    takeEvery(DialogsPageTypes.GET_MESSAGES_DIALOG, loaderWrap(getMessagesDialog)),
];
