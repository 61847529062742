import React from 'react';
import FacebookLogin from 'react-facebook-login-typed';
import { connect } from 'react-redux';
import { system } from '@src/constants/objects';
import { icons } from '@public/images';
import { authActions } from '@src/modules/Auth/redux/reducer';
import { Messages } from '@public/locales/messages';
import { StyledSocialButton, StyledSocialButtonText } from './styled';
var _a = system.AUTH_PROVIDERS.FACEBOOK, CLIENT_ID = _a.CLIENT_ID, NAME = _a.NAME;
var LoginButtonFacebook = function (_a) {
    var loginSocial = _a.loginSocial;
    var loginFacebook = function (response) {
        loginSocial({
            code: response.accessToken,
            serviceName: NAME,
        });
    };
    return (React.createElement(FacebookLogin, { appId: CLIENT_ID, autoLoad: false, callback: loginFacebook, render: function (renderProps) { return (React.createElement(StyledSocialButton, { onClick: renderProps.onClick },
            React.createElement("img", { src: icons.facebook, alt: "Facebook Logo" }),
            React.createElement(StyledSocialButtonText, null, Messages.Buttons.loginWithFacebook))); }, responseType: "token", disableMobileRedirect: true }));
};
var mapDispatchToProps = function (dispatch) { return ({
    loginSocial: function (data) { return dispatch(authActions.loginSocial(data)); },
}); };
export default connect(null, mapDispatchToProps)(LoginButtonFacebook);
