import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { clientsActions } from '@src/modules/Pages/Clients/redux/reducer';
import Input from '@src/components/Input';
import Loader from '@src/components/Loader';
import Button from '@src/components/Button';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { loadingStore } from '@src/redux/loading/selector';
import { StyledStepWrapper, StyledCreateClientInput, } from '@src/modules/Pages/Clients/CreateClient/styled';
import { StyledPreviousBtn, StyledSaveBtn } from '@src/components/Button/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterTwoBtnWrapper } from '@src/app/styled';
var StepThree = function (props) {
    var name = props.name, phone = props.phone, email = props.email, setName = props.setName, lastName = props.lastName, firstName = props.firstName, currentStep = props.currentStep, setLastName = props.setLastName, setFirstName = props.setFirstName, createClient = props.createClient, selectedBrand = props.selectedBrand, setCurrentStep = props.setCurrentStep, selectedMessengers = props.selectedMessengers, isLoadingCreateClient = props.isLoadingCreateClient;
    var setValue = function (data) {
        setName(data.clientName);
        setFirstName(data.clientFirstName);
        setLastName(data.clientLastName);
    };
    var onSubmit = function (data) {
        setValue(data);
        createClient({
            email: email,
            phone: phone,
            brandId: selectedBrand.value,
            lastName: data.clientLastName,
            username: data.clientName,
            messenger: selectedMessengers.value,
            firstName: data.clientFirstName,
        });
    };
    var createClientInput = InputWithStyles(Input, StyledCreateClientInput);
    return (React.createElement(StyledStepWrapper, null, isLoadingCreateClient ? (React.createElement(Loader, null)) : (React.createElement(Form, { onSubmit: onSubmit, initialValues: {
            clientName: name,
            clientFirstName: firstName,
            clientLastName: lastName,
        }, render: function (_a) {
            var handleSubmit = _a.handleSubmit, getState = _a.form.getState;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(StyledModalContentWrapper, null,
                    React.createElement(StyledModalContentFieldsWrapper, null,
                        React.createElement(Field, { name: "clientName", label: Messages.Labels.ClientName, placeholder: Messages.Controls.ClientName, component: createClientInput }),
                        React.createElement(Field, { name: "clientFirstName", label: Messages.Labels.ClientFirstName, placeholder: Messages.Controls.ClientFirstName, component: createClientInput }),
                        React.createElement(Field, { name: "clientLastName", label: Messages.Labels.ClientLastName, placeholder: Messages.Controls.ClientLastName, component: createClientInput })),
                    React.createElement(StyledModalFooterTwoBtnWrapper, null,
                        React.createElement(StyledPreviousBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.previous, onClick: function () {
                                    setValue(getState().values);
                                    setCurrentStep(currentStep - 1);
                                } })),
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.createClient, onClick: function () { }, type: TButtonTypes.submit }))))));
        } }))));
};
var mapStateToProps = function (state) { return ({
    isLoadingCreateClient: loadingStore.getIsLoadingCreateClient(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    createClient: function (data) { return dispatch(clientsActions.createClient(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
