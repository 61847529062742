import React from 'react';
import { icons } from '@public/images';
import { urlValidation } from '@src/utils/validations';
import { TextWithImageRenderer } from '@src/components/tableRenderers/TextWithImageRenderer/TextWithImageRenderer';
import { StyledAgentNameRenderer } from '@src/components/tableRenderers/AgentRenderer/styled';
var AgentRenderer = function (data) {
    var avatar = data.avatar, name = data.name;
    var isValidAvatarUrl = urlValidation(avatar);
    return (React.createElement(StyledAgentNameRenderer, { isAvatar: isValidAvatarUrl },
        React.createElement(TextWithImageRenderer, { text: name, imageUrl: avatar && isValidAvatarUrl ? avatar : icons.defaultAvatar })));
};
export default AgentRenderer;
