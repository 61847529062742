var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createAction, createSlice } from '@reduxjs/toolkit';
import { ITables, reduxModules, } from '@src/global-interfaces';
import { size } from 'lodash';
import { TableOptionTypes } from '@src/redux/tableOptions/types';
import { createTableOptionsStore } from '@src/utils/additional';
var initialTableOptionsState = createTableOptionsStore(Object.keys(ITables));
var initialState = initialTableOptionsState;
var tableOptionsSlice = createSlice({
    name: reduxModules.appPage,
    initialState: initialState,
    reducers: {
        setPagination: function (state, action) {
            var _a = action.payload, pagination = _a.pagination, pageKey = _a.pageKey;
            state[pageKey].pagination = __assign(__assign({}, state[pageKey].pagination), pagination);
        },
        setSorting: function (state, action) {
            var _a = action.payload, sorting = _a.sorting, pageKey = _a.pageKey;
            state[pageKey].sorting = __assign(__assign({}, state[pageKey].sorting), sorting);
        },
        setFiltering: function (state, action) {
            var _a = action.payload, filtering = _a.filtering, pageKey = _a.pageKey;
            if (size(filtering) === 0) {
                state[pageKey].filtering = {};
            }
            else {
                state[pageKey].filtering = __assign(__assign({}, state[pageKey].filtering), filtering);
            }
        },
    },
});
export var tableOptionsActions = {
    setSorting: tableOptionsSlice.actions.setSorting,
    setFiltering: tableOptionsSlice.actions.setFiltering,
    setPagination: tableOptionsSlice.actions.setPagination,
    updateSorting: createAction(TableOptionTypes.UPDATE_SORTING),
    updateFiltering: createAction(TableOptionTypes.UPDATE_FILTERING),
    updatePagination: createAction(TableOptionTypes.UPDATE_PAGINATION),
};
export var tableOptionsReducer = tableOptionsSlice.reducer;
