import { connect } from 'react-redux';
import HistoryComponent from '@src/modules/Pages/Clients/HistoryDialog/HistoryComponent';
import { clientsActions } from '@src/modules/Pages/Clients/redux/reducer';
import { clientsStore } from '@src/modules/Pages/Clients/redux/selector';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { loadingStore } from '@src/redux/loading/selector';
var mapStateToProps = function (state) { return ({
    agentDictionary: dictionariesStore.dictionaries(state).agentDictionary,
    currentAudioCalls: clientsStore.getCurrentAudioCalls(state),
    currentDialogMessages: clientsStore.getCurrentDialogMessages(state),
    isLoadingCurrentDialogHistory: loadingStore.getIsLoadingCurrentDialogHistory(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showClientHistoryDialog: function (id) { return dispatch(clientsActions.showClientHistoryDialog(id)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(HistoryComponent);
