var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Field, Form } from 'react-final-form';
import { icons } from '@public/images';
import Messages from '@public/locales/en';
import Input from '@src/components/Input';
import { ListItem } from '@src/components/SearchList/SearchListItem/SearchListItem';
import { searchItemsList } from '@src/utils/searchItemsList';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { composeValidators, isRequired, isSpaces, lengthCreator, nameValidation, } from '@src/utils/validations';
import { useOutsideClick } from '@src/utils/useOutsideClick';
import { notificationTypes } from '@src/global-interfaces';
import { StyledSearchInput, StyledSearchListWrapper, StyledSearchListItemsWrapper, } from '@src/components/SearchList/styled';
var SearchList = function (props) {
    var multiple = props.multiple, isSearch = props.isSearch, placeholder = props.placeholder, onClickItem = props.onClickItem, selectedItem = props.selectedItem, searchListArr = props.searchListArr, hideSearchList = props.hideSearchList, onClickItemIcon = props.onClickItemIcon, showNotification = props.showNotification, isAbsolutePosition = props.isAbsolutePosition, resetNotificationState = props.resetNotificationState, _a = props.elementIsNotHideSearchList, elementIsNotHideSearchList = _a === void 0 ? [] : _a;
    var ref = useRef();
    useOutsideClick(hideSearchList, ref, elementIsNotHideSearchList, true);
    var _b = __read(useState(searchListArr || []), 2), searchList = _b[0], setSearchList = _b[1];
    var maxNameLength = lengthCreator.maxNameLength, minNameLength = lengthCreator.minNameLength;
    var inputStyles = {
        marginBottom: '0',
        paddingRight: '5px',
        background: '#f5f4f4',
    };
    var onSubmit = function (data) { return setSearchList(searchItemsList(searchListArr, data.searchInput)); };
    var onChange = function (value, fn, field) {
        if (value.length <= 0) {
            setSearchList(searchListArr);
            fn(field);
        }
    };
    var onClickSearchListItems = function (value) {
        if (!multiple) {
            selectedItem.length < 1 && onClickItem(value);
            if (selectedItem.length >= 1) {
                showNotification({ msg: Messages.Warning.YouHaveAlreadySelectedAFilter, type: notificationTypes.warning });
                setTimeout(function () { return resetNotificationState(); }, 1000);
            }
            return;
        }
        onClickItem(value);
    };
    var searchInput = InputWithStyles(Input, StyledSearchInput);
    return (React.createElement(StyledSearchListWrapper, { isAbsolutePosition: isAbsolutePosition, ref: ref },
        isSearch && (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit, form = _a.form;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: "searchInput", component: searchInput, placeholder: placeholder, icon: icons.search, styles: inputStyles, validate: composeValidators(isSpaces, isRequired, nameValidation, minNameLength, maxNameLength), iconOnclick: handleSubmit }),
                    React.createElement(OnChange, { name: "searchInput" }, function (value) { return onChange(value, form.resetFieldState, 'searchInput'); })));
            } })),
        searchList.length >= 1 && (React.createElement(StyledSearchListItemsWrapper, null, searchList.map(function (el, index) { return (React.createElement(ListItem, { isSelected: selectedItem.includes(el.value), el: el.name, value: el.value, key: index, onClickItemIcon: onClickItemIcon, onClick: function () { return onClickSearchListItems(el.value); } })); })))));
};
export default SearchList;
