var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { cloneDeep, difference } from 'lodash';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Messages } from '@public/locales/messages';
import { getDefaultCustomizations } from '@src/constants/defaultCustomizations';
import { greetingScheme } from '@src/global-interfaces';
import { channelsApi } from '@src/modules/Pages/Channels/redux/api';
import { ChannelPageTypes, channelsActions } from '@src/modules/Pages/Channels/redux/reducer';
import channelsStore from '@src/modules/Pages/Channels/redux/selector';
import { deepMerge } from '@src/modules/Pages/Channels/redux/utils';
import { entitiesActions } from '@src/redux/entities/reducers';
import { loaderWrap, setLoading } from '@src/redux/loading/loaderWrap';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { errorNotification, successNotification } from '@src/redux/notification/saga';
function createChannel(_a) {
    var channelURL, channelName, requiredFields, clientHistory, greetingsAndPhrase, enabledLang, selectedBrand, greetings, requiredFieldsObj, data, response, error_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                channelURL = payload.channelURL, channelName = payload.channelName, requiredFields = payload.requiredFields, clientHistory = payload.clientHistory, greetingsAndPhrase = payload.greetingsAndPhrase, enabledLang = payload.enabledLang, selectedBrand = payload.selectedBrand;
                greetings = greetingScheme.map(function (el) {
                    if (greetingsAndPhrase[el.locale]) {
                        return {
                            locale: el.locale,
                            text: greetingsAndPhrase[el.locale].greetings,
                            phrases: greetingsAndPhrase[el.locale].phrases,
                        };
                    }
                    return el;
                });
                requiredFieldsObj = Object.fromEntries(requiredFields.map(function (currentItem) { return [currentItem, true]; }));
                data = {
                    customizations: getDefaultCustomizations(),
                    lang: 'en',
                    name: channelName,
                    position: 'right',
                    isAuth: true,
                    isIcon: true,
                    enabledLang: enabledLang,
                    url: channelURL,
                    gettingClientHistory: clientHistory,
                    greeting: greetings,
                    requiredFields: requiredFieldsObj,
                };
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4, call(channelsApi.createChannel, data, selectedBrand)];
            case 2:
                response = _b.sent();
                if (!response) return [3, 4];
                return [4, fork(successNotification, Messages.Success.ChanelCreated)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [3, 7];
            case 5:
                error_1 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_1.text)];
            case 6:
                _b.sent();
                console.error(error_1);
                return [3, 7];
            case 7: return [4, put(entitiesActions.updateTableData())];
            case 8:
                _b.sent();
                return [4, put(setLoading(ChannelPageTypes.CREATE_CHANNEL, false))];
            case 9:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.CREATE_CHANNEL, null))];
            case 10:
                _b.sent();
                return [2];
        }
    });
}
function getChannelById(id) {
    var channel, defaultCustomizations, serverCustomizations, customizationsActual, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4, call(channelsApi.getChannelById, id)];
            case 1:
                channel = _a.sent();
                return [4, cloneDeep(getDefaultCustomizations())];
            case 2:
                defaultCustomizations = _a.sent();
                serverCustomizations = (channel === null || channel === void 0 ? void 0 : channel.customizations) || {};
                return [4, cloneDeep(defaultCustomizations)];
            case 3:
                customizationsActual = _a.sent();
                deepMerge(customizationsActual, serverCustomizations);
                return [4, put(channelsActions.setCurrentChannel(__assign(__assign({}, channel), { customizations: customizationsActual })))];
            case 4:
                _a.sent();
                return [3, 7];
            case 5:
                error_2 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_2.text)];
            case 6:
                _a.sent();
                console.error(error_2);
                return [3, 7];
            case 7: return [2];
        }
    });
}
function getMessengersByChannelId(id) {
    var messengers, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(channelsApi.getMessengersByChannelId, id)];
            case 1:
                messengers = _a.sent();
                return [4, put(channelsActions.setCurrentChannelMessengers(messengers))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                error_3 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_3.text)];
            case 4:
                _a.sent();
                console.error(error_3);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function getMessengersByBrandId(id) {
    var messengers, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, call(channelsApi.getMessengersByBrandId, id)];
            case 1:
                messengers = _a.sent();
                return [4, put(channelsActions.setCurrentBrandMessengers(messengers))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                error_4 = _a.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_4.text)];
            case 4:
                _a.sent();
                console.error(error_4);
                return [3, 5];
            case 5: return [2];
        }
    });
}
function showCurrentChannel(_a) {
    var brandId;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, call(getChannelById, payload)];
            case 1:
                _b.sent();
                return [4, put(modalWindowsActions.toggleModal(modalTypes.UPDATE_CHANNEL, payload))];
            case 2:
                _b.sent();
                return [4, select(channelsStore.getCurrentChannel)];
            case 3:
                brandId = (_b.sent()).brandId;
                return [4, call(getMessengersByChannelId, payload)];
            case 4:
                _b.sent();
                return [4, call(getMessengersByBrandId, brandId)];
            case 5:
                _b.sent();
                return [2];
        }
    });
}
function deleteMessengerFromChannel(_a) {
    var response, error_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4, call(channelsApi.deleteMessengerFromChannel, payload)];
            case 1:
                response = _b.sent();
                if (!response) return [3, 3];
                return [4, fork(successNotification, Messages.Success.DeleteMessenger)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [4, call(getMessengersByChannelId, payload.channelId)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                error_5 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_5.text)];
            case 6:
                _b.sent();
                console.error(error_5);
                return [3, 7];
            case 7: return [4, put(setLoading(ChannelPageTypes.DELETE_MESSENGER_FROM_CHANNEL, false))];
            case 8:
                _b.sent();
                return [2];
        }
    });
}
function addMessengerForChannel(_a) {
    var response, error_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4, call(channelsApi.addMessengerForChannel, payload)];
            case 1:
                response = _b.sent();
                if (!response) return [3, 3];
                return [4, fork(successNotification, Messages.Success.AddMessenger)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [4, call(getMessengersByChannelId, payload.channelId)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                error_6 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_6.text)];
            case 6:
                _b.sent();
                console.error(error_6);
                return [3, 7];
            case 7: return [4, put(setLoading(ChannelPageTypes.ADD_MESSENGER_FOR_CHANNEL, false))];
            case 8:
                _b.sent();
                return [2];
        }
    });
}
function deleteAndAddMessengerForChannel(_a) {
    var deleteMessenger, addMessenger, id, responseDeleteMessenger, responseAddMessenger, error_7;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                deleteMessenger = payload.deleteMessenger, addMessenger = payload.addMessenger;
                return [4, select(channelsStore.getCurrentChannel)];
            case 1:
                id = (_b.sent()).id;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4, call(channelsApi.deleteMessengerFromChannel, deleteMessenger)];
            case 3:
                responseDeleteMessenger = _b.sent();
                return [4, call(channelsApi.addMessengerForChannel, addMessenger)];
            case 4:
                responseAddMessenger = _b.sent();
                if (!(responseDeleteMessenger && responseAddMessenger)) return [3, 6];
                return [4, fork(successNotification, Messages.Success.ChangedMessenger)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [4, call(getMessengersByChannelId, id)];
            case 7:
                _b.sent();
                return [3, 10];
            case 8:
                error_7 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_7.text)];
            case 9:
                _b.sent();
                console.error(error_7);
                return [3, 10];
            case 10: return [4, put(setLoading(ChannelPageTypes.DELETE_AND_ADD_MESSENGER_FROM_CHANNEL, false))];
            case 11:
                _b.sent();
                return [2];
        }
    });
}
function updateChannel(_a) {
    var currentChannel, url, name, greeting, isCaptcha, clientHistory, privacyPolicy, requiredFields, customizations, botNameLocales, selectedGroups, assignationType, showAllClientDialogs, selectedLanguages, newGroups, deleteGroups, addGroups, channel, error_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(channelsStore.getCurrentChannel)];
            case 1:
                currentChannel = _b.sent();
                url = payload.url, name = payload.name, greeting = payload.greeting, isCaptcha = payload.isCaptcha, clientHistory = payload.clientHistory, privacyPolicy = payload.privacyPolicy, requiredFields = payload.requiredFields, customizations = payload.customizations, botNameLocales = payload.botNameLocales, selectedGroups = payload.selectedGroups, assignationType = payload.assignationType, showAllClientDialogs = payload.showAllClientDialogs, selectedLanguages = payload.selectedLanguages;
                return [4, selectedGroups.map(function (el) {
                        if (typeof el === 'string') {
                            return el;
                        }
                        return el.value;
                    })];
            case 2:
                newGroups = _b.sent();
                deleteGroups = difference(currentChannel.groupIds, newGroups);
                addGroups = difference(newGroups, currentChannel.groupIds);
                _b.label = 3;
            case 3:
                _b.trys.push([3, 10, , 12]);
                return [4, call(channelsApi.sendUpdateChannel, __assign(__assign({}, currentChannel), { url: url, name: name, greeting: greeting, isCaptcha: isCaptcha, privacyPolicy: privacyPolicy, requiredFields: requiredFields, customizations: customizations, botNameLocales: botNameLocales, assignationType: assignationType, showAllClientDialogs: showAllClientDialogs, gettingClientHistory: clientHistory, enabledLang: selectedLanguages.map(function (el) {
                            if (typeof el !== 'string') {
                                return el.value;
                            }
                            return el;
                        }) }))];
            case 4:
                channel = _b.sent();
                if (!(deleteGroups.length > 0)) return [3, 6];
                return [4, call(channelsApi.deleteGroupsToChannel, deleteGroups, currentChannel.id)];
            case 5:
                channel = _b.sent();
                _b.label = 6;
            case 6:
                if (!(addGroups.length > 0)) return [3, 8];
                return [4, call(channelsApi.addGroupToChannel, addGroups, currentChannel.id)];
            case 7:
                channel = _b.sent();
                _b.label = 8;
            case 8: return [4, put(channelsActions.setCurrentChannel(channel))];
            case 9:
                _b.sent();
                return [3, 12];
            case 10:
                error_8 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_8.text)];
            case 11:
                _b.sent();
                console.error(error_8);
                return [3, 12];
            case 12: return [4, put(setLoading(ChannelPageTypes.UPDATE_CHANNEL, false))];
            case 13:
                _b.sent();
                return [2];
        }
    });
}
function updateDefaultCustomizations(_a) {
    var currentChannel, channel, error_9;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, select(channelsStore.getCurrentChannel)];
            case 1:
                currentChannel = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 7]);
                return [4, call(channelsApi.sendUpdateChannel, __assign(__assign({}, currentChannel), { customizations: payload }))];
            case 3:
                channel = _b.sent();
                return [4, put(channelsActions.setCurrentChannel(channel))];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                error_9 = _b.sent();
                return [4, fork(errorNotification, Messages.Errors.ServerError || error_9.text)];
            case 6:
                _b.sent();
                console.error(error_9);
                return [3, 7];
            case 7: return [2];
        }
    });
}
function createPipeDrive(_a) {
    var error_10;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, call(channelsApi.createKeyPipeDrive, payload)];
            case 1:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.Success)];
            case 2:
                _b.sent();
                return [4, call(getChannelById, payload.channelId)];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                error_10 = _b.sent();
                return [4, fork(errorNotification, error_10.text || Messages.Errors.ServerError)];
            case 5:
                _b.sent();
                console.error(error_10);
                return [3, 6];
            case 6: return [2];
        }
    });
}
function updatePipeDrive(_a) {
    var error_11;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, call(channelsApi.updateKeyPipeDrive, payload)];
            case 1:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.Success)];
            case 2:
                _b.sent();
                return [4, call(getChannelById, payload.channelId)];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                error_11 = _b.sent();
                return [4, fork(errorNotification, error_11.text || Messages.Errors.ServerError)];
            case 5:
                _b.sent();
                console.error(error_11);
                return [3, 6];
            case 6: return [2];
        }
    });
}
function deletePipeDrive(_a) {
    var id, error_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4, call(channelsApi.deleteKeyPipeDrive, payload)];
            case 1:
                _b.sent();
                return [4, fork(successNotification, Messages.Success.Success)];
            case 2:
                _b.sent();
                return [4, select(channelsStore.getCurrentChannel)];
            case 3:
                id = (_b.sent()).id;
                return [4, call(getChannelById, id)];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                error_12 = _b.sent();
                return [4, fork(errorNotification, error_12.text || Messages.Errors.ServerError)];
            case 6:
                _b.sent();
                console.error(error_12);
                return [3, 7];
            case 7: return [2];
        }
    });
}
export var channelPageSagas = [
    takeEvery(ChannelPageTypes.CREATE_CHANNEL, loaderWrap(createChannel, false)),
    takeEvery(ChannelPageTypes.SHOW_CURRENT_CHANNEL, loaderWrap(showCurrentChannel)),
    takeEvery(ChannelPageTypes.DELETE_MESSENGER_FROM_CHANNEL, loaderWrap(deleteMessengerFromChannel, false)),
    takeEvery(ChannelPageTypes.ADD_MESSENGER_FOR_CHANNEL, loaderWrap(addMessengerForChannel, false)),
    takeEvery(ChannelPageTypes.DELETE_AND_ADD_MESSENGER_FROM_CHANNEL, loaderWrap(deleteAndAddMessengerForChannel, false)),
    takeEvery(ChannelPageTypes.UPDATE_CHANNEL, loaderWrap(updateChannel, false)),
    takeEvery(ChannelPageTypes.UPDATE_DEFAULT_CUSTOMIZATION, loaderWrap(updateDefaultCustomizations)),
    takeEvery(ChannelPageTypes.CREATE_PIPE_DRIVE, loaderWrap(createPipeDrive)),
    takeEvery(ChannelPageTypes.UPDATE_PIPE_DRIVE, loaderWrap(updatePipeDrive)),
    takeEvery(ChannelPageTypes.DELETE_PIPE_DRIVE, loaderWrap(deletePipeDrive)),
];
