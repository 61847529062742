import { Messages } from '@public/locales/messages';
import { tableRenderers } from '@src/components/tableRenderers';
var _a = Messages.Pages.Clients.TableColumns, AgentName = _a.AgentName, Messenger = _a.Messenger, LastTag = _a.LastTag, ActiveDialog = _a.ActiveDialog, Brand = _a.Brand, InternalId = _a.InternalId, ExternalId = _a.ExternalId, Name = _a.Name, FirstName = _a.FirstName, LastName = _a.LastName, Email = _a.Email, InvoiceNumber = _a.InvoiceNumber, DialogsCount = _a.DialogsCount, FirstMessage = _a.FirstMessage, LastActivity = _a.LastActivity;
export var clientsColumns = [
    {
        title: InternalId,
        key: 'id',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ExternalId,
        key: 'messengerId',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: Name,
        key: 'username',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: FirstName,
        key: 'firstName',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: LastName,
        key: 'lastName',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: Email,
        key: 'email',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: InvoiceNumber,
        key: 'invoiceNumber',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: AgentName,
        key: 'agentName',
        render: tableRenderers.TextEllipsisRenderer,
        styles: {
            width: '150px',
        },
    },
    {
        title: Messenger,
        key: 'messenger',
        render: tableRenderers.MessengerRenderer,
    },
    {
        title: LastTag,
        key: 'lastTag',
        render: tableRenderers.TextEllipsisRenderer,
        styles: {
            width: '120px',
        },
    },
    {
        title: Brand,
        key: 'brand',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: ActiveDialog,
        key: 'agentAssigned',
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: DialogsCount,
        key: 'dialogsCount',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            width: '120px',
            justifyContent: 'center',
        },
    },
    {
        title: FirstMessage,
        key: 'entryDate',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: LastActivity,
        key: 'lastMsgTime',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
];
