import React from 'react';
import { Popover } from 'antd';
import { icons } from '@public/images';
import { Messages } from '@public/locales/messages';
import { notificationTypes } from '@src/global-interfaces';
import { StyledContentPopover, StyledPopoverWrapper, StyledTitlePopover } from '@src/components/CustomPopover/styled';
var CustomPopover = function (props) {
    var children = props.children, text = props.text, iconClick = props.iconClick, titleText = props.titleText, showNotification = props.showNotification, resetNotificationState = props.resetNotificationState, _a = props.isHeader, isHeader = _a === void 0 ? true : _a;
    var onClickIcon = function () {
        iconClick(text);
        showNotification({ msg: "".concat(titleText, " ").concat(Messages.Info.Copied), type: notificationTypes.info });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var content = (React.createElement(StyledPopoverWrapper, { onClick: function (e) { return e.stopPropagation(); } },
        isHeader && (React.createElement(StyledTitlePopover, null,
            React.createElement("span", null, titleText),
            React.createElement("img", { src: icons.copy, alt: "copy", onClick: onClickIcon }))),
        React.createElement(StyledContentPopover, { id: "customPopoverText", isHeader: isHeader }, text)));
    return (React.createElement(Popover, { content: content }, children));
};
export default CustomPopover;
