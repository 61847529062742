import React, { useRef } from 'react';
import { ChromePicker } from 'react-color';
import { StyledPicker, StyledPickerContent, StyledPickerTitle, StyledPickerWrapper, } from '@src/components/CustomColorPicker/styled';
import { useOutsideClick } from '@src/utils/useOutsideClick';
function ColorPicker(_a) {
    var modalInfo = _a.modalInfo, toggleModal = _a.toggleModal, customizations = _a.customizations;
    var closeColorPicker = function () {
        toggleModal();
    };
    var ref = useRef(null);
    useOutsideClick(closeColorPicker, ref, [], true);
    var handleChangeComplete = function (color) {
        if (modalInfo) {
            modalInfo.setColor(color.hex);
        }
    };
    return (React.createElement(StyledPickerWrapper, null,
        React.createElement(StyledPickerContent, null,
            React.createElement(StyledPicker, { ref: ref },
                React.createElement(StyledPickerTitle, null, modalInfo === null || modalInfo === void 0 ? void 0 : modalInfo.title),
                React.createElement(ChromePicker, { color: modalInfo ? customizations[modalInfo === null || modalInfo === void 0 ? void 0 : modalInfo.customizationModule][modalInfo === null || modalInfo === void 0 ? void 0 : modalInfo.customizationName] : '#fff', onChangeComplete: handleChangeComplete, disableAlpha: true, width: 500 })))));
}
export default ColorPicker;
