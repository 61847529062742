var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from 'react';
import Icon from '@src/components/Icon';
import { useOutsideClick } from '@src/utils/useOutsideClick';
import { List } from './List';
import { StyledDropDownMenu } from './styled';
export function DropDownMenu(_a) {
    var list = _a.list, activeItem = _a.activeItem, _b = _a.activeIconItem, activeIconItem = _b === void 0 ? '' : _b, position = _a.position;
    var _c = __read(useState(false), 2), isShowMenu = _c[0], setIsShowMenu = _c[1];
    var toggleMenu = function (e) {
        e.stopPropagation();
        e.preventDefault();
        setIsShowMenu(!isShowMenu);
    };
    var ref = useRef();
    useOutsideClick(setIsShowMenu, ref, [], true);
    var dropDownIconStyles = { height: '16px', marginRight: '5px' };
    return (React.createElement(StyledDropDownMenu, { onClick: toggleMenu, ref: ref },
        React.createElement("div", { className: "items-block" },
            React.createElement("div", { className: "active-item" },
                activeIconItem && React.createElement(Icon, { src: activeIconItem, styles: dropDownIconStyles }),
                React.createElement("p", { className: "active-item-name" }, activeItem && activeItem)),
            isShowMenu && React.createElement(List, { list: list, position: position }))));
}
