import React from 'react';
import { Arrow } from './Arrow/Arrow';
import { Page } from './Page/Page';
import dblRightArrow from './paginationIcons/double-right-arrows-angles.svg';
import leftArrow from './paginationIcons/left-arrow.svg';
import { PaginationTriggerEnum } from './pagination-trigger.enum';
import { StyledPagination } from './StyledPagination';
export function Pagination(_a) {
    var pagination = _a.pagination, isLoading = _a.isLoading, updatePagination = _a.updatePagination;
    var pages = [];
    var count = pagination.count, overallCount = pagination.overallCount, offset = pagination.offset;
    var lastPage = Math.ceil(overallCount / count);
    var firstPage = 1;
    var pagesToSkip = 5;
    var currentPageIsFirst = offset === firstPage;
    var currentPageIsLast = offset === lastPage;
    var currentOffsetLeft = offset - firstPage >= pagesToSkip;
    var currentOffsetRight = lastPage - offset >= pagesToSkip;
    var elementsAroundCurrent = 2;
    for (var i = firstPage; i <= lastPage; i++) {
        if (currentOffsetLeft && i === firstPage + 1) {
            pages.push(React.createElement(Arrow, { key: Math.random(), iconUrl: dblRightArrow, type: PaginationTriggerEnum.BACK, skipCount: pagesToSkip, styles: { transform: 'rotate(180deg)', height: '10px', width: '10px' }, offset: offset, count: count, overallCount: overallCount, updatePagination: updatePagination }));
        }
        if (currentOffsetRight && i === lastPage) {
            pages.push(React.createElement(Arrow, { key: Math.random(), iconUrl: dblRightArrow, type: PaginationTriggerEnum.FORWARD, skipCount: pagesToSkip, styles: { height: '10px', width: '10px' }, offset: offset, count: count, overallCount: overallCount, updatePagination: updatePagination }));
        }
        if (currentOffsetLeft && i > firstPage && i < offset - elementsAroundCurrent)
            continue;
        if (currentOffsetRight && i < lastPage && i > offset + elementsAroundCurrent)
            continue;
        pages.push(React.createElement(Page, { key: Math.random(), offset: offset, pageNumber: i, count: count, overallCount: overallCount, updatePagination: updatePagination }));
    }
    return (React.createElement(StyledPagination, { isLoading: isLoading },
        !currentPageIsFirst && (React.createElement(Arrow, { iconUrl: leftArrow, type: PaginationTriggerEnum.BACK, skipCount: 1, styles: { height: '20px', width: '20px' }, offset: offset, count: count, overallCount: overallCount, updatePagination: updatePagination })),
        pages.length > 1 && React.createElement("div", { className: "pagination-pages-wrap" }, pages.map(function (el) { return el; })),
        !currentPageIsLast && (React.createElement(Arrow, { iconUrl: leftArrow, type: PaginationTriggerEnum.FORWARD, skipCount: 1, styles: { transform: 'rotate(180deg)', height: '20px', width: '20px' }, offset: offset, count: count, overallCount: overallCount, updatePagination: updatePagination }))));
}
