var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles } from '@src/app/styled';
export var ResetPasswordBackDisplay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    background-color: #000000;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"], ["\n  && {\n    background-color: #000000;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"])));
export var ResetPasswordWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    background-color: #1d2933;\n    width: 25vw;\n    max-height: 50vh;\n    padding: 10px 20px;\n    overflow: auto;\n    border-radius: 12px;\n    ", "\n  }\n"], ["\n  && {\n    background-color: #1d2933;\n    width: 25vw;\n    max-height: 50vh;\n    padding: 10px 20px;\n    overflow: auto;\n    border-radius: 12px;\n    ", "\n  }\n"])), scrollStyles);
export var ResetPasswordInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"], ["\n  && {\n    input {\n      height: 32px;\n    }\n  }\n"])));
export var ResetPasswordTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    height: 32px;\n    color: #e5e5e5;\n    padding-bottom: 45px;\n  }\n"], ["\n  && {\n    height: 32px;\n    color: #e5e5e5;\n    padding-bottom: 45px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
