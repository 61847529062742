var _a;
import { ITables } from '@src/global-interfaces';
import { transformFiltering } from '@src/utils/transformFilteringForExcel';
import { getAppLang } from '@public/locales/messages';
import { system } from '@src/constants/objects';
import { getHistory } from '@src/utils/history';
import { transformSortingParameter } from '@src/utils/transformSortingParameter';
export var preparingRequestForExcelSorting = function (props) {
    var order = props.order;
    var column = props.column;
    if (!column || !order)
        return '';
    if (['/reports'].includes(getHistory().location.pathname)) {
        if (order === 'asc')
            order = 'Ascending';
        if (order === 'desc')
            order = 'Descending';
    }
    return "Sorting.Parameter=".concat(transformSortingParameter(column), "&Sorting.SortOrder=").concat(order, "&");
};
export var preparingRequestForExcelPaginate = function (_a) {
    var overallCount = _a.overallCount;
    var countName = 'Count';
    var offsetName = 'Offset';
    if (['/agents', '/clients', '/tags', '/groups', '/dialogs', '/channels'].includes(getHistory().location.pathname)) {
        countName = 'Pagination.Count';
        offsetName = 'Pagination.Offset';
    }
    return "".concat(countName, "=").concat(overallCount, "&").concat(offsetName, "=").concat(0, "&");
};
var tablesExcelRequest = (_a = {},
    _a[ITables.tags] = 'tag',
    _a[ITables.groups] = 'group',
    _a[ITables.agents] = 'agent',
    _a[ITables.brands] = 'brand',
    _a[ITables.dialogs] = 'dialog',
    _a[ITables.clients] = 'client',
    _a[ITables.channels] = 'channel',
    _a[ITables.agentsReports] = 'agentStatistic',
    _a[ITables.groupsReports] = 'groupStatistic',
    _a[ITables.messengerReports] = 'messengerStatistic',
    _a);
export var preparedRequestForExcel = function (_a) {
    var pagination = _a.pagination, sorting = _a.sorting, filtering = _a.filtering, tableType = _a.tableType, brandId = _a.brandId;
    var sort = preparingRequestForExcelSorting(sorting);
    var paginate = preparingRequestForExcelPaginate(pagination);
    var filters = Object
        .entries(transformFiltering(filtering))
        .reduce(function (acc, curr) { return (!curr[1] ? acc : (acc + curr[1])); }, '');
    var table = tablesExcelRequest[tableType];
    var brandSearchByNameById = brandId && "BrandId=".concat(brandId);
    return "".concat(table, "?").concat(sort).concat(paginate).concat(brandSearchByNameById || filters, "&culture=").concat(system.LOCALES[getAppLang()].regName);
};
