import React from 'react';
import Header from '@src/modules/Header';
import Button from '@src/components/Button';
import Toolbar from '@src/modules/ToolBar';
import TableComponent from '@src/modules/Pages/TableComponent';
import TagsCreateModal from '@src/modules/Pages/Tags/TagsCreateModal';
import TagsUpdateModal from '@src/modules/Pages/Tags/TagsUpdateModal';
import { StyledAddBtn } from '@src/components/Button/styled';
import { StyledContent, StyledPageWrapper } from '@src/app/styled';
import { Messages } from '@public/locales/messages';
var TagsPage = function (props) {
    var toggleModal = props.toggleModal, currentPage = props.currentPage, isModalOpenUpdateTags = props.isModalOpenUpdateTags, isModalOpenCreateTags = props.isModalOpenCreateTags;
    return (React.createElement(StyledPageWrapper, { className: "tags-page" },
        React.createElement(Header, null),
        React.createElement(StyledContent, { currentPage: currentPage },
            React.createElement(StyledAddBtn, null,
                React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addTag), onClick: toggleModal })),
            React.createElement(Toolbar, null),
            React.createElement(TableComponent, null)),
        isModalOpenCreateTags && (React.createElement(TagsCreateModal, null)),
        isModalOpenUpdateTags && (React.createElement(TagsUpdateModal, null))));
};
export default TagsPage;
