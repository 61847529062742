var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { scrollStyles, StyledModalWrapperMedia } from '@src/app/styled';
export var StyledCreateSmsInboxWrapper = styled(StyledModalWrapperMedia)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  max-height: 50vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"], ["\n  overflow: auto;\n  padding: 10px 20px;\n  display: flex;\n  max-height: 50vh;\n  width: 40vw;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledLoaderWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    position: relative;\n    height: 15vh;\n  }\n"], ["\n  && {\n    position: relative;\n    height: 15vh;\n  }\n"])));
export var StyledMessageNameWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    text-align: center;\n    font-size: 15px;\n    padding: 5px;\n    margin: 5px 0;\n    border-bottom: 1px solid ", ";\n    color: ", ";\n  }\n"], ["\n  && {\n    text-align: center;\n    font-size: 15px;\n    padding: 5px;\n    margin: 5px 0;\n    border-bottom: 1px solid ", ";\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
var templateObject_1, templateObject_2, templateObject_3;
