var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { COLORS, FONT, HEADER_HEIGHT } from '@src/app/styled';
var BLUE_VIVID = COLORS.BLUE_VIVID, WHITE = COLORS.WHITE;
var DEFAULT_FONT_SIZE = FONT.DEFAULT_FONT_SIZE;
export var StyledHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    height: ", "px;\n    background: ", ";\n    display: flex;\n    align-items: center;\n\n    p,\n    a,\n    li,\n    span,\n    div {\n      font-size: calc(", " + 2px);\n    }\n\n    .options {\n      display: flex;\n      align-items: center;\n\n      & > div {\n        margin: 0 15px;\n      }\n    }\n  }\n"], ["\n  && {\n    width: 100%;\n    height: ", "px;\n    background: ", ";\n    display: flex;\n    align-items: center;\n\n    p,\n    a,\n    li,\n    span,\n    div {\n      font-size: calc(", " + 2px);\n    }\n\n    .options {\n      display: flex;\n      align-items: center;\n\n      & > div {\n        margin: 0 15px;\n      }\n    }\n  }\n"])), HEADER_HEIGHT, function (_a) {
    var theme = _a.theme;
    return theme.colors.headerBackground;
}, DEFAULT_FONT_SIZE);
export var StyledNavigation = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: start;\n    width: 100%;\n    height: 100%;\n\n    a {\n      height: 100%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      font-weight: normal;\n      transition: color 0.2s;\n      margin-right: 70px;\n      white-space: nowrap;\n\n      &:hover {\n        color: ", ";\n      }\n    }\n\n    .active-page {\n      border-bottom: 5px solid ", ";\n    }\n\n    .menu-item {\n      color: ", ";\n    }\n  }\n"], ["\n  && {\n    display: flex;\n    align-items: center;\n    justify-content: start;\n    width: 100%;\n    height: 100%;\n\n    a {\n      height: 100%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      font-weight: normal;\n      transition: color 0.2s;\n      margin-right: 70px;\n      white-space: nowrap;\n\n      &:hover {\n        color: ", ";\n      }\n    }\n\n    .active-page {\n      border-bottom: 5px solid ", ";\n    }\n\n    .menu-item {\n      color: ", ";\n    }\n  }\n"])), BLUE_VIVID, BLUE_VIVID, WHITE);
export var StyledNavigationWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: start;\n    z-index: 1;\n    margin-right: ", ";\n  }\n"], ["\n  && {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: start;\n    z-index: 1;\n    margin-right: ", ";\n  }\n"])), function (props) { return (props.language === 'ar' || props.language === 'he' ? '0' : 'auto'); });
var templateObject_1, templateObject_2, templateObject_3;
