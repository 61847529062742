import React, { useMemo } from 'react';
import Msg from '@src/components/MessagesContainer/msg/Msg';
import NoData from '@src/components/NoData';
import { StyledMessagesContainerWrapper, StyledMessagesContainerNoData } from '@src/components/MessagesContainer/styled';
var MessagesContainer = function (props) {
    var messages = props.messages, agentDictionary = props.agentDictionary, clientName = props.clientName;
    var memoizedMessages = useMemo(function () { return messages.filter(function (el) { return el.sender !== 'system'; }); }, [messages]);
    return (React.createElement(React.Fragment, null, memoizedMessages.length >= 1 ? (React.createElement(StyledMessagesContainerWrapper, null, memoizedMessages === null || memoizedMessages === void 0 ? void 0 : memoizedMessages.map(function (msg, index) { return (React.createElement(Msg, { key: index, text: msg.text, time: msg.time, sender: msg.sender, agentName: agentDictionary[msg.sender], clientName: clientName })); }))) : (React.createElement(StyledMessagesContainerNoData, null,
        React.createElement(NoData, null)))));
};
export default MessagesContainer;
