import { Messages } from '@public/locales/messages';
import { tableRenderers } from '@src/components/tableRenderers';
var _a = Messages.Pages.Brands.TableColumns, BrandChannels = _a.BrandChannels, BrandName = _a.BrandName, BrandsId = _a.BrandsId, ClientUniqueness = _a.ClientUniqueness, CreatedAt = _a.CreatedAt, UpdatedAt = _a.UpdatedAt, DialogCount = _a.DialogCount, Status = Messages.Controls.Status;
export var brandColumns = [
    {
        title: BrandName,
        key: 'name',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: BrandsId,
        key: 'id',
        render: tableRenderers.TextEllipsisRenderer,
        sortingEnabled: true,
    },
    {
        title: ClientUniqueness,
        key: 'clientUniqueness',
        sortingEnabled: true,
        render: tableRenderers.TextEllipsisRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: BrandChannels,
        render: tableRenderers.ArrayTextRenderer,
        key: 'channels',
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: DialogCount,
        render: tableRenderers.TextEllipsisRenderer,
        key: 'dialogsCount',
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: Status,
        render: tableRenderers.TextEllipsisRenderer,
        key: 'status',
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: CreatedAt,
        key: 'createdAt',
        render: tableRenderers.DateRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: UpdatedAt,
        key: 'updatedAt',
        render: tableRenderers.DateRenderer,
        sortingEnabled: true,
        styles: {
            justifyContent: 'center',
        },
    },
];
