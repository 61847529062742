import { connect } from 'react-redux';
import agentsStore from '@src/modules/Pages/Agents/redux/selector';
import { agentsActions } from '@src/modules/Pages/Agents/redux/reducer';
import AgentToGroups from './AgentToGroups';
var mapStateToProps = function (state) { return ({
    availableGroupsCurrentAgent: agentsStore.getAvailableGroupsCurrentAgent(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    addAndDeleteAgentGroups: function (data) { return dispatch(agentsActions.addAndDeleteAgentGroups(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AgentToGroups);
