import { Messages } from '@public/locales/messages';
import { tableRenderers } from '@src/components/tableRenderers';
var _a = Messages.Pages.Dialogs.TableColumns, Agent = _a.Agent, Channel = _a.Channel, Dialog = _a.Dialog, FirstMessage = _a.FirstMessage, Language = _a.Language, LastActivity = _a.LastActivity, MessagesCount = _a.MessagesCount, Messenger = _a.Messenger, Rating = _a.Rating, Status = _a.Status, Tag = _a.Tag;
export var dialogColumns = [
    {
        title: Status,
        key: 'status',
        render: tableRenderers.StatusRenderer,
        styles: {
            width: '120px',
        },
    },
    {
        title: Dialog,
        key: 'id',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: Messenger,
        key: 'messenger',
        render: tableRenderers.MessengerRenderer,
    },
    {
        title: Channel,
        key: 'channel',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: Tag,
        key: 'tag',
        render: tableRenderers.TextEllipsisRenderer,
        styles: {
            width: '120px',
        },
    },
    {
        title: Agent,
        key: 'agent',
        render: tableRenderers.TextEllipsisRenderer,
    },
    {
        title: Language,
        key: 'language',
        render: tableRenderers.LanguageRenderer,
    },
    {
        title: FirstMessage,
        key: 'createdAt',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: LastActivity,
        key: 'lastActivity',
        sortingEnabled: true,
        render: tableRenderers.DateRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
    {
        title: Rating,
        key: 'rating',
        sortingEnabled: true,
        render: tableRenderers.TextRenderer,
        styles: {
            width: '120px',
            justifyContent: 'center',
        },
    },
    {
        title: MessagesCount,
        key: 'msgCount',
        render: tableRenderers.TextRenderer,
        styles: {
            justifyContent: 'center',
        },
    },
];
