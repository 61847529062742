var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { omit } from 'lodash';
export var modalTypes = {
    AGENT_MODAL: 'agentModal',
    DIALOG_MESSAGES: 'dialogMessages',
    NEW_PHRASE_GROUPS: 'newPhraseGroups',
    CREATE_TAGS_MODAL: 'createTagsModal',
    UPDATE_TAGS_MODAL: 'updateTagsModal',
    USEFUL_LINKS_MODAL: 'usefulLinksModal',
    EDIT_PHRASE_GROUPS: 'editPhraseGroups',
    NEW_CATEGORY_GROUPS: 'newCategoryGroups',
    EDIT_CATEGORY_GROUPS: 'editCategoryGroups',
    CLEAR_CATEGORIES_GROUPS: 'clearCategoriesGroups',
    RESET_PASSWORD_MODAL: 'resetPasswordModal',
    FORGOT_PASSWORD: 'forgotPassword',
    CREATE_GROUPS_MODAL: 'createGroupsModal',
    UPDATE_GROUPS_MODAL: 'updateGroupsModal',
    ADD_AGENTS_TO_GROUPS: 'addAgentsToGroups',
    CLIENT_INFO_MODAL: 'clientInfoModal',
    CREATE_CLIENT: 'clientClient',
    IMPORT_CLIENTS: 'importClients',
    CREATE_BRAND: 'createBrand',
    SETTINGS_BRAND: 'settingsBrand',
    CREATE_MAIL_INBOX: 'createMailInbox',
    CREATE_TELEGRAM_INBOX: 'createTelegramInbox',
    CREATE_SMS_INBOX: 'createSmsInbox',
    UPDATE_MAIL_INBOX: 'updateMailInbox',
    UPDATE_TELEGRAM_INBOX: 'updateTelegramInbox',
    UPDATE_SMS_INBOX: 'updateSmsInbox',
    SHOW_AGENT_REPORT: 'showAgentReport',
    CREATE_CHANNEL: 'createChannel',
    BULK_MESSAGING: 'bulkMessaging',
    UPDATE_CHANNEL: 'updateChannel',
    CHANGE_COLOR_PICKER: 'changeColorPicker',
};
export var toggleModal = function (state, action) {
    var _a;
    if (state.modals.includes(action.payload.type)) {
        state.modals = state.modals.filter(function (el) { return el !== action.payload.type; });
        state.modalsInfo = omit(state.modalsInfo, [action.payload.type]);
        return;
    }
    state.modals = __spreadArray(__spreadArray([], __read(state.modals), false), [action.payload.type], false);
    state.modalsInfo = __assign(__assign({}, state.modalsInfo), (_a = {}, _a[action.payload.type] = action.payload.modalInfo, _a));
};
