import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Messages } from '@public/locales/messages';
import { StyledModalContentFieldsWrapper, StyledModalContentWrapper, StyledModalFooterTwoBtnWrapper, } from '@src/app/styled';
import Button from '@src/components/Button';
import { TButtonTypes } from '@src/components/Button/Button';
import { StyledPreviousBtn, StyledSaveBtn } from '@src/components/Button/styled';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import Loader from '@src/components/Loader';
import { notificationTypes } from '@src/global-interfaces';
import { StyledCreateClientInput, StyledStepWrapper } from '@src/modules/Pages/Clients/CreateClient/styled';
import { clientsActions } from '@src/modules/Pages/Clients/redux/reducer';
import { clientsStore } from '@src/modules/Pages/Clients/redux/selector';
import { loadingStore } from '@src/redux/loading/selector';
import { notificationActions } from '@src/redux/notification/reducer';
import { composeValidators, isCorrectEmail, isRequired, isSpaces, lengthCreator, phoneValidation, } from '@src/utils/validations';
var StepTwo = function (props) {
    var email = props.email, phone = props.phone, setPhone = props.setPhone, setEmail = props.setEmail, currentStep = props.currentStep, currentBrand = props.currentBrand, brandSettings = props.brandSettings, setCurrentStep = props.setCurrentStep, showNotification = props.showNotification, getBrandSettings = props.getBrandSettings, resetNotificationState = props.resetNotificationState, isLoadingCreateClientBrandSettings = props.isLoadingCreateClientBrandSettings;
    var maxEmailLength = lengthCreator.maxEmailLength, minEmailLength = lengthCreator.minEmailLength, minPhoneNumber = lengthCreator.minPhoneNumber, maxPhoneNumber = lengthCreator.maxPhoneNumber;
    useEffect(function () {
        if (currentBrand.value) {
            getBrandSettings(currentBrand.value);
        }
    }, [currentBrand]);
    var getIsRequiredEmail = function () {
        if ((brandSettings === null || brandSettings === void 0 ? void 0 : brandSettings.clientUniqueness) === 'email') {
            return composeValidators(isSpaces, isRequired, isCorrectEmail, minEmailLength, maxEmailLength);
        }
    };
    var getIsRequiredPhone = function () {
        if ((brandSettings === null || brandSettings === void 0 ? void 0 : brandSettings.clientUniqueness) === 'phone') {
            return composeValidators(isSpaces, isRequired, phoneValidation, minPhoneNumber, maxPhoneNumber);
        }
    };
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var setValue = function (data) {
        setPhone(data.clientPhone);
        setEmail(data.clientEmail);
    };
    var onSubmit = function (data) {
        setValue(data);
        setCurrentStep(currentStep + 1);
    };
    var createClientInput = InputWithStyles(Input, StyledCreateClientInput);
    return (React.createElement(StyledStepWrapper, null, isLoadingCreateClientBrandSettings ? (React.createElement(Loader, null)) : (React.createElement(Form, { onSubmit: onSubmit, initialValues: {
            clientPhone: phone,
            clientEmail: email,
        }, render: function (_a) {
            var handleSubmit = _a.handleSubmit, getState = _a.form.getState;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(StyledModalContentWrapper, null,
                    React.createElement(StyledModalContentFieldsWrapper, null,
                        React.createElement("div", { style: { marginBottom: '10px' } }, Messages.Labels.ContactsAreWorkingWithClientUniqueness),
                        React.createElement(Field, { name: "clientPhone", label: Messages.Labels.ClientPhone, validate: getIsRequiredPhone(), showWarning: showWarning, placeholder: Messages.Controls.ClientPhone, component: createClientInput }),
                        React.createElement(Field, { name: "clientEmail", label: Messages.Labels.ClientEmail, showWarning: showWarning, validate: getIsRequiredEmail(), placeholder: Messages.Controls.ClientEmail, component: createClientInput })),
                    React.createElement(StyledModalFooterTwoBtnWrapper, null,
                        React.createElement(StyledPreviousBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.previous, onClick: function () {
                                    setValue(getState().values);
                                    setCurrentStep(currentStep - 1);
                                } })),
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.next, onClick: function () { }, type: TButtonTypes.submit }))))));
        } }))));
};
var mapStateToProps = function (state) { return ({
    brandSettings: clientsStore.getCurrentBrandSettings(state),
    isLoadingCreateClientBrandSettings: loadingStore.getIsLoadingCreateClientBrandSettings(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
    getBrandSettings: function (id) { return dispatch(clientsActions.getBrandSettings(id)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
