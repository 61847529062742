var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export var useExcelModal = function (_a) {
    var clearExcelQueue = _a.clearExcelQueue, excelQueue = _a.excelQueue, toggleExcelModalMinimize = _a.toggleExcelModalMinimize;
    var _b = __read(useState(document.createElement('div')), 1), container = _b[0];
    useEffect(function () {
        document.body.appendChild(container);
        return function () {
            clearExcelQueue();
            toggleExcelModalMinimize();
            document.body.removeChild(container);
        };
    }, []);
    var downloadList = Object.entries(excelQueue).map(function (el) { return ({ id: el[1].id, url: el[1].url, tableName: el[0] }); });
    return {
        container: container,
        downloadList: downloadList,
    };
};
