var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledCustomColorPickerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    padding: 5px;\n    width: max-content;\n    height: max-content;\n  }\n"], ["\n  && {\n    padding: 5px;\n    width: max-content;\n    height: max-content;\n  }\n"])));
export var StyledCustomColorPickerBtn = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  && {\n    display: flex;\n    flex-direction: column;\n  }\n"])));
export var StyledCustomColorPickerTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    text-align: center;\n    padding-bottom: 5px;\n    user-select: none;\n  }\n"], ["\n  && {\n    color: ", ";\n    text-align: center;\n    padding-bottom: 5px;\n    user-select: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledCustomColorPickerBrnWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    display: flex;\n  }\n"], ["\n  && {\n    display: flex;\n  }\n"])));
export var StyledCustomColorPickerBtnIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    width: 32px;\n    height: 32px;\n    background: ", ";\n    border-radius: 4px;\n    border: 1px solid ", ";\n  }\n"], ["\n  && {\n    width: 32px;\n    height: 32px;\n    background: ", ";\n    border-radius: 4px;\n    border: 1px solid ", ";\n  }\n"])), function (_a) {
    var valueColor = _a.valueColor;
    return valueColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledCustomColorPickerBtnValue = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    width: max-content;\n    height: 32px;\n    padding: 0 8px;\n    border-radius: 4px;\n    margin-left: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  && {\n    width: max-content;\n    height: 32px;\n    padding: 0 8px;\n    border-radius: 4px;\n    margin-left: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: ", ";\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
